import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import InfoButtonProviderDetails from '../../components/InfoButtonProviderDetails';
import InfoButtonProviderContacts from '../../components/InfoButtonProviderContacts';
import InfoButtonPropertyList from '../../components/InfoButtonPropertyList';
import TimelinePanel from '../../components/TimelinePanel';
import { showOrLoadProviderDetailsView } from '../../modules/ProviderDetails/ProviderDetailsActions';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

class ProviderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      // isTestProviderCommission: false,
    };
  }

  componentDidMount() {
    this.props.actions.showOrLoadProviderDetailsView(this.props.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.providerDetails) && this.state.isLoading) {
      this.setState({
        isLoading: false,
        // isTestProviderCommission:
        //   nextProps.providerDetails.id.charAt(0) === 'a' ||
        //   nextProps.providerDetails.id.charAt(0) === 'c' ||
        //   nextProps.providerDetails.id.charAt(0) === 'd' ||
        //   (nextProps.providerDetails.id.charAt(0) === 'e' &&
        //     (get(nextProps, 'providerDetails.commissionPercentage', 0) === 0 ||
        //       get(nextProps, 'providerDetails.commissionPercentage', 0) === 6)),
      });
    }
  }

  render() {
    return (
      <div className='animated fadeIn col-sm-12'>
        <div>
          {this.props.metasource.error && (
            <Alert
              message='Error'
              description={this.props.metasource.errorDetail}
              type='error'
              showIcon
              closable
            />
          )}
        </div>
        {this.state.isLoading ? (
          <div key='1' className='loader loading-background loading-background-full'>
            &nbsp;
          </div>
        ) : (
          <>
            <div className='marginTop2 col-sm-12'>
              <div className='row'>
                <InfoButtonProviderDetails />
                <InfoButtonProviderContacts mainView='ProviderDetails' leftOffset='5' />
                <InfoButtonPropertyList
                  leftOffset='7'
                  providerDetails={this.props.providerDetails}
                />
              </div>
            </div>
            {isNumber(this.props.providerDetails.disabledDate) && (
              <Alert
                message='¡Cuidadito!'
                description='¡Este Proveedor se encuentra en la lista negra de Captación!'
                type='warning'
                showIcon
                className='mb-4'
              />
            )}
            {/* {this.state.isTestProviderCommission && (
              <Alert
                message='¡Al loro!'
                description='Este Proveedor se encuentra afectado por un test por el cual le estamos incrementando la comisión a un 10%'
                type='warning'
                showIcon
                className='mb-4'
              />
            )} */}
            <TimelinePanel contextType='PROVIDER' metasource={this.props.metasource} />
          </>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      showOrLoadProviderDetailsView: id => dispatch(showOrLoadProviderDetailsView(id)),
    },
  };
}
function mapStateToProps(state) {
  return {
    metasource: state.ProviderDetails.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    providerDetails: state.ProviderDetails.data.detail,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetails);
