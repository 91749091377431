import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Steps } from 'antd';
import Select from 'react-select';
import CheckboxButton from '../CheckboxButton';
import SelectDeal from '../SelectDeal';
import SelectContact from '../SelectContact';
import { PARSE_DATE_FORMAT, ENDPOINT } from '../../shared/appConfig.js';
import { createNewInvoice } from '../../modules/InvoiceDetails/InvoiceDetailsActions';
import { openNotificationWithIcon, RENTAL_REQUEST_STAGES } from '../../shared/utils/appUtils';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import { INVOICE_TYPES } from 'hs-utils';
import api from '../../shared/utils/api';

class FormNewInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: {
        hsagent: { username: props.username },
      },
      wizardState: {
        currentStep: 0,
        backButtonDisabled: true,
        nextButtonDisabled: true,
        finishButtonDisabled: true,
        selectInvoiceDealDisabled: true,
        invoiceDealRelation: null,
        selectedDealObject: null,
        proposalOptions: [],
        noProposalsAvailableMessage: null,
        selectInvoiceProposalDisabled: true,
        contactsPrefilter: '',
        filterContactsByDeal: false,
      },
    };
    this.handleCheckboxesChange = this.handleCheckboxesChange.bind(this);
    this.handleInvoiceTypeChange = this.handleInvoiceTypeChange.bind(this);
    this.handleDealSelectChange = this.handleDealSelectChange.bind(this);
    this.handleProposalSelectChange = this.handleProposalSelectChange.bind(this);
    this.handleContactSelectChange = this.handleContactSelectChange.bind(this);
    this.getProviderIdFromSelectedProposals = this.getProviderIdFromSelectedProposals.bind(this);
    this.getSelectedProposal = this.getSelectedProposal.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.finish = this.finish.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.createInvoice && nextProps.createInvoice.error) {
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error al crear la factura',
        nextProps.createInvoice.errorDetail
      );
    }
  }

  setButtonsState(newWizardState, newInvoiceDetails) {
    newWizardState.backButtonDisabled = false;
    newWizardState.finishButtonDisabled = true;
    if (newWizardState.currentStep === 0) {
      newWizardState.backButtonDisabled = true;
      if (newInvoiceDetails.invoiceType) {
        newWizardState.nextButtonDisabled = false;
      } else {
        newWizardState.nextButtonDisabled = true;
      }
    } else if (newWizardState.currentStep === 1) {
      if (newInvoiceDetails.receiverType) {
        if (
          newInvoiceDetails.invoiceType === INVOICE_TYPES.PROFORMA &&
          newInvoiceDetails.receiverType === 'CUSTOMER'
        ) {
          if (newInvoiceDetails.customerProformaInvoiceType) {
            newWizardState.nextButtonDisabled = false;
          } else {
            newWizardState.nextButtonDisabled = true;
          }
        } else {
          newWizardState.nextButtonDisabled = false;
        }
      } else {
        newWizardState.nextButtonDisabled = true;
      }
    } else if (newWizardState.currentStep === 2) {
      if (
        newWizardState.invoiceDealRelation === 'FALSE' ||
        (newWizardState.invoiceDealRelation === 'TRUE' && newInvoiceDetails.dealId)
      ) {
        newWizardState.nextButtonDisabled = false;
      } else {
        newWizardState.nextButtonDisabled = true;
      }
    } else if (newWizardState.currentStep === 3) {
      if (newInvoiceDetails.proposalIds) {
        newWizardState.nextButtonDisabled = false;
      } else {
        newWizardState.nextButtonDisabled = true;
      }
    } else if (newWizardState.currentStep === 4) {
      newWizardState.nextButtonDisabled = true;
      if (newInvoiceDetails.contactId) {
        newWizardState.finishButtonDisabled = false;
      } else {
        newWizardState.finishButtonDisabled = true;
      }
    }

    return newWizardState;
  }

  handleCheckboxesChange(e) {
    const newInvoiceDetails = this.state.invoiceDetails;
    let newWizardState = this.state.wizardState;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'invoiceTypeProforma') {
      if (value) {
        newInvoiceDetails.invoiceType = INVOICE_TYPES.PROFORMA;
      } else {
        newInvoiceDetails.invoiceType = null;
      }
    } else if (e.target.name === 'invoiceTypeRegular') {
      if (value) {
        newInvoiceDetails.invoiceType = INVOICE_TYPES.REGULAR;
      } else {
        newInvoiceDetails.invoiceType = null;
      }
    } else if (e.target.name === 'invoiceReceiverTypeProvider') {
      if (value) {
        newInvoiceDetails.receiverType = 'PROVIDER';
        newInvoiceDetails.customerProformaInvoiceType = null;
      } else {
        newInvoiceDetails.receiverType = null;
        newInvoiceDetails.customerProformaInvoiceType = null;
      }
    } else if (e.target.name === 'invoiceReceiverTypeCustomer') {
      if (value) {
        newInvoiceDetails.receiverType = 'CUSTOMER';
      } else {
        newInvoiceDetails.receiverType = null;
        newInvoiceDetails.customerProformaInvoiceType = null;
      }
    } else if (e.target.name === 'customerProformaInvoiceTypeManagement') {
      if (value) {
        newInvoiceDetails.customerProformaInvoiceType = 'RENTAL_MANAGEMENT';
      } else {
        newInvoiceDetails.customerProformaInvoiceType = null;
      }
    } else if (e.target.name === 'customerProformaInvoiceTypeRental') {
      if (value) {
        newInvoiceDetails.customerProformaInvoiceType = 'RENTAL_ITSELF_AND_EXPENSES';
      } else {
        newInvoiceDetails.customerProformaInvoiceType = null;
      }
    } else if (e.target.name === 'invoiceDealRelationFalse') {
      newWizardState.selectInvoiceDealDisabled = true;
      newInvoiceDetails.dealId = null;
      newInvoiceDetails.proposalIds = null;
      newWizardState.selectedDealObject = null;
      if (value) {
        newWizardState.invoiceDealRelation = 'FALSE';
      } else {
        newWizardState.invoiceDealRelation = null;
      }
    } else if (e.target.name === 'invoiceDealRelationTrue') {
      if (value) {
        newWizardState.invoiceDealRelation = 'TRUE';
        newWizardState.selectInvoiceDealDisabled = false;
      } else {
        newWizardState.invoiceDealRelation = null;
        newWizardState.selectInvoiceDealDisabled = true;
      }
    }

    newWizardState = this.setButtonsState(newWizardState, newInvoiceDetails);
    this.setState({
      invoiceDetails: newInvoiceDetails,
      wizardState: newWizardState,
    });
  }

  handleInvoiceTypeChange(e) {
    const newInvoiceDetails = this.state.invoiceDetails;
    let newWizardState = this.state.wizardState;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'invoiceTypeProforma') {
      if (value) {
        newInvoiceDetails.invoiceType = INVOICE_TYPES.PROFORMA;
      } else {
        newInvoiceDetails.invoiceType = null;
      }
    } else if (e.target.name === 'invoiceTypeRegular') {
      if (value) {
        newInvoiceDetails.invoiceType = INVOICE_TYPES.REGULAR;
      } else {
        newInvoiceDetails.invoiceType = null;
      }
    } else if (e.target.name === 'invoiceTypeCorrective') {
      newInvoiceDetails.invoiceType = INVOICE_TYPES.CORRECTIVE;
    }

    newWizardState = this.setButtonsState(newWizardState, newInvoiceDetails);
    this.setState({
      invoiceDetails: newInvoiceDetails,
      wizardState: newWizardState,
    });
  }

  handleDealSelectChange = e => {
    const { value, details } = e.target;

    const newInvoiceDetails = this.state.invoiceDetails;
    let newWizardState = this.state.wizardState;

    if (value) {
      newInvoiceDetails.dealId = value;
    } else {
      newInvoiceDetails.dealId = null;
      newInvoiceDetails.proposalIds = null;
    }

    newWizardState.selectedDealObject = details;
    newWizardState = this.setButtonsState(newWizardState, newInvoiceDetails);

    this.setState({
      invoiceDetails: newInvoiceDetails,
      wizardState: newWizardState,
    });
  };

  handleProposalSelectChange(selectedOptions) {
    const newInvoiceDetails = this.state.invoiceDetails;
    let newWizardState = this.state.wizardState;

    if (selectedOptions && selectedOptions.length > 0) {
      const proposalIds = [];

      selectedOptions.forEach(p => proposalIds.push(p.value));
      newInvoiceDetails.proposalIds = proposalIds;
    } else {
      newInvoiceDetails.proposalIds = null;
    }

    newWizardState = this.setButtonsState(newWizardState, newInvoiceDetails);
    this.setState({
      invoiceDetails: newInvoiceDetails,
      wizardState: newWizardState,
    });
  }

  handleContactSelectChange(name, selectedOption) {
    const newInvoiceDetails = this.state.invoiceDetails;
    let newWizardState = this.state.wizardState;

    if (selectedOption) {
      newInvoiceDetails.contactId = selectedOption.value;
    } else {
      newInvoiceDetails.contactId = null;
    }

    newWizardState = this.setButtonsState(newWizardState, newInvoiceDetails);
    this.setState({
      invoiceDetails: newInvoiceDetails,
      wizardState: newWizardState,
    });
  }

  setSelectedDeal(selectedDeal, newWizardState) {
    newWizardState.selectedDealObject = selectedDeal;
    return newWizardState;
  }

  async next() {
    let newWizardState = this.state.wizardState;
    const newInvoiceDetails = this.state.invoiceDetails;

    newWizardState.currentStep += 1;
    if (newWizardState.currentStep === 3) {
      if (
        !newInvoiceDetails.dealId ||
        (newInvoiceDetails.invoiceType === INVOICE_TYPES.PROFORMA &&
          newInvoiceDetails.receiverType === 'CUSTOMER' &&
          newInvoiceDetails.customerProformaInvoiceType === 'RENTAL_MANAGEMENT')
      ) {
        newWizardState.currentStep += 1;
      } else {
        try {
          const response = await api.request(`${ENDPOINT}/deal/${newInvoiceDetails.dealId}`);
          const fullDeal = get(response, 'json.deal', {});

          const newProposalOptions = [];
          let newNoProposalsAvailableMessage = null;
          let newSelectInvoiceProposalDisabled = false;

          if (fullDeal) {
            if (fullDeal.proposals && fullDeal.proposals.length > 0) {
              let label;
              let value;

              fullDeal.proposals.forEach(proposalResource => {
                const { proposal } = proposalResource;

                if (
                  proposal.proposalStage &&
                  proposal.proposalStage === 'ACCEPTED' &&
                  proposal.property
                ) {
                  if (proposal.creationDate) {
                    label = `(${moment(proposal.creationDate).format(PARSE_DATE_FORMAT)}) - `;
                  } else {
                    label = '(??/??/????) - ';
                  }

                  if (proposal.title) {
                    label += `${proposal.title} [`;
                  } else {
                    label += ' Not informed title [';
                  }

                  if (proposal.address && proposal.address.formattedAddress) {
                    label += `${proposal.address.formattedAddress}]`;
                  } else {
                    label += ' Not informed address]';
                  }

                  value = proposal.id;
                  newProposalOptions.push({ label, value });
                }
              });
            }

            if (newProposalOptions.length === 0) {
              if (fullDeal.proposals && fullDeal.proposals.length > 0) {
                newNoProposalsAvailableMessage = `La oportunidad contiene ${fullDeal.proposals.length} propuesta/s pero no está/n aceptada/s o no está/n relacionada/s con una propiedad.`;
              } else {
                newNoProposalsAvailableMessage = 'La oportunidad no contiene ninguna propuesta.';
              }

              newSelectInvoiceProposalDisabled = true;
            }
          }

          newWizardState.selectedDealObject = fullDeal;
          newWizardState.proposalOptions = newProposalOptions;
          newWizardState.noProposalsAvailableMessage = newNoProposalsAvailableMessage;
          newWizardState.selectInvoiceProposalDisabled = newSelectInvoiceProposalDisabled;
        } catch (error) {
          console.error(error);
          openNotificationWithIcon(
            'error',
            'Ooops',
            'No se han podido cargar las propuestas de la oportunidad seleccionada'
          );
        }
      }
    }

    if (newWizardState.currentStep === 4) {
      if (newInvoiceDetails.dealId) {
        if (newInvoiceDetails.receiverType === 'PROVIDER') {
          newWizardState.contactsPrefilter = `&sort=%2BcontactType&providerId=${this.getProviderIdFromSelectedProposals()}`;
        } else {
          newWizardState.contactsPrefilter = newWizardState.selectedDealObject.id;
          newWizardState.filterContactsByDeal = true;
        }
      } else {
        newWizardState.contactsPrefilter = '&sort=%2BcontactType';
      }

      // if (!newInvoiceDetails.dealId) {
      //   newWizardState.contactsPrefilter = '&sort=%2BcontactType';
      // }
    }

    newWizardState = this.setButtonsState(newWizardState, newInvoiceDetails);
    this.setState({
      invoiceDetails: newInvoiceDetails,
      wizardState: newWizardState,
    });
  }

  getProviderIdFromSelectedProposals() {
    for (let p = 0; p < this.state.wizardState.selectedDealObject.proposals.length; p++) {
      if (
        this.state.wizardState.selectedDealObject.proposals[p].proposal.id ===
          this.state.invoiceDetails.proposalIds[0] &&
        this.state.wizardState.selectedDealObject.proposals[p].proposal.property &&
        this.state.wizardState.selectedDealObject.proposals[p].proposal.property.providerBack
      ) {
        return this.state.wizardState.selectedDealObject.proposals[p].proposal.property.providerBack
          .id;
      }
    }

    return null;
  }

  getSelectedProposal() {
    if (this.state.invoiceDetails.proposalIds) {
      for (
        let p = 0;
        p < get(this.state, 'wizardState.selectedDealObject.proposals', []).length;
        p++
      ) {
        if (
          this.state.wizardState.selectedDealObject.proposals[p].proposal.id ===
            this.state.invoiceDetails.proposalIds[0] &&
          this.state.wizardState.selectedDealObject.proposals[p].proposal.property &&
          this.state.wizardState.selectedDealObject.proposals[p].proposal.property.providerBack
        ) {
          return this.state.wizardState.selectedDealObject.proposals[p].proposal;
        }
      }
    }

    return null;
  }

  prev() {
    let newWizardState = this.state.wizardState;
    const newInvoiceDetails = this.state.invoiceDetails;

    newWizardState.currentStep -= 1;
    if (newWizardState.currentStep === 3 && !newInvoiceDetails.dealId) {
      newWizardState.currentStep -= 1;
    }

    newWizardState = this.setButtonsState(newWizardState, newInvoiceDetails);
    this.setState({
      invoiceDetails: newInvoiceDetails,
      wizardState: newWizardState,
    });
  }

  finish() {
    const newWizardState = this.state.wizardState;

    newWizardState.finishButtonDisabled = true;
    this.setState({ wizardState: newWizardState });
    this.props.actions.createNewInvoice(this.props.invoiceLocation, this.state.invoiceDetails);
  }

  render() {
    const { Step } = Steps;
    const steps = [
      {
        title: 'Tipo de factura',
        content: this.getInvoiceTypeWizardStep(),
      },
      {
        title: 'Tipo de destinatario',
        content: this.getReceiverTypeWizardStep(),
      },
      {
        title: 'Relación con oportunidad',
        content: this.getDealRelationWizardStep(),
      },
      {
        title: 'Propuestas',
        content: this.getProposalstWizardStep(),
      },
      {
        title: 'Destinatario',
        content: this.getContactWizardStep(),
      },
    ];

    const { currentStep } = this.state.wizardState;

    return (
      <Form>
        <Steps current={currentStep}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className='steps-content'>{steps[this.state.wizardState.currentStep].content}</div>

        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-sm-3'>
              <button
                type='button'
                className='btn btn-primary btn-block'
                disabled={this.state.wizardState.backButtonDisabled}
                onClick={() => this.prev()}
              >
                Atrás
              </button>
            </div>
            <div className='col-sm-3'>
              <button
                type='button'
                className='btn btn-primary btn-block'
                disabled={this.state.wizardState.nextButtonDisabled}
                onClick={() => this.next()}
              >
                Siguiente
              </button>
            </div>
            <div className='col-sm-3'>&nbsp;</div>
            <div className='col-sm-3'>
              <button
                type='button'
                className='btn btn-primary btn-block'
                disabled={this.state.wizardState.finishButtonDisabled}
                onClick={() => this.finish()}
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </Form>
    );
  }

  getInvoiceTypeWizardStep() {
    return (
      <div className='col-sm-12'>
        <div className='row'>&nbsp;</div>
        <div className='row'>
          <div className='col-sm-4'>
            <CheckboxButton
              borderStyle='invoiceCheckboxActive'
              id='invoiceTypeProforma'
              name='invoiceTypeProforma'
              handleChange={this.handleInvoiceTypeChange}
              content='Proforma'
              value={
                !!(
                  this.state.invoiceDetails &&
                  this.state.invoiceDetails.invoiceType === INVOICE_TYPES.PROFORMA
                )
              }
            />
          </div>
          <div className='col-sm-4'>
            <div className='row'>
              <CheckboxButton
                borderStyle='invoiceCheckboxActive'
                id='invoiceTypeRegular'
                name='invoiceTypeRegular'
                handleChange={this.handleInvoiceTypeChange}
                content='Ordinaria'
                value={
                  !!(
                    this.state.invoiceDetails &&
                    this.state.invoiceDetails.invoiceType === INVOICE_TYPES.REGULAR
                  )
                }
              />
            </div>
          </div>
          <div className='col-sm-4'>
            <CheckboxButton
              borderStyle='invoiceCheckboxActive'
              id='invoiceTypeCorrective'
              name='invoiceTypeCorrective'
              handleChange={this.handleInvoiceTypeChange}
              content='Rectificativa'
              value={
                !!(
                  this.state.invoiceDetails &&
                  this.state.invoiceDetails.invoiceType === INVOICE_TYPES.CORRECTIVE
                )
              }
            />
          </div>
        </div>
        <div className='row'>&nbsp;</div>
      </div>
    );
  }

  getReceiverTypeWizardStep() {
    return (
      <div className='col-sm-12'>
        <div className='row'>&nbsp;</div>
        <div className='row'>
          <div className='col-sm-6'>
            <CheckboxButton
              borderStyle='invoiceCheckboxActive'
              id='invoiceReceiverTypeProvider'
              name='invoiceReceiverTypeProvider'
              content='Proveedor'
              handleChange={this.handleCheckboxesChange}
              value={
                !!(
                  this.state.invoiceDetails && this.state.invoiceDetails.receiverType === 'PROVIDER'
                )
              }
            />
          </div>
          <div className='col-sm-6'>
            <CheckboxButton
              borderStyle='invoiceCheckboxActive'
              id='invoiceReceiverTypeCustomer'
              name='invoiceReceiverTypeCustomer'
              content='Cliente'
              handleChange={this.handleCheckboxesChange}
              value={
                !!(
                  this.state.invoiceDetails && this.state.invoiceDetails.receiverType === 'CUSTOMER'
                )
              }
            />
          </div>
        </div>
        {this.state.invoiceDetails.invoiceType === INVOICE_TYPES.PROFORMA &&
          this.state.invoiceDetails.receiverType === 'CUSTOMER' && (
            <div>
              <div className='row'>&nbsp;</div>
              <div className='row'>
                <div className='col-sm-6'>&nbsp;</div>
                <div className='col-sm-3'>
                  <CheckboxButton
                    borderStyle='invoiceCheckboxActive'
                    id='customerProformaInvoiceTypeManagement'
                    name='customerProformaInvoiceTypeManagement'
                    content='Sobre la gestión'
                    handleChange={this.handleCheckboxesChange}
                    value={
                      !!(
                        this.state.invoiceDetails &&
                        this.state.invoiceDetails.customerProformaInvoiceType ===
                          'RENTAL_MANAGEMENT'
                      )
                    }
                  />
                </div>
                <div className='col-sm-3'>
                  <CheckboxButton
                    borderStyle='invoiceCheckboxActive'
                    id='customerProformaInvoiceTypeRental'
                    name='customerProformaInvoiceTypeRental'
                    content='Sobre el alquiler'
                    handleChange={this.handleCheckboxesChange}
                    value={
                      !!(
                        this.state.invoiceDetails &&
                        this.state.invoiceDetails.customerProformaInvoiceType ===
                          'RENTAL_ITSELF_AND_EXPENSES'
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
        <div className='row'>&nbsp;</div>
      </div>
    );
  }

  getDealRelationWizardStep() {
    return (
      <div className='col-sm-12'>
        <div className='row'>&nbsp;</div>
        <div className='row'>
          <div className='col-sm-6'>
            <CheckboxButton
              borderStyle='invoiceCheckboxActive'
              id='invoiceDealRelationFalse'
              name='invoiceDealRelationFalse'
              content='Sin oportunidad'
              handleChange={this.handleCheckboxesChange}
              value={
                !!(this.state.wizardState && this.state.wizardState.invoiceDealRelation === 'FALSE')
              }
            />
          </div>
          <div className='col-sm-6'>
            <CheckboxButton
              borderStyle='invoiceCheckboxActive'
              id='invoiceDealRelationTrue'
              name='invoiceDealRelationTrue'
              content='Seleccionar una oportunidad'
              handleChange={this.handleCheckboxesChange}
              value={
                !!(this.state.wizardState && this.state.wizardState.invoiceDealRelation === 'TRUE')
              }
            />
          </div>
        </div>
        <div className='row'>&nbsp;</div>
        <div className='row'>
          <div className='col-sm-12'>
            <Form.Item>
              <SelectDeal
                name='dealId'
                id='proposalDeal'
                selectedDeal={get(this.state, 'wizardState.selectedDealObject')}
                prefilter={{
                  rentalRequestStages: [
                    RENTAL_REQUEST_STAGES.S020_SEND_SEARCH_RESULTS,
                    RENTAL_REQUEST_STAGES.S030_CONTRACT_INVOICE_MANAGMENT,
                    RENTAL_REQUEST_STAGES.S040_CHECK_IN,
                    RENTAL_REQUEST_STAGES.S050_RENTAL_CONFIRMED,
                  ],
                }}
                // isRequired
                disabled={this.state.wizardState.selectInvoiceDealDisabled}
                handleChange={this.handleDealSelectChange}
              />
            </Form.Item>
          </div>
        </div>
        <div className='row'>&nbsp;</div>
      </div>
    );
  }

  getProposalstWizardStep() {
    return (
      <div className='col-sm-12'>
        <div className='row'>&nbsp;</div>
        {this.state.wizardState.noProposalsAvailableMessage && (
          <div className='row'>{this.state.wizardState.noProposalsAvailableMessage}</div>
        )}
        <div className='row'>
          <div className='col-sm-12'>
            <label htmlFor='invoiceProposals' className='required'>
              Propuestas
            </label>
            <Select
              name='invoiceCProposals'
              multi
              className='form-control'
              value={
                this.state.invoiceDetails.proposalIds ? this.state.invoiceDetails.proposalIds : []
              }
              options={this.state.wizardState.proposalOptions}
              disabled={this.state.wizardState.selectInvoiceProposalDisabled}
              onChange={this.handleProposalSelectChange}
            />
          </div>
        </div>
        <div className='row'>&nbsp;</div>
      </div>
    );
  }

  getContactWizardStep() {
    let invoicingContacts;

    if (this.state.invoiceDetails.receiverType === 'PROVIDER') {
      const proposal = this.getSelectedProposal();
      invoicingContacts = filter(get(proposal, 'contactsOfProvider', []), function(c) {
        return c.roles.includes('INVOICING') || c.roles.includes('LANDLORD');
      });
    } else {
      invoicingContacts = filter(
        get(this.state, 'wizardState.selectedDealObject.contactsOfCompany', []),
        function(c) {
          return c.roles.includes('INVOICING') || c.roles.includes('LEASEHOLDER');
        }
      );
    }

    return (
      <div className='col-sm-12'>
        <div className='row'>&nbsp;</div>
        <div className='row'>
          <div className='col-sm-12'>
            {!isNil(this.state.wizardState.selectedDealObject) && (
              <SelectContact
                ref='selectContact'
                name='contactId'
                id='invoiceContact'
                label='¿A quién le quieres expedir la factura / proforma?'
                value={
                  this.state.invoiceDetails.contactId ? this.state.invoiceDetails.contactId : ''
                }
                isRequired
                handleChange={this.handleContactSelectChange}
                contactList={invoicingContacts}
              />
            )}
            {isNil(this.state.wizardState.selectedDealObject) && (
              <SelectContact
                ref='selectContact'
                name='contactId'
                id='invoiceContact'
                label='¿A quién le quieres expedir la factura / proforma?'
                value={
                  this.state.invoiceDetails.contactId ? this.state.invoiceDetails.contactId : ''
                }
                isRequired
                handleChange={this.handleContactSelectChange}
                prefilter={this.state.wizardState.contactsPrefilter}
                filterByDeal={this.state.wizardState.filterContactsByDeal}
              />
            )}
          </div>
        </div>
        <div className='row'>&nbsp;</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createNewInvoice: (url, invoiceDetailsData) =>
        dispatch(createNewInvoice(url, invoiceDetailsData)),
    },
  };
}

function mapStateToProps(state) {
  return {
    invoiceLocation: state.Invoices.invoiceLocation.location,
    username: state.Preload.currentUserInformation.username,
    createInvoice: state.Invoices.create,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormNewInvoice));
