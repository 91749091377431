import { combineReducers } from 'redux';
import LoginControl from '../../modules/LoginControl/LoginControlReducer';
import Preload from '../../modules/Preload/PreloadReducer';
import Providers from '../../modules/Providers/ProvidersReducer';
import ProviderDetails from '../../modules/ProviderDetails/ProviderDetailsReducer';
import Properties from '../../modules/Properties/PropertiesReducer';
import PropertyDetails from '../../modules/PropertyDetails/PropertyDetailsReducer';
import Events from '../../modules/Events/EventsReducer';
import Homybrain from './Homybrain';
import Contacts from '../../modules/Contacts/ContactsReducer';
import ContactDetails from '../../modules/ContactDetails/ContactDetailsReducer';
import Companies from '../../modules/Companies/CompaniesReducer';
import CompanyDetails from '../../modules/CompanyDetails/CompanyDetailsReducer';
import Deals from '../../modules/Deals/DealsReducer';
import Requests from '../../modules/Requests/RequestsReducer';
import DealDetails from '../../modules/DealDetails/DealDetailsReducer';
import Proposals from '../../modules/Proposals/ProposalsReducer';
import ProposalDetails from '../../modules/ProposalDetails/ProposalDetailsReducer';
import Invoices from '../../modules/Invoices/InvoicesReducer';
import InvoiceDetails from '../../modules/InvoiceDetails/InvoiceDetailsReducer';
import Pmas from '../../modules/Pmas/PmasReducer';
import SupplyDemand from '../../modules/SupplyDemand/SupplyDemandReducer';

const rootReducer = combineReducers({
  Homybrain,
  LoginControl,
  Preload,
  Providers,
  ProviderDetails,
  Events,
  Properties,
  PropertyDetails,
  Contacts,
  ContactDetails,
  Companies,
  CompanyDetails,
  Deals,
  Requests,
  DealDetails,
  Proposals,
  ProposalDetails,
  Invoices,
  InvoiceDetails,
  Pmas,
  SupplyDemand,
});

export default rootReducer;
