import React, { useState } from 'react';
import { Button, Col, DatePicker, Form, InputNumber, Row } from 'antd';
import { DATE_FORMAT } from '../../shared/appConfig';

const { Item: FormItem } = Form;

const SupplyDemandFilter = ({ filter: propsFilter = {}, isLoading, handleSubmitFilter }) => {
  const [filter, setFilter] = useState(propsFilter);

  const handleSubmit = e => {
    e.preventDefault();
    handleSubmitFilter(filter);
  };

  const handleChange = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  return (
    <Form onSubmit={handleSubmit} layout='vertical'>
      <Row gutter={8} type='flex' justify='start' align='bottom'>
        <Col xxl={2} xl={2} lg={4} md={4}>
          <FormItem label='Radio en km.'>
            <InputNumber
              name='radiusInKm'
              placeholder='3'
              style={{ width: '100%' }}
              min={0}
              max={100}
              value={filter.radiusInKm}
              onChange={value => handleChange('radiusInKm', value)}
            />
          </FormItem>
        </Col>
        <Col xxl={2} xl={2} lg={4} md={4}>
          <FormItem label='Inquilinos'>
            <InputNumber
              name='pax'
              placeholder='0'
              style={{ width: '100%' }}
              min={0}
              max={30}
              value={filter.pax}
              onChange={value => handleChange('pax', value)}
            />
          </FormItem>
        </Col>
        <Col xxl={2} xl={2} lg={4} md={4}>
          <FormItem label='Camas'>
            <InputNumber
              name='minNumberOfBeds'
              placeholder='0'
              style={{ width: '100%' }}
              min={0}
              max={30}
              value={filter.minNumberOfBeds}
              onChange={value => handleChange('minNumberOfBeds', value)}
            />
          </FormItem>
        </Col>
        <Col xxl={4} xl={3} lg={4} md={4}>
          <FormItem label='Fecha entrada'>
            <DatePicker
              name='startDate'
              placeholder='dd/mm/yyyy'
              style={{ width: '100%' }}
              format={DATE_FORMAT}
              value={filter.startDate}
              onChange={value => handleChange('startDate', value)}
            />
          </FormItem>
        </Col>
        <Col xxl={4} xl={3} lg={4} md={4}>
          <FormItem label='Fecha salida'>
            <DatePicker
              name='endDate'
              style={{ width: '100%' }}
              placeholder='dd/mm/yyyy'
              format={DATE_FORMAT}
              value={filter.endDate}
              onChange={value => handleChange('endDate', value)}
            />
          </FormItem>
        </Col>

        <Col xxl={2} xl={3} lg={4} md={4}>
          <FormItem>
            <Button
              htmlType='submit'
              icon='search'
              className='btn-primary filter-buttons'
              loading={isLoading}
            >
              Filtrar
            </Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
export default SupplyDemandFilter;
