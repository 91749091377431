import { getAxiosInstance } from '../../shared/utils/apiUtils';
import api from '../../shared/utils/api';
import { publishPropertyURL, getSignedAvailabilityUrl } from '../../shared/apiUrls';

class PropertyDetailsApi {
  static getMetasource(url, id) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url + '/' + id);
  }

  static getPropertyDetailsData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static savePropertyDetailsData(url, propertyDetails, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, propertyDetails);
  }

  static uploadPropertyImages(url, files) {
    const axiosInstance = getAxiosInstance();
    const data = new FormData();

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      data.append('uploadingFiles', file, file.name);
    }

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    return axiosInstance.post(url, data, config);
  }

  static async publishProperty(propertyRef, propertyId) {
    await api.request_retry(publishPropertyURL(propertyRef, propertyId), 'post', 5);
  }

  static async getSignedAvailabilityUrl(propertyRef, dealId, propertyId) {
    const resp = await api.request_retry(
      getSignedAvailabilityUrl(propertyRef, dealId, propertyId),
      'post',
      5
    );
    return resp.json.url;
  }
}

export default PropertyDetailsApi;
