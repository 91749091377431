import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  cloneProperty,
  resetClonePropertyStatus,
} from '../../modules/PropertyDetails/PropertyDetailsActions';

// PARAMETROS A CONFIGURAR
// callbackFuncionOnCreate -> función a llamar cuando se ha creado un nuevo contacto
// totalCopies -> número de copias del inmueble que se deben realiar

class ProgressCloneProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyDetails: this.props.propertyDetails,
      totalCopies: props.totalCopies,
      currentCopies: 0,
      cloneFinished: false,
      progressMessage: `Clonando 1 de ${this.props.totalCopies} inmuebles`,
      cloneStatus: this.props.cloneStatus,
    };
    this.localCloneIndex = 0;
    this.handleCloneSuccessful = this.handleCloneSuccessful.bind(this);
    this.handleCloneFailed = this.handleCloneFailed.bind(this);
    this.doClone = this.doClone.bind(this);
    this.doClone();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.state.cloneStatus) !== JSON.stringify(nextProps.cloneStatus)) {
      if (nextProps.cloneStatus.error) {
        this.handleCloneFailed();
      } else if (nextProps.cloneStatus.index < this.state.totalCopies) {
        this.setState({
          progressMessage: `Clonando ${nextProps.cloneStatus.index} de ${this.props.totalCopies} inmuebles`,
        });
        this.doClone();
      } else {
        this.handleCloneSuccessful();
      }
    }
  }

  doClone() {
    const localPropertyDetails = Object.assign({}, this.state.propertyDetails, {
      id: '',
      iCalURL: null,
      creationDate: new Date().getTime(),
      incorporationDate: new Date().getTime(),
      reservations: [],
      sourceId: '',
      title: `${this.state.propertyDetails.title} ${parseInt(this.localCloneIndex + 1, 10)}`,
      homyspaceReference: '',
    });

    this.localCloneIndex++;
    this.props.actions.cloneProperty(localPropertyDetails);
  }

  handleCloneSuccessful() {
    this.setState({
      progressMessage: 'Clonado finalizado',
      cloneFinished: true,
    });
    this.props.actions.resetClonePropertyStatus();
  }

  handleCloneFailed() {
    const errorMessage = `Se ha producido un error clonando los inmuebles. Se han podido clonar ${this.state.cloneStatus.index} inmueble/s`;

    this.setState({
      progressMessage: errorMessage,
      cloneFinished: true,
    });
    this.props.actions.resetClonePropertyStatus();
  }

  render() {
    return (
      <div className='col-sm-12'>
        <form id='formProgressClone' action='' method='put'>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='row'>
                <div className='col-sm-12'>
                  <button
                    disabled={!this.state.cloneFinished}
                    type='button'
                    className='btn btn-primary'
                    onClick={this.props.callbackFuncionOnCreate}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
            <div className='col-sm-10'>
              <p>{this.state.progressMessage}</p>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      cloneProperty: propertyDetails => dispatch(cloneProperty(propertyDetails)),
      resetClonePropertyStatus: () => dispatch(resetClonePropertyStatus()),
    },
  };
}

function mapStateToProps(state) {
  return {
    propertyDetails: state.PropertyDetails.data.detail,
    cloneStatus: state.PropertyDetails.clone,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressCloneProperty);
