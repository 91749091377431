import { browserHistory } from 'react-router';
import {
  REQUESTS_METASOURCE_ERROR,
  REQUESTS_METASOURCE_SUCCESS,
  REQUESTS_TABLEDATA_ERROR,
  REQUESTS_TABLEDATA_FILTER,
  REQUESTS_TABLEDATA_SUCCESS,
} from '../../shared/actionTypes';

import RequestsApi from './RequestsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink } from '../../shared/utils/appUtils';

export const filterTableDataRequests = filterCriteria => ({
  type: REQUESTS_TABLEDATA_FILTER,
  filterCriteria,
});

export const metasourceSuccess = metasourceLinks => ({
  type: REQUESTS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: REQUESTS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

const tableDataSuccess = (tableData, totalCount) => ({
  type: REQUESTS_TABLEDATA_SUCCESS,
  tableData,
  totalCount,
});

const tablaDataError = errorMessage => ({
  type: REQUESTS_TABLEDATA_ERROR,
  errorDetail: errorMessage,
});

export const getRequestsTableData = (purl = null) => (dispatch, getState) => {
  try {
    const { metasource } = getState().Requests;
    const { metasourceStrings } = getState().Preload;
    let url = purl;

    if (!url) {
      url = getMetasourceLink(
        metasource,
        metasourceStrings,
        'REQUEST_MAIN_METASOURCE_REQUESTS_PAGINATED'
      );
      url = { href: url.href.replace('?size=15', '?size=25') };
    }

    if (!url || !url.href) {
      return;
    }

    return RequestsApi.getRequestsTableData(url.href)
      .then(response => {
        dispatch(tableDataSuccess(response.data, response.headers['x-total-count']));
      })
      .catch(error => {
        console.error(error);
        dispatch(tablaDataError(extractErrorMessage(error)));
      });
  } catch (e) {
    console.error(e);
    browserHistory.push('/');
  }
};

export const loadRequestsMetasource = () =>
  function(dispatch, getState) {
    try {
      return RequestsApi.getMetasource(getState().Preload.mainMetasource.requests.href)
        .then(response => {
          dispatch(metasourceSuccess(response.data._links));
        })
        .catch(error => {
          console.error(error);
          dispatch(metasourceError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };
