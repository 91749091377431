import { getAxiosInstance } from '../../shared/utils/apiUtils';
import { ENDPOINT_V2 } from '../../shared/appConfig.js';

class LoginControlApi {
  static login(credentials) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(ENDPOINT_V2 + '/users/login', {
      username: credentials.username,
      password: credentials.password,
    });
  }
}

export default LoginControlApi;
