import {
  GET_IMPORT_STATUS_ERROR,
  GET_IMPORT_STATUS_SUCCESS,
  NEW_PROPERTY_LOCATION_ERROR,
  NEW_PROPERTY_LOCATION_SUCCESS,
  PROPERTIES_ACTIVE_BUSINESS_DATA,
  PROPERTIES_BY_DISTANCE_INMAP_FILTERED_ERROR,
  PROPERTIES_BY_DISTANCE_INMAP_FILTERED_SUCCESS,
  PROPERTIES_EXPORTDATA_ERROR,
  PROPERTIES_EXPORTDATA_SUCCESS,
  PROPERTIES_EXTERNAL_FILTER,
  PROPERTIES_FILTER,
  PROPERTIES_INMAP_FILTERED_ERROR,
  PROPERTIES_INMAP_FILTERED_SUCCESS,
  PROPERTIES_LIST_ERROR,
  PROPERTIES_LIST_SUCCESS,
  PROPERTIES_METASOURCE_ERROR,
  PROPERTIES_METASOURCE_SUCCESS,
  UPLOAD_IMPORT_FILE_ERROR,
  UPLOAD_IMPORT_FILE_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  properties: {
    list: [],
    error: false,
    errorDetail: '',
    version: 0,
  },
  propertyList: {
    list: [],
    error: false,
    errorDetail: '',
    byRelenvance: false,
  },
  propertyLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
  filter: {
    radius: 0,
    maxPrice: 0,
    guest: 0,
    location: {},
    billable: null,
    comissionable: null,
    startDate: null,
    endDate: null,
    bounds: null,
    refProvider: null,
    refHomyspace: null,
    beds: 0,
    rooms: 0,
    baths: 0,
    publishedInWeb: null,
    providerType: [],
  },
  externalFilter: false,
  activeBusiness: {
    id: null,
    startDate: null,
    endDate: null,
    zone: null,
    tenants: null,
    providersContacted: [],
  },
  importStatus: {
    error: false,
    errorDetail: '',
    status: '',
    data: null,
  },
};

const Properties = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTIES_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        properties: {
          list: [],
          error: false,
          errorDetail: '',
          version: 0,
        },
        propertyList: {
          list: [],
          error: false,
          errorDetail: '',
        },
        propertyLocation: {
          location: '',
          error: false,
          errorDetail: '',
        },
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
        externalFilter: false,
      });
    }

    case PROPERTIES_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        properties: {
          list: [],
          error: false,
          errorDetail: '',
          version: 0,
        },
        propertyList: {
          list: [],
          error: false,
          errorDetail: '',
        },
        propertyLocation: {
          location: '',
          error: false,
          errorDetail: '',
        },
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROPERTIES_INMAP_FILTERED_SUCCESS: {
      return Object.assign({}, state, {
        properties: {
          list: action.properties,
          error: false,
          errorDetail: '',
          byRelevance: action.byRelevance,
          version: state.properties.version + 1,
        },
        filter: action.filter,
        externalFilter: false,
      });
    }

    case PROPERTIES_INMAP_FILTERED_ERROR: {
      return Object.assign({}, state, {
        properties: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
          version: state.properties.version,
        },
      });
    }

    case PROPERTIES_BY_DISTANCE_INMAP_FILTERED_SUCCESS: {
      return Object.assign({}, state, {
        properties: {
          list: action.properties,
          error: false,
          errorDetail: '',
          version: state.properties.version + 1,
        },
      });
    }

    case PROPERTIES_BY_DISTANCE_INMAP_FILTERED_ERROR: {
      return Object.assign({}, state, {
        properties: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
          version: state.properties.version,
        },
      });
    }

    case NEW_PROPERTY_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        propertyLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_PROPERTY_LOCATION_ERROR: {
      return Object.assign({}, state, {
        propertyLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROPERTIES_LIST_SUCCESS: {
      return Object.assign({}, state, {
        propertyList: {
          list: action.properties,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPERTIES_LIST_ERROR: {
      return Object.assign({}, state, {
        propertyList: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROPERTIES_EXPORTDATA_SUCCESS: {
      return Object.assign({}, state, {
        exportation: {
          message: action.message,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPERTIES_EXPORTDATA_ERROR: {
      return Object.assign({}, state, {
        exportation: {
          message: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROPERTIES_ACTIVE_BUSINESS_DATA: {
      return Object.assign({}, state, {
        activeBusiness: action.activeBusiness,
      });
    }

    case PROPERTIES_EXTERNAL_FILTER: {
      return Object.assign({}, state, {
        filter: action.filter,
        activeBusiness: action.activeBusiness,
        externalFilter: true,
      });
    }

    case UPLOAD_IMPORT_FILE_SUCCESS: {
      return Object.assign({}, state, {
        importStatus: {
          status: 'uploaded',
          error: false,
          errorDetail: '',
        },
      });
    }

    case UPLOAD_IMPORT_FILE_ERROR: {
      return Object.assign({}, state, {
        importStatus: {
          status: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case GET_IMPORT_STATUS_SUCCESS: {
      return Object.assign({}, state, {
        importStatus: {
          error: false,
          errorDetail: '',
          data: action.data,
        },
      });
    }

    case GET_IMPORT_STATUS_ERROR: {
      return Object.assign({}, state, {
        importStatus: {
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROPERTIES_FILTER: {
      return Object.assign({}, state, {
        filter: action.filter,
      });
    }

    default:
      return state;
  }
};

export default Properties;
