import React from 'react';
import { Card, Col, Form, InputNumber, Row } from 'antd';
import CheckboxButton from '../CheckboxButton';
import get from 'lodash/get';

const { Item: FormItem } = Form;

const FormDossierClientPrices = ({ data = {}, onChangeTotalPrice = () => {} }) => {
  return (
    <Card title='Precios para el Cliente (dossier)'>
      <Form layout='vertical'>
        <Row gutter={8} type='flex' justify='start' align='bottom'>
          <Col span={6}>
            <FormItem label='Precio mes'>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailPrice'
                name='monthlyPriceDossier'
                disabled
                value={get(data, 'monthlyPriceDossier.amount', '')}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label='Precio total'>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailTotalPrice'
                name='totalPriceDossier'
                disabled
                value={get(data, 'totalPriceDossier.amount', '')}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label='Fianza'>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailDeposit'
                name='deposit'
                disabled
                value={get(data, 'deposit.amount', '')}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={8} type='flex' justify='start' align='bottom'>
          <Col span={6}>
            <FormItem label='Precio mes (imp. incl)'>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailPriceWithTax'
                name='monthlyPriceWithTaxDossier'
                disabled
                value={get(data, 'monthlyPriceWithTaxDossier.amount', '')}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label='Precio total (imp. incl)'>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailTotalPriceWithTax'
                name='totalPriceWithTaxDossier'
                disabled
                value={get(data, 'totalPriceWithTaxDossier.amount', '')}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label='Gastos limpieza (imp. incl)'>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalCleaningChargesWithTax'
                name='cleaningChargesWithTax'
                disabled
                value={get(data, 'cleaningChargesWithTax.amount', '')}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem>
              <CheckboxButton
                borderStyle='proposalCheckboxActive'
                id='proposalShowTotalPrice'
                name='showTotalPrice'
                handleChange={e => onChangeTotalPrice(e.target.checked)}
                content='Mostrar precio total'
                value={get(data, 'showTotalPrice', false)}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default FormDossierClientPrices;
