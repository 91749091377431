import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Skeleton } from 'antd';
import ImageSelector from '../../components/ImageSelector/ImageSelector';
import ImageLoader from '../../components/ImageLoader/ImageLoader';
import get from 'lodash/get';

const NOOP = () => {};

const PropertyDetailsImages = ({ ...props }) => {
  const selectedPictures = get(props, 'data.pictures') ? [...props.data.pictures] : [];
  const propertyPictures = props.images;
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleImagesLoaded = images => {
    images = images.map((imageData, index) => {
      return {
        imageData,
        imageKey: propertyPictures[index],
      };
    });
    setImages(() => images);
    setLoading(false);
  };

  return (
    <>
      <ImageLoader
        s3bucket={get(props, 'data.bucketName')}
        imagesToLoad={propertyPictures}
        handleLoadComplete={handleImagesLoaded}
      />
      <Row gutter={8}>
        <Col span={12}>
          Imagen destacada
          <br />
          {loading ? (
            <Skeleton active />
          ) : (
            <ImageSelector
              imagesToRender={images}
              id='featuredImage'
              borderStyle='primaryCheckboxActive'
              // handleImageSelect={props.onSelectMainPicture}
              handleImageSelect={props.onFeaturedPictureChange}
              noPadding
              selectedImages={props.data.featuredPicturePath}
            />
          )}
        </Col>
        <Col span={12}>
          Imágenes del dossier
          <br />
          {loading ? (
            <Skeleton active />
          ) : (
            <ImageSelector
              imagesToRender={images}
              id='includeImage'
              borderStyle='primaryCheckboxActive'
              // handleImageSelect={props.onSelectImage}
              handleImageSelect={props.onPicturesChange}
              selectedImages={selectedPictures}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

PropertyDetailsImages.propTypes = {
  data: PropTypes.shape({
    featuredImageIndex: PropTypes.array,
    imagesSelected: PropTypes.array,
  }),
  images: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func,
};

PropertyDetailsImages.defaultProps = {
  data: {
    featuredImageIndex: [''],
    imagesSelected: [''],
  },
  images: [''],
  handleChange: NOOP,
};

export default PropertyDetailsImages;
