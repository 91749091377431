import { browserHistory } from 'react-router';
import AWS from 'aws-sdk/global';
import {
  MAIN_METASOURCE_SUCCESS,
  METASOURCE_STRINGS_ERROR,
  METASOURCE_STRINGS_SUCCESS,
  OPTION_LABELS_SUCCESS,
  PARENT_VALUES_SUCCESS,
  RENTAL_REQUEST_ACTIVE_STAGES_FOR_FILTER,
  USER_INFORMATION_SUCCESS,
} from '../../shared/actionTypes';
import PreloadApi from './PreloadApi';
import { loadProvidersMetasource } from '../Providers/ProvidersActions';
import { loadPropertiesMetasource } from '../Properties/PropertiesActions';
import { loadCompaniesMetasource } from '../Companies/CompaniesActions';
import { loadContactsMetasource } from '../Contacts/ContactsActions';
import { loadDealsMetasource } from '../Deals/DealsActions';
import { loadRequestsMetasource } from '../Requests/RequestsActions';
import { loadProposalsMetasource } from '../Proposals/ProposalsActions';
import { loadInvoicesMetasource } from '../Invoices/InvoicesActions';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getArrays, getObjects, getValues, removeDuplicates } from '../../shared/utils/jsonUtils';
import { loadPmasMetasource } from '../Pmas/PmasActions';

export const metasourceStringsSuccess = metasourceStrings => ({
  type: METASOURCE_STRINGS_SUCCESS,
  metasourceStrings,
});

export const metasourceStringsError = errorMessage => ({
  type: METASOURCE_STRINGS_ERROR,
  errorDetail: errorMessage,
});

export const optionLabelsSuccess = optionLabels => ({
  type: OPTION_LABELS_SUCCESS,
  optionLabels,
});

export const parentValuesSuccess = parentValues => ({
  type: PARENT_VALUES_SUCCESS,
  parentValues,
});

export const getMainMetasourceSuccess = mainMetasource => ({
  type: MAIN_METASOURCE_SUCCESS,
  mainMetasource,
});

export const getCurrentUserInformationSuccess = (username, awsCredentials, s3Endpoint) => ({
  type: USER_INFORMATION_SUCCESS,
  username,
  awsCredentials,
  s3Endpoint,
});

export const rentalRequestActiveStagesSuccess = rentalRequestActiveStagesForFilteredCall => ({
  type: RENTAL_REQUEST_ACTIVE_STAGES_FOR_FILTER,
  rentalRequestActiveStagesForFilteredCall,
});

const getMetasourceStrings = json => {
  const metasourceStrings = {};
  const hateoasLinks = getObjects(json, '(hateoasLink)', '');
  const hateoasUniqueLinks = removeDuplicates(hateoasLinks);

  for (let i = 0; i < hateoasUniqueLinks.length; i++) {
    const hateoasLink = hateoasUniqueLinks[i];
    const objectKeys = Object.keys(hateoasLink);
    const stringsHateoas = getValues(hateoasLink, '(hateoasLink)');

    for (let j = 0; j < stringsHateoas.length; j++) {
      if (j < objectKeys.length) {
        metasourceStrings[stringsHateoas[j]] = objectKeys[j];
      }
    }
  }

  return metasourceStrings;
};

const getValuesFromJSON = (json, tag) => {
  const values = getArrays(json, tag);

  return removeDuplicates(values);
};

const getSingleDefinitionAttributeValue = (json, attributeName) => {
  const attribute = getValues(json, attributeName);

  return removeDuplicates(attribute)[0];
};

export const getMainMetasource = () =>
  function(dispatch) {
    return PreloadApi.getMainMetasource()
      .then(response => {
        dispatch(getMainMetasourceSuccess(response.data._links));
        dispatch(loadProvidersMetasource());
        dispatch(loadPropertiesMetasource());
        dispatch(loadCompaniesMetasource());
        dispatch(loadContactsMetasource());
        dispatch(loadDealsMetasource());
        dispatch(loadRequestsMetasource());
        dispatch(loadProposalsMetasource());
        dispatch(loadInvoicesMetasource());
        dispatch(loadPmasMetasource());
      })
      .catch(error => {
        console.error(error);
      });
  };

export const getPublicMainMetasource = () =>
  function(dispatch) {
    return PreloadApi.getPublicMainMetasource()
      .then(response => {
        dispatch(getMainMetasourceSuccess(response.data._links));
        dispatch(loadProposalsMetasource());
      })
      .catch(error => {
        console.error(error);
      });
  };

export const getCurrentUserInformation = () =>
  function(dispatch) {
    return PreloadApi.getCurrentUserInformation()
      .then(response => {
        const awsCredentials = new AWS.Credentials(response.data.keyId, response.data.secretKey);

        dispatch(
          getCurrentUserInformationSuccess(
            response.data.username,
            awsCredentials,
            response.data.s3Endpoint
          )
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

export const preloadApp = () =>
  function(dispatch) {
    return PreloadApi.preloadApp()
      .then(response => {
        const metasourceStrings = getMetasourceStrings(response.data);
        const optionLabels = getValuesFromJSON(response.data, '(optionLabels_ES)');
        const parentValues = getValuesFromJSON(response.data, '(parent_values)');
        const rentalRequestActiveStagesForFilteredCall = getSingleDefinitionAttributeValue(
          response.data,
          '(rentalRequestActiveStagesForFilter)'
        );

        dispatch(metasourceStringsSuccess(metasourceStrings));
        dispatch(optionLabelsSuccess(optionLabels));
        dispatch(parentValuesSuccess(parentValues));
        dispatch(getMainMetasource());
        dispatch(getCurrentUserInformation());
        dispatch(rentalRequestActiveStagesSuccess(rentalRequestActiveStagesForFilteredCall));
        //browserHistory.push('/');
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceStringsError(extractErrorMessage(error)));
        browserHistory.push('/login');
      });
  };

export const preloadPublicApp = () =>
  function(dispatch) {
    return PreloadApi.preloadPublicApp()
      .then(response => {
        const metasourceStrings = getMetasourceStrings(response.data);
        const optionLabels = getValuesFromJSON(response.data, '(optionLabels_ES)');
        const parentValues = getValuesFromJSON(response.data, '(parent_values)');

        dispatch(metasourceStringsSuccess(metasourceStrings));
        dispatch(optionLabelsSuccess(optionLabels));
        dispatch(parentValuesSuccess(parentValues));
        dispatch(getPublicMainMetasource());
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceStringsError(extractErrorMessage(error)));
      });
  };

export const preloadPublicForm = () =>
  function(dispatch) {
    let metasourceStrings;

    return PreloadApi.preloadPublicApp()
      .then(response => {
        metasourceStrings = getMetasourceStrings(response.data);
      })
      .then(() => dispatch(metasourceStringsSuccess(metasourceStrings)))
      .then(() => dispatch(getPublicMainMetasource()))
      .catch(error => {
        console.error(error);
        dispatch(metasourceStringsError(extractErrorMessage(error)));
      });
  };

// export const preloadPublicForm = () => {
//   return dispatch => {
//     let metasourceStrings;

//     PreloadApi.preloadPublicApp()
//       .then(response => {
//         metasourceStrings = getMetasourceStrings(response.data);
//       })
//       .then(() => dispatch(metasourceStringsSuccess(metasourceStrings)))
//       .then(() => dispatch(getPublicMainMetasource()))
//       .catch(error => {
//         console.error(error);
//         dispatch(metasourceStringsError(extractErrorMessage(error)));
//       });
//   };
// };
