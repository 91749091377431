import { clone } from 'lodash-es';
import { getAxiosInstance } from '../../shared/utils/apiUtils';

class DealDetailsApi {
  static getMetasource(url, id) {
    return this.doGet(url + '/' + id);
  }

  static getDealDetailsData(url) {
    return this.doGet(url);
  }

  static saveDealDetailsData(url, dealDetails, etag) {
    const axiosInstance = getAxiosInstance();

    // Back returns dealDetails.proposals with {proposal, links} format,
    // but does not accept this format for saving. Needed to extract links
    // before calling the endpoint for save, in a cloned object only for
    // this purpose
    const newDealDetails = clone(dealDetails);
    newDealDetails.proposals = dealDetails.proposals.map(el => el.proposal);

    // if (
    //   dealDetails.proposals &&
    //   Object.keys(dealDetails.proposals).length > 0 &&
    //   'proposal' in dealDetails.proposals[0]
    // ) {
    //   dealDetails.proposals = dealDetails.proposals.map(el => el.proposal);
    // }

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.post(url, newDealDetails);
  }

  static saveDealDetailsDataFromRequest(url, dealDetails, etag) {
    const axiosInstance = getAxiosInstance();

    if (
      dealDetails.proposals &&
      Object.keys(dealDetails.proposals).length > 0 &&
      'proposal' in dealDetails.proposals[0]
    ) {
      dealDetails.proposals = dealDetails.proposals.map(el => el.proposal);
    }

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.post(url, dealDetails);
  }

  static deleteDealDetailsData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.delete(url);
  }

  static followUp(url, stage) {
    return getAxiosInstance().put(url, { value: stage });
  }

  static doGet(url) {
    return getAxiosInstance().get(url);
  }

  static doPost(url) {
    return getAxiosInstance().post(url);
  }

  static doPostWithData(url, data) {
    return getAxiosInstance().post(url, data);
  }

  static doPut(url, data) {
    return getAxiosInstance().put(url, data);
  }
}

export default DealDetailsApi;
