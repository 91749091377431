import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import { Input } from 'antd';

import { formatPlaceToWorldWideAddress } from '../../shared/utils/geoUtils';

class HomyAddress extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onSelectedAddress: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.shape({}),
  };

  static defaultProps = {
    isDisabled: false,
    label: 'Dirección',
    name: 'address',
    onSelectedAddress: null,
    placeholder: 'Ejemplo: Paseo de la Castellana, 35, Madrid, España',
    value: {},
  };

  // Solo para versiones superiores a React y React-DOM ^16.3.1
  // static getDerivedStateFromProps(props, state) {
  //   if (props.value !== state.value) {
  //     return {
  //       worldWideAddress: props.value,
  //     };
  //   }
  //   return null;
  // }

  constructor(props) {
    super(props);
    this.addressElement = {};
    this.state = {
      worldWideAddress: this.props.value && this.props.value.formattedAddress,
    };

    this.geocodeAddressForSpain = this.geocodeAddressForSpain.bind(this);
    this.geocodeAddressGlobal = this.geocodeAddressGlobal.bind(this);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
  }

  handleChange = e => {
    const formattedAddress = e.target.value;

    if (formattedAddress) {
      const address = this.state.worldWideAddress || {};

      this.setState({
        worldWideAddress: {
          ...address,
          formattedAddress,
        },
      });
    } else {
      this.setState({
        worldWideAddress: {
          formattedAddress: '',
        },
      });
    }
  };

  handleScriptLoad() {
    const { google } = window;

    if (!google) {
      return false;
    }

    const context = this;
    const node = this.addressElement[this.props.id]
      ? this.addressElement[this.props.id].input
      : null;

    if (!node) {
      console.error('There is no node for InputAddress!');

      return;
    }

    const geocoder = new google.maps.Geocoder(node);

    google.maps.event.addDomListener(node, 'focusout', function() {
      context.geocodeAddressForSpain(geocoder, node);
    });
  }

  geocodeAddressForSpain(geocoder, node) {
    const context = this;

    if (node.value) {
      geocoder.geocode(
        { address: node.value, componentRestrictions: { country: 'ES' } },
        (results, status) => {
          if (status === 'OK') {
            const worldWideAddress = formatPlaceToWorldWideAddress(results[0]);

            if (!worldWideAddress.administrativeAreaLevel1) {
              this.geocodeAddressGlobal(geocoder, node);

              return false;
            }

            context.props.onSelectedAddress(worldWideAddress);
            node.value = worldWideAddress && worldWideAddress.formattedAddress;
            this.setState({ worldWideAddress: { ...worldWideAddress } });
          } else {
            this.geocodeAddressGlobal(geocoder, node);
          }
        }
      );
    } else {
      node.value = null;
      this.setState({ worldWideAddress: {} });
    }
  }

  geocodeAddressGlobal(geocoder, node) {
    const context = this;

    geocoder.geocode({ address: node.value }, (results, status) => {
      if (status === 'OK') {
        const worldWideAddress = formatPlaceToWorldWideAddress(results[0]);

        context.props.onSelectedAddress(worldWideAddress);
        node.value = worldWideAddress && worldWideAddress.formattedAddress;
        this.setState({ worldWideAddress: { ...worldWideAddress } });
      } else {
        context.props.onSelectedAddress(node.value);
        console.error('Error a la hora de obtener la dirección.', results, status);

        this.setState({ worldWideAddress: { formattedAddress: node.value } });
      }
    });
  }

  render() {
    const { name, label, id, placeholder, isDisabled, value } = this.props;

    return (
      <div>
        <Script
          onLoad={this.handleScriptLoad}
          url='https://maps.googleapis.com/maps/api/js?language=es&key=AIzaSyD2jCPiuEEiN9N-bvhiy3_7hXQqKfvCmNA&libraries=places&v=3.35.7'
        />
        <label htmlFor={`googleMapsDOMElement-${id}`}>
          {label}
          <Input
            id={`googleMapsDOMElement-${id}`}
            autoComplete='address-level-1'
            disabled={isDisabled}
            name={name}
            onChange={this.handleChange}
            placeholder={placeholder}
            ref={el => {
              this.addressElement[id] = el;
            }}
            value={
              this.state.worldWideAddress
                ? this.state.worldWideAddress.formattedAddress
                : value && value.formattedAddress
            }
          />
        </label>
      </div>
    );
  }
}

export default HomyAddress;
