import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { Row, Col, Input, Modal, Button } from 'antd';
import HomySelect from '../../../components/HomyComponents/HomySelect';
import TableDataPmasApproximate from '../../../components/TableDataPmasApproximate/TableDataPmasApproximate';
import ContactInfo from './ContactInfo';
import ClientInfo from './ClientInfo';
import OpportunityInfo from './OpportunityInfo';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { calculateDealScore } from '../../../modules/Deals/DealsActions';
import { getOptionsForSelect, openNotificationWithIcon } from '../../../shared/utils/appUtils';
import RequestsApi from '../../../modules/Requests/RequestsApi';
import { ENDPOINT, PARSE_DATE_FORMAT, DATE_FORMAT } from '../../../shared/appConfig';
import { browserHistory } from 'react-router';
import {
  createNewDealFromRequest,
  createNewDeal,
} from '../../../modules/DealDetails/DealDetailsActions';
import { areValidDates } from '../../../shared/utils/dateUtils';
import { getPmaApproximate } from '../../../modules/Pmas/PmasActions';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import QuestionsInfo from './QuestionsInfo';
import api from '../../../shared/utils/api';
import CompanyDetailsApi from '../../../modules/CompanyDetails/CompanyDetailsApi';
import ContactDetailsApi from '../../../modules/ContactDetails/ContactDetailsApi';
import InformationBlock from '../../../components/InformationBlock/InformationBlock';

const { TextArea } = Input;

const shouldSaveCompanyInfo = (discardReason, company, contact) => {
  if (
    !get(company, 'companyType') ||
    !get(company, 'baseContact.name') ||
    !get(contact, 'name') ||
    !get(contact, 'email')
  ) {
    return false;
  }

  const discardReasons = ['INDIVIDUAL', 'PROPERTY', 'PROVIDER', 'DUPLICATE'];
  return !discardReasons.includes(discardReason);
};

const handleDiscard = async (
  reasonsOfDiscard = {},
  id = '',
  company,
  contact,
  qualifyingQuestions,
  currentUserName
) => {
  const { comment = '', reason = '' } = reasonsOfDiscard;

  if (!isEmpty(comment) && !isEmpty(reason)) {
    let error = false;
    if (company && shouldSaveCompanyInfo(reason, company, contact)) {
      // Some additional data necessary to save company/contact
      company.updatedBy = currentUserName;
      company.baseContact.updatedBy = currentUserName;
      if (company.id !== contact.id && company.baseContact.email === contact.email) {
        company.baseContact.email2 = company.baseContact.email;
        delete company.baseContact.email;
      }
      try {
        let promisesArray;
        if (company.id) {
          if (!contact.relatedCompany) {
            contact.relatedCompany = {
              idOfContact: company.id,
              roles: ['UNKNOWN'],
            };
          }
          // We need to save contact data first, beacuse saveContactData endpoint replace
          // ALL the data for the contact, and when de COMPANY and CONTACT are the same
          // (because the CONTACT is the baseContact for company), we would be erasing
          // company data
          await ContactDetailsApi.saveContactData(contact, contact.etag);
          // If company exists, we can make the remaining requests in pararell
          promisesArray = [
            CompanyDetailsApi.saveCompanyData(company),
            CompanyDetailsApi.saveQualifyingQuestions(company.id, qualifyingQuestions),
          ];
        } else {
          // If company doesn't exists, we must to save the company first to retrieve the id
          const companyResult = await CompanyDetailsApi.saveCompanyData(company);
          const companyId = companyResult.id;
          contact.relatedCompany = {
            idOfContact: companyId,
            roles: ['UNKNOWN'],
          };
          contact.contactType = 'PERSON';
          promisesArray = [
            ContactDetailsApi.saveContactData(contact, contact.etag),
            CompanyDetailsApi.saveQualifyingQuestions(companyId, qualifyingQuestions),
          ];
        }
        // eslint-disable-next-line no-undef
        await Promise.all(promisesArray).catch(error => {
          // log that I have an error, return the entire array;
          console.error('Error saving company data while discarding the request: ', error);
          error = true;
        });
      } catch (e) {
        console.error('Error saving company data while discarding the request: ', error);
        error = true;
      }
    }

    RequestsApi._doPut(`${ENDPOINT}/request/${id}/discard`, {
      reason,
      comment,
    })
      .then(() => {
        const message =
          'Solicitud descartada correctamente' +
          (error ? ', pero se ha producido un error al guardar la información de la empresa' : '');
        openNotificationWithIcon(error ? 'warning' : 'success', 'Todo OK :)', message);
      })
      .then(() => {
        browserHistory.push('/requests');
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de descartar la Solicitud...
    ${err}`
        );
      });
  } else {
    openNotificationWithIcon(
      'error',
      'Opps!! :(',
      'Selecciona un motivo e introduce un comentario para descartar la Solicitud!'
    );
  }
};

const saveOpportunityBody = (requestType = '', hsagent = {}) => {
  if (requestType === 'B' && !isEmpty(get(hsagent, 'username'))) {
    return '¿Estás seguro de Guardar esta nueva Oportunidad? Se enviará una confirmación de los datos al Cliente, en nombre del Gestor Asignado.\nAdemás la oportunidad pasará a la etapa "Búsqueda de Inmuebles".';
  } else if (!isEmpty(get(hsagent, 'username'))) {
    return '¿Estás seguro de Guardar esta nueva Oportunidad? Se enviará una confirmación de los datos al Cliente, en nombre del Gestor Asignado.';
  } else {
    return '¿Estás seguro de Guardar esta nueva Oportunidad?';
  }
};

const CreateOpportunity = () => {
  const [deal, setDeal] = useState({
    questions: {
      travelManagement: 'NSNC',
      frequency: 'NS/NC',
    },
  });
  const [showDiscardReasons, setShowDiscardReasons] = useState(false);
  const [discardReasons, setDiscardReasons] = useState({
    comment: '',
    reason: undefined,
  });
  const [submitting, setSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [isEditable, setIsEditable] = useState(true);

  const dispatch = useDispatch();

  const {
    dealLocation,
    invoiceOptions,
    optionLabels,
    request,
    score,
    currentUserName,
  } = useSelector(
    state => ({
      dealLocation: get(state, 'Deals.dealLocation.location'),
      invoiceOptions: state.Companies.invoiceMarketingSourceMediumOptions.options.data,
      optionLabels: state.Preload.optionLabels,
      request: state.Deals.newDealFromRequestData,
      score: state.Deals.score.dealScore,
      currentUserName: state.Preload.currentUserInformation.username,
    }),
    shallowEqual
  );

  const debounceScore = useCallback(
    debounce(score => {
      dispatch(calculateDealScore(score));
    }, 1800),
    [dispatch]
  );

  useEffect(() => {
    if (!isEmpty(request) && window.location.href.includes('newfromrequest')) {
      setDeal({
        address: request.address || {},
        hsagent: { username: request.hsAgent },
        contactDetails:
          !isNil(request.contactId) && !isEmpty(request.contactId)
            ? {}
            : {
                name: request.contactName,
                lastOTradeName: request.contactLastName,
                phoneNumber: '',
                mobilePhoneNumber: request.phoneNumber,
                email: request.contactEmail,
              },
        companyDetails:
          !isNil(request.clientId) && !isEmpty(request.clientId)
            ? {}
            : {
                baseContact: {
                  name: request.companyName,
                },
                // marketingSourceMedium: request.marketingSourceMedium,
              },
        numberOfPeople: request.pax,
        companyId: request.clientId,
        contactId: request.contactId,
        rentalStartDate:
          request.checkinDate && moment(request.checkinDate, 'DD/MM/YYYY', true).isValid()
            ? moment(request.checkinDate, DATE_FORMAT).format(PARSE_DATE_FORMAT)
            : null,
        rentalEndDate:
          request.checkoutDate && moment(request.checkoutDate, 'DD/MM/YYYY', true).isValid()
            ? moment(request.checkoutDate, DATE_FORMAT).format(PARSE_DATE_FORMAT)
            : null,
        dealDescription: (function() {
          let descrip = '';
          if (request.checkinDate && !moment(request.checkinDate, 'DD/MM/YYYY', true).isValid()) {
            descrip = `Fecha de entrada: ${request.checkinDate}\n`;
          }

          if (request.checkoutDate && !moment(request.checkoutDate, 'DD/MM/YYYY', true).isValid()) {
            descrip = `${descrip}Fecha de salida: ${request.checkoutDate}\n`;
          }

          if (request.city) {
            descrip = `${descrip}Ciudad: ${request.city}\n`;
          }

          if (request.landingPageUrl) {
            descrip = `${descrip}Landing: ${
              request.landingPageUrl.includes('utm')
                ? request.landingPageUrl.substr(0, request.landingPageUrl.indexOf('utm'))
                : request.landingPageUrl
            }\n`;
          }

          if (request.observations) {
            descrip = `${descrip}${request.observations}\n`;
          }

          return descrip;
        })(),
        generalConditionsDate: request.generalConditionsDate,
        generalConditionsOrigin: request.generalConditionsOrigin,
        privacyPolicyDate: request.privacyPolicyDate,
        privacyPolicyOrigin: request.privacyPolicyOrigin,
        marketingSourceMedium: request.marketingSourceMedium,
      });
    }
  }, [request, dispatch]);

  useEffect(() => {
    const getContact = async id => {
      try {
        const res = await api.getDetailData('contact', id);
        // Saving version (etag) of the contact for let to save the contact
        // when discarding the request
        const etag = res.headers.get('etag');
        let contact = get(res, 'json.contact');

        const roles = get(contact, 'relatedCompany.roles', []);
        if (!isEmpty(roles)) {
          const isTravelManager = includes(roles, 'TRAVEL_MANAGER');

          contact = {
            ...contact,
            isTravelManager,
            etag,
          };
        }

        if (!isEmpty(contact)) {
          setDeal(prev => ({ ...prev, contactDetails: contact }));
        }
      } catch (e) {
        console.error('Something went wrong', e);
      }
    };

    if (deal.contactId) {
      getContact(deal.contactId);
    }
  }, [deal.contactId]);

  useEffect(() => {
    const getCompany = async id => {
      try {
        const res = await api.getDetailData('company', id);
        const company = get(res, 'json.company');

        if (!isEmpty(company)) {
          setDeal(prev => ({
            ...prev,
            marketingSourceMedium:
              !isNil(deal.marketingSourceMedium) && !isEmpty(deal.marketingSourceMedium)
                ? deal.marketingSourceMedium
                : get(company, 'marketingSourceMedium', ''),
            companyDetails: company,
          }));

          const questions = await CompanyDetailsApi.getQualifyingQuestions(deal.companyId);
          if (!isEmpty(questions)) {
            setDeal(prev => ({
              ...prev,
              questions: {
                ...prev.questions,
                ...questions,
              },
            }));
          }
        }
      } catch (e) {
        console.error(
          'Something went wrong. The selected resource is not a Company nor Contact',
          e
        );
      }
    };

    if (deal.companyId) {
      getCompany(deal.companyId);
    }
  }, [deal.companyId, deal.isContact]);

  useEffect(() => {
    const score = {
      company: deal.companyDetails,
      contact: deal.contactDetails,
      budget: deal.budgetSelect,
      area: deal.area,
      entryDate: deal.entryDate,
      numberOfAdvanceDays: deal.numberOfAdvanceDays,
      rentalStartDate: deal.rentalStartDate,
      rentalEndDate: deal.rentalEndDate,
      duration: deal.rentalDuration,
      zone: deal.zone,
      allreadyHosted: deal.allreadyHosted,
      bedsNum: deal.numberOfPeople,
      guestsNumber: deal.guestsNumber,
      roomsNum: deal.numberOfRooms,
      contractAndInvoice: deal.invoiceRequired ? 'CONTRACT_AND_INVOICE' : null,
    };

    debounceScore(score);
  }, [deal, debounceScore]);

  useEffect(() => {
    if (!isEmpty(deal.address)) {
      const {
        locality = '',
        administrativeAreaLevel2: areaLevel2 = '',
        postalCode = '',
      } = deal.address;

      if (areaLevel2 && locality) {
        const start = deal.rentalStartDate;
        const end = deal.rentalEndDate;

        const dateStart = start ? moment(start).valueOf() : null;
        const dateEnd = end ? moment(end).valueOf() : null;

        if (dateStart && dateEnd && areValidDates(dateStart, dateEnd)) {
          dispatch(getPmaApproximate(locality, postalCode, dateStart, dateEnd));
        }
      }
    }
  }, [deal.address, deal.rentalStartDate, deal.rentalEndDate, dispatch]);

  const opportunityScore = get(score, 'dealScore.score');

  const checkFields = () => {
    const elementRequired = Array.from(document.querySelectorAll('[required]')).find(el =>
      isEmpty(el.value)
    );

    if (!isEmpty(elementRequired)) {
      return false;
    }

    const startDate = get(deal, 'rentalStartDate', '');
    const endDate = get(deal, 'rentalEndDate', '');

    if (!get(deal, 'companyDetails.companyType')) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        '¡Es necesario indicar si el Cliente es una persona Física o Jurídica!'
      );

      return false;
    }

    if (!isEmpty(startDate) && !isEmpty(endDate) && !areValidDates(startDate, endDate)) {
      openNotificationWithIcon(
        'error',
        'Meeeeeec =(',
        'Hay un problema con las fechas introducidas. Por favor, revísalo.'
      );

      return false;
    }

    const contactEmail = get(deal, 'contactDetails.email');
    const clientEmail = get(deal, 'companyDetails.baseContact.email');
    if (
      clientEmail &&
      contactEmail &&
      get(deal, 'companyDetails.id') !== get(deal, 'contactDetails.id') &&
      clientEmail === contactEmail
    ) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        'El email del cliente y del contacto no puede ser el mismo'
      );

      return false;
    }

    if (!clientEmail && !contactEmail) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        'Es necesario al menos un correo electrónico, ya sea en el Cliente, en el Contacto, o en ambos!'
      );

      return false;
    }

    if (isEmpty(deal.address)) {
      openNotificationWithIcon('error', 'Opps!! :(', 'Introduce una ubicación!');
      return false;
    }

    const marketingSourceMedium = get(deal, 'marketingSourceMedium', '');
    const companyNif = get(deal, 'companyDetails.baseContact.identificationCode', '');
    if (marketingSourceMedium === 'OUTBOUND_SALES' && isEmpty(companyNif)) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        'Tienes que indicar el NIF del cliente si seleccionas Outbound Sales como Fuente/Medio'
      );
      return false;
    }

    setShowModal(true);
  };

  const handleQQChange = (name, value) => {
    setDeal(prev => ({
      ...prev,
      questions: {
        ...prev.questions,
        [name]: value ? value : '',
      },
    }));
  };

  const save = async () => {
    setSubmitting(true);

    const dealToSave = {
      ...deal,
      questions: deal.questions,
      contactsOfCompany: [
        {
          contact: deal.contactDetails,
          roles: [],
        },
      ],
      hsagent: {
        username: get(deal, 'hsagent.username') || '',
      },
      rentalRequestType: get(score, 'dealScore.rating'),
      otherRequestInfo: {
        entryDate: deal.entryDate,
        rentalDuration: deal.rentalDuration,
        area: deal.area,
        guestsNumber: deal.guestsNumber,
        budget: deal.budgetSelect,
        allreadyHosted: deal.allreadyHosted,
        transport: deal.transport,
        score: get(score, 'dealScore.score') || '',
      },
    };

    const requestId = get(request, 'id');

    window.location.href.includes('newfromrequest')
      ? dispatch(createNewDealFromRequest(requestId, dealToSave))
      : dispatch(createNewDeal(dealLocation, dealToSave));

    setSubmitting(false);
  };

  const isRequest = !isEmpty(request) && window.location.href.includes('newfromrequest');

  return (
    <>
      {!isEmpty(request) && (
        <Modal
          visible={showDiscardReasons}
          title='Descartar Solicitud'
          onCancel={() => setShowDiscardReasons(false)}
          onOk={() =>
            handleDiscard(
              discardReasons,
              request.id,
              deal.companyDetails,
              deal.contactDetails,
              deal.questions,
              currentUserName
            )
          }
          okText='Descartar'
        >
          <HomySelect
            label='Elegir el motivo del descarte'
            name='rejectRequest'
            placeholder='Selecciona el motivo'
            onChange={value =>
              setDiscardReasons(prev => ({
                ...prev,
                reason: value,
              }))
            }
            value={discardReasons.reason}
            options={
              !isEmpty(optionLabels) ? getOptionsForSelect(optionLabels, 'RequestsType') : null
            }
            showArrow
            showSearch
            allowClear
            style={{ marginTop: 10 }}
          />
          <TextArea
            placeholder='Agrega un breve comentario sobre el motivo del descarte (140 caracteres máximo)'
            maxLength={140}
            autosize={{ minRows: 2, maxRows: 6 }}
            onChange={e => {
              e.persist();
              setDiscardReasons(prev => ({
                ...prev,
                comment: e.target.value,
              }));
            }}
            value={discardReasons.comment}
            required
            style={{ marginTop: 10 }}
          />
          {shouldSaveCompanyInfo(discardReasons.reason) && (
            <ValidationInfo
              companyDetails={deal.companyDetails}
              contactDetails={deal.contactDetails}
            />
          )}
        </Modal>
      )}
      <Modal
        visible={showModal}
        title='Guardar nueva Oportunidad'
        onCancel={() => setShowModal(false)}
        onOk={() => save()}
        okText='Guardar'
      >
        {saveOpportunityBody(deal.rentalRequestType, deal.hsagent)}
      </Modal>
      <form
        style={{ margin: '10px 10px 0 10px' }}
        onSubmit={e => {
          e.preventDefault();
          checkFields();
        }}
      >
        <Col>
          <Row
            className={`mb-2 ${
              !request.clientId ? 'primera-solicitud-bg' : 'historico-oportunidad-bg'
            }`}
          >
            <Col>
              <div className='clasificacion-oportunidad-cliente px-2'>
                <div className='flex-container-info-historico pr-2'>
                  <span
                    className={!request.clientId ? 'first-request-styles' : ''}
                    style={{ fontSize: '14px', fontWeight: 'bold' }}
                  >
                    {!request.clientId
                      ? 'Primera oportunidad'
                      : request.hasPreviousAcceptedProposals
                      ? 'Oportunidad recurrente'
                      : 'Oportunidad 2º tiro'}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <ClientInfo
                deal={deal}
                setDeal={setDeal}
                options={optionLabels}
                invoiceOptions={invoiceOptions}
                // isEditable={isEditable}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <ContactInfo deal={deal} setDeal={setDeal} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className='border-component p-2'>
                <Row>
                  <Col>
                    <label className='mt-1'>
                      <b className='title-component'>Qualifiying Questions</b>
                    </label>
                    <div style={{ marginBottom: 10 }} />
                  </Col>
                </Row>
                <QuestionsInfo questions={deal.questions} handleChange={handleQQChange} />
              </div>
            </Col>
          </Row>
          <Row gutter={20} className='mt-2'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingBottom: '10px' }}>
              <OpportunityInfo
                deal={deal}
                setDeal={setDeal}
                options={optionLabels}
                rating={get(score, 'dealScore.rating') || 'B'}
                score={opportunityScore}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={{ span: isRequest ? 8 : 7, offset: 17 }}
              xl={{ span: isRequest ? 8 : 7, offset: 17 }}
              style={{ padding: 30 }}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={isRequest ? 12 : 24} xl={isRequest ? 10 : 24}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    onClick={() => checkFields()}
                    disabled={submitting}
                    style={{ width: '100%' }}
                  >
                    Guardar
                  </Button>
                </Col>
                {isRequest && (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={{ span: 11, offset: 1 }}
                    xl={{ span: 11, offset: 1 }}
                  >
                    <Button
                      className='more-filters'
                      onClick={() => setShowDiscardReasons(true)}
                      disabled={submitting}
                      style={{ width: '100%' }}
                    >
                      Descartar solicitud
                    </Button>
                  </Col>
                )}
                <br />
                <Col span={24} style={{ margin: 10 }}>
                  <TableDataPmasApproximate
                    id='tableDataPmasHomybrain'
                    showTitle='Precio Medio de Alquiler'
                    showHomybrain
                    showExternal={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </form>
      <style jsx>
        {`
          .border-component {
            border: 1px solid #d1d4d7;
            min-height: 488px;
          }

          .title-component {
            color: #000000;
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: 600;
          }
          .clasificacion-oportunidad-cliente {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            align-items: center;
            height: 38px;
          }
          .historico-oportunidad-bg {
            background-color: #fcedd1;
          }
          .primera-solicitud-bg {
            border: 1px solid #3b859d;
            border-left: 8px solid #3b859d;
          }
          .flex-container-info-historico {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 1190px;
            align-items: center;
          }
          .first-request-styles {
            color: #3b859d;
          }
        `}
      </style>
    </>
  );
};

const ValidationInfo = ({ companyDetails, contactDetails }) => {
  let message =
    'Si no introduces estos campos obligatorios, no se guardará la información del cliente cuando descartes la solicitud<br/>';
  let fields = '';
  fields += isEmpty(companyDetails.companyType) ? '- Tipo de persona<br/>' : '';
  fields += isEmpty(companyDetails.baseContact.name) ? '- Nombre del cliente<br/>' : '';
  fields += isEmpty(contactDetails.name) ? '- Nombre del contacto<br/>' : '';
  fields += isEmpty(contactDetails.email) ? '- Email del contacto' : '';

  return fields !== '' ? (
    <>
      <br />
      <br />
      <InformationBlock label={message + fields} />
    </>
  ) : (
    <></>
  );
};

export default CreateOpportunity;
// Really sorry for implementing the things like this and using window.location.href to destinguish between Request and Opportunity.
// It was the only way for a fast delivery. Please, remove Redux from Brain. It only makes it worse.
