import {
  CONTACTS_LIST_ERROR,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_METASOURCE_ERROR,
  CONTACTS_METASOURCE_SUCCESS,
  CONTACTS_TABLEDATA_ERROR,
  CONTACTS_TABLEDATA_FILTER,
  CONTACTS_TABLEDATA_SUCCESS,
  NEW_CONTACT_CREATE_ERROR,
  NEW_CONTACT_CREATE_SUCCESS,
  NEW_CONTACT_LOCATION_ERROR,
  NEW_CONTACT_LOCATION_SUCCESS,
} from '../../shared/actionTypes';
import ContactsApi from './ContactsApi';
import {
  createQueryString,
  extractErrorMessage,
  getUrlBase,
  parseQueryString,
} from '../../shared/utils/apiUtils';
import { getMetasourceLink, resolveParams } from '../../shared/utils/appUtils';
import {
  getProviderContactsData,
  providerContactSubmitSuccess,
} from '../ProviderDetails/ProviderDetailsActions';
import {
  companyContactSubmitSuccess,
  getCompanyContactsData,
} from '../CompanyDetails/CompanyDetailsActions';

export const metasourceSuccess = metasourceLinks => ({
  type: CONTACTS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: CONTACTS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const filterTableDataContacts = filterCriteria => ({
  type: CONTACTS_TABLEDATA_FILTER,
  filterCriteria,
});

export const tableDataSuccess = (tableData, totalCount) => ({
  type: CONTACTS_TABLEDATA_SUCCESS,
  tableData,
  totalCount,
});

export const tablaDataError = errorMessage => ({
  type: CONTACTS_TABLEDATA_ERROR,
  errorDetail: errorMessage,
});

export const newContactLocationSuccess = location => ({
  type: NEW_CONTACT_LOCATION_SUCCESS,
  location,
});

export const newContactLocationError = errorMessage => ({
  type: NEW_CONTACT_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const createNewContactSuccess = () => ({
  type: NEW_CONTACT_CREATE_SUCCESS,
});

export const createNewContactError = errorMessage => ({
  type: NEW_CONTACT_CREATE_ERROR,
  createNewContactErrorDetails: errorMessage,
});

export const contactsListSuccess = (contactsList, totalCount, componentId) => ({
  type: CONTACTS_LIST_SUCCESS,
  contactsList,
  totalCount,
  componentId,
});

export const resetContactsList = () =>
  function(dispatch) {
    dispatch(contactsListSuccess([], 0, null));
  };

export const contactsListError = errorDetail => ({
  type: CONTACTS_LIST_ERROR,
  errorDetail,
});

export const getContactsTableData = (purl = null) =>
  function(dispatch, getState) {
    const { metasource } = getState().Contacts;
    const { metasourceStrings } = getState().Preload;
    let url = purl;

    if (!url) {
      url = getMetasourceLink(
        metasource,
        metasourceStrings,
        'CONTACT_MAIN_METASOURCE_CONTACTS_PAGINATED'
      );
      const urlParsed = parseQueryString(url.href);
      const urlBase = getUrlBase(url.href);

      urlParsed.baseOrNot = 'nobase';
      url = { href: `${urlBase}?${createQueryString(urlParsed)}` };
    }

    return ContactsApi.getContactsTableData(url.href)
      .then(response => {
        dispatch(tableDataSuccess(response.data, response.headers['x-total-count']));
      })
      .catch(error => {
        console.error(error);
        dispatch(tablaDataError(extractErrorMessage(error)));
      });
  };

export const getAllContactsTableData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().Contacts;
    const { metasourceStrings } = getState().Contacts;
    const url = getMetasourceLink(
      metasource,
      metasourceStrings,
      'CONTACT_MAIN_METASOURCE_CONTACTS'
    );

    dispatch(getContactsTableData(url.href));
  };

export const loadContactsMetasource = () =>
  function(dispatch, getState) {
    return ContactsApi.getMetasource(getState().Preload.mainMetasource.contacts.href)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
        // dispatch(getContactsTableData());
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const getNewContactLocation = () =>
  function(dispatch, getState) {
    let url = getMetasourceLink(
      getState().Contacts.metasource,
      getState().Preload.metasourceStrings,
      'CONTACT_MAIN_METASOURCE_NEW_CONTACT'
    );

    url = resolveParams(url.href, {});

    return ContactsApi.getNewContactLocation(url)
      .then(location => {
        dispatch(newContactLocationSuccess(resolveParams(location), {}));
      })
      .catch(error => {
        console.error(error);
        dispatch(newContactLocationError(extractErrorMessage(error)));
      });
  };

export const getContactsListData = (url, componentId) =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Contacts;
      const { metasourceStrings } = getState().Preload;

      if (!url) {
        url = getMetasourceLink(
          metasource,
          metasourceStrings,
          'CONTACT_MAIN_METASOURCE_CONTACTS_PAGINATED'
        );
      }

      return ContactsApi.getContactsTableData(url.href)
        .then(response => {
          dispatch(
            contactsListSuccess(response.data, response.headers['x-total-count'], componentId)
          );
        })
        .catch(error => {
          console.error(error);
          dispatch(contactsListError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
    }
  };

export const createNewContact = (url, contactData, parent) =>
  function(dispatch) {
    return ContactsApi.saveNewContactData(url, contactData, parent)
      .then(response => {
        const { etag } = response.headers;

        dispatch(createNewContactSuccess());
        dispatch(providerContactSubmitSuccess(etag));
        dispatch(getProviderContactsData());
        dispatch(companyContactSubmitSuccess(etag));
        dispatch(getCompanyContactsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(createNewContactError(extractErrorMessage(error)));
      });
  };
