import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import Select from 'react-select';
import { getPropertyDetailsData } from '../../modules/PropertyDetails/PropertyDetailsActions';
import { saveProposalCTA } from '../../modules/ProposalDetails/ProposalDetailsActions';
import { normalizeNumberToDecimals, pairRoundAmount } from '../../shared/utils/formUtils';
import ImageLoader from '../../components/ImageLoader';
import ImageGallery from '../../components/ImageGallery';
import { getOptionsForSelect, getTextValueFromSelectOptions } from '../../shared/utils/appUtils';
import GoogleMapsMap from '../../components/GoogleMapsMap';
import { DATE_FORMAT, MAP_RADIUS } from '../../shared/appConfig.js';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import has from 'lodash/has';
import get from 'lodash/get';
import { PROPOSAL_PAYMENT_TYPES } from '../../shared/utils/priceUtils';

class PrintablePropertyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyImages: [],
      loadingImages: true,
      options: {
        cleaningChargeType: getOptionsForSelect(this.props.optionLabels, 'CleaningChargeType'),
        clientResponse: getOptionsForSelect(this.props.optionLabels, 'ClientResponse'),
        proposalClientRejectionReason: getOptionsForSelect(
          this.props.optionLabels,
          'ProposalClientRejectionReason'
        ),
      },
      fake:
        JSON.stringify(props.printableData.address) !==
        JSON.stringify(props.propertyDetails.address),

      cleaningCharges: get(
        this.props,
        'printableData.cleaningCharges',
        get(this.props, 'propertyDetails.cleaningCharges', '')
      ),
      cleaningChargeType: get(
        this.props,
        'printableData.cleaningChargeType',
        get(this.props, 'propertyDetails.cleaningChargeType', '')
      ),
      // showButtons:
      //   get(this.props, 'printableData.showButtons', false) &&
      //   get(this.props, 'location.query.print', false) === false,
      showButtons: get(this.props, 'printableData.showButtons', false),
      acceptProperty: false,
      thinkProperty: false,
      declineProperty: false,
      thinkPropertyDenied: false,
      thinkPropertyAccepted: false,
      declinedPropertySubmit: false,
      proposalDetails: {},
      printLaunched: false,
      featuredPictureIndex: -1,
    };

    this.handleLoadImages = this.handleLoadImages.bind(this);
    this.setReferencePointInMap = this.setReferencePointInMap.bind(this);
    this.handleProposalSelection = this.handleProposalSelection.bind(this);
    this.handleProposalCTA = this.handleProposalCTA.bind(this);
    this.setUserDecision = this.setUserDecision.bind(this);
    this.handleAcceptThinkingReservation = this.handleAcceptThinkingReservation.bind(this);
    this.handleDeclineThinkingReservation = this.handleDeclineThinkingReservation.bind(this);
    this.handleDeclineProposal = this.handleDeclineProposal.bind(this);
  }

  componentDidUpdate() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.setReferencePointInMap();
  }

  UNSAFE_componentWillMount() {
    if (this.state.cleaningCharges && !!this.state.cleaningCharges.amount) {
      const cleaningChargesAmount = this.state.cleaningCharges.amount;

      this.setState({
        cleaningCharges: cleaningChargesAmount,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const showButtons =
      get(nextProps, 'printableData.showButtons', false) &&
      get(this.props, 'location.query.print', false) === false;

    if (nextProps.optionLabels) {
      this.setState({
        options: {
          cleaningChargeType: getOptionsForSelect(nextProps.optionLabels, 'CleaningChargeType'),
          clientResponse: getOptionsForSelect(nextProps.optionLabels, 'ClientResponse'),
          proposalClientRejectionReason: getOptionsForSelect(
            nextProps.optionLabels,
            'ProposalClientRejectionReason'
          ),
        },
      });

      if (!isEmpty(nextProps.printableData)) {
        if (!isEmpty(nextProps.printableData.clientResponse)) {
          if (
            (nextProps.printableData.clientResponseThinkingDate &&
              nextProps.printableData.clientResponseThinkingDate.length > 5) ||
            nextProps.printableData.clientResponse !== 'THINKING'
          ) {
            // this.setState({
            //   showButtons,
            // });
          } else {
            this.setState({
              clientResponseThinkingDate: nextProps.printableData.clientResponseThinkingDate,
            });
          }
        }

        this.setState({
          showButtons,
        });

        if (!isEmpty(nextProps.printableData.address)) {
          this.setState(
            {
              printableData: {
                ...nextProps.printableData,
                address: nextProps.printableData.address,
              },
            },
            () => this.setReferencePointInMap()
          );
        }
      }
    }
  }

  handleLoadImages(imagesLoaded) {
    const pictures = get(this.props, 'propertyDetails.pictures', []);
    const { featuredPicturePath } = this.props.propertyDetails;
    const featuredPictureIndex = pictures.indexOf(featuredPicturePath);
    this.setState({
      loadingImages: false,
      propertyImages: imagesLoaded,
      featuredPictureIndex,
    });
    if (this.props.location.query.print === 'true') {
      setTimeout(() => {
        if (!this.state.printLaunched) {
          window.print();
          this.setState({ printLaunched: true });
        }
      }, 1000);
    }
  }

  setReferencePointInMap() {
    const centerView = true;
    const drawMarker = true;

    const { printableData } = this.props;

    if (get(printableData, 'address.geoLocation')) {
      this.refs.googleMapsMap.setReferencePoint(
        printableData.address.geoLocation.lat,
        printableData.address.geoLocation.lng,
        MAP_RADIUS,
        centerView,
        !drawMarker
      );
    }
  }

  getLoadingOrErrorMessageView(str) {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center w-100 loadingCard'>
        <div className='shadow' style={{ width: `${40}vw` }}>
          <div className='bg-primary p-2'>
            <div className='logoHs'>&nbsp;</div>
          </div>
          <div className='p-3' style={{ backgroundColor: 'white' }}>
            <br />
            <h5>{str}</h5>
            <br />
          </div>
        </div>
      </div>
    );
  }

  handleSelectChange(name, selectedOption) {
    const newProposalDetails = this.state.proposalDetails;

    newProposalDetails[name] = selectedOption
      ? Array.isArray(selectedOption)
        ? selectedOption.map(option => option.value)
        : null
      : null;
    this.setState({ proposalDetails: newProposalDetails });
  }

  handleDeclineThinkingReservation() {
    const newProposalDetails = this.state.proposalDetails;

    newProposalDetails.makeReservation = false;
    this.setState({ proposalDetails: newProposalDetails, thinkPropertyDenied: true }, () =>
      this.handleProposalCTA()
    );
  }

  handleAcceptThinkingReservation() {
    const newProposalDetails = this.state.proposalDetails;

    newProposalDetails.makeReservation = true;
    this.setState({ proposalDetails: newProposalDetails, thinkPropertyAccepted: true }, () =>
      this.handleProposalCTA()
    );
  }

  handleDeclineProposal() {
    const newProposalDetails = this.state.proposalDetails;

    newProposalDetails.proposalStage = this.state.clientResponse;
    this.setState(
      {
        proposalDetails: newProposalDetails,
        declinedPropertySubmit: true,
      },
      this.handleProposalCTA()
    );
  }

  handleProposalCTA() {
    this.props.actions.saveProposalCTA(this.props.params.code, this.state.proposalDetails);
  }

  setUserDecision(value) {
    const newProposalDetails = this.state.proposalDetails;

    newProposalDetails.clientResponse = value || null;
    this.setState({ proposalDetails: newProposalDetails });
  }

  handleProposalSelection(e) {
    let accept = false;
    let think = false;
    let decline = false;

    this.setUserDecision(e.target.name);
    switch (e.target.name) {
      case 'PRE_RESERVED':
        accept = true;
        this.handleProposalCTA();
        break;
      case 'THINKING':
        think = true;
        break;
      case 'REJECTED':
        decline = true;
        break;
      default:
        break;
    }

    this.setState({
      acceptProperty: accept,
      thinkProperty: think,
      declineProperty: decline,
    });
  }

  renderChargesFixed = () => {
    const chargesIncluded =
      get(this.props, 'printableData.chargesIncluded') ||
      get(this.props, 'propertyDetails.chargesIncluded') ||
      false;

    const spendingLimit =
      get(this.props, 'printableData.spendingLimit.amount') ||
      get(this.props, 'printableData.spendingLimit') ||
      get(this.props, 'propertyDetails.spendingLimit.amount') ||
      get(this.props, 'propertyDetails.spendingLimit');

    let text = '';

    if (!chargesIncluded && spendingLimit > 0) {
      text = `${pairRoundAmount(
        normalizeNumberToDecimals(spendingLimit, 0)
      )} € / mes fijos de suministros`;
    }

    if (!chargesIncluded && !spendingLimit) {
      text = `Suministros no incluidos`;
    }

    return text;
  };

  renderChargesIncluded = () => {
    const chargesIncluded =
      get(this.props, 'printableData.chargesIncluded') ||
      get(this.props, 'propertyDetails.chargesIncluded') ||
      false;

    const spendingLimit =
      get(this.props, 'printableData.spendingLimit.amount') ||
      get(this.props, 'printableData.spendingLimit') ||
      get(this.props, 'propertyDetails.spendingLimit.amount') ||
      get(this.props, 'propertyDetails.spendingLimit');

    let text = '';

    if (chargesIncluded) {
      text = 'Suministros incluidos';

      if (!isNil(spendingLimit) && isNumber(spendingLimit) && spendingLimit > 0) {
        const roundedSpendingLimit = pairRoundAmount(normalizeNumberToDecimals(spendingLimit, 0));

        text += ` hasta ${roundedSpendingLimit} €`;
      }
    }

    return text;
  };

  renderAvailability = () => {
    const { printableData } = this.props;
    const checkIn = get(printableData, 'dateFrom', get(printableData, 'dateCheckIn'));
    const checkOut = get(printableData, 'dateTo', get(printableData, 'dateCheckOut'));

    let availability = '';

    if (!isNil(checkIn) && !isNil(checkOut)) {
      const checkInDate = moment(checkIn).format(DATE_FORMAT);
      const checkOutDate = moment(checkOut).format(DATE_FORMAT);

      availability = `Periodo: ${checkInDate} - ${checkOutDate}`;
    } else {
      availability = 'Pendiente de disponibilidad';
    }

    return availability;
  };

  renderTaxIncluded = () => {
    const { printableData } = this.props;

    const taxIncluded = get(printableData, 'taxIncluded', false);
    const taxValue = get(printableData, 'taxValue');
    const taxType = get(printableData, 'taxType', '[Tipo impositivo sin definir]');

    let text = '';

    if (taxIncluded && !isNil(taxValue) && isNumber(taxValue)) {
      text = taxValue > 0 ? `${taxValue}% de ${taxType}` : `${taxType} incluido`;
    }

    return text;
  };

  render() {
    if (isEmpty(this.props.printableData) || has(this.props.printableData, 'url')) {
      let message;

      if (!isNil(this.props.errorStatus)) {
        switch (this.props.errorStatus) {
          case 404: {
            message = this.getLoadingOrErrorMessageView(
              'La propuesta solicitada no existe. Por favor, contacta con tu gestor personal.'
            );

            break;
          }

          case 410: {
            message = this.getLoadingOrErrorMessageView(
              'El acceso a esta propuesta ha caducado. Si necesitas más información, por favor, ponte en contacto con tu gestor personal.'
            );

            break;
          }

          default: {
            message = this.getLoadingOrErrorMessageView(
              'Hubo un error intentando cargar la propuesta. Por favor, contacta con tu gestor personal.'
            );

            break;
          }
        }

        return message;
      }

      if (!this.props.metasourceStringsError) {
        message = this.getLoadingOrErrorMessageView('Cargando...');
      } else {
        message = this.getLoadingOrErrorMessageView(
          'Hubo un error intentando cargar la propuesta. Por favor, contacta con tu gestor personal.'
        );
      }

      return message;
    }

    const { printableData, propertyDetails } = this.props;
    const monthlyPriceDossier = get(printableData, 'monthlyPriceDossier.amount', 0);
    const totalPriceDossier = get(printableData, 'totalPriceDossier.amount', 0);
    const deposit = get(printableData, 'deposit.amount', get(printableData, 'deposit'));
    const cleaningCharges = get(printableData, 'cleaningCharges.amount');
    // const commissionPercentage = get(printableData, 'commissionPercentage', 0);
    // const checkIn = get(printableData, 'dateFrom', get(printableData, 'dateCheckIn'));
    // const checkOut = get(printableData, 'dateTo', get(printableData, 'dateCheckOut'));
    const pricePerPersonAndDay = get(printableData, 'personPerDayPrice.amount', 0);

    // let priceDisplayed = '';

    // if (printableData.price) {
    //   if (commissionPercentage != null) {
    //     let clientPrice;

    //     if (printableData.taxExempt) {
    //       clientPrice = getPriceDossierTaxExempt(
    //         printableData.address,
    //         price,
    //         commissionPercentage
    //       );
    //     } else {
    //       clientPrice = getMonthlyPriceDossier(price, commissionPercentage);
    //     }

    //     priceDisplayed = `${pairRoundAmount(normalizeNumberToDecimals(clientPrice, 2))} € / mes`;
    //   } else {
    //     priceDisplayed = `${pairRoundAmount(normalizeNumberToDecimals(price, 2))} € / mes`;
    //   }
    // }

    // let totalPriceDisplayed = '';

    // if (get(this.props, 'printableData.showTotalPrice')) {
    //   totalPriceDisplayed += `${pairRoundAmount(normalizeNumberToDecimals(totalPrice, 2))} €`;
    // }

    const pagesOfImages = [];
    let imagesInGallery = [];

    if (!this.state.loadingImages) {
      this.state.propertyImages.map(image => {
        if (imagesInGallery.length % 6 === 0 && imagesInGallery.length > 0) {
          pagesOfImages.push(imagesInGallery);
          imagesInGallery = [];
        }

        return imagesInGallery.push(image);
      });
      if (imagesInGallery.length > 0) {
        pagesOfImages.push(imagesInGallery);
      }
    }

    const taxValue = get(printableData, 'taxValue');
    const plusTax = get(printableData, 'plusTax', false);
    const taxExempt = get(printableData, 'taxExempt', false);
    const taxType = get(printableData, 'taxType', '[Tipo impositivo sin definir]');

    return (
      <div>
        <div className='width100'>
          <ImageLoader
            s3bucket={propertyDetails.bucketName}
            imagesToLoad={propertyDetails.pictures}
            handleLoadComplete={this.handleLoadImages}
          />
          <div className='page sizeA4 hsBackground'>
            <div className='row'>
              <div className='col-sm-9'>
                <div className='featuredText'>{printableData.title}</div>
                {!this.state.fake &&
                  (propertyDetails.homyspaceReference
                    ? `Ref.: ${propertyDetails.homyspaceReference}`
                    : '')}
                <br />
                {get(printableData, 'address.administrativeAreaLevel2') && (
                  <span>Provincia: {printableData.address.administrativeAreaLevel2} </span>
                )}
                <br />
                {get(printableData, 'address.locality') && (
                  <span>Ciudad: {printableData.address.locality} </span>
                )}
                <br />
                {this.renderAvailability()}
              </div>
              <div className='col-sm-3 alignRight'>
                <img alt='iconoHs' src='../../img/icono_negro.png' className='hsHeader' />
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12'>
                {this.state.propertyImages.length > 0 &&
                  this.state.featuredPictureIndex > -1 &&
                  !this.state.loadingImages && (
                    <div className='printableDataFeaturedImage'>
                      <img
                        id='featuredImage'
                        key='featuredImage'
                        src={this.state.propertyImages[this.state.featuredPictureIndex]}
                        alt='featured'
                      />
                    </div>
                  )}
                <div className='printableDataBox printableDataBoxFeatured'>
                  {printableData.paymentType === PROPOSAL_PAYMENT_TYPES.MONTHLY_PAYMENT && (
                    <div className='featuredText'>{monthlyPriceDossier} € / mes</div>
                  )}
                  {printableData.paymentType === PROPOSAL_PAYMENT_TYPES.ONE_TIME_PAYMENT && (
                    <div>
                      <div style={{ fontSize: '14px', paddingBottom: '0.2em' }} className='bold'>
                        Total estancia:
                      </div>
                      <div className='featuredText'>{totalPriceDossier} €</div>
                    </div>
                  )}
                  {/* {!printableData.showTotalPrice && (
                    <div className='featuredText'>
                      {priceDisplayed !== '' && priceDisplayed}
                      {priceDisplayed === '' && totalPriceDisplayed !== '' && totalPriceDisplayed}
                    </div>
                  )}
                  {printableData.showTotalPrice &&
                    priceDisplayed !== '' &&
                    totalPriceDisplayed !== '' && (
                      <div>
                        <div style={{ fontSize: '14px', paddingBottom: '0.2em' }} className='bold'>
                          Total estancia:
                        </div>
                        <div className='featuredText'>{totalPriceDisplayed}</div>
                      </div>
                    )} */}
                  {printableData.showPaxPrice && pricePerPersonAndDay !== 0 && (
                    <div>{pricePerPersonAndDay} € persona / día</div>
                  )}
                  {plusTax && !isNil(taxValue) && taxValue > 0 && (
                    <div>
                      + {taxValue}% de {taxType}
                    </div>
                  )}
                  {taxExempt && <div>Exento de impuestos</div>}
                  {this.renderTaxIncluded()}
                  {!this.props.printableData.showTotalPrice &&
                    this.props.printableData.proposalSaving &&
                    this.props.printableData.tenants === 1 &&
                    this.props.printableData.proposalSaving.monthlySaving && (
                      <div className='savingBox'>
                        <span className='savingBoxBigText'>
                          Tu empresa está ahorrando
                          <br />
                          {`${this.props.printableData.proposalSaving.monthlySaving}€ / mes`}
                        </span>
                        <br />
                        comparado con los precios de la zona
                      </div>
                    )}
                  {this.props.printableData.showTotalPrice &&
                    this.props.printableData.proposalSaving &&
                    this.props.printableData.tenants === 1 &&
                    this.props.printableData.proposalSaving.totalSaving && (
                      <div className='savingBox'>
                        <span className='savingBoxBigText'>
                          Tu empresa está ahorrando
                          <br />
                          {`${this.props.printableData.proposalSaving.totalSaving}€`}
                        </span>
                        <br />
                        comparado con los precios de la zona
                      </div>
                    )}
                  <hr className='hs-hr' />
                  {this.renderChargesFixed()}
                  {this.renderChargesIncluded()}
                  <hr className='hs-hr' />
                  <div style={{ textAlign: 'left' }}>
                    <div className='row'>
                      <div className='col-sm-2'>
                        <img
                          className='iconHorizontal'
                          src='../../img/icons/cleaning.png'
                          alt='rooms'
                        />{' '}
                        {/* &nbsp; &nbsp; */}
                      </div>
                      <div className='col-sm-10 vertical-align-middle'>
                        {cleaningCharges > 0 &&
                          `+ ${normalizeNumberToDecimals(
                            cleaningCharges,
                            0
                          )} € de limpieza ${getCleaningChargesDescripcion(
                            this.state.options.cleaningChargeType,
                            this.props.printableData.cleaningChargeType
                          ).toLowerCase()}`}
                        {cleaningCharges === 0 &&
                          `Limpieza ${getCleaningChargesDescripcion(
                            this.state.options.cleaningChargeType,
                            this.props.printableData.cleaningChargeType
                          ).toLowerCase()} incluida`}
                        {(cleaningCharges < 0 ||
                          isNaN(cleaningCharges) ||
                          cleaningCharges == null) &&
                          'No incluye limpieza'}
                      </div>
                    </div>
                    {/* <br />  */}
                    <img
                      className='iconHorizontal'
                      src='../../img/icons/safebox.png'
                      alt='safebox'
                    />{' '}
                    &nbsp;&nbsp;&nbsp;
                    {deposit > 0 && `+ ${normalizeNumberToDecimals(deposit, 0)} € de fianza`}
                    {deposit <= 0 && 'No necesita fianza'}
                    <hr className='hs-hr' />
                    <div>Oferta disponible durante 24h desde la recepción del dossier</div>
                  </div>
                </div>
                <div className='printableDataBox'>
                  <div className='row'>
                    <div className='col-sm-3 alignCenter'>
                      <img className='icon' src='../../img/icons/rooms.png' alt='rooms' />
                      <br />
                      {get(this.props, 'propertyDetails.rooms', '-')}
                      <br />
                      hab.
                    </div>
                    <div className='col-sm-3 alignCenter'>
                      <img className='icon' src='../../img/icons/bed.png' alt='beds' />
                      <br />
                      {get(this.props, 'propertyDetails.beds', '-')}
                      <br />
                      {this.props.propertyDetails.beds > 1 ? 'camas' : 'cama'}
                    </div>
                    <div className='col-sm-3 alignCenter'>
                      <img className='icon' src='../../img/icons/sofa.png' alt='sofa' />
                      <br />
                      {get(this.props, 'propertyDetails.sofaBed', '-')}
                      <br />
                      sofá cama
                    </div>
                    <div className='col-sm-3 alignCenter'>
                      <img className='icon' src='../../img/icons/bathroom.png' alt='bathroom' />
                      <br />
                      <span style={{ fontSize: '0.9em' }}>
                        {get(this.props, 'propertyDetails.bathrooms', '-')}
                        {this.props.propertyDetails.bathrooms > 1 ? ' baños' : ' baño'}
                      </span>
                      <br />
                      <span style={{ fontSize: '0.9em' }}>
                        {get(this.props, 'propertyDetails.toilets', '-')}
                        {this.props.propertyDetails.toilets > 1 ? ' aseos' : ' aseo'}
                      </span>
                    </div>
                  </div>
                  <hr className='hs-hr' />
                  <div className='row'>
                    <div className='col-sm-12 comoditiesBox'>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.tv ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.tv ? '' : 'notAvailable'
                          }`}
                        >
                          TV
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.wifi ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.wifi ? '' : 'notAvailable'
                          }`}
                        >
                          WIFI
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={
                              this.props.propertyDetails.smokingAllowed ? {} : { display: 'none' }
                            }
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.smokingAllowed ? '' : 'notAvailable'
                          }`}
                        >
                          Se permite fumar
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={
                              this.props.propertyDetails.petsAllowed ? {} : { display: 'none' }
                            }
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.petsAllowed ? '' : 'notAvailable'
                          }`}
                        >
                          Se permite mascotas
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.lift ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.lift ? '' : 'notAvailable'
                          }`}
                        >
                          Ascensor
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={
                              this.props.propertyDetails.airConditioning ? {} : { display: 'none' }
                            }
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.airConditioning ? '' : 'notAvailable'
                          }`}
                        >
                          Aire acondicionado
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.heating ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.heating ? '' : 'notAvailable'
                          }`}
                        >
                          Calefacción
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.oven ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.oven ? '' : 'notAvailable'
                          }`}
                        >
                          Horno
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={
                              this.props.propertyDetails.microwaveOven ? {} : { display: 'none' }
                            }
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.microwaveOven ? '' : 'notAvailable'
                          }`}
                        >
                          Microondas
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={
                              this.props.propertyDetails.kitchenware ? {} : { display: 'none' }
                            }
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.kitchenware ? '' : 'notAvailable'
                          }`}
                        >
                          Menaje
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={
                              this.props.propertyDetails.washerMachine ? {} : { display: 'none' }
                            }
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.washerMachine ? '' : 'notAvailable'
                          }`}
                        >
                          Lavadora
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={
                              this.props.propertyDetails.dryerMachine ? {} : { display: 'none' }
                            }
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.dryerMachine ? '' : 'notAvailable'
                          }`}
                        >
                          Secadora
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.iron ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.iron ? '' : 'notAvailable'
                          }`}
                        >
                          Plancha
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.towels ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.towels ? '' : 'notAvailable'
                          }`}
                        >
                          Se suministran toallas
                        </div>
                      </div>
                      <div>
                        <div className='iconImage'>
                          <img
                            className='smallIcon'
                            src='../../img/icons/checked.png'
                            alt='checked'
                            style={this.props.propertyDetails.bedding ? {} : { display: 'none' }}
                          />
                        </div>
                        <div
                          className={`iconText ${
                            this.props.propertyDetails.bedding ? '' : 'notAvailable'
                          }`}
                        >
                          Se suministra ropa de cama
                        </div>
                      </div>
                      {this.props.propertyDetails.freeCarPark && (
                        <div>
                          <div className='iconImage'>
                            <img
                              className='smallIcon'
                              src='../../img/icons/checked.png'
                              alt='checked'
                            />
                          </div>
                          <div className='iconText'>Parquing gratuito</div>
                        </div>
                      )}
                      {this.props.propertyDetails.pearbyCarPark && (
                        <div>
                          <div className='iconImage'>
                            <img
                              className='smallIcon'
                              src='../../img/icons/checked.png'
                              alt='checked'
                            />
                          </div>
                          <div className='iconTex'>Parquing de pago</div>
                        </div>
                      )}
                      {this.props.propertyDetails.easyParking && (
                        <div>
                          <div className='iconImage'>
                            <img
                              className='smallIcon'
                              src='../../img/icons/checked.png'
                              alt='checked'
                            />
                          </div>
                          <div className='iconText'>Facilidad para aparcar</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='printableDataFooter'>
              <a href='https://www.homyspace.com' target='_blank' rel='noopener noreferrer'>
                www.homyspace.com
              </a>{' '}
              &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp;{' '}
              <a href='mailto:reservas@homyspace.com'>reservas@homyspace.com</a> &nbsp; &nbsp;
              &nbsp; | &nbsp; &nbsp; &nbsp; <a href='tel:961134414'>961134414</a>
            </div>
          </div>

          {pagesOfImages.map((imagesInThisPage, i) => (
            <div key={i} className='page sizeA4 hsBackground'>
              <div className='row'>
                <div className='col-sm-12 pageTitle'>{i === 0 && '¿Cómo es el inmueble?'}</div>
              </div>
              <div className='row'>
                <div className='col-sm-12 photos'>
                  {this.props.printableData.pictures && !this.state.loadingImages && (
                    <ImageGallery
                      lineHeight={220}
                      galleryId='propertyImage'
                      images={imagesInThisPage}
                    />
                  )}
                </div>
              </div>
              <div className='printableDataFooter'>
                <a href='https://www.homyspace.com' target='_blank' rel='noopener noreferrer'>
                  www.homyspace.com
                </a>{' '}
                &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp;{' '}
                <a href='mailto:reservas@homyspace.com'>reservas@homyspace.com</a> &nbsp; &nbsp;
                &nbsp; | &nbsp; &nbsp; &nbsp; <a href='tel:961134414'>961134414</a>
              </div>
            </div>
          ))}

          <div className='page sizeA4 hsBackground'>
            <div className='row'>
              <div className='col-sm-12 pageTitle'>¿Dónde está el inmueble?</div>
            </div>
            <div className='row'>
              <div className='col-sm-12 map noPadding'>
                <GoogleMapsMap
                  ref='googleMapsMap'
                  mapStyle={{ width: '100%', height: '10cm' }}
                  viewChangeCallback={() => {}}
                  loadCallback={this.setReferencePointInMap}
                  initializeLocation={false}
                />
              </div>
            </div>
            {!isEmpty(this.props.printableData.observations) &&
              this.props.printableData.observations !== '<p><br></p>' && (
                <div>
                  <div className='row'>
                    <div className='col-sm-12 pageTitle'>Algunas cosas a tener en cuenta</div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 map noPadding'>
                      <p>{ReactHtmlParser(this.props.printableData.observations)}</p>
                    </div>
                  </div>
                </div>
              )}
            <div className='printableDataFooter'>
              <a href='https://www.homyspace.com' target='_blank' rel='noopener noreferrer'>
                www.homyspace.com
              </a>{' '}
              &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp;{' '}
              <a href='mailto:reservas@homyspace.com'>reservas@homyspace.com</a> &nbsp; &nbsp;
              &nbsp; | &nbsp; &nbsp; &nbsp; <a href='tel:961134414'>961134414</a>
            </div>
          </div>

          <div className='page sizeA4 hsBackground'>
            <div className='row'>
              <div className='col-sm-12 pageTitle'>
                Si acepto la propuesta, ¿qué tengo que hacer?
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='stepDescription'>
                  <div className='stepIcon'>
                    <img src='../../img/icons/reserva.png' alt='step1' />
                  </div>
                  <div className='stepTitle'>Realiza la reserva</div>
                  <br />
                  <br />
                  ¡No pierdas la reserva!
                  <br />
                  <br />
                  <ul>
                    <li>
                      Dile lo antes posible a tu gestor personal que quieres reservar el inmueble.
                    </li>
                    <li>
                      Realiza el pago de la tarifa Homyspace y la primera mensualidad. Te
                      facilitaremos todos los datos necesarios.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='stepDescription'>
                  <div className='stepIcon'>
                    <img src='../../img/icons/documentacion.png' alt='step2' />
                  </div>
                  <div className='stepTitle'>Confirma los datos y firma la documentación</div>
                  <br />
                  <br />
                  Confirma los datos de:
                  <br />
                  <br />
                  <ul>
                    <li>
                      La empresa.
                      <br />
                    </li>
                    <li>
                      Los trabajadores que se alojarán.
                      <br />
                    </li>
                    <li>
                      Avisa a la persona con capacidad de firma.
                      <br />
                    </li>
                  </ul>
                  Homyspace redactará y revisará el contrato de arrendamiento. Firma fácil y rápido
                  online. ¿Porque elegimos la firma online? Haz clic{' '}
                  <a href='https://youtu.be/RSxCgVkmoCc' target='_blank' rel='noopener noreferrer'>
                    aquí
                  </a>
                  .
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='stepDescription'>
                  <div className='stepIcon'>
                    <img src='../../img/icons/chekin.png' alt='step3' />
                  </div>
                  <div className='stepTitle'>Entra al piso</div>
                  <br />
                  <br />
                  Los trabajadores/inquilinos/alojados recogen/son entregados las llaves el día de
                  la entrada.
                  <br />
                  <br />
                  Tienes 24 horas para confirmarnos que todo ha ido bien. ¿La bombilla no funciona?
                  ¿Os faltan las sábanas? Nosotros nos encargamos de lo que necesitéis.
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='stepDescription'>
                  <div className='stepIcon'>
                    <img src='../../img/icons/renovacion.png' alt='step4' />
                  </div>
                  <div className='stepTitle'>¿Lo necesitas más tiempo?</div>
                  <br />
                  <br />
                  Si por cualquier motivo quieres extender tu estancia en este piso después de la
                  reserva, solo tienes que contactarnos para confirmar disponibilidad.
                  <br />
                  <br />
                  Disfruta de las mismas garantías:
                  <br />
                  <ul>
                    <li>Facturas</li>
                    <li>Contrato</li>
                    <li>Firma segura on-line</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='printableDataFooter'>
              <a href='https://www.homyspace.com' target='_blank' rel='noopener noreferrer'>
                www.homyspace.com
              </a>{' '}
              &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp;{' '}
              <a href='mailto:reservas@homyspace.com'>reservas@homyspace.com</a> &nbsp; &nbsp;
              &nbsp; | &nbsp; &nbsp; &nbsp; <a href='tel:961134414'>961134414</a>
            </div>
          </div>
        </div>

        {this.state.showButtons &&
          !this.state.acceptProperty &&
          !this.state.declineProperty &&
          !this.state.thinkProperty && (
            <div className='col-sm-12 windowCTA'>
              <h4 style={{ marginTop: 20 }}>
                ¿Te interesa la Propiedad?
                <br />
                <b>¡Haz YA la pre-reserva, y que no te la quiten!</b>
              </h4>
              <div style={{ marginTop: `${7}%` }}>
                <FloatingButton
                  className='col-sm-4'
                  name='REJECTED'
                  color='#f36042'
                  action={this.handleProposalSelection}
                  text={getTextValueFromSelectOptions(
                    'REJECTED',
                    this.state.options.clientResponse
                  )}
                />
                {(!this.state.clientResponseThinkingDate ||
                  this.state.clientResponseThinkingDate.length < 5) && (
                  <FloatingButton
                    className='col-sm-4'
                    name='THINKING'
                    color='#B2B2B2'
                    action={this.handleProposalSelection}
                    text={getTextValueFromSelectOptions(
                      'THINKING',
                      this.state.options.clientResponse
                    )}
                  />
                )}
                <FloatingButton
                  className='col-sm-4'
                  name='PRE_RESERVED'
                  color='#2e9e50'
                  action={this.handleProposalSelection}
                  text={getTextValueFromSelectOptions(
                    'PRE_RESERVED',
                    this.state.options.clientResponse
                  )}
                />
              </div>
            </div>
          )}

        {this.state.showButtons && this.state.acceptProperty && (
          <div className='col-sm-12 windowCTA'>
            <h4 style={{ marginTop: 20 }}>
              ¡Muchas gracias! <br />
              <br /> Tu gestor ya está informado y enseguida te enviará las instrucciones para
              gestionar la reserva.
            </h4>
          </div>
        )}

        {this.state.showButtons &&
          this.state.thinkProperty &&
          !this.state.thinkPropertyDenied &&
          !this.state.thinkPropertyAccepted && (
            <div className='col-sm-12 windowCTA'>
              <div>
                <div>
                  <h4 style={{ marginTop: 20 }}>
                    ¿Quieres que bloqueemos estas fechas durante 24 horas?
                    {this.state.clientResponseThinkingDate &&
                      this.state.clientResponseThinkingDate.length >= 4 && (
                        <div>
                          Debido al tiempo transcurrido desde el envío de la Propuesta, solo
                          podremos bloquearte las fechas 24 horas más.
                        </div>
                      )}
                  </h4>
                </div>
              </div>
              <FloatingButton
                className='col-sm-4'
                color='#CE3517'
                action={this.handleDeclineThinkingReservation}
                text='No'
              />
              <FloatingButton
                className='col-sm-4'
                color='#0E9247'
                action={this.handleAcceptThinkingReservation}
                text='Si'
              />
            </div>
          )}

        {this.state.showButtons && this.state.thinkProperty && this.state.thinkPropertyDenied && (
          <div className='col-sm-12 windowCTA'>
            <div>
              <div>
                <h4 style={{ marginTop: 20 }}>¡Vaya!</h4>
                <h5>
                  Recuerda que la oferta está disponible durante 24h desde la recepción del dossier.
                </h5>
              </div>
            </div>
          </div>
        )}

        {this.state.showButtons && this.state.thinkProperty && this.state.thinkPropertyAccepted && (
          <div className='col-sm-12 windowCTA'>
            <div>
              <div>
                <h4 style={{ marginTop: 20 }}>De acuerdo</h4>
                <h5>Tu gestor ya está informado para que mantenga la disponibilidad 24h más.</h5>
              </div>
            </div>
          </div>
        )}

        {this.state.showButtons &&
          this.state.declineProperty &&
          !this.state.declinedPropertySubmit && (
            <div className='col-sm-12 windowCTA'>
              <div>
                <h4 style={{ marginTop: 20 }}>
                  Sentimos mucho que la reserva no salga hacia delante.
                </h4>
                <h5>¿Podrías indicarnos el motivo de descartarlo?</h5>
              </div>
              <label htmlFor='contactRole' className='required'>
                Selecciona una o más opciones
              </label>
              <Select
                name='proposalClientRejectionReason'
                className='form-control validate validate-required'
                options={this.state.options.proposalClientRejectionReason}
                value={
                  this.state.proposalDetails.proposalClientRejectionReason
                    ? this.state.proposalDetails.proposalClientRejectionReason
                    : ''
                }
                onChange={value => {
                  this.handleSelectChange('proposalClientRejectionReason', value);
                }}
                multi
              />
              <div style={{ marginTop: `${15}px` }}>
                <button
                  style={{ float: 'left' }}
                  className='btn'
                  onClick={this.handleProposalSelection}
                >
                  Atrás
                </button>
                <button
                  style={{ float: 'right' }}
                  className='btn btn-primary panel-footer'
                  onClick={this.handleDeclineProposal}
                >
                  Enviar
                </button>
              </div>
            </div>
          )}

        {this.state.showButtons && this.state.declineProperty && this.state.declinedPropertySubmit && (
          <div className='col-sm-12 windowCTA'>
            <div>
              <div>
                <h4 style={{ marginTop: 20 }}>Muchas gracias</h4>
                <h5>
                  Agradecemos tu valoración para poder mejorar constantemente. Esperamos volver a
                  verte pronto.
                </h5>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function getCleaningChargesDescripcion(options, value) {
  if (!options || !value) {
    return '';
  }

  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    if (option.value === value) {
      return option.label;
    }
  }

  return '';
}

function mapStateToProps(state) {
  return {
    propertyDetails: state.PropertyDetails.data.detail,
    printableData: state.PropertyDetails.printableData,
    optionLabels: state.Preload.optionLabels,
    metasource: state.Proposals.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    metasourceStringsError: state.Preload.metasourceStringsError,
    errorStatus: state.ProposalDetails.submit.errorStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getPropertyDetailsData: () => dispatch(getPropertyDetailsData()),
      saveProposalCTA: (id, proposalDetails) => dispatch(saveProposalCTA(id, proposalDetails)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintablePropertyDetails);
