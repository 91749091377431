import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Modal, Row, Table } from 'antd';
import moment from 'moment';
import { getImportStatus } from '../../modules/Properties/PropertiesActions';
import { DATE_FORMAT } from '../../shared/appConfig';

const STATUS_STRINGS = {
  IN_PROGRESS: 'EN PROGRESO',
  FINALIZED: 'FINALIZADO',
};

class TableImportProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorDetail: '',
      data: null,
      modalShowDetails: false,
      errorList: [],
    };
    this.toggleShowDetails = this.toggleShowDetails.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.localeStatus = this.localeStatus.bind(this);
    this.props.actions.getImportStatus();
    this.statusInterval = setInterval(() => {
      this.props.actions.getImportStatus();
    }, 3000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.importStatus.error) {
      this.setState({
        error: true,
        errorDetail: nextProps.importStatus.errorDetail,
      });
    } else {
      this.setState({
        error: false,
        errorDetail: '',
        data: nextProps.importStatus.data,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.statusInterval);
  }

  toggleShowDetails() {
    this.setState({ modalShowDetails: !this.state.modalShowDetails });
  }

  localeStatus(status) {
    return STATUS_STRINGS[status];
  }

  showDetails(record) {
    this.setState({
      errorList: record.massiveUploadProperties.errors,
      modalShowDetails: true,
    });
  }

  render() {
    const columns = [
      {
        title: 'Fecha',
        dataIndex: 'massiveUploadProperties.date',
        render: date => moment(new Date(date)).format(DATE_FORMAT),
      },
      {
        title: 'Nombre del fichero',
        dataIndex: 'massiveUploadProperties.fileName',
        render: text => text,
      },
      {
        title: 'Estado',
        dataIndex: 'massiveUploadProperties.status',
        render: text => this.localeStatus(text),
      },
      {
        title: 'Total inmuebles',
        dataIndex: 'massiveUploadProperties.total',
        render: text => <div style={{ textAlign: 'right' }}>{text}</div>,
      },
      {
        title: 'Inmuebles procesados',
        dataIndex: 'massiveUploadProperties.processed',
        render: text => <div style={{ textAlign: 'right' }}>{text}</div>,
      },
      {
        title: 'Procesados con éxito',
        dataIndex: 'massiveUploadProperties.oks',
        render: text => <div style={{ textAlign: 'right' }}>{text}</div>,
      },
      {
        title: 'Procesados con error',
        dataIndex: 'massiveUploadProperties.kos',
        render: text => <div style={{ textAlign: 'right' }}>{text}</div>,
      },
    ];

    return (
      <div className='animated fadeIn'>
        <Row>
          <Col span={24}>
            {this.state.error && (
              <Alert
                message='Error'
                description={this.state.errorDetail}
                type='error'
                showIcon
                closable
              />
            )}
          </Col>
        </Row>
        <br />
        <Modal
          title='Errores en la importación'
          visible={this.state.modalShowDetails}
          onCancel={this.toggleShowDetails}
          footer={null}
        >
          <Row>
            <Col span={24}>
              <div className='importErrorsModalDiv'>
                {this.state.errorList.length > 0
                  ? this.state.errorList.map((error, i) => <p key={`error${i}`}>{error}</p>)
                  : 'No hay errores'}
              </div>
            </Col>
          </Row>
        </Modal>
        <Row>
          <Col>
            <Table
              size='small'
              dataSource={this.state.data}
              columns={columns}
              rowKey={rec => rec.massiveUploadProperties.id}
              onRowClick={(record, index) => this.showDetails(record, index)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    importStatus: state.Properties.importStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getImportStatus: () => dispatch(getImportStatus()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableImportProperties);
