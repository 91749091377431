import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { getOptionsForSelect } from '../../shared/utils/appUtils';
import { browserHistory } from 'react-router';
// PARAMETROS A CONFIGURAR
// callbackFunctionOnContactSelect -> Funcion a la que se llama cuando se selecciona un contacto en la lista
// contactList -> (OPTIONAL) lista con los contactos a renderizar
// callbackFunctionOnContactDeleted -> (OPTIONAL) function to be called when user wants to delete a contact from the list
// deleteRender -> (OPTIONAL) the component to render in the delete button position

class TableDataProviderContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.contactList ? props.contactList : [],
      contactsVersion: props.providerContactsVersion,
      roles: this.buildRolesMap(),
    };
    this.showProviderContactDetail = this.showProviderContactDetail.bind(this);
  }

  buildRolesMap() {
    return getOptionsForSelect(this.props.optionLabels, 'ContactRole').reduce((map, item) => {
      map[item.value] = item.label;

      return map;
    }, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.contactList && nextProps.contactsVersion !== this.state.contactsVersion) {
      this.setState({
        contactsVersion: nextProps.contactsVersion,
        data: nextProps.providerContacts,
      });
    }

    if (
      this.props.contactList &&
      JSON.stringify(this.state.data) !== JSON.stringify(this.props.contactList)
    ) {
      this.setState({
        data: this.props.contactList,
      });
    }
  }

  showProviderContactDetail = contact => {
    browserHistory.push(`/contactDetails/${contact.id}`);
  };

  render() {
    const tableScroll = window.screen.availWidth - 600;
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'contact.name',
        sorter: (a, b) =>
          a.contact.name < b.contact.name ? -1 : a.contact.name > b.contact.name ? 1 : 0,
        render: text => (
          <a href=''>{text && text.length > 27 ? `${text.substring(0, 24)}...` : text}</a>
        ),
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showProviderContactDetail(record.contact);
        },
      },
      {
        title: 'Apellidos / Razón social',
        dataIndex: 'contact.lastOTradeName',
        sorter: (a, b) =>
          a.contact.lastOTradeName < b.contact.lastOTradeName
            ? -1
            : a.contact.lastOTradeName > b.contact.lastOTradeName
            ? 1
            : 0,
        render: text => (text && text.length > 27 ? `${text.substring(0, 24)}...` : text),
      },
      {
        title: 'Función',
        dataIndex: 'contact.relatedProvider.roles',
        render: roles => (roles ? roles.map(r => this.state.roles[r]).join(', ') : ''),
      },
      {
        title: 'Cargo',
        dataIndex: 'contact.jobTitle',
        sorter: (a, b) =>
          a.contact.jobTitle < b.contact.jobTitle
            ? -1
            : a.contact.jobTitle > b.contact.jobTitle
            ? 1
            : 0,
        render: text => (text && text.length > 27 ? `${text.substring(0, 24)}...` : text),
      },
      {
        title: 'Email',
        dataIndex: 'contact.email',
        sorter: (a, b) =>
          a.contact.email < b.contact.email ? -1 : a.contact.email > b.contact.email ? 1 : 0,
        render: val => (
          <a href={`mailto:pruebas@homyspace.com`}>
            {val && val.length > 27 ? `${val.substring(0, 24)}...` : val}
          </a>
        ),
      },
      {
        title: '2º Email',
        dataIndex: 'contact.email2',
        sorter: (a, b) =>
          a.contact.email2 < b.contact.email2 ? -1 : a.contact.email2 > b.contact.email2 ? 1 : 0,
        render: val => (
          <a href={`mailto:pruebas@homyspace.com`}>
            {val && val.length > 27 ? `${val.substring(0, 24)}...` : val}
          </a>
        ),
      },
      {
        title: 'Teléfono',
        dataIndex: 'contact.phoneNumber',
        sorter: (a, b) =>
          a.contact.phoneNumber < b.contact.phoneNumber
            ? -1
            : a.contact.phoneNumber > b.contact.phoneNumber
            ? 1
            : 0,
        render: val => <a href={`tel:${val}`}>{val}</a>,
      },
      {
        title: '2º Teléfono',
        dataIndex: 'contact.mobilePhoneNumber',
        sorter: (a, b) =>
          a.contact.mobilePhoneNumber < b.contact.mobilePhoneNumber
            ? -1
            : a.contact.mobilePhoneNumber > b.contact.mobilePhoneNumber
            ? 1
            : 0,
        render: val => <a href={`tel:${val}`}>{val}</a>,
      },
      {
        title: ' ',
        dataIndex: 'contact.id',
        render: this.props.deleteRender,
        // render: val => ( this.props.contactList &&
        //     <button type='button' className='btn btn-primary btn-sm' onClick={(e)=>{
        //           e.preventDefault();
        //           if(typeof this.props.callbackFunctionOnContactDeleted === 'function'){
        //               this.props.callbackFunctionOnContactDeleted(val);
        //           }
        //         }
        //       }>
        //       <Icon type="delete" />
        //     </button>
        // )
      },
    ];

    const dataSource =
      this.state.data && this.state.data.length > 0 ? this.state.data : this.props.providerContacts;

    if (dataSource && dataSource.length > 0 && dataSource[0].roles) {
      columns[2].dataIndex = 'roles';
    }

    return (
      <div className='col-sm-12'>
        {this.state.data && (
          <Table
            size='small'
            pagination={{ pageSize: 15 }}
            scroll={{ x: tableScroll }}
            dataSource={dataSource}
            columns={columns}
            rowKey={rec => rec.contact.id}
            defaultExpandAllRows
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    providerContacts: state.ProviderDetails.contacts.detail,
    providerContactsVersion: state.ProviderDetails.contactsVersion,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps)(TableDataProviderContacts);
