import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Timeline } from 'antd';
import moment from 'moment';
import { deleteBusinessEvent } from '../../modules/Events/EventsActions';
import { DATETIME_FORMAT } from '../../shared/appConfig';

/** ************************************************
PROPS TO CONFIGURE IN PARENT
key -> id to render identify the instance of the component
eventObject -> object with the event information
************************************************* */

// TODO ajusta el color de los eventos en función del tipo de entidad a la que esté asociado el evento

class TimelineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    if (window.confirm('Vas a borrar este evento, ¿estas seguro?')) {
      this.props.actions.deleteBusinessEvent(this.props.eventObject._links.self.href);
    }
  }

  render() {
    const eventDate = moment(this.props.eventObject.businessEvent.date).format(DATETIME_FORMAT);
    let iconElement = 'right-circle-o';
    let eventDetails = '';

    if (this.props.eventObject.businessEvent.type.includes('EMAIL')) {
      iconElement = 'mail';
      if (
        this.props.eventObject.businessEvent.from &&
        this.props.eventObject.businessEvent.from !== ''
      ) {
        eventDetails += `<strong>De: </strong>${this.props.eventObject.businessEvent.from}<br/>`;
      }

      if (
        this.props.eventObject.businessEvent.to &&
        this.props.eventObject.businessEvent.to !== ''
      ) {
        eventDetails += `<strong>A: </strong>${this.props.eventObject.businessEvent.to}<br/>`;
      }

      if (
        this.props.eventObject.businessEvent.cc &&
        this.props.eventObject.businessEvent.cc !== ''
      ) {
        eventDetails += `<strong>CC: </strong>${this.props.eventObject.businessEvent.cc}<br/>`;
      }

      if (
        this.props.eventObject.businessEvent.bcc &&
        this.props.eventObject.businessEvent.bcc !== ''
      ) {
        eventDetails += `<strong>BCC: </strong>${this.props.eventObject.businessEvent.bcc}<br/>`;
      }

      if (
        this.props.eventObject.businessEvent.subject &&
        this.props.eventObject.businessEvent.subject !== ''
      ) {
        eventDetails += `<br/><h3>${this.props.eventObject.businessEvent.subject}</h3>`;
      }
    }

    if (this.props.eventObject.businessEvent.type.includes('NOTE')) {
      iconElement = 'exception';
    }

    if (this.props.eventObject.businessEvent.type.includes('CALL')) {
      iconElement = 'phone';
      if (
        this.props.eventObject.businessEvent.toNumber &&
        this.props.eventObject.businessEvent.toNumber !== ''
      ) {
        eventDetails += `<strong>Teléfono: </strong>${this.props.eventObject.businessEvent.toNumber}<br/>`;
      }
    }

    if (this.props.eventObject.businessEvent.type.includes('TASK')) {
      iconElement = 'clock-circle-o';
      if (
        this.props.eventObject.businessEvent.subject &&
        this.props.eventObject.businessEvent.subject !== ''
      ) {
        // eventDetails += `<h3>${this.props.eventObject.businessEvent.subject}</h3>`;
        // text for tasks comes in subject attribute, so <h3> is discarded
        eventDetails += `${this.props.eventObject.businessEvent.subject}`;
      }
    }

    if (this.props.eventObject.businessEvent.type.includes('FOLLOW_UP')) {
      iconElement = 'monitor';
      // if (
      //   this.props.eventObject.businessEvent.subject &&
      //   this.props.eventObject.businessEvent.subject !== ''
      // ) {
      //   eventDetails += `<h3>${this.props.eventObject.businessEvent.subject}</h3>`;
      // }
    }

    return (
      <Timeline.Item
        dot={<Icon type={iconElement} style={{ fontSize: '20px' }} className='darkColor' />}
      >
        <div
          className='businessEventDate'
          style={{
            backgroundColor: 'rgba(128, 128, 128, 0.1)',
            color: 'black',
          }}
        >
          {eventDate}
        </div>
        <div
          className='businessEventDetails darkBottomBorder'
          dangerouslySetInnerHTML={{ __html: eventDetails }}
        />
        <br />
        <div
          className='businessEventDisplay'
          dangerouslySetInnerHTML={{ __html: this.props.eventObject.businessEvent.text }}
        />
        <button
          type='button'
          className='btn btn-primary btn-sm floatRigth'
          onClick={this.handleDelete}
        >
          <Icon type='delete' />
        </button>
        <hr className='hrHardBorder' />
      </Timeline.Item>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      deleteBusinessEvent: url => dispatch(deleteBusinessEvent(url)),
    },
  };
}

export default connect(null, mapDispatchToProps)(TimelineItem);
