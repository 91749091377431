import { Icon, Popover } from 'antd';
import React, { useState } from 'react';

//eslint-disable-next-line react/display-name
export const FollowUpButton = React.memo(props => {
  const ACTION_SEND_FOLLOW_UP = 'SEND_FOLLOW_UP';
  const ACTION_FOLLOW_UP_CALL = 'FOLLOW_UP_CALL';
  const ACTION_FOLLOW_UP_WHATSAPP = 'FOLLOW_UP_WHATSAPP';

  const [visible, setVisible] = useState(false);

  const getSendFollowUpText = () => {
    if (!props.dealFollowUpStage || props.dealFollowUpStage === 'NOT_STARTED') {
      return 'Enviar primer follow up';
    }

    if (props.dealFollowUpStage === 'FIRST_EMAIL_SENT') {
      return 'Enviar segundo follow up';
    }

    if (props.dealFollowUpStage === 'SECOND_EMAIL_SENT') {
      return 'Follow up completado';
    }

    return '';
  };

  const handleAction = action => {
    setVisible(false);
    if (action === ACTION_SEND_FOLLOW_UP) {
      props.handleSendFollowUp();
    } else if (action === ACTION_FOLLOW_UP_CALL) {
      props.handleFollowUpCall();
    } else {
      props.handleFollowUpWhatsapp();
    }
  };

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  const popoverContent = (
    <div>
      <div className={'row popup-option'}>
        <div className='col-sm-12 noPadding'>
          <a className={'popup-option'} onClick={() => handleAction(ACTION_FOLLOW_UP_CALL)}>
            <div className='row'>
              <div className='col-sm-3'>
                <Icon type='phone' style={{ fontSize: '20px' }} />
              </div>
              <div className='col-sm-9 noPadding'>Hacer seguimiento</div>
            </div>
          </a>
        </div>
      </div>
      <div className={`row popup-option${props.disableSendFollowUpOption ? '-disabled' : ''}`}>
        <div className='col-sm-12 noPadding'>
          <a
            disabled={props.disableSendFollowUpOption}
            className={`popup-option${props.disableSendFollowUpOption ? '-disabled' : ''}`}
            onClick={() => handleAction(ACTION_SEND_FOLLOW_UP)}
          >
            <div className='row'>
              <div className='col-sm-3'>
                <Icon type='mail' style={{ fontSize: '20px' }} />
              </div>
              <div className='col-sm-9 noPadding' style={{ whiteSpace: 'nowrap' }}>
                {getSendFollowUpText()}
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className={'row popup-option'} style={{ paddingTop: 5, paddingBottom: 1 }}>
        <div className='col-sm-12 noPadding'>
          <a className={'popup-option'} onClick={() => handleAction(ACTION_FOLLOW_UP_WHATSAPP)}>
            <div className='row'>
              <div className='col-sm-3'>
                <i className='fab fa-whatsapp' style={{ fontSize: '20px' }}></i>
              </div>
              <div className='col-sm-9 noPadding' style={{ alignSelf: 'center' }}>
                Hacer seguimiento
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      placement='right'
      content={popoverContent}
      trigger='click'
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <button
        type='button'
        className='btn btn-primary btn-block alignLeft buttonBreakLine'
        onClick={() => setVisible(!visible)}
        // style={{ borderColor: 'white' }}
      >
        Hacer seguimiento
      </button>
    </Popover>
  );
});
