import {
  GENERATE_INVOICE_PDF_ERROR,
  GENERATE_INVOICE_PDF_SUCCESS,
  INVOICEDETAILS_DATA_ERROR,
  INVOICEDETAILS_DATA_SUCCESS,
  INVOICEDETAILS_METASOURCE_ERROR,
  INVOICEDETAILS_METASOURCE_SUCCESS,
  INVOICEDETAILS_RESET,
  INVOICEDETAILS_SUBMIT_ENTRIES,
  INVOICEDETAILS_SUBMIT_ERROR,
  INVOICEDETAILS_SUBMIT_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  data: {
    url: '',
    etag: '',
    detail: {},
    error: false,
    errorDetail: '',
  },
  submit: {
    error: false,
    errorDetail: '',
  },
  generatePdf: {
    pdfData: '',
    inProgress: false,
    error: false,
    errorDetail: '',
  },
};

const InvoiceDetails = (state = initialState, action) => {
  switch (action.type) {
    case INVOICEDETAILS_RESET: {
      return Object.assign({}, initialState);
    }

    case INVOICEDETAILS_METASOURCE_SUCCESS: {
      return Object.assign({}, initialState, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case INVOICEDETAILS_METASOURCE_ERROR: {
      return Object.assign({}, initialState, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case INVOICEDETAILS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          url: action.url,
          etag: action.etag,
          detail: action.invoiceDetailsData,
          error: false,
          errorDetail: '',
        },
        submit: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case INVOICEDETAILS_DATA_ERROR: {
      return Object.assign({}, state, {
        data: {
          url: '',
          etag: '',
          detail: {},
          error: true,
          errorDetail: action.invoiceDetailsErrorDetails,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case INVOICEDETAILS_SUBMIT_SUCCESS: {
      const data = Object.assign({}, state.data, {
        etag: action.etag,
      });

      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
        },
        data: data,
      });
    }

    case INVOICEDETAILS_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.invoiceDetailsSubmitErrorDetails,
        },
      });
    }

    case INVOICEDETAILS_SUBMIT_ENTRIES: {
      const dataDetail = Object.assign({}, state.data.detail, {
        entries: action.entries,
      });
      const newData = Object.assign({}, state.data, {
        detail: dataDetail,
      });

      return Object.assign({}, state, {
        data: newData,
      });
    }

    case GENERATE_INVOICE_PDF_SUCCESS: {
      return Object.assign({}, state, {
        generatePdf: {
          pdfData: action.pdfData,
          fileName: action.fileName,
          inProgress: false,
        },
      });
    }

    case GENERATE_INVOICE_PDF_ERROR: {
      return Object.assign({}, state, {
        generatePdf: {
          pdfData: '',
          inProgress: false,
          error: true,
          errorDetail: action.errorMessage,
        },
      });
    }

    case 'DISABLE_ONCE': {
      return Object.assign({}, state, {
        generatePdf: {
          error: false,
        },
      });
    }

    default:
      return state;
  }
};

export default InvoiceDetails;
