import { isFlagActive } from 'hs-feature-flags';
import { FEATURE_FLAGS } from './shared/appConfig';

// ORIGNAL IMPLEMENTATION
export const initHotjar = async () => {
  let shouldLoadHotjar = false;
  try {
    shouldLoadHotjar = await isFlagActive(FEATURE_FLAGS.BRAIN_HOTJAR_ANALYTICS);
  } catch (e) {
    console.error('Could not retrieve feature flag for hotjar');
  }
  if (shouldLoadHotjar) {
    loadHotjar();
  }
};

// Script for site www.homyspace.com
const loadHotjar = function() {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: '1618949', hjsv: '6' };
    h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = h._scriptPath;
      a.appendChild(r);
    }
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
};

//////////////////////////////////////////////
////// TESTS FOR LOAD 2 HOTJAR TRACKERS //////
//////////////////////////////////////////////

// export const initHotjar = async trackId => {
//   let shouldLoadHotjar = false;
//   try {
//     if (window.hj) {
//       shouldLoadHotjar = true;
//     } else {
//       shouldLoadHotjar = await isFlagActive(FEATURE_FLAGS.BRAIN_HOTJAR_ANALYTICS);
//     }
//   } catch (e) {
//     console.error('Could not retrieve feature flag for hotjar');
//   }
//   if (shouldLoadHotjar) {
//     loadHotjar(trackId);
//   }
// };

// Test removing script node and overwriting hj function
// const loadHotjar = function(trackId) {
//   (function(h, o, t, j, a, r) {
//     h.hj = undefined;
//     let oldTrackId = '1670380';
//     if (a === '1670380') {
//       oldTrackId = '1670382';
//     }
//     const oldScriptPath = t + oldTrackId + j + '6';
//     const oldElem = document.querySelector('script[src*="' + oldScriptPath + '"]');
//     if (oldElem) oldElem.parentNode.removeChild(oldElem);
//     h.hj = function() {
//       (h.hj.q = h.hj.q || []).push(arguments);
//     };
//     h._hjSettings = { hjid: a, hjsv: '6' };
//     h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
//     if (!document.querySelector('script[src*="' + h._scriptPath + '"]')) {
//       a = o.getElementsByTagName('head')[0];
//       r = o.createElement('script');
//       r.async = 1;
//       r.src = h._scriptPath;
//       a.appendChild(r);
//     }
//   })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=', trackId);
// };
