export const getObjects = (obj, key, val, father = null) => {
  var objects = [];

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }

    if (typeof obj[i] === 'object') {
      objects = objects.concat(getObjects(obj[i], key, val, obj));
    } else if ((i === key && obj[i] === val) || (i === key && val === '')) {
      //
      objects.push(father);
    } else if (obj[i] === val && key === '') {
      objects.push(father);
    }
  }

  return objects;
};

export const getArrays = (obj, key, iFather = null) => {
  var objects = [];

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }

    if (Array.isArray(obj[i]) && i === key) {
      objects.push({ arrayName: iFather, values: obj[i] });
    } else if (typeof obj[i] === 'object') {
      objects = objects.concat(getArrays(obj[i], key, i));
    }
  }

  return objects;
};

export const removeDuplicates = objects =>
  objects.filter(function(item, pos, self) {
    return self.indexOf(item) === pos;
  });

//return an array of values that match on a certain key
export const getValues = (obj, key) => {
  var objects = [];

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }

    if (typeof obj[i] === 'object') {
      objects = objects.concat(getValues(obj[i], key));
    } else if (i === key) {
      objects.push(obj[i]);
    }
  }

  return objects;
};

//return an array of keys that match on a certain value
export const getKeys = (obj, val) => {
  var objects = [];

  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }

    if (typeof obj[i] === 'object') {
      objects = objects.concat(getKeys(obj[i], val));
    } else if (obj[i] === val) {
      objects.push(i);
    }
  }

  return objects;
};
