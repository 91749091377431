let environment = '';
let environment_v2 = '';
let environmentDossier = '';
let pdf_service = '';

// eslint-disable-next-line no-undef
export const STAGE = process.env.REACT_APP_HOMYBRAIN_STAGE;

switch (STAGE) {
  case 'dev': {
    pdf_service = 'https://pre.homybrain.com';
    environment = 'http://localhost:9090/api/v1';
    environment_v2 = 'http://localhost:3000/api/v2';
    environmentDossier = 'https://localhost:3000/pre/dossier';
    break;
  }

  case 'proxy': {
    pdf_service = 'http://localhost:3000';
    environment = 'http://localhost:3000/api/v1';
    environment_v2 = 'http://localhost:3000/api/v2';
    environmentDossier = 'https://preweb.homyspace.com/dossier';
    break;
  }

  case 'pre': {
    pdf_service = 'https://pre.homybrain.com';
    environment = 'https://pre.homybrain.com/api/v1';
    environment_v2 = 'https://pre.homybrain.com/api/v2';
    environmentDossier = 'https://preweb.homyspace.com/dossier';
    break;
  }

  case 'pre2': {
    pdf_service = 'https://pre2.homybrain.com';
    environment = 'https://pre2.homybrain.com/api/v1';
    environment_v2 = 'https://pre2.homybrain.com/api/v2';
    environmentDossier = 'https://pre2web.homyspace.com/dossier';
    break;
  }

  case 'precarlos': {
    pdf_service = 'https://pre.homybrain.com';
    environment = 'https://precarlos.homybrain.com/api/v1';
    environment_v2 = 'https://precarlos.homybrain.com/api/v2';
    environmentDossier = 'https://preweb.homyspace.com/dossier';
    break;
  }

  case 'premike': {
    pdf_service = 'https://pre.homybrain.com';
    environment = 'http://premike.homybrain.com:9090/api/v1';
    environment_v2 = 'http://premike.homybrain.com:8080/api/v2';
    environmentDossier = 'http://localhost:4000/dossier';
    break;
  }

  case 'prepablo': {
    pdf_service = 'https://pre.homybrain.com';
    environment = 'https://prepablo.homybrain.com/api/v1';
    environment_v2 = 'https://prepablo.homybrain.com/api/v2';
    environmentDossier = 'https://preweb.homyspace.com/dossier';
    break;
  }

  case 'staging': {
    pdf_service = 'https://staging.homybrain.com';
    environment = 'https://staging.homybrain.com/api/v1';
    environment_v2 = 'https://staging.homybrain.com/api/v2';
    environmentDossier = 'https://stagingweb.homyspace.com/dossier';
    break;
  }

  case 'prod': {
    pdf_service = 'https://homybrain.com';
    environment = 'https://homybrain.com/api/v1';
    environment_v2 = 'https://homybrain.com/api/v2';
    environmentDossier = 'https://www.homyspace.com/dossier';
    break;
  }

  case 'pruebas': {
    environment = 'http://tf-modular-alb-310028826.eu-west-1.elb.amazonaws.com/api/v1';
    environment_v2 = 'http://tf-modular-alb-310028826.eu-west-1.elb.amazonaws.com/api/v2';
    break;
  }

  default: {
    environment = '';
    break;
  }
}

export const HOMYSPACE_WEB_BASEPATHS = {
  pre: 'https://preweb.homyspace.com/',
  proxy: 'https://preweb.homyspace.com/',
  pre2: 'https://pre2web.homyspace.com/',
  staging: 'https://stagingweb.homyspace.com/',
  prod: 'https://www.homyspace.com/',
  prepablo: 'http://prepablo.homybrain.com:9090/',
  premike: 'http://premike.homybrain.com:9090/',
};

export const FEATURE_FLAGS = {
  BRAIN_HOTJAR_ANALYTICS: 'BRAIN_HOTJAR_ANALYTICS',
  AVAILABILITY_WSP_YES_NO: 'AVAILABILITY_WSP_SI_NO',
  AVAILABILITY_WSP_INTERMEDIA: 'AVAILABILITY_WSP_INTERMEDIA',
  PROPOSALS_WHATSAPP: 'PROPOSALS_WHATSAPP',
};

export const HOMYSPACE_WEB_BASEPATH = HOMYSPACE_WEB_BASEPATHS[STAGE];

export const ENDPOINT = environment;
export const ENDPOINT_V2 = environment_v2;
export const ENDPOINT_DOSSIER = environmentDossier;
export const ENDPOINT_PDF_SERVICE = pdf_service;

export const SORT_QUERY_STRING = 'sort';
export const FILTER_QUERY_STRING = '&filter=';
export const PAGE_SIZE = 15;

export const DATE_FORMAT = 'DD/MM/YYYY';
export const PARSE_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const MAP_RADIUS = 200;
export const MAXIMUM_PROPERTIES = 151;

export const TOTAL_STAGES = 5;

// Days to add/subtract in properties filter
export const DATE_FILTER_MODIFIERS = {
  // 5 days
  DAYS_FOR_EXACT_FLEXIBLE: 432000000,
  // 10 days
  DAYS_FOR_APPROX: 864000000,
  MINIMUM_RENTAL_DAYS_FOR_APPLY_MODIFIERS: 31,
};

export const COMMISSION_TO_PROVIDER_DATE_THRESHOLD = new Date('2021-11-26T04:00:00+01:00');
