import {
  NEW_PROPOSAL_CREATE_ERROR,
  NEW_PROPOSAL_LOCATION_ERROR,
  NEW_PROPOSAL_LOCATION_SUCCESS,
  PROPOSALS_METASOURCE_ERROR,
  PROPOSALS_METASOURCE_SUCCESS,
  PROPOSALS_SEND_ERROR,
  PROPOSALS_SEND_SUCCESS,
} from '../../shared/actionTypes';
import ProposalsApi from './ProposalsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink, openNotificationWithIcon } from '../../shared/utils/appUtils';
import { showOrLoadDealDetailsView } from '../DealDetails/DealDetailsActions';
import { browserHistory } from 'react-router';
import api from '../../shared/utils/api';
import { ENDPOINT_V2 } from '../../shared/appConfig';

export const metasourceSuccess = metasourceLinks => ({
  type: PROPOSALS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: PROPOSALS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const newProposalLocationSuccess = location => ({
  type: NEW_PROPOSAL_LOCATION_SUCCESS,
  location,
});

export const newProposalLocationError = errorMessage => ({
  type: NEW_PROPOSAL_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const createNewProposalError = errorMessage => ({
  type: NEW_PROPOSAL_CREATE_ERROR,
  createNewProposalErrorDetails: errorMessage,
});

export const sendProposalsSuccess = ids => ({
  type: PROPOSALS_SEND_SUCCESS,
  ids,
});

export const sendProposalsError = error => ({
  type: PROPOSALS_SEND_ERROR,
  errorDetail: error,
});

export const loadProposalsMetasource = () =>
  function(dispatch, getState) {
    return ProposalsApi.getMetasource(getState().Preload.mainMetasource.proposals.href)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const getNewProposalLocation = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Proposals.metasource,
      getState().Preload.metasourceStrings,
      'PROPOSAL_MAIN_METASOURCE_NEW_PROPOSAL'
    );

    return ProposalsApi.getNewProposalLocation(url.href)
      .then(response => {
        console.error(response.headers.location);
        // dispatch(newProposalLocationSuccess(response.headers.location));
      })
      .catch(error => {
        console.error(error);
        dispatch(newProposalLocationError(extractErrorMessage(error)));
      });
  };

export const createNewProposal = (url, proposalData) =>
  function(dispatch) {
    return ProposalsApi.saveProposalData(url, proposalData)
      .then(response => {
        const extraInfo = response.headers['hs-extra'];

        if (extraInfo) {
          openNotificationWithIcon('error', 'Oops!', extraInfo);
        }

        browserHistory.push(`/dealDetails/${response.data.proposal.dealId}`);

        try {
          api
            .request_retry(`${ENDPOINT_V2}/proposals`, 'post', 5, {
              body: JSON.stringify({ id: response.data.proposal.id }),
            })
            .catch(err => {
              console.error('Proposals RelevanceService call error: ' + err.errorMessage);
            });
        } catch (e) {
          console.error('Proposal RelevanceService call error: ' + e);
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(createNewProposalError(extractErrorMessage(error)));
      });
  };

export const sendProposals = (body, id) =>
  function(dispatch, getState) {
    let url = getMetasourceLink(
      getState().Proposals.metasource,
      getState().Preload.metasourceStrings,
      'PROPOSAL_UPDATE_STAGE'
    );

    return ProposalsApi.sendProposals(url, body)
      .then(() => {
        openNotificationWithIcon('success', 'Hecho!', 'Se han enviado las propuestas');
        dispatch(showOrLoadDealDetailsView(id));
      })
      .catch(error => {
        console.error(error);
        const err = extractErrorMessage(error);

        openNotificationWithIcon('error', 'oops!', err);
      });
  };

export const sendCheckin = (ids, dealId) =>
  function(dispatch, getState) {
    let url = getMetasourceLink(
      getState().Proposals.metasource,
      getState().Preload.metasourceStrings,
      'PROPOSAL_SEND_CHECKIN'
    );

    console.error('ProposalActions:: url', url);
    ids = ids.join(',');
    url = url.href.replace('{ids}', ids);

    return ProposalsApi.sendCheckin(url)
      .then(() => {
        openNotificationWithIcon('success', 'Hecho!', 'Se han enviado los datos para checkin');
        dispatch(showOrLoadDealDetailsView(dealId));
      })
      .catch(error => {
        console.error(error);
        const err = extractErrorMessage(error);

        openNotificationWithIcon('error', 'oops!', err);
      });
  };
