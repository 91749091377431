import { browserHistory } from 'react-router';
import {
  COMPANYBASECONTACTANDCHILDREN_DATA_ERROR,
  COMPANYBASECONTACTANDCHILDREN_DATA_SUCCESS,
  COMPANYCONTACT_ETAG_SUCCESS,
  COMPANYCONTACT_SUBMIT_ERROR,
  COMPANYCONTACT_SUBMIT_SUCCESS,
  COMPANYCONTACTS_DATA_ERROR,
  COMPANYCONTACTS_DATA_SUCCESS,
  COMPANYDETAILS_DATA_ERROR,
  COMPANYDETAILS_DATA_SUCCESS,
  COMPANYDETAILS_METASOURCE_ERROR,
  COMPANYDETAILS_METASOURCE_SUCCESS,
  COMPANYDETAILS_RESET,
  COMPANYDETAILS_SUBMIT_ERROR,
  COMPANYDETAILS_SUBMIT_SUCCESS,
  NEW_COMPANY_CREATE_ERROR,
  NEW_CONTACT_CREATE_ERROR,
  NEW_CONTACT_CREATE_SUCCESS,
  NEW_CONTACT_LOCATION_ERROR,
  NEW_CONTACT_LOCATION_SUCCESS,
} from '../../shared/actionTypes';
import CompanyDetailsApi from './CompanyDetailsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink, normalizeChildrenContacts } from '../../shared/utils/appUtils';
import { deleteContactError, deleteContactSuccess } from '../ContactDetails/ContactDetailsActions';
import ContactDetailsApi from '../ContactDetails/ContactDetailsApi';

export const resetState = () => ({
  type: COMPANYDETAILS_RESET,
});

export const metasourceSuccess = metasourceLinks => ({
  type: COMPANYDETAILS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: COMPANYDETAILS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const companyDetailsDataSuccess = (data, url, etag) => ({
  type: COMPANYDETAILS_DATA_SUCCESS,
  companyDetailsData: data,
  url,
  etag,
});

export const companyDetailsDataError = errorMessage => ({
  type: COMPANYDETAILS_DATA_ERROR,
  companyDetailsErrorDetails: errorMessage,
});

export const companyContactsDataSuccess = data => ({
  type: COMPANYCONTACTS_DATA_SUCCESS,
  companyContactsData: data,
});

export const companyContactsDataError = errorMessage => ({
  type: COMPANYCONTACTS_DATA_ERROR,
  companyContactsErrorDetails: errorMessage,
});

export const companyBaseContactAndChildrenDataSuccess = data => ({
  type: COMPANYBASECONTACTANDCHILDREN_DATA_SUCCESS,
  companyBaseContactAndChildrenData: data,
});

export const companyBaseContactAndChildrenDataError = errorMessage => ({
  type: COMPANYBASECONTACTANDCHILDREN_DATA_ERROR,
  companyBaseContactAndChildrenErrorDetails: errorMessage,
});

export const companyDetailsSubmitSuccess = etag => ({
  type: COMPANYDETAILS_SUBMIT_SUCCESS,
  etag,
});

export const companyDetailsSubmitError = errorMessage => ({
  type: COMPANYDETAILS_SUBMIT_ERROR,
  companyDetailsSubmitErrorDetails: errorMessage,
});

export const companyContactEtagSuccess = etag => ({
  type: COMPANYCONTACT_ETAG_SUCCESS,
  etag,
});

export const companyContactSubmitSuccess = etag => ({
  type: COMPANYCONTACT_SUBMIT_SUCCESS,
  etag,
});

export const companyContactSubmitError = errorMessage => ({
  type: COMPANYCONTACT_SUBMIT_ERROR,
  companyContactSubmitErrorDetails: errorMessage,
});

export const createNewCompanyError = errorMessage => ({
  type: NEW_COMPANY_CREATE_ERROR,
  createNewCompanyErrorDetails: errorMessage,
});

export const newContactLocationSuccess = location => ({
  type: NEW_CONTACT_LOCATION_SUCCESS,
  location,
});

export const newContactLocationError = errorMessage => ({
  type: NEW_CONTACT_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const createNewContactError = errorMessage => ({
  type: NEW_CONTACT_CREATE_ERROR,
  createNewContactErrorDetails: errorMessage,
});
export const createNewContactSuccess = () => ({
  type: NEW_CONTACT_CREATE_SUCCESS,
});

export const getCompanyDetailsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().CompanyDetails;
    const { metasourceStrings } = getState().Preload;
    const urlReduced = getMetasourceLink(
      metasource,
      metasourceStrings,
      'COMPANY_DETAIL_METASOURCE_COMPANY_REDUCED'
    );

    if (!urlReduced || !urlReduced.href) {
      return null;
    }

    return CompanyDetailsApi.getCompanyDetailsData(urlReduced.href)
      .then(response => {
        const { etag } = response.headers;

        dispatch(companyDetailsDataSuccess(response.data.company, urlReduced.href, etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(companyDetailsDataError(extractErrorMessage(error)));
      });
  };

export const setNewCompanyDetails = companyDetails =>
  function(dispatch) {
    dispatch(companyDetailsDataSuccess(companyDetails, null, null));
  };

export const getCompanyMetasourceLinks = (companyId, getState) => {
  const url = getState().Preload.mainMetasource.companies.href;

  return CompanyDetailsApi.getMetasource(url, companyId).then(response => response.data._links);
};

export const showOrLoadCompanyDetailsView = id =>
  function(dispatch, getState) {
    if (!id) {
      dispatch(companyDetailsDataSuccess(null, null, null));

      return;
    }

    return getCompanyMetasourceLinks(id, getState)
      .then(links => {
        dispatch(metasourceSuccess(links));
        dispatch(getCompanyDetailsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const getCompanyContactsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().CompanyDetails;
    const { metasourceStrings } = getState().Preload;
    const urlChildren = getMetasourceLink(
      metasource,
      metasourceStrings,
      'COMPANY_DETAIL_METASOURCE_COMPANY_CHILDREN'
    );

    if (!urlChildren) {
      return null;
    }

    return CompanyDetailsApi.getCompanyDetailsData(urlChildren.href)
      .then(response => {
        const normalizedContacts = normalizeChildrenContacts(
          response.data.company.baseContact.contact.children
        );

        dispatch(companyContactsDataSuccess(normalizedContacts));
      })
      .catch(error => {
        console.error(error);
        dispatch(companyContactsDataError(extractErrorMessage(error)));
      });
  };

export const filterCompanyContactsData = listOfContacts =>
  function(dispatch, getState) {
    if (!listOfContacts) {
      return;
    }

    const companyContacts = getState().CompanyDetails.contacts.detail;
    const companyContactsFiltered = [];

    for (let i = 0; i < companyContacts.length; i++) {
      const currentValue = companyContacts[i];

      for (let j = 0; j < listOfContacts.length; j++) {
        if (listOfContacts[j].contact.id === currentValue.contact.id) {
          companyContactsFiltered.push(currentValue);
        }
      }
    }

    dispatch(companyContactsDataSuccess(companyContactsFiltered));
  };

export const getCompanyBaseContactAndChildren = url =>
  function(dispatch) {
    return CompanyDetailsApi.getCompanyDetailsData(url)
      .then(response => {
        const normalizedContacts = normalizeChildrenContacts(
          new Array(response.data.company.baseContact)
        );

        dispatch(companyBaseContactAndChildrenDataSuccess(normalizedContacts));
      })
      .catch(error => {
        console.error(error);
        dispatch(companyBaseContactAndChildrenDataError(extractErrorMessage(error)));
      });
  };

export const saveCompanyDetailsData = (show = false, additionalCompanyDetailsData = {}) =>
  function(dispatch, getState) {
    const companyDetailsData = {
      ...(getState().CompanyDetails.data.detail || {}),
      ...(additionalCompanyDetailsData || {}),
    };
    const { etag } = getState().CompanyDetails.data;
    const { url } = getState().CompanyDetails.data;

    return CompanyDetailsApi.saveCompanyDetailsData(url, companyDetailsData, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(companyDetailsSubmitSuccess(etag));
        if (show) {
          browserHistory.push(`/companyDetails/${companyDetailsData.id}`);
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(companyDetailsSubmitError(extractErrorMessage(error)));
      });
  };

export const saveCompanyContactData = (data, url) =>
  function(dispatch, getState) {
    const { etag } = getState().CompanyDetails.contact;
    const { parent } = data;

    return CompanyDetailsApi.saveCompanyContactData(url, data, parent, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(companyContactSubmitSuccess(etag));
        dispatch(getCompanyContactsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(companyContactSubmitError(extractErrorMessage(error)));
      });
  };

export const deleteCompanyContactData = contact =>
  function(dispatch) {
    const url = contact._links.self.href;

    // 1. Se recupera el contacto para obtener el version
    return ContactDetailsApi.getContactDetailsData(url)
      .then(response => {
        const { etag } = response.headers;

        // 2. Se elimina el contacto con el etag recuperado
        return ContactDetailsApi.doDelete(url, etag)
          .then(() => {
            dispatch(deleteContactSuccess());
          })
          .catch(error => {
            console.error(error);
            dispatch(deleteContactError(extractErrorMessage(error)));
          });
      })
      .catch(error => {
        console.error(error);
        dispatch(deleteContactError(extractErrorMessage(error)));
      });
  };

export const getCompanyContactEtag = url =>
  function(dispatch) {
    return CompanyDetailsApi.getCompanyDetailsData(url)
      .then(response => {
        const { etag } = response.headers;

        dispatch(companyContactEtagSuccess(etag));
      })
      .catch(error => {
        console.error(error);
      });
  };

export const createNewCompany = (url, companyDetailsData) =>
  function(dispatch) {
    return CompanyDetailsApi.saveCompanyDetailsData(url, companyDetailsData)
      .then(response => {
        browserHistory.push(`/companyDetails/${response.data.company.id}`);
      })
      .catch(error => {
        console.error(error);
        dispatch(createNewCompanyError(extractErrorMessage(error)));
      });
  };

export const getNewContactLocation = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().CompanyDetails.metasource,
      getState().Preload.metasourceStrings,
      'COMPANY_DETAIL_METASOURCE_NEW_CONTACT'
    );

    return CompanyDetailsApi.getNewContactLocation(url.href)
      .then(location => {
        dispatch(newContactLocationSuccess(location.headers.location));
      })
      .catch(error => {
        console.error(error);
        dispatch(newContactLocationError(extractErrorMessage(error)));
      });
  };
