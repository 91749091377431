import React, { useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import {
  BUSINESS_EVENT_TYPES,
  CONTACT_TYPE,
  getOptionsForSelect,
  getTextValueFromSelectOptions,
} from '../../shared/utils/appUtils';
import { useCheckAvailabilityHandler } from '../../shared/utils/hooks/useCheckAvailabilityHandler';
import { Tooltip } from 'antd';
import { isUsualContact } from '../../shared/utils/contactUtils';

const ContactsBigTable = ({ ...props }) => {
  const { contacts, bondedDeal, currentUsername, propertyData } = props;
  const [areContactsOfProvider] = useState(() => {
    if (!isNil(contacts)) {
      for (const contact of contacts) {
        if (get(contact, 'contact.relatedProvider.roles')) {
          return true;
        } else if (get(contact, 'contact.relatedCompany.roles')) {
          return false;
        }
      }

      return false;
    }
  });
  const {
    handleCheckAvailabilityByEmail,
    handleCheckAvailabilityByWhatsapp,
    handleCopyAvailabilityMessage,
    handleRegisterBusinessEvent,
  } = useCheckAvailabilityHandler(bondedDeal, currentUsername, propertyData);

  return (
    <div className='row'>
      <div className='col-12'>
        <table className='table table-hover'>
          <thead className='thead-light'>
            <tr className='table-title'>
              <th>NOMBRE</th>
              <th>FUNCIÓN</th>
              <th>CARGO</th>
              {/* <th>CONTACTO PRINCIPAL</th> */}
              <th>EMAIL</th>
              <th>TELÉFONO</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {/* {contacts && contacts.length > 0 && contacts.map(contact => (
              getContactRow(contact.contact)

            ))} */}
            {contacts &&
              contacts.length > 0 &&
              contacts.map(contact => {
                let contactWithChildren = [contact.contact];
                const childrens = get(contact, 'contact.children', []);

                if (childrens && childrens.length > 0) {
                  for (const children of childrens) {
                    contactWithChildren = [...contactWithChildren, children.contact];
                  }
                }

                return contactWithChildren.map(contact =>
                  getContactRow(
                    contact,
                    areContactsOfProvider,
                    props.optionsForContactRole,
                    handleCheckAvailabilityByEmail,
                    handleCheckAvailabilityByWhatsapp,
                    handleCopyAvailabilityMessage,
                    handleRegisterBusinessEvent,
                    props.onAvailabilityCheck
                  )
                );
              })}
          </tbody>
        </table>
      </div>
      <style jsx>
        {`
          table th {
            font-size: 1rem !important;
            font-weight: 600;
          }

          table td {
            color: #000000;
            font-family: 'Open Sans';
            font-size: 14px;
          }

          .space-before-icon {
            width: 21px;
            height: 21px;
            display: inline-block;
            margin-right: 2px;
            margin-top: -4px;
          }
          .icon-button-font-size {
            font-size: 1.2rem !important;
          }import { isEmpty } from 'lodash/isEmpty';
import { isNil } from 'lodash/isNil';

          .fas.homy-color-icon {
            color: #eeaf30;
            font-size: 15px;
          }
          .inmuebles__icon__icon {
            font-size: 20px;
            color: black;
          }
          .icon-disabled {
            color: darkgray;
          }
          .contact-link {
            color: black;
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

const getContactRow = (
  contact,
  areContactsOfProvider,
  optionsForContactRole,
  handleCheckAvailabilityByEmail,
  handleCheckAvailabilityByWhatsapp,
  handleCopyAvailabilityMessage,
  handleRegisterBusinessEvent,
  onAvailabilityCheck
) => {
  let name = '';
  const contactType = get(contact, 'contactType', '');

  if (contactType === CONTACT_TYPE.COMPANY) {
    name = get(contact, 'lastOTradeName', get(contact, 'name', ''));
  } else {
    name = get(contact, 'name', '') + ' ' + get(contact, 'lastOTradeName', '');
  }

  const jobTitle = get(contact, 'jobTitle', '');
  const roles = getRolesList(contact, optionsForContactRole);
  const emails = getEmailList(contact, handleRegisterBusinessEvent, onAvailabilityCheck);
  const phones = getPhoneList(contact, handleRegisterBusinessEvent, onAvailabilityCheck);
  const contactMail = !isEmpty(get(contact, 'email'))
    ? get(contact, 'email')
    : get(contact, 'email2');

  return (
    <tr className='table-body'>
      <td className='remove-top-border'>
        {isUsualContact(contact) && (
          <div className='space-before-icon'>
            <img src='../../img/icon-star.png' alt='' className='mr-2' />
          </div>
        )}
        {name}
      </td>
      <td>{roles}</td>
      <td>{jobTitle}</td>
      {/* <td>
        <div className='d-flex justify-content-center'>
          <div className='bgWhatsapp mr-3'>
            <span className='typeOfProperty spanBg'>whatsapp</span>
          </div>
          <span>677 78 89 00</span>
        </div>
      </td> */}
      <td>{emails}</td>
      <td>{phones}</td>
      {areContactsOfProvider && (
        <td className='icon-table-container'>
          <div className='d-flex justify-content-around'>
            <button
              className='btn btn-outline-homyColor'
              onClick={() => {
                handleCheckAvailabilityByEmail(contact);
                onAvailabilityCheck();
              }}
              disabled={!contactMail}
            >
              {/* <i className='fas fa-calendar-alt inmuebles__icon__icon' /> */}
              <Tooltip placement='top' title='Confirmar disponibilidad por mail'>
                <i
                  className={`fas fa-calendar-alt inmuebles__icon__icon ${
                    !contactMail ? 'icon-disabled' : ''
                  }`}
                />
              </Tooltip>
            </button>

            <button
              className='btn btn-outline-homyColor'
              onClick={() => {
                handleCheckAvailabilityByWhatsapp(contact);
                onAvailabilityCheck();
              }}
              disabled={!get(contact, 'mobilePhoneNumber')}
            >
              {/* <i className='fab fa-whatsapp inmuebles__icon__icon' /> */}
              <Tooltip placement='top' title='Confirmar disponibilidad por whatsapp'>
                <i
                  className={`fab fa-whatsapp inmuebles__icon__icon ${
                    !get(contact, 'mobilePhoneNumber') ? 'icon-disabled' : ''
                  }`}
                />
              </Tooltip>
            </button>

            <button
              className='btn btn-outline-homyColor'
              onClick={() => {
                handleCopyAvailabilityMessage();
                onAvailabilityCheck();
              }}
            >
              <Tooltip placement='top' title='Copiar mensaje de disponibilidad'>
                <i className='fas fa-copy inmuebles__icon__icon' />
              </Tooltip>
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};

const getRolesList = (contact, optionsForContactRole) => {
  let roles = get(contact, 'relatedProvider.roles');

  if (!roles) {
    roles = get(contact, 'relatedCompany.roles');
  }

  if (roles && roles.length > 0) {
    return (
      <ul className='list-unstyled'>
        {roles.map((role, key) => (
          <li key={key}>{translateContactRole(role, optionsForContactRole)}</li>
        ))}
      </ul>
    );
  }

  return null;
};

const translateContactRole = (role, optionsForContactRole) =>
  getTextValueFromSelectOptions(role, optionsForContactRole);

const getEmailList = (contact, handleRegisterBusinessEvent, onAvailabilityCheck) => {
  const email = get(contact, 'email');
  const email2 = get(contact, 'email2');

  if (email || email2) {
    return (
      <ul className='list-unstyled'>
        {email && email !== '' && (
          <li>
            <a
              className='contact-link'
              href={`mailto:pruebas@homyspace.com`}
              onClick={() => {
                handleRegisterBusinessEvent(contact, BUSINESS_EVENT_TYPES.EMAIL);
                onAvailabilityCheck();
              }}
            >
              {email}
            </a>
          </li>
        )}
        {email2 && email2 !== '' && (
          <li>
            <a
              className='contact-link'
              href={`mailto:pruebas@homyspace.com`}
              onClick={() => {
                handleRegisterBusinessEvent(contact, BUSINESS_EVENT_TYPES.EMAIL);
                onAvailabilityCheck();
              }}
            >
              {email2}
            </a>
          </li>
        )}
      </ul>
    );
  }

  return null;
};

const getPhoneList = (contact, handleRegisterBusinessEvent, onAvailabilityCheck) => {
  const phoneNumber = get(contact, 'phoneNumber');
  const mobilePhoneNumber = get(contact, 'mobilePhoneNumber');

  if (phoneNumber || mobilePhoneNumber) {
    return (
      <ul className='list-unstyled'>
        {phoneNumber && phoneNumber !== '' && (
          <li>
            <a
              className='contact-link'
              href={`tel:${phoneNumber}`}
              onClick={() => {
                handleRegisterBusinessEvent(contact, BUSINESS_EVENT_TYPES.CALL);
                onAvailabilityCheck();
              }}
            >
              {phoneNumber}
            </a>
          </li>
        )}
        {mobilePhoneNumber && mobilePhoneNumber !== '' && (
          <li>
            <a
              className='contact-link'
              href={`tel:${mobilePhoneNumber}`}
              onClick={() => {
                handleRegisterBusinessEvent(contact, BUSINESS_EVENT_TYPES.CALL);
                onAvailabilityCheck();
              }}
            >
              {mobilePhoneNumber}
            </a>
          </li>
        )}
      </ul>
    );
  }

  return null;
};

const mapStateToProps = function(state) {
  return {
    optionsForContactRole: getOptionsForSelect(state.Preload.optionLabels, 'ContactRole'),
    currentUsername: state.Preload.currentUserInformation.username,
  };
};

export default connect(mapStateToProps)(ContactsBigTable);
