import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import './HomyPagination.css';

const HomyPagination = props => (
  <React.Fragment>
    <Pagination
      current={props.current}
      data-test-id='pagination'
      defaultCurrent={props.defaultCurrent}
      onChange={props.onChange}
      onShowSizeChange={props.onShowSizeChange}
      pageSize={props.pageSize}
      pageSizeOptions={props.pageSizeOptions}
      showQuickJumper={props.showQuickJumper}
      showSizeChanger={props.showSizeChanger}
      showTotal={(total, range) => `${range[0]}-${range[1]} de ${total} registros`}
      total={props.totalCount}
    />
    <style jsx>
      {`
        .ant-table-footer {
          background-color: white !important;
        }
      `}
    </style>
  </React.Fragment>
);
HomyPagination.propTypes = {
  current: PropTypes.number.isRequired,
  defaultCurrent: PropTypes.number,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  showQuickJumper: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
};

HomyPagination.defaultProps = {
  defaultCurrent: 1,
  onChange: () => {},
  onShowSizeChange: () => {},
  pageSize: 25,
  pageSizeOptions: ['10', '15', '20', '25', '30'],
  showQuickJumper: true,
  showSizeChanger: true,
};
export default HomyPagination;
