import React from 'react';
// import { connect } from 'react-redux';
import { Button, Col, Form, Input, Row } from 'antd';
import get from 'lodash/get';
// import Select from 'react-select';
// import { createNewContact } from '../../modules/Contacts/ContactsActions';
// import { saveContactDetailsDataFromParent } from '../../modules/ContactDetails/ContactDetailsActions';
// import {
//   validateInputs,
//   normalizePhoneNumber,
// } from '../../shared/utils/formUtils';
// import {
//   getOptionsForSelect,
//   openNotificationWithIcon,
// } from '../../shared/utils/appUtils';
import SelectContact from '../../components/SelectContact/SelectContact';
import HomySelect from '../../components/HomyComponents/HomySelect';
// import isNil from 'lodash/isNil';

const { Item: FormItem } = Form;

class CreateNewContact extends React.Component {
  validateFields = e => {
    e.preventDefault();
    this.props.form.validateFields(err => {
      if (!err) {
        this.props.handleSubmitNewContact();
      }
    });
  };

  handleChange = e => {
    this.props.form.setFieldsValue({
      [e.target.name]: e.target.value,
    });
    if (e.target.type === 'select') {
      this.props.handleSelectChange(e.target.name, e.target.value, e.target.mode);
    } else {
      this.props.handleChangeNewContact(e);
    }
  };

  render() {
    const { props } = this;
    const { getFieldDecorator } = props.form;

    return (
      <Form id='formNewContact' onSubmit={this.validateFields} layout='vertical'>
        <Row>
          <Col span={24}>
            <SelectContact
              name='parent'
              id='parentContact'
              label='Contacto padre'
              isRequired={false}
              handleChange={props.handleSelectParentChange}
              prefilter={props.selectParentContactPrefilter}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label='Tipo'>
              {getFieldDecorator('contactType', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor, selecciona un valor para Tipo',
                  },
                ],
              })(
                <HomySelect
                  name='contactType'
                  options={get(props, 'options.contactType', '')}
                  onChange={value =>
                    this.handleChange({
                      target: {
                        name: 'contactType',
                        value,
                        type: 'select',
                        mode: 'default',
                      },
                    })
                  }
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {props.contactInfo.showMoreParentFields && (
          <Row>
            <Col span={24}>
              <FormItem label='Función'>
                {getFieldDecorator('contactRole', {
                  rules: [
                    {
                      required: true,
                      message: 'Por favor, selecciona un valor para Función',
                    },
                  ],
                })(
                  <HomySelect
                    name='contactRole'
                    options={get(props, 'options.contactRole', '')}
                    onChange={value =>
                      this.handleChange({
                        target: {
                          name: 'contactRole',
                          value,
                          type: 'select',
                          mode: 'multiple',
                        },
                      })
                    }
                    mode='multiple'
                    showArrow
                    showSearch
                    allowClear
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <FormItem label='Nombre'>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Por favor, introduce un nombre',
                  },
                ],
              })(
                <Input
                  id='contactName'
                  name='name'
                  placeholder='Ana'
                  // value={get(props, 'contactInfo.name', '')}
                  onChange={this.handleChange}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label='Apellidos / Razón social'>
              <Input
                id='contactLastOTradeName'
                name='lastOTradeName'
                placeholder='García García'
                // value={get(props, 'contactInfo.lastOTradeName', '')}
                onChange={this.handleChange}
              />
            </FormItem>
          </Col>
        </Row>
        {(props.contactInfo.contactType === '' || props.contactInfo.contactType === 'PERSON') && (
          <Row>
            <Col span={24}>
              <FormItem label='Cargo'>
                <Input
                  id='jobTitle'
                  name='jobTitle'
                  placeholder='CEO'
                  // value={get(props, 'contactInfo.jobTitle', '')}
                  onChange={this.handleChange}
                />
              </FormItem>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <FormItem label='NIF'>
              <Input
                id='contactIdentificationCode'
                name='identificationCode'
                placeholder='12345678Z'
                // value={get(props, 'contactInfo.identificationCode', '')}
                onChange={this.handleChange}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label='Email'>
              <Input
                id='contactEmail'
                name='email'
                placeholder='pruebas@pruebas.com'
                // value={get(props, 'contactInfo.email', '')}
                onChange={this.handleChange}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label='Teléfono fijo'>
              <Input
                id='contactPhoneNumber'
                name='phoneNumber'
                placeholder='961231212'
                // value={get(props, 'contactInfo.phoneNumber', '')}
                onChange={this.handleChange}
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem label='Teléfono móvil'>
              <Input
                id='contactMobilePhoneNumber'
                name='mobilePhoneNumber'
                placeholder='616232323'
                // value={get(props, 'contactInfo.mobilePhoneNumber', '')}
                onChange={this.handleChange}
              />
            </FormItem>
          </Col>
        </Row>

        <Row type='flex' justify='end'>
          <Col>
            <Button htmlType='submit' className='btn-primary' loading={props.submitting}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(CreateNewContact);
