import {
  CREATE_NEW_DEAL_ERROR,
  CREATE_NEW_DEAL_SUCCESS,
  DEALDETAILS_BONDED,
  DEALDETAILS_DATA_ERROR,
  DEALDETAILS_DATA_SUCCESS,
  DEALDETAILS_DELETE_ERROR,
  DEALDETAILS_DELETE_SUCCESS,
  DEALDETAILS_FOLLOW_UP_ERROR,
  DEALDETAILS_FOLLOW_UP_SUCCESS,
  DEALDETAILS_GENERATE_PROPOSAL_ERROR,
  DEALDETAILS_GENERATE_PROPOSAL_SUCCESS,
  DEALDETAILS_METASOURCE_ERROR,
  DEALDETAILS_METASOURCE_SUCCESS,
  DEALDETAILS_PROPOSALS_RELEVANCE_SUCCESS,
  DEALDETAILS_RESET,
  DEALDETAILS_SUBMIT_ERROR,
  DEALDETAILS_SUBMIT_SUCCESS,
  DEALDETAILS_PROPOSALS_EMAIL_SENT_SUCCESSFULLY,
  DEALDETAILS_RESET_PROPOSALS_EMAIL_SENT_SUCCESSFULLY,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  data: {
    url: '',
    etag: '',
    detail: {},
    error: false,
    errorDetail: '',
    links: {},
  },
  submit: {
    error: false,
    errorDetail: '',
  },
  create: {
    error: false,
    errorDetail: '',
  },
  followUpSubmit: {
    error: false,
    errorDetail: '',
  },
  generateProposal: {
    error: false,
    errorDetail: '',
  },
  proposalsRelevance: {},
  proposalsEmailSentSuccessfully: false,
};

const DealDetails = (state = initialState, action) => {
  switch (action.type) {
    case DEALDETAILS_RESET: {
      return Object.assign({}, initialState);
    }

    case DEALDETAILS_METASOURCE_SUCCESS: {
      return Object.assign({}, initialState, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case DEALDETAILS_METASOURCE_ERROR: {
      return Object.assign({}, initialState, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case DEALDETAILS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          url: action.url ? action.url : state.data.url,
          etag: action.etag ? action.etag : state.data.etag,
          detail: action.dealDetailsData,
          error: false,
          errorDetail: '',
          links: action.links ? action.links : state.data.links,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case DEALDETAILS_DATA_ERROR: {
      return Object.assign({}, state, {
        data: {
          url: '',
          etag: '',
          detail: {},
          error: true,
          errorDetail: action.dealDetailsErrorDetails,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case DEALDETAILS_SUBMIT_SUCCESS: {
      const data = Object.assign({}, state.data, {
        etag: action.etag,
      });

      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
        },
        data,
      });
    }

    case DEALDETAILS_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.dealDetailsSubmitErrorDetails,
        },
      });
    }

    case DEALDETAILS_BONDED: {
      return Object.assign({}, state, { bondedDeal: action.bondedDeal });
    }

    case DEALDETAILS_DELETE_SUCCESS: {
      const data = Object.assign({}, state.data, {
        etag: action.etag,
      });

      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
        },
        data,
      });
    }

    case DEALDETAILS_DELETE_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.dealDetailsDeleteErrorDetails,
        },
      });
    }

    case CREATE_NEW_DEAL_SUCCESS: {
      return Object.assign({}, state, {
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case CREATE_NEW_DEAL_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: true,
          errorDetail: action.createNewDealErrorDetails,
        },
      });
    }

    case DEALDETAILS_FOLLOW_UP_SUCCESS: {
      return Object.assign({}, state, {
        followUpSubmit: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case DEALDETAILS_FOLLOW_UP_ERROR: {
      return Object.assign({}, state, {
        followUpSubmit: {
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case DEALDETAILS_GENERATE_PROPOSAL_SUCCESS: {
      return Object.assign({}, state, {
        generateProposal: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case DEALDETAILS_GENERATE_PROPOSAL_ERROR: {
      return Object.assign({}, state, {
        generateProposal: {
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case DEALDETAILS_PROPOSALS_RELEVANCE_SUCCESS: {
      return Object.assign({}, state, {
        proposalsRelevance: action.proposalsRelevance,
      });
    }

    case DEALDETAILS_PROPOSALS_EMAIL_SENT_SUCCESSFULLY: {
      return Object.assign({}, state, {
        proposalsEmailSentSuccessfully: true,
      });
    }

    case DEALDETAILS_RESET_PROPOSALS_EMAIL_SENT_SUCCESSFULLY: {
      return Object.assign({}, state, {
        proposalsEmailSentSuccessfully: false,
      });
    }

    default:
      return state;
  }
};

export default DealDetails;
