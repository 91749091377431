import { browserHistory } from 'react-router';
import {
  CREATE_NEW_DEAL_ERROR,
  CREATE_NEW_DEAL_SUCCESS,
  DEALDETAILS_BONDED,
  DEALDETAILS_DATA_ERROR,
  DEALDETAILS_DATA_SUCCESS,
  DEALDETAILS_DELETE_ERROR,
  DEALDETAILS_DELETE_SUCCESS,
  DEALDETAILS_FOLLOW_UP_ERROR,
  DEALDETAILS_FOLLOW_UP_SUCCESS,
  DEALDETAILS_GENERATE_PROPOSAL_ERROR,
  DEALDETAILS_GENERATE_PROPOSAL_SUCCESS,
  DEALDETAILS_METASOURCE_ERROR,
  DEALDETAILS_METASOURCE_SUCCESS,
  DEALDETAILS_PROPOSALS_RELEVANCE_SUCCESS,
  DEALDETAILS_RESET,
  DEALDETAILS_SUBMIT_ERROR,
  DEALDETAILS_SUBMIT_SUCCESS,
  DEALDETAILS_PROPOSALS_EMAIL_SENT_SUCCESSFULLY,
  DEALDETAILS_RESET_PROPOSALS_EMAIL_SENT_SUCCESSFULLY,
} from '../../shared/actionTypes';
import DealDetailsApi from './DealDetailsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink, openNotificationWithIcon } from '../../shared/utils/appUtils';
import { ENDPOINT, ENDPOINT_V2 } from '../../shared/appConfig';
import api from '../../shared/utils/api';
import { createNewBusinessEventSuccess } from '../Events/EventsActions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import replace from 'lodash/replace';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import CompanyDetailsApi from '../CompanyDetails/CompanyDetailsApi';
import { orderBy } from 'lodash';

export const resetState = () => ({
  type: DEALDETAILS_RESET,
});

export const metasourceSuccess = metasourceLinks => ({
  type: DEALDETAILS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: DEALDETAILS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const dealDetailsDataSuccess = (data, url, etag, links) => ({
  type: DEALDETAILS_DATA_SUCCESS,
  dealDetailsData: data,
  url,
  etag,
  links,
});

export const dealDetailsDataError = errorMessage => ({
  type: DEALDETAILS_DATA_ERROR,
  dealDetailsErrorDetails: errorMessage,
});

export const dealDetailsSubmitSuccess = etag => ({
  type: DEALDETAILS_SUBMIT_SUCCESS,
  etag,
});

export const dealDetailsSubmitError = errorMessage => ({
  type: DEALDETAILS_SUBMIT_ERROR,
  dealDetailsSubmitErrorDetails: errorMessage,
});

export const dealDetailsBonded = data => ({
  type: DEALDETAILS_BONDED,
  bondedDeal: data,
});

export const dealDetailsUnbonded = () => ({
  type: DEALDETAILS_BONDED,
  bondedDeal: null,
});

export const deleteDealDetailSuccess = dealScore => ({
  type: DEALDETAILS_DELETE_SUCCESS,
  dealScore,
});

export const deleteDealDetailError = errorMessage => ({
  type: DEALDETAILS_DELETE_ERROR,
  dealDetailsDeleteErrorDetails: errorMessage,
});

export const createNewDealSuccess = () => ({
  type: CREATE_NEW_DEAL_SUCCESS,
});

export const createNewDealError = errorMessage => ({
  type: CREATE_NEW_DEAL_ERROR,
  createNewDealErrorDetails: errorMessage,
});

const followUpSuccess = () => ({
  type: DEALDETAILS_FOLLOW_UP_SUCCESS,
});

const followUpError = err => ({
  type: DEALDETAILS_FOLLOW_UP_ERROR,
  errorDetail: err,
});

const generateProposalSuccess = () => ({
  type: DEALDETAILS_GENERATE_PROPOSAL_SUCCESS,
});

const generateProposalError = err => ({
  type: DEALDETAILS_GENERATE_PROPOSAL_ERROR,
  errorDetail: err,
});

const proposalsRelevanceDataSuccess = proposalsRelevance => ({
  type: DEALDETAILS_PROPOSALS_RELEVANCE_SUCCESS,
  proposalsRelevance,
});

export const proposalsEmailSentSuccessfully = () => ({
  type: DEALDETAILS_PROPOSALS_EMAIL_SENT_SUCCESSFULLY,
});

export const resetProposalsEmailSentSuccessfully = () => ({
  type: DEALDETAILS_RESET_PROPOSALS_EMAIL_SENT_SUCCESSFULLY,
});

export const getDealDetailsDataById = id =>
  function(dispatch, getState) {
    const url = getState().Preload.mainMetasource.deals.href;

    return DealDetailsApi.getMetasource(url, id)
      .then(response => {
        const metasource = { links: response.data._links };
        const { metasourceStrings } = getState().Preload;
        const urlReduced = getMetasourceLink(
          metasource,
          metasourceStrings,
          'DEAL_DETAIL_METASOURCE_DEAL_REDUCED'
        );

        if (!urlReduced || !urlReduced.href) {
          return null;
        }

        return DealDetailsApi.getDealDetailsData(urlReduced.href)
          .then(response => {
            const { etag } = response.headers;

            dispatch(dealDetailsDataSuccess(response.data.deal, urlReduced.href, etag));
          })
          .catch(error => {
            console.error(error);
            dispatch(dealDetailsDataError(extractErrorMessage(error)));
          });
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const showOrLoadDealDetailsView = (id, showDealDetail = false) =>
  function(dispatch, getState) {
    const url = getState().Preload.mainMetasource.deals.href;

    return DealDetailsApi.getMetasource(url, id)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
        if (showDealDetail) {
          browserHistory.push(`/dealDetails/${id}`);
        } else {
          dispatch(getDealDetailsData());
        }
      })
      .catch(error => {
        console.error('Error showOrLoadDealDetailsView()', error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const getDealDetailsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().DealDetails;
    const { metasourceStrings } = getState().Preload;
    const urlReduced = getMetasourceLink(
      metasource,
      metasourceStrings,
      'DEAL_DETAIL_METASOURCE_DEAL_REDUCED'
    );

    if (!urlReduced || !urlReduced.href) {
      return null;
    }

    return DealDetailsApi.getDealDetailsData(urlReduced.href)
      .then(response => {
        const { etag } = response.headers;
        // Order proposals by creationDate, it has been lost at the back at some point
        response.data.deal.proposals = orderBy(
          response.data.deal.proposals,
          ['proposal.creationDate'],
          ['asc']
        );
        dispatch(dealDetailsDataSuccess(response.data.deal, urlReduced.href, etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(dealDetailsDataError(extractErrorMessage(error)));
      });
  };

// const getCreateNewCompanyPromise = (companyDetails, getState) => {
//   const newCompanyUrl = getMetasourceLink(
//     getState().Companies.metasource,
//     getState().Preload.metasourceStrings,
//     'COMPANY_MAIN_METASOURCE_NEW_COMPANY'
//   );

//   return CompaniesApi.createNewCompany(companyDetails, newCompanyUrl.href)
//     .then(res => {
//       console.error('company created', res.data.company);

//       return res.data.company;
//     })
//     .catch(error => {
//       console.error('Error creating the company', error);
//     });
// };

// const getCreateNewContactOfCompanyPromise = (companyId, contactDetails, getState) =>
//   getCompanyMetasourceLinks(companyId, getState)
//     .then(links => {
//       console.error('getCreateNewContactOfCompanyPromise --> First then', links);

//       return getMetasourceLink(
//         { links },
//         getState().Preload.metasourceStrings,
//         'COMPANY_DETAIL_METASOURCE_NEW_CONTACT'
//       );
//     })
//     .then(newContactUrl => {
//       console.error('getCreateNewContactOfCompanyPromise --> Second then', newContactUrl);

//       return ContactsApi.createNewContact(contactDetails, newContactUrl.href);
//     })
//     .then(res => {
//       console.error('getCreateNewContactOfCompanyPromise --> Third then', res);

//       return res.data.contact;
//     })
//     .catch(error =>
//       console.error(
//         'Error a la hora de Crear nuevos Contactos de Compañía [getCreateNewContactOfCompanyPromise()]'
//       )
//     );

// const getUpdateContactOfCompanyPromise = (getState, dealData) => {
//   const { metasource } = getState().Contacts;
//   const { metasourceStrings } = getState().Preload;
//   const url = `${
//     getMetasourceLink(metasource, metasourceStrings, 'CONTACT_MAIN_METASOURCE_CONTACTS').href
//   }/${dealData.contactDetails.id}`;

//   // 1. Se recupera el contacto para obtener el version
//   return ContactDetailsApi.getContactDetailsData(url)
//     .then(response => {
//       const { etag } = response.headers;
//       const { contactDetails } = dealData;

//       // 2. Se guarda el contacto con el etag recuperado
//       return ContactDetailsApi.saveContactDetailsData(url, dealData.contactDetails, null, etag)
//         .then(contact => {
//           const { roles } = contactDetails.relatedCompany;

//           if (roles) {
//             roles.push('USUAL');
//           }

//           dealData.contactsOfCompany = [{ contact: contactDetails, roles }];

//           return contactDetails;
//         })
//         .catch(error => {
//           console.error(
//             'Ha ocurrido un error a la hora de guardar el contacto con el etag recuperado [getUpdateContactOfCompanyPromise(), saveContactDetailsData]',
//             error
//           );
//         });
//     })
//     .catch(error => {
//       console.error(
//         'Ha ocurrido un error a la hora de recuperar el contacto para obtener la versión [getUpdateContactOfCompanyPromise(), getContactDetailsData]',
//         error
//       );
//     });
// };

export const createNewDealFromRequest = (requestId, dealData) =>
  createNewDeal(
    `${ENDPOINT}/request/${requestId}/convert2deal`,
    dealData,
    DealDetailsApi.doPostWithData
  );

/* export const createNewDeal = (url, dealData, save = DealDetailsApi.saveDealDetailsData) => {
  return (dispatch) => {
    let operation = Promise.resolve();
    return operation
      .then(_ => {
        return save(url, dealData);
      })
      .then(res => {
        dispatch(createNewDealSuccess());
        dispatch(showOrLoadDealDetailsView(res.data.deal.id));
      })
      .catch(err => {
        console.error('Error a la hora de crear un Nueva Oportunidad', err);
        dispatch(createNewDealError(extractErrorMessage(err)));
      });
  };
};
*/
export const createNewDeal = (url, dealData, httpOp = DealDetailsApi.doPut) => dispatch => {
  const {
    generalConditionsDate,
    generalConditionsOrigin,
    privacyPolicyDate,
    privacyPolicyOrigin,
  } = dealData;

  const newDealRequest = {
    company: dealData.companyDetails,
    contact: dealData.contactDetails,
    deal: dealData,
    generalConditionsDate,
    generalConditionsOrigin,
    privacyPolicyDate,
    privacyPolicyOrigin,
  };

  return httpOp(url, newDealRequest)
    .then(async res => {
      dispatch(createNewDealSuccess());
      openNotificationWithIcon(
        'success',
        '¡Genial! :)',
        `¡Creación de la Oportunidad realizada correctamente! ${
          get(res, 'data.deal.rentalRequestType', '') === 'B'
            ? 'Iniciando generación de Propuestas...'
            : ''
        }`
      );

      browserHistory.push(`/dealDetails/${res.data.deal.id}`);

      // Os juro que siento muchisimo las siguientes 80 lineas 😓
      // Lo siento sobre todo por mis familiares, que les pitarán los oidos por cagaros en todos ellos.
      const isTravelManager = get(dealData, 'contactDetails.isTravelManager', false);

      try {
        if (isTravelManager) {
          const usualContact = find(get(res, 'data.deal.contactsOfCompany'), c =>
            c.roles.includes('USUAL')
          );

          const usualContactId = get(usualContact, 'contact.id');

          const contactRes = await api.getDetailData('contact', usualContactId);
          const contactResData = get(contactRes, 'json.contact');

          contactResData.relatedCompany.roles = without(
            uniq([...(contactResData.relatedCompany.roles || []), 'TRAVEL_MANAGER', 'USUAL']),
            'UNKNOWN'
          );

          const dataToSave = {
            ...contactResData,
            etag: replace(contactRes.headers.get('etag'), /"/g, ''),
          };

          await api.save('contact', contactResData.id, dataToSave);
        } else {
          const contactId = get(newDealRequest, 'contact.id');

          // If contact ids doesnt exist it means that is a new contact
          if (contactId) {
            const contactRes = await api.getDetailData('contact', contactId);
            const contact = get(contactRes, 'json.contact');

            const hasTravelManagerRole = contact.relatedCompany.roles.includes('TRAVEL_MANAGER');

            if (!isEmpty(contact) && hasTravelManagerRole) {
              contact.relatedCompany.roles.splice(
                contact.relatedCompany.roles.indexOf('TRAVEL_MANAGER'),
                1
              );

              const dataToSave = {
                ...contact,
                etag: replace(contactRes.headers.get('etag'), /"/g, ''),
              };

              await api.save('contact', contact.id, dataToSave);
            }
          }
        }
      } catch (e) {
        console.error('Something went wrong saving Contact as Travel Manager', e);
      }

      try {
        CompanyDetailsApi.saveQualifyingQuestions(res.data.deal.companyId, dealData.questions);
        // api.request(
        //   `${ENDPOINT_V2}/companies/${res.data.deal.companyId}/qualifyingQuestions`,
        //   'POST',
        //   {
        //     body: JSON.stringify({ questions: dealData.questions }),
        //   }
        // );
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'Oh nonono :(',
          'No ha sido posible guardar las Qualifying Questions'
        );
        console.error('Something went wrong saving Qualifying Questions', e);
      }

      try {
        const { roomShared = null, sofaBed = null, closeOrBudget = null } = dealData;

        api.request(`${ENDPOINT_V2}/opportunities/${res.data.deal.id}/preferences`, 'POST', {
          body: JSON.stringify({ roomShared, sofaBed, closeOrBudget }),
        });
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'Oh nonono :(',
          'No ha sido posible guardar las Preferencias del cliente'
        );
        console.error('Something went wrong saving Preferencias del cliente', e);
      }
    })
    .catch(err => {
      console.error('Error a la hora de crear un Nueva Oportunidad', err);
      openNotificationWithIcon('error', 'Oops!', extractErrorMessage(err));
      dispatch(createNewDealError(extractErrorMessage(err)));
    });
};

export const saveDealDetailsData = (
  dealDetailsData,
  tenantsHandleSubmit = () => {},
  reloadDealDetail = true
) =>
  function(dispatch, getState) {
    const { etag } = getState().DealDetails.data;
    const { url } = getState().DealDetails.data;

    return DealDetailsApi.saveDealDetailsData(url, dealDetailsData, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(dealDetailsSubmitSuccess(etag));

        tenantsHandleSubmit();
        openNotificationWithIcon(
          'success',
          'Todo OK :)',
          'Los cambios se han registrado correctamente'
        );
        if (reloadDealDetail) {
          browserHistory.push(`/dealDetails/${dealDetailsData.id}`);
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(dealDetailsSubmitError(extractErrorMessage(error)));
        if (reloadDealDetail) {
          dispatch(showOrLoadDealDetailsView(dealDetailsData.id));
        }

        openNotificationWithIcon('error', 'Un errorcillo...', extractErrorMessage(error));
      });
  };

export const deleteDealDetailsData = () =>
  function(dispatch, getState) {
    const { url } = getState().DealDetails.data;

    return DealDetailsApi.deleteDealDetailsData(url)
      .then(() => {
        openNotificationWithIcon('success', 'Todo OK :)', 'La oportunidad se ha eliminado');
        browserHistory.push('/deals');
        dispatch(deleteDealDetailSuccess());
      })
      .catch(error => {
        const errorMsg = extractErrorMessage(error);

        openNotificationWithIcon('error', 'Oops!', errorMsg);
        console.error(error);
        dispatch(deleteDealDetailError(errorMsg));
      });
  };

export const followUp = (dealId, followUpStage) =>
  function(dispatch, getState) {
    const metasource = { links: getState().DealDetails.metasource.links };
    const { metasourceStrings } = getState().Preload;
    const url = getMetasourceLink(metasource, metasourceStrings, 'DEAL_DETAIL_METASOURCE_FOLLOW_UP')
      .href;

    return DealDetailsApi.followUp(url, followUpStage)
      .then(() => {
        dispatch(followUpSuccess());
        dispatch(showOrLoadDealDetailsView(dealId));
        // to update timelinepanel to view de related business event created
        dispatch(createNewBusinessEventSuccess());
      })
      .catch(error => {
        const errorMsg = extractErrorMessage(error);

        dispatch(followUpError(errorMsg));
      });
  };

export const generateAutomaticProposal = id =>
  function(dispatch, getState) {
    const { metasource } = getState().DealDetails;
    const { metasourceStrings } = getState().Preload;
    const url = getMetasourceLink(metasource, metasourceStrings, 'DEAL_DETAIL_NEW_AUTO_PROPOSAL')
      .href;

    return DealDetailsApi.doPost(url)
      .then(response =>
        DealDetailsApi.doPut(response.headers.location, {})
          .then(() => {
            dispatch(generateProposalSuccess());
            dispatch(showOrLoadDealDetailsView(id));
            openNotificationWithIcon(
              'success',
              'Hecho!',
              'Se ha realizado la Generación de Propuesta Automática de forma correcta.'
            );
          })
          .catch(error => {
            console.error(error);
            const errorMsg = extractErrorMessage(error);

            dispatch(generateProposalError(errorMsg));
          })
      )
      .catch(error => {
        console.error(error);
        const errorMsg = extractErrorMessage(error);

        dispatch(generateProposalError(errorMsg));
      });
  };

export const saveClientInfo = (dealDetails, url) =>
  function() {
    // const url =
    //   getState().Preload.mainMetasource.deal_client_info.href + dealDetails.id;
    return DealDetailsApi.doPut(url.href, dealDetails)
      .then(() => {
        // dispatch(metasourceSuccess(response.data._links));
        browserHistory.push('/thankYou');
      })
      .catch(error => {
        console.error(error);
        // dispatch(dealDetailsSubmitError(errorMsg));
        browserHistory.push({
          pathname: '/thankYou',
          search: `?message=${extractErrorMessage(error)}`,
        });
      });
  };

export const getDealDetailsForPublicForm = dealId =>
  function(dispatch, getState) {
    const url = getState().Preload.mainMetasource.public_deal.href + dealId;

    return DealDetailsApi.doGet(url)
      .then(response => {
        const { etag } = response.headers;

        dispatch(dealDetailsDataSuccess(response.data.publicDeal, url, etag, response.data._links));
      })
      .catch(error => {
        console.error(error);
        dispatch(dealDetailsDataError(extractErrorMessage(error)));
        browserHistory.push({
          pathname: '/thankYou',
          search: `?message=${extractErrorMessage(error)}`,
        });
      });
  };

export const getProposalsRelevance = dealId =>
  function(dispatch) {
    return api
      .request_retry(`${ENDPOINT_V2}/opportunities/${dealId}/proposals`, 'get', 5)
      .then(response => {
        dispatch(proposalsRelevanceDataSuccess(response.json));
      })
      .catch(error => {
        console.error('Proposals RelevanceService call error: ' + error.message);
      });
  };
