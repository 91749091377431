import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Typography } from 'antd';
import TableDataFollowUps from '../../components/TableDataFollowUps/TableDataFollowUps';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import FollowUpsFilter from './FollowUpsFilter';
import api from '../../shared/utils/api';
import tableUtils from '../../shared/utils/tableUtils';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import moment from 'moment';

const { Title } = Typography;
export class FollowUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        data: [],
      },
      filter: {
        ...props.filter,
        agent: props.currentUsername,
        rentalRequestStages: ['S020_SEND_SEARCH_RESULTS'],
        date: moment().format('YYYY-MM-DD'),
      },
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      sorter: '',
      loading: true,
    };

    this.loadTableData = this.loadTableData.bind(this);
    this.handleChangeOnFilterConditions = this.handleChangeOnFilterConditions.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.sorterData = this.sorterData.bind(this);
    this.handleSelectChangeOfSearch = this.handleSelectChangeOfSearch.bind(this);
    this.handleDateChangeOnFilterConditions = this.handleDateChangeOnFilterConditions.bind(this);
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.filter) !== JSON.stringify(newProps.filter)) {
      this.setState(
        {
          loading: true,
          filter: {
            ...newProps.filter,
          },
        },
        () => {
          this.loadTableData();
        }
      );
    }
  }

  handlePaginationChange = (page, pageSize) => {
    this.setState(
      prevState => ({
        loading: true,
        pagination: {
          ...prevState.pagination,
          pageSize,
          page,
        },
      }),
      () => this.loadTableData()
    );
  };

  loadTableData = () => {
    const { pagination, sorter, filter } = this.state;

    const params = {
      pagination,
      filters: filter,
      sorter: sorter || '-rentalStartDate',
    };

    api
      .getTableDataV2('tasks', params)
      .then(res => {
        this.setState({
          loading: false,
          table: {
            data: get(res, 'json.searchItems', []),
          },
          pagination: {
            ...pagination,
            totalCount: get(res, 'json.totalHits', 0),
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de procesar datos en los Seguimientos... ${err.message}`
        );
      });
  };

  sorterData = sorter => {
    const condition = tableUtils.getSortCondition(sorter, 'deal');

    this.setState(
      {
        sorter: condition,
        loading: true,
      },
      () => this.loadTableData()
    );
  };

  handleFilter = e => {
    e.preventDefault();

    const { pagination } = this.state;

    this.setState(
      {
        loading: true,
        pagination: {
          ...pagination,
          page: 1,
        },
      },
      () => {
        // this.props.actions.filterTableDataDeals(this.state.filter);
        this.loadTableData();
      }
    );
  };

  handleChangeOnFilterConditions(e) {
    const { filter } = this.state;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({
      filter: {
        ...filter,
        [e.target.name]: value,
      },
    });
  }

  handleDateChangeOnFilterConditions = (name, date) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [name]: isNil(date) ? null : date.valueOf(),
      },
    }));
  };

  handleSelectChangeOfSearch(name, selectedOption) {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        [name]: selectedOption
          ? Array.isArray(selectedOption)
            ? selectedOption
            : selectedOption.value
          : null,
      },
    });
  }

  render() {
    return (
      <div className='animated fadeIn followups'>
        <Row align='middle' gutter={8}>
          <Col xxl={20} xl={20} lg={18} md={18} sm={24} xs={24}>
            <Title level={2} className='title-followups'>
              Seguimientos
              {/* <img src='img/xmas/gingerbread-man.png' alt='gingerbread' height={50} /> */}
            </Title>
          </Col>
        </Row>
        <Row className='row-filter-deals'>
          <Col span={24}>
            <FollowUpsFilter
              filter={this.state.filter}
              handleChangeOnFilterConditions={this.handleChangeOnFilterConditions}
              handleDateChangeOnFilterConditions={this.handleDateChangeOnFilterConditions}
              loading={this.state.loading}
              handleSelectChangeOfSearch={this.handleSelectChangeOfSearch}
              handleFilter={this.handleFilter}
            />
          </Col>
        </Row>
        <Row className='row-table-followups'>
          <Col span={24}>
            <TableDataFollowUps
              data={this.state.table.data}
              loading={this.state.loading}
              options={this.state.options}
              pagination={this.state.pagination}
              handleSorter={this.sorterData}
              handlePaginationChange={this.handlePaginationChange}
              handlePaginationSizeChange={this.handlePaginationChange}
            />
          </Col>
        </Row>
        <style jsx>
          {`
            .followups {
              margin: 10px;
            }

            .title-followups {
              margin: 0;
            }

            .row-filter-followups {
              margin-top: 10px;
            }

            .row-table-followups {
              margin-top: 5px;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUsername: state.Preload.currentUserInformation.username,
  };
}

export default connect(mapStateToProps)(FollowUps);
