import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { getTextValueFromSelectOptions } from '../../shared/utils/appUtils';
import { getInvoicesTableData } from '../../modules/Invoices/InvoicesActions';
import { DATE_FORMAT } from '../../shared/appConfig.js';
import HomyPagination from '../HomyComponents/HomyPagination';
import tableUtils from '../../shared/utils/tableUtils';

class TableDataInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
    this.showInvoiceDetail = this.showInvoiceDetail.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.data) !== JSON.stringify(newProps.data)) {
      this.setState({
        data: newProps.data,
      });
    }
  }

  showInvoiceDetail(invoiceId) {
    browserHistory.push(`/invoiceDetails/${invoiceId}`);
  }

  render() {
    const columns = [
      {
        title: 'Tipo factura',
        dataIndex: 'invoice.invoiceType',
        fixed: 'left',
        sorter: true,
        width: 100,
        render: (value, record) => (
          <a href={`/invoiceDetails/${record.invoice.id}`}>
            {getTextValueFromSelectOptions(value, this.props.options.invoiceType)}
          </a>
        ),
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showInvoiceDetail(record.invoice.id);
        },
      },
      {
        title: 'Nº factura',
        dataIndex: 'invoice.invoiceNumber',
        sorter: true,
        width: 100,
        fixed: 'left',
        render: text => <a href=''>{text}</a>,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showInvoiceDetail(record.invoice.id);
        },
      },
      {
        title: 'Info Destinatario',
        children: [
          {
            title: 'Tipo',
            width: 100,
            dataIndex: 'invoice.receiverType',
            sorter: true,
            render: value => getTextValueFromSelectOptions(value, this.props.options.receiverType),
          },
          {
            width: 150,
            title: 'Razón social',
            dataIndex: 'invoice.lastOTradeName',
            sorter: true,
          },
          {
            title: 'NIF',
            width: 150,
            dataIndex: 'invoice.identificationCode',
            sorter: true,
          },
        ],
      },
      {
        title: 'Importes',
        children: [
          {
            title: 'Base imponible',
            width: 100,

            dataIndex: 'invoice.amountWithNoTax',
            sorter: true,
            render: record => (
              <div style={{ textAlign: 'right' }}>
                {record ? numeral(record.amount).format('0.00') : ''}
              </div>
            ),
          },
          {
            title: 'Total (imp. incluido)',
            width: 100,
            dataIndex: 'invoice.amount',
            sorter: true,
            render: record => (
              <div
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                }}
              >
                {record ? numeral(record.amount).format('0.00') : ''}
              </div>
            ),
          },
        ],
      },
      {
        title: 'Info factura',
        children: [
          {
            title: 'Fecha',
            dataIndex: 'invoice.issueDate',
            sorter: true,
            width: 100,
            render: val => <a href=''>{val ? moment(val).format(DATE_FORMAT) : ''}</a>,
            onCellClick: (record, event) => {
              event.preventDefault();
              this.showInvoiceDetail(record.invoice.id);
            },
          },
          {
            title: 'Estado',
            dataIndex: 'invoice.invoiceState',
            sorter: true,
            width: 100,
            render: value => getTextValueFromSelectOptions(value, this.props.options.invoiceState),
          },
        ],
      },
      {
        title: 'Oportunidad',
        children: [
          {
            title: 'Código',
            dataIndex: 'invoice.dealReference',
            sorter: true,
            width: 100,
          },
          {
            title: 'Tipo',
            dataIndex: 'invoice.rentalRequestType',
            sorter: true,
            width: 100,
            render: value =>
              getTextValueFromSelectOptions(value, this.props.options.rentalRequestType),
          },
        ],
      },

      {
        title: 'Gestor',
        dataIndex: 'invoice.hsagent',
        sorter: true,
        render: val => (val ? val.username : ''),
      },
      // {
      //   title: 'Recurrente',
      //   dataIndex: 'invoice.recurrent',
      //   sorter: true,
      //   render: val => (val ? <i className="fa fa-check fa-lg" /> : ''),
      // },
      // {
      //   title: 'Fuente/medio',
      //   dataIndex: 'invoice.origin',
      //   sorter: true,
      // },
    ];
    const scrollX = tableUtils.getTableWidth();
    const scrollY = tableUtils.getTableHeight() - 50;

    return (
      <div className='animated fadeIn invoiceBorderTopColor'>
        <Table
          bordered
          size='middle'
          pagination={false}
          dataSource={this.state.data}
          columns={columns}
          rowKey={rec => rec.invoice.id}
          scroll={{ x: scrollX, y: scrollY }}
          className='fixed-width-table'
          // onChange={(pagination, filter, sorter) => {
          //   if (
          //     sorter.field !== this.state.currentSorterField ||
          //     sorter.order !== this.state.currentSortOrder
          //   ) {
          //     this.setState(
          //       {
          //         currentSorterField: sorter.field,
          //         currentSortOrder: sorter.order,
          //       },
          //       () => this.sortData(sorter)
          //     );
          //   }
          // }}
          onChange={(pagination, filter, sorter) => this.props.handleSorter(sorter)}
          loading={this.props.loading}
          footer={() => (
            <HomyPagination
              showSizeChanger
              showQuickJumper
              current={this.props.pagination.page}
              pageSize={this.props.pagination.pageSize}
              totalCount={this.props.pagination.totalCount}
              onShowSizeChange={this.props.handlePaginationSizeChange}
              onChange={this.props.handlePaginationChange}
            />
          )}
        />
        {/* {this.state.data._links && (
              <Pagination
                pageSize={PAGE_SIZE}
                total={this.state.totalCount}
                disabled={this.state.loading}
                onGoToNext={() => {
                  this.getTableData(this.state.data._links.next);
                }}
                onGoToPrev={() =>
                  this.getTableData(this.state.data._links.prev)
                }
              />
            )} */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getInvoicesTableData: url => dispatch(getInvoicesTableData(url, false)),
    },
  };
}

function mapStateToProps(state) {
  return {
    invoicesData: state.Invoices.invoicesData,
    filter: state.Invoices.filter,
    filterVersion: state.Invoices.filterVersion,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  TableDataInvoices
);
