import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import { normalizeNumberToDecimals } from '../../shared/utils/formUtils';
import { showPropertyDetailsView } from '../../modules/PropertyDetails/PropertyDetailsActions.js';
import { DATE_FORMAT } from '../../shared/appConfig.js';
import includes from 'lodash/includes';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { CURRENCY_LETTER, openNotificationWithIcon } from '../../shared/utils/appUtils';

const DEFAULT = '../img/map/mapIcon-07.png';
const VISITED = '../img/map/mapIcon-check.png';
const CONSULTED_AVAILABILITY = '../img/map/mapIcon-availability.png';
const CLICKED = '../img/map/mapIcon-06.png';
const CREATED = '../img/map/mapIcon-05.png';

class TableDataProperties extends Component {
  constructor(props) {
    super(props);
    this.redirectToDetails = this.redirectToDetails.bind(this);
  }

  redirectToDetails = async id => {
    try {
      const property = await this.props.getProperty(id);

      this.props.actions.showPropertyDetailsView(property.id);
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Woops',
        `¡No he podido cargar la información del Inmueble!`
      );
      console.error(e);
    }
  };

  setImage = (properties, currentProperty, propertyProviderId) => {
    let img = DEFAULT;
    const visited = get(properties, 'visited');
    const selected = get(properties, 'selected');
    const consultedAvailability = get(properties, 'consultedAvailability');
    const bonded = get(properties, 'bonded');

    if (includes(visited, currentProperty)) {
      img = VISITED;
    }

    if (selected === currentProperty) {
      img = CLICKED;
    }

    if (includes(consultedAvailability, propertyProviderId)) {
      img = CONSULTED_AVAILABILITY;
    }

    if (!isNil(bonded)) {
      const hasBondedDeal = get(
        find(bonded, o => o.proposal.property.id === currentProperty),
        'proposal.property.id'
      );

      if (hasBondedDeal) {
        img = CREATED;
      }
    }

    return img;
  };

  columnName = (text, record) => {
    const {
      propertySelected,
      propertiesVisited,
      consultedAvailabilityProviders,
      bondedDeal,
    } = this.props;

    const properties = {
      selected: propertySelected,
      visited: propertiesVisited,
      consultedAvailability: consultedAvailabilityProviders,
      bonded: get(bondedDeal, 'proposals'),
    };

    const img = this.setImage(properties, record.id, record.providerId);

    return (
      <div>
        <img src={img} alt='Property' width='25' height='25' />
        {'  '}
        <span>{text}</span>
      </div>
    );
  };

  render() {
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'title',
        key: 'title',
        width: 350,
        sorter: (a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0),
        render: (text, record) => this.columnName(text, record),
      },
      {
        title: 'Dirección',
        dataIndex: 'route',
        key: 'route',
        width: 250,
        sorter: (a, b) => (a.route < b.route ? -1 : a.route > b.route ? 1 : 0),
        render: (text, obj) => `${text || ''} ${get(obj, 'streetNumber') || ''}`,
      },
      {
        title: 'Precio/mes',
        dataIndex: 'monthlyPrice.amount',
        width: 75,
        sorter: (a, b) =>
          a.monthlyPrice.amount < b.monthlyPrice.amount
            ? -1
            : a.monthlyPrice.amount > b.monthlyPrice.amount
            ? 1
            : 0,
        render: (text, obj) =>
          `${text ? normalizeNumberToDecimals(text, 2) : 'Sin precio'} ${get(
            CURRENCY_LETTER,
            get(obj, 'monthlyPrice.currency')
          ) || ''}`,
      },
      {
        title: 'Habitaciones',
        dataIndex: 'rooms',
        width: 75,
        sorter: (a, b) => (a.rooms < b.rooms ? -1 : a.rooms > b.rooms ? 1 : 0),
      },
      {
        title: 'Camas',
        dataIndex: 'beds',
        width: 75,
        sorter: (a, b) => (a.beds < b.beds ? -1 : a.beds > b.beds ? 1 : 0),
      },
      {
        title: 'Sofá cama',
        dataIndex: 'sofaBed',
        width: 75,
        sorter: (a, b) => (a.sofaBed < b.sofaBed ? -1 : a.sofaBed > b.sofaBed ? 1 : 0),
      },
      {
        title: 'Baños',
        dataIndex: 'bathrooms',
        width: 75,
        sorter: (a, b) => (a.bathrooms < b.bathrooms ? -1 : a.bathrooms > b.bathrooms ? 1 : 0),
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'creationDate',
        width: 150,
        sorter: (a, b) =>
          new Date(a.creationDate) < new Date(b.creationDate)
            ? -1
            : new Date(a.creationDate) > new Date(b.creationDate)
            ? 1
            : 0,
        render: date => moment(new Date(date)).format(DATE_FORMAT),
      },
      {
        // title: 'Ir al detalle',
        width: 175,
        render: () => <a href=''>Ir al detalle</a>,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.redirectToDetails(record.id);
        },
      },
      // {
      //   title: <span className='numericColumnTitle'>Relevancia</span>,
      //   dataIndex: 'property.relevance',
      //   width: 150,
      //   sorter: (a, b) =>
      //     a.property.relevance < b.property.relevance
      //       ? -1
      //       : a.property.relevance > b.property.relevance
      //       ? 1
      //       : 0,
      //   render: relevance =>
      //     // <div style={{ textAlign: 'right' }}>
      //     normalizeNumberToDecimals(relevance * 100, 2),
      //   // </div>
      // },
    ];

    if (
      !isEmpty(this.props.bondedDeal) ||
      (!isEmpty(get(this.props, 'filter.address')) &&
        find(this.props.properties, 'distanceFromDesiredLocationInKm'))
    ) {
      columns.splice(2, 0, {
        title: 'Distancia',
        dataIndex: 'distanceFromDesiredLocationInKm',
        width: 75,
        sorter: (a, b) =>
          a.distanceFromDesiredLocationInKm < b.distanceFromDesiredLocationInKm
            ? -1
            : a.distanceFromDesiredLocationInKm > b.distanceFromDesiredLocationInKm
            ? 1
            : 0,
        render: text => `${normalizeNumberToDecimals(text, 2)} km.`,
      });
    }

    return (
      <div className='animated fadeIn'>
        {this.props.properties && (
          <Table
            size='small'
            className='fixed-width-table'
            onRow={record => ({
              onClick: event => {
                event.preventDefault();

                if (event.target.cellIndex === 7 || event.target.nodeName === 'A') {
                  return false;
                }

                this.props.loadInfoWindow(true, record);
                this.props.addPropertyIdToVisited(record.id);
              },
            })}
            pagination={{ pageSize: 15 }}
            dataSource={this.props.properties}
            columns={columns}
            rowKey={rec => rec.id}
            loading={this.props.loading}
          />
        )}
        <style jsx>
          {`
            .ant-table-column-has-actions:hover {
              cursor: pointer;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      showPropertyDetailsView: id => dispatch(showPropertyDetailsView(id)),
    },
  };
}

export default connect(null, mapDispatchToProps)(TableDataProperties);
