import React, { Component } from 'react';

/*******************************************************
PROPS TO CONFIGURE IN PARENT
lineHeight -> height of each line in gallery
galleryId -> unique id of the gallery
images -> array with the ids of the images to be loaded in gallery
********************************************************/

class ImageGallery extends Component {
  render() {
    return (
      <div className='imageGallery'>
        {this.props.images &&
          this.props.images.map((image, i) => (
            <div style={{ height: this.props.lineHeight }} key={this.props.galleryId + i}>
              <img id={this.props.galleryId + i} src={image} alt={this.props.galleryId + i} />
            </div>
          ))}
      </div>
    );
  }
}

export default ImageGallery;
