import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import moment from 'moment';
import Select from 'react-select';
import { Icon, Tabs, Modal, Row, Col, InputNumber, Alert, Button, Popover } from 'antd';
import RichTextEditor from 'react-rte';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import {
  CONTACT_TYPE,
  getContactFromExtendedContactList,
  getExtendedContactList,
  getOptionsForSelect,
  getTenantsList,
  getTextValueFromSelectOptions,
  getTextValuesFromSelectedOptions,
  isEmptyObject,
  openNotificationWithIcon,
  removeEmptyKeysFromObject,
  RENTAL_REQUEST_STAGES,
  isSpain,
  isCanaryIslands,
  isCeutaMelilla,
} from '../../shared/utils/appUtils';
import { areValidDates, getDays, isValidDate } from '../../shared/utils/dateUtils';
import { normalizeNumberToDecimals, validateInputs } from '../../shared/utils/formUtils';
import { printPropertyDetails } from '../../modules/PropertyDetails/PropertyDetailsActions';
import {
  deleteProposal,
  getContractData,
  requestConfirmation,
  resetContractDataError,
  saveProposalDetailsData,
  sendContractData,
} from '../../modules/ProposalDetails/ProposalDetailsActions';
import { getDealDetailsData } from '../../modules/DealDetails/DealDetailsActions';
import CheckboxButton from '../CheckboxButton';
import ImageSelector from '../ImageSelector';
import ImageLoader from '../ImageLoader';
import {
  getProviderBaseContactAndChildren,
  showOrLoadProviderDetailsView,
} from '../../modules/ProviderDetails/ProviderDetailsActions';
import TableDataProviderContacts from '../TableDataProviderContacts';
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  PARSE_DATE_FORMAT,
  ENDPOINT_V2,
  COMMISSION_TO_PROVIDER_DATE_THRESHOLD,
  HOMYSPACE_WEB_BASEPATH,
} from '../../shared/appConfig';
import FormNewProposal from '../FormNewProposal';
import FormDossierClientPrices from '../FormDossierClientPrices';
import { getNewProposalLocation } from '../../modules/Proposals/ProposalsActions';
import ContractData from '../ContractData/ContractData';
import FormProviderInitialPrice from '../FormProviderInitialPrice';
import FormProviderCommission from '../FormProviderCommission';
import TableDataTenants from '../TableDataTenants/TableDataTenants';
import SelectContact from '../SelectContact';
import get from 'lodash/get';
import set from 'lodash/set';
import assign from 'lodash/assign';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import {
  // getDossier,
  getNewDossier,
  // getDossierAndProposal,
  getNewDossierAndProposal,
  getDossierProvider,
} from '../../shared/apiUrls';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';
import { isFlagActive } from 'hs-feature-flags';
import AfterSales from './FormProposalAfterSales';
import ReusedProposalInfo from './ReusedProposalInfo';
import { getContractPDF } from '../../shared/utils/api';
import api from '../../shared/utils/api';
import { PROPOSAL_PAYMENT_TYPES } from '../../shared/utils/priceUtils';
import ProvidersApi from '../../modules/Providers/ProvidersApi';
import { getPropertyUsualContact } from '../../shared/utils/contactUtils';
import { useCheckAvailabilityHandler } from '../../shared/utils/hooks/useCheckAvailabilityHandler';

const { TabPane } = Tabs;
const SIGNER_IS_COMPANY_MESSAGE =
  'El contacto seleccionado no puede tener el rol de "Firma contrato" porque es un contacto de tipo "Persona jurídica"';
const UNLOCK_INVOICE_FIELDS_FLAG = 'UNLOCK_INVOICE_FIELDS';
const DEFAULT_AMOUNT = { amount: 0, currency: 'EUR' };

// PARÁMETROS
// proposalIndex -> index of the proposal inside proposals array of the deal

class FormProposalDetails extends Component {
  constructor(props) {
    super(props);

    const proposal = props.dealDetails.proposals[props.proposalIndex].proposal
      ? props.dealDetails.proposals[props.proposalIndex].proposal
      : props.dealDetails.proposals[props.proposalIndex];

    const links = props.dealDetails.proposals[props.proposalIndex]._links
      ? props.dealDetails.proposals[props.proposalIndex]._links
      : '';

    let initialObservationsTextObject = RichTextEditor.createEmptyValue();

    if (proposal && proposal.observations) {
      initialObservationsTextObject = RichTextEditor.createValueFromString(
        proposal.observations,
        'html'
      );
    }

    // Add pictures info to proposal from the asociated property
    proposal.pictures = get(proposal, 'property.pictures', []);

    // For images validation
    this.imagesChanged = false;
    this.oldImagesSelectedLength = get(proposal, 'property.pictures.lenght', 0);

    this.state = {
      dealDetails: {},
      proposalDetails: {
        deposit: {
          amount: 0,
          currency: 'EUR',
        },
        commissionPercentage: 0,
        providerRate: {
          amount: 0,
          currency: 'EUR',
        },
        showPaxPrice: proposal.showPaxPrice ? proposal.showPaxPrice : false,
        showTotalPrice: proposal.showTotalPrice ? proposal.showTotalPrice : false,
        cleaningChargeWithTax: '',
        clientTotalPrice: '',
        ...proposal,
        monthlyPrice: proposal.price || {
          amount: 0,
          currency: 'EUR',
        },
      },
      proposalUrl: links ? links.self : '',
      observationsTextObject: initialObservationsTextObject,
      imagesLoaded: [],
      loading: true,
      providerDetails: {},
      providerDetailsLinks: {},
      providerBaseContactAndChildren: null,
      contactList: null,
      tenantList: null,
      modalContact: false,
      modalTenant: false,
      editingContact: false,
      // editContact: {},
      contact: {},
      showContractDataModal: false,
      showRequestConfirmationModal: false,
      disableAddTenantButton: false,
      options: {
        taxType: getOptionsForSelect(this.props.optionLabels, 'TaxType'),
        proposalStage: getOptionsForSelect(this.props.optionLabels, 'ProposalStage'),
        rentalRequestStage: getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage'),
        proposalStatus: getOptionsForSelect(this.props.optionLabels, 'ProposalStatus'),
        reasonsOfRejection: getOptionsForSelect(
          this.props.optionLabels,
          'ProposalClientRejectionReason'
        ),
        contactRole: getOptionsForSelect(this.props.optionLabels, 'ProposalContactRole'),
        clientResponse: getOptionsForSelect(this.props.optionLabels, 'ClientResponse'),
        cleaningChargeType: getOptionsForSelect(this.props.optionLabels, 'CleaningChargeType'),
      },
      proposalStatusDisabled:
        !!proposal.property &&
        !this.areEqualsAddresses(proposal.address, proposal.property.address),
      proposalLocation: this.props.proposalLocation,
      days: null,
      // urlPublicDossier: getDossier(proposal.dealId),
      urlNewPublicDossier: getNewDossier(proposal.dealId),
      signerIsCompanyError: false,
      currentUser: this.props.currentUsername,
      rentalRequestStage: this.props.dealDetails.rentalRequestStage,
      isPdfDownloading: false,
      hasFieldsBlocked: false,
      featuredPicturePath: get(proposal, 'property.featuredPicturePath'),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleLoadImagesComplete = this.handleLoadImagesComplete.bind(this);
    this.handleFeaturedImageSelected = this.handleFeaturedImageSelected.bind(this);
    this.handleImageSelected = this.handleImageSelected.bind(this);
    this.handleChangeRichText = this.handleChangeRichText.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePreviewProposal = this.handlePreviewProposal.bind(this);
    this.handleDeleteProposal = this.handleDeleteProposal.bind(this);
    // this.getContactListFormatted = this.getContactListFormatted.bind(this);
    this.toggleContact = this.toggleContact.bind(this);
    this.toggleTenant = this.toggleTenant.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.submitAddContact = this.submitAddContact.bind(this);
    this.handleDeleteContact = this.handleDeleteContact.bind(this);
    this.handleEditContact = this.handleEditContact.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.handleAddressComplementChange = this.handleAddressComplementChange.bind(this);
    this.handleContactToBeAddedRole = this.handleContactToBeAddedRole.bind(this);
    this.handleContractData = this.handleContractData.bind(this);
    this.areEqualsAddresses = this.areEqualsAddresses.bind(this);
    this.confirmRequestConfirmation = this.confirmRequestConfirmation.bind(this);
    this.toggleProposal = this.toggleProposal.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.confirmSendContractData = this.confirmSendContractData.bind(this);
    this.handleRejectChange = this.handleRejectChange.bind(this);
    this.handleDeleteTenant = this.handleDeleteTenant.bind(this);
    this.submitAddTenant = this.submitAddTenant.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getStageIndex = this.getStageIndex.bind(this);
    this.contactSelected = this.contactSelected.bind(this);
    this.isValidSubmit = this.isValidSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.proposalToCopy = this.proposalToCopy.bind(this);
    this.showPropertyDetails = this.showPropertyDetails.bind(this);
    this.isGenerateContractActive = this.isGenerateContractActive.bind(this);
    this.downloadContractPdf = this.downloadContractPdf.bind(this);
    this.updateImagesLoaded = this.updateImagesLoaded.bind(this);
    this.onPriceFormChange = this.onPriceFormChange.bind(this);
  }

  componentDidMount = async () => {
    let proposalDetails = { ...(this.state.proposalDetails || {}) };

    const checkIn = get(proposalDetails, 'dateCheckIn');
    const checkOut = get(proposalDetails, 'dateCheckOut');

    const newState = {
      days: checkIn && checkOut ? getDays(new Date(checkIn), new Date(checkOut)) : null,
    };

    if (!proposalDetails.taxIncluded && !proposalDetails.taxExempt) {
      proposalDetails.plusTax = true;
    }

    // HTI-4826 Commented to let agent to change proposalStage after client responses
    // if (
    //   proposalDetails.clientResponse &&
    //   (proposalDetails.clientResponse === 'REJECTED' ||
    //     proposalDetails.clientResponse === 'ACCEPTED')
    // ) {
    //   proposalDetails.proposalStage = proposalDetails.clientResponse;

    //   this.setState({
    //     proposalDetails,
    //   });
    // }

    if (get(this.state, 'proposalDetails.property.providerBack')) {
      const { id: providerId } = get(this.state, 'proposalDetails.property.providerBack');

      this.props.actions.loadAndGetProviderDetailsData(providerId);
    }

    if (this.getStageIndex() >= 3) {
      const unlockFields = await isFlagActive(UNLOCK_INVOICE_FIELDS_FLAG, this.state.currentUser);

      newState.hasFieldsBlocked = !unlockFields;
    }

    const prices = await api.request(`${ENDPOINT_V2}/proposals/${proposalDetails.id}/pricing`);

    if (!isEmpty(prices)) {
      proposalDetails = { ...proposalDetails, ...(prices.json || {}) };
    }

    newState.proposalDetails = proposalDetails;

    this.setState(newState);
  };

  proposalToCopy = () => {
    const { proposalDetails } = this.state;

    return {
      ...proposalDetails,
      accessRecords: [],
      proposalStage: 'PREPARING',
      creationDate: moment.utc().valueOf(),
      preparingDate: moment.utc().valueOf(),
      shippingDate: null,
      aceptedDate: null,
      rejectedDate: null,
      contractDataSent: false,
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dealDetails, proposalLocation } = this.state;
    const nextPropsProposal = {
      ...this.state.proposalDetails,
      ...(nextProps.dealDetails.proposals[this.props.proposalIndex].proposal
        ? nextProps.dealDetails.proposals[this.props.proposalIndex].proposal
        : nextProps.dealDetails.proposals[this.props.proposalIndex]),
    };
    nextPropsProposal.monthlyPrice = nextPropsProposal.price || {
      amount: 0,
      currency: 'EUR',
    };
    // Add pictures info to proposal from the asociated property before save
    nextPropsProposal.pictures = get(nextPropsProposal, 'property.pictures', []);
    nextPropsProposal.featuredPicturePath = this.state.featuredPicturePath;
    const { submitting } = nextProps.proposalDetailsSubmit;

    if (proposalLocation !== nextProps.proposalLocation) {
      this.setState(
        {
          proposalLocation: nextProps.proposalLocation,
          proposalToCopy: this.proposalToCopy(),
        },
        this.toggleProposal
      );

      return false;
    }

    if (
      nextProps.dealDetails &&
      JSON.stringify(nextProps.dealDetails) !== JSON.stringify(dealDetails)
    ) {
      // Update imagesLoaded after save the proposal, to delete from ImageSelector
      // the images that are not longer included in the property
      this.updateImagesLoaded(nextPropsProposal.pictures);
      this.setState(
        {
          dealDetails: nextProps.dealDetails,
          proposalDetails: nextPropsProposal,
        },
        async () => {
          if (this.getStageIndex() >= 3) {
            const unlockFields = await isFlagActive(
              UNLOCK_INVOICE_FIELDS_FLAG,
              this.state.currentUser
            );

            this.setState({
              hasFieldsBlocked: !unlockFields,
            });
          }
        }
      );
    }

    if (this.props.proposalDetailsSubmit.submitting !== submitting) {
      if (!submitting) {
        this.props.actions.getDealDetailsData();
        if (!nextProps.proposalDetailsSubmit.error) {
          if (nextProps.proposalDetails.externalReservationFounded) {
            openNotificationWithIcon(
              'warning',
              'Opps!! Parece ser que existe otra reserva para este Inmueble a través de otro canal. Por favor, verifica con el Proveedor si hay otra propuesta  aceptada para este inmueble.'
            );
          } else {
            this.setState({ proposalDetails: nextPropsProposal });
            openNotificationWithIcon(
              'success',
              'Todo OK :)',
              'Los cambios se han registrado correctamente'
            );
          }
        } else {
          openNotificationWithIcon(
            'error',
            'Opps!! :(',
            nextProps.proposalDetailsSubmit.errorDetail
          );
        }
      }
    }

    if (
      this.state.loading &&
      nextProps.providerDetailsData.data &&
      nextProps.providerDetailsData.data.detail
    ) {
      if (
        JSON.stringify(this.state.providerDetails) !==
        JSON.stringify(nextProps.providerDetailsData.data.detail)
      ) {
        this.setState({
          loading: false,
          providerDetails: nextProps.providerDetailsData.data.detail,
          providerDetailsLinks: nextProps.providerDetailsData.metasource.links,
        });
        this.props.actions.getProviderBaseContactAndChildren(
          nextProps.providerDetailsData.metasource.links.children.href
        );
      }
    }

    if (
      Array.isArray(nextProps.providerBaseContactAndChildren) &&
      JSON.stringify(this.state.providerBaseContactAndChildren) !==
        JSON.stringify(nextProps.providerBaseContactAndChildren)
    ) {
      const contactList = getExtendedContactList(nextProps.providerBaseContactAndChildren);

      const tenantList = getTenantsList(nextProps.dealDetails.contactsOfCompany);

      this.setState({
        providerBaseContactAndChildren: nextProps.providerBaseContactAndChildren,
        contactList,
        tenantList,
      });
    }

    if (nextProps.contractData.error && isEmptyObject(nextProps.contractData.data)) {
      this.setState({ showContractDataModal: false }, () => {
        if (nextProps.contractData.error) {
          openNotificationWithIcon('error', 'Oops!', nextProps.contractData.errorDetail);
          this.props.actions.resetContractDataError();
        }
      });
    }
  }

  /**
   * Se cambia el relatedProvider porque es de donde se sacan los roles para mostrarse en la tabla (TableDataProviderContacts)
   * y ahorrarnos crear un componente nuevo :D
   */
  // getContactListFormatted(contactList) {
  //   const contactListFormatted = [];
  //   for (let i = 0; i < contactList.length; i++) {
  //     let contact = Object.assign({}, contactList[i]).contact;
  //     if (contact.relatedProvider === null)
  //       contact.relatedProvider = { idOfContact: '', roles: [] };
  //     contact.relatedProvider.idOfContact = contact.parent;
  //     contact.relatedProvider.roles = contactList[i].roles;
  //     contactListFormatted.push({ contact });
  //   }
  //   return contactListFormatted;
  // }

  onSubmit() {
    this.handleSubmit();
  }

  getTenantsListFormatted(tenants) {
    const tenantListFormatted = [];

    for (let i = 0; i < tenants.length; i++) {
      const tenant = Object.assign({}, tenants[i]);

      tenantListFormatted.push({ tenant });
    }

    return tenantListFormatted;
  }

  getStageIndex() {
    const stages = get(this.state, 'options.rentalRequestStage');
    const currentStage = get(this.state, 'dealDetails.rentalRequestStage');
    let stageIndex = 0;

    stages.forEach((stage, index) => {
      if (stage.value === currentStage) {
        stageIndex = index;
      }
    });

    return stageIndex;
  }

  async calculatePrice(proposalDetails, changedField) {
    if (!changedField) {
      console.error('No changedField provided to calculatePrice', changedField);
      return;
    }

    let priceField = changedField;

    if (priceField === 'providerAverageMonthlyPrice' && !proposalDetails.taxIncluded) {
      priceField = 'providerAverageMonthlyPrice';
    } else if (
      priceField === 'providerAverageMontlyPriceTaxIncluded' &&
      proposalDetails.taxIncluded
    ) {
      priceField = 'providerAverageMontlyPriceTaxIncluded';
    } else if (
      proposalDetails.taxIncluded &&
      changedField !== 'monthlyPriceWithTax' &&
      changedField !== 'totalPriceWithTax'
    ) {
      priceField = 'monthlyPriceWithTax';
    } else if (
      (proposalDetails.plusTax &&
        changedField !== 'monthlyPrice' &&
        changedField !== 'totalPrice') ||
      changedField === 'taxExempt'
    ) {
      priceField = 'monthlyPrice';
    } else if (
      proposalDetails.taxExempt &&
      changedField !== 'monthlyPrice' &&
      changedField !== 'totalPrice'
    ) {
      priceField = 'monthlyPrice';
    }

    const endpointsByPriceField = {
      monthlyPrice: '',
      monthlyPriceWithTax: 'withTaxIncluded',
      totalPrice: 'fromTotalPrice',
      totalPriceWithTax: 'fromTotalPriceWithTaxIncluded',
      providerAverageMonthlyPrice: 'fromProviderMonthlyPrice',
      providerAverageMontlyPriceTaxIncluded: 'fromProviderMonthlyPriceTaxIncluded',
    };

    let priceEndpoint = endpointsByPriceField[priceField];

    let price = {
      amount: 0,
      currency: 'EUR',
    };
    if (isNumber(proposalDetails[priceField].amount)) {
      // It means priceField is not empty
      price = proposalDetails[priceField];
    }

    const payload = {
      dateCheckIn: new Date(proposalDetails.dateCheckIn).toISOString(),
      dateCheckOut: new Date(proposalDetails.dateCheckOut).toISOString(),
      price,
      commissionPercentage: proposalDetails.commissionPercentage,
      address: proposalDetails.address,
      taxValue: proposalDetails.taxValue,
      cleaningCharges: isNumber(get(proposalDetails, 'cleaningCharges.amount'))
        ? proposalDetails.cleaningCharges
        : DEFAULT_AMOUNT,
      cleaningChargeType: proposalDetails.cleaningChargeType,
      deposit: isNumber(get(proposalDetails, 'deposit.amount'))
        ? proposalDetails.deposit
        : DEFAULT_AMOUNT,
      proposalId: proposalDetails.id,
      opportunityId: proposalDetails.dealId,
    };

    try {
      const response = await api.request(
        `${ENDPOINT_V2}/pricing/proposals${isEmpty(priceEndpoint) ? '' : `/${priceEndpoint}`}`,
        'post',
        {
          body: JSON.stringify(payload),
        }
      );

      if (!isEmpty(response.json)) {
        this.setState({
          proposalDetails: {
            ...this.state.proposalDetails,
            ...response.json,
          },
        });
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', 'Ooops', 'Ha ocurrido un problema al calcular los precios');
    }
  }

  async onPriceFormChange(field, val) {
    const proposalDetails = {
      ...this.state.proposalDetails,
      [field]: val,
    };

    this.setState(
      { proposalDetails },
      async () => await this.calculatePrice(proposalDetails, field)
    );
  }

  contactSelected = contact => {
    browserHistory.push(`/contactDetails/${contact.id}`);
  };

  confirmRequestConfirmation() {
    this.props.actions.requestConfirmation(this.state.proposalDetails.id, error => {
      this.setState({ showRequestConfirmationModal: false });
      if (error) {
        openNotificationWithIcon('error', 'Oops!', error);
      } else {
        openNotificationWithIcon('success', 'Yuju!', 'Confirmación enviada al proveedor');
      }
    });
  }

  submitAddContact() {
    // comprobamos que el contacto no esté añadido en la lista de contactos de la propuesta
    const { contact } = this.state;

    if (
      contact.contact.contactType === 'COMPANY' &&
      find(
        get(
          this.state,
          this.state.editingContact
            ? 'contact.contactRole'
            : 'proposalDetails.contactToBeAddedRoles'
        ),
        ({ value }) => value === 'USUAL'
      )
    ) {
      return openNotificationWithIcon(
        'error',
        'Contacto inválido',
        'No se puede añadir personas jurídicas como contacto habitual de una propuesta'
      );
    }

    let contactsOfProvider = [];

    if (this.state.editingContact) {
      const contacts = this.state.proposalDetails.contactsOfProvider;

      contacts.forEach((value, index) => {
        if (value.contact.id === contact.contact.id) {
          contacts[index] = {
            roles: contact.contactRole ? contact.contactRole.map(rol => rol.value) : 'UNKNOWN',
            contact: contact.contact,
          };
        }
      });
      contactsOfProvider = contacts;
    } else {
      if (this.state.proposalDetails.contactsOfProvider) {
        for (let i = 0; i < this.state.proposalDetails.contactsOfProvider.length; i++) {
          const { contact } = this.state.proposalDetails.contactsOfProvider[i];

          if (contact && this.state.proposalDetails.contactToBeAdded === contact.id) {
            openNotificationWithIcon(
              'error',
              'No me engañes :P',
              'El contacto que has seleccionado ya está en la lista de contactos de la propuesta'
            );

            return;
          }
        }
      }

      const contact = getContactFromExtendedContactList(
        this.state.providerBaseContactAndChildren,
        this.state.proposalDetails.contactToBeAdded
      );

      if (contact) {
        contactsOfProvider = this.state.proposalDetails.contactsOfProvider;
        contactsOfProvider.push({
          contact,
          roles: this.state.proposalDetails.contactToBeAddedRoles.map(r => r.value),
        });
      }
    }

    const { proposalDetails } = this.state;

    proposalDetails.contactsOfProvider = contactsOfProvider;
    this.setState(
      {
        proposalDetails,
        signerIsCompanyError: false,
      },
      this.handleSubmit()
    );
    this.toggleContact();
  }

  submitAddTenant() {
    const { proposalDetails, dealDetails } = this.state;
    const { tenantContacts, tenantToBeAdded } = proposalDetails;
    const { contactsOfCompany } = dealDetails;

    if (!tenantToBeAdded) {
      this.setState({
        disableAddTenantButton: true,
      });

      return false;
    }

    if (tenantContacts && tenantContacts.length > 0) {
      for (let i = 0; i < tenantContacts.length; i += 1) {
        if (tenantContacts[i] && tenantContacts[i].id === tenantToBeAdded.id) {
          openNotificationWithIcon(
            'error',
            'Basta de engaños aquí',
            'El Inquilino que has seleccionado ya está en la lista de Inquilinos de la Propuesta'
          );

          return false;
        }
      }
    }

    const tenantsList = tenantContacts || [];

    if (tenantToBeAdded.identificationCode) {
      tenantsList.push({
        id: tenantToBeAdded.id,
        name: tenantToBeAdded.name,
        lastOTradeName: tenantToBeAdded.lastOTradeName,
        identificationCode: tenantToBeAdded.identificationCode,
      });

      let isDuplicated = false;

      for (let i = 0; i < contactsOfCompany.length; i += 1) {
        if (contactsOfCompany[i].contact.id === tenantToBeAdded.id) {
          isDuplicated = true;
          break;
        }
      }

      if (!isDuplicated) {
        contactsOfCompany.push({
          contact: tenantToBeAdded,
          roles: tenantToBeAdded.relatedCompany.roles,
        });
      }
    } else if (!tenantToBeAdded.identificationCode) {
      openNotificationWithIcon(
        'error',
        'Este Inquilino huele raro...',
        <div>
          <p>El Inquilino seleccionado no tiene DNI, cosa requerida. ¿Qué está sucediendo?</p>
          <div
            className='m-0 linkBehavior'
            style={{ marginBottom: 0, color: '#eeaf30' }}
            onClick={event => {
              event.preventDefault();
              browserHistory.push(`/contactDetails/${tenantToBeAdded.id}`);
            }}
          >
            Haz click aquí para ir al Inquilino
          </div>
        </div>
      );
      // return false;
    }

    const proposalDetailsObject = proposalDetails;

    proposalDetailsObject.tenantContacts = tenantsList;

    this.setState(
      {
        proposalDetails: proposalDetailsObject,
      },
      () => {
        this.handleSubmit();
      }
    );
    this.toggleTenant();
  }

  toggleContact() {
    this.setState({
      modalContact: !this.state.modalContact,
    });
    if (this.state.editingContact) {
      this.setState({
        editingContact: !this.state.editingContact,
      });
    }
  }

  editToggleContact(contact) {
    this.setState({
      modalContact: !this.state.modalContact,
      editingContact: !this.state.editingContact,
      contact,
    });
  }

  handleChange(e) {
    const { proposalDetails } = this.state;

    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    const name = e.target.name;

    proposalDetails[name] = value;

    if (name === 'plusTax' && value) {
      proposalDetails.typeOfTax = 'plusTax';
      proposalDetails.taxIncluded = false;
      proposalDetails.taxExempt = false;
    }

    if (name === 'taxIncluded' && value) {
      proposalDetails.typeOfTax = 'plusTax';
      proposalDetails.plusTax = false;
      proposalDetails.taxExempt = false;
    }

    if (name === 'taxExempt' && value) {
      proposalDetails.typeOfTax = 'taxExempt';
      proposalDetails.taxIncluded = false;
      proposalDetails.plusTax = false;
      proposalDetails.taxValue = '';
    }

    if (name === 'chargesIncluded' && !value) {
      proposalDetails.spendingLimit = null;
    }

    const newState = { proposalDetails };

    if (name.includes('date')) {
      const checkIn = get(proposalDetails, 'dateCheckIn');
      const checkOut = get(proposalDetails, 'dateCheckOut');

      newState.days = checkIn && checkOut ? getDays(new Date(checkIn), new Date(checkOut)) : null;
    }

    this.setState(newState, async () =>
      this.calculatePrice(newState.proposalDetails, value && name)
    );
  }

  handleDeleteContact(idToDelete) {
    const { proposalDetails } = this.state;

    if (
      proposalDetails.contactsOfProvider &&
      window.confirm('¿Quieres desvincular el contacto de la lista de contactos de la propuesta?')
    ) {
      const contactList = proposalDetails.contactsOfProvider;

      if (contactList.length === 1) {
        openNotificationWithIcon(
          'error',
          `No puedes hacerme esto :(`,
          'La propuesta tiene que tener al menos un contacto'
        );

        return;
      }

      for (let i = 0; i < contactList.length; i += 1) {
        const contact = contactList[i];

        if (contact && contact.contact && idToDelete === contact.contact.id) {
          contactList.splice(i, 1);
          proposalDetails.contactsOfProvider = contactList;
          this.setState(
            {
              proposalDetails,
            },
            this.handleSubmit()
          );

          return;
        }
      }
    }
  }

  handleAddressComplementChange(e) {
    const { proposalDetails } = this.state;

    if (!proposalDetails.address) {
      proposalDetails.address = {};
    }

    proposalDetails.address.addressComplement = e.target.value;
    this.setState({ proposalDetails }, () => {
      this.handlePlaceChanged(this.state.proposalDetails.address);
    });
  }

  handlePriceChange(e) {
    const { proposalDetails } = this.state;
    const { name, value } = e.target;

    if (value !== null && value !== '') {
      proposalDetails[name] = {
        currency: 'EUR',
        amount: parseFloat(value),
      };
    } else {
      proposalDetails[name] = null;
    }

    this.setState({ proposalDetails }, async () => {
      await this.calculatePrice(proposalDetails, name);
    });
  }

  handleChangeDate = e => {
    if (e.target.value && !isValidDate(e.target.value)) {
      openNotificationWithIcon('error', 'Quo vadis?', 'Comprueba la fecha... ¡Algo no cuadra!');
    }
  };

  handleChangePrice(e) {
    const { proposalDetails } = this.state;
    let newPrice = null;

    if (e.target.value) {
      newPrice = {
        amount: e.target.value,
        currency: 'EUR',
      };
    }

    proposalDetails[e.target.name] = newPrice;
    this.setState({
      proposalDetails,
    });
  }

  handleRejectChange(name, selectedOption) {
    const { proposalDetails } = this.state;

    proposalDetails[name] = selectedOption
      ? Array.isArray(selectedOption)
        ? selectedOption.map(option => option.value)
        : null
      : null;
    this.setState({ proposalDetails });
  }

  handleSelectChange(name, selectedOption) {
    const { proposalDetails } = this.state;
    const optionValue = get(selectedOption, 'value', '');

    if (name === 'proposalStage' && !proposalDetails.dealHSAgent) {
      return openNotificationWithIcon(
        'error',
        'Oops!',
        'No se puede cambiar el estado de una propuesta sin tener un gestor asignado a la oportunidad'
      );
    }

    if (name === 'cleaningChargeType') {
      if (proposalDetails.taxType === 'IVA' && optionValue && optionValue !== 'FINAL') {
        proposalDetails.taxValue = 10;
      }

      if (optionValue && optionValue !== 'FINAL') {
        openNotificationWithIcon(
          'warning',
          'Si cambias la periodicidad a Puntual / Quincenal / Semanal / Diaria , el IVA aplicable cambiará a: 10%'
        );
      }
    }

    if (selectedOption) {
      proposalDetails[name] = optionValue;
    } else if (name === 'taxType') {
      proposalDetails[name] = null;
    } else {
      proposalDetails[name] = null;
    }

    if (name === 'proposalClientRejectionReason') {
      proposalDetails.reasonsOfRejection = selectedOption;
    }

    if (name === 'proposalStage') {
      switch (optionValue) {
        case 'REJECTED':
          proposalDetails.clientResponse = optionValue;
          proposalDetails.aceptedDate = null;
          break;
        case 'ACCEPTED':
          proposalDetails.clientResponse = 'PRE_RESERVED';
          proposalDetails.proposalClientRejectionReason = null;
          proposalDetails.reasonsOfRejection = null;
          proposalDetails.rejectedDate = null;
          break;
        default:
          proposalDetails.proposalClientRejectionReason = null;
          proposalDetails.reasonsOfRejection = null;
          proposalDetails.rejectedDate = null;
          proposalDetails.aceptedDate = null;
          break;
      }
    }

    if (name === 'tenantId' && selectedOption) {
      this.setState({
        disableAddTenantButton: false,
      });

      proposalDetails.tenantToBeAdded = selectedOption.contact;
    }

    this.setState({ proposalDetails }, async () => {
      if (name === 'cleaningChargeType') {
        await this.calculatePrice(proposalDetails, 'cleaningChargeType');
      }
    });

    if (name === 'contactToBeAdded') {
      this.checkIfSignerIsCompany(selectedOption, proposalDetails.contactToBeAddedRoles);
      this.setState({ contact: selectedOption || {} });
    }
  }

  handleContactToBeAddedRole(roles) {
    const { contact } = this.state;
    // const key = this.state.editingContact ? 'editContact' : 'newContact';

    this.checkIfSignerIsCompany(contact, roles);

    // name = name.length > 0 ? name : 'contactInfo';
    // localContact[name] = selectedOption || null;
    // this.setState({ [key]: localContact });

    if (this.state.editingContact) {
      contact.contactRole = roles;
      this.setState({ contact });
    } else {
      const { proposalDetails } = this.state;

      proposalDetails.contactToBeAddedRoles = roles;
      this.setState({ proposalDetails });
    }
  }

  checkIfSignerIsCompany(contact, roles) {
    if (
      get(contact, 'contact.contactType', '') === CONTACT_TYPE.COMPANY &&
      roles &&
      !isEmpty(filter(roles, ['value', 'CONTRACT_SIGNING']))
    ) {
      this.setState({ signerIsCompanyError: true });

      return true;
    }

    this.setState({ signerIsCompanyError: false });

    return false;
  }

  handleChangeRichText(value) {
    this.setState({
      observationsTextObject: value,
    });
  }

  handleLoadImagesComplete(imagesLoaded) {
    const propertyPictures = get(this.state, 'proposalDetails.property.pictures', []);
    imagesLoaded = imagesLoaded.map((imageData, index) => {
      return {
        imageData,
        imageKey: propertyPictures[index],
      };
    });
    this.setState({
      imagesLoaded,
    });
  }

  handleFeaturedImageSelected(featuredPicturePath) {
    this.setState(prev => ({
      proposalDetails: {
        ...prev.proposalDetails,
        featuredPicturePath,
      },
      featuredPicturePath,
    }));
  }

  handleImageSelected(imagesSelected) {
    this.setState(prev => ({
      proposalDetails: {
        ...prev.proposalDetails,
        pictures: imagesSelected,
      },
    }));
    this.imagesChanged = true;
  }

  updateImagesLoaded(imagePaths) {
    let { imagesLoaded } = this.state;
    imagesLoaded = imagesLoaded.filter(image => imagePaths.includes(image.imageKey));
    this.setState({
      imagesLoaded,
    });
  }

  handleEditContact(contactId) {
    let proposalContact = {};

    if (!this.state.editingContact) {
      proposalContact = this.state.proposalDetails.contactsOfProvider.find(
        e => e.contact.id === contactId
      );
    }

    const editContact = {};

    editContact.contactRole = [];
    editContact.contact = proposalContact.contact;

    if (!proposalContact.roles) {
      proposalContact.roles = ['UNKNOWN'];
    } else {
      for (let i = 0; i < proposalContact.roles.length; i += 1) {
        editContact.contactRole[i] = this.state.options.contactRole.find(
          e => e.value === proposalContact.roles[i]
        );
      }
    }

    this.editToggleContact(editContact);
  }

  handleDeleteTenant(tenantId) {
    if (
      this.state.proposalDetails.tenantContacts.length &&
      window.confirm('¿Quieres desvincular el Inquilino de la lista de Inquilinos de la propuesta?')
    ) {
      const tenantList = this.state.proposalDetails.tenantContacts;

      for (let i = 0; i < tenantList.length; i++) {
        const tenant = tenantList[i];

        if (tenant && tenantId === tenant.id) {
          tenantList.splice(i, 1);
          const { proposalDetails } = this.state;

          proposalDetails.tenantContacts = tenantList;

          this.setState(
            {
              proposalDetails,
            },
            () => this.handleSubmit()
          );

          return;
        }
      }
    }
  }

  handleContractData() {
    this.setState({ showContractDataModal: true }, () =>
      this.props.actions.getContractData(this.state.proposalDetails.id)
    );
  }

  isValidSubmit() {
    if (
      this.state.proposalDetails.proposalStage === 'REJECTED' &&
      !this.state.proposalDetails.proposalClientRejectionReason
    ) {
      openNotificationWithIcon(
        'error',
        'Error',
        'La propuesta está rechazada, por tanto tienes que especificar un motivo de rechazo!'
      );

      return false;
    }

    if (this.state.proposalDetails.taxExempt && !this.state.proposalDetails.taxType) {
      openNotificationWithIcon(
        'error',
        'No me engañes!! :(',
        'Si especificas que el inmueble está exento de impuesto, tienes que indicar el tipo de impuesto'
      );

      return false;
    }

    if (
      !areValidDates(
        this.state.proposalDetails.dateCheckIn,
        this.state.proposalDetails.dateCheckOut
      )
    ) {
      openNotificationWithIcon(
        'error',
        'Pero illo...',
        'Has introducido mal las fechas. Por favor, revísalo.'
      );

      return false;
    }

    if (!this.state.proposalUrl) {
      openNotificationWithIcon(
        'error',
        'Esto es un error del LAB. Comunícalo.',
        'Para solucionar el error, vuelve a cargar la Oportunidad.\nError: "[No _links.self on DealDetails.Proposals]".'
      );

      return false;
    }

    return true;
  }

  handleSubmit() {
    if (!this.isValidForm() || !this.isValidSubmit()) {
      return false;
    }

    if (!this.state.proposalDetails.dealHSAgent) {
      openNotificationWithIcon(
        'error',
        'Oops!',
        'No se puede cambiar el estado de una propuesta sin tener un gestor asignado a la oportunidad'
      );

      return false;
    }

    const dateCheckIn = moment(this.state.proposalDetails.dateCheckIn)
      .format(PARSE_DATE_FORMAT)
      .toString();
    const dateCheckOut = moment(this.state.proposalDetails.dateCheckOut)
      .format(PARSE_DATE_FORMAT)
      .toString();

    const { proposalDetails } = this.state;

    proposalDetails.dateCheckIn = dateCheckIn;
    proposalDetails.dateCheckOut = dateCheckOut;

    // Images validation
    if (proposalDetails.pictures.length > 20) {
      openNotificationWithIcon(
        'error',
        'Sorry!!!',
        'La propuesta solo puede tener como máximo 20 imágenes seleccionadas'
      );
      return;
    }
    if (this.imagesChanged) {
      if (
        window.confirm(
          '¡OJO! Las fotos que hayas desmarcado para el dossier, se eliminarán también del inmueble. ¿Quieres continuar?'
        )
      ) {
        if (
          this.oldImagesSelectedLength > proposalDetails.pictures.length &&
          proposalDetails.pictures.length < 20
        ) {
          openNotificationWithIcon(
            'error',
            'Sorry!!!',
            'La propuesta debe tener al menos 20 imágenes seleccionadas. Si actualmente, el total de imágenes del inmueble dispone de menos, manten las fotos que tuviera seleccionadas o eliminalas desde la ficha del inmueble'
          );
          return;
        }
      } else {
        return;
      }
    }

    this.setState(
      {
        proposalDetails,
      },
      () => {
        proposalDetails.observations = this.state.observationsTextObject.toString('html');
        if (proposalDetails.observations === '<p><br/></p>') {
          proposalDetails.observations = '';
        }

        this.setState(
          {
            proposalDetails,
          },
          () => {
            proposalDetails.price = proposalDetails.monthlyPrice;
            proposalDetails.monthlyPrice =
              proposalDetails.paymentType !== PROPOSAL_PAYMENT_TYPES.ONE_TIME_PAYMENT;
            this.props.actions.saveProposalDetailsData(this.state.proposalUrl, proposalDetails);
            this.imagesChanged = false;
          }
        );
      }
    );
  }

  handlePreviewProposal() {
    if (get(this.state, 'proposalDetails.id')) {
      if (get(this.state, 'proposalDetails.proposalStage') === 'PREPARING') {
        const { proposalDetails } = this.state;

        const printableData = {
          ...proposalDetails,
          address: get(proposalDetails, 'address'),
          chargesIncluded: get(proposalDetails, 'chargesIncluded', false),
          dateFrom: get(proposalDetails, 'dateCheckIn', null),
          dateTo: get(proposalDetails, 'dateCheckOut', null),
          deposit: get(proposalDetails, 'deposit.amount', ''),
          // featuredImageIndex: get(proposalDetails, 'featuredImageIndex', ''),
          // imagesSelected: get(proposalDetails, 'imagesSelected', ''),
          // featuredPicturePath: get(proposalDetails, 'featuredPicturePath'),
          // pictures: get(proposalDetails, 'pictures'),
          monthlyPrice: get(proposalDetails, 'monthlyPrice', ''),
          plusTax: get(proposalDetails, 'plusTax', false),
          price: get(proposalDetails, 'price.amount', ''),
          // pricePerPerson: get(proposalDetails, 'pricePerPerson', false),
          taxIncluded: get(proposalDetails, 'taxIncluded', false),
          taxExempt: get(proposalDetails, 'taxExempt', false),
          taxType: get(proposalDetails, 'taxType', ''),
          taxValue: get(proposalDetails, 'taxValue', ''),
          tenants: get(proposalDetails, 'tenants', ''),
          title: get(proposalDetails, 'title', ''),
          clientResponse: get(proposalDetails, 'clientResponse', false),
          observations: get(proposalDetails, 'observations', ''),
          spendingLimit: get(proposalDetails, 'spendingLimit.amount', ''),
          cleaningCharges: get(proposalDetails, 'cleaningCharges', ''),
          cleaningChargeType: get(proposalDetails, 'cleaningChargeType', ''),
          proposalSaving: get(proposalDetails, 'proposalSaving', ''),
          monthlyPriceWithTax: get(proposalDetails, 'monthlyPriceWithTax', ''),
          totalPriceWithTax: get(proposalDetails, 'totalPriceWithTax', ''),
          commissionPercentage: get(proposalDetails, 'commissionPercentage', ''),
          clientTotalPrice: get(proposalDetails, 'clientTotalPrice', ''),
          showPaxPrice: get(proposalDetails, 'showPaxPrice', false),
          showTotalPrice: get(proposalDetails, 'showTotalPrice', false),
          cleaningChargeWithTax: get(proposalDetails, 'cleaningChargeWithTax', ''),
        };

        const data = assign({}, get(proposalDetails, 'property'), printableData);

        this.props.actions.printPropertyDetails(proposalDetails.property.id, data, data);
      } else {
        let dossierUrl = getNewDossierAndProposal(
          this.state.proposalDetails.dealId,
          this.state.proposalDetails.id
        );
        // let dossierUrl = !get(this.props, 'dealDetails.firstRequest', false)
        //   ? getNewDossierAndProposal(
        //       this.state.proposalDetails.dealId,
        //       this.state.proposalDetails.id
        //     )
        //   : getDossierAndProposal(
        //       this.state.proposalDetails.dealId,
        //       this.state.proposalDetails.id,
        //       get(this.props, 'dealDetails.hsagent.username')
        //     );
        window.open(dossierUrl, '_blank');
      }
    }
  }

  handleDeleteProposal() {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta propuesta?')) {
      this.props.actions.deleteProposal(this.state.proposalUrl);
    }
  }

  handlePlaceChanged(place) {
    let newProposalStatusDisabled = false;
    const { proposalDetails } = this.state;

    set(proposalDetails, 'address', {
      ...place,
      addressComplement: get(proposalDetails, 'address.addressComplement', null),
    });

    const propertyAddress = removeEmptyKeysFromObject(proposalDetails.property.address);

    if (this.areEqualsAddresses(proposalDetails.address, propertyAddress)) {
      proposalDetails.proposalStatus = 'OK';
    } else {
      proposalDetails.proposalStatus = 'UNUSABLE';
      newProposalStatusDisabled = true;
    }

    this.setState({
      proposalDetails,
      proposalStatusDisabled: newProposalStatusDisabled,
    });
  }

  toggleProposal() {
    const { modalProposal } = this.state;

    this.setState({
      modalProposal: !modalProposal,
    });
  }

  toggleTenant() {
    const { modalTenant } = this.state;

    this.setState({
      modalTenant: !modalTenant,
    });
  }

  isValidForm() {
    const inputsToValidate = document.querySelectorAll(
      `.proposal-${this.props.proposalIndex}`,
      '.validate'
    );
    const validForm = validateInputs(inputsToValidate);

    if (!validForm) {
      openNotificationWithIcon(
        'error',
        'Esto no me cuadra :(',
        'Parece que hay algún error en los datos que has introducido'
      );
    }

    return validForm;
  }

  areEqualsAddresses = (add1, add2) => {
    if (!add1 && !add2) {
      return true;
    }

    if (!add1 || !add2) {
      return false;
    }

    const formattedAddressAdd1 = add1.formattedAddress
      ? add1.formattedAddress.replace(/,/g, '').trim()
      : null;
    const formattedAddressAdd2 = add2.formattedAddress
      ? add2.formattedAddress.replace(/,/g, '').trim()
      : null;
    const addressComplementAdd1 = add1.addressComplement
      ? add1.addressComplement.replace(/,/g, '').trim()
      : null;
    const addressComplementAdd2 = add2.addressComplement
      ? add2.addressComplement.replace(/,/g, '').trim()
      : null;

    return (
      formattedAddressAdd1 === formattedAddressAdd2 &&
      addressComplementAdd1 === addressComplementAdd2
    );
  };

  confirmSendContractData() {
    this.props.actions.sendContractData(this.state.proposalDetails.id);
  }

  showPropertyDetails() {
    if (get(this.state, 'proposalDetails.property.deletionDate')) {
      openNotificationWithIcon(
        'warning',
        <span role='img' aria-label='emoji'>
          ¡Lo siento! &#128532;
        </span>,
        `¡Este inmueble se ha dado de baja!`
      );

      return;
    }

    browserHistory.push(`/propertyDetails/${this.state.proposalDetails.property.id}`);
  }

  isGenerateContractActive() {
    const proposal = this.state.proposalDetails;

    if (isEmpty(proposal)) {
      return false;
    }

    const {
      proposalStage = '',
      address = {},
      taxType = '',
      taxExempt = false,
      taxValue = 0,
    } = proposal;

    if (proposalStage !== 'ACCEPTED') {
      return false;
    }

    if (isSpain(address.country)) {
      if (taxExempt) {
        return true;
      } else if (isCanaryIslands(address.administrativeAreaLevel1) && taxType === 'IGIC') {
        return true;
      } else if (isCeutaMelilla(address.administrativeAreaLevel1) && taxType === 'IPSI') {
        return true;
      } else if (taxType === 'IVA' && (taxValue === 21 || taxValue === 10)) {
        return true;
      }
    }

    return false;
  }

  async downloadContractPdf() {
    const { id } = this.state.proposalDetails;
    openNotificationWithIcon('info', 'Empezando la generación del PDF', '', 15);

    try {
      const data = await getContractPDF(id);
      const contentType = data.headers.get('content-type');

      if (contentType && contentType.indexOf('application/json') !== -1) {
        const { error } = await data.json();
        throw error;
      }

      const blob = await data.blob();
      const link = document.createElement('a');

      const { dateCheckIn = 0, title = '' } = get(this.state, 'proposalDetails');
      const checkIn = new Date(dateCheckIn);
      const date = `${checkIn.getDate()}-${checkIn.getMonth() + 1}-${checkIn.getFullYear()}`;

      const { identificationCode = '', companyName = '', address = {} } = get(
        this.state,
        'dealDetails'
      );
      const locality =
        address.locality || address.administrativeAreaLevel2 || address.formattetdAddress || '';
      const name = `${date}_Contrato_${identificationCode}_${companyName}_${locality}_${title}`;

      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.pdf`;
      link.click();
      window.URL.revokeObjectURL(blob);
      openNotificationWithIcon('success', `¡Contrato generado correctamente!`);
    } catch (e) {
      openNotificationWithIcon('error', `No se ha podido generar el contrato debido a: "${e}"`);
    } finally {
      this.setState({
        isPdfDownloading: false,
      });
    }
  }

  render() {
    const shippingDate = this.state.proposalDetails.shippingDate
      ? moment(this.state.proposalDetails.shippingDate).format(DATETIME_FORMAT)
      : '';
    const providerConfirmationDate = this.state.proposalDetails.providerConfirmationDate
      ? moment(this.state.proposalDetails.providerConfirmationDate).format(DATETIME_FORMAT)
      : '';

    const { hasFieldsBlocked } = this.state;

    const deleteContact = val => (
      <div className='row'>
        <div>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={e => {
              e.preventDefault();
              this.handleEditContact(val);
            }}
            style={{ borderColor: 'white' }}
          >
            <Icon type='edit' />
          </button>
        </div>
        <div>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={e => {
              e.preventDefault();
              this.handleDeleteContact(val);
            }}
            style={{ borderColor: 'white' }}
          >
            <Icon type='delete' />
          </button>
        </div>
      </div>
    );
    const deleteTenant = val => (
      <div className='row'>
        <div>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={e => {
              e.preventDefault();
              this.handleDeleteTenant(val);
            }}
            style={{ borderColor: 'white' }}
            disabled={this.props.proposalDetailsSubmit.submitting}
          >
            <Icon type='delete' />
          </button>
        </div>
      </div>
    );
    const { contactsOfCompany } = this.state.dealDetails;
    const { baseContact: companyBaseContact } = this.props.companyDetails;
    const companyTypeIsPerson = companyBaseContact && companyBaseContact.contactType === 'PERSON';
    let extraContactsForTenantsSelect = [];

    if (contactsOfCompany) {
      const companyBaseContactObject = { contact: companyBaseContact };

      extraContactsForTenantsSelect = companyTypeIsPerson
        ? [companyBaseContactObject, ...contactsOfCompany]
        : [...contactsOfCompany];
    }

    const commissionToProvider =
      this.state.dealDetails.creationDate >= COMMISSION_TO_PROVIDER_DATE_THRESHOLD;

    return (
      <>
        <div className='col-sm-12 row'>
          <Modal
            visible={this.state.showContractDataModal}
            title='Generación del contrato'
            width={1575}
            onOk={this.confirmSendContractData}
            style={{ top: '50px' }}
            onCancel={() => this.setState({ showContractDataModal: false })}
            footer={[
              <Button key='back' onClick={() => this.setState({ showContractDataModal: false })}>
                Cancelar
              </Button>,
              <Button key='submit' type='primary' onClick={this.confirmSendContractData}>
                Enviar datos
              </Button>,
              <Button
                key='pdf'
                type='primary'
                loading={this.state.isPdfDownloading}
                onClick={() =>
                  this.setState(
                    {
                      isPdfDownloading: true,
                    },
                    () => this.downloadContractPdf()
                  )
                }
                disabled={!this.isGenerateContractActive()}
              >
                Generar contrato
              </Button>,
            ]}
          >
            <ContractData
              data={get(this.props, 'contractData.data')}
              hasCompanyInfo={get(this.props, 'dealDetails.hasCompanyInfo', false)}
              hasProviderInfo={get(this.state, 'proposalDetails.hasProviderInfo', false)}
            />
          </Modal>
          <Modal
            visible={this.state.showRequestConfirmationModal}
            title='Confirmación de propuesta'
            onOk={this.confirmRequestConfirmation}
            okText='Enviar'
            onCancel={() => this.setState({ showRequestConfirmationModal: false })}
            cancelText='Cancelar'
          >
            <Row>
              <Col span={24}>¿Seguro que quieres enviar la confirmación?</Col>
            </Row>
          </Modal>

          {/* FORMULARIO */}

          {/* BOTONES LATERALES IZQUIERDOS */}
          <div className='col-sm-2'>
            <button
              disabled={this.props.proposalDetailsSubmit.submitting}
              type='button'
              className='btn btn-primary btn-block alignLeft'
              onClick={this.onSubmit}
            >
              Guardar
            </button>
            <button
              disabled={
                this.props.proposalDetailsSubmit.submitting ||
                this.state.dealDetails.rentalRequestStage ===
                  RENTAL_REQUEST_STAGES.S050_RENTAL_CONFIRMED ||
                this.state.dealDetails.rentalRequestStage ===
                  RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION
              }
              type='button'
              className='btn btn-primary btn-block alignLeft buttonBreakLine'
              onClick={this.handleDeleteProposal}
            >
              Eliminar
            </button>
            <hr
              style={{
                height: '3px',
                borderLeft: '7vw solid rgba(0, 0, 0, 0.1)',
              }}
            />
            <button
              disabled={this.props.proposalDetailsSubmit.submitting}
              type='button'
              className='btn btn-primary btn-block alignLeft buttonBreakLine'
              onClick={this.handlePreviewProposal}
            >
              Previsualizar propuesta
            </button>
            {/* <button
                type='button'
                className='btn btn-primary btn-block alignLeft buttonBreakLine'
                onClick={this.props.handleAvailabilityQuery}
              >
                Enviar consulta disponibilidad
              </button> */}
            <AvailabilityButton
              deal={this.state.dealDetails}
              property={this.state.proposalDetails.property}
              currentUsername={this.props.currentUsername}
              onAvailabilityQueried={this.props.onAvailabilityQueried}
              proposal={this.state.proposalDetails}
            />
            <button
              disabled={
                this.state.proposalDetails.proposalStage === 'ACCEPTED' ||
                this.state.proposalDetails.proposalStage === 'REJECTED' ||
                this.props.proposalDetailsSubmit.submitting
              }
              type='button'
              className='btn btn-primary btn-block alignLeft buttonBreakLine'
              onClick={() => this.setState({ showRequestConfirmationModal: true })}
            >
              Confirmar datos de la propuesta
            </button>
            <button
              disabled={this.props.proposalDetailsSubmit.submitting}
              type='button'
              className='btn btn-primary btn-block alignLeft buttonBreakLine'
              onClick={() => {
                this.setState({
                  modalProposal: true,
                });
              }}
            >
              Copiar en otra oportunidad
            </button>
            <Modal
              visible={this.state.modalProposal}
              title='Copiar Propuesta en la oportunidad...'
              onCancel={() => this.setState({ modalProposal: false })}
              footer={null}
            >
              <FormNewProposal
                proposal={this.proposalToCopy()}
                preselectedContactId={get(this.state, 'proposalDetails.property.usualContactId')}
                showButtons={false}
              />
            </Modal>
            <button
              disabled={
                this.props.proposalDetailsSubmit.submitting ||
                /* this.state.proposalDetails.contractDataSent || */
                this.state.proposalDetails.proposalStage === 'PREPARING' ||
                this.state.proposalDetails.proposalStage === 'SHIPPED' ||
                this.state.proposalDetails.proposalStage === 'REJECTED' ||
                this.state.proposalDetails.proposalStatus === 'UNUSABLE'
              }
              type='button'
              className='btn btn-primary btn-block alignLeft buttonBreakLine'
              onClick={this.handleContractData}
            >
              {this.state.proposalDetails.contractDataSent
                ? 'Volver a generar contrato'
                : 'Generar contrato'}
            </button>

            <br />
            {this.state.proposalDetails.creationDate ? (
              <p>
                <b>Creado por:</b>
                <br />
                {prettyPrintAgentFromEmail(get(this.state, 'proposalDetails.createdBy'), '')}
                <br />
                {moment(this.state.proposalDetails.creationDate).format(DATETIME_FORMAT)}
              </p>
            ) : (
              ''
            )}
            {this.state.proposalDetails.updateDate ? (
              <p>
                <b>Última actualización por:</b>
                <br />
                {prettyPrintAgentFromEmail(get(this.state, 'proposalDetails.updatedBy'), '')}
                <br />
                {moment(this.state.proposalDetails.updateDate).format(DATETIME_FORMAT)}
              </p>
            ) : (
              ''
            )}
            {shippingDate && (
              <p>
                Fecha de envío: <br /> {shippingDate}
              </p>
            )}
            {this.state.proposalDetails.providerConfirmationDate && (
              <p>
                Confirmación de datos: <br /> {providerConfirmationDate}
              </p>
            )}
            {this.state.proposalDetails.clientResponse && (
              <p>
                Respuesta del cliente: <br />
                {this.state.proposalDetails.clientResponse
                  ? getTextValueFromSelectOptions(
                      this.state.proposalDetails.clientResponse,
                      this.state.options.clientResponse
                    )
                  : ''}
              </p>
            )}
            {this.state.proposalDetails.clientResponse &&
              this.state.proposalDetails.clientResponseDate && (
                <p>
                  Fecha de la respuesta: <br />
                  {this.state.proposalDetails.clientResponseDate
                    ? moment(this.state.proposalDetails.clientResponseDate).format(DATE_FORMAT)
                    : ''}
                </p>
              )}
            {(this.state.proposalDetails.clientResponse === 'REJECTED' ||
              this.state.proposalDetails.proposalStage === 'REJECTED') &&
              this.state.proposalDetails.proposalClientRejectionReason && (
                <p>
                  Motivo: <br />
                  {this.state.proposalDetails.proposalClientRejectionReason
                    ? getTextValuesFromSelectedOptions(
                        this.state.proposalDetails.proposalClientRejectionReason,
                        this.state.options.reasonsOfRejection
                      )
                    : ''}
                </p>
              )}
          </div>
          {/* PESTAÑAS */}
          <div className='card-container col-sm-10'>
            {!isNil(this.state.proposalDetails.rootProposalId) && (
              <div className='pb-3'>
                <ReusedProposalInfo rootProposalId={this.state.proposalDetails.rootProposalId} />
              </div>
            )}
            <Tabs type='card'>
              {/* PESTAÑA 1 */}
              <TabPane tab='Información' key='1'>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor='proposalStage'>Etapa de la Propuesta</label>
                      <Select
                        name='proposalStage'
                        className='form-control'
                        disabled={this.getStageIndex() >= 4}
                        value={
                          this.state.proposalDetails.proposalStage
                            ? this.state.proposalDetails.proposalStage
                            : ''
                        }
                        options={this.state.options.proposalStage}
                        onChange={value => {
                          this.handleSelectChange('proposalStage', value);
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor='proposalStatus'>Estado de la Propuesta</label>
                      <Select
                        name='proposalStatus'
                        className='form-control'
                        value={
                          this.state.proposalDetails.proposalStatus
                            ? this.state.proposalDetails.proposalStatus
                            : ''
                        }
                        options={this.state.options.proposalStatus}
                        onChange={value => {
                          this.handleSelectChange('proposalStatus', value);
                        }}
                        disabled={this.state.proposalStatusDisabled}
                      />
                    </div>
                  </div>
                  {this.state.proposalDetails.proposalStage === 'REJECTED' && (
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='proposalStatus'>Motivo de rechazo</label>
                        <Select
                          name='proposalClientRejectionReason'
                          className='form-control validate validate-required'
                          options={this.state.options.reasonsOfRejection}
                          value={
                            this.state.proposalDetails.proposalClientRejectionReason
                              ? this.state.proposalDetails.proposalClientRejectionReason
                              : ''
                          }
                          onChange={value => {
                            this.handleRejectChange('proposalClientRejectionReason', value);
                          }}
                          multi
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='form-group'>
                      <label htmlFor='proposalDetailTitle'>Título (dossier)</label>
                      <input
                        type='text'
                        className='form-control'
                        id='proposalDetailTitle'
                        name='title'
                        placeholder='Título inmueble (dossier)'
                        value={this.state.proposalDetails ? this.state.proposalDetails.title : ''}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <label htmlFor='proposalDetailAddress'>Dirección (dossier)</label>
                      <GoogleMapsAutocomplete
                        handlePlaceChanged={this.handlePlaceChanged}
                        initValue={
                          this.state.proposalDetails ? this.state.proposalDetails.address : ''
                        }
                        inputClassName='form-control'
                        id={`proposalAddress${this.props.proposalIndex}`}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-group'>
                      <label htmlFor='propertyDetailAddressComplement'>
                        Complemento de dirección
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='propertyDetailAddressComplement'
                        name='address.addressComplement'
                        placeholder='n.3'
                        value={
                          this.state.proposalDetails.address &&
                          this.state.proposalDetails.address.addressComplement
                            ? this.state.proposalDetails.address.addressComplement
                            : ''
                        }
                        onChange={this.handleAddressComplementChange}
                      />
                    </div>
                  </div>
                </div>
                {this.state.proposalDetails.property && (
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='form-group'>
                        <button
                          type='button'
                          className='btn btn-primary btn-block alignLeft buttonBreakLine propertyBackgroundColor noBorder linkBehavior'
                          onClick={this.showPropertyDetails}
                        >
                          {this.state.proposalDetails.property.title}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='col-sm-3'>
                    <label>&nbsp;</label>
                    <CheckboxButton
                      borderStyle='proposalCheckboxActive'
                      id='proposalDetailChargesIncluded'
                      name='chargesIncluded'
                      handleChange={this.handleChange}
                      content='Suministros incluidos'
                      disabled={hasFieldsBlocked}
                      value={
                        this.state.proposalDetails && this.state.proposalDetails.chargesIncluded
                          ? this.state.proposalDetails.chargesIncluded
                          : false
                      }
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label htmlFor='proposalDetailSpendingLimit'>
                      {this.state.proposalDetails.chargesIncluded
                        ? 'Límite de suministros (€)'
                        : 'Importe fijo suministros (€)'}
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      id='proposalDetailSpendingLimit'
                      name='spendingLimit'
                      min='0'
                      placeholder={
                        this.state.proposalDetails && this.state.proposalDetails.chargesIncluded
                          ? 'Sin límite'
                          : 'No incluido'
                      }
                      disabled={hasFieldsBlocked}
                      value={
                        this.state.proposalDetails.spendingLimit
                          ? normalizeNumberToDecimals(
                              this.state.proposalDetails.spendingLimit.amount,
                              2
                            )
                          : ''
                      }
                      onChange={this.handleChangePrice}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label htmlFor='cleaningCharges'>Gastos de limpieza/mes</label>
                    <input
                      type='number'
                      className='form-control'
                      id='cleaningCharges'
                      name='cleaningCharges'
                      placeholder='0'
                      min='0'
                      value={
                        this.state.proposalDetails.cleaningCharges
                          ? this.state.proposalDetails.cleaningCharges.amount
                          : ''
                      }
                      disabled={hasFieldsBlocked}
                      onChange={this.handlePriceChange}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label htmlFor='cleaningChargeType'>Periodicidad de limpieza</label>
                    <Select
                      name='cleaningChargeType'
                      className='form-control'
                      value={
                        this.state.proposalDetails.cleaningChargeType
                          ? this.state.proposalDetails.cleaningChargeType
                          : ''
                      }
                      options={this.state.options.cleaningChargeType}
                      onChange={value => {
                        this.handleSelectChange('cleaningChargeType', value);
                      }}
                      disabled={hasFieldsBlocked}
                    />
                  </div>
                </div>
                <br />
                {this.state.proposalDetails.property && (
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='proposalDetailHomyspaceReference'>Ref. HomySpace</label>
                        <input
                          type='text'
                          className='form-control noBorder'
                          disabled
                          id='proposalDetailPropertyHomyspaceReference'
                          name='property.homyspaceReference'
                          placeholder='HS-1234'
                          value={this.state.proposalDetails.property.homyspaceReference}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <div className='form-group'>
                        <label htmlFor='proposalDetailRooms'>Hab.</label>
                        <input
                          type='number'
                          className='form-control noBorder'
                          disabled
                          id='proposalDetailPropertyRooms'
                          name='property.rooms'
                          placeholder='0'
                          min='0'
                          value={this.state.proposalDetails.property.rooms}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <div className='form-group'>
                        <label htmlFor='proposalDetailBeds'>Camas</label>
                        <input
                          type='number'
                          className='form-control noBorder'
                          disabled
                          id='proposalDetailPropertyBeds'
                          name='property.beds'
                          placeholder='0'
                          min='0'
                          value={this.state.proposalDetails.property.beds}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <div className='form-group'>
                        <label htmlFor='proposalDetailBaths'>Baños</label>
                        <input
                          type='number'
                          className='form-control noBorder'
                          disabled
                          id='proposalDetailPropertyBaths'
                          name='property.bathrooms'
                          placeholder='0'
                          min='0'
                          value={
                            this.state.proposalDetails.property.bathrooms +
                            this.state.proposalDetails.property.toilets
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor='proposalDetailDateCheckIn'>Fecha de entrada</label>
                      <input
                        type='date'
                        className='form-control'
                        id='proposalDetailDateCheckIn'
                        name='dateCheckIn'
                        value={
                          this.state.proposalDetails.dateCheckIn
                            ? moment(this.state.proposalDetails.dateCheckIn).format(
                                PARSE_DATE_FORMAT
                              )
                            : ''
                        }
                        disabled={hasFieldsBlocked}
                        onChange={this.handleChange}
                        onBlur={this.handleChangeDate}
                      />
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor='proposalDetailDateCheckOut'>Fecha de salida</label>
                      <input
                        type='date'
                        className='form-control'
                        id='proposalDetailDateCheckOut'
                        name='dateCheckOut'
                        value={
                          this.state.proposalDetails.dateCheckOut
                            ? moment(this.state.proposalDetails.dateCheckOut).format(
                                PARSE_DATE_FORMAT
                              )
                            : ''
                        }
                        onChange={this.handleChange}
                        onBlur={this.handleChangeDate}
                        disabled={hasFieldsBlocked}
                      />
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor='proposalDetailDays'>Noches de estancia</label>
                      <InputNumber
                        className='noBorder'
                        disabled
                        id='proposalDetailPropertyDays'
                        name='property.days'
                        min={0}
                        placeholder=''
                        value={this.state.days}
                      />
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor='proposalDetailTenants'>Total inquilinos</label>
                      <input
                        type='number'
                        className='form-control'
                        id='proposalDetailTenants'
                        name='tenants'
                        min='0'
                        value={this.state.proposalDetails ? this.state.proposalDetails.tenants : ''}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <br />
              </TabPane>
              <TabPane tab='Precios' key='2' forceRender>
                {get(this.state, 'proposalDetails.proposalStatus', '') === 'UNUSABLE' &&
                  !get(this.state, 'proposalDetails.price.amount') && (
                    <Alert
                      message='La propuesta generada automáticamente NO contiene precio puesto que no disponemos de PMA en la ciudad para el numero de habitaciones solicitado.'
                      type='warning'
                      closable
                    />
                  )}
                <div className='row'>
                  <h6>Impuestos (alquiler)</h6>
                </div>
                <hr className='hs-hr' />
                <div className='col-sm-12'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <label>&nbsp;</label>
                      <CheckboxButton
                        borderStyle='proposalCheckboxActive'
                        id='proposalDetailPlusTax'
                        name='plusTax'
                        handleChange={this.handleChange}
                        content='+ Impuesto'
                        value={
                          this.state.proposalDetails && this.state.proposalDetails.plusTax
                            ? this.state.proposalDetails.plusTax
                            : false
                        }
                      />
                    </div>
                    <div className='col-sm-4'>
                      <label>&nbsp;</label>
                      <CheckboxButton
                        borderStyle='proposalCheckboxActive'
                        id='proposalDetailTaxIncluded'
                        name='taxIncluded'
                        handleChange={this.handleChange}
                        content='Impuesto incluido'
                        value={
                          this.state.proposalDetails && this.state.proposalDetails.taxIncluded
                            ? this.state.proposalDetails.taxIncluded
                            : false
                        }
                      />
                    </div>
                    <div className='col-sm-4'>
                      <label>&nbsp;</label>
                      <CheckboxButton
                        borderStyle='proposalCheckboxActive'
                        id='proposalDetailTaxExempt'
                        name='taxExempt'
                        handleChange={this.handleChange}
                        content='Exento'
                        value={
                          this.state.proposalDetails && this.state.proposalDetails.taxExempt
                            ? this.state.proposalDetails.taxExempt
                            : false
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='proposalDetailTaxValue'>% Impuesto</label>
                        <input
                          type='number'
                          className='form-control'
                          id='proposalDetailTaxValue'
                          name='taxValue'
                          placeholder={this.state.proposalDetails.taxExempt ? '' : '0'}
                          min='0'
                          value={this.state.proposalDetails.taxValue || ''}
                          onChange={this.handleChange}
                          disabled={hasFieldsBlocked || this.state.proposalDetails.taxExempt}
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='proposalDetailTaxType'>Impuesto</label>
                        <Select
                          name='taxType'
                          className='form-control'
                          disabled={hasFieldsBlocked || this.state.proposalDetails.taxExempt}
                          // value={
                          //   !this.state.proposalDetails.taxExempt &&
                          //   this.state.proposalDetails.taxType
                          //     ? this.state.proposalDetails.taxType
                          //     : ''
                          // }
                          value={this.state.proposalDetails.taxType}
                          options={this.state.options.taxType}
                          onChange={value => {
                            this.handleSelectChange('taxType', value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className='row'>
                  {commissionToProvider && <h6>Precios para el Cliente</h6>}
                  {!commissionToProvider && <h6>Precios iniciales del proveedor</h6>}
                </div>
                <hr className='hs-hr' />
                <FormProviderInitialPrice
                  data={this.state.proposalDetails}
                  onChange={this.onPriceFormChange}
                  hasFieldsBlocked={hasFieldsBlocked}
                />
                <br />
                <div className='row'>
                  {commissionToProvider && <h6>Precios para el Proveedor</h6>}
                  {!commissionToProvider && <h6>Comisión al Proveedor</h6>}
                </div>
                <hr className='hs-hr' />
                <FormProviderCommission
                  data={this.state.proposalDetails}
                  dealDetails={this.state.dealDetails}
                  // onChange={this.onPriceFormChange.bind(this, 'commissionPercentage')}
                  onChange={this.onPriceFormChange}
                  hasFieldsBlocked={hasFieldsBlocked}
                />
                {!commissionToProvider && (
                  <>
                    <div className='row'>&nbsp;</div>
                    <FormDossierClientPrices
                      data={this.state.proposalDetails}
                      onChangeTotalPrice={this.onPriceFormChange.bind(this, 'showTotalPrice')}
                    />
                  </>
                )}
              </TabPane>
              <TabPane tab={<span name='dossierTab'>Dossier</span>} key='3'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='form-group'>
                      {(() => {
                        if (
                          this.state.proposalDetails.proposalStage === 'SHIPPED' ||
                          this.state.proposalDetails.proposalStage === 'ACCEPTED' ||
                          this.state.proposalDetails.proposalStage === 'REJECTED'
                        ) {
                          let dossierUrl = !get(this.props, 'dealDetails.firstRequest', false)
                            ? this.state.urlNewPublicDossier
                            : this.state.urlPublicDossier +
                              (!isEmpty(get(this.props, 'dealDetails.hsagent.username'))
                                ? `?agent=${get(this.props, 'dealDetails.hsagent.username')}`
                                : '');
                          return (
                            <label htmlFor='proposalDetailUrlPublicDossier'>
                              URL dossier público
                              <input
                                type='text'
                                className='form-control noBorder'
                                disabled
                                id='proposalDetailUrlPublicDossier'
                                name='urlPublicDossier'
                                placeholder='https://...'
                                value={dossierUrl}
                              />
                            </label>
                          );
                        }

                        return (
                          <span>
                            El dossier no está disponible ya que no se ha enviado la propuesta
                          </span>
                        );
                      })()}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-12'>
                    <label htmlFor='proposalDetailObservations'>Observaciones:</label>
                    <div className='form-group totalBorder'>
                      <RichTextEditor
                        id='proposalDetailObservations'
                        name='observations'
                        ref='RichTextEditorObservations'
                        className='form-control'
                        value={this.state.observationsTextObject}
                        onChange={this.handleChangeRichText}
                      />
                    </div>
                  </div>
                </div>

                {this.state.proposalDetails.property && (
                  <div>
                    <ImageLoader
                      ref='imageLoader'
                      s3bucket={this.state.proposalDetails.property.bucketName}
                      imagesToLoad={this.state.proposalDetails.property.pictures}
                      handleLoadComplete={this.handleLoadImagesComplete}
                    />
                    <div className='row'>
                      <div className='col-sm-6'>
                        Imágen destacada
                        <ImageSelector
                          imagesToRender={this.state.imagesLoaded}
                          id='featuredImage'
                          borderStyle='primaryCheckboxActive'
                          handleImageSelect={this.handleFeaturedImageSelected}
                          noPadding
                          selectedImages={this.state.proposalDetails.featuredPicturePath}
                        />
                      </div>
                      <div className='col-sm-6'>
                        Imágenes del dossier
                        <ImageSelector
                          imagesToRender={this.state.imagesLoaded}
                          id='includeImage'
                          borderStyle='primaryCheckboxActive'
                          handleImageSelect={this.handleImageSelected}
                          selectedImages={this.state.proposalDetails.pictures}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </TabPane>
              <TabPane
                tab={
                  this.state.proposalDetails.accessRecords
                    ? `${this.state.proposalDetails.accessRecords.length} accesos`
                    : 'Sin accesos'
                }
                key='4'
              >
                <div className='col-sm-12'>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <b>Fecha y hora</b>
                    </div>
                    <div className='col-sm-9'>
                      <b>Dirección</b>
                    </div>
                  </div>
                  {this.state.proposalDetails.accessRecords &&
                    this.state.proposalDetails.accessRecords.length &&
                    this.state.proposalDetails.accessRecords.map((item, key) => (
                      <div className='row' key={`proposalAccess${key}`}>
                        <div className='col-sm-3'>{moment(item.date).format(DATETIME_FORMAT)}</div>
                        <div className='col-sm-9'>{item.address}</div>
                      </div>
                    ))}
                </div>
              </TabPane>
              <TabPane tab={<span name='providerTab'>Proveedor</span>} key='5'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='form-group'>
                      <label htmlFor='providerFormDataUrl'>
                        URL del formulario de datos del Proveedor:
                      </label>
                      <input
                        type='text'
                        className='form-control noBorder'
                        disabled
                        id='providerFormDataUrl'
                        name='urlPoviderFormData'
                        placeholder='https://...'
                        value={
                          get(this.state, 'proposalDetails.id') &&
                          get(this.state, 'proposalDetails.proposalStage') !== 'REJECTED'
                            ? getDossierProvider(get(this.state, 'proposalDetails.id'))
                            : ''
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label htmlFor='providerFormDataEmailContractSigning'>
                        Correo para la firma de contrato:
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='providerFormDataEmailContractSigning'
                        name='emailContractSigning'
                        placeholder='firmante@email.com'
                        value={
                          this.state.proposalDetails &&
                          this.state.proposalDetails.emailContractSigning
                            ? this.state.proposalDetails.emailContractSigning
                            : ''
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                {this.state.providerDetails && !isEmptyObject(this.state.providerDetails) && (
                  <div>
                    <br />
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='form-group'>
                          <button
                            type='button'
                            className='btn btn-primary btn-block alignLeft buttonBreakLine providerBackgroundColor noBorder linkBehavior'
                            onClick={e => {
                              e.preventDefault();
                              browserHistory.push(
                                `/providerDetails/${this.state.providerDetails.id}`
                              );
                            }}
                          >
                            {`${
                              this.state.providerDetails.baseContact.name
                                ? this.state.providerDetails.baseContact.name
                                : ''
                            } ${
                              this.state.providerDetails.baseContact.lastOTradeName
                                ? this.state.providerDetails.baseContact.lastOTradeName
                                : ''
                            }${
                              this.state.providerDetails.baseContact.email
                                ? ` (${this.state.providerDetails.baseContact.email})`
                                : ''
                            }`}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-sm-10' />
                      <div className='col-sm-2'>
                        <Modal
                          visible={this.state.modalContact}
                          className='modal-lg'
                          title={
                            this.state.editingContact
                              ? 'Modificar función de contacto de la propuesta'
                              : 'Añadir contacto a la propuesta'
                          }
                          onOk={this.submitAddContact}
                          onCancel={() => {
                            const { proposalDetails } = this.state;

                            proposalDetails.contactToBeAdded = '';
                            proposalDetails.contactToBeAddedRoles = [];

                            this.setState({
                              modalContact: false,
                              signerIsCompanyError: false,
                              contact: {},
                              proposalDetails,
                            });
                          }}
                          okButtonProps={{
                            disabled: this.state.signerIsCompanyError,
                          }}
                        >
                          {this.state.signerIsCompanyError && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Alert
                                    message={SIGNER_IS_COMPANY_MESSAGE}
                                    type='error'
                                    showIcon
                                  />
                                </Col>
                              </Row>
                              <br />
                            </>
                          )}
                          <Row>
                            <Col span={24}>
                              <div className='form-group'>
                                <label htmlFor='contactToBeAdded' className='required'>
                                  Contacto del proveedor
                                </label>
                                {this.state.editingContact && (
                                  <Select
                                    name='contactToBeAdded'
                                    className='form-control'
                                    value={get(this.state, 'contact.contact.id', '')}
                                    disabled
                                    options={this.state.contactList}
                                    onChange={value => {
                                      this.handleSelectChange('contactToBeAdded', value);
                                    }}
                                  />
                                )}
                                {!this.state.editingContact && (
                                  <Select
                                    name='contactToBeAdded'
                                    className='form-control'
                                    value={
                                      this.state.proposalDetails.contactToBeAdded
                                        ? this.state.proposalDetails.contactToBeAdded
                                        : ''
                                    }
                                    options={this.state.contactList}
                                    onChange={value => {
                                      this.handleSelectChange('contactToBeAdded', value);
                                    }}
                                  />
                                )}
                              </div>
                              <div className='form-group'>
                                <label htmlFor='contactRole' className='required'>
                                  Función
                                </label>
                                {this.state.editingContact && (
                                  <Select
                                    name='contactToBeAddedRole'
                                    className='form-control proposal validate validate-required'
                                    value={
                                      this.state.contact.contactRole
                                        ? this.state.contact.contactRole
                                        : ''
                                    }
                                    options={this.state.options.contactRole}
                                    onChange={this.handleContactToBeAddedRole}
                                    multi
                                  />
                                )}
                                {!this.state.editingContact && (
                                  <Select
                                    name='contactToBeAddedRole'
                                    className='form-control proposal validate validate-required'
                                    value={
                                      this.state.proposalDetails.contactToBeAddedRoles
                                        ? this.state.proposalDetails.contactToBeAddedRoles
                                        : ''
                                    }
                                    options={this.state.options.contactRole}
                                    onChange={this.handleContactToBeAddedRole}
                                    multi
                                  />
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Modal>
                      </div>
                      <div className='col-sm-10' />
                    </div>
                    <br />
                    <div className='row'>
                      <div className='col-sm-12'>
                        <TableDataProviderContacts
                          callbackFunctionOnContactSelect={this.contactSelected}
                          contactList={
                            // this.getContactListFormatted(
                            this.state.proposalDetails.contactsOfProvider
                            // )
                          }
                          deleteRender={deleteContact}
                        />
                      </div>
                    </div>
                    <br />
                    <div className='row'>
                      <div className='col-sm-2'>
                        <button
                          disabled={this.props.proposalDetailsSubmit.submitting}
                          type='button'
                          className='btn btn-primary btn-block alignLeft buttonBreakLine'
                          onClick={this.toggleContact}
                        >
                          Añadir contacto
                        </button>
                      </div>
                      <div className='col-sm-10' />
                    </div>
                  </div>
                )}
              </TabPane>
              <TabPane
                tab={
                  this.state.proposalDetails.tenantContacts &&
                  this.state.proposalDetails.tenantContacts.length > 0
                    ? `${this.state.proposalDetails.tenantContacts.length} Inquilinos`
                    : 'Sin Inquilinos'
                }
                key='6'
              >
                <div className='row'>
                  <div className='col-sm-2'>
                    <Modal
                      visible={this.state.modalTenant}
                      title='Añadir Inquilinos a la propuesta'
                      onOk={this.submitAddTenant}
                      okButtonProps={{
                        disabled: this.state.disableAddTenantButton,
                      }}
                      onCancel={() => this.setState({ modalTenant: false })}
                      destroyOnClose
                    >
                      <Row>
                        <Col span={24}>
                          <SelectContact
                            name='tenantId'
                            id='tenantId'
                            label='Inquilinos del Cliente'
                            isRequired={false}
                            handleChange={this.handleSelectChange}
                            extraContacts={extraContactsForTenantsSelect}
                            prefilter={
                              this.state.dealDetails.companyId
                                ? `&companyId=${this.state.dealDetails.companyId}&roles=TENANT`
                                : ''
                            }
                          />
                        </Col>
                      </Row>
                    </Modal>
                  </div>
                </div>
                <br />
                <div className='row'>
                  <div className='col-sm-12'>
                    {(!this.state.proposalDetails.tenantContacts ||
                      this.state.proposalDetails.tenantContacts === null ||
                      this.state.proposalDetails.tenantContacts.length === 0) && (
                      <div>
                        <p>Todavía no existen inquilinos para esta propuesta.</p>
                        <p>
                          Agrega Inquilinos a esta Propuesta que proceden de{' '}
                          <b>Contactos del Cliente</b> con rol <b>Inquilino</b>.
                        </p>
                      </div>
                    )}
                    {this.state.proposalDetails.tenantContacts &&
                      this.state.proposalDetails.tenantContacts.length > 0 && (
                        <TableDataTenants
                          callbackFunctionOnContactSelect={this.contactSelected}
                          tenantsList={this.getTenantsListFormatted(
                            this.state.proposalDetails.tenantContacts
                          )}
                          deleteRender={deleteTenant}
                        />
                      )}
                  </div>
                </div>
                <br />
                <div className='row'>
                  <div className='col-sm-2'>
                    <button
                      disabled={this.props.proposalDetailsSubmit.submitting}
                      type='button'
                      className='btn btn-primary btn-block alignLeft buttonBreakLine'
                      onClick={this.toggleTenant}
                    >
                      Añadir Inquilino
                    </button>
                  </div>
                  <div className='col-sm-10' />
                </div>
              </TabPane>
              <TabPane tab='Post venta' key='7'>
                <AfterSales
                  proposalStage={
                    this.state.proposalDetails.proposalStage
                      ? this.state.proposalDetails.proposalStage
                      : ''
                  }
                  rentalRequestStage={
                    this.state.rentalRequestStage ? this.state.rentalRequestStage : ''
                  }
                  proposalId={this.state.proposalDetails.id}
                  proposalDateCheckOut={this.state.proposalDetails.dateCheckOut}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </>
    );
  }
}

FormProposalDetails.defaultProps = {
  handleAvailabilityQuery: () => {},
  isAvailabilityQueried: null,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadAndGetProviderDetailsData: id => dispatch(showOrLoadProviderDetailsView(id, false, true)),
      saveProposalDetailsData: (url, data) => dispatch(saveProposalDetailsData(url, data)),
      getDealDetailsData: () => dispatch(getDealDetailsData()),
      printPropertyDetails: (id, data, propertyDetails) =>
        dispatch(printPropertyDetails(id, data, propertyDetails)),
      deleteProposal: (urlProposal, dealId) => dispatch(deleteProposal(urlProposal, dealId)),
      getProviderBaseContactAndChildren: url => dispatch(getProviderBaseContactAndChildren(url)),
      getNewProposalLocation: () => dispatch(getNewProposalLocation()),
      getContractData: proposalId => dispatch(getContractData(proposalId)),
      sendContractData: (proposalId, callback) => dispatch(sendContractData(proposalId, callback)),
      requestConfirmation: (id, callback) => dispatch(requestConfirmation(id, callback)),
      resetContractDataError: () => dispatch(resetContractDataError()),
    },
  };
}

function mapStateToProps(state) {
  return {
    dealDetails: state.DealDetails.data.detail,
    optionLabels: state.Preload.optionLabels,
    proposalDetailsSubmit: state.ProposalDetails.submit,
    providerDetailsData: state.ProviderDetails,
    providerBaseContactAndChildren: state.ProviderDetails.baseContact.detail,
    proposalLocation: state.Proposals.proposalLocation.location,
    contractData: state.ProposalDetails.contractData,
    companyDetails: state.CompanyDetails.data.detail,
    currentUsername: state.Preload.currentUserInformation.username,
  };
}

const AvailabilityButton = ({
  deal,
  property,
  currentUsername,
  onAvailabilityQueried,
  proposal,
}) => {
  const {
    handleCheckAvailabilityByWhatsapp,
    handleCheckAvailabilityByEmail,
  } = useCheckAvailabilityHandler(deal, currentUsername, property);
  const [contactForAvailability, setContactForAvailability] = useState();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const fetchProviderChildren = async () => {
      const children = await ProvidersApi.loadProviderWithChildren(property.providerBack.id);
      const providerContacts = get(children, 'baseContact.contact.children');

      // Determine contact for availability query
      setContactForAvailability(
        getPropertyUsualContact(
          property.usualContactBack,
          property.providerBack.baseContact,
          providerContacts
        )
      );
    };

    if (property.providerBack.id) {
      fetchProviderChildren();
    }
  }, [property]);

  const ACTION_AVAILABILITY_MAIL = 'AVAILABILITY_MAIL';
  const ACTION_AVAILABILITY_WHATSAPP = 'AVAILABILITY_WHATSAPP';
  const ACTION_COPY_LINK = 'COPY_LINK';

  const handleAction = async action => {
    setVisible(false);
    if (
      (action === ACTION_AVAILABILITY_WHATSAPP || action === ACTION_AVAILABILITY_MAIL) &&
      !isEmpty(proposal.rootProposalId)
    ) {
      openNotificationWithIcon(
        'warning',
        'No se ha consultado la disponibilidad',
        'Las propuestas reutilizadas ya tienen disponibilidad consultada en la oportunidad original de la que proviene'
      );
      return;
    }
    if (action === ACTION_AVAILABILITY_WHATSAPP) {
      handleCheckAvailabilityByWhatsapp(contactForAvailability);
      onAvailabilityQueried();
    } else if (action === ACTION_AVAILABILITY_MAIL) {
      handleCheckAvailabilityByEmail(contactForAvailability);
      onAvailabilityQueried();
    } else {
      try {
        const longLink = `${HOMYSPACE_WEB_BASEPATH}disponibilidad/${get(
          property,
          'homyspaceReference'
        )}?opportunityId=${get(deal, 'id')}&propertyId=${get(
          property,
          'id'
        )}&step=available_price&utm_medium=whatsapp`;

        const response = await api.request(
          `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBqwPnorVaDN8Wma4864Ey-Y9PHwEZEaPA`,
          'POST',
          {
            body: JSON.stringify({
              dynamicLinkInfo: {
                domainUriPrefix: 'https://homyspace.page.link',
                link: longLink,
              },
            }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: '',
            },
          }
        );

        await navigator.clipboard.writeText(get(response, 'json.shortLink'));

        openNotificationWithIcon('info', 'Enlace copiado!');
      } catch (error) {
        console.error(error);
        openNotificationWithIcon('error', 'Oops', 'Ha ocurrido alguna movida generando en enlace');
      }
    }
  };

  const popoverContent = (
    <div>
      <div className={'row popup-option'} style={{ paddingTop: 5, paddingBottom: 1 }}>
        <div className='col-sm-12 noPadding'>
          <a className={'popup-option'} onClick={() => handleAction(ACTION_AVAILABILITY_WHATSAPP)}>
            <div className='row'>
              <div className='col-sm-3'>
                <i className='fab fa-whatsapp' style={{ fontSize: '20px' }}></i>
              </div>
              <div className='col-sm-9 noPadding mt-1'>Por whatsapp</div>
            </div>
          </a>
        </div>
      </div>
      <div className={`row popup-option`}>
        <div className='col-sm-12 noPadding'>
          <a className={'popup-option'} onClick={() => handleAction(ACTION_AVAILABILITY_MAIL)}>
            <div className='row'>
              <div className='col-sm-3'>
                <Icon type='mail' style={{ fontSize: '20px' }} />
              </div>
              <div className='col-sm-9 noPadding'>Por Email</div>
            </div>
          </a>
        </div>
      </div>
      <div className={'row popup-option'}>
        <div className='col-sm-12 noPadding'>
          <a className={'popup-option'} onClick={() => handleAction(ACTION_COPY_LINK)}>
            <div className='row'>
              <div className='col-sm-3'>
                <Icon type='copy' style={{ fontSize: '20px' }} />
              </div>
              <div className='col-sm-9 noPadding' style={{ alignSelf: 'center' }}>
                Copiar enlace de consulta
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      placement='right'
      content={popoverContent}
      trigger='click'
      visible={visible}
      onVisibleChange={setVisible}
    >
      <button
        type='button'
        className='btn btn-primary btn-block alignLeft buttonBreakLine'
        onClick={() => setVisible(!visible)}
      >
        Enviar consulta disponibilidad
      </button>
    </Popover>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormProposalDetails);
