import { HOMYSPACE_WEB_BASEPATH } from '../appConfig';
import get from 'lodash/get';
import includes from 'lodash/includes';
import moment from 'moment';
import { monthsBetween, getDays } from '../../shared/utils/dateUtils';
import { DEAL_ENTRY_DATE_TYPE } from '../../shared/utils/appUtils';
import CompanyDetailsApi from '../../modules/CompanyDetails/CompanyDetailsApi';

export const getStageIndex = (stage, dealStages) => {
  let stageIndex = 0;

  if (dealStages && stage) {
    dealStages.forEach((stage, index) => {
      if (stage.value === stage) {
        stageIndex = index;
      }
    });
  }

  return stageIndex;
};

export const getLocalityOrWider = ({ address = {} } = {}) =>
  address.locality ||
  address.postalTown ||
  address.administrativeAreaLevel5 ||
  address.administrativeAreaLevel4 ||
  address.administrativeAreaLevel3 ||
  address.administrativeAreaLevel2 ||
  address.administrativeAreaLevel1 ||
  null;

export const shouldSendListing = async deal => {
  if (get(deal, 'hsagent.username') === 'joseignacio.florido@homyspace.com') {
    return true;
  }

  const targetForPublicListingCities = [
    'Madrid',
    'Barcelona',
    'Valencia',
    'València',
    'Palma',
    'Sevilla',
    'Málaga',
  ];
  const city = getLocalityOrWider(deal);

  if (!includes(targetForPublicListingCities, city)) {
    return false;
  }

  if (deal.firstRequest && monthsBetween(deal.rentalStartDate, deal.rentalEndDate) >= 6) {
    return true;
  }

  if (getDays(deal.creationDate, deal.rentalStartDate) >= 70) {
    return true;
  }

  if (get(deal, 'otherRequestInfo.entryDate') === DEAL_ENTRY_DATE_TYPE.APPROX) {
    return true;
  }

  const qualifyingQuestions =
    (await CompanyDetailsApi.getQualifyingQuestions(deal.companyId)) || {};

  return qualifyingQuestions.frequency === 'PROMPTLY';
};

export const getListingFollowUpUrl = deal =>
  `${HOMYSPACE_WEB_BASEPATH}dossier/inmuebles/${deal.id}?opp=${deal.id}&oppStartDate=${moment(
    deal.rentalStartDate
  ).format('YYYY-MM-DD')}&oppEndDate=${moment(deal.rentalEndDate).format(
    'YYYY-MM-DD'
  )}&utm_source=proposals&utm_medium=whatsapp&utm_campaign=followup&utm_content=prop_f1&city=${getLocalityOrWider(
    deal
  )}`;

export const getListingManualUrl = deal =>
  `${HOMYSPACE_WEB_BASEPATH}dossier/inmuebles/${deal.id}?opp=${deal.id}&oppStartDate=${moment(
    deal.rentalStartDate
  ).format('YYYY-MM-DD')}&oppEndDate=${moment(deal.rentalEndDate).format(
    'YYYY-MM-DD'
  )}&utm_source=proposals&utm_medium=whatsapp&utm_campaign=manual&utm_content=prop_f1&city=${getLocalityOrWider(
    deal
  )}`;

export const dealPreferenceQuestions = [
  { label: '¿Los inquilinos compartirían habitación?', value: 'roomShared' },
  { label: '¿Estarían dispuestos a dormir en un sofá-cama?', value: 'sofaBed' },
  {
    label: '¿Cuál es la preferencia del cliente entre precio y distancia?',
    value: 'closeOrBudget',
  },
];

export const dealPreferenceOptions = [
  { label: 'Sí', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'N/C', value: 'INDIFFERENT' },
];

export const dealCloseOrBudgetOptions = [
  { label: 'Prefiere dentro de presupuesto aunque esté un poco más lejos', value: 'BUDGET' },
  { label: 'Prefiere cerca de la dirección indicada aunque sea un poco más caro', value: 'CLOSE' },
];
