import {
  NEW_CONTACT_LOCATION_ERROR,
  NEW_CONTACT_LOCATION_SUCCESS,
  NEW_PROVIDER_CREATE_ERROR,
  PROVIDERBASECONTACTANDCHILDREN_DATA_ERROR,
  PROVIDERBASECONTACTANDCHILDREN_DATA_SUCCESS,
  PROVIDERCONTACT_ETAG_SUCCESS,
  PROVIDERCONTACT_SUBMIT_ERROR,
  PROVIDERCONTACT_SUBMIT_SUCCESS,
  PROVIDERCONTACTS_DATA_ERROR,
  PROVIDERCONTACTS_DATA_SUCCESS,
  PROVIDERDETAILS_DATA_ERROR,
  PROVIDERDETAILS_DATA_SUCCESS,
  PROVIDERDETAILS_DELETE_ERROR,
  PROVIDERDETAILS_METASOURCE_ERROR,
  PROVIDERDETAILS_METASOURCE_SUCCESS,
  PROVIDERDETAILS_RESET,
  PROVIDERDETAILS_RESET_CREATE_ERROR,
  PROVIDERDETAILS_SUBMIT_ERROR,
  PROVIDERDETAILS_SUBMIT_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  data: {
    url: '',
    etag: '',
    detail: {},
    error: false,
    errorDetail: '',
  },
  contacts: {
    detail: {},
    error: false,
    errorDetail: '',
  },
  baseContact: {
    detail: {},
    error: false,
    errorDetail: '',
  },
  contactsVersion: 0,
  contact: {
    etag: 0,
    error: false,
    errorDetail: '',
  },
  submit: {
    error: false,
    errorDetail: '',
  },
  create: {
    error: false,
    errorDetail: '',
  },
  contactCreate: {
    error: false,
    errorDetail: '',
  },
  contactCreateSuccess: false,
  contactLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
  delete: {
    error: false,
    errorDetail: '',
  },
};

const ProviderDetails = (state = initialState, action) => {
  switch (action.type) {
    case PROVIDERDETAILS_RESET: {
      return Object.assign({}, initialState);
    }

    case PROVIDERDETAILS_METASOURCE_SUCCESS: {
      return Object.assign({}, initialState, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROVIDERDETAILS_METASOURCE_ERROR: {
      return Object.assign({}, initialState, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROVIDERDETAILS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          url: action.url,
          etag: action.etag,
          detail: action.providerDetailsData,
          error: false,
          errorDetail: '',
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROVIDERDETAILS_DATA_ERROR: {
      return Object.assign({}, state, {
        data: {
          url: '',
          etag: '',
          detail: {},
          error: true,
          errorDetail: action.providerDetailsErrorDetails,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROVIDERCONTACTS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        contacts: {
          detail: action.providerContactsData,
          error: false,
          errorDetail: '',
        },
        contactsVersion: state.contactsVersion + 1,
      });
    }

    case PROVIDERCONTACTS_DATA_ERROR: {
      return Object.assign({}, state, {
        contacts: {
          detail: {},
          error: true,
          errorDetail: action.providerContactsErrorDetails,
        },
      });
    }

    case PROVIDERBASECONTACTANDCHILDREN_DATA_SUCCESS: {
      return Object.assign({}, state, {
        baseContact: {
          detail: action.providerBaseContactAndChildrenData,
          error: false,
          errorDetail: '',
        },
        contactsVersion: state.contactsVersion + 1,
      });
    }

    case PROVIDERBASECONTACTANDCHILDREN_DATA_ERROR: {
      return Object.assign({}, state, {
        baseContact: {
          detail: {},
          error: true,
          errorDetail: action.providerBaseContactAndChildrenErrorDetails,
        },
      });
    }

    case PROVIDERDETAILS_SUBMIT_SUCCESS: {
      const data = Object.assign({}, state.data, {
        etag: action.etag,
      });

      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
        },
        data: data,
      });
    }

    case PROVIDERDETAILS_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.providerDetailsSubmitErrorDetails,
        },
      });
    }

    case PROVIDERCONTACT_ETAG_SUCCESS: {
      return Object.assign({}, state, {
        contact: {
          etag: action.etag,
        },
      });
    }

    case PROVIDERCONTACT_SUBMIT_SUCCESS: {
      return Object.assign({}, state, {
        contact: {
          etag: action.etag,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROVIDERCONTACT_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        contact: {
          etag: 0,
          error: true,
          errorDetail: action.providerContactSubmitErrorDetails,
        },
      });
    }

    case NEW_PROVIDER_CREATE_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: true,
          errorDetail: action.createNewProviderErrorDetails,
        },
      });
    }

    case NEW_CONTACT_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        contactLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
        contactCreateSuccess: false,
      });
    }

    case NEW_CONTACT_LOCATION_ERROR: {
      return Object.assign({}, state, {
        contactLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
        contactCreateSuccess: false,
      });
    }

    case PROVIDERDETAILS_DELETE_ERROR: {
      return Object.assign({}, state, {
        delete: {
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROVIDERDETAILS_RESET_CREATE_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    default:
      return state;
  }
};

export default ProviderDetails;
