import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Modal, Row, Typography } from 'antd';
import TableDataContacts from '../../components/TableDataContacts';
import {
  filterTableDataContacts,
  getContactsTableData,
  getNewContactLocation,
} from '../../modules/Contacts/ContactsActions';
import {
  getOptionsForSelect,
  openNotificationWithIcon,
  parseStringValuesToComboValue,
  resolveParams,
} from '../../shared/utils/appUtils';
import api from '../../shared/utils/api';
import ContactsFilter from './ContactsFilter';
import tableUtils from '../../shared/utils/tableUtils';
import CreateNewContact from './CreateNewContact';
import split from 'lodash/split';
import last from 'lodash/last';
import { browserHistory } from 'react-router';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

const { Title } = Typography;

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: { data: [] },
      filter: { ...props.filter },
      modal: false,
      sorter: '',
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      loading: true,
      contactLocation: props.contactLocation,
      createContactInfo: {
        contactType: '',
        name: '',
        lastOTradeName: '',
        email: '',
        email2: '',
        phoneNumber: '',
        mobilePhoneNumber: '',
        creationDate: new Date(),
        fullParentContact: {},
        parent: '',
        showMoreParentFields: false,
      },
      options: {
        contactType: getOptionsForSelect(this.props.optionLabels, 'ContactType'),
        contactRole: getOptionsForSelect(this.props.optionLabels, 'ContactRole'),
      },
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangeOnFilterConditions = this.handleChangeOnFilterConditions.bind(this);
    this.handleChangeFilterCombo = this.handleChangeFilterCombo.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.loadTableData = this.loadTableData.bind(this);
    this.handleFilterCollapse = this.handleFilterCollapse.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChangeNewContact = this.handleChangeNewContact.bind(this);
    this.handleSelectParentChange = this.handleSelectParentChange.bind(this);
    this.handleSelectChangeNewContact = this.handleSelectChangeNewContact.bind(this);
    this.createNewContact = this.createNewContact.bind(this);
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.contactLocation !== newProps.contactLocation) {
      this.setState(
        {
          contactLocation: newProps.contactLocation,
        },
        () => this.toggle()
      );
    }
  }

  handlePaginationChange = (page, pageSize) => {
    const { pagination } = this.state;

    this.setState(
      {
        loading: true,
        pagination: {
          ...pagination,
          pageSize,
          page,
        },
      },
      () => this.loadTableData()
    );
  };

  handleFilterCollapse() {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        showMoreFilters: !prevState.filter.showMoreFilters,
      },
    }));
  }

  handleSelectChange(name, selectedOption, mode) {
    const { filter } = this.state;

    const options = parseStringValuesToComboValue(selectedOption, this.state.options[name], mode);

    this.setState({ filter: { ...filter, [name]: options || null } });
  }

  handleSelectChangeNewContact(name, selectedOption, mode = 'default') {
    const { createContactInfo } = this.state;

    const options = parseStringValuesToComboValue(selectedOption, this.state.options[name], mode);

    this.setState({
      createContactInfo: { ...createContactInfo, [name]: options || null },
    });
  }

  loadTableData() {
    const { pagination, sorter } = this.state;
    const filters = this.filterData();

    const params = {
      pagination,
      filters,
      sorter,
    };

    api
      .getTableData('contact', params)
      .then(res => {
        this.setState({
          loading: false,
          table: {
            data: res.json.contactList,
          },
          pagination: {
            ...pagination,
            totalCount: parseInt(res.headers.get('x-total-count'), 10) || 0,
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de procesar datos en Contactos... ${err.message}`
        );
      });
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handleChangeOnFilterConditions(e) {
    const { filter } = this.state;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    filter[e.target.name] = value;
    this.setState({ filter });
  }

  handleChangeFilterCombo(selectedOption) {
    const { filter } = this.state;

    filter.contactType = selectedOption ? selectedOption.value : null;
    this.setState({ filter });
  }

  handleFilter = e => {
    e.preventDefault();

    const { pagination } = this.state;

    this.setState(
      {
        loading: true,
        pagination: {
          ...pagination,
          page: 1,
        },
      },
      () => {
        this.props.actions.filterTableDataContacts(this.state.filter);
        this.loadTableData();
      }
    );
  };

  filterData() {
    const { filter } = this.state;

    filter.baseOrNot = 'nobase';

    return filter;
  }

  sorterData = sorter => {
    const condition = tableUtils.getSortCondition(sorter, 'contact');

    this.setState(
      {
        sorter: condition,
        loading: true,
      },
      () => this.loadTableData()
    );
  };

  showBlacklistedNotification = () => {
    openNotificationWithIcon(
      'warning',
      <span role='img' area-label='eyes'>
        &#128123; ¡Huye! &#128123;
      </span>,
      `¡Este Cliente se encuentra en la lista negra de Captación!`
    );
  };

  handleChangeNewContact = e => {
    const { name, value } = e.target;

    this.setState(prevState => ({
      createContactInfo: {
        ...prevState.createContactInfo,
        [name]: value,
      },
    }));
  };

  handleSelectParentChange(name, selectedOption, obj) {
    const { createContactInfo } = this.state;
    const relatedCompany = get(obj, 'relatedCompany');
    const relatedProvider = get(obj, 'relatedProvider');

    let showContactRoleField = false;

    if (!isNil(relatedCompany) || !isNil(relatedProvider)) {
      showContactRoleField = true;
    }

    this.setState({
      createContactInfo: {
        ...createContactInfo,
        fullParentContact: obj,
        parent: get(obj, 'id', null),
        showMoreParentFields: showContactRoleField,
      },
    });
  }

  async createNewContact() {
    let { createContactInfo } = this.state;
    const parentContactId = get(createContactInfo, 'parent');
    const parentContact = get(createContactInfo, 'fullParentContact');
    const roles = get(createContactInfo, 'contactRole');

    try {
      const generateId = await api.generateId('contact');
      const location = resolveParams(generateId.headers.get('location'));

      let id = last(split(location, '/'));

      if (parentContactId) {
        id += `?parent=${parentContactId}`;
      }

      if (parentContact) {
        if (parentContact.relatedProvider) {
          createContactInfo = {
            ...createContactInfo,
            relatedProvider: {
              idOfContact: parentContact.relatedProvider.idOfContact,
              roles,
            },
          };
        } else if (parentContact.relatedCompany) {
          createContactInfo = {
            ...createContactInfo,
            relatedCompany: {
              idOfContact: parentContact.relatedCompany.idOfContact,
              roles,
            },
          };
        } else {
          createContactInfo.relatedCompany = null;
        }
      }

      const creationResponse = await api.save('contact', id, createContactInfo);

      if (get(creationResponse, 'json.contact.id')) {
        openNotificationWithIcon('success', '¡Contacto creado con éxito!');
        browserHistory.push(`contactDetails/${creationResponse.json.contact.id}`);
      }
    } catch (e) {
      openNotificationWithIcon('error', 'Oops! 😞', `${e.message}`);
      console.error('Error a la hora de crear un contacto', e);
    }
  }

  render() {
    const { metasource } = this.props;
    const { modal, createContactInfo } = this.state;

    return (
      <div className='animated fadeIn contacts'>
        {metasource.error && (
          <Alert
            message='Error'
            description={metasource.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
        <Row align='middle' gutter={8}>
          <Col xxl={22} xl={18} lg={18} md={18} sm={12} xs={24}>
            <Title level={2}>
              Contactos
              {/* <img src='img/xmas/angel.png' alt='santa-claus' height={50} /> */}
            </Title>
          </Col>
          <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
            <Button
              className='btn-primary'
              style={{ width: '100%' }}
              // onClick={() => actions.getNewContactLocation()}
              onClick={() => this.setState({ modal: true })}
            >
              Nuevo contacto
            </Button>
            <Modal
              title='Nuevo contacto'
              visible={modal}
              onCancel={() => this.setState({ modal: false })}
              footer={null}
            >
              <CreateNewContact
                handleSubmitNewContact={this.createNewContact}
                handleSelectChange={this.handleSelectChangeNewContact}
                handleChangeNewContact={this.handleChangeNewContact}
                handleSelectParentChange={this.handleSelectParentChange}
                contactInfo={createContactInfo}
                options={this.state.options}
              />
            </Modal>
          </Col>
        </Row>
        <Row className='row-filter-contacts'>
          <Col span={24}>
            <ContactsFilter
              handleFilter={this.handleFilter}
              loading={this.state.loading}
              filter={this.state.filter}
              handleChangeOnFilterConditions={this.handleChangeOnFilterConditions}
              options={this.state.options}
              handleChangeFilterCombo={this.handleChangeFilterCombo}
              handleSelectChange={this.handleSelectChange}
            />
          </Col>
        </Row>
        <Row className='row-table-contacts'>
          <Col span={24}>
            <TableDataContacts
              data={this.state.table.data}
              loading={this.state.loading}
              options={this.state.options}
              pagination={this.state.pagination}
              handleSorter={this.sorterData}
              handlePaginationChange={this.handlePaginationChange}
              handlePaginationSizeChange={this.handlePaginationChange}
              showBlacklistedNotification={this.showBlacklistedNotification}
            />
          </Col>
        </Row>
        <style jsx>
          {`
            .contacts {
              margin: 10px;
            }

            .title-contacts {
              margin: 0;
            }

            .row-filter-contacts {
              margin-top: 10px;
            }

            .row-table-contacts {
              margin-top: 5px;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.Contacts.metasource,
    filter: state.Contacts.filter,
    contactLocation: state.Contacts.contactLocation.location,
    optionLabels: state.Preload.optionLabels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      filterTableDataContacts: filterCriteria => dispatch(filterTableDataContacts(filterCriteria)),
      getNewContactLocation: () => dispatch(getNewContactLocation()),
      getContactsTableData: url => dispatch(getContactsTableData(url, false)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
