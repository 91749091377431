/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import InputAntdValidator from './InputAntdValidator';

class FormInput extends Component {
  static propTypes = {
    fieldDecorator: PropTypes.string.isRequired,
    form: PropTypes.shape({}).isRequired,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isRequiredMessage: PropTypes.string,
    isWrongMessage: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    validator: PropTypes.func,
    initialValue: PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
    isRequired: false,
    isRequiredMessage: 'Por favor, introduce los datos necesarios',
    isWrongMessage: 'Introduce los datos necesarios correctamente',
    label: '',
    onChange: null,
    placeholder: '',
    prefix: '',
    validator: null,
    initialValue: '',
  };

  render() {
    const {
      fieldDecorator,
      form,
      isDisabled,
      isRequired,
      isRequiredMessage,
      isWrongMessage,
      label,
      name,
      onChange,
      placeholder,
      prefix,
      validator,
      initialValue,
    } = this.props;

    return (
      <InputAntdValidator
        fieldDecorator={fieldDecorator}
        form={form}
        isRequired={isRequired}
        isRequiredMessage={isRequiredMessage}
        isWrongMessage={isWrongMessage}
        label={label}
        validator={validator}
        initialValue={initialValue}
      >
        <Input
          disabled={isDisabled}
          label=''
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          prefix={prefix}
        />
      </InputAntdValidator>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    return {
      [props.name]: Form.createFormField({
        ...props,
      }),
    };
  },
})(FormInput);
