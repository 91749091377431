import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Col, Row, Steps, Alert } from 'antd';
import PropertyDetailsImages from '../../views/PropertyDetails/PropertyDetailsImages';
import GenerateProposalDefaultInfo from './GenerateProposalDefaultInfo';
import get from 'lodash/get';
import ProposalTaxes from '../ProposalPrices/ProposalTaxes';
import GenerateProposalExtraInfo from './GenerateProposalExtraInfo';
import FormNewProposal from '../FormNewProposal/FormNewProposal';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import FormProviderInitialPrice from '../FormProviderInitialPrice/FormProviderInitialPrice';
import FormProviderCommission from '../FormProviderCommission/FormProviderCommission';
import { areValidDates } from '../../shared/utils/dateUtils';
import { COMMISSION_TO_PROVIDER_DATE_THRESHOLD, ENDPOINT_V2 } from '../../shared/appConfig';
import isEmpty from 'lodash/isEmpty';
import api from '../../shared/utils/api';
import { PROPOSAL_PAYMENT_TYPES } from '../../shared/utils/priceUtils';
import { isNumber } from 'lodash';
import FormDossierClientPrices from '../FormDossierClientPrices/FormDossierClientPrices';

const DEFAULT_AMOUNT = { amount: 0, currency: 'EUR' };

const { Step } = Steps;

const useStep = () => {
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState('process');

  const next = data => {
    if (current === 0) {
      if (!data.dateCheckIn) {
        openNotificationWithIcon('warning', '¡Falta la fecha de entrada!');
        return false;
      }

      if (!data.dateCheckOut) {
        openNotificationWithIcon('warning', '¡Falta la fecha de salida!');
        return false;
      }

      if (!areValidDates(data.dateCheckIn, data.dateCheckOut)) {
        openNotificationWithIcon(
          'error',
          'Pero illo...',
          'Has introducido mal las fechas. Por favor, revísalo.'
        );

        setStatus('error');

        return false;
      }

      if (!data.proposalCreationReason && data.rentalRequestType === 'A') {
        openNotificationWithIcon('warning', 'Falta el motivo de la propuesta!');
        return false;
      }
    }

    setCurrent(current + 1);
    setStatus('process');
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return {
    current,
    status,
    next,
    prev,
  };
};

const usePropertyData = values => {
  const [data, setData] = useState({
    monthlyPrice: DEFAULT_AMOUNT,
    totalPrice: DEFAULT_AMOUNT,
    monthlyPriceDossier: DEFAULT_AMOUNT,
    totalPriceDossier: DEFAULT_AMOUNT,
    providerRate: DEFAULT_AMOUNT,
    providerRateTaxType: 'IVA',
    providerRateTaxPercentage: 0,
    providerRateTaxValue: DEFAULT_AMOUNT,
    providerCommissionWithTax: DEFAULT_AMOUNT,
    monthlyPriceWithTax: DEFAULT_AMOUNT,
    monthlyPriceTaxValue: DEFAULT_AMOUNT,
    totalPriceWithTax: DEFAULT_AMOUNT,
    totalPriceTaxValue: DEFAULT_AMOUNT,
    monthlyPriceWithTaxDossier: DEFAULT_AMOUNT,
    totalPriceWithTaxDossier: DEFAULT_AMOUNT,
    cleaningChargesWithTax: DEFAULT_AMOUNT,
    cleaningChargesTaxType: 'IVA',
    cleaningChargesTaxPercentage: 0,
    cleaningChargesTaxValue: DEFAULT_AMOUNT,
    paymentType: PROPOSAL_PAYMENT_TYPES.ONE_TIME_PAYMENT,
    customerRentPayment: DEFAULT_AMOUNT,
    amountToTransferToProvider: DEFAULT_AMOUNT,
    deposit: DEFAULT_AMOUNT,
    ...values,
    tenants: get(values, 'numberOfPeople') || get(values, 'tenants'),
  });

  const calculatePrice = async (values, changedField) => {
    if (!changedField) {
      console.error('No changedField provided to calculatePrice', changedField);
      return;
    }

    let priceField = changedField;
    if (priceField === 'providerAverageMonthlyPrice' && !values.taxIncluded) {
      priceField = 'providerAverageMonthlyPrice';
    } else if (priceField === 'providerAverageMontlyPriceTaxIncluded' && values.taxIncluded) {
      priceField = 'providerAverageMontlyPriceTaxIncluded';
    } else if (
      values.taxIncluded &&
      changedField !== 'monthlyPriceWithTax' &&
      changedField !== 'totalPriceWithTax'
    ) {
      priceField = 'monthlyPriceWithTax';
    } else if (
      (values.plusTax && changedField !== 'monthlyPrice' && changedField !== 'totalPrice') ||
      changedField === 'taxExempt'
    ) {
      priceField = 'monthlyPrice';
    } else if (
      values.taxExempt &&
      changedField !== 'monthlyPrice' &&
      changedField !== 'totalPrice'
    ) {
      priceField = 'monthlyPrice';
    }

    const endpointsByPriceField = {
      monthlyPrice: '',
      monthlyPriceWithTax: 'withTaxIncluded',
      totalPrice: 'fromTotalPrice',
      totalPriceWithTax: 'fromTotalPriceWithTaxIncluded',
      providerAverageMonthlyPrice: 'fromProviderMonthlyPrice',
      providerAverageMontlyPriceTaxIncluded: 'fromProviderMonthlyPriceTaxIncluded',
    };

    let priceEndpoint = endpointsByPriceField[priceField];

    if (!values.dateCheckIn || !values.dateCheckOut || !values.address) {
      return;
    }

    let price = {
      amount: 0,
      currency: 'EUR',
    };
    if (isNumber(values[priceField].amount)) {
      // It means priceField is not empty
      price = values[priceField];
    }

    const payload = {
      dateCheckIn: new Date(values.dateCheckIn).toISOString(),
      dateCheckOut: new Date(values.dateCheckOut).toISOString(),
      price,
      commissionPercentage: values.commissionPercentage,
      address: values.address,
      taxValue: values.taxValue,
      cleaningCharges: isNumber(get(values, 'cleaningCharges.amount'))
        ? values.cleaningCharges
        : DEFAULT_AMOUNT,
      cleaningChargeType: values.cleaningChargeType,
      deposit: values.deposit && isNumber(values.deposit.amount) ? values.deposit : DEFAULT_AMOUNT,
      opportunityId: get(values, 'dealId', ''),
    };

    try {
      const response = await api.request(
        `${ENDPOINT_V2}/pricing/proposals${isEmpty(priceEndpoint) ? '' : `/${priceEndpoint}`}`,
        'post',
        {
          body: JSON.stringify(payload),
        }
      );

      if (!isEmpty(response.json)) {
        setData(prevData => ({ ...prevData, ...response.json }));
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', 'Ooops', 'Ha ocurrido un problema al calcular los precios');
    }
  };

  useEffect(() => {
    calculatePrice(data);
  }, []);

  // For make imageValidation in FormNewProposals if imagesSelected of the proposals were modified
  const [selectedImagesChanged, setSelectedImagesChanged] = useState(false);

  const onChange = useCallback(
    async e => {
      const value = get(e, 'target.value', get(e, 'target.checked'));
      const name = get(e, 'target.name', '');
      const type = get(e, 'target.type');

      if (name === 'chargesIncluded') {
        data.spendingLimit = null;
      }

      if (name === 'cleaningChargeType' && data.taxType === 'IVA' && value && value !== 'FINAL') {
        setData(obj => ({ ...obj, taxValue: 10 }));
      }

      let taxInfo = {};

      if (type === 'checkbox') {
        switch (name) {
          case 'plusTax': {
            taxInfo = {
              typeOfTax: 'plusTax',
              taxIncluded: false,
              taxExempt: false,
            };
            break;
          }

          case 'taxIncluded': {
            taxInfo = {
              typeOfTax: 'plusTax',
              plusTax: false,
              taxExempt: false,
            };
            break;
          }

          case 'taxExempt': {
            taxInfo = {
              typeOfTax: 'taxExempt',
              plusTax: false,
              taxIncluded: false,
              taxValue: '',
            };
            break;
          }

          case 'chargesIncluded': {
            taxInfo.spendingLimit = null;
            break;
          }

          default: {
            break;
          }
        }
      }

      const newData = { ...data, ...taxInfo, [name]: value };

      setData(newData);
      await calculatePrice(newData, name);
    },
    [data]
  );

  const onPriceChange = async (field, val) => {
    const newData = {
      ...data,
      [field]: val,
    };

    setData(newData);
    await calculatePrice(newData, field);
  };

  useEffect(() => {
    if (!data.taxIncluded && !data.taxExempt && !data.plusTax) {
      setData(obj => ({ ...obj, plusTax: true, typeOfTax: 'plusTax' }));
    }
  }, [data.plusTax, data.taxExempt, data.taxIncluded]);

  const onFeaturedPictureChange = featuredPicturePath => {
    setData(obj => ({ ...obj, featuredPicturePath }));
  };

  const onPicturesChange = pictures => {
    setSelectedImagesChanged(true);
    setData(obj => ({ ...obj, pictures }));
  };

  return {
    data,
    onChange,
    onPriceChange,
    selectedImagesChanged,
    onFeaturedPictureChange,
    onPicturesChange,
  };
};

const GenerateProposalInfo = ({ ...props }) => {
  const { next, prev, current, status } = useStep();
  const [isOpenCleaningChargeTypeModal, showCleaningChargeTypeModal] = useState(false);

  const {
    data,
    onChange,
    onPriceChange,
    selectedImagesChanged,
    onFeaturedPictureChange,
    onPicturesChange,
  } = usePropertyData({ ...props.bondedDeal, ...props.data, dealId: get(props, 'bondedDeal.id') });

  useEffect(() => {
    showCleaningChargeTypeModal(data.cleaningChargeType && data.cleaningChargeType !== 'FINAL');
  }, [data.cleaningChargeType]);

  const proposalToBeSubmitted = useMemo(() => {
    return {
      ...data,
      plusTax: get(data, 'plusTax', false),
      taxIncluded: get(data, 'taxIncluded', false),
      taxExempt: get(data, 'taxExempt', false),
      price: get(data, 'monthlyPrice'),
      monthlyPrice: data.paymentType !== PROPOSAL_PAYMENT_TYPES.ONE_TIME_PAYMENT,
      property: {
        id: get(data, 'id'),
      },
      contactsOfProvider: [{ id: get(data, 'contactOfProvider') }],
    };
  }, [current]);

  const commissionToProvider = useMemo(
    () =>
      props.bondedDeal && props.bondedDeal.creationDate >= COMMISSION_TO_PROVIDER_DATE_THRESHOLD,
    [props.bondedDeal]
  );

  const steps = [
    {
      title: 'Datos de estancia',
      content: (
        <GenerateProposalDefaultInfo
          data={data}
          options={props.options}
          bondedDeal={props.bondedDeal}
          handleChange={onChange}
        />
      ),
    },
    {
      title: 'Precios',
      content: (
        <>
          <ProposalTaxes data={data} options={props.options} handleChange={onChange} />
          <Row>
            {commissionToProvider && <h6>Precios para el Cliente</h6>}
            {!commissionToProvider && <h6>Precios iniciales del proveedor</h6>}
          </Row>
          <hr className='hs-hr' />
          <Row>
            <FormProviderInitialPrice data={data} onChange={onPriceChange} />
          </Row>
          <Row>
            {commissionToProvider && <h6>Precios para el Proveedor</h6>}
            {!commissionToProvider && <h6>Comisión al Proveedor</h6>}
          </Row>
          <hr className='hs-hr' />
          <Row>
            <FormProviderCommission
              data={data}
              dealDetails={props.bondedDeal}
              // onChange={onPriceChange.bind(this, 'commissionPercentage')}
              onChange={onPriceChange}
            />
          </Row>
          {!commissionToProvider && (
            <FormDossierClientPrices
              data={data}
              onChangeTotalPrice={onPriceChange.bind(this, 'showTotalPrice')}
            />
          )}
        </>
      ),
    },
    {
      title: 'Información adicional',
      content: (
        <>
          <GenerateProposalExtraInfo data={data} handleChange={onChange} />
          <PropertyDetailsImages
            data={data}
            images={props.images}
            onFeaturedPictureChange={onFeaturedPictureChange}
            onPicturesChange={onPicturesChange}
          />
        </>
      ),
    },
    {
      title: 'Datos Proveedor',
      content: (
        <FormNewProposal
          proposal={proposalToBeSubmitted}
          bondedDeal={props.bondedDeal}
          preselectedContactId={get(data, 'usualContactId')}
          prev={() => prev()}
          selectedImagesChanged={selectedImagesChanged}
          initialSelectedImagesLength={props.initialSelectedImagesLength}
        />
      ),
    },
  ];

  return (
    <>
      {isOpenCleaningChargeTypeModal && (
        <Row className='hs-row'>
          <Alert
            message='Si cambias la periodicidad a Puntual / Quincenal / Semanal / Diaria , el IVA aplicable cambiará a: 10%'
            type='warning'
          />
        </Row>
      )}
      <Steps size='small' current={current} className='hs-steps-proposal' status={status}>
        {steps.map(item => (
          <Step key={item.title} description={item.title} />
        ))}
      </Steps>
      <div className='steps-content'>{steps[current].content}</div>
      <div className='steps-action'>
        <Row type='flex' justify='end' gutter={4}>
          {current > 0 && current < steps.length - 1 && (
            <Col>
              <Button style={{ marginLeft: 8 }} onClick={() => prev()}>
                Anterior
              </Button>
            </Col>
          )}
          {current < steps.length - 1 && (
            <Col>
              <Button type='primary' onClick={() => next(data)}>
                Siguiente
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <style jsx>
        {`
          .steps-content {
            margin-bottom: 15px;
          }

          .hs-steps-proposal {
            margin-bottom: 15px;
          }

          .width100 {
            width: 0 !important;
          }

          .printableDataBox {
            float: initial;
          }

          .ant-steps-item-title {
            margin: 3px -10px 0 0 !important;
            display: flex !important;
          }

          .ant-steps-item-process .ant-steps-item-icon {
            background: #eeaf30;
            border-color: #eeaf30;
          }
        `}
      </style>
    </>
  );
};

// GenerateProposalInfo.propTypes = {
//   propertyData: PropTypes. ,
//   printableData: PropTypes. ,
//   options: PropTypes. ,
//   bondedDeal: PropTypes. ,
// };

// GenerateProposalInfo.defaultProps = {
//   propertyData: {},
//   printableData: {},
//   options: {},
//   bondedDeal: {}
// };

export default GenerateProposalInfo;
