import React, { Component } from 'react';
import { Alert, Button, Form, Row } from 'antd';

// PARAMETROS A CONFIGURAR
// callbackFuncionOnCreate -> función a llamar cuando se ha creado un nuevo contacto

class FormCloneProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numCopies: 0,
      submitting: false,
      invalidInput: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeNumCopies = this.handleChangeNumCopies.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    const { numCopies } = this.state;

    if (numCopies > 50) {
      this.setState({
        submitting: false,
        invalidInput: true,
      });
    } else {
      this.setState(
        {
          submitting: true,
          invalidInput: false,
        },
        () => this.props.callbackFuncionOnCreate(numCopies)
      );
    }
  };

  handleChangeNumCopies = e => {
    if (e.target.value != null && e.target.value !== '') {
      this.setState({
        numCopies: e.target.value,
      });
    }
  };

  render() {
    const { invalidInput, numCopies, submitting } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        {invalidInput && (
          <Alert
            message='Error: no puede clonar más de 50 inmuebles'
            type='error'
            showIcon
            closable
          />
        )}
        <Row>
          <label htmlFor='numCopies' id='numberOfCopies'>
            Número de copias
            <input
              type='number'
              className='form-control'
              id='numCopies'
              name='numCopies'
              placeholder='0'
              value={numCopies}
              onChange={this.handleChangeNumCopies}
              min='0'
              max='50'
            />
          </label>
        </Row>
        <br />
        <Row>
          <Button
            htmlType='submit'
            className='btn-primary'
            style={{ float: 'right' }}
            loading={submitting}
          >
            Guardar
          </Button>
        </Row>
      </Form>
    );
  }
}

export default FormCloneProperty;
