import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import HomySelect from '../../components/HomyComponents/HomySelect';

const { Item: FormItem } = Form;

const ContactsFilter = props => (
  <Form id='formFilterContacts' onSubmit={props.handleFilter} layout='vertical'>
    <Row gutter={8} type='flex' justify='start' align='bottom'>
      <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
        <FormItem label='Nombre'>
          <Input
            id='filterName'
            name='name'
            placeholder='Federico'
            value={props.filter.name}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
        <FormItem label='Apellidos / Razón social'>
          <Input
            id='filterLastOTradeName'
            name='lastOTradeName'
            placeholder='Jiménez'
            value={props.filter.lastOTradeName}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} sm={12} xs={24}>
        <FormItem label='Cargo'>
          <Input
            id='filterJobTitle'
            name='jobTitle'
            placeholder='Redactor jefe'
            value={props.filter.jobTitle}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
        <FormItem label='Email'>
          <Input
            id='filterEmail'
            name='email'
            placeholder='federico@hotmail.com'
            value={props.filter.email}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} sm={12} xs={24}>
        <FormItem label='NIF'>
          <Input
            id='filterIdentificationCode'
            name='identificationCode'
            placeholder='11133305A'
            value={props.filter.identificationCode}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} sm={12} xs={24}>
        <FormItem label='Teléfono'>
          <Input
            id='filterPhoneNumber'
            name='phoneNumber'
            placeholder='960852963'
            value={props.filter.phoneNumber}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} sm={12} xs={24}>
        <FormItem label='Tipo de contacto'>
          <HomySelect
            name='contactType'
            placeholder='Física o Jurídica'
            value={props.filter.contactType || ''}
            options={props.options.contactType}
            onChange={value => {
              props.handleSelectChange('contactType', value, 'default');
            }}
            showArrow
            showSearch
            allowClear
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} sm={12} xs={24}>
        <FormItem>
          <Button className='btn-primary filter-buttons' htmlType='submit' loading={props.loading}>
            Filtrar
          </Button>
        </FormItem>
      </Col>
    </Row>
    <style jsx>
      {`
        .filter-buttons {
          width: 100% !important;
        }
      `}
    </style>
  </Form>
);
export default ContactsFilter;
