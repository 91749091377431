import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Table } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../../shared/appConfig';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import HomyPagination from '../HomyComponents/HomyPagination';
import tableUtils from '../../shared/utils/tableUtils';
import { browserHistory } from 'react-router';

class TableDataProviders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
    this.showProviderDetail = this.showProviderDetail.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.data) !== JSON.stringify(newProps.data)) {
      this.setState({
        data: newProps.data,
      });
    }
  }

  showProviderDetail(providerId, isBlacklisted) {
    if (isBlacklisted) {
      this.showBlacklistedNotification();
    } else {
      browserHistory.push(`/providerDetails/${providerId}`);
    }
  }

  showBlacklistedNotification = () => {
    openNotificationWithIcon(
      'warning',
      <span role='img' aria-label='eyes'>
        ¡Cuidadito! &#128064;
      </span>,
      `¡Este Proveedor se encuentra en la lista negra de Captación!`
    );
  };

  render() {
    const { providersData } = this.props;

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        sorter: true,
        width: 300,
        render: (text, record) => <a href={`/providerDetails/${record.id}`}>{text}</a>,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showProviderDetail(record.id, record.disabledDate);
        },
      },
      {
        title: 'Apellidos / Razón social',
        dataIndex: 'lastOTradeName',
        sorter: true,
        width: 300,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showProviderDetail(record.provider.id, record.provider.disabledDate);
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
        width: 250,
        render: val => <a href={`mailto:pruebas@homyspace.com`}>{val}</a>,
      },
      {
        title: 'NIF',
        dataIndex: 'identificationCode',
        sorter: true,
        width: 150,
      },
      {
        title: 'Teléfono',
        dataIndex: 'phoneNumber',
        sorter: true,
        width: 150,
        render: val => <a href={`tel:${val}`}>{val}</a>,
      },
      {
        title: 'Ciudad',
        dataIndex: 'locality',
        sorter: true,
        width: 200,
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'creationDate',
        sorter: true,
        width: 150,
        render: val => moment(val).format(DATE_FORMAT),
      },
      {
        title: 'Hace factura',
        dataIndex: 'billable',
        sorter: false,
        render: val => (val ? <i className='fa fa-check fa-lg' /> : ''),
      },
    ];

    const scrollX = tableUtils.getTableWidth();
    const scrollY = tableUtils.getTableHeight() + 20;

    return (
      <div className={`animated fadeIn providerBorderTopColor`}>
        {providersData && providersData.error && (
          <Alert
            message='Error'
            description={providersData.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
        <Table
          size='middle'
          className='fixed-width-table'
          pagination={false}
          dataSource={this.state.data}
          columns={columns}
          rowKey={rec => rec.id}
          onChange={(pagination, filter, sorter) => this.props.handleSorter(sorter)}
          scroll={{ x: scrollX, y: scrollY }}
          loading={this.props.loading}
          footer={() => (
            <HomyPagination
              showSizeChanger
              showQuickJumper
              current={this.props.pagination.page}
              pageSize={this.props.pagination.pageSize}
              totalCount={this.props.pagination.totalCount}
              onShowSizeChange={this.props.handlePaginationSizeChange}
              onChange={this.props.handlePaginationChange}
            />
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    providersData: state.Providers.providersData,
  };
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(TableDataProviders);
