import React from 'react';
import { hashHistory, IndexRoute, Route, Router } from 'react-router';
// Containers
import Full from './containers/Full';
import PrintablePage from './containers/PrintablePage';
// import PublicDossier from './containers/PublicDossier';
// Views
import Login from './views/Login';
import Preload from './views/Preload';
import Dashboard from './views/Dashboard';
import Providers from './views/Providers';
import ProviderDetails from './views/ProviderDetails';
import Properties from './views/Properties';
import PropertyDetails from './views/PropertyDetails';
import Companies from './views/Companies';
import CompanyDetails from './views/CompanyDetails';
import PrintablePropertyDetails from './printables/PropertyDetails';
import Contacts from './views/Contacts';
import ContactDetails from './views/ContactDetails';
import Deals from './views/Deals';
import Requests from './views/Requests';
import DealDetails from './views/DealDetails';
import Invoices from './views/Invoices';
import InvoiceDetails from './views/InvoiceDetails';
import Pmas from './views/Pmas';
import SupplyDemand from './views/SupplyDemand';
import FollowUps from './views/FollowUps';
import FormDealClientInfo from './components/FormDealClientInfo/FormDealClientInfo';
import FormProposalProviderInfo from './components/FormProposalProviderInfo/FormProposalProviderInfo';
import GenericThankYouPage from './components/GenericThankYouPage/GenericThankYouPage';
import ProposalConfirmationThankYouPage from './components/ProposalConfirmationThankYouPage';
import CreateOpportunity from './views/Deals/CreateOpportunity/CreateOpportunity';

export default (
  <Router history={hashHistory}>
    <Route path='/login' name='Login' component={Login} />
    <Route path='/preload' name='Preload' component={Preload} onEnter={requireAuth} />

    <Route path='/' name='Inicio' component={Full} onEnter={requireAuth}>
      <IndexRoute component={Dashboard} />
      <Route path='providers' name='Proveedores' component={Providers} />
      <Route path='providerDetails/:id' name='Proveedor' component={ProviderDetails} />

      <Route path='properties' name='Inmuebles' component={Properties} />
      <Route path='propertyDetails/:id' name='Inmueble' component={PropertyDetails} />

      <Route path='companies' name='Empresas' component={Companies} />
      <Route path='companyDetails/:id' name='Empresa' component={CompanyDetails} />
      <Route path='contacts' name='Contactos' component={Contacts} />
      <Route path='contactDetails/:id' name='Contacto' component={ContactDetails} />
      <Route path='requests' name='Solicitudes' component={Requests} />
      <Route path='invoices' name='Facturas' component={Invoices} />

      <Route path='deals' name='Negocios' component={Deals} />
      <Route path='deals/new' name='Nuevo Negocio' component={CreateOpportunity} />
      <Route path='deals/newfromrequest' name='Nuevo Negocio' component={CreateOpportunity} />
      <Route path='dealDetails/:id' name='Negocio' component={DealDetails} />

      <Route path='invoiceDetails/:id' name='Factura' component={InvoiceDetails} />

      <Route path='pmas' name='PMA' component={Pmas} />
      <Route path='supplydemand' name='Oferta/Demanda' component={SupplyDemand} />
      <Route path='followups' name='Seguimientos' component={FollowUps} />
    </Route>
    <Route path='/printable' name='Doc' component={PrintablePage} onEnter={requireAuth}>
      <Route path='propertyDetails/:id' name='Inmueble' component={PrintablePropertyDetails} />
    </Route>
    {/* <Route path='/proposal/:code' name='Doc' component={PublicDossier}>
      <Route path='dossier' name='Dosier' component={PrintablePropertyDetails} />
    </Route> */}
    <Route
      path='dealInfo/:id/clientInfo'
      name='Información del cliente'
      component={FormDealClientInfo}
    />
    <Route path='/thankYou' name='Solicitud enviada' component={GenericThankYouPage} />
    <Route
      path='/proposalConfirmation'
      name='Confirmación de datos de propuesta'
      component={ProposalConfirmationThankYouPage}
    />
    <Route
      path='proposal/:id/providerInfo'
      name='Información del contrato'
      component={FormProposalProviderInfo}
    />
  </Router>
);

function requireAuth(nextState, replace) {
  if (!localStorage.jwt) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname },
    });
  }
}
