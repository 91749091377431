import React, { Component } from 'react';
import Carousel from 'react-responsive-carousel/lib/components/Carousel.js';
import isEmpty from 'lodash/isEmpty';

/*******************************************************
 PROPS TO CONFIGURE IN PARENT
 imagesToRender -> array with the ids of the images to be rendered in the carousel
 ********************************************************/

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesToRender: props.imagesToRender,
    };

    this.getSelectedImage = this.getSelectedImage.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.state.imagesToRender) !== JSON.stringify(nextProps.imagesToRender)) {
      this.setState({
        imagesToRender: nextProps.imagesToRender,
      });
    }
  }

  getSelectedImage() {
    const { carousel } = this.refs;

    return carousel.state.selectedItem;
  }

  render() {
    const { imagesToRender } = this.state;
    const imagesInCarousel = [];

    if (!isEmpty(imagesToRender)) {
      for (let i = 0; i < imagesToRender.length; i++) {
        imagesInCarousel.push(
          <img id={'image' + i} key={'image' + i} src={imagesToRender[i]} alt={'image' + i} />
        );
      }
    }

    return (
      <div>
        {!isEmpty(imagesToRender) && (
          <Carousel infiniteLoop adaptiveHeight ref='carousel'>
            {imagesInCarousel}
          </Carousel>
        )}
        {isEmpty(imagesToRender) && <div className='loading-background-hs'>&nbsp;</div>}
      </div>
    );
  }
}

export default ImageCarousel;
