/*
  Special attention:
  getFieldDecorator can not be used to decorate stateless component.
  https://ant.design/components/form/
*/
/* eslint-disable react/prefer-stateless-function */

import { Form } from 'antd';
import React, { Component } from 'react';

import PropTypes from 'prop-types';

const FormItem = Form.Item;

class InputAntdValidator extends Component {
  static propTypes = {
    className: PropTypes.string,
    fieldDecorator: PropTypes.string.isRequired,
    form: PropTypes.shape({}).isRequired,
    help: PropTypes.string,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isRequiredMessage: PropTypes.string,
    isWrongMessage: PropTypes.string,
    label: PropTypes.string,
    // name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.element,
    type: PropTypes.string,
    validator: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
    valuePropName: PropTypes.string,
    whitespace: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    help: undefined,
    isDisabled: false,
    isRequired: false,
    isRequiredMessage: 'Por favor, introduce la información necesaria.',
    isWrongMessage: 'El valor introducido no es correcto.',
    label: undefined,
    onChange: null,
    placeholder: '',
    prefix: null,
    type: 'string',
    validator: null,
    value: '',
    valuePropName: 'noValuePropName',
    whitespace: true,
  };

  render() {
    const {
      children,
      fieldDecorator,
      form,
      help,
      initialValue,
      isRequired,
      isRequiredMessage,
      isWrongMessage,
      label,
      type,
      validator,
      whitespace,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <FormItem label={label} help={help}>
        {getFieldDecorator(fieldDecorator, {
          initialValue,
          rules: [
            {
              type,
              message: isWrongMessage,
              whitespace,
            },
            {
              required: isRequired,
              message: isRequiredMessage,
            },
            {
              validator,
            },
          ],
        })(children)}
      </FormItem>
    );
  }
}

export default Form.create({
  onFieldsChange() {
    // debugger;
    // props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    // debugger;
    const a = {
      [props.fieldDecorator]: Form.createFormField({
        ...props,
        value: props.value,
      }),
    };

    // console.error(a);
    return a;
  },
})(InputAntdValidator);
// export default InputAntdValidator;
