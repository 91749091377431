import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableDataProviderProperties from '../TableDataProviderProperties/TableDataProviderProperties';
import { Modal } from 'antd';
import FormNewProperty from '../FormNewProperty/FormNewProperty';
import PropertiesApi from '../../modules/Properties/PropertiesApi';
import { ENDPOINT } from '../../shared/appConfig';
import PropertyDetailsApi from '../../modules/PropertyDetails/PropertyDetailsApi';
import { useDispatch } from 'react-redux';
import { getPropertyList } from '../../modules/Properties/PropertiesActions';
import { PROPERTY_PUBLICATION_STATUS } from 'hs-utils';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';

function FormProviderProperties(props) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleCreateEmptyProperty = async () => {
    try {
      const propertyLocation = await PropertiesApi.getNewPropertyLocation(`${ENDPOINT}/property`);
      const propertyDetails = {
        title: '',
        providerId: props.providerDetails.id,
        publicationStatus: PROPERTY_PUBLICATION_STATUS.NEW,
      };
      await PropertyDetailsApi.savePropertyDetailsData(
        propertyLocation.headers.location,
        propertyDetails
      );
      openNotificationWithIcon('success', 'Todo OK :)', 'Inmueble creado correctamente');
      dispatch(
        getPropertyList({ href: `${ENDPOINT}/property/provider/${props.providerDetails.id}` })
      );
    } catch (e) {
      console.error(e);
      openNotificationWithIcon('error', 'Opps!! :(', 'Ha ocurrido un error al crear el inmueble');
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-sm-2'>
          <button
            type='button'
            className='btn btn-primary btn-block alignLeft'
            onClick={() => setShowModal(true)}
          >
            Añadir inmueble
          </button>
          <button
            type='button'
            className='btn btn-primary btn-block alignLeft'
            onClick={() => handleCreateEmptyProperty()}
          >
            Crear inmueble vacío
          </button>
          <Modal
            title='Nuevo inmueble'
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
          >
            <FormNewProperty providerDetails={props.providerDetails} />
          </Modal>
        </div>
        <div className='col-sm-10'>
          <TableDataProviderProperties handlePropertyListChange={props.handlePropertyListChange} />
        </div>
      </div>
    </>
  );
}

FormProviderProperties.propTypes = {
  handlePropertyListChange: PropTypes.func,
};

export default FormProviderProperties;
