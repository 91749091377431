import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import {
  deleteProvider,
  saveProviderDetailsData,
} from '../../modules/ProviderDetails/ProviderDetailsActions';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import {
  getOptionsForSelect,
  getTextValueFromSelectOptions,
  getYesNoOptionsForBooleanOptions,
  openNotificationWithIcon,
  setKeyLabelESToLabel,
  YES_NO_NOINFO_OPTIONS,
} from '../../shared/utils/appUtils';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import CheckboxButton from '../CheckboxButton';
import { DATE_FORMAT, DATETIME_FORMAT } from '../../shared/appConfig';
import FormIBAN, { validateIBAN } from '../HomyFormComponents/FormIBAN';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';

class FormProviderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providerDetails: props.providerDetailsData.data.detail,
      providerDetailsEtag: props.providerDetailsData.data.etag,
      submitting: false,
      submitSuccess: false,
      invalidInput: false,
      showJobTitle: true,
      marketingSourceMediumOptions: setKeyLabelESToLabel(this.props.marketingSourceMediumOptions),
      options: {
        providerType: getOptionsForSelect(this.props.optionLabels, 'ProviderType'),
        leadLifecycle: getOptionsForSelect(this.props.optionLabels, 'ProviderLeadLifecycle'),
        priceType: getOptionsForSelect(this.props.optionLabels, 'PriceType'),
        favoriteContactChannel: getOptionsForSelect(
          this.props.optionLabels,
          'FavoriteContactChannel'
        ),
        responseSpeed: getOptionsForSelect(this.props.optionLabels, 'ResponseSpeed'),
        tax: getOptionsForSelect(this.props.optionLabels, 'Tax'),
        registeredInRoi: getOptionsForSelect(this.props.optionLabels, 'RegisteredInRoi'),
        providerManagementTypes: getOptionsForSelect(
          this.props.optionLabels,
          'ProviderManagementType'
        ),
        channelsManager: getOptionsForSelect(this.props.optionLabels, 'ChannelManager'),
        providerCommissionInPrice: getOptionsForSelect(
          this.props.optionLabels,
          'ProviderCommissionInPrice'
        ),
        usesICal: getYesNoOptionsForBooleanOptions(),
      },
      deletingProvider: false,
    };

    // HTI-6301 Return to 6% of commission for all providers
    // this.isTestProviderCommission =
    //   (props.providerDetailsData.data.detail.id.charAt(0) === 'a' ||
    //     props.providerDetailsData.data.detail.id.charAt(0) === 'c' ||
    //     props.providerDetailsData.data.detail.id.charAt(0) === 'd' ||
    //     props.providerDetailsData.data.detail.id.charAt(0) === 'e') &&
    //   (get(props, 'providerDetailsData.data.detail.commissionPercentage', 0) === 0 ||
    //     get(props, 'providerDetailsData.data.detail.commissionPercentage', 0) === 6);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.validateFavoriteContactChannel = this.validateFavoriteContactChannel.bind(this);
    this.handleDeleteProvider = this.handleDeleteProvider.bind(this);
    this.handleCopyUrl = this.handleCopyUrl.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting) {
      if (nextProps.providerDetailsSubmit.error) {
        openNotificationWithIcon('error', 'Oops!', nextProps.providerDetailsSubmit.errorDetail);
      } else {
        openNotificationWithIcon(
          'success',
          'Todo Ok! :)',
          'Los cambios se han realizado correctamente'
        );
      }

      this.setState({ submitting: false });
    }

    // For Provider deletion
    if (
      JSON.stringify(this.state.deleteProviderStatus) !==
      JSON.stringify(nextProps.deleteProviderStatus)
    ) {
      if (nextProps.deleteProviderStatus.error && this.state.deletingProvider) {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error eliminando el proveedor: \n${nextProps.deleteProviderStatus.errorDetail}`
        );
      } else if (this.state.deletingProvider) {
        openNotificationWithIcon(
          'success',
          'Todo OK :)',
          'El proveedor se ha eliminado correctamente'
        );
      }

      this.setState({
        deletingProvider: false,
        deleteProviderStatus: {},
      });
    }
  }

  handleChange(e) {
    const { providerDetails } = this.state;

    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (
      e.target.name === 'baseContact.phoneNumber' ||
      e.target.name === 'baseContact.mobilePhoneNumber'
    ) {
      value = normalizePhoneNumber(value);
    }

    const isBaseContact = e.target.name.startsWith('baseContact.');

    if (isBaseContact) {
      const targetBaseContact = e.target.name.replace('baseContact.', '');

      if (targetBaseContact === 'address.addressComplement') {
        if (!providerDetails.baseContact.address) {
          providerDetails.baseContact.address = {};
        }

        providerDetails.baseContact.address.addressComplement = value;
      } else {
        providerDetails.baseContact[targetBaseContact] = value;
      }
    } else {
      providerDetails[e.target.name] = value;
    }

    this.setState({ providerDetails });
  }

  handleSelectChange(name, selectedOption) {
    const { providerDetails } = this.state;
    const isBaseContact = name.startsWith('baseContact.');

    let value = get(selectedOption, 'value', null);
    if (value === 'no-info') {
      value = null;
    }

    if (isBaseContact) {
      const targetBaseContact = name.replace('baseContact.', '');

      providerDetails.baseContact[targetBaseContact] = value;
    } else if (name === 'providerManagementTypes' || name === 'channelManager') {
      providerDetails[name] = selectedOption
        ? Array.isArray(selectedOption)
          ? selectedOption.map(option => option.value)
          : null
        : null;
    } else {
      providerDetails[name] = value;
    }

    this.setState({ providerDetails });
    if (providerDetails.providerType === 'COMPANY') {
      this.setState({
        showJobTitle: false,
      });
    } else {
      this.setState({
        showJobTitle: true,
      });
    }
  }

  handlePlaceChanged(place) {
    const { providerDetails } = this.state;

    set(providerDetails, 'baseContact.address', {
      ...place,
      addressComplement: get(providerDetails, 'baseContact.address.addressComplement', null),
    });

    this.setState({
      providerDetails,
    });
  }

  validateForm() {
    const isValidFavoriteChannel = this.validateFavoriteContactChannel();

    let isValidForm = false;

    if (isValidFavoriteChannel) {
      isValidForm = this.isValidForm();
    }

    return isValidFavoriteChannel && isValidForm;
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const isValidForm = validateInputs(inputsToValidate);

    if (isValidForm) {
      this.setState({ invalidInput: !isValidForm });
    } else {
      openNotificationWithIcon(
        'error',
        'Ai dioh mio',
        '¡Se te ha pasado rellenar algún campo requerido!'
      );
    }

    return isValidForm;
  }

  validateFavoriteContactChannel() {
    const { favoriteContactChannel: channel, baseContact: contact } = this.state.providerDetails;

    let isValid = true;

    switch (channel) {
      case 'PHONE':
        if (!contact.phoneNumber && !contact.mobilePhoneNumber) {
          isValid = false;
          openNotificationWithIcon(
            'error',
            'Dale a tu cuerpo alegría Macarena',
            'Si seleccionas como canal favorito "Teléfono", debes rellenar el campo Teléfono Fijo o Móvil'
          );
        }

        break;
      case 'MAIL':
        if (!contact.email) {
          openNotificationWithIcon(
            'warning',
            'Parece que el contacto del proveedor no tiene correo, asegúrate que algún contacto tenga correo si no, no le llegarán las notificaciones'
          );
        }
        break;
      case 'WHATSAPP':
        if (!contact.mobilePhoneNumber) {
          isValid = false;
          openNotificationWithIcon(
            'error',
            'Eeeeeeh Macarena',
            'Si seleccionas como canal favorito "WhatsApp", debes rellenar el campo Teléfono Móvil'
          );
        }

        break;
      default:
        break;
    }

    return isValid;
  }

  handleSubmit = e => {
    e.preventDefault();
    const isValidForm = this.validateForm();

    if (validateIBAN() && isValidForm) {
      this.setState({ submitting: true }, () => {
        this.props.actions.saveProviderDetailsData();
      });
    }
  };

  handleDeleteProvider = e => {
    e.preventDefault();

    if (
      this.props.providerDetailsData &&
      window.confirm('¿Estás seguro de que quieres eliminar este proveedor?')
    ) {
      const url = this.props.providerDetailsData.metasource.links.reduced.href;
      const { etag } = this.props.providerDetailsData.data;

      this.setState({ deletingProvider: true }, () => {
        this.props.actions.deleteProvider(url, etag);
      });
    }
  };

  handleCopyUrl() {
    navigator.clipboard
      .writeText(window.decodeURIComponent(this.state.providerDetails.portalUrl))
      .then(
        function() {
          openNotificationWithIcon('info', 'URL copiada al portapapeles!!!', '', 5);
        },
        function() {
          openNotificationWithIcon('error', 'No se ha podido copiar la URL');
        }
      );
  }

  render() {
    const hasAcceptedGeneralConditions =
      isNumber(get(this.state, 'providerDetails.generalConditionsDate')) &&
      !isEmpty(get(this.state, 'providerDetails.generalConditionsOrigin'));

    const hasAcceptedPrivacyPolicy =
      isNumber(get(this.state, 'providerDetails.privacyPolicyDate')) &&
      !isEmpty(get(this.state, 'providerDetails.privacyPolicyOrigin'));

    // const billabelValue = this.state.providerDetails.billable === null ? 'no-info' : this.state.providerDetails.billable;

    return (
      <div className='col-sm-12'>
        <form id='formProviderDetails' onSubmit={this.handleSubmit}>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='row'>
                <div className='col-sm-12'>
                  <Button
                    loading={this.state.submitting}
                    disabled={this.state.deletingProvider}
                    htmlType='submit'
                    className='btn btn-primary btn-block alignLeft'
                  >
                    Guardar
                  </Button>
                  <Button
                    loading={this.state.deletingProvider}
                    disabled={this.state.submitting}
                    type='button'
                    className='btn btn-primary btn-block alignLeft'
                    onClick={this.handleDeleteProvider}
                  >
                    Eliminar
                  </Button>
                  <br />
                  {this.state.providerDetails.baseContact &&
                  this.state.providerDetails.baseContact.creationDate ? (
                    <p>
                      <b>Creado por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(
                        get(this.state, 'providerDetails.baseContact.createdBy'),
                        ''
                      )}
                      <br />
                      {moment(this.state.providerDetails.baseContact.creationDate).format(
                        DATETIME_FORMAT
                      )}
                    </p>
                  ) : (
                    ''
                  )}
                  {this.state.providerDetails.baseContact &&
                  this.state.providerDetails.baseContact.updateDate ? (
                    <p>
                      <b>Última actualización por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(
                        get(this.state, 'providerDetails.baseContact.updatedBy'),
                        ''
                      )}
                      <br />
                      {moment(this.state.providerDetails.baseContact.updateDate).format(
                        DATETIME_FORMAT
                      )}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <div className='col-sm-10'>
              {!isEmpty(get(this.state, 'providerDetails.portalUrl')) && (
                <div className='row'>
                  <div className='col-sm-11'>
                    <div className='form-group'>
                      <label htmlFor='providerDetailportalUrl'>URL para editar el proveedor</label>
                      <input
                        type='text'
                        className='form-control noBorder'
                        disabled
                        id='providerDetailportalUrl'
                        name='providerDetailportalUrl'
                        placeholder='https://...'
                        value={
                          this.state.providerDetails.portalUrl
                            ? this.state.providerDetails.portalUrl
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className='col-sm-1 pt-4 m-0'>
                    <Tooltip placement='top' title='Copiar URL'>
                      <i className='far fa-copy copy-icon' onClick={this.handleCopyUrl} />
                    </Tooltip>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerType' className='required'>
                      Tipo
                    </label>
                    <Select
                      id='providerType'
                      name='providerType'
                      className='form-control validate validate-required'
                      value={this.state.providerDetails.providerType || ''}
                      options={this.state.options.providerType}
                      onChange={value => {
                        this.handleSelectChange('providerType', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='En qué página web hemos encontrado al Proveedor antes de incorporarlo a Homybrain.'
                    >
                      <label htmlFor='providerOrigin'>Origen del proveedor</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <Select
                      id='providerOrigin'
                      name='marketingSourceMedium'
                      className='form-control'
                      value={this.state.providerDetails.marketingSourceMedium || ''}
                      options={this.state.marketingSourceMediumOptions}
                      onChange={value => {
                        this.handleSelectChange('marketingSourceMedium', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailWebDomain'>Página web</label>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailWebDomain'
                      name='baseContact.webDomain'
                      placeholder='pruebas.com'
                      value={
                        this.state.providerDetails.baseContact &&
                        this.state.providerDetails.baseContact.webDomain &&
                        this.state.providerDetails.baseContact.webDomain !== null
                          ? this.state.providerDetails.baseContact.webDomain
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailLeadLifecycle'>Estado del lead</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='0'
                      value={getTextValueFromSelectOptions(
                        this.state.providerDetails.providerLeadLifecycle,
                        this.state.options.leadLifecycle
                      )}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Número de inmuebles que gestiona el Proveedor'
                    >
                      <label htmlFor='channelsManager'>Número de inmuebles</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <input
                      type='text'
                      className='form-control'
                      id='propertiesNumber'
                      name='propertiesNumber'
                      placeholder='0'
                      value={this.state.providerDetails.propertiesNumber || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Tipo de gestión que realiza el Property Manager de los inmuebles.'
                    >
                      <label htmlFor='providerManagementType'>Tipo de Gestión</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <Select
                      name='providerManagementTypes'
                      className='form-control'
                      value={this.state.providerDetails.providerManagementTypes || ''}
                      options={this.state.options.providerManagementTypes}
                      onChange={value => {
                        this.handleSelectChange('providerManagementTypes', value);
                      }}
                      multi
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Channel Managers con los que está integrado el Proveedor.'
                    >
                      <label htmlFor='channelManager'>Channel Managers</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <Select
                      name='channelManager'
                      className='form-control'
                      value={this.state.providerDetails.channelManager || ''}
                      options={this.state.options.channelsManager}
                      onChange={value => {
                        this.handleSelectChange('channelManager', value);
                      }}
                      multi
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='usesICal'>Trabaja con iCal</label>
                    <Select
                      name='usesICal'
                      className='form-control'
                      value={
                        typeof this.state.providerDetails.usesICal !== 'undefined'
                          ? this.state.providerDetails.usesICal
                          : ''
                      }
                      options={this.state.options.usesICal}
                      onChange={value => {
                        this.handleSelectChange('usesICal', value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip placement='topLeft' title='Número de Identificación Fiscal.'>
                      <label htmlFor='providerDetailIdentificationCode'>NIF</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailIdentificationCode'
                      name='baseContact.identificationCode'
                      placeholder='12121212Z'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.identificationCode
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailFirstName' className='required'>
                      Nombre
                    </label>
                    <input
                      type='text'
                      className='form-control validate validate-required'
                      id='providerDetailName'
                      name='baseContact.name'
                      placeholder='Ana'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.name
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailLastOTradeName'>Apellidos/Razón Social</label>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailLastOTradeName'
                      name='baseContact.lastOTradeName'
                      placeholder='García García'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.lastOTradeName
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  {this.state.showJobTitle && (
                    <div className='form-group'>
                      <label htmlFor='providerDetailLastOTradeName'>Cargo</label>
                      <input
                        type='text'
                        className='form-control'
                        id='providerDetailJobTitle'
                        name='baseContact.jobTitle'
                        placeholder='Cargo'
                        value={
                          this.state.providerDetails.baseContact
                            ? this.state.providerDetails.baseContact.jobTitle
                            : ''
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                  {!this.state.showJobTitle && (
                    <div className='form-group'>
                      <label htmlFor='providerDetailLastOTradeName'>Cargo</label>
                      <input
                        type='text'
                        className='form-control'
                        id='providerDetailJobTitle'
                        name='baseContact.jobTitle'
                        placeholder='Cargo'
                        readOnly
                        value={
                          this.state.providerDetails.baseContact
                            ? this.state.providerDetails.baseContact.jobTitle
                            : ''
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailEmail'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      id='providerDetailEmail'
                      name='baseContact.email'
                      placeholder='pruebas@pruebas.com'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.email
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailEmail2'>2º Email</label>
                    <input
                      type='email'
                      className='form-control'
                      id='providerDetailEmail2'
                      name='baseContact.email2'
                      placeholder='pruebas@pruebas.com'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.email2
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailPhoneNumber'>Teléfono fijo</label>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailPhoneNumber'
                      name='baseContact.phoneNumber'
                      placeholder='961231212'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.phoneNumber
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailMobilePhoneNumber'>Teléfono móvil</label>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailMobilePhoneNumber'
                      name='baseContact.mobilePhoneNumber'
                      placeholder='616232323'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.mobilePhoneNumber
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='favoriteContactChannel'>Canal de contacto preferido</label>
                    <Select
                      name='favoriteContactChannel'
                      className='form-control'
                      id='favoriteContactChannel'
                      value={this.state.providerDetails.favoriteContactChannel || ''}
                      options={this.state.options.favoriteContactChannel}
                      onChange={value => {
                        this.handleSelectChange('favoriteContactChannel', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='responseSpeed'>Rapidez de respuesta</label>
                    <Select
                      name='responseSpeed'
                      className='form-control'
                      id='responseSpeed'
                      value={this.state.providerDetails.responseSpeed || 'UNKNOWN'}
                      options={this.state.options.responseSpeed}
                      onChange={value => {
                        this.handleSelectChange('responseSpeed', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailHomyspaceReference'>Ref. HomySpace</label>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailHomyspaceReference'
                      name='homyspaceReference'
                      placeholder='HS123'
                      value={this.state.providerDetails.homyspaceReference}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Escribe la dirección y SELECCIONA una de las opciones del desplegable.'
                    >
                      <label htmlFor='providerDetailAddress'>Dirección</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <GoogleMapsAutocomplete
                      handlePlaceChanged={this.handlePlaceChanged}
                      initValue={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.address
                          : ''
                      }
                      inputClassName='form-control'
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Introduce aquí la parte de la dirección que no reconoce Google Maps (número de planta, escalera, etc.).'
                    >
                      <label htmlFor='providerDetailAddressComponent'>Complemento dirección</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailAddressComponent'
                      name='baseContact.address.addressComplement'
                      placeholder='n.3 '
                      value={
                        this.state.providerDetails.baseContact &&
                        this.state.providerDetails.baseContact.address
                          ? this.state.providerDetails.baseContact.address.addressComplement
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <FormIBAN
                      name='baseContact.accountNumber'
                      initialValue={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.accountNumber
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='El código SWIFT (Society for Worldwide Interbank Financial Telecommunication) o también denominado código BIC (Bank Identifier Code) es una serie alfanumérica de 8 u 11 dígitos que sirve para identificar al banco receptor cuando se realiza una transferencia internacional.'
                    >
                      <label htmlFor='providerDetailBicOrSwift'>BIC o SWIFT</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailBicOrSwift'
                      name='baseContact.bicOrSwift'
                      placeholder='HOMYESMMXXX'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.bicOrSwift
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Titular de la cuenta bancaria a quien se le hará la transferencia.'
                    >
                      <label htmlFor='providerDetailBeneficiary'>Beneficiario</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <input
                      type='text'
                      className='form-control'
                      id='providerDetailBeneficiary'
                      name='baseContact.beneficiary'
                      placeholder='Antonio'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.beneficiary
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailBillable'>Hace factura</label>
                    <Select
                      name='billable'
                      id='providerDetailBillable'
                      className='form-control'
                      value={
                        this.state.providerDetails.billable === null
                          ? 'no-info'
                          : this.state.providerDetails.billable
                      }
                      options={YES_NO_NOINFO_OPTIONS}
                      onChange={value => {
                        this.handleSelectChange('billable', value);
                      }}
                      clearable={false}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-3'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title='Indica si el proveedor nos facilita los precios impuestos incluidos, o + impuestos.'
                        >
                          <label htmlFor='providerDetailPriceType'>Impuestos de la renta</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <Select
                          name='priceType'
                          className='form-control'
                          value={this.state.providerDetails.priceType || ''}
                          options={this.state.options.priceType}
                          onChange={value => {
                            this.handleSelectChange('priceType', value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='¿Está el Proveedor registrada en el Registro de Operadores Intracomunitarios?'
                    >
                      <label htmlFor='registeredInRoi'>Registrado en el ROI</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <Select
                      name='baseContact.registeredInRoi'
                      className='form-control'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.registeredInRoi
                          : ''
                      }
                      options={this.state.options.registeredInRoi}
                      onChange={value => {
                        this.handleSelectChange('baseContact.registeredInRoi', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    {/* {!this.isTestProviderCommission && ( */}
                    <>
                      <Tooltip
                        placement='topLeft'
                        title='Porcentaje de comisión negociado con el Proveedor.'
                      >
                        <label htmlFor='providerDetailCommissionPercentage'>Comisión %</label>{' '}
                        <i className='far fa-question-circle' />
                      </Tooltip>
                      <input
                        type='number'
                        className='form-control'
                        id='providerDetailCommissionPercentage'
                        name='commissionPercentage'
                        placeholder='0.0'
                        min='0'
                        value={this.state.providerDetails.commissionPercentage || ''}
                        onChange={this.handleChange}
                      />
                    </>
                    {/* )}
                    {this.isTestProviderCommission && (
                      <>
                        <Tooltip
                          placement='topLeft'
                          title={`Se muestra la comisión aplicada tras el test. La comisión real negociada con este proveedor es del ${
                            this.state.providerDetails.commissionPercentage
                              ? this.state.providerDetails.commissionPercentage
                              : 0
                          }%`}
                        >
                          <label htmlFor='providerDetailCommissionPercentage'>Comisión %</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <input
                          type='number'
                          className='form-control'
                          id='providerDetailCommissionPercentageTest'
                          name='commissionPercentageTest'
                          value='10'
                          disabled
                        />
                      </>
                    )} */}
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Indica si la comisión negociada con el Proveedor está incluida en el precio, o se añade al que nos facilita.'
                    >
                      <label htmlFor='providerCommissionInPrice'>
                        Comisión al proveedor en precio
                      </label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <Select
                      name='providerCommissionInPrice'
                      className='form-control'
                      id='providerCommissionInPrice'
                      value={this.state.providerDetails.providerCommissionInPrice || ''}
                      options={this.state.options.providerCommissionInPrice}
                      onChange={value => {
                        this.handleSelectChange('providerCommissionInPrice', value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <Tooltip
                      placement='topLeft'
                      title='Honorarios que nos cobran los colaboradores (Property Managers).'
                    >
                      <label htmlFor='tax'>Honorarios</label>{' '}
                      <i className='far fa-question-circle' />
                    </Tooltip>
                    <Select
                      name='tax'
                      className='form-control'
                      id='tax'
                      value={this.state.providerDetails.tax || ''}
                      options={this.state.options.tax}
                      onChange={value => {
                        this.handleSelectChange('tax', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailSpecificContract'>&nbsp;</label>
                    <Tooltip
                      placement='topLeft'
                      title='Indica si debemos utilizar específicamente el contrato del Property, o no.'
                    >
                      <CheckboxButton
                        borderStyle='providerCheckboxActive'
                        id='providerDetailSpecificContract'
                        name='specificContract'
                        handleChange={this.handleChange}
                        content='Contrato específico'
                        value={this.state.providerDetails.specificContract || false}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailAcceptedSafeCheckin'>
                      Acepta check-in seguro
                    </label>
                    <Select
                      name='acceptedSafeCheckin'
                      id='providerDetailAcceptedSafeCheckin'
                      className='form-control'
                      value={
                        this.state.providerDetails.acceptedSafeCheckin === null
                          ? 'no-info'
                          : this.state.providerDetails.acceptedSafeCheckin
                      }
                      options={YES_NO_NOINFO_OPTIONS}
                      onChange={value => {
                        this.handleSelectChange('acceptedSafeCheckin', value);
                      }}
                      clearable={false}
                      disabled={get(this.state, 'providerDetails.userWeb', false)}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailDescription'>Descripción</label>
                    <textarea
                      id='providerDetailDescription'
                      name='baseContact.description'
                      rows='8'
                      className='form-control'
                      placeholder='Descripción'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.description
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='providerDetailObservations'>Observaciones</label>
                    <textarea
                      id='providerDetailObservations'
                      name='baseContact.observations'
                      rows='4'
                      className='form-control'
                      placeholder='Observaciones'
                      value={
                        this.state.providerDetails.baseContact
                          ? this.state.providerDetails.baseContact.observations
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-6'>
                  <div style={{ fontSize: 14 }}>
                    <b style={{ marginRight: 5 }}>Condiciones generales:</b>
                    {hasAcceptedGeneralConditions
                      ? `Aceptadas ${moment(
                          this.state.providerDetails.generalConditionsDate
                        ).format(DATE_FORMAT)}`
                      : 'No aceptadas'}
                    <br />
                    <b style={{ marginRight: 5 }}>Política de privacidad:</b>
                    {hasAcceptedPrivacyPolicy
                      ? `Aceptada ${moment(this.state.providerDetails.privacyPolicyDate).format(
                          DATE_FORMAT
                        )}`
                      : 'No aceptada'}
                  </div>
                </div>

                {!isEmpty(this.state.providerDetails.loginEmails) && (
                  <div className='col-sm-6'>
                    <div style={{ fontSize: 14 }}>
                      <b style={{ marginRight: 5 }}>Correos de acceso al portal:</b>
                      {this.state.providerDetails.loginEmails.map((email, key) => (
                        <>
                          <br />
                          <span key={key}>{email}</span>
                        </>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveProviderDetailsData: () => dispatch(saveProviderDetailsData()),
      deleteProvider: (url, etag) => dispatch(deleteProvider(url, etag)),
    },
  };
}

function mapStateToProps(state) {
  return {
    providerDetailsData: state.ProviderDetails,
    providerDetailsSubmit: state.ProviderDetails.submit,
    optionLabels: state.Preload.optionLabels,
    marketingSourceMediumOptions: state.Providers.providerMarketingSourceMediumOptions.options.data,
    deleteProviderStatus: state.ProviderDetails.delete,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormProviderDetails);
