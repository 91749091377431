/* eslint-disable react/no-string-refs */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Button, Col, Modal, Row, Typography } from 'antd';
import moment from 'moment';
import GoogleMapsMap from '../../components/GoogleMapsMap';
import FormNewProperty from '../../components/FormNewProperty';
import {
  exportFilteredProperties,
  getNewPropertyLocation,
  getPropertiesInMapFilteredByReference,
  propertiesFilter,
  // propertiesInMapFilteredSuccess,
  saveActiveBusinessData,
} from '../../modules/Properties/PropertiesActions';
import TableDataProperties from '../../components/TableDataProperties';
import FormImportProperties from '../../components/FormImportProperties/FormImportProperties';
import TableImportPropertiesStatus from '../../components/TableImportPropertiesStatus/TableImportPropertiesStatus';
import { getOptionsForSelect, openNotificationWithIcon } from '../../shared/utils/appUtils';
import PropertiesFilter, { adjustDateFilters } from './PropertiesFilter';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import tableUtils from '../../shared/utils/tableUtils';
import { adjustDay } from '../../shared/utils/dateUtils';
import isNil from 'lodash/isNil';
import isDate from 'lodash/isDate';
import map from 'lodash/map';
import isNumber from 'lodash/isNumber';
import PropertyInfo from '../../components/PropertyInfo/PropertyInfo';
import api from '../../shared/utils/api';
import { ENDPOINT_V2, MAXIMUM_PROPERTIES } from '../../shared/appConfig';
import { dealDetailsUnbonded } from '../../modules/DealDetails/DealDetailsActions';
import PropertiesApi from '../../modules/Properties/PropertiesApi';

const { Title } = Typography;

class Properties extends Component {
  static MAXIMUM_PROPERTIES_EXCEEDED_MESSAGE_TITLE =
    'Existen más inmuebles que los mostrados en el mapa.';

  static MAXIMUM_PROPERTIES_EXCEEDED_MESSAGE_DESCRIPTION = `Solo se muestran los ${MAXIMUM_PROPERTIES} más económicos. Restringe la búsqueda mediante el zoom o aplicando más filtros`;

  constructor(props) {
    super(props);
    this.state = {
      filter: {
        roomsFilter: 'roomsMoreThan',
        bedsFilter: 'bedsMoreThan',
        ...props.filter,
        showMoreFilters: false,
        // smartSearch disabled temporally
        smartSearch: false,
      },
      property: {
        data: {},
        // images: [],
        etag: '',
        visible: false,
      },
      options: {
        housingType: getOptionsForSelect(this.props.optionLabels, 'HousingType'),
        cleaningChargeType: getOptionsForSelect(this.props.optionLabels, 'CleaningChargeType'),
        classification: getOptionsForSelect(this.props.optionLabels, 'Classification'),
        taxType: getOptionsForSelect(this.props.optionLabels, 'TaxType'),
        proposalCreationReason: getOptionsForSelect(
          this.props.optionLabels,
          'ProposalCreationReason'
        ),
      },
      visitedProperties: [],
      consultedAvailabilityProviders: [],
      propertySelected: '',
      externalFilter: props.externalFilter,
      propertiesVersion: 0,

      loading: true,
      showMap: true,

      modalImport: false,
      modalImportStatus: false,
      maximumPropertiesExceeded: false,
      propertiesCount: '0',
      totalCount: '',
      bondedDealPreferences: {},
    };

    this.showExportationMessage = false;
    // smartSearch disabled temporally
    this.disableSmartSearch = true;
    // this.disableSmartSearch = !props.filter.smartSearch;

    this.toggle = this.toggle.bind(this);
    this.initializeMap = this.initializeMap.bind(this);
    this.filterProperties = this.filterProperties.bind(this);
    this.renderMarkersFromProperties = this.renderMarkersFromProperties.bind(this);

    this.renderPropertiesFiltered = this.renderPropertiesFiltered.bind(this);
    this.showPropertyDetails = this.showPropertyDetails.bind(this);
    this.handleCheckAvailability = this.handleCheckAvailability.bind(this);
    this.cleanReferencePoint = this.cleanReferencePoint.bind(this);
    this.changeViewMode = this.changeViewMode.bind(this);
    this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.handleFileUploaded = this.handleFileUploaded.bind(this);
    this.toggleImportStatus = this.toggleImportStatus.bind(this);
    // this.setOpportunityInfo = this.setOpportunityInfo.bind(this);
    this.handleDateChangeOnFilterConditions = this.handleDateChangeOnFilterConditions.bind(this);
    this.handleChangeOnFilterConditions = this.handleChangeOnFilterConditions.bind(this);
    this.handlePlaceChangedOnFilterConditions = this.handlePlaceChangedOnFilterConditions.bind(
      this
    );
    this.handleFilterCollapse = this.handleFilterCollapse.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.onCloseBondedDealInfo = this.onCloseBondedDealInfo.bind(this);
    this.isInfoWindowVisible = this.isInfoWindowVisible.bind(this);
    this.handleImagesLoaded = this.handleImagesLoaded.bind(this);
    this.showProviderDetails = this.showProviderDetails.bind(this);
    this.addPropertyIdToVisited = this.addPropertyIdToVisited.bind(this);
  }

  componentDidMount() {
    const { bondedDeal } = this.props;
    const dealId = get(bondedDeal, 'id');

    // Comes from properties page instead of deal search
    if (isEmpty(bondedDeal) || isEmpty(dealId)) {
      return;
    }

    const propertiesUrl = `${ENDPOINT_V2}/opportunities/${dealId}/properties`;
    const dealPreferencesUrl = `${ENDPOINT_V2}/opportunities/${dealId}/preferences`;

    Promise.all(map([propertiesUrl, dealPreferencesUrl], url => api.request(url, 'get')))
      .then(([propertiesResponse, preferencesResponse]) => {
        this.setState({
          visitedProperties: get(propertiesResponse, 'json.visitedProperties', []),
          consultedAvailabilityProviders: get(
            propertiesResponse,
            'json.consultedAvailabilityProviders',
            []
          ),
          bondedDealPreferences: get(preferencesResponse, 'json', {}),
        });
      })
      .catch(err => {
        console.error(`Call failed: ${err.message}`);
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.properties.version !== this.state.propertiesVersion) {
      this.setState(
        {
          propertiesVersion: nextProps.properties.version,
          propertiesCount: nextProps.properties.list.length,
        },
        this.renderPropertiesFiltered
      );
    }

    if (
      this.showExportationMessage &&
      nextProps.exportation.error &&
      nextProps.exportation.errorDetail
    ) {
      openNotificationWithIcon('warn', 'Oppss :(', nextProps.exportation.errorDetail);
      this.showExportationMessage = false;
    } else if (
      this.showExportationMessage &&
      nextProps.exportation.message &&
      nextProps.exportation.message.length > 0
    ) {
      openNotificationWithIcon('info', 'OK!! :)', nextProps.exportation.message);
      this.showExportationMessage = false;
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  setMarkers(properties) {
    const mapComponent = this.refs.googleMapsMap;

    mapComponent.clearMarkers();

    if (!isEmpty(properties)) {
      properties.forEach(property => {
        const marker = mapComponent.addMarker(
          property.geoLocation.lat,
          property.geoLocation.lng,
          property.id,
          property.providerId
        );

        mapComponent.addInfoWindowToMarker(property.id, property, marker);
      });

      if (this.state.filteringByRef) {
        this.refs.googleMapsMap.fitBoundsToMarkers();
      }

      this.setState({
        maximumPropertiesExceeded: properties.length > MAXIMUM_PROPERTIES,
        loading: false,
        properties,
      });
    } else {
      this.setState({
        loading: false,
        properties,
      });
    }
  }

  renderMarkersFromProperties() {
    const mapComponent = this.refs.googleMapsMap;

    mapComponent.clearMarkers();

    if (get(this.props, 'properties.list') && !isEmpty(get(this.props, 'properties.list'))) {
      for (let i = 0; i < this.props.properties.list.length; i++) {
        const { property } = this.props.properties.list[i];

        if (get(property, 'address') && get(property, 'address.geoLocation')) {
          const marker = mapComponent.addMarker(
            property.address.geoLocation.lat,
            property.address.geoLocation.lng,
            property.id,
            property.providerId
          );

          if (!marker) {
            return;
          }

          mapComponent.addInfoWindowToMarker(property.id, property, marker);
        }
      }

      if (this.state.filteringByRef) {
        this.refs.googleMapsMap.fitBoundsToMarkers();
      }

      let mappedList = [];

      if (!isEmpty(this.props.properties.list)) {
        mappedList = this.props.properties.list.map(p => p.property);
      }

      this.setState({
        maximumPropertiesExceeded: this.props.properties.list.length > MAXIMUM_PROPERTIES,
        properties: mappedList,
      });
    } else {
      this.setState({
        properties: [],
      });
    }
  }

  renderPropertiesFiltered() {
    this.renderMarkersFromProperties();

    this.setState({
      filtering: false,
      loading: false,
      filteringByRef: false,
    });
  }

  initializeMap() {
    const { loading, bounds, externalFilter, filter } = this.state;
    const mapComponent = this.refs.googleMapsMap;
    let viewChanged = false;

    // hemos vuelto a la pantalla de inmueble, estamos inicializando el mapa y tenemos posición anterior
    if (loading && bounds && !externalFilter) {
      mapComponent.setBounds(bounds);
      viewChanged = true;
    }

    // estamos haciendo una búsqueda con ubicación especificada
    if (get(filter, 'address.geoLocation') && get(this.props, 'bondedDeal.address.geoLocation')) {
      mapComponent.setReferencePoint(
        filter.address.geoLocation.lat,
        filter.address.geoLocation.lng,
        filter.radius > 0 ? filter.radius : null,
        true
      );
      viewChanged = true;
    } else if (get(filter, 'address.geoLocation')) {
      mapComponent.setReferencePoint(
        filter.address.geoLocation.lat,
        filter.address.geoLocation.lng,
        filter.radius > 0 ? filter.radius : null,
        true
      );
      viewChanged = true;
    } else if (get(this.props, 'bondedDeal.address.geoLocation')) {
      const bondedDealAddress = get(this.props, 'bondedDeal.address.geoLocation');

      mapComponent.setReferencePoint(
        bondedDealAddress.lat,
        bondedDealAddress.lng,
        filter.radius > 0 ? filter.radius : null,
        true
      );
      viewChanged = true;
    }

    // una vez estamos posicionados, filtramos las propiedades del mapa
    // pero sólo cuando no hemos cambiado la vista en algunos de los casos anteriores
    // en este caso el filterProperties lo hace el callback del mapa
    if (!viewChanged) {
      this.filterProperties();
    }
  }

  handleFilterCollapse() {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        showMoreFilters: !prevState.filter.showMoreFilters,
      },
    }));
  }

  handleClearFilters() {
    this.setState(prevState => ({
      filter: {
        showMoreFilters: prevState.filter.showMoreFilters,
        smartSearch: prevState.filter.smartSearch,
        address: {},
        maxPrice: 0,
        guest: 0,
        rooms: 0,
        beds: 0,
        bedsFilter: 'bedsMoreThan',
        roomsFilter: 'roomsMoreThan',
        baths: 0,
        providerType: [],
      },
    }));
  }

  filterProperties(exportation) {
    const mapComponent = this.refs.googleMapsMap;
    let bounds = null;
    const { filter: stateFilter } = this.state;
    const { bondedDeal } = this.props;

    if (stateFilter.smartSearch && !isEmpty(get(bondedDeal, 'id'))) {
      this.getPropertiesBySmartSearch();
      return;
    }

    if (!exportation) {
      this.isInfoWindowVisible(false);
      bounds = mapComponent.getBounds();
    }

    const filter = {};

    filter.maxPrice = stateFilter.maxPrice || 0;
    // More than default filter for guest
    filter.guest = stateFilter.guest;
    filter.address = stateFilter.address;
    filter.ubicacionFromLng = get(stateFilter, 'address.geoLocation.lng');
    filter.ubicacionFromLat = get(stateFilter, 'address.geoLocation.lat');
    filter.radius = stateFilter.radius;
    filter.verified = stateFilter.verified;
    filter.comissionable = stateFilter.comissionable;
    filter.startDate = stateFilter.startDate ? adjustDay(stateFilter.startDate).valueOf() : null;
    filter.endDate = stateFilter.endDate ? adjustDay(stateFilter.endDate).valueOf() : null;
    filter.refHomyspace = stateFilter.refHomyspace;
    filter.refProvider = stateFilter.refProvider;
    filter.bounds = bounds;
    filter.beds = stateFilter.beds;
    filter.bedsFilter = stateFilter.bedsFilter;
    filter.rooms = stateFilter.rooms;
    filter.roomsFilter = stateFilter.roomsFilter;
    filter.baths = stateFilter.baths;
    filter.publishedInWeb = stateFilter.publishedInWeb;
    filter.airConditioning = stateFilter.airConditioning;
    filter.wifi = stateFilter.wifi;
    filter.freeCarPark = stateFilter.freeCarPark;
    filter.lift = stateFilter.lift;
    filter.petsAllowed = stateFilter.petsAllowed;
    filter.cleaningChargeType = stateFilter.cleaningChargeType;
    filter.incorporationDateStart = stateFilter.incorporationDateStart;
    filter.providerId = stateFilter.providerId;
    filter.highConversionProbability = stateFilter.highConversionProbability ? 0.1 : 0;
    filter.providerType = stateFilter.providerType;

    // Sets startDate and endDate params for api call
    adjustDateFilters(filter, get(this.props, 'bondedDeal'));

    if (stateFilter.refProvider || stateFilter.refHomyspace) {
      // tenemos que consultar por referencia
      if (stateFilter.refProvider) {
        this.props.actions.getPropertiesInMapFilteredByReference(
          'PROVIDER',
          stateFilter.refProvider,
          filter
        );
      }

      if (stateFilter.refHomyspace) {
        this.props.actions.getPropertiesInMapFilteredByReference(
          'HS',
          stateFilter.refHomyspace,
          filter
        );
      }
    } else if (!exportation) {
      // tenemos que consultar por el filtro genérico
      const topLeft = {
        lat: bounds.getNorthEast().lat(),
        lng: bounds.getSouthWest().lng(),
      };
      const bottomRight = {
        lat: bounds.getSouthWest().lat(),
        lng: bounds.getNorthEast().lng(),
      };

      const geolocation = {
        latA: topLeft.lat,
        lonA: topLeft.lng,
        latB: bottomRight.lat,
        lonB: bottomRight.lng,
      };

      this.getProperties(geolocation, filter);
    } else {
      this.showExportationMessage = true;
      this.props.actions.exportFilteredProperties(
        filter.maxPrice === 0 ? null : filter.maxPrice / 30,
        filter.guest === 0 ? null : filter.guest,
        filter.verified ? filter.verified : null,
        filter.comissionable ? filter.comissionable : null,
        filter
      );
    }
  }

  getPropertiesBySmartSearch = async () => {
    try {
      const { bondedDeal } = this.props;
      const properties = await PropertiesApi.getPropertiesBySmartSearch(bondedDeal.id);
      if (isEmpty(properties) || properties.length === 0) {
        openNotificationWithIcon(
          'info',
          'Todavía no hay inmuebles',
          'Es posible que al algoritmo de búsqueda inteligente le quede un ratillo, o que no haya inmuebles para esta zona...'
        );
      } else {
        this.setMarkers(properties);
      }
    } catch (e) {
      openNotificationWithIcon(
        'info',
        'Todavía no hay inmuebles',
        'Es posible que al algoritmo de búsqueda inteligente le quede un ratillo, o que no haya inmuebles para esta zona...'
      );
      console.error(e);
    }
  };

  getProperties = async (geolocation, filters) => {
    const bondedDealAddress = get(this.props, 'bondedDeal.address');

    let filtersParsed = {
      ...filters,
      maxPrice: filters.maxPrice || null,
      guest: filters.guest || null,
      verified: get(filters, 'verified') || null,
      comissionable: get(filters, 'comissionable') || null,
      bathsAndToilets: get(filters, 'baths'),
      incorporationDateStart: filters.incorporationDateStart
        ? moment()
            .subtract(1, 'months')
            .valueOf()
        : null,
      baths: null,
      bounds: null,
      address: null,
      radius: null,
      publishedInWeb: !filters.publishedInWeb,
      airConditioning: get(filters, 'airConditioning') || null,
      wifi: get(filters, 'wifi') || null,
      freeCarPark: get(filters, 'freeCarPark') || null,
      lift: get(filters, 'lift') || null,
      petsAllowed: get(filters, 'petsAllowed') || null,
      cleaningChargeType: get(filters, 'cleaningChargeType') || null,
      providerType: filters.providerType.join(' ') || null,
    };

    if (bondedDealAddress) {
      filtersParsed = {
        ...filtersParsed,
        ubicacionFromLng: bondedDealAddress.geoLocation.lng,
        ubicacionFromLat: bondedDealAddress.geoLocation.lat,
      };
    }

    const params = {
      geolocation,
      filters: filtersParsed,
    };

    try {
      const res = await api.getMapDataV2('properties', params);
      const properties = map(
        get(res, 'json.properties', []),
        ({ property, distanceFromDesiredLocationInKm } = {}) => ({
          ...property,
          distanceFromDesiredLocationInKm,
        })
      );

      this.setState({
        totalCount: get(res, 'json.totalHits', 0),
        propertiesCount: properties.length,
      });

      this.setMarkers(properties);
      this.props.actions.propertiesFilter(filters);
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Lo he intentado 😿',
        'No he podido obtener los Inmuebles para cargarlos sobre el mapa...'
      );
      console.error(e);
    }
  };

  handlePlaceChangedOnFilterConditions(value) {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        address: value,
      },
    }));
  }

  handleChangeOnFilterConditions(name, value) {
    this.setState(
      prevState => ({
        filter: {
          ...prevState.filter,
          [name]: value,
        },
      }),
      () => {
        if (name === 'smartSearch') {
          if (value) {
            this.getPropertiesBySmartSearch();
          } else {
            this.filterProperties();
          }
        }
      }
    );
  }

  handleDateChangeOnFilterConditions = (name, date) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [name]: isNil(date) ? null : date.valueOf(),
      },
    }));
  };

  formatDate = date =>
    !isNil(date) && (isNumber(date) || isDate(date)) ? moment(date).format('DD/MM/YYYY') : date;

  addPropertyIdToVisited(id) {
    const { bondedDeal } = this.props;
    const { visitedProperties } = this.state;

    const dealId = get(bondedDeal, 'id');

    // Comes from properties page instead of deal search
    if (isEmpty(bondedDeal) || isEmpty(dealId)) {
      return this.setState({ visitedProperties: [...visitedProperties, id] });
    }

    const url = `${ENDPOINT_V2}/opportunities/${dealId}/properties/${id}`;

    api
      .request(url, 'post')
      .then(() => {
        this.setState({ visitedProperties: [...visitedProperties, id] });
      })
      .catch(err => {
        console.error(`Call to ${url} failed: ${err.message}`);
        this.setState({ visitedProperties: [...visitedProperties, id] });
      });
  }

  showPropertyDetails(id) {
    if (isEmpty(id)) {
      return;
    }

    window.open(`/propertyDetails/${id}`, '_blank');
  }

  showProviderDetails(id) {
    if (isEmpty(id)) {
      return;
    }

    browserHistory.push(`/providerDetails/${id}`);
  }

  handleCheckAvailability(providerId) {
    this.setState({
      consultedAvailabilityProviders: [...this.state.consultedAvailabilityProviders, providerId],
    });
  }

  cleanReferencePoint() {
    const mapComponent = this.refs.googleMapsMap;

    mapComponent.clearMarkers();
    mapComponent.setReferencePoint(null, null);
    this.initializeMap();
  }

  changeViewMode() {
    const { showMap: isTableVisible } = this.state;

    this.setState(
      {
        showMap: !isTableVisible,
      },
      () => {
        if (!isTableVisible) {
          const { propertySelected } = this.state;

          if (!isEmpty(propertySelected)) {
            const { visitedProperties } = this.state;

            visitedProperties.push(propertySelected);

            this.setState(
              {
                propertySelected,
              },
              () => this.filterProperties()
            );
          } else {
            this.filterProperties();
          }
        }
      }
    );

    this.isInfoWindowVisible(false);
  }

  handleSubmitFilter(e) {
    e.preventDefault();
    const { refHomyspace, refProvider } = this.state.filter;

    if (!isEmpty(refHomyspace) || !isEmpty(refProvider)) {
      this.setState({ filtering: true, filteringByRef: true }, this.initializeMap);
    } else {
      this.setState({ filtering: true }, this.initializeMap);
    }
  }

  toggleImport() {
    this.setState({
      modalImport: !this.state.modalImport,
    });
  }

  toggleImportStatus() {
    this.setState({
      modalImportStatus: !this.state.modalImportStatus,
    });
  }

  handleFileUploaded() {
    setTimeout(() => {
      this.setState({
        modalImport: !this.state.modalImport,
        modalImportStatus: !this.state.modalImporStatus,
      });
    }, 500);
  }

  onCloseBondedDealInfo() {
    this.filterProperties();
  }

  getProperty = async id => {
    try {
      const res = await api.getDetailData('property', id);
      const { property } = res.json;

      return property;
    } catch (e) {
      openNotificationWithIcon(
        'error',
        '😰 Siento fallarte',
        'No he podido obtener el Inmueble solicitado :('
      );
      console.error(e);
    }
  };

  isInfoWindowVisible = async (isVisible = false, data = {}) => {
    const property = isVisible ? await this.getProperty(data.id) : {};

    this.setState(prevState => ({
      property: {
        ...prevState.property,
        data: {
          ...property,
          distance: get(this.props, 'bondedDeal')
            ? get(data, 'distanceFromDesiredLocationInKm')
            : null,
        },
        visible: isVisible,
      },
      propertySelected: isVisible && get(property, 'id') ? get(property, 'id') : '',
    }));
  };

  handleImagesLoaded(images) {
    this.setState(prevState => ({
      property: {
        ...prevState.property,
        images,
      },
    }));
  }

  closeInfoWindow = () => {
    this.isInfoWindowVisible(false);
    const mapComponent = this.refs.googleMapsMap;

    if (mapComponent) {
      mapComponent.clearInfoWindowVisibility();
      this.filterProperties();
    }
  };

  render() {
    const {
      property,
      properties,
      showMap,
      visitedProperties,
      consultedAvailabilityProviders,
      propertySelected,
    } = this.state;
    const { bondedDeal } = this.props;

    const mapStyle = {
      width: '100%',
      height: tableUtils.getTableHeight() + (bondedDeal ? 200 : 160),
    };

    return (
      <Row className='animated fadeIn properties' gutter={8}>
        {/* {!isEmpty(bondedDeal) && (
          <Col span={5}>
            <div className='container resumen-oportunidad'>
              <DealInfo
                data={bondedDeal}
                bondedDealPreferences={bondedDealPreferences}
                onClose={e => {
                  this.setState(
                    {
                      loading: true,
                    },
                    () => {
                      this.props.actions.unbondDeal();
                      this.handleClearFilters();
                      this.handleSubmitFilter(e);
                      this.props.collapseSidebar(false);
                      this.props.navigateTo('properties');

                      this.setState({
                        consultedAvailabilityProviders: [],
                        loading: false,
                      });
                    }
                  );
                }}
              />
              <hr />
              <DataOfContact bondedDeal={bondedDeal} />
              <hr />
              {!isEmpty(bondedDeal.proposals) ? (
                <>
                  <h5>
                    <strong>Propuestas: {bondedDeal.proposals.length}</strong>
                  </h5>
                  <div style={{ maxHeight: '338px', overflowX: 'hidden', overflowY: 'auto' }}>
                    {bondedDeal.proposals.map((obj, i) => (
                      <>
                        <ProposalInfo
                          key={`key-${get(obj, 'proposal.id', i)}`}
                          bucket={get(obj, 'proposal.property.bucketName')}
                          proposal={obj.proposal}
                        />
                        <br />
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <h5 style={{ fontSize: '17.5px', marginBottom: '15px' }}>
                  <strong>Sin propuestas</strong>
                </h5>
              )}
            </div>
          </Col>
        )} */}
        <Col span={24}>
          {isEmpty(bondedDeal) && (
            <Row gutter={8} align='middle'>
              <Col xxl={15} xl={11} lg={9} md={24} sm={24} xs={24}>
                <Title level={2} className='title-properties'>
                  Inmuebles
                  {/* <img src='img/xmas/xmas-star.png' alt='xmas-star' height={50} /> */}
                </Title>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={6} sm={12} xs={24}>
                <Button
                  className='exportation'
                  icon='download'
                  onClick={() => this.filterProperties(true)}
                >
                  Exportar
                </Button>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={6} sm={12} xs={24}>
                <Button className='exportation' icon='upload' onClick={() => this.toggleImport()}>
                  Importar
                </Button>
                <Modal
                  title='Importar inmuebles'
                  visible={this.state.modalImport}
                  onCancel={this.toggleImport}
                  footer={null}
                >
                  <FormImportProperties callbackFuncionOnCreate={this.handleFileUploaded} />
                </Modal>
              </Col>
              <Col xxl={3} xl={4} lg={5} md={6} sm={12} xs={24}>
                <Button
                  className='exportation'
                  style={{ width: '100%' }}
                  onClick={() => this.toggleImportStatus()}
                >
                  Estado importación
                </Button>
                <Modal
                  width={920}
                  title='Estado de la importación de inmuebles'
                  visible={this.state.modalImportStatus}
                  onCancel={this.toggleImportStatus}
                  footer={null}
                >
                  <TableImportPropertiesStatus />
                </Modal>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={6} sm={12} xs={24}>
                <Button
                  className='btn-primary'
                  style={{ width: '100%' }}
                  onClick={this.toggle}
                  disabled={this.state.loading}
                >
                  Crear inmueble
                </Button>
                <Modal
                  title='Crear inmueble'
                  visible={this.state.modal}
                  onCancel={this.toggle}
                  footer={null}
                >
                  <FormNewProperty />
                </Modal>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24} className='row-filter-properties'>
              <PropertiesFilter
                showMap={this.state.showMap}
                filter={this.state.filter}
                handleChange={this.handleChangeOnFilterConditions}
                handleDateChange={this.handleDateChangeOnFilterConditions}
                handleLocationChange={this.handlePlaceChangedOnFilterConditions}
                loading={this.state.loading}
                handleSubmitFilter={this.handleSubmitFilter}
                handleFilterCollapse={this.handleFilterCollapse}
                changeViewMode={this.changeViewMode}
                handleClearFilters={this.handleClearFilters}
                disableSmartSearch={this.disableSmartSearch}
                showSmartSearch={!isEmpty(bondedDeal)}
                propertiesCount={this.state.propertiesCount}
                propertiesTotalCount={this.state.totalCount}
              />
            </Col>
          </Row>
          {/* <Row className='hs-row'>
            <Col span={24}>
              <BondedDealInfo onClose={this.onCloseBondedDealInfo} />
              {this.state.maximumPropertiesExceeded && (
                <Alert
                  message={Properties.MAXIMUM_PROPERTIES_EXCEEDED_MESSAGE_TITLE}
                  description={Properties.MAXIMUM_PROPERTIES_EXCEEDED_MESSAGE_DESCRIPTION}
                  type='warning'
                  showIcon
                />
              )}
            </Col>
          </Row> */}
          <Row className='row-table-properties hs-row' gutter={8}>
            <Col span={24} className={`${!showMap ? 'row' : ''}`}>
              <div className={!this.state.showMap ? 'displayNone' : ''}>
                <GoogleMapsMap
                  mapStyle={mapStyle}
                  viewChangeCallback={this.filterProperties}
                  ref='googleMapsMap'
                  loadCallback={this.initializeMap}
                  initializeLocation
                  showInfoWindow={this.isInfoWindowVisible}
                  addPropertyIdToVisited={this.addPropertyIdToVisited}
                  propertiesVisited={visitedProperties}
                  consultedAvailabilityProviders={consultedAvailabilityProviders}
                  getProperty={this.getProperty}
                  propertySelected={property.data.id}
                  bondedDealProposals={get(bondedDeal, 'proposals')}
                />
              </div>
              <div
                className={`${!showMap && property.visible ? 'col-9 no-paddings' : ''} ${
                  !showMap && !property.visible ? 'col-12 no-paddings' : ''
                }
                ${showMap ? 'displayNone' : ''} `}
              >
                <TableDataProperties
                  properties={properties}
                  filter={this.state.filter}
                  loading={this.state.loading}
                  loadInfoWindow={this.isInfoWindowVisible}
                  addPropertyIdToVisited={this.addPropertyIdToVisited}
                  consultedAvailabilityProviders={consultedAvailabilityProviders}
                  propertySelected={propertySelected}
                  propertiesVisited={visitedProperties}
                  getProperty={this.getProperty}
                  bondedDeal={bondedDeal}
                />
              </div>
              {!isEmpty(property.data) && property.visible && (
                <div
                  style={!isEmpty(property.data) ? { display: 'inline' } : {}}
                  className={
                    'property-info-div ' + (property.visible ? 'slide-left' : 'slide-right')
                  }
                >
                  <>
                    <PropertyInfo
                      height={mapStyle.height}
                      data={property.data}
                      // opportunity={this.setOpportunityInfo()}
                      handleCheckAvailability={this.handleCheckAvailability}
                      showPropertyDetails={this.showPropertyDetails}
                      showProviderDetails={this.showProviderDetails}
                      close={this.closeInfoWindow}
                      options={this.state.options}
                      bondedDeal={this.props.bondedDeal}
                      bondedDealProposals={get(bondedDeal, 'proposals')}
                      propertiesFilter={this.state.filter}
                    />
                  </>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <style jsx>
          {`
            .property-info-div {
              position: absolute;
              top: 10px;
              right: 12px;
            }

            .property-with-bonded-div {
              top: 10px;
            }

            .properties {
              margin: 4px !important;
            }

            .title-properties {
              margin: 0 !important;
            }

            .ant-form-vertical .ant-form-item {
              padding-bottom: 0 !important;
            }

            .row-filter-properties {
              margin-top: 10px;
            }

            .no-paddings {
              padding: 0;
            }

            .no-margins {
              margin: 0;
            }

            .slide-left {
              -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            }

            .hs-row {
              margin-bottom: 15px;
            }

            /* ----------------------------------------------
            * Generated by Animista on 2019-4-22 13:45:17
            * w: http://animista.net, t: @cssanimista
            * ---------------------------------------------- */

            /**
            * ----------------------------------------
            * animation slide-left
            * ----------------------------------------
            */
            @-webkit-keyframes slide-left {
              0% {
                -webkit-transform: translateX(300px);
                transform: translateX(300px);
              }
              100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
              }
            }
            @keyframes slide-left {
              0% {
                -webkit-transform: translateX(300px);
                transform: translateX(300px);
              }
              100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
              }
            }

            .slide-right {
              -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            }

            /* ----------------------------------------------
            * Generated by Animista on 2019-4-22 15:38:38
            * w: http://animista.net, t: @cssanimista
            * ---------------------------------------------- */

            /**
            * ----------------------------------------
            * animation slide-right
            * ----------------------------------------
            */
            @-webkit-keyframes slide-right {
              0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
              }
              100% {
                -webkit-transform: translateX(450px);
                transform: translateX(450px);
              }
            }
            @keyframes slide-right {
              0% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
              }
              100% {
                -webkit-transform: translateX(450px);
                transform: translateX(450px);
              }
            }

            .widthTransition {
              -webkit-transition: width 400ms ease; /* Safari */
              transition: width 400ms ease;
              -moz-transition: width 400ms ease;
              -o-transition: width 400ms ease;
            }
          `}
        </style>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.Properties.metasource,
    properties: state.Properties.properties,
    //propertyLocation: state.Properties.propertyLocation.location,
    filter: state.Properties.filter,
    externalFilter: state.Properties.externalFilter,
    exportation: state.Properties.exportation,
    bondedDeal: state.DealDetails.bondedDeal,
    optionLabels: state.Preload.optionLabels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      exportFilteredProperties: (maxPrice, guest, verified, comissionable, filter) =>
        dispatch(exportFilteredProperties(maxPrice, guest, verified, comissionable, filter)),
      getPropertiesInMapFilteredByReference: (type, reference, filter) =>
        dispatch(getPropertiesInMapFilteredByReference(type, reference, filter)),
      propertiesFilter: filter => dispatch(propertiesFilter(filter)),
      // propertiesInMapFilteredSuccess: (properties, filter) =>
      //   dispatch(propertiesInMapFilteredSuccess(properties, filter)),
      getNewPropertyLocation: () => dispatch(getNewPropertyLocation()),
      saveActiveBusinessData: activeBusiness => dispatch(saveActiveBusinessData(activeBusiness)),
      unbondDeal: (url, data) => dispatch(dealDetailsUnbonded(url, data)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
