import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  getContactsList,
  getMetasourceLink,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import { getContactsListData, resetContactsList } from '../../modules/Contacts/ContactsActions';
import { showOrLoadContactDetailsView } from '../../modules/ContactDetails/ContactDetailsActions';
import isEmpty from 'lodash/isEmpty';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 handleChange -> function to be called when active state change
 isRequired -> boolean to indicate if component is required
 label -> Text to show
 value -> select's value
 selectedContact -> select's object value
 prefilter -> prefilter to apply besides search string
 name -> name of the component
 id -> id of the componen
 filterByDeal -> if true, the select will call to endpoint defined
 by the metasource:
 CONTACT_MAIN_METASOURCE_CONTACTS_BY_COMPANY_AND_DEAL
 Else:
 CONTACT_MAIN_METASOURCE_CONTACTS_PAGINATED
 contactList -> if defined, the select only shows this array of contacts
 ******************************************************* */

class SelectContact extends Component {
  static MINIMUM_CHARS_TO_SEARCH = 3;

  static INIT_MODE_WITH_CONTACT_ID = 'WITH_CONTACT_ID';

  static INIT_MODE_WITH_CONTACT_OBJECT = 'WITH_CONTACT_OBJECT';

  static INIT_MODE_WITH_NO_PARAMS = 'WITH_NO_PARAMS';

  static TRANSACTION_LOAD_NEW_CONTACT_LIST = 'LOAD_NEW_CONTACT_LIST';

  static TRANSACTION_LOAD_NEW_CONTACT = 'LOAD_NEW_CONTACT';

  constructor(props) {
    super(props);
    if (props.filterByDeal) {
      this.URL_CONTACTS_PAGINATED = getMetasourceLink(
        this.props.contactsMetasource,
        this.props.metasourceStrings,
        'CONTACT_MAIN_METASOURCE_CONTACTS_BY_COMPANY_AND_DEAL'
      ).href;
    } else {
      this.URL_CONTACTS_PAGINATED = getMetasourceLink(
        this.props.contactsMetasource,
        this.props.metasourceStrings,
        'CONTACT_MAIN_METASOURCE_CONTACTS_PAGINATED'
      ).href;
    }

    if (props.prefilter) {
      this.URL_CONTACTS_PAGINATED += props.prefilter;
    }

    this.CURRENT_TRANSACTION = null;
    this.INIT_MODE = SelectContact.INIT_MODE_WITH_NO_PARAMS;
    let theValue = null;
    let theContactData = null;

    if (props.selectedContact) {
      theValue = props.selectedContact.id;
      theContactData = { detail: props.selectedContact, error: null };
      this.INIT_MODE = SelectContact.INIT_MODE_WITH_CONTACT_OBJECT;
    } else if (props.value) {
      theValue = props.value;
      this.INIT_MODE = SelectContact.INIT_MODE_WITH_CONTACT_ID;
    }

    this.state = {
      value: theValue,
      options: !isEmpty(props.contactList) ? getContactsList(props.contactList) : [],
      isLoading: false,
      contactData: theContactData,
      contactsList: props.contactList || [],
    };
    this.initialContactsList = props.contactList || [];
    this.firstLoad = true;

    this.setSelectedContactInState = this.setSelectedContactInState.bind(this);
    this.setStateToNull = this.setStateToNull.bind(this);
    this.getContactData = this.getContactData.bind(this);
    this.loadDefaultList = this.loadDefaultList.bind(this);
    this.getContactsListData = this.getContactsListData.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getItemInList = this.getItemInList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.mergeContacts = this.mergeContacts.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.INIT_MODE === SelectContact.INIT_MODE_WITH_NO_PARAMS) {
      this.loadDefaultList();
    } else if (this.INIT_MODE === SelectContact.INIT_MODE_WITH_CONTACT_ID) {
      this.getContactData(this.state.value);
    } else if (this.INIT_MODE === SelectContact.INIT_MODE_WITH_CONTACT_OBJECT) {
      this.setSelectedContactInState(this.state.contactData.detail);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.CURRENT_TRANSACTION === SelectContact.TRANSACTION_LOAD_NEW_CONTACT_LIST) {
      if (nextProps.contactsList) {
        if (nextProps.contactsList.error) {
          openNotificationWithIcon(
            'error',
            'Ha ocurrido un error al cargar la lista de contactos',
            nextProps.contactsList.errorDetail
          );
          this.setStateToNull();
        } else {
          let list;

          if (this.props.id) {
            list = nextProps.contactsList[this.props.id];
          } else {
            list = nextProps.contactsList.list;
          }

          if (list && this.props.extraContacts) {
            list = this.mergeContacts(list, this.props.extraContacts);
          }

          const contactList = getContactsList(list);

          // const {list} = nextProps.contactsList;
          // if (list && this.props.extraContacts) {
          //   list.push(...this.props.extraContacts);
          // }
          // let contactList = getContactsList(list);
          // if (this.props.id) {
          //   contactList = getContactsList(nextProps.contactsList[this.props.id]);
          // }

          // let list = getContactsList(nextProps.contactsList.list);
          // if (this.props.id) {
          //   list = getContactsList(nextProps.contactsList[this.props.id]);
          // }
          if (this.firstLoad) {
            this.initialContactsList = contactList;
            this.firstLoad = false;
          }

          this.setState({
            value: null,
            options: contactList,
            isLoading: false,
            contactData: null,
            contactsList: this.props.id
              ? nextProps.contactsList[this.props.id]
              : nextProps.contactsList.list,
          });
        }
      }
    } else if (this.CURRENT_TRANSACTION === SelectContact.TRANSACTION_LOAD_NEW_CONTACT) {
      if (nextProps.contactData.error) {
        openNotificationWithIcon(
          'error',
          'Ha ocurrido un error al cargar el contacto',
          nextProps.contactData.errorDetail
        );
        this.setStateToNull();
      } else {
        if (
          JSON.stringify(nextProps.contactData.detail) !== JSON.stringify(this.state.contactData)
        ) {
          this.setSelectedContactInState(nextProps.contactData.detail);
        }
      }
    }

    if (this.props.id && nextProps.contactsList) {
      if (this.props.id === nextProps.contactsList.componentId) {
        this.CURRENT_TRANSACTION = null;
      }
    } else if (
      this.CURRENT_TRANSACTION === SelectContact.TRANSACTION_LOAD_NEW_CONTACT &&
      nextProps.contactData.detail.id
    ) {
      this.CURRENT_TRANSACTION = null;
    }
  }

  componentWillUnmount() {
    this.props.actions.resetContactsList();
  }

  mergeContacts(listOne, listTwo) {
    const newList = [];

    newList.push(...listOne);
    newList.push(...listTwo);
    for (let i = 0; i < newList.length; ++i) {
      for (let j = i + 1; j < newList.length; ++j) {
        if (newList[i].contact.id === newList[j].contact.id) {
          newList.splice(j--, 1);
        }
      }
    }

    return newList;
  }

  setSelectedContactInState(contactDetail) {
    let labelValue = '';

    if (contactDetail.contactType === 'PERSON') {
      labelValue = `${
        contactDetail.relatedProvider
          ? '(Proveedor) - '
          : contactDetail.relatedCompany
          ? '(Cliente) - '
          : ''
      }(Persona física) - ${contactDetail.name ? contactDetail.name : '[Sin nombre]'}`;
      labelValue += ` ${contactDetail.lastOTradeName || '[Sin apellido]'}`;
    } else {
      labelValue += `${
        contactDetail.relatedProvider
          ? '(Proveedor) - '
          : contactDetail.relatedCompany
          ? '(Cliente) - '
          : ''
      }(Persona jurídica) - ${contactDetail.lastOTradeName ||
        (contactDetail.name ? contactDetail.name : '[Sin nombre]')}`;
    }

    this.setState(
      {
        value: contactDetail.id,
        options: [
          {
            label: labelValue,
            value: contactDetail.id,
          },
        ],
        isLoading: false,
        contactData: contactDetail,
        contactsList: [
          {
            label: labelValue,
            value: contactDetail.id,
            contact: contactDetail,
          },
        ],
      },
      () => {
        if (this.props.handleChange) {
          this.props.handleChange(
            '',
            {
              label: labelValue,
              value: contactDetail.id,
            },
            contactDetail
          );
        }
      }
    );
  }

  setStateToNull() {
    this.setState({
      value: null,
      options: [],
      isLoading: false,
      contactData: null,
      contactsList: [],
    });
  }

  getContactData() {
    this.CURRENT_TRANSACTION = SelectContact.TRANSACTION_LOAD_NEW_CONTACT;
    this.setState({ isLoading: true });
    this.props.actions.getContactData(this.state.value);
  }

  loadDefaultList() {
    this.getContactsListData();
  }

  getContactsListData(searchString) {
    this.CURRENT_TRANSACTION = SelectContact.TRANSACTION_LOAD_NEW_CONTACT_LIST;
    let urlString = this.URL_CONTACTS_PAGINATED;

    // if (searchString && !this.props.filterByDeal) {
    //   urlString += `&globalSearch=${searchString
    //     .replace('&', '%26')
    //     .replace('=', '%3D')
    //     .replace('+', '%2B')}`;
    // } else if (!searchString && !this.props.filterByDeal && this.props.companyId) {
    //   urlString += `&companyId=${this.props.companyId}`;
    // }

    // this.props.actions.getContactsListData({ href: urlString }, this.props.id);

    if (isEmpty(this.props.contactList)) {
      this.setState({ isLoading: true });
      if (searchString && !this.props.filterByDeal) {
        urlString += `&globalSearch=${searchString
          .replace('&', '%26')
          .replace('=', '%3D')
          .replace('+', '%2B')}`;
      } else if (!searchString && !this.props.filterByDeal && this.props.companyId) {
        urlString += `&companyId=${this.props.companyId}`;
      }

      this.props.actions.getContactsListData({ href: urlString }, this.props.id);
    }
    // else {
    //   getContactList(this.state.contactList);
    // }
  }

  handleSelectChange(selectedOption) {
    let selectedObject = null;

    if (selectedOption) {
      selectedObject = this.getItemInList(selectedOption.value);
      this.setState({
        value: selectedOption,
        options: [selectedOption],
        isLoading: false,
        contactData: selectedObject,
        contactsList: [selectedObject],
      });
    } else {
      this.loadDefaultList();
    }

    this.props.handleChange(this.props.name, selectedOption, selectedObject);
  }

  getItemInList(id) {
    if (this.state.contactsList) {
      for (let d = 0; d < this.state.contactsList.length; d++) {
        if (this.state.contactsList[d].contact && this.state.contactsList[d].contact.id === id) {
          return this.state.contactsList[d].contact;
        }

        if (this.state.contactsList[d].id === id) {
          return this.state.contactsList[d];
        }
      }
    } else {
      return null;
    }
  }

  handleInputChange(value) {
    if (value.length >= SelectContact.MINIMUM_CHARS_TO_SEARCH) {
      this.getContactsListData(value);
    } else if (value.length !== 0) {
      this.setState({
        options: [],
      });
    }
  }

  render() {
    return (
      <div className='form-group'>
        <label htmlFor='contactDetailContact' className={this.props.isRequired ? 'required' : ''}>
          {this.props.label ? this.props.label : 'Contacto'}
        </label>
        {this.props.filterByDeal && (
          <Select
            name={this.props.name}
            id={this.props.id}
            disabled={this.props.disabled}
            className={`form-control ${this.props.isRequired ? 'validate validate-required' : ''}`}
            value={this.state.value ? this.state.value : ''}
            options={this.state.options}
            onChange={value => {
              this.handleSelectChange(value);
            }}
            isLoading={this.state.isLoading}
            noResultsText='No hay resultados'
            placeholder='Escribe para empezar a filtrar...'
          />
        )}
        {!this.props.filterByDeal && (
          <Select
            filterOptions={options => options}
            name={this.props.name}
            id={this.props.id}
            disabled={this.props.disabled}
            className={`form-control ${this.props.isRequired ? 'validate validate-required' : ''}`}
            value={this.state.value ? this.state.value : ''}
            options={this.state.options}
            onChange={value => {
              this.handleSelectChange(value);
            }}
            onInputChange={value => {
              this.handleInputChange(value);
            }}
            isLoading={this.state.isLoading}
            noResultsText='No hay resultados'
            placeholder='Escribe para empezar a filtrar...'
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactsList: state.Contacts.contactsList,
    contactsMetasource: state.Contacts.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    contactData: state.ContactDetails.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getContactsListData: (url, componentId) => dispatch(getContactsListData(url, componentId)),
      getContactData: id => dispatch(showOrLoadContactDetailsView(id, null, false, true)),
      resetContactsList: () => dispatch(resetContactsList()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectContact);
