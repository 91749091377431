import { InformationBlock } from 'homy-ui';
import React, { useState, useEffect } from 'react';
import SlideDown from 'react-slidedown';
import api from '../../shared/utils/api';
import get from 'lodash/get';

const ReusedProposaInfo = ({ rootProposalId }) => {
  const [moreInfo, setMoreInfo] = useState(false);
  const [opportunityId, setOpportunityId] = useState();
  const [opportunityCode, setOpportunityCode] = useState();

  useEffect(() => {
    const fetchReusedProposalInfo = async id => {
      try {
        const res = await api.getDetailData('proposal', id);
        const proposal = get(res, 'json.proposal');
        setOpportunityCode(get(proposal, 'dealIdentificationCode'));
        setOpportunityId(get(proposal, 'dealId'));
      } catch (e) {
        console.error('ReusedProposalInfo: error fetching info for reused proposal', e);
      }
    };
    if (rootProposalId) {
      fetchReusedProposalInfo(rootProposalId);
    }
  }, [rootProposalId]);

  return (
    <InformationBlock type='warning' icon={['fas', 'info-circle']}>
      Esta es una propuesta reutilizada proveniente de otra oportunidad de características similares
      {opportunityId ? ': ' : '.'}
      {opportunityId && (
        <a
          href={`/dealDetails/${opportunityId}`}
          target='_blank'
          rel='noopener noreferrer'
          // onClick={e => {
          //   e.preventDefault();
          //   browserHistory.push(`/dealDetails/${opportunityId}`);
          // }}
        >
          {opportunityCode}
        </a>
        // <Link
        //   activeClassName='active'
        //   onClick={() => browserHistory.push(`/dealDetails/${opportunityId}`)}
        // >
        //   <span>{opportunityCode}</span>
        // </Link>
      )}
      <br />
      <a
        href=''
        onClick={e => {
          e.preventDefault();
          setMoreInfo(!moreInfo);
        }}
      >
        {moreInfo ? '- info' : '+ info'}
      </a>
      <SlideDown>
        {moreInfo && (
          <div>
            <br />
            <p>
              Esto quiere decir que es una propuesta para la cual{' '}
              <b>ya se ha comprobado disponibilidad</b> y que se ha copiado de otra oportunidad con
              destino, fechas, inquilinos y presupuesto similares. Sin embargo, es posible que
              quieras consultar algún detalle con el proveedor antes de darla por válida.
            </p>
            <p>
              Si esta propuesta te resulta adecuada para esta oportunidad, solo tienes que
              enviársela al cliente. Si no, puedes eliminarla.
            </p>
          </div>
        )}
      </SlideDown>
    </InformationBlock>
  );
};
export default ReusedProposaInfo;
