import { startsWith } from 'lodash';

export const sanitizeMobilePrefix = tel => {
  let telephoneWithPrefix;

  if (tel.charAt(0) === '+') {
    telephoneWithPrefix = tel.substring(1);
  } else if (tel.startsWith('34')) {
    telephoneWithPrefix = tel;
  } else {
    telephoneWithPrefix = addSpanishMobilePrefix(tel);
  }

  return telephoneWithPrefix.trim();
};

const addSpanishMobilePrefix = tel => `34${tel}`;

export const isMobilePhoneNumber = phone =>
  startsWith(phone, '6') ||
  startsWith(phone, '7') ||
  startsWith(phone, '+346') ||
  startsWith(phone, '+347') ||
  startsWith(phone, '346') ||
  startsWith(phone, '347');
