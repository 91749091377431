import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Progress } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { DATE_FORMAT } from '../../shared/appConfig';
import { getDealDetailsData } from '../../modules/DealDetails/DealDetailsActions';
import {
  getEndingPercentDealStage,
  getOptionsForSelect,
  getTextValueFromSelectOptions,
} from '../../shared/utils/appUtils';
import FormDealDetails from '../FormDealDetails';
import { browserHistory } from 'react-router';

// PARAMETROS A CONFIGURAR
// leftOffset -> columnas bootstrap a desplazer el botón
// withSelfLink -> boolean to indicate if we need to follow the self link

class InfoButtonDealDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDealDetail: false,
      dealDetails: this.props.dealDetailsData.detail,
      dealDetailsMetasourceLinkReduced: this.props.dealDetailsMetasourceLinkReduced,
      options: {
        rentalRequestStage: getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage'),
      },
    };
    this.showDealDetail = this.showDealDetail.bind(this);
    this.showDealDetailsView = this.showDealDetailsView.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dealDetailsData && this.state.dealDetails !== nextProps.dealDetailsData.detail) {
      this.setState({ dealDetails: nextProps.dealDetailsData.detail });
    }

    if (
      JSON.stringify(this.state.dealDetailsMetasourceLinkReduced) !==
      JSON.stringify(nextProps.dealDetailsMetasourceLinkReduced)
    ) {
      // Si cambia el enlace del recurso, cargamos de nuevo los datos del recurso
      this.setState(
        {
          dealDetailsMetasourceLinkReduced: nextProps.dealDetailsMetasourceLinkReduced,
        },
        () => this.props.actions.getDealDetailsData()
      );
    }
  }

  componentDidUpdate() {
    if (document.getElementById('infoButtonDealDetails')) {
      if (this.state.showDealDetail) {
        document.getElementById('infoButtonDealDetails').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonDealDetails').style.zIndex = 1;
      }
    }
  }

  showDealDetail(event) {
    if (event.target.nodeName !== 'A') {
      const showOrHide = !this.state.showDealDetail;

      this.setState({
        showDealDetail: showOrHide,
      });
    }
  }

  showDealDetailsView(event) {
    if (this.props.withSelfLink) {
      event.preventDefault();
      browserHistory.push(`/dealDetails/${this.state.dealDetails.id}`);
    }
  }

  render() {
    let leftOffset = 0;

    if (this.props.leftOffset) {
      leftOffset = parseInt(this.props.leftOffset, 10) * -20;
    }

    const styleInfoButtonDetail = {
      marginLeft: `${leftOffset}%`,
    };

    return (
      <div id='infoButtonDealDetails' className='col-sm-5 noPadding'>
        {this.props.dealDetailsData.error && (
          <Alert
            message='Error'
            description={this.props.dealDetailsData.errorDetail}
            type='error'
            showIcon
          />
        )}

        <div
          className='card card-inverse dealBackgroundColor infoButton'
          onClick={this.showDealDetail}
        >
          {this.state.dealDetails && (
            <div className='card-block paddingInfoButtonDeal'>
              <div className='row'>
                <div className='col-sm-2 fontSizeMax alignCenter'>
                  {this.state.dealDetails.rentalRequestType}
                </div>
                <div className='col-sm-10 floatLeft'>
                  <div className='row'>
                    <div
                      className='h6 m-0 bold linkBehavior col-sm-12'
                      data-test-id='dealId'
                      onClick={this.showDealDetailsView}
                    >
                      {this.state.dealDetails.identificationCode}
                    </div>
                    <div className='col-sm-12' data-test-id='dealDates'>
                      <i className='fa fa-calendar' /> &nbsp; &nbsp;{' '}
                      {this.state.dealDetails.rentalStartDate &&
                        moment(this.state.dealDetails.rentalStartDate).format(DATE_FORMAT)}
                      -{' '}
                      {this.state.dealDetails.rentalEndDate &&
                        moment(this.state.dealDetails.rentalEndDate).format(DATE_FORMAT)}
                    </div>
                    <div className='col-sm-6' data-test-id='dealAddress'>
                      <i className='fa fa-map-marker' /> &nbsp; &nbsp;{' '}
                      {this.state.dealDetails.address &&
                      this.state.dealDetails.address.administrativeAreaLevel2
                        ? `${this.state.dealDetails.address.administrativeAreaLevel2}, `
                        : ''}
                      {this.state.dealDetails.address &&
                      this.state.dealDetails.address.administrativeAreaLevel1
                        ? this.state.dealDetails.address.administrativeAreaLevel1
                        : ''}
                    </div>
                    <div className='col-sm-6' data-test-id='dealPax'>
                      <i className='fa fa-bed' /> &nbsp;{' '}
                      {this.state.dealDetails.numberOfPeople
                        ? this.state.dealDetails.numberOfPeople
                        : 'Sin'}
                      {' PAX'}
                    </div>
                    <div className='col-sm-6' data-test-id='dealClientTax'>
                      <div>
                        <i className='fa fa-money' /> &nbsp;{' '}
                        {`Cliente: ${
                          this.state.dealDetails.customerRate
                            ? `${numeral(this.state.dealDetails.customerRate.amount).format(
                                '0,00'
                              )} ${this.state.dealDetails.customerRate.currency}`
                            : 'No hay "Tarifa de Cliente" actualmente'
                        }`}
                      </div>
                    </div>
                    <div className='col-sm-6' data-test-id='dealProviderTax'>
                      <i className='fa fa-money' /> &nbsp;{' '}
                      {`Proveedor: ${
                        this.state.dealDetails.providerRate
                          ? `${numeral(this.state.dealDetails.providerRate.amount).format(
                              '0,00'
                            )} ${this.state.dealDetails.providerRate.currency}`
                          : ''
                      }`}
                    </div>
                    <div className='col-sm-12' data-test-id='dealStatus'>
                      <Progress
                        status='active'
                        showInfo={false}
                        percent={getEndingPercentDealStage(
                          this.state.dealDetails.rentalRequestStage,
                          this.state.options.rentalRequestStage
                        )}
                      />
                      <b>
                        {this.state.dealDetails.rentalRequestStage
                          ? getTextValueFromSelectOptions(
                              this.state.dealDetails.rentalRequestStage,
                              this.state.options.rentalRequestStage
                            )
                          : ''}
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.showDealDetail && (
                <div className='openIcon'>
                  <i className='fa fa-caret-down fa-2x' />
                </div>
              )}
            </div>
          )}
        </div>

        {this.state.showDealDetail && (
          <div
            style={styleInfoButtonDetail}
            className='infoButtonDetail cardNoMargin noPadding animated fadeIn dealBorderTopColor'
          >
            <div className='card'>
              <div className='card-block'>
                <FormDealDetails currentUsername={this.props.currentUsername} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getDealDetailsData: () => dispatch(getDealDetailsData()),
    },
  };
}

function mapStateToProps(state) {
  return {
    dealDetailsData: state.DealDetails.data,
    dealDetailsMetasourceLinkReduced: state.DealDetails.metasource
      ? state.DealDetails.metasource.links.reduced
      : '',
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButtonDealDetails);
