import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class GenericThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        props.location.query.message && props.location.query.message !== ''
          ? props.location.query.message
          : '¡Gracias!<br/>Tu solicitud ha sido enviada',
    };
  }

  render() {
    return (
      <div className='app flex-row align-items-center'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 loadingCard'>
          <div className='shadow' style={{ width: 40 + 'vw' }}>
            <div className='bg-primary p-2'>
              <div className='logoHs'>&nbsp;</div>
            </div>
            {/* <div className='p-3 alignCenter' style={{backgroundColor:'white'}} dangerouslySetInnerHTML={{__html: this.state.message}}/> */}
            <div className='p-3 alignCenter' style={{ backgroundColor: 'white' }}>
              <br />
              <h5>{ReactHtmlParser(this.state.message)}</h5>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GenericThankYouPage;
