import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Modal, Row, Typography } from 'antd';
import TableDataProviders from '../../components/TableDataProviders';
import ProvidersFilter from './ProvidersFilter';
import FormNewProvider from '../../components/FormNewProvider';
import { getNewProviderLocation } from '../../modules/Providers/ProvidersActions';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import api from '../../shared/utils/api';
import tableUtils from '../../shared/utils/tableUtils';
import isEqual from 'lodash/isEqual';

const { Title } = Typography;

class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        data: [],
      },
      filter: {
        ...props.filter,
        showMoreFilters: false,
      },
      parsedFilters: {},
      sorter: '',
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      modal: false,
      loading: true,
      providerLocation: props.providerLocation,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangeOnFilterConditions = this.handleChangeOnFilterConditions.bind(this);
    this.handleChangeLocalityFilter = this.handleChangeLocalityFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleFilterCollapse = this.handleFilterCollapse.bind(this);
    this.handleExportation = this.handleExportation.bind(this);
  }

  componentDidMount() {
    this.handleSubmit();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.providerLocation !== newProps.providerLocation) {
      this.setState(
        {
          providerLocation: newProps.providerLocation,
        },
        () => this.toggle()
      );
    }
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handleFilterCollapse() {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        showMoreFilters: !prevState.filter.showMoreFilters,
      },
    }));
  }

  handlePaginationChange = (page, pageSize) => {
    const { pagination } = this.state;

    this.setState(
      {
        loading: true,
        pagination: {
          ...pagination,
          pageSize,
          page,
        },
      },
      () => this.handleSubmit()
    );
  };

  filterData() {
    const urlFilters = {};
    const { filter } = this.state;

    urlFilters.name = filter.name || undefined;
    urlFilters.lastOTradeName = filter.lastOTradeName || undefined;
    urlFilters.email = filter.email || undefined;
    urlFilters.identificationCode = filter.identificationCode || undefined;
    urlFilters.phoneNumber = filter.phoneNumber || undefined;
    urlFilters.locality = (filter.locality && filter.locality.locality) || undefined;
    urlFilters.billable = filter.billable ? true : undefined;

    return urlFilters;
  }

  handleChangeOnFilterConditions(e) {
    const { filter } = this.state;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    filter[e.target.name] = value;
    this.setState({ filter });
  }

  handleChangeLocalityFilter(locality) {
    const { filter } = this.state;

    filter.locality = locality;
    this.setState({ filter });
  }

  handleSubmit() {
    const { pagination, sorter } = this.state;
    const filters = this.state.parsedFilters;
    const params = {
      pagination,
      filters,
      sorter,
    };

    api
      .getTableDataV2('providers', params)
      .then(res => {
        this.setState({
          loading: false,
          table: {
            data: res.json.searchItems,
          },
          pagination: {
            ...pagination,
            totalCount: parseInt(res.json.totalHits, 10) || 0,
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de procesar datos en los Proveedores... ${err.message}`
        );
      });
  }

  sorterData = sorter => {
    const condition = tableUtils.getSortCondition(sorter, 'providers');

    this.setState(
      {
        sorter: condition,
        loading: true,
      },
      () => this.handleSubmit()
    );
  };

  handleFilterSubmit = e => {
    e.preventDefault();

    const { pagination } = this.state;

    const filters = this.filterData();

    if (!isEqual(filters, this.state.parsedFilters)) {
      this.setState(
        {
          loading: true,
          pagination: {
            ...pagination,
            page: 1,
          },
          parsedFilters: filters,
        },
        () => {
          this.handleSubmit();
        }
      );
    }
  };

  handleExportation() {
    const filters = this.filterData();

    api
      .getExportToExcelData('provider', filters)
      .then(res => {
        openNotificationWithIcon(
          'info',
          <span role='img' aria-label='ok'>
            Exportación realizada con éxito &#128076;
          </span>,
          res.message
        );
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          <span aria-label='error'>El servidor está ocupado</span>,
          'En este momento se está generando un informe para otro agente. Inténtalo de nuevo dentro de unos minutos.'
        );

        console.error('Error in exportation', err);
      });
  }

  render() {
    const { metasource, actions } = this.props;
    const { modal, filter, table, loading, pagination } = this.state;

    return (
      <div className='animated fadeIn providers'>
        {metasource.error && (
          <Alert
            message='Error'
            description={metasource.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}

        <Row align='middle' gutter={8}>
          <Col xxl={20} xl={18} lg={12} md={12} sm={24} xs={24}>
            <Title level={2} className='title-providers'>
              Proveedores
              {/* <img src='img/xmas/mistletoe.png' alt='mistletoe' height={50} /> */}
            </Title>
          </Col>
          <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
            <Button className='exportation' icon='download' onClick={this.handleExportation}>
              Exportar
            </Button>
          </Col>
          <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
            <Button
              style={{ width: '100%' }}
              className='btn-primary'
              onClick={() => actions.getNewProviderLocation()}
            >
              Crear proveedor
            </Button>
            <Modal title='Nuevo proveedor' visible={modal} onCancel={this.toggle} footer={null}>
              <FormNewProvider />
            </Modal>
          </Col>
        </Row>
        <Row className='row-providers'>
          <ProvidersFilter
            filter={filter}
            handleFilter={this.handleFilterSubmit}
            handleChangeOnFilterConditions={this.handleChangeOnFilterConditions}
            handleChangeLocalityFilter={this.handleChangeLocalityFilter}
            handleFilterCollapse={this.handleFilterCollapse}
            loading={loading}
          />
        </Row>
        <Row className='row-providers'>
          <TableDataProviders
            data={table.data}
            loading={loading}
            pagination={pagination}
            handleSorter={this.sorterData}
            handlePaginationChange={this.handlePaginationChange}
            handlePaginationSizeChange={this.handlePaginationChange}
          />
        </Row>
        <style jsx>
          {`
            .providers {
              margin: 10px;
            }
            .title-providers {
              margin: 0;
            }
            .row-providers {
              margin-top: 10px;
            }
            .provider-btn-font {
              color: #212529;
            }
            .icon-color > i {
              color: red;
            }
            .slide-down-row {
              overflow-x: hidden;
              overflow-y: hidden;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.Providers.metasource,
    filter: state.Providers.filter,
    providerLocation: state.Providers.providerLocation.location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getNewProviderLocation: () => {
        dispatch(getNewProviderLocation());
      },
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
