import AWS from 'aws-sdk/global';
import { browserHistory } from 'react-router';
import {
  PROPOSALDETAILS_CONTRACTDATA_ERROR,
  PROPOSALDETAILS_CONTRACTDATA_RESET_ERROR,
  PROPOSALDETAILS_CONTRACTDATA_SUCCESS,
  PROPOSALDETAILS_DATA_ERROR,
  PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_ERROR,
  PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_SUCCESS,
  PROPOSALDETAILS_DATA_SUCCESS,
  PROPOSALDETAILS_DELETE_ERROR,
  PROPOSALDETAILS_DELETE_SUCCESS,
  PROPOSALDETAILS_SUBMIT_ERROR,
  PROPOSALDETAILS_SUBMIT_SUCCESS,
  PROPOSALDETAILS_SUBMITTING,
} from '../../shared/actionTypes';
import ProposalDetailsApi from './ProposalDetailsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import ProposalsApi from '../Proposals/ProposalsApi';
import { getDealDetailsData } from '../DealDetails/DealDetailsActions';
import { getCurrentUserInformationSuccess } from '../Preload/PreloadActions';
import { getMetasourceLink, openNotificationWithIcon } from '../../shared/utils/appUtils';

export const proposalDetailsSubmitSuccess = data => ({
  proposalDetails: data,
  type: PROPOSALDETAILS_SUBMIT_SUCCESS,
});

export const proposalDetailsSubmitError = errorMessage => ({
  type: PROPOSALDETAILS_SUBMIT_ERROR,
  proposalDetailsSubmitErrorDetails: errorMessage,
});

export const proposaltDetailsDataForPublicDossierSuccess = proposal => ({
  type: PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_SUCCESS,
  proposalDetails: proposal,
});

export const proposaltDetailsDataForPublicDossierError = statusError => ({
  type: PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_ERROR,
  proposalDetailsSubmitErrorStatus: statusError,
});

export const proposalDetailsDataSuccess = (data, url, etag, links) => ({
  type: PROPOSALDETAILS_DATA_SUCCESS,
  proposalDetailsData: data,
  url,
  etag,
  links,
});

export const proposalDetailsDataError = errorMessage => ({
  type: PROPOSALDETAILS_DATA_ERROR,
  dealDetailsErrorDetails: errorMessage,
});

export const proposalDetailsSubmitting = submitting => ({
  type: PROPOSALDETAILS_SUBMITTING,
  submitting: submitting,
});

export const saveProposalDetailsData = (url, proposalDetailsData) =>
  function(dispatch) {
    dispatch(proposalDetailsSubmitting(true));
    ProposalDetailsApi.getProposalDetailsData(url.href)
      .then(response => {
        const { etag } = response.headers;

        return ProposalDetailsApi.saveProposalDetailsData(url.href, proposalDetailsData, etag)
          .then(response => {
            dispatch(proposalDetailsSubmitSuccess(response.data.proposal));
            // Since proposals relevance is not useful for the agents, this call to recalculte
            // relevance after save the proposal is deactivate to avoid the slack notification
            // about new automatic proposal created
            // try {
            //   api
            //     .request_retry(`${ENDPOINT_V2}/proposals`, 'post', 5, {
            //       body: JSON.stringify({ id: response.data.proposal.id }),
            //     })
            //     .catch(err => {
            //       console.error('Proposals RelevanceService call error: ' + err.errorMessage);
            //     });
            // } catch (e) {
            //   console.error('Proposal RelevanceService call error: ' + e);
            // }
          })
          .catch(error => {
            console.error(error);
            dispatch(proposalDetailsSubmitError(extractErrorMessage(error)));
          });
      })
      .catch(error => {
        console.error(error);
        dispatch(proposalDetailsSubmitError(extractErrorMessage(error)));
      });
  };

export const proposalDetaislDeleteSuccess = () => ({
  type: PROPOSALDETAILS_DELETE_SUCCESS,
});

export const proposalDetailsDeleteError = errorMessage => ({
  type: PROPOSALDETAILS_DELETE_ERROR,
  proposalDetailsDeleteErrorDetails: errorMessage,
});

export const contractDataSuccess = contractData => ({
  type: PROPOSALDETAILS_CONTRACTDATA_SUCCESS,
  contractData,
});

export const contractDataError = err => ({
  type: PROPOSALDETAILS_CONTRACTDATA_ERROR,
  errorDetail: err,
});

export const resetContractDataError = () => ({
  type: PROPOSALDETAILS_CONTRACTDATA_RESET_ERROR,
});

export const deleteProposal = urlProposal =>
  function(dispatch) {
    dispatch(proposalDetailsSubmitting(true));

    return ProposalsApi.getMetasource(urlProposal.href)
      .then(response => {
        const { etag } = response.headers;

        return ProposalsApi.deleteProposal(urlProposal.href, etag)
          .then(() => {
            dispatch(proposalDetaislDeleteSuccess());
            dispatch(getDealDetailsData());
          })
          .catch(error => {
            console.error(error);
            dispatch(proposalDetailsDeleteError(extractErrorMessage(error)));
          });
      })
      .catch(error => {
        console.error(error);
        dispatch(proposalDetailsDeleteError(extractErrorMessage(error)));
      });
  };

export const getProposalDetailsForPublicDossier = url =>
  function(dispatch) {
    // console.error("url -----> " + url);
    ProposalDetailsApi.getProposalDetailsData(url)
      .then(response => {
        // console.error("response" + JSON.stringify(response));
        const awsCredentials = new AWS.Credentials(
          response.data.user.keyId,
          response.data.user.secretKey
        );

        dispatch(
          getCurrentUserInformationSuccess(
            'ANONYMOUS',
            awsCredentials,
            response.data.user.s3Endpoint
          )
        );
        dispatch(proposaltDetailsDataForPublicDossierSuccess(response.data.proposal));
      })
      .catch(error => {
        console.error(error);
        dispatch(proposaltDetailsDataForPublicDossierError(error.response.data.status));
      });
  };

export const saveProposalCTA = (id, proposalDetails) =>
  function(dispatch, getState) {
    dispatch(proposalDetailsSubmitting(true));
    const url = getState().Preload.mainMetasource.public_dossier_cta;

    return ProposalDetailsApi.getMetasource(url.href, id)
      .then(response => {
        const dossierUrl = getMetasourceLink(
          response.data,
          getState().Preload.metasourceStrings,
          'PROPOSAL_RESOURCE_CTA'
        );

        ProposalDetailsApi.saveProposalDetailsData(dossierUrl.href, proposalDetails)
          .then(response => {
            proposaltDetailsDataForPublicDossierSuccess(response.data.proposal);
          })
          .catch(error => {
            console.error(error);
            dispatch(proposaltDetailsDataForPublicDossierError(error.response.data.status));
          });
      })
      .catch(error => {
        console.error(error);
        dispatch(proposaltDetailsDataForPublicDossierError(error.response.data.status));
      });
  };

export const getContractData = proposalId =>
  function(dispatch, getState) {
    const metasource = { links: getState().Proposals.metasource.links };
    const { metasourceStrings } = getState().Preload;
    let url = getMetasourceLink(metasource, metasourceStrings, 'PROPOSAL_GET_CONTRACT').href;

    url = url
      .replace('proposalId', proposalId)
      .replace('{', '')
      .replace('}', '');
    ProposalsApi.getContractData(url)
      .then(response => {
        dispatch(contractDataSuccess(response.data));
      })
      .catch(error => {
        dispatch(contractDataError(extractErrorMessage(error)));
      });
  };

export const sendContractData = proposalId =>
  function(dispatch, getState) {
    const metasource = { links: getState().Proposals.metasource.links };
    const { metasourceStrings } = getState().Preload;
    let url = getMetasourceLink(metasource, metasourceStrings, 'PROPOSAL_SEND_CONTRACT').href;

    url = url.replace('{proposalId}', proposalId);
    ProposalsApi.sendContractData(url)
      .then(() => {
        openNotificationWithIcon('success', 'Yuju!', 'Datos de contrato enviados');
      })
      .catch(err => {
        const error = extractErrorMessage(err);

        openNotificationWithIcon('error', 'Oops!', error);
      });
  };

export const requestConfirmation = (proposalId, callback) =>
  function(dispatch, getState) {
    const metasource = { links: getState().Proposals.metasource.links };
    const { metasourceStrings } = getState().Preload;
    let url = getMetasourceLink(metasource, metasourceStrings, 'PROPOSAL_REQUEST_CONFIRMATION')
      .href;

    url = url.replace('{proposalId}', proposalId);
    ProposalsApi.requestConfirmation(url)
      .then(() => {
        callback(null);
      })
      .catch(err => {
        const error = extractErrorMessage(err);

        callback(error);
      });
  };

export const getProposalDetailsForPublicForm = proposalId =>
  function(dispatch, getState) {
    const url = getState().Preload.mainMetasource.public_proposal.href + proposalId;

    return ProposalDetailsApi.doGet(url)
      .then(response => {
        const { etag } = response.headers;

        dispatch(
          proposalDetailsDataSuccess(response.data.proposal, url, etag, response.data._links)
        );
      })
      .catch(error => {
        console.error(error);
        dispatch(proposalDetailsDataError(extractErrorMessage(error)));
        browserHistory.push({
          pathname: '/thankYou',
          search: `?message=${extractErrorMessage(error)}`,
        });
      });
  };

export const saveProviderInfo = (info, url) =>
  function(dispatch) {
    dispatch(proposalDetailsSubmitting(true));

    return ProposalDetailsApi.doPut(url.href, info)
      .then(() => {
        browserHistory.push('/thankYou');
      })
      .catch(error => {
        console.error(error);
        browserHistory.push({
          pathname: '/thankYou',
          search: `?message=${extractErrorMessage(error)}`,
        });
      });
  };
