import React from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import moment from 'moment';
import GoogleMapsAutocomplete from '../../components/GoogleMapsAutocomplete';
import { DATE_FILTER_MODIFIERS, DATE_FORMAT } from '../../shared/appConfig';
import get from 'lodash/get';
import SlideDown from 'react-slidedown';
import SelectProvider from '../../components/SelectProvider/SelectProvider';
import {
  DEAL_ENTRY_DATE_TYPE,
  DEAL_RENTAL_DURATION_TYPE,
  PROVIDER_TYPES,
} from '../../shared/utils/appUtils';
import { adjustDay, getDays } from '../../shared/utils/dateUtils';
import isEmpty from 'lodash/isEmpty';
import PropertiesSmartSearch from './PropertiesSmartSearch';
import PropertiesCounter from './PropertiesCounter';

const InputGroup = Input.Group;
const { Item: FormItem } = Form;
const { Option } = Select;

const ButtonGroup = Button.Group;

const PropertiesFilter = ({ ...props }) => {
  const handleCheckboxChange = e => {
    props.handleChange(e.target.name, e.target.checked);
  };

  const handleSelectProviderChange = (name, selectedOption) => {
    props.handleChange(name, selectedOption ? selectedOption.value : null);
  };

  const handleCheckboxGroupChange = e => {
    const { target } = e;

    props.handleChange(e.target.name, updateFilterArray(target));
  };

  const updateFilterArray = ({ name, value, checked }) => {
    const newValue = props.filter[name];

    if (checked) {
      newValue.push(value);

      return newValue;
    }

    return newValue.filter(item => item !== value);
  };

  return (
    <Form onSubmit={props.handleSubmitFilter} layout='vertical'>
      <Row gutter={8} type='flex' align='bottom'>
        <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
          <FormItem>
            <ButtonGroup style={{ width: '100%' }}>
              <Button
                style={{ width: '50%' }}
                disabled={props.loading}
                className={props.showMap ? 'btn-primary' : ''}
                onClick={props.changeViewMode}
              >
                Mapa
              </Button>
              <Button
                style={{ width: '50%' }}
                disabled={props.loading}
                className={!props.showMap ? 'btn-primary' : ''}
                onClick={props.changeViewMode}
              >
                Tabla
              </Button>
            </ButtonGroup>
          </FormItem>
        </Col>
        {!props.hideFilters && (
          <>
            <Col xxl={4} xl={9} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='Ubicación'>
                <GoogleMapsAutocomplete
                  handlePlaceChanged={props.handleLocationChange}
                  initValue={props.filter.address}
                />
              </FormItem>
            </Col>
            <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='Fecha desde'>
                <DatePicker
                  id='filterStartDate'
                  name='startDate'
                  style={{ width: '100%' }}
                  placeholder='dd/mm/yyyy'
                  value={props.filter.startDate ? moment(props.filter.startDate) : null}
                  format={DATE_FORMAT}
                  onChange={(moment, dateString) =>
                    props.handleDateChange('startDate', moment, dateString)
                  }
                />
              </FormItem>
            </Col>
            <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='Fecha hasta'>
                <DatePicker
                  id='filterEndDate'
                  name='endDate'
                  style={{ width: '100%' }}
                  placeholder='dd/mm/yyyy'
                  value={props.filter.endDate ? moment(props.filter.endDate) : null}
                  format={DATE_FORMAT}
                  onChange={(moment, dateString) =>
                    props.handleDateChange('endDate', moment, dateString)
                  }
                />
              </FormItem>
            </Col>
            <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='Precio hasta'>
                <InputNumber
                  name='maxPrice'
                  style={{ width: '100%' }}
                  min={0}
                  max={5000}
                  value={props.filter.maxPrice}
                  onChange={value => props.handleChange('maxPrice', value)}
                />
              </FormItem>
            </Col>
            <Col xxl={1} xl={2} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='PAX'>
                <InputNumber
                  name='guest'
                  placeholder='0'
                  style={{ width: '100%' }}
                  min={0}
                  max={30}
                  value={get(props, 'filter.guest') || 0}
                  onChange={value => props.handleChange('guest', value)}
                />
              </FormItem>
            </Col>
            <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='Camas'>
                <InputGroup compact>
                  <Select
                    name='bedsFilter'
                    style={{ width: '45%' }}
                    value={get(props, 'filter.bedsFilter') || 'bedsMoreThan'}
                    onChange={value => props.handleChange('bedsFilter', value)}
                  >
                    <Option value='beds'>=</Option>
                    <Option value='bedsMoreThan'>&gt;=</Option>
                  </Select>
                  <InputNumber
                    name='beds'
                    placeholder='0'
                    style={{ width: '55%' }}
                    min={0}
                    max={30}
                    value={get(props, 'filter.beds') || 0}
                    onChange={value => props.handleChange('beds', value)}
                  />
                </InputGroup>
              </FormItem>
            </Col>
            <Col xxl={1} xl={2} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='Baños'>
                <InputNumber
                  name='baths'
                  min={0}
                  max={30}
                  placeholder='0'
                  style={{ width: '100%' }}
                  value={get(props, 'filter.baths') || 0}
                  onChange={value => props.handleChange('baths', value)}
                />
              </FormItem>
            </Col>
            <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
              <FormItem label='Habitaciones'>
                <InputGroup compact>
                  <Select
                    name='roomsFilter'
                    value={get(props, 'filter.roomsFilter') || 'roomsMoreThan'}
                    style={{ width: '45%' }}
                    onChange={value => props.handleChange('roomsFilter', value)}
                  >
                    <Option value='rooms'>=</Option>
                    <Option value='roomsMoreThan'>&gt;=</Option>
                  </Select>
                  <InputNumber
                    name='rooms'
                    placeholder='0'
                    style={{ width: '55%' }}
                    min={0}
                    max={30}
                    value={get(props, 'filter.rooms') || 0}
                    onChange={value => props.handleChange('rooms', value)}
                  />
                </InputGroup>
              </FormItem>
            </Col>
            {/* <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
          <FormItem label='Radio en kilómetros'>
            <InputNumber
              min={0}
              max={100}
              style={{ width: '100%' }}
              value={props.filter.radius / 1000}
              onChange={props.handleRadiusChanged}
            />
          </FormItem>
        </Col> */}
            <Col xxl={2} xl={4} lg={6} md={6} sm={12} xs={24}>
              <FormItem>
                <Button
                  className='filter-buttons more-filters'
                  icon={props.filter.showMoreFilters ? 'up' : 'down'}
                  onClick={props.handleFilterCollapse}
                >
                  Más filtros
                </Button>
              </FormItem>
            </Col>
            <Col xxl={2} xl={4} lg={6} md={6} sm={12} xs={24}>
              <FormItem>
                <Button
                  className='filter-buttons more-filters'
                  icon='delete'
                  onClick={props.handleClearFilters}
                >
                  Limpiar
                </Button>
              </FormItem>
            </Col>
            <Col xxl={2} xl={5} lg={6} md={6} sm={12} xs={24}>
              <FormItem>
                <Button
                  htmlType='submit'
                  icon='search'
                  className='btn-primary filter-buttons'
                  loading={props.loading}
                >
                  Filtrar
                </Button>
              </FormItem>
            </Col>
          </>
        )}
      </Row>
      <SlideDown>
        {props.filter.showMoreFilters && (
          <Row type='flex' align='top' gutter={8}>
            {/* <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                id='filterBillable'
                name='billable'
                handleChange={e => props.handleChange('billable', e.target.checked)}
                content='Hace factura'
                value={props.filter.billable || false}
              />
            </FormItem>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                id='filterComissionable'
                name='comissionable'
                handleChange={e => props.handleChange('comissionable', e.target.checked)}
                content='Negociada comisión'
                value={props.filter.comissionable || false}
              />
            </FormItem>
          </Col> */}
            {/* <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
              <FormItem>
                <CheckboxButton
                  borderStyle='primaryCheckboxActive'
                  id='filterPublishedInWeb'
                  name='publishedInWeb'
                  handleChange={props.handleChange}
                  content='No publicado en web'
                  value={
                    props.filter.publishedInWeb
                      ? props.filter.publishedInWeb
                      : false
                  }
                />
              </FormItem>
            </Col> */}
            <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={24}>
              <Row>
                <Checkbox
                  name='verified'
                  checked={props.filter.verified || false}
                  onChange={handleCheckboxChange}
                >
                  Verificado
                </Checkbox>
              </Row>
              {/* <Row>
                <Checkbox
                  name='comissionable'
                  checked={props.filter.comissionable || false}
                  onChange={handleCheckboxChange}
                >
                  Negociada comisión
                </Checkbox>
              </Row> */}
              <Row>
                <Checkbox
                  name='highConversionProbability'
                  checked={props.filter.highConversionProbability || false}
                  onChange={handleCheckboxChange}
                >
                  Buena conversión
                </Checkbox>
              </Row>
              <Row>
                <Checkbox
                  name='incorporationDateStart'
                  checked={props.filter.incorporationDateStart || false}
                  onChange={handleCheckboxChange}
                >
                  Añadidos último mes
                </Checkbox>
              </Row>
            </Col>
            <Col xxl={3} xl={2} lg={5} md={5} sm={12} xs={24}>
              <Row align='top'>
                <Checkbox
                  name='airConditioning'
                  checked={props.filter.airConditioning}
                  onChange={handleCheckboxChange}
                >
                  A/C
                </Checkbox>
              </Row>
              <Row align='top'>
                <Checkbox name='wifi' checked={props.filter.wifi} onChange={handleCheckboxChange}>
                  Wifi
                </Checkbox>
              </Row>
              <Row align='top'>
                <Checkbox
                  name='freeCarPark'
                  checked={props.filter.freeCarPark}
                  onChange={handleCheckboxChange}
                >
                  Parking
                </Checkbox>
              </Row>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={24}>
              <Row align='top'>
                <Checkbox name='lift' checked={props.filter.lift} onChange={handleCheckboxChange}>
                  Ascensor
                </Checkbox>
              </Row>
              <Row align='top'>
                <Checkbox
                  name='petsAllowed'
                  checked={props.filter.petsAllowed}
                  onChange={handleCheckboxChange}
                >
                  Acepta mascotas
                </Checkbox>
              </Row>
              <Row align='top'>
                <Checkbox
                  name='cleaningChargeType'
                  checked={props.filter.cleaningChargeType}
                  onChange={handleCheckboxChange}
                >
                  Limpieza
                </Checkbox>
              </Row>
            </Col>

            <Col xxl={3} xl={3} lg={6} md={6} sm={12} xs={24}>
              {PROVIDER_TYPES.map(({ label, name }, index) => (
                <Row align='top' key={index}>
                  <Checkbox
                    name='providerType'
                    value={name}
                    checked={props.filter.providerType.includes(name)}
                    onChange={handleCheckboxGroupChange}
                  >
                    {label}
                  </Checkbox>
                </Row>
              ))}
            </Col>

            {/* <Col xxl={2} xl={2} lg={2} md={4} sm={4} xs={24}>
              <FormItem label='Baños'>
                <InputNumber
                  name='baths'
                  min={0}
                  max={30}
                  placeholder='0'
                  style={{ width: '100%' }}
                  value={props.filter.baths}
                  onChange={value => props.handleChange('baths', value)}
                />
              </FormItem>
            </Col> */}
            <Col xxl={5} xl={4} lg={8} md={24} sm={24} xs={24}>
              <FormItem label='Nombre proveedor'>
                <SelectProvider
                  id='providerId'
                  name='providerId'
                  value={props.filter.providerId || ''}
                  handleChange={handleSelectProviderChange}
                  extraClassName='select-provider-filter'
                />
              </FormItem>
            </Col>
            <Col xxl={2} xl={3} lg={8} md={12} sm={12} xs={24}>
              <FormItem label='Ref. Homyspace'>
                <Input
                  id='filterRefHomyspace'
                  name='refHomyspace'
                  placeholder='HS123'
                  value={props.filter.refHomyspace || ''}
                  onChange={e => props.handleChange('refHomyspace', e.target.value)}
                />
              </FormItem>
            </Col>
            <Col xxl={5} xl={4} lg={8} md={12} sm={12} xs={24}>
              <FormItem label='Ref. Proveedor'>
                <Input
                  id='filterRefProvider'
                  name='refProvider'
                  placeholder='PRO123'
                  value={props.filter.refProvider || ''}
                  onChange={e => props.handleChange('refProvider', e.target.value)}
                />
              </FormItem>
            </Col>
          </Row>
        )}
      </SlideDown>
      <div className='d-flex justify-content-between'>
        <div>
          {props.showSmartSearch && (
            <PropertiesSmartSearch
              checked={props.filter.smartSearch || false}
              onChange={handleCheckboxChange}
              disabled={props.disableSmartSearch}
            />
          )}
        </div>
        <div>
          {props.propertiesCount !== undefined && (
            <PropertiesCounter
              propertiesCount={props.propertiesCount}
              totalCount={props.propertiesTotalCount}
            />
          )}
        </div>
      </div>
      <style jsx>
        {`
          .filter-buttons {
            width: 100% !important;
          }

          .display-none {
            display: none;
          }

          .select-provider-filter {
            margin-top: 18px;
            height: 38px;
          }
        `}
      </style>
    </Form>
  );
};

export const adjustDateFilters = (filter, bondedDeal) => {
  const otherRequestInfo = get(bondedDeal, 'otherRequestInfo');

  if (!isEmpty(filter)) {
    if (
      filter.startDate &&
      filter.endDate &&
      getDays(filter.startDate, filter.endDate) <
        DATE_FILTER_MODIFIERS.MINIMUM_RENTAL_DAYS_FOR_APPLY_MODIFIERS
    ) {
      return;
    }

    if (!isEmpty(otherRequestInfo)) {
      const { entryDate, rentalDuration } = otherRequestInfo;

      if (filter.startDate) {
        if (entryDate === DEAL_ENTRY_DATE_TYPE.EXACT_FLEXIBLE) {
          filter.startDate = adjustDay(
            filter.startDate + DATE_FILTER_MODIFIERS.DAYS_FOR_EXACT_FLEXIBLE
          );
        }

        if (entryDate === DEAL_ENTRY_DATE_TYPE.APPROX) {
          filter.startDate = adjustDay(filter.startDate + DATE_FILTER_MODIFIERS.DAYS_FOR_APPROX);
        }
      }

      if (filter.endDate) {
        if (rentalDuration === DEAL_RENTAL_DURATION_TYPE.EXACT_POSSIBLE_EXTENSION) {
          filter.endDate = adjustDay(
            filter.endDate - DATE_FILTER_MODIFIERS.DAYS_FOR_EXACT_FLEXIBLE
          );
        }

        if (rentalDuration === DEAL_RENTAL_DURATION_TYPE.APPROX) {
          filter.endDate = adjustDay(filter.endDate - DATE_FILTER_MODIFIERS.DAYS_FOR_APPROX);
        }
      }
    }
  }
};

export default PropertiesFilter;
