import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Button, Col, DatePicker, Row, TimePicker } from 'antd';
import moment from 'moment';
import RichTextEditor from 'react-rte';
import { createNewBusinessEvent } from '../../modules/Events/EventsActions';
import { markAsInvalid, markAsValid } from '../../shared/utils/formUtils';
import { DATE_FORMAT, TIME_FORMAT } from '../../shared/appConfig.js';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import CheckboxButton from '../CheckboxButton';

// PROPERTIES TO BE CONFIGURED
// handleSubmit --> function to be called when a new business event is saved

class FormNewBusinessEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newBusinessEvent: {
        date: new Date(),
        textObject: RichTextEditor.createEmptyValue(),
        text: '',
      },
      submitting: false,
      invalidInput: false,
      isHighInterestChecked: false,
    };

    this.handleChangeRitchText = this.handleChangeRitchText.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleHighInterestChange = this.handleHighInterestChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting) {
      if (nextProps.businessEventCreate.error) {
        openNotificationWithIcon(
          'error',
          'Oop!',
          `Ha ocurrido un error. ${nextProps.businessEventCreate.error}`
        );
      }
    }

    this.setState({
      submitting: false,
    });
  }

  handleChangeRitchText = value => {
    const { newBusinessEvent } = this.state;

    newBusinessEvent.textObject = value;
    this.setState({ newBusinessEvent });
  };

  handleChangeDate(value) {
    let dateValue = new Date();

    if (value) {
      const formatOfValue = value._f;

      switch (formatOfValue) {
        case DATE_FORMAT:
          dateValue = moment(
            `${value.format(formatOfValue)} ${moment(this.state.newBusinessEvent.date).format(
              TIME_FORMAT
            )}`,
            `${DATE_FORMAT} ${TIME_FORMAT}`
          ).toDate();
          break;
        case TIME_FORMAT:
          dateValue = moment(
            `${moment(this.state.newBusinessEvent.date).format(DATE_FORMAT)} ${value.format(
              formatOfValue
            )}`,
            `${DATE_FORMAT} ${TIME_FORMAT}`
          ).toDate();
          break;
        default:
          dateValue = new Date();
          break;
      }
    }

    const localNewBusinessEvent = this.state.newBusinessEvent;

    localNewBusinessEvent.date = dateValue;
    this.setState({ newBusinessEvent: localNewBusinessEvent });
  }

  handleHighInterestChange(e) {
    this.setState({
      isHighInterestChecked: e.target.checked,
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const localNewBusinessEvent = this.state.newBusinessEvent;

    localNewBusinessEvent.text = `<b>${
      this.props.currentUserInformation.username
    }</b> registró:<br>${localNewBusinessEvent.textObject.toString('html')}`;

    if (this.state.isHighInterestChecked) {
      localNewBusinessEvent.text += '<p>Alto interés</p>';
      localNewBusinessEvent.highinterest = true;
    }

    if (this.props.agent) {
      localNewBusinessEvent.from = this.props.agent;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const domObject = ReactDOM.findDOMNode(this.refs.RichTextEditorBusinessEventText);

    if (localNewBusinessEvent.text === '' || localNewBusinessEvent.text === '<p><br></p>') {
      markAsInvalid(domObject);
      this.setState({ invalidInput: true });

      return;
    }

    markAsValid(domObject);
    this.setState(
      {
        invalidInput: false,
        submitting: true,
        newBusinessEvent: localNewBusinessEvent,
      },
      () => {
        this.props.actions.createNewBusinessEvent(
          this.props.businessEventLocation,
          this.state.newBusinessEvent,
          this.onSuccess
        );
      }
    );
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Row>
          <Col span={12}>
            <DatePicker
              id='businessEventDateFormatDate'
              name='dateFormatDate'
              value={moment(this.state.newBusinessEvent.date, DATE_FORMAT)}
              format={DATE_FORMAT}
              onChange={this.handleChangeDate}
            />
          </Col>
          <Col span={12}>
            <TimePicker
              id='businessEventDateFormatTime'
              name='dateFormatTime'
              value={moment(this.state.newBusinessEvent.date, TIME_FORMAT)}
              format={TIME_FORMAT}
              onChange={this.handleChangeDate}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <div className='form-group totalBorder'>
              <RichTextEditor
                id='businessEventText'
                name='text'
                ref='RichTextEditorBusinessEventText'
                className='form-control validate validate-required'
                value={this.state.newBusinessEvent.textObject}
                onChange={this.handleChangeRitchText}
              />
            </div>
          </Col>
        </Row>
        <Row type='flex' justify={this.props.highInterest ? 'space-between' : 'end'}>
          {this.props.highInterest && (
            <Col>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                handleChange={this.handleHighInterestChange}
                content='Alto interés'
                value={this.state.isHighInterestChecked || false}
                style={{ padding: 10 }}
              />
            </Col>
          )}
          <Col>
            <Button loading={this.state.submitting} htmlType='submit' className='btn-primary'>
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createNewBusinessEvent: (url, newBusinessEventData) =>
        dispatch(createNewBusinessEvent(url, newBusinessEventData)),
    },
  };
}

function mapStateToProps(state) {
  return {
    businessEventCreate: state.Events.businessEventCreate,
    businessEventLocation: state.Events.businessEventLocation.location,
    currentUserInformation: state.Preload.currentUserInformation,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormNewBusinessEvent);
