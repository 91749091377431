import { getAxiosInstance } from '../../shared/utils/apiUtils';

class InvoicesApi {
  static getMetasource(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getInvoicesTableData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getNextInvoiceData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getNewInvoiceLocation(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(url);
  }
}

export default InvoicesApi;
