import React from 'react';
import { Row, Col, Radio, Select, Input, Form } from 'antd';

const NOOP = () => {};

const QuestionsInfo = ({
  questions: {
    travelManagement = 'NSNC',
    agency = '',
    marketingSourceMedium = undefined,
    department = '',
    frequency = 'NS/NC',
    travellingTo = undefined,
    countries = '',
  } = {},
  handleChange = NOOP,
}) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label='¿Gestionáis los viajes de negocios internamente?'>
            <Radio.Group
              onChange={e => {
                handleChange('travelManagement', e.target.value);
              }}
              value={travelManagement}
              name='travelManagement'
            >
              <Radio value='NSNC'>NS/NC</Radio>
              <Radio value='EXTERNALLY'>Externamente</Radio>
              <Radio value='INTERNALLY'>Internamente</Radio>
              <Radio value='DIRECTLY_FROM_EMPLOYEES'>Directamente los empleados</Radio>
            </Radio.Group>
          </Form.Item>

          {travelManagement === 'EXTERNALLY' && (
            <Form.Item label='¿A través de que agencia?'>
              <Select
                allowClear
                showSearch
                showArrow
                value={agency}
                name='agency'
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => {
                  handleChange('agency', value);
                }}
              >
                <Select.Option value='EL_CORTE_INGLES_TRAVEL_AGENCY'>
                  Viajes El Corte Inglés
                </Select.Option>
                <Select.Option value='BCD_TRAVEL'>BCD Travel</Select.Option>
                <Select.Option value='EGENCIA'>Egencia</Select.Option>
                <Select.Option value='BCM_TRAVEL'>BCM Travel</Select.Option>
                <Select.Option value='IAG7_TRAVEL'>IAG7 Travel</Select.Option>
                <Select.Option value='CARLSON_WAGONLIT_TRAVEL'>
                  Carlson Wagonlit Travel
                </Select.Option>
                <Select.Option value='AMEX'>AMEX</Select.Option>
                <Select.Option value='CORPORATE_TRAVEL_MANAGEMENT'>
                  Corporate Travel Management
                </Select.Option>
                <Select.Option value='NAUTALIA'>Nautalia</Select.Option>
                <Select.Option value='OTHER'>Otro</Select.Option>
              </Select>
            </Form.Item>
          )}

          {travelManagement === 'INTERNALLY' && (
            <Form.Item label='¿Dónde buscas el alojamiento?'>
              <Select
                mode='multiple'
                allowClear
                showSearch
                showArrow
                value={marketingSourceMedium ? marketingSourceMedium.split(',') : undefined}
                name='marketingSourceMedium'
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => {
                  handleChange('marketingSourceMedium', value.toString());
                }}
              >
                <Select.Option value='IDEALISTA'>Idealista</Select.Option>
                <Select.Option value='ENALQUILER'>Enalquiler</Select.Option>
                <Select.Option value='AIRBNB'>Airbnb</Select.Option>
                <Select.Option value='HOMEAWAY'>Homeaway</Select.Option>
                <Select.Option value='SPOTAHOME'>Spotahome</Select.Option>
                <Select.Option value='LOCAL_SUPPLIERS'>Proveedores locales</Select.Option>
                <Select.Option value='BOOKING'>Booking</Select.Option>
                <Select.Option value='HOTELS'>Hoteles</Select.Option>
                <Select.Option value='PENSIONS'>Pensiones</Select.Option>
                <Select.Option value='APARTHOTELES'>Aparthoteles</Select.Option>
                <Select.Option value='LOCALPROPERTY'>Inmobiliaria</Select.Option>
              </Select>
            </Form.Item>
          )}

          {travelManagement === 'INTERNALLY' && (
            <Form.Item label='¿A través de qué departamento?'>
              <Select
                allowClear
                showSearch
                showArrow
                value={department}
                name='department'
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={value => {
                  handleChange('department', value);
                }}
              >
                <Select.Option value='TRAVEL'>Travel</Select.Option>
                <Select.Option value='SHOPPING'>Compras</Select.Option>
                <Select.Option value='HR'>RRHH</Select.Option>
                <Select.Option value='ADMINISTRATION'>Administración</Select.Option>
                <Select.Option value='PERSONAL'>Personal</Select.Option>
                <Select.Option value='PROVIDERS'>Proveedores</Select.Option>
                <Select.Option value='MARKETING'>Márketing</Select.Option>
                <Select.Option value='BUSINESS_DEVELOPMENT'>Desarrollo de negocio</Select.Option>
                <Select.Option value='ACCOUNTING'>Contabilidad</Select.Option>
                <Select.Option value='IT'>IT</Select.Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item label='¿Cuántas veces al año desplazáis a vuestros empleados por más de dos semanas?'>
            <Radio.Group
              value={frequency}
              onChange={e => {
                handleChange('frequency', e.target.value);
              }}
              defaultValue={frequency}
              name='frequency'
            >
              <Radio value='NS/NC'>NS/NC</Radio>
              <Radio value='ONE_TO_TWO'>1-2</Radio>
              <Radio value='THREE_TO_FOUR'>3-4</Radio>
              <Radio value='FIVE_TO_TEN'>5-10</Radio>
              <Radio value='TEN_TO_TWENTY'>10-20</Radio>
              <Radio value='PLUS_TWENTY'>+20</Radio>
              <Radio value='PROMPTLY'>Puntualmente</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label='¿Dónde desplazáis a vuestros empleados?'>
            <Select
              mode='multiple'
              allowClear
              showArrow
              showSearch
              value={travellingTo ? travellingTo.split(',') : undefined}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={value => {
                handleChange('travellingTo', value.toString());
              }}
              name='travellingTo'
            >
              <Select.Option value='EUROPE'>Europa</Select.Option>
              <Select.Option value='MIDDLE_EAST'>Oriente medio</Select.Option>
              <Select.Option value='NORTH_AMERICA'>América del Norte</Select.Option>
              <Select.Option value='SOUTH_AMERICA'>América del Sur</Select.Option>
              <Select.Option value='ASIA'>Asia</Select.Option>
              <Select.Option value='AFRICA'>África</Select.Option>
              <Select.Option value='WORLD'>Todo el mundo</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label='¿A qué países de esa zona?'>
            <Input
              id='countries'
              name='countries'
              value={countries}
              placeholder='Islas Caimán, Panamá, Suiza, Andorra...'
              onChange={e => {
                handleChange('countries', e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <style jsx>{`
        .ant-radio-checked:after,
        .ant-radio-checked .ant-radio-inner {
          border-color: #eeaf30 !important;
        }

        .ant-radio-inner:after {
          background-color: #eeaf30 !important;
        }

        .ant-form-item-label {
          text-align: inherit !important;
          white-space: inherit !important;
        }
      `}</style>
    </>
  );
};
export default QuestionsInfo;
