import {
  REQUESTS_METASOURCE_ERROR,
  REQUESTS_METASOURCE_SUCCESS,
  REQUESTS_TABLEDATA_ERROR,
  REQUESTS_TABLEDATA_FILTER,
  REQUESTS_TABLEDATA_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  requestsData: {
    tableData: {},
    totalCount: 0,
    error: false,
    errorDetail: '',
  },
  filter: {
    firstRequest: false,
    recurrent: false,
    converted2Deal: false,
    notConverted2Deal: true,
  },
  filterVersion: 0,
};

const Requests = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTS_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case REQUESTS_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case REQUESTS_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        requestsData: {
          tableData: action.tableData,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
        },
      });
    }

    case REQUESTS_TABLEDATA_FILTER: {
      // let filterVersion = state.filterVersion + 1;
      return Object.assign({}, state, {
        filter: action.filterCriteria,
        // filterVersion: filterVersion,
      });
    }

    case REQUESTS_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        requestsData: {
          tableData: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    default:
      return state;
  }
};

export default Requests;
