import {
  LOGIN_ERROR,
  LOGIN_PROBLEM,
  LOGIN_SUCCESS,
  SESSION_CLOSED,
} from '../../shared/actionTypes';

const initialState = {
  isLoggedIn: false,
  session: localStorage.jwt,
  loginError: false,
  loginProblem: false,
  loginProblemDetails: '',
};

const LoginControl = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        isLoggedIn: true,
      });
    }

    case LOGIN_ERROR: {
      return Object.assign({}, state, {
        isLoggedIn: false,
        loginProblem: false,
        loginProblemDetails: false,
        loginError: true,
      });
    }

    case LOGIN_PROBLEM: {
      return Object.assign({}, state, {
        isLoggedIn: false,
        loginError: false,
        loginProblem: true,
        loginProblemDetails: action.errorDetails,
      });
    }

    case SESSION_CLOSED: {
      return Object.assign({}, state, initialState);
    }

    default:
      return state;
  }
};

export default LoginControl;
