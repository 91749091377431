import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Table } from 'antd';

class TableDataTenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.tenantsList ? props.tenantsList : [],
      contactsVersion: props.providerContactsVersion,
    };
    this.showProviderTenantDetail = this.showProviderTenantDetail.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.tenantsList && nextProps.contactsVersion !== this.state.contactsVersion) {
      this.setState({
        contactsVersion: nextProps.contactsVersion,
        data: nextProps.providerContacts,
      });
    }

    if (
      this.props.tenantsList &&
      JSON.stringify(this.state.data) !== JSON.stringify(this.props.tenantsList)
    ) {
      this.setState({
        data: this.props.tenantsList,
      });
    }
  }

  showProviderTenantDetail = tenant => {
    browserHistory.push(`/contactDetails/${tenant.id}`);
    // this.props.callbackFunctionOnContactSelect(tenant);
  };

  render() {
    const tableScroll = window.screen.availWidth - 1280;

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'tenant.name',
        sorter: (a, b) =>
          a.tenant.name < b.tenant.name ? -1 : a.tenant.name > b.tenant.name ? 1 : 0,
        render: text => (
          <a href=''>{text && text.length > 27 ? text.substring(0, 24) + '...' : text}</a>
        ),
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showProviderTenantDetail(record.tenant);
        },
      },
      {
        title: 'Apellidos / Razón social',
        dataIndex: 'tenant.lastOTradeName',
        sorter: (a, b) =>
          a.tenant.lastOTradeName < b.tenant.lastOTradeName
            ? -1
            : a.tenant.lastOTradeName > b.tenant.lastOTradeName
            ? 1
            : 0,
        render: text => (text && text.length > 27 ? text.substring(0, 24) + '...' : text),
      },
      {
        title: 'DNI',
        dataIndex: 'tenant.identificationCode',
        render: text => text,
      },
      {
        title: ' ',
        dataIndex: 'tenant.id',
        render: this.props.deleteRender,
      },
    ];

    return (
      <div className='col-sm-12'>
        {this.state.data && (
          <Table
            size='small'
            pagination={{ pageSize: 15 }}
            dataSource={this.state.data && this.state.data.length > 0 ? this.state.data : null}
            columns={columns}
            rowKey={rec => rec.tenant.id}
            scroll={{ x: tableScroll }}
            defaultExpandAllRows={true}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    providerContacts: state.ProviderDetails.contacts.detail,
    providerContactsVersion: state.ProviderDetails.contactsVersion,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps)(TableDataTenants);
