import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
// import { getContactsTableData } from '../../modules/Contacts/ContactsActions';
import {
  getTextValueFromSelectOptions,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import { DATE_FORMAT } from '../../shared/appConfig';
import HomyPagination from '../HomyComponents/HomyPagination';
import tableUtils from '../../shared/utils/tableUtils';
import { browserHistory } from 'react-router';

class TableDataContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      // totalCount: 0,
      // filter: {},
      // filterVersion: 0,
      // currentSorterField: '',
      // currentSortOrder: '',
      // loading: true,
      // options: {
      //   contactTypes: getOptionsForSelect(
      //     this.props.optionLabels,
      //     'ContactType'
      //   ),
      // },
    };

    // this.props.actions.getContactsTableData(null);
    this.showContactDetail = this.showContactDetail.bind(this);
    // this.sortData = this.sortData.bind(this);
    // this.filterData = this.filterData.bind(this);
    // this.getTableData = this.getTableData.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.data) !== JSON.stringify(newProps.data)) {
      this.setState({
        data: newProps.data,
      });
    }
  }

  showContactDetail(contactId, isBlacklisted) {
    const { showBlacklistedNotification } = this.props;

    isBlacklisted
      ? showBlacklistedNotification()
      : browserHistory.push(`/contactDetails/${contactId}`);
  }

  showBlacklistedNotification = () => {
    openNotificationWithIcon(
      'warning',
      <span role='img' area-label='eyes'>
        &#128123; ¡Huye! &#128123;
      </span>,
      `¡Este Cliente se encuentra en la lista negra de Captación!`
    );
  };

  render() {
    const {
      options,
      loading,
      pagination,
      handleSorter,
      handlePaginationSizeChange,
      handlePaginationChange,
    } = this.props;
    const { data } = this.state;

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'contact.name',
        sorter: true,
        width: 200,
        render: (text, record) => (
          <a
            href={`/contactDetails/${record.contact.id}`}
            className={record.contact.disabled ? 'disabled-link' : ''}
          >
            {text}
          </a>
        ),
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showContactDetail(record.contact.id, record.contact.disabled);
        },
      },
      {
        title: 'Apellidos / Razón social',
        dataIndex: 'contact.lastOTradeName',
        sorter: true,
        width: 200,
        render: (text, record) => (
          <span className={record.contact.disabled ? 'disabled-link' : ''}>{text}</span>
        ),
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showContactDetail(record.contact.id, record.contact.disabled);
        },
      },
      {
        title: 'Cargo',
        dataIndex: 'contact.jobTitle',
        sorter: true,
        width: 150,
      },
      {
        title: 'Email',
        dataIndex: 'contact.email',
        sorter: true,
        render: (text, record) => (
          <a
            href={record.contact.disabled ? '' : `mailto:pruebas@homyspace.com`}
            className={record.contact.disabled ? 'disabled-link' : ''}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Teléfono',
        dataIndex: 'contact.phoneNumber',
        sorter: true,
        width: 150,
        render: (text, record) => (
          <a
            href={record.contact.disabled ? '' : `tel:${text}`}
            className={record.contact.disabled ? 'disabled-link' : ''}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'contact.creationDate',
        width: 200,
        sorter: true,
        render: val => moment(val).format(DATE_FORMAT),
      },
      {
        title: 'Tipo de contacto',
        dataIndex: 'contact.contactType',
        width: 200,
        sorter: true,
        render: val => getTextValueFromSelectOptions(val, options.contactType),
      },
    ];

    const scrollX = tableUtils.getTableWidth();
    const scrollY = tableUtils.getTableHeight();

    return (
      <div className='animated fadeIn contactBorderTopColor'>
        <Table
          size='middle'
          className='fixed-width-table'
          pagination={false}
          dataSource={data}
          loading={loading}
          columns={columns}
          rowKey={rec => rec.contact.id}
          scroll={{ y: scrollY, x: scrollX }}
          onChange={(pagination, filter, sorter) => handleSorter(sorter)}
          footer={() => (
            <HomyPagination
              showSizeChanger
              showQuickJumper
              current={pagination.page}
              pageSize={pagination.pageSize}
              totalCount={pagination.totalCount}
              onShowSizeChange={handlePaginationSizeChange}
              onChange={handlePaginationChange}
            />
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactsData: state.Contacts.contactsData,
    filter: state.Contacts.filter,
    filterVersion: state.Contacts.filterVersion,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(TableDataContacts);
