import AWS from 'aws-sdk';
import isEmpty from 'lodash/isEmpty';

let instance = null;
let awsCredentials = null;
let s3Endpoint = null;

class AwsUtils {
  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  getS3Resource(bucket, resourceKey) {
    if (isEmpty(bucket) || isEmpty(resourceKey)) {
      return;
    }

    const config = new AWS.Config({
      credentials: awsCredentials,
      s3ForcePathStyle: true,
      endpoint: s3Endpoint,
      signatureVersion: 'v4',
    });

    const s3 = new AWS.S3(config);

    return s3
      .getObject({
        Bucket: bucket,
        Key: resourceKey,
      })
      .promise();
  }

  setAwsCredentials(credentials) {
    awsCredentials = credentials;
  }

  setS3Endpoint(endpoint) {
    s3Endpoint = endpoint;
  }
}

export default AwsUtils;
