import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip, Select, Tabs } from 'antd';
import moment from 'moment';
import { saveCompanyDetailsData } from '../../modules/CompanyDetails/CompanyDetailsActions';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import {
  getOptionsForSelect,
  getTextValueFromSelectOptions,
  openNotificationWithIcon,
  setKeyLabelESToLabel,
} from '../../shared/utils/appUtils';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import { DATE_FORMAT, DATETIME_FORMAT } from '../../shared/appConfig';
import FormIBAN, { validateIBAN } from '../HomyFormComponents/FormIBAN';
import HomySelect from '../HomyComponents/HomySelect';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';
import CheckboxButton from '../CheckboxButton/CheckboxButton';
import { isFlagActive } from 'hs-feature-flags';
import QuestionsInfo from '../../views/Deals/CreateOpportunity/QuestionsInfo';
import CompanyDetailsApi from '../../modules/CompanyDetails/CompanyDetailsApi';
import RichTextEditor from 'react-rte';

const { TabPane } = Tabs;
class FormCompanyDetails extends Component {
  constructor(props) {
    super(props);

    let initialGeneralConditionsPremium = RichTextEditor.createEmptyValue();

    const currentGeneralConditionsPremium = get(
      props,
      'companyDetailsData.data.detail.generalConditionsPremium',
      ''
    );

    if (currentGeneralConditionsPremium) {
      initialGeneralConditionsPremium = RichTextEditor.createValueFromString(
        currentGeneralConditionsPremium,
        'html'
      );
    }

    this.state = {
      companyDetails: props.companyDetailsData.data.detail,
      companyDetailsEtag: props.companyDetailsData.data.etag,
      submitting: false,
      submitSuccess: false,
      invalidInput: false,
      isPremiumFFActive: false,
      qualifyingQuestions: {},
      options: {
        leadLifecycle: getOptionsForSelect(this.props.optionLabels, 'CompanyLeadLifecycle'),
        registeredInRoi: getOptionsForSelect(this.props.optionLabels, 'RegisteredInRoi'),
        companyTypeOptions: getOptionsForSelect(this.props.optionLabels, 'CompanyType'),
        companyMarketingSourceMediumOptions: setKeyLabelESToLabel(
          this.props.companyMarketingSourceMediumOptions
        ),
      },
      generalConditionsPremium: initialGeneralConditionsPremium,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.getLastOrTradeNameLabel = this.getLastOrTradeNameLabel.bind(this);
    this.isPremiumFFActive = this.isPremiumFFActive.bind(this);
    this.handleQQChange = this.handleQQChange.bind(this);
    this.handleChangeGeneralConditionsPremium = this.handleChangeGeneralConditionsPremium.bind(
      this
    );

    this.isPremiumFFActive();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting) {
      if (!nextProps.companyDetailsSubmit.error) {
        this.setState({ submitSuccess: true }, () => {
          openNotificationWithIcon(
            'success',
            'Todo Ok! :)',
            'Los cambios se han realizado correctamente'
          );
          this.setState({ submitting: false });
        });
      } else {
        this.setState({ submitSuccess: false }, () => {
          openNotificationWithIcon('error', 'Oops!', nextProps.companyDetailsSubmit.errorDetail);
        });
      }
    }
  }

  async componentDidMount() {
    try {
      const qualifyingQuestions = await CompanyDetailsApi.getQualifyingQuestions(
        this.state.companyDetails.id
      );
      if (!isEmpty(qualifyingQuestions)) {
        this.setState({ qualifyingQuestions });
      }
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Oops!',
        'No se han podido cargar las Qualifying Questions del cliente'
      );
    }
  }

  handleChange(e) {
    const { companyDetails } = this.state;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (
      e.target.name === 'baseContact.phoneNumber' ||
      e.target.name === 'baseContact.mobilePhoneNumber'
    ) {
      value = normalizePhoneNumber(value);
    }

    const isBaseContact = e.target.name.startsWith('baseContact.');

    if (isBaseContact) {
      const targetBaseContact = e.target.name.replace('baseContact.', '');

      if (targetBaseContact === 'address.addressComplement') {
        if (!companyDetails.baseContact.address) {
          companyDetails.baseContact.address = {};
        }

        companyDetails.baseContact.address.addressComplement = value;
      } else {
        companyDetails.baseContact[targetBaseContact] = value;
      }
    } else {
      companyDetails[e.target.name] = value;
    }

    this.setState({ companyDetails });
  }

  handleSelectChange(name, selectedOption) {
    const { companyDetails } = this.state;

    if (name === 'registeredInRoi') {
      companyDetails.baseContact[name] = selectedOption || null;
    } else {
      companyDetails[name] = selectedOption || null;
    }

    if (
      name === 'companyType' &&
      selectedOption.value === 'COMPANY' &&
      companyDetails.baseContact
    ) {
      companyDetails.baseContact.jobTitle = null;
    }

    this.setState({ companyDetails });
  }

  handlePlaceChanged(place) {
    const { companyDetails } = this.state;

    set(companyDetails, 'baseContact.address', {
      ...place,
      addressComplement: get(companyDetails, 'baseContact.address.addressComplement', null),
    });

    this.setState({
      companyDetails,
    });
  }

  handleQQChange(name, value) {
    this.setState({
      qualifyingQuestions: {
        ...this.state.qualifyingQuestions,
        [name]: value,
      },
    });
  }

  isValidForm() {
    const isValidIBAN = validateIBAN();

    if (!isValidIBAN) {
      return false;
    }

    const inputsToValidate = document.getElementsByClassName('validate');
    const isValidForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !isValidForm });

    return isValidForm;
  }

  handleChangeGeneralConditionsPremium(value) {
    this.setState({
      generalConditionsPremium: value,
    });
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (this.isValidForm()) {
      const { mainView } = this.props;
      this.setState({ submitting: true }, () => {
        const generalConditionsPremiumAsHTML = this.state.generalConditionsPremium.toString('html');
        const additionalCompanyData = {
          generalConditionsPremium:
            generalConditionsPremiumAsHTML === '<p><br></p>' ? '' : generalConditionsPremiumAsHTML,
        };

        this.props.actions.saveCompanyDetailsData(
          mainView === 'CompanyDetails',
          additionalCompanyData
        );
      });
    } else {
      openNotificationWithIcon(
        'error',
        'Hoooold!',
        'Falta info en determinados campos. ¡Guíate por el color rojo!'
      );
    }

    try {
      await CompanyDetailsApi.saveQualifyingQuestions(
        this.state.companyDetails.id,
        this.state.qualifyingQuestions
      );
    } catch (e) {
      console.error(e);
      openNotificationWithIcon(
        'error',
        'Oops!',
        'No se han podido guardar las Qualifying Questions del cliente'
      );
    }
  };

  getLastOrTradeNameLabel() {
    if (get(this.state, 'companyDetails.companyType') === 'PERSON') {
      return 'Apellidos';
    }

    if (get(this.state, 'companyDetails.companyType') === 'COMPANY') {
      return 'Razón Social';
    }

    return 'Apellidos/Razón Social';
  }

  isPremiumFFActive = async () => {
    const checkFF = await isFlagActive('PREMIUM_FRONT_SETUP', this.props.currentUser);

    this.setState({
      isPremiumFFActive: checkFF,
    });
  };

  render() {
    const hasAcceptedGeneralConditions =
      isNumber(get(this.state, 'companyDetails.generalConditionsDate')) &&
      !isEmpty(get(this.state, 'companyDetails.generalConditionsOrigin'));
    const hasAcceptedGeneralConditionsPremium = isNumber(
      get(this.state, 'companyDetails.generalConditionsPremiumDate')
    );
    const hasAcceptedPrivacyPolicy =
      isNumber(get(this.state, 'companyDetails.privacyPolicyDate')) &&
      !isEmpty(get(this.state, 'companyDetails.privacyPolicyOrigin'));

    return (
      <div className='col-sm-12'>
        <form id='formCompanyDetails' onSubmit={this.handleSubmit}>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='row'>
                <div className='col-sm-12'>
                  <Button
                    loading={this.state.submitting}
                    htmlType='submit'
                    className='btn btn-primary btn-block alignLeft'
                  >
                    Guardar
                  </Button>
                  <br />
                  {get(this.state, 'companyDetails.baseContact.creationDate') ? (
                    <p>
                      <b>Creado por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(
                        get(this.state, 'companyDetails.baseContact.createdBy'),
                        ''
                      )}
                      <br />
                      {moment(get(this.state, 'companyDetails.baseContact.creationDate')).format(
                        DATETIME_FORMAT
                      )}
                    </p>
                  ) : (
                    ''
                  )}
                  {get(this.state, 'companyDetails.baseContact.updateDate') ? (
                    <p>
                      <b>Última actualización por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(
                        get(this.state, 'companyDetails.baseContact.updatedBy'),
                        ''
                      )}
                      <br />
                      {moment(get(this.state, 'companyDetails.baseContact.updateDate')).format(
                        DATETIME_FORMAT
                      )}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <div className='card-container col-sm-10'>
              <Tabs type='card'>
                <TabPane tab='Cliente' key='1'>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title="El campo 'Tipo' nos permite identificar el tipo de cliente. Si es una persona o una sociedad."
                        >
                          <label htmlFor='type' className='required'>
                            Tipo
                          </label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <HomySelect
                          name='companyType'
                          className='validate validate-required'
                          value={get(this.state, 'companyDetails.companyType') || ''}
                          options={get(this.state, 'options.companyTypeOptions')}
                          onChange={value => {
                            this.handleSelectChange('companyType', value);
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-sm-3'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title="El campo 'Fuente/Medio' nos permite registrar el origen del lead. Es decir, la fuente o el medio desde el cual el lead ha accedido a nosotros."
                        >
                          <label htmlFor='marketingSourceMedium'>Fuente / Medio</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <HomySelect
                          name='marketingSourceMedium'
                          value={get(this.state, 'companyDetails.marketingSourceMedium') || ''}
                          options={this.state.options.companyMarketingSourceMediumOptions}
                          onChange={value => {
                            this.handleSelectChange('marketingSourceMedium', value);
                          }}
                          showArrow
                          showSearch
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <Tooltip placement='topLeft' title='Estado del lead en el Funnel.'>
                          <label htmlFor='companyDetailLeadLifecycle'>Estado del lead</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <input
                          disabled
                          type='text'
                          className='form-control'
                          value={getTextValueFromSelectOptions(
                            this.state.companyDetails.companyLeadLifecycle,
                            this.state.options.leadLifecycle
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='hs-hr' />

                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <Tooltip placement='topLeft' title='Número de Identificación Fiscal.'>
                          <label htmlFor='companyDetailIdentificationCode'>NIF</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailIdentificationCode'
                          name='baseContact.identificationCode'
                          placeholder='12121212Z'
                          value={
                            get(this.state, 'companyDetails.baseContact.identificationCode') || ''
                          }
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailFirstName' className='required'>
                          Nombre
                        </label>
                        <input
                          type='text'
                          className='form-control validate validate-required'
                          id='companyDetailName'
                          name='baseContact.name'
                          placeholder='Ana'
                          value={get(this.state, 'companyDetails.baseContact.name') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailLastOTradeName'>
                          {this.getLastOrTradeNameLabel()}
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailLastOTradeName'
                          name='baseContact.lastOTradeName'
                          placeholder='García García'
                          value={get(this.state, 'companyDetails.baseContact.lastOTradeName') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailEmail'>Email</label>
                        <input
                          type='email'
                          className='form-control'
                          id='companyDetailEmail'
                          name='baseContact.email'
                          placeholder='pruebas@pruebas.com'
                          value={get(this.state, 'companyDetails.baseContact.email') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-3'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailEmail2'>2º Email</label>
                        <input
                          type='email'
                          className='form-control'
                          id='companyDetailEmail2'
                          name='baseContact.email2'
                          placeholder='pruebas@pruebas.com'
                          value={get(this.state, 'companyDetails.baseContact.email2') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-3'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailPhoneNumber'>Teléfono fijo</label>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailPhoneNumber'
                          name='baseContact.phoneNumber'
                          placeholder='961231212'
                          value={get(this.state, 'companyDetails.baseContact.phoneNumber') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-3'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailMobilePhoneNumber'>Teléfono móvil</label>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailMobilePhoneNumber'
                          name='baseContact.mobilePhoneNumber'
                          placeholder='616232323'
                          value={
                            get(this.state, 'companyDetails.baseContact.mobilePhoneNumber') || ''
                          }
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailSector'>Sector</label>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailSector'
                          name='sector'
                          value={get(this.state, 'companyDetails.sector') || ''}
                          title={get(this.state, 'companyDetails.sector') || ''}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailCNAE'>CNAE</label>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailCNAE'
                          name='cnae'
                          value={get(this.state, 'companyDetails.cnae') || ''}
                          title={get(this.state, 'companyDetails.cnae') || ''}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='hs-hr' />

                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title='Escribe la dirección y SELECCIONA una de las opciones del desplegable.'
                        >
                          <label htmlFor='companyDetailAddress'>Dirección</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <GoogleMapsAutocomplete
                          id='companyDetailAddress'
                          inputClassName='form-control'
                          handlePlaceChanged={this.handlePlaceChanged}
                          initValue={get(this.state, 'companyDetails.baseContact.address')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title='Introduce aquí la parte de la dirección que no reconoce Google Maps (número de planta, escalera, etc.).'
                        >
                          <label htmlFor='companyDetailAddressComplement'>
                            Complemento dirección
                          </label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailAddressComplement'
                          name='baseContact.address.addressComplement'
                          placeholder='n.3 '
                          value={
                            get(
                              this.state,
                              'companyDetails.baseContact.address.addressComplement'
                            ) || ''
                          }
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='hs-hr' />

                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <FormIBAN
                          name='baseContact.accountNumber'
                          initialValue={
                            get(this.state, 'companyDetails.baseContact.accountNumber') || ''
                          }
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title='El código SWIFT (Society for Worldwide Interbank Financial Telecommunication) o también denominado código BIC (Bank Identifier Code) es una serie alfanumérica de 8 u 11 dígitos que sirve para identificar al banco receptor cuando se realiza una transferencia internacional.'
                        >
                          <label htmlFor='companyDetailBicOrSwift'>BIC o SWIFT</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <input
                          type='text'
                          className='form-control'
                          id='companyDetailBicOrSwift'
                          name='baseContact.bicOrSwift'
                          placeholder='HOMYESMMXXX'
                          value={get(this.state, 'companyDetails.baseContact.bicOrSwift') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title='Titular de la cuenta bancaria a quien se le hará la transferencia.'
                        >
                          <label htmlFor='beneficiary'>Beneficiario</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <input
                          type='text'
                          className='form-control'
                          id='beneficiary'
                          name='baseContact.beneficiary'
                          placeholder='Titular de la cuenta'
                          value={get(this.state, 'companyDetails.baseContact.beneficiary') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title='¿Está el Cliente / Contacto / Proveedor registrada en el Registro de Operadores Intracomunitarios?'
                        >
                          <label htmlFor='companyDetailRegisteredInRoi'>Registrado en el ROI</label>{' '}
                          <i className='far fa-question-circle' />
                        </Tooltip>
                        <HomySelect
                          name='companyDetailRegisteredInRoi'
                          value={
                            get(this.state, 'companyDetails.baseContact.registeredInRoi') || ''
                          }
                          options={get(this.state, 'options.registeredInRoi')}
                          onChange={value => {
                            this.handleSelectChange('registeredInRoi', value);
                          }}
                        />
                      </div>
                    </div>
                    {get(this.state, 'isPremiumFFActive', false) && (
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <label htmlFor='companyServiceType'>Modelo de servicio</label>
                          <Select
                            id='companyServiceType'
                            name='serviceType'
                            value={get(this.state, 'companyDetails.serviceType') || 'standard'}
                            onChange={value => {
                              this.handleSelectChange('serviceType', value);
                            }}
                          >
                            <Select.Option value='standard'>Standard</Select.Option>
                            <Select.Option value='premium'>Premium</Select.Option>
                          </Select>
                        </div>
                      </div>
                    )}
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailSpecificContract'>&nbsp;</label>
                        <CheckboxButton
                          borderStyle='companyCheckboxActive'
                          id='companyDetailSpecificContract'
                          name='specificContract'
                          handleChange={this.handleChange}
                          content='Contrato específico'
                          value={get(this.state, 'companyDetails.specificContract', false)}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='hs-hr' />

                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailDescription'>Descripción</label>
                        <textarea
                          id='companyDetailDescription'
                          name='baseContact.description'
                          rows='8'
                          className='form-control'
                          placeholder='Descripción'
                          value={get(this.state, 'companyDetails.baseContact.description') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='form-group'>
                        <label htmlFor='companyDetailObservations'>Observaciones</label>
                        <textarea
                          id='companyDetailObservations'
                          name='baseContact.observations'
                          rows='4'
                          className='form-control'
                          placeholder='Observaciones'
                          value={get(this.state, 'companyDetails.baseContact.observations') || ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className='hs-hr' />

                  <div className='row'>
                    <div className='col-sm-12'>
                      <div style={{ fontSize: 14 }}>
                        <b style={{ marginRight: 5 }}>Condiciones generales:</b>
                        {hasAcceptedGeneralConditions
                          ? `Aceptadas ${moment(
                              get(this.state, 'companyDetails.generalConditionsDate')
                            ).format(DATE_FORMAT)}`
                          : 'No aceptadas'}
                        <br />
                        <b style={{ marginRight: 5 }}>Condiciones generales Premium:</b>
                        {hasAcceptedGeneralConditionsPremium
                          ? `Aceptadas ${moment(
                              get(this.state, 'companyDetails.generalConditionsPremiumDate')
                            ).format(DATE_FORMAT)}`
                          : 'No aceptadas'}
                        <br />
                        <b style={{ marginRight: 5 }}>Política de privacidad:</b>
                        {hasAcceptedPrivacyPolicy
                          ? `Aceptada ${moment(
                              get(this.state, 'companyDetails.privacyPolicyDate')
                            ).format(DATE_FORMAT)}`
                          : 'No aceptada'}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className='row'>
                    <div className='col-sm-12'>
                      <label htmlFor='companyDetailsGeneralConditionsPremium'>
                        <b>Condiciones generales Premium:</b>
                      </label>
                      <div className='form-group totalBorder'>
                        <RichTextEditor
                          id='companyDetailsGeneralConditionsPremium'
                          name='generalConditionsPremium'
                          ref='RichTextEditorGeneralConditionsPremium'
                          className='form-control'
                          value={get(this.state, 'generalConditionsPremium')}
                          onChange={this.handleChangeGeneralConditionsPremium}
                        />
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='Qualifiying Questions' key='2'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <QuestionsInfo
                        questions={this.state.qualifyingQuestions}
                        handleChange={this.handleQQChange}
                      />
                    </div>
                  </div>
                  {/* <div className='row'>
                    <div className='col-sm-12'>
                      <label>¿Gestionáis los viajes de negocios internamente?</label>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-3'>
                      
                    </div>
                    <div className='col-sm-3'></div>
                    <div className='col-sm-3'></div>
                    <div className='col-sm-3'></div>
                  </div> */}
                </TabPane>
              </Tabs>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveCompanyDetailsData: (show, additionalCompanyData) =>
        dispatch(saveCompanyDetailsData(show, additionalCompanyData)),
    },
  };
}

function mapStateToProps(state) {
  return {
    companyDetailsData: state.CompanyDetails,
    companyDetailsSubmit: state.CompanyDetails.submit,
    optionLabels: state.Preload.optionLabels,
    currentUser: state.Preload.currentUserInformation.username,
    companyMarketingSourceMediumOptions:
      state.Companies.invoiceMarketingSourceMediumOptions.options.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCompanyDetails);
