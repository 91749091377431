import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import find from 'lodash/find';
import includes from 'lodash/includes';

/**************************************************
PROPS TO CONFIGURE IN PARENT
mapStyle -> div's initial style ({width: '100%',height: '85vh'})
viewChangeCallback -> function to be called when the map's view changed
loadCallback -> (OPTIONAL) function to be called when the map is fully loaded
initializeLocation -> (OPTIONAL) check if yoy would like to initialize the map with the user's current position
clickCallback -> (OPTIONAL) function to be called when user click on a maps location
dblclickCallback -> (OPTIONAL) function to be called when user double click on a maps location

METHODS PUBLISHED:
clearMarkers() -> clear all markers in the map
setReferencePoint(latitude, longitude, radius, centerView) -> draw a reference point at a latitude and longitude.
  As option, you can draw a circle arround this point with the given radius
addMarker(latitude, longitude) -> add a marker at the given point
getBounds() -> return actual bounds of the map
setBounds() -> set actual bounds of the map
fitBoundsToMarkers() -> fit bounds of the map to markers, referencePoint an reference circle
fitMapBounds(bounds) -> fit map to a determinate bounds
getZoom() -> get actual zoom of the map
setZoom(value) -> set actual zoom to the passed value
addInfoWindowToMarker(id, content, marker) -> adds an infoWindow with id and the passed content to the specified marker
activateInteraction() -> activate the call to the viewChangeCallback function in zoom_changed and dragend
deactivateInteraction() -> deactivate the call to the viewChangeCallback function in zoom_changed and dragend
**************************************************/
const ICONS = {
  DEFAULT: {
    icon: '../img/map/mapIcon-07.png',
    zIndex: 1,
  },
  CONFIRMED: {
    icon: '../img/map/xxx.png',
    zIndex: 2,
  },
  VISITED: {
    icon: '../img/map/mapIcon-check.png',
    zIndex: 3,
  },
  CONSULTED_AVAILABILITY: {
    icon: '../img/map/mapIcon-availability.png',
    zIndex: 4,
  },
  CREATED: {
    icon: '../img/map/mapIcon-05.png',
    zIndex: 5,
  },
  CLICKED: {
    icon: '../img/map/mapIcon-06.png',
    zIndex: 6,
  },
};

class GoogleMapsMap extends Component {
  constructor(props) {
    super(props);
    if (!window.google) {
      return;
    }

    this.state = {
      maps: window.google.maps,
      map: {},
      markers: [],
      referencePoint: {},
      circleReference: {},
      initialPosition: {
        latitude: 39.46975,
        longitude: -0.37739,
      },
      previousInfoWindow: {},
      previousMarker: {},
    };

    this.getCurrentPosition = this.getCurrentPosition.bind(this);
    this.getBounds = this.getBounds.bind(this);
    this.setBounds = this.setBounds.bind(this);
    this.fitBoundsToMarkers = this.fitBoundsToMarkers.bind(this);
    this.getZoom = this.getZoom.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.clearMarkers = this.clearMarkers.bind(this);
    this.addMarker = this.addMarker.bind(this);
    this.addInfoWindowToMarker = this.addInfoWindowToMarker.bind(this);
    this.activateInteraction = this.activateInteraction.bind(this);
    this.deactivateInteraction = this.deactivateInteraction.bind(this);
    this.setReferencePoint = this.setReferencePoint.bind(this);
    this.setReferencePointAfterErase = this.setReferencePointAfterErase.bind(this);
    // this.addPropertyIdToVisited = this.addPropertyIdToVisited.bind(this);
    this.setPreviousMarker = this.setPreviousMarker.bind(this);
    this.clearInfoWindowVisibility = this.clearInfoWindowVisibility.bind(this);
  }

  componentDidMount() {
    if (this.props.initializeLocation) {
      this.getCurrentPosition();
    } else {
      this.loadMap();
    }
  }

  getCurrentPosition() {
    navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({
          initialPosition: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });

        this.loadMap(); // No lo pongas dentro del callback del setState, por lo que mas quieras. Enserio.
      },
      error => {
        console.error(error.message);
        this.loadMap();
      },
      { enableHighAccuracy: true, maximumAge: 1000 }
    );
  }

  clearMarkers() {
    for (var i = 0; i < this.state.markers.length; i++) {
      const marker = this.state.markers[i];

      marker.setMap(null);
    }

    this.setState({ markers: [] });
  }

  setReferencePoint(lat, lng, radius = null, centerView, drawMarker = true) {
    const { referencePoint, circleReference } = this.state;

    if (referencePoint.position) {
      referencePoint.setMap(null);
    }

    if (circleReference.map) {
      circleReference.setMap(null);
    }

    this.setState(
      {
        circleReference: {},
        referencePoint: {},
      },
      () => this.setReferencePointAfterErase(lat, lng, radius, centerView, drawMarker)
    );
  }

  setReferencePointAfterErase(lat, lng, radius = null, centerView, drawMarker = true) {
    if (isNil(lat) || !isNumber(lat)) {
      return;
    }

    if (isNil(lng) || !isNumber(lng)) {
      return;
    }

    if (!isObject(this.state.referencePoint)) {
      return;
    }

    const latLng = new this.state.maps.LatLng(lat, lng);

    let marker;

    if (drawMarker) {
      marker = new this.state.maps.Marker({
        map: this.state.map,
        position: latLng,
      });
    } else {
      const icon = '../img/pixel.png';

      marker = new this.state.maps.Marker({
        map: this.state.map,
        position: latLng,
        icon: icon,
      });
    }

    this.setState({ referencePoint: marker });
    if (centerView) {
      this.state.map.setCenter(latLng);
    }

    if (radius) {
      const circle = new this.state.maps.Circle({
        map: this.state.map,
        radius: radius,
        fillColor: '#eeaf30',
        fillOpacity: 0.35,
        strokeWeight: 0,
      });

      circle.bindTo('center', marker, 'position');
      this.setState({ circleReference: circle }, () => {
        if (centerView) {
          const auxBounds = circle.getBounds();

          if (auxBounds) {
            this.setBounds(auxBounds);
          }
        }
      });
    }

    this.props.viewChangeCallback();

    return marker;
  }

  setMarkerToVisited = () => {};

  addMarker(lat, lng, id, providerId) {
    if (isNaN(lat) || isNaN(lng)) {
      return;
    }

    let markerIcon = ICONS.DEFAULT;

    if (includes(this.props.propertiesVisited, id)) {
      markerIcon = ICONS.VISITED;
    }

    if (includes(this.props.consultedAvailabilityProviders, providerId)) {
      markerIcon = ICONS.CONSULTED_AVAILABILITY;
    }

    if (id === this.props.propertySelected) {
      markerIcon = ICONS.CLICKED;
    }

    if (includes(this.props.propertiesConfirmedAvailability, id)) {
      markerIcon = ICONS.CONFIRMED;
    }

    const bondedDealProposals = get(this.props, 'bondedDealProposals');

    if (!isNil(bondedDealProposals)) {
      const hasBondedDeal = get(
        find(bondedDealProposals, o => o.proposal.property.id === id),
        'proposal.property.id'
      );

      if (hasBondedDeal) {
        markerIcon = ICONS.CREATED;
      }
    }

    const latLng = new this.state.maps.LatLng(lat, lng);
    const marker = new this.state.maps.Marker({
      map: this.state.map,
      position: latLng,
      icon: markerIcon.icon,
      zIndex: markerIcon.zIndex,
      providerId,
    });

    if (id === this.props.propertySelected) {
      this.setPreviousMarker(marker);
    }

    const { markers } = this.state;

    markers.push(marker);
    this.setState({ markers: markers });

    return marker;
  }

  getBounds() {
    return this.state.map.getBounds();
  }

  setBounds(bounds) {
    this.state.map.fitBounds(bounds);
    this.state.map.setZoom(this.state.map.getZoom() + 1);
  }

  fitBoundsToMarkers() {
    var bounds = new this.state.maps.LatLngBounds();

    this.state.markers.forEach(marker => {
      bounds.extend(marker.getPosition());
    });
    if (this.state.referencePoint.position) {
      bounds.extend(this.state.referencePoint.getPosition());
    }

    this.state.map.fitBounds(bounds);
  }

  getZoom() {
    return this.state.map.getZoom();
  }

  setZoom(value) {
    this.deactivateInteraction();
    this.state.map.setZoom(value);
    this.activateInteraction();
  }

  clearInfoWindowVisibility = () => {
    this.setState({
      previousMarker: {},
    });
  };

  addInfoWindowToMarker(id, content, marker) {
    marker.addListener('click', () => {
      const previousMarker = get(this.state, 'previousMarker');

      marker.setIcon(ICONS.CLICKED.icon);
      marker.setZIndex(ICONS.CLICKED.zIndex);

      if (!isNil(previousMarker) && !isEmpty(previousMarker)) {
        let icon = ICONS.VISITED;

        if (includes(this.props.consultedAvailabilityProviders, previousMarker.providerId)) {
          icon = ICONS.CONSULTED_AVAILABILITY;
        }

        previousMarker.setIcon(icon.icon);
        previousMarker.setZIndex(icon.zIndex);

        if (JSON.stringify(marker.getPosition()) === JSON.stringify(previousMarker.getPosition())) {
          this.setState({ previousMarker: {} }, () => this.props.showInfoWindow(false));

          return;
        }
      }

      this.props.showInfoWindow(true, content);
      this.props.addPropertyIdToVisited(id);
      this.setPreviousMarker(marker);
    });
  }

  setPreviousMarker = marker => {
    this.setState({
      previousMarker: marker,
    });
  };

  activateInteraction() {
    this.state.maps.event.addListener(
      this.state.map,
      'zoom_changed',
      this.props.viewChangeCallback
    );
    this.state.maps.event.addListener(this.state.map, 'dragend', this.props.viewChangeCallback);
  }

  deactivateInteraction() {
    this.state.maps.event.clearListeners(this.state.map, 'zoom_changed');
    this.state.maps.event.clearListeners(this.state.map, 'dragend');
  }

  loadMap() {
    const { maps, initialPosition } = this.state;

    if (maps) {
      const { loadCallback, clickCallback, dblclickCallback } = this.props;

      const mapRef = this.refs.map;
      // eslint-disable-next-line react/no-find-dom-node
      const node = ReactDOM.findDOMNode(mapRef);

      if (!node) {
        return;
      }

      const zoom = 13;
      const lat = initialPosition.latitude;
      const lng = initialPosition.longitude;
      const center = new this.state.maps.LatLng(lat, lng);
      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
          zoomControl: true,
          zoomControlOptions: {
            // position: maps.ControlPosition.TOP_RIGHT,
            position: maps.ControlPosition.TOP_LEFT,
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: maps.ControlPosition.LEFT_TOP,
          },
          fullscreenControl: false,
          styles: [
            {
              featureType: 'poi.business',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            // {
            //   featureType: "poi.business",
            //   elementType: "geometry",
            //   stylers: [
            //     { color: "#CCFFFF" }
            //   ]
            // },
          ],
          // featureType: maps.featureType.POI,
        }
      );

      this.map = new this.state.maps.Map(node, mapConfig);
      this.setState({ map: this.map });

      if (!isNull(loadCallback) && isFunction(loadCallback)) {
        maps.event.addListenerOnce(this.map, 'idle', loadCallback);
      }

      if (!isNull(clickCallback) && isFunction(clickCallback)) {
        maps.event.addListener(this.map, 'click', clickCallback);
      }

      if (!isNull(dblclickCallback) && isFunction(dblclickCallback)) {
        maps.event.addListener(this.map, 'dblclick', dblclickCallback);
      }

      this.activateInteraction();
    }
  }

  render() {
    return (
      <div style={this.props.mapStyle} ref='map'>
        <div className='height100PerCent loading-background' />
      </div>
    );
  }
}

export default GoogleMapsMap;
