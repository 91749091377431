const getSortCondition = (sorter = '', entity = '') => {
  let condition;

  if (sorter.field) {
    condition = sorter.order === 'descend' ? '-' : '+';
    condition += sorter.field
      .replace(`${entity}.`, '')
      .replace('.username', '')
      .replace('hsagent', 'hsagent.username')
      .replace('provider.baseContact.address.', '')
      .replace('provider.baseContact.', '')
      .replace('company.baseContact.', '')
      .replace('company.baseContact.address.', '')
      .replace('contact.', '');
  }

  return condition;
};

const getTableHeight = () => {
  let height;

  if (window.innerHeight >= 977) {
    height = 660;
  }

  if (window.innerHeight >= 890 && window.innerHeight < 977) {
    height = 630;
  }

  if (window.innerHeight >= 712 && window.innerHeight < 890) {
    height = 570;
  }

  if (window.innerHeight >= 593 && window.innerHeight < 712) {
    height = 410;
  }

  if (window.innerHeight >= 550 && window.innerHeight < 593) {
    height = 225;
  }

  if (window.innerHeight < 550) {
    height = 85;
  }

  return height;
};

const getTableWidth = () => {
  let width;

  if (window.innerWidth > 1350 && window.innerWidth <= 1980) {
    width = 1700;
  }

  if (window.innerWidth > 992 && window.innerWidth <= 1350) {
    width = 1650;
  }

  if (window.innerWidth > 593 && window.innerWidth <= 992) {
    width = 1600;
  }

  return width;
};

export default {
  getSortCondition,
  getTableHeight,
  getTableWidth,
};
