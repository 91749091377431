/*
Why do we use defaultValue and value:
  https://github.com/ant-design/ant-design/issues/4347
  https://reactjs.org/docs/forms.html#controlled-components
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { parseStringValuesToComboValue } from '../../shared/utils/appUtils';
// import './HomySelect.css';

const { Option } = Select;

const HomySelect = props => (
  <>
    {props.label && <label htmlFor={props.name}>{props.label}</label>}
    <Select
      notFoundContent={props.notFoundContent}
      allowClear={props.allowClear}
      defaultValue={parseStringValuesToComboValue(props.defaultValue, props.options, props.mode)}
      value={parseStringValuesToComboValue(props.value, props.options, props.mode)}
      disabled={props.isDisabled}
      optionFilterProp='children'
      filterOption={(input, option) =>
        option.props.children
          .toString()
          .toLowerCase()
          .indexOf(input.toString().toLowerCase()) >= 0
      }
      id={props.name}
      name={props.name}
      onChange={e => props.onChange(e)}
      placeholder={props.placeholder}
      mode={props.mode}
      style={{
        width: '100%',
        padding: '0',
        cursor: 'pointer',
        height: '100%',
        ...props.style,
      }}
      showArrow={props.showArrow}
      showSearch={props.showSearch}
      className={`homy-select ${props.className}`}
      dropdownStyle={props.dropdownStyle}
    >
      {!isEmpty(props.options) &&
        props.options.map(option => (
          <Option
            className='homy-option'
            key={option.initialValue || option.value}
            value={option.initialValue || option.value}
          >
            {option.label}
          </Option>
        ))}
    </Select>
  </>
);
HomySelect.propTypes = {
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  mode: PropTypes.string,
  showArrow: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  showSearch: PropTypes.bool,
  notFoundContent: PropTypes.string,
};

HomySelect.defaultProps = {
  defaultValue: undefined,
  allowClear: false,
  className: '',
  isDisabled: false,
  label: '',
  onChange: null,
  placeholder: '',
  style: null,
  showArrow: true,
  mode: 'default',
  value: undefined,
  showSearch: false,
  notFoundContent: '',
};

export default HomySelect;
