import './react-dev-tools';
import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory, Router } from 'react-router';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { LocaleProvider } from 'antd';
import esES from 'antd/lib/locale-provider/es_ES';
import rootReducer from './shared/reducers/rootReducer';
import routes from './routes';
import { adjustI18 } from './shared/utils/appUtils';
import 'react-select/dist/react-select.css';
import 'react-slidedown/lib/slidedown.css';
import { composeWithDevTools } from 'redux-devtools-extension';
import 'whatwg-fetch';
import 'homy-ui/lib/styles.css';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

if (window.Cypress) {
  window.store = store;
}

adjustI18(esES);

ReactDOM.render(
  <LocaleProvider locale={esES}>
    <Provider store={store}>
      <Router routes={routes} history={browserHistory} />
    </Provider>
  </LocaleProvider>,
  document.getElementById('root')
);
