import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Alert } from 'antd';
import ImageCarousel from '../ImageCarousel';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 s3Bucket -> id of the s3's bucket wherethe images will be loaded
 imagesToRender -> array with the images to be rendered
 handleImagesChange -> function to callback when images change. The function must accept files array as parameter
 handleImageDelete -> function to be called when an image is deleted. This function must accept index of the image to be deleted.
 ******************************************************* */

class ImageManager extends Component {
  static BYTES_IN_MEGABYTE = 1048576;

  // (1 MB)
  static MAX_FILE_SIZE = 1048576;

  // (1 MB)
  static MAX_REQUEST_SIZE = 20971520; // (20 MB)

  constructor(props) {
    super(props);
    this.state = {
      imagesToRender: props.imagesToRender,
      s3bucket: props.s3bucket,
      submittingUploading: false,
      submittingDeleting: false,
      files: [],
      sizeExcededMessage: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.refreshState = this.refreshState.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.state.imagesToRender) !== JSON.stringify(nextProps.imagesToRender)) {
      this.setState({ imagesToRender: nextProps.imagesToRender });
    }

    if (this.state.s3bucket !== nextProps.s3bucket) {
      this.setState({ s3bucket: nextProps.s3bucket });
    }
  }

  refreshState() {
    this.setState({
      submittingUploading: false,
      submittingDeleting: false,
      files: [],
    });
  }

  handleDelete() {
    const { carousel } = this.refs;
    const indexSelectedImage = carousel.getSelectedImage();

    if (window.confirm('Vas a eliminar la fotografía seleccionada, ¿estás seguro?')) {
      this.props.handleImageDelete(indexSelectedImage);
    }
  }

  handleDrop(files) {
    let newFiles = [];
    let newSizeExcededMessage = null;
    let totalSize = 0;

    for (const file of this.state.files) {
      totalSize += file.size;
    }

    for (const file of files) {
      if (file.size > ImageManager.MAX_FILE_SIZE) {
        if (!newSizeExcededMessage) {
          newSizeExcededMessage = `Los siguientes archivos superan el tamaño máximo permitido (${(
            ImageManager.MAX_FILE_SIZE / ImageManager.BYTES_IN_MEGABYTE
          ).toFixed(1)} MB):`;
        }

        newSizeExcededMessage += ` ${file.name}`;
      } else {
        totalSize += file.size;
        if (totalSize > ImageManager.MAX_REQUEST_SIZE) {
          newSizeExcededMessage = ` Se ha superado el tamaño máximo de subida de fotografías (${(
            ImageManager.MAX_REQUEST_SIZE / ImageManager.BYTES_IN_MEGABYTE
          ).toFixed(1)} MB).`;
          break;
        }

        newFiles.push(file);
      }
    }

    newFiles = newFiles.concat(this.state.files);
    this.setState({
      files: newFiles,
      sizeExcededMessage: newSizeExcededMessage,
    });
  }

  handleUpload() {
    this.props.handleImagesChange(this.state.files);
  }

  render() {
    return (
      <div className='row'>
        <div className='col-sm-3'>
          <section>
            <div className='dropzone fontSizeSmall'>
              <Dropzone onDrop={this.handleDrop} accept='image/jpeg, image/png'>
                Arrastra las imágenes aquí o haz clic para seleccionarlas
              </Dropzone>
            </div>

            {this.state.sizeExcededMessage && (
              <div className='row'>
                <div className='col-sm-12'>
                  <Alert
                    message='Tamaño de archivo/s excedido'
                    description={this.state.sizeExcededMessage}
                    type='warning'
                    showIcon
                    closeText='Aceptar'
                    onClose={() => this.setState({ sizeExcededMessage: null })}
                  />
                </div>
              </div>
            )}

            {this.state.files.length > 0 && (
              <aside className='fontSizeSmall'>
                <span className='bold'>Ficheros seleccionados</span>
                <ul>
                  {this.state.files.map((f, i) => (
                    <li key={`file${i}`}>
                      {f.name} - {(f.size / ImageManager.BYTES_IN_MEGABYTE).toFixed(2)} MB
                    </li>
                  ))}
                </ul>
              </aside>
            )}
          </section>
          <button
            disabled={this.state.submittingUploading}
            type='button'
            className='btn btn-primary btn-block alignLeft'
            onClick={this.handleUpload}
          >
            Subir fotografías
          </button>
          <button
            disabled={this.state.submittingDeleting}
            type='button'
            className='btn btn-primary btn-block alignLeft'
            onClick={this.handleDelete}
          >
            Eliminar
          </button>
        </div>
        <div className='col-sm-9 carouselMaxi'>
          {this.state.imagesToRender && (
            <ImageCarousel imagesToRender={this.state.imagesToRender} ref='carousel' />
          )}
        </div>
      </div>
    );
  }
}

export default ImageManager;
