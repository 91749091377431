import {
  NEW_PROPOSAL_CREATE_ERROR,
  NEW_PROPOSAL_LOCATION_ERROR,
  NEW_PROPOSAL_LOCATION_SUCCESS,
  PROPOSALS_METASOURCE_ERROR,
  PROPOSALS_METASOURCE_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  proposalLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
  create: {
    error: false,
    errorDetail: '',
  },
};

const Proposals = (state = initialState, action) => {
  switch (action.type) {
    case PROPOSALS_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        proposalLocation: {
          location: '',
          error: false,
          errorDetail: '',
        },
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPOSALS_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        proposalLocation: {
          location: '',
          error: false,
          errorDetail: '',
        },
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_PROPOSAL_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        proposalLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_PROPOSAL_LOCATION_ERROR: {
      return Object.assign({}, state, {
        proposalLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_PROPOSAL_CREATE_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: true,
          errorDetail: action.createNewProposalErrorDetails,
        },
      });
    }

    default:
      return state;
  }
};

export default Proposals;
