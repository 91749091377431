import { browserHistory } from 'react-router';
import {
  INVOICES_METASOURCE_ERROR,
  INVOICES_METASOURCE_SUCCESS,
  INVOICES_NEXTINVOICEDATA_ERROR,
  INVOICES_NEXTINVOICEDATA_SUCCESS,
  INVOICES_TABLEDATA_ERROR,
  INVOICES_TABLEDATA_FILTER,
  INVOICES_TABLEDATA_SUCCESS,
  NEW_INVOICE_LOCATION_ERROR,
  NEW_INVOICE_LOCATION_SUCCESS,
} from '../../shared/actionTypes';

import InvoicesApi from './InvoicesApi';
import {
  extractErrorMessage,
  parseQueryString,
  createQueryString,
  getUrlBase,
} from '../../shared/utils/apiUtils';
import { getMetasourceLink } from '../../shared/utils/appUtils';
import isNil from 'lodash/isNil';

export const metasourceSuccess = metasourceLinks => ({
  type: INVOICES_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: INVOICES_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const filterTableDataInvoices = filterCriteria => ({
  type: INVOICES_TABLEDATA_FILTER,
  filterCriteria,
});

export const tableDataSuccess = (tableData, totalCount) => ({
  type: INVOICES_TABLEDATA_SUCCESS,
  tableData,
  totalCount,
});

export const tablaDataError = errorMessage => ({
  type: INVOICES_TABLEDATA_ERROR,
  errorDetail: errorMessage,
});
export const newInvoiceLocationSuccess = location => ({
  type: NEW_INVOICE_LOCATION_SUCCESS,
  location,
});

export const newInvoiceLocationError = errorMessage => ({
  type: NEW_INVOICE_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const nextInvoiceDataSuccess = nextInvoiceData => ({
  type: INVOICES_NEXTINVOICEDATA_SUCCESS,
  nextInvoiceData,
});

export const nextInvoiceDataError = errorMessage => ({
  type: INVOICES_NEXTINVOICEDATA_ERROR,
  errorDetail: errorMessage,
});

export const getInvoicesTableData = (purl = null) =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Invoices;
      const { metasourceStrings } = getState().Preload;
      let url = purl;

      if (!url) {
        url = getMetasourceLink(
          metasource,
          metasourceStrings,
          'INVOICE_MAIN_METASOURCE_INVOICES_PAGINATED'
        );
      }

      return InvoicesApi.getInvoicesTableData(url.href)
        .then(response => {
          dispatch(tableDataSuccess(response.data, response.headers['x-total-count']));
        })
        .catch(error => {
          console.error(error);
          dispatch(tablaDataError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const loadInvoicesMetasource = () =>
  function(dispatch, getState) {
    try {
      return InvoicesApi.getMetasource(getState().Preload.mainMetasource.invoices.href)
        .then(response => {
          dispatch(metasourceSuccess(response.data._links));
        })
        .catch(error => {
          console.error(error);
          dispatch(metasourceError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getNewInvoiceLocation = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Invoices.metasource,
      getState().Preload.metasourceStrings,
      'INVOICE_MAIN_METASOURCE_NEW_INVOICE'
    );

    return InvoicesApi.getNewInvoiceLocation(url.href)
      .then(response => {
        dispatch(newInvoiceLocationSuccess(response.headers.location));
      })
      .catch(error => {
        console.error(error);
        dispatch(newInvoiceLocationError(extractErrorMessage(error)));
      });
  };

export const getNextInvoiceData = (invoiceType, proposalIds = []) =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Invoices;
      const { metasourceStrings } = getState().Preload;
      let url = getMetasourceLink(
        metasource,
        metasourceStrings,
        'INVOICE_MAIN_METASOURCE_NEXT_INVOICE'
      );

      if (invoiceType) {
        const urlBase = getUrlBase(url.href);
        const urlParsed = parseQueryString(url.href);
        urlParsed['invoiceType'] = invoiceType;
        if (!isNil(proposalIds)) {
          urlParsed['proposalIds'] = proposalIds.join(',');
        }
        url = { href: `${urlBase}?${createQueryString(urlParsed)}` };
      }

      return InvoicesApi.getNextInvoiceData(url.href)
        .then(response => {
          dispatch(nextInvoiceDataSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(nextInvoiceDataError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };
