import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import FormCompanyContacts from '../FormCompanyContacts';
import {
  filterCompanyContactsData,
  getCompanyContactsData,
  resetState as resetStateCompanyContacts,
} from '../../modules/CompanyDetails/CompanyDetailsActions';

// PARAMETROS A CONFIGURAR
// leftOffset -> columnas bootstrap a desplazer el botón
// filterContacts -> (OPTIONAL) filter contacts from the specified context (DEAL, etc.)
// mainView -> main view in wich this component is included (optional)

class InfoButtonCompanyContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyContacts: false,
      companyContacts: {},
      companyContactsVersion: props.companyContactsVersion,
    };
    this.showCompanyContacts = this.showCompanyContacts.bind(this);
    this.refreshCompanyDetailsData = this.refreshCompanyDetailsData.bind(this);
  }

  componentDidMount() {
    this.props.actions.getCompanyContactsData();
  }

  componentWillUnmount() {
    this.props.actions.resetStateCompanyContacts();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.state.companyContacts) !==
      JSON.stringify(nextProps.companyContacts.detail)
    ) {
      this.setState({ companyContacts: nextProps.companyContacts.detail }, () => {
        if (this.props.filterContacts) {
          if (this.props.filterContacts === 'DEAL') {
            this.props.actions.filterCompanyContactsData(this.props.dealDetails.contactsOfCompany);
          }
        }
      });
    }

    if (JSON.stringify(this.props.companyDetails) !== JSON.stringify(nextProps.companyDetails)) {
      this.props.actions.getCompanyContactsData();
    }
  }

  componentDidUpdate() {
    if (document.getElementById('infoButtonCompanyContacts')) {
      if (this.state.showCompanyContacts) {
        document.getElementById('infoButtonCompanyContacts').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonCompanyContacts').style.zIndex = 1;
      }
    }
  }

  showCompanyContacts(event) {
    if (event.target.nodeName !== 'A') {
      const showCompanyContacts = !this.state.showCompanyContacts;

      this.setState({
        showCompanyContacts,
      });
      if (showCompanyContacts) {
        this.props.actions.getCompanyContactsData();
      }
    }
  }

  refreshCompanyDetailsData() {
    this.props.actions.getCompanyContactsData();
    // setTimeout(
    //   ()=>{
    //     this.setState({
    //       showCompanyContacts:false
    //     });
    //     this.setState({
    //       showCompanyContacts:true
    //     });
    //     window.scrollTo(0,0);
    //   }
    // ,500)
  }

  render() {
    let leftOffset = 0;

    if (this.props.leftOffset) {
      leftOffset = this.props.leftOffset * -50;
    }

    const styleInfoButtonDetail = {
      marginLeft: `${leftOffset}%`,
    };

    return (
      <div id='infoButtonCompanyContacts' className='col-sm-3 noPadding'>
        {this.props.companyContacts.error && (
          <Alert
            message='Error'
            description={this.props.companyContacts.errorDetail}
            type='error'
            showIcon
          />
        )}
        <div
          className='card card-inverse contactBackgroundColor bold infoButton'
          onClick={this.showCompanyContacts}
        >
          <div className='card-block'>
            <div className='row positionRelative'>
              <div className='col-sm-12'>
                <h1 className='nexa-bold'>
                  {this.state.companyContacts ? this.state.companyContacts.length : 0}{' '}
                </h1>
                contactos
              </div>
            </div>
            {this.state.showCompanyContacts && (
              <div className='openIcon'>
                <i className='fa fa-caret-down fa-2x' />
              </div>
            )}
          </div>
        </div>
        {this.state.showCompanyContacts && (
          <div
            style={styleInfoButtonDetail}
            className='infoButtonDetail cardNoMargin noPadding animated fadeIn contactBorderTopColor'
          >
            <div className='card'>
              <div className='card-block'>
                <FormCompanyContacts
                  mainView={this.props.mainView}
                  callbackFunctionOnUpdateOrSave={this.refreshCompanyDetailsData}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCompanyContactsData: () => dispatch(getCompanyContactsData()),
      filterCompanyContactsData: listOfContacts =>
        dispatch(filterCompanyContactsData(listOfContacts)),
      resetStateCompanyContacts: () => dispatch(resetStateCompanyContacts()),
    },
  };
}

function mapStateToProps(state) {
  return {
    companyDetails: state.CompanyDetails.data.detail,
    companyContacts: state.CompanyDetails.contacts,
    companyContactsVersion: state.CompanyDetails.contactsVersion,
    dealDetails: state.DealDetails.data.detail,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButtonCompanyContacts);
