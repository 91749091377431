/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import InputAntdValidator from './InputAntdValidator';
import HomyNIF from '../HomyComponents/HomyNIF';

class FormNIF extends Component {
  static propTypes = {
    fieldDecorator: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    form: PropTypes.shape({}).isRequired,
    help: PropTypes.string,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isRequiredMessage: PropTypes.string,
    isWrongMessage: PropTypes.string,
    label: PropTypes.string,
    initialValue: PropTypes.string,
    validator: PropTypes.func,
  };

  static defaultProps = {
    help: undefined,
    isDisabled: false,
    isRequired: false,
    isRequiredMessage: 'Por favor, introduce un NIF',
    isWrongMessage: 'Introduce un NIF válido',
    label: '',
    validator: null,
    initialValue: '',
  };

  render() {
    const {
      fieldDecorator,
      form,
      help,
      isDisabled,
      isRequired,
      isRequiredMessage,
      isWrongMessage,
      label,
      name,
      validator,
      initialValue,
    } = this.props;

    return (
      <InputAntdValidator
        fieldDecorator={fieldDecorator}
        form={form}
        help={help}
        isRequired={isRequired}
        isRequiredMessage={isRequiredMessage}
        isWrongMessage={isWrongMessage}
        label={label}
        validator={validator}
        initialValue={initialValue}
      >
        <HomyNIF disabled={isDisabled} name={name} label='' />
      </InputAntdValidator>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    return {
      [props.name]: Form.createFormField({
        ...props,
      }),
    };
  },
})(FormNIF);
