import React, { Component } from 'react';
import { Table } from 'antd';
import numeral from 'numeral';
import { externalPriceAdjustment, openNotificationWithIcon } from '../../shared/utils/appUtils';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 data -> data to show in table
 loading -> to show a spiner
 ******************************************************* */

class TableDataPmas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pmasList: {
        list: {
          pmas: [],
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.url) {
      this.setState({ loading: true }, () => this.props.actions.getPmaTableData(this.props.url));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pmasList && nextProps.pmasList.error) {
      openNotificationWithIcon('error', 'Oppss :(', nextProps.pmasList.errorDetail);
    } else if (JSON.stringify(nextProps.pmasList) !== JSON.stringify(this.state.pmasList)) {
      this.setState({
        pmasList: nextProps.pmasList,
        loading: false,
      });
    }

    if (this.props.url !== nextProps.url) {
      this.props.actions.getPmaTableData(nextProps.url);
    }
  }

  render() {
    const columns = [
      {
        title: 'Ciudad',
        dataIndex: 'city',
      },
      {
        title: 'Código postal',
        dataIndex: 'zipCode',
      },
      {
        title: 'Semana',
        dataIndex: 'week',
      },
      {
        title: 'Habitaciones',
        dataIndex: 'rooms',
      },
      {
        title: 'Precio homybrain',
        dataIndex: 'homybrainPrice',
        render: record => (record ? numeral(record.amount).format('0.00') : ''),
      },
      {
        title: 'Muestras homybrain',
        dataIndex: 'homybrainCount',
      },
      {
        title: 'Precio externo',
        dataIndex: 'externalPrice',
        render: record => externalPriceAdjustment(record),
      },
      {
        title: 'Muestras externas',
        dataIndex: 'externalCount',
      },
    ];

    return (
      <div className='animated fadeIn'>
        <div className='col-sm-12'>
          {this.props.data && (
            <div>
              <Table
                dataSource={this.props.data}
                className='fixed-width-table'
                columns={columns}
                rowKey={rec => `${rec.city}-${rec.zipCode}-${rec.week}-${rec.rooms}`}
                pagination={{ pageSize: 9 }}
                loading={this.props.loading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TableDataPmas;
