import React from 'react';
import { Alert, Col, Row } from 'antd';

export const DeletedPropertyInfo = props =>
  props.visible && (
    <>
      <Row>
        <Col span={24}>
          <Alert
            message={'¡Ojo! Estás accediendo a un inmueble deshabilitado'}
            description={
              'Esto significa que no aparecerá en ninguna búsqueda para una oportunidad, ni se podrán realizar propuestas con él.'
            }
            type='warning'
            showIcon
          />
        </Col>
      </Row>
      <br />
    </>
  );
