import { getAxiosInstance } from '../../shared/utils/apiUtils';
import api from '../../shared/utils/api';
import { resolveParams } from '../../shared/utils/appUtils';

class EventsApi {
  static getEventListData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getEventTotalCounts(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getNewBusinessEventLocation(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(url);
  }

  static saveBusinessEventData(url, businessEvent, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, businessEvent);
  }

  static deleteBusinessEvent(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.delete(url);
  }

  /**
   *
   * @param {*} businessEvent payload for the business event
   * @param {*} businessEventType from BUSINESS_EVENT_TYPES in appUtils
   * @param {*} params dealId, contactId,... to which this business event applies
   */
  static async createNewBusinessEvent(businessEvent, businessEventType, params) {
    //1. Get location with api.js (or getNewBusinessEventLocation)
    //2. Save businessEvent with this.saveBusinessEventData
    //3. Return result to DealDetails and call there to createNewBusinessEventSuccess
    //   redux action to avoid two way dependencies (EventsApi <-> EventsActions)
    let res;

    try {
      // res = await this.getNewBusinessEventLocation(`${ENDPOINT}/businessevent`);
      params = { ...params, type: businessEventType };
      res = await api.generateId('businessevent', params);
    } catch (e) {
      console.error('Error retrieving location for a new Business Event' + e);
      return;
    }

    try {
      const location = res.headers.get('location');
      const cleanUrl = resolveParams(location);
      return await api.saveUrl(cleanUrl, businessEvent);
    } catch (e) {
      console.error('Error saving new Business Event' + e);
    }
  }
}

export default EventsApi;
