import IBAN from 'iban';
import moment from 'moment';
import isNil from 'lodash/isNil';

const isValidInput = input => {
  if (input.className.indexOf('validate-accountNumber') >= 0) {
    if (input.value === '') {
      return true;
    }

    const ibanValue = IBAN.electronicFormat(input.value);

    return IBAN.isValid(ibanValue);
  }

  if (input.className.indexOf('validate-required') >= 0) {
    let inputValue = input.value;

    if (inputValue) {
      inputValue = inputValue.trim();
    } else {
      inputValue = input.textContent;
      inputValue = inputValue.replace('Select...', '');
    }

    return !(!inputValue || inputValue === '');
  }

  return true;
};

export const markAsInvalid = element => {
  element.className += ' invalid-input';
};

export const markAsValid = element => {
  element.className = element.className.replace(' invalid-input', '');
};

export const validateInputs = inputsToValidate => {
  let validInputs = true;

  for (let i = 0; i < inputsToValidate.length; i++) {
    const inputToValidate = inputsToValidate[i];

    if (!isValidInput(inputToValidate)) {
      markAsInvalid(inputToValidate);
      validInputs = false;
    } else {
      markAsValid(inputToValidate);
    }
  }

  return validInputs;
};

export const normalizePhoneNumber = value => (value ? value.replace(new RegExp(' ', 'g'), '') : '');

export const normalizeNumberToDecimals = (num, decimals) => {
  if (isNil(num)) {
    return null;
  }

  const decimalConstant = Math.pow(10, decimals);

  return Math.round(num * decimalConstant) / decimalConstant;
};

export const checkDateBefore = (before, after) => {
  if (!before || !after) {
    return false;
  }

  if (moment(before).diff(moment(after)) > 0) {
    return false;
  }

  return true;
};

export const pairRoundAmount = number => {
  if (!number) {
    return 0.0;
  }

  number = Math.round(number * 1000000000000.0) / 1000000000000.0;
  const stringNum = String(number);
  let numDecimals;
  let strDecimalPart;

  if (stringNum.indexOf('.') > -1) {
    strDecimalPart = stringNum.split('.')[1];
    numDecimals = strDecimalPart.length;
  } else {
    numDecimals = 0;
    strDecimalPart = '';
  }

  if (numDecimals <= 2) {
    return number;
  }

  if (numDecimals === 3 && strDecimalPart.endsWith('5')) {
    const isFirstDecimalZero = parseInt(strDecimalPart.substring(0, 1), 10) === 0;
    let decimalsRounded = parseInt(strDecimalPart.substring(0, 2), 10);
    const previousDecimal = parseInt(strDecimalPart.substring(1, 2), 10);

    if (previousDecimal % 2 !== 0) {
      // ODD - round up
      decimalsRounded++;
      // EVEN - mantain the previous decimals
    }

    const fractionalPart = number % 1;
    const integralPart = number - fractionalPart;
    const decimalPart =
      isFirstDecimalZero && previousDecimal !== 9 ? `${0}${decimalsRounded}` : decimalsRounded;

    if (decimalPart === 100) {
      // for example whe rounding X.995, decimal part becomes 100
      return integralPart + 1;
    }

    return parseFloat(integralPart) + parseFloat((integralPart < 0 ? '-0.' : '0.') + decimalPart);
  }

  return Math.round(number * 100.0) / 100.0;
};
