import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Row } from 'antd';
import Select from 'react-select';
import { createNewContact } from '../../modules/Contacts/ContactsActions';
import { saveContactDetailsDataFromParent } from '../../modules/ContactDetails/ContactDetailsActions';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import { getOptionsForSelect, openNotificationWithIcon } from '../../shared/utils/appUtils';
import SelectContact from '../SelectContact';
import isNil from 'lodash/isNil';
// PARAMETROS A CONFIGURAR
// callbackFuncionOnCreate -> función a llamar cuando se ha creado un nuevo contacto
// mainView -> main view in wich this component is included (optional)
// providerOrCompany -> provider or company for which the contact is being created (required)

class FormNewContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newContact: {
        parent: this.props.providerOrCompany ? this.props.providerOrCompany.id : null,
        contactType: '',
        name: '',
        lastOTradeName: '',
        email: '',
        email2: '',
        phoneNumber: '',
        mobilePhoneNumber: '',
        creationDate: new Date(),
        fullParentContact: {},
      },
      editingContact: this.props.editingContact ? this.props.editingContact : false,
      editContact: this.props.editContact ? this.props.editContact : {},
      newParent: this.props.providerOrCompany ? this.props.providerOrCompany.id : null,
      submitting: false,
      invalidInput: false,
      options: {
        contactType: getOptionsForSelect(this.props.optionLabels, 'ContactType'),
        contactRole: this.isProviderContact()
          ? getOptionsForSelect(this.props.optionLabels, 'ProviderContactRole')
          : this.isCompanyContact()
          ? getOptionsForSelect(this.props.optionLabels, 'CompanyContactRole')
          : getOptionsForSelect(this.props.optionLabels, 'ContactRole'),
      },
      showContactRoleField: this.props.mainView !== 'Contacts',
      contactCreateSuccess: false,
      contactUpdateSuccess: false,
    };
    this.defaultContact = Object.assign({}, this.state.newContact);

    this.props.contactCreate.error = false;

    this.selectExistingContactPrefilter = '';
    this.selectParentContactPrefilter = '';
    this.showExistingContactField = true;
    if (this.props.mainView) {
      if (this.props.mainView === 'ProviderDetails') {
        this.selectExistingContactPrefilter = '&provOrComp=NONE';
        this.selectParentContactPrefilter = `&providerId=${this.props.providerOrCompany.id}`;
      } else if (this.props.mainView === 'CompanyDetails') {
        this.selectExistingContactPrefilter = '&provOrComp=NONE';
        this.selectParentContactPrefilter = `&companyId=${this.props.providerOrCompany.id}`;
      } else if (this.props.mainView === 'Contacts') {
        this.showExistingContactField = false;
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectExistingContactChange = this.handleSelectExistingContactChange.bind(this);
    this.editFunctionOnContactUnlink = this.editFunctionOnContactUnlink.bind(this);
    this.handleSelectParentChange = this.handleSelectParentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.isProviderContact = this.isProviderContact.bind(this);

    if (this.state.editingContact) {
      this.editFunctionOnContactUnlink(this.state.editContact, this.props.contactType);
    }
  }

  isProviderContact() {
    return (
      this.props.providerOrCompany &&
      this.props.providerOrCompany.baseContact &&
      this.props.providerOrCompany.baseContact.relatedProvider
    );
  }

  isCompanyContact() {
    return (
      this.props.providerOrCompany &&
      this.props.providerOrCompany.baseContact &&
      this.props.providerOrCompany.baseContact.relatedCompany
    );
  }

  editFunctionOnContactUnlink(contact, contactType) {
    const editContact = contact;

    editContact.contact.contactRole = [];
    for (let i = 0; i < editContact.contact[contactType].roles.length; i++) {
      editContact.contact.contactRole[i] = this.state.options.contactRole.find(
        e => e.value === editContact.contact[contactType].roles[i]
      );
    }
  }

  // componentDidUpdate(prevProps) {
  //   const props = this.props;
  //   debugger;
  //   if (
  //     prevProps.creationSuccess !== props.creationSuccess ||
  //     prevProps.contactUpdateSuccess !== props.contactUpdateSuccess
  //   ) {
  //     openNotificationWithIcon(
  //       'success',
  //       'Todo OK :)',
  //       'El contacto se ha guardado correctamente'
  //     );

  //     this.setState(
  //       {
  //         submitting: false,
  //         contactUpdateSuccess: false,
  //         contactCreateSuccess: false,
  //       },
  //       () => this.props.callbackFuncionOnCreate()
  //     );
  //   }
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contactCreateSuccess, contactUpdateSuccess } = this.state;

    if (
      (!isNil(contactCreateSuccess) && nextProps.contactCreateSuccess !== contactCreateSuccess) ||
      (!isNil(contactUpdateSuccess) && nextProps.contactUpdateSuccess !== contactUpdateSuccess)
    ) {
      openNotificationWithIcon('success', 'Todo OK :)', 'El contacto se ha guardado correctamente');

      this.props.callbackFuncionOnCreate();
    }

    this.setState({
      submitting: false,
      contactCreateSuccess: false,
      contactUpdateSuccess: false,
    });
  }

  handleChange(e) {
    const localNewContact = this.state.newContact;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'phoneNumber' || e.target.name === 'mobilePhoneNumber') {
      value = normalizePhoneNumber(value);
    }

    localNewContact[e.target.name] = value;
    this.setState({ newContact: localNewContact });
  }

  // eslint-disable-next-line no-unused-vars
  handleSelectChange(name, selectedOption, obj) {
    if (this.state.editingContact) {
      const { editContact } = this.state;

      editContact.contact[name] = selectedOption
        ? Array.isArray(selectedOption)
          ? selectedOption
          : selectedOption.value
        : null;
      this.setState({ editContact });
    } else {
      const localNewContact = this.state.newContact;

      localNewContact[name] = selectedOption
        ? Array.isArray(selectedOption)
          ? selectedOption
          : selectedOption.value
        : null;
      this.setState({ newContact: localNewContact });
    }
  }

  handleSelectParentChange(name, selectedOption, obj) {
    const localNewContact = this.state.newContact;

    localNewContact.fullParentContact = obj;
    let { showContactRoleField } = this.state;

    if (this.props.mainView === 'Contacts') {
      if (!obj) {
        showContactRoleField = false;
      } else if (obj.relatedCompany || obj.relatedProvider) {
        showContactRoleField = true;
      } else {
        showContactRoleField = false;
      }
    }

    this.setState({
      newContact: localNewContact,
      newParent: obj ? obj.id : this.defaultContact.parent,
      showContactRoleField,
    });
  }

  handleSelectExistingContactChange(name, selectedOption, obj) {
    if (obj) {
      this.setState({ newContact: obj });
    } else {
      this.setState({ newContact: this.defaultContact });
    }
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !validForm });

    return validForm;
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.isValidForm()) {
      this.setState({ submitting: true }, () => {
        let contact = {};

        if (this.state.editingContact) {
          contact = this.state.editContact.contact;
        } else {
          contact = this.state.newContact;
        }

        if (this.props.providerOrCompany) {
          contact.fullParentContact = this.props.providerOrCompany.baseContact;
        }

        if (contact.fullParentContact) {
          if (contact.fullParentContact.relatedProvider) {
            contact.relatedProvider = {};
            contact.relatedProvider.idOfContact =
              contact.fullParentContact.relatedProvider.idOfContact;
            contact.relatedProvider.roles = contact.contactRole.map(r => r.value);
          } else if (contact.fullParentContact.relatedCompany) {
            contact.relatedCompany = {};
            contact.relatedCompany.idOfContact =
              contact.fullParentContact.relatedCompany.idOfContact;
            contact.relatedCompany.roles = contact.contactRole.map(r => r.value);
          } else {
            contact.relatedCompany = null;
          }
        }

        if (contact.id) {
          if (this.state.editingContact) {
            this.props.actions.saveContactDetailsDataFromParent(contact, contact.parent);
          } else {
            contact.parent = this.state.newParent;
            this.props.actions.saveContactDetailsDataFromParent(contact, this.state.newParent);
          }
        } else {
          this.props.actions.createNewContact(
            this.props.contactLocation,
            contact,
            this.state.newParent
          );
        }
      });
    }
  };

  render() {
    return (
      <div>
        {this.props.contactCreate.error && (
          <div>
            <Alert
              message='¡Ojo!'
              description={this.props.contactCreate.errorDetail}
              type='error'
              showIcon
              closable
            />
            <br />
          </div>
        )}
        {this.state.invalidInput && (
          <div>
            <Alert
              message='Error'
              description='Se te ha pasado rellenar algun campo obligatorio!'
              type='error'
              showIcon
              closable
            />
            <br />
          </div>
        )}
        <form id='formNewContact' onSubmit={this.handleSubmit}>
          {this.showExistingContactField && !this.state.editingContact && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={24}>
                <SelectContact
                  name='existingContact'
                  id='existingContact'
                  label='Contacto existente'
                  isRequired={false}
                  handleChange={this.handleSelectExistingContactChange}
                  prefilter={this.selectExistingContactPrefilter}
                />
              </Col>
            </Row>
          )}
          {this.state.editingContact && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={24}>
                <SelectContact
                  name='existingContact'
                  label='Contacto'
                  isRequired={false}
                  handleChange={this.handleSelectExistingContactChange}
                  prefilter={this.selectExistingContactPrefilter}
                  value={this.state.editContact.contact.id}
                  disabled
                />
              </Col>
            </Row>
          )}
          {!this.state.editingContact && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={24}>
                <SelectContact
                  name='parent'
                  id='parentContact'
                  label='Contacto padre'
                  isRequired={false}
                  handleChange={this.handleSelectParentChange}
                  prefilter={this.selectParentContactPrefilter}
                />
              </Col>
            </Row>
          )}
          {!this.state.editingContact && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={24}>
                <label htmlFor='contactContactType' className='required'>
                  Tipo
                </label>
                <Select
                  name='contactType'
                  className='form-control validate validate-required'
                  value={
                    this.state.newContact && this.state.newContact.contactType
                      ? this.state.newContact.contactType
                      : ''
                  }
                  options={this.state.options.contactType}
                  onChange={value => {
                    this.handleSelectChange('contactType', value);
                  }}
                />
              </Col>
            </Row>
          )}
          {this.state.showContactRoleField && !this.state.editingContact && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={24}>
                <label htmlFor='contactRole' className='required'>
                  Función
                </label>
                <Select
                  name='contactRole'
                  className='form-control validate validate-required'
                  value={
                    this.state.newContact && this.state.newContact.contactRole
                      ? this.state.newContact.contactRole
                      : ''
                  }
                  options={this.state.options.contactRole}
                  onChange={value => {
                    this.handleSelectChange('contactRole', value);
                  }}
                  multi
                />
              </Col>
            </Row>
          )}
          {this.state.editingContact && (
            <Row style={{ marginBottom: '10px' }}>
              <Col span={24}>
                <label htmlFor='contactRole' className='required'>
                  Función
                </label>
                <Select
                  name='contactRole'
                  className='form-control validate validate-required'
                  value={
                    this.state.editContact && this.state.editContact.contact.contactRole
                      ? this.state.editContact.contact.contactRole
                      : ''
                  }
                  options={this.state.options.contactRole}
                  onChange={value => {
                    this.handleSelectChange('contactRole', value);
                  }}
                  multi
                />
              </Col>
            </Row>
          )}
          {!this.state.editingContact && (
            <div>
              <Row>
                <Col span={24}>
                  <label htmlFor='contactName' className='required'>
                    Nombre
                  </label>
                  <input
                    type='text'
                    className='form-control validate validate-required'
                    id='contactName'
                    name='name'
                    placeholder='Ana'
                    value={
                      this.state.newContact && this.state.newContact.name
                        ? this.state.newContact.name
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={24}>
                  <label htmlFor='contactLastOTradeName'>Apellidos / Razón social</label>
                  <input
                    type='text'
                    className='form-control'
                    id='contactLastOTradeName'
                    name='lastOTradeName'
                    placeholder='García García'
                    value={
                      this.state.newContact && this.state.newContact.lastOTradeName
                        ? this.state.newContact.lastOTradeName
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <br />
              {(this.state.newContact.contactType === '' ||
                this.state.newContact.contactType === 'PERSON') && (
                <div>
                  <Row>
                    <Col span={24}>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label htmlFor='contactJobTitle'>Cargo</label>
                            <input
                              type='text'
                              className='form-control'
                              id='jobTitle'
                              name='jobTitle'
                              placeholder='Ceo'
                              value={
                                this.state.newContact && this.state.newContact.jobTitle
                                  ? this.state.newContact.jobTitle
                                  : ''
                              }
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
              <Row>
                <Col span={24}>
                  <label htmlFor='contactLastOTradeName'>NIF</label>
                  <input
                    type='text'
                    className='form-control'
                    id='contactIdentificationCode'
                    name='identificationCode'
                    placeholder='12345678Z'
                    value={
                      this.state.newContact && this.state.newContact.identificationCode
                        ? this.state.newContact.identificationCode
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <br />

              <Row>
                <Col span={24}>
                  <label htmlFor='contactEmail'>Email</label>
                  <input
                    type='email'
                    className='form-control'
                    id='contactEmail'
                    name='email'
                    placeholder='pruebas@pruebas.com'
                    value={
                      this.state.newContact && this.state.newContact.email
                        ? this.state.newContact.email
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <br />

              <Row>
                <Col span={24}>
                  <label htmlFor='contactPhoneNumber'>Teléfono fijo</label>
                  <input
                    type='text'
                    className='form-control'
                    id='contactPhoneNumber'
                    name='phoneNumber'
                    placeholder='961231212'
                    value={
                      this.state.newContact && this.state.newContact.phoneNumber
                        ? this.state.newContact.phoneNumber
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <br />

              <Row>
                <Col span={24}>
                  <label htmlFor='contactMobilePhoneNumber'>Teléfono móvil</label>
                  <input
                    type='text'
                    className='form-control'
                    id='contactMobilePhoneNumber'
                    name='mobilePhoneNumber'
                    placeholder='616232323'
                    value={
                      this.state.newContact && this.state.newContact.mobilePhoneNumber
                        ? this.state.newContact.mobilePhoneNumber
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <br />
            </div>
          )}

          <Row type='flex' justify='end'>
            <Col>
              <Button
                htmlType='submit'
                style={{
                  background: '#eeaf30',
                  color: 'white',
                  marginTop: '18px',
                }}
                loading={this.state.submitting}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createNewContact: (url, contactData, parent) =>
        dispatch(createNewContact(url, contactData, parent)),
      saveContactDetailsDataFromParent: (contact, parent) =>
        dispatch(saveContactDetailsDataFromParent(contact, parent)),
    },
  };
}

function mapStateToProps(state) {
  return {
    contactCreate: state.Contacts.contactCreate,
    contactLocation: state.Contacts.contactLocation.location,
    optionLabels: state.Preload.optionLabels,
    contactCreateSuccess: state.Contacts.contactCreateSuccess,
    contactUpdateSuccess: state.ContactDetails.contactUpdateSuccess,
    contactUpdateCount: state.ContactDetails.contactUpdateCount,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormNewContact);
