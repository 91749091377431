import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { InputNumber, Select, Table } from 'antd';
import moment from 'moment';
import { getRequestsTableData } from '../../modules/Requests/RequestsActions';
import { DATE_FORMAT } from '../../shared/appConfig';
import { createNewDealFromRequest } from '../../modules/Deals/DealsActions';
import HomyPagination from '../HomyComponents/HomyPagination';
import tableUtils from '../../shared/utils/tableUtils';
import RequestObservations from './RequestObservations';
import RequestFollowUps from './RequestFollowUps';
import api from '../../shared/utils/api';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import get from 'lodash/get';

class TableDataRequests extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.data) !== JSON.stringify(newProps.data)) {
      this.setState({
        data: newProps.data,
      });
    }
  }

  async redirectToRequestOrDealDetails(id) {
    try {
      const res = await api.getDetailData('request', id);
      const request = get(res, 'json.request', []);

      const { convertedDealId = '' } = id;
      convertedDealId
        ? browserHistory.push(`/dealDetails/${convertedDealId}`)
        : this.props.actions.createNewDealFromRequest(request);
    } catch (e) {
      console.error(e);
      openNotificationWithIcon('error', 'No se ha podido obtener la información de la Solicitud');
    }
  }

  render() {
    const columns = [
      {
        title: 'Fecha de creación',
        dataIndex: 'request.creationDate',
        width: 100,
        sorter: true,
        render: val => (
          <span
            style={{
              color: '#eeaf30',
              textDecoration: 'none',
              cursor: 'pointer',
              fontFamily: 'Open Sans Bold',
            }}
          >
            {moment(val).format(DATE_FORMAT)}
          </span>
        ),
        onCellClick: (record, event) => {
          event.preventDefault();
          this.redirectToRequestOrDealDetails(record.request.id);
        },
      },
      {
        title: 'Gestor asignado',
        dataIndex: 'request.hsAgent',
        width: 150,
        sorter: true,
        render: (text, record) => (
          <Select
            name='agentSelected'
            placeholder='Selecciona al Gestor'
            defaultValue={text}
            onChange={value => this.props.handleChangeAgent(value, record)}
            allowClear={false}
            showSearch
          >
            {this.props.salesAgents.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: 'Descripción',
        dataIndex: 'request.observations',
        width: 100,
        sorter: false,
        render: (text, record) => (
          <RequestObservations
            text={text}
            record={record}
            refresh={this.props.loadTableData}
          ></RequestObservations>
        ),
      },
      {
        title: 'Cliente',
        dataIndex: 'request.companyName',
        width: 200,
        sorter: true,
        render: text => (
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              // whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            {text || ''}
          </span>
        ),
      },
      {
        title: 'Ciudad',
        dataIndex: 'request.city',
        width: 200,
        sorter: true,
        render: text => (
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              // whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            {text}
          </span>
        ),
      },
      {
        title: 'PAX',
        dataIndex: 'request.pax',
        width: 75,
        sorter: true,
        render: text => <span>{text}</span>,
      },
      {
        title: 'Tipo',
        dataIndex: 'request.hasPreviousAcceptedProposals',
        width: 90,
        sorter: true,
        render: (hasPreviousAcceptedProposals, { request }) => (
          <span>
            {!request.clientId
              ? 'Primera'
              : hasPreviousAcceptedProposals
              ? 'Recurrente'
              : 'Segundo tiro'}
          </span>
        ),
      },
      {
        title: 'Fecha de entrada',
        dataIndex: 'request.checkinDate',
        width: 100,
        sorter: false,
        render: val => <span>{val}</span>,
      },
      {
        title: 'Fecha de salida',
        dataIndex: 'request.checkoutDate',
        width: 100,
        sorter: false,
        render: val => <span>{val}</span>,
      },
      {
        title: 'Teléfono',
        dataIndex: 'request.phoneNumber',
        width: 100,
        sorter: false,
        render: text => (
          <a
            href={`tel:${text}`}
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              // whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: 'Seguimiento',
        dataIndex: 'request.imsCount',
        width: 100,
        sorter: false,
        render: (imsCount, record) => (
          <RequestFollowUps
            imsCount={imsCount}
            request={record.request}
            refresh={this.props.loadTableData}
          />
        ),
      },
      {
        title: 'Llamadas',
        dataIndex: 'request.callsCount',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={6}
            defaultValue={text || 0}
            name='callsCount'
            onChange={value => {
              this.props.handleCallsCount(record.request, value);
            }}
          />
        ),
      },
      {
        title: 'Últ. Llamada',
        dataIndex: 'request.lastCallDate',
        width: 120,
        sorter: true,
        render: text => <span>{text && moment(text).format('DD/MM/YYYY HH:mm')}</span>,
      },
      {
        title: 'Email',
        dataIndex: 'request.contactEmail',
        sorter: true,
        width: 300,
        render: text => (
          <span
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              // whiteSpace: 'nowrap',
              display: 'block',
            }}
          >
            {text}
          </span>
        ),
      },
    ];

    const scrollX = tableUtils.getTableWidth();
    const scrollY = tableUtils.getTableHeight();

    return (
      <div className='requestBorderTopColor'>
        <Table
          data-test-id='requestTable'
          size='middle'
          className='fixed-width-table'
          pagination={false}
          dataSource={this.state.data}
          loading={this.props.loading}
          columns={columns}
          rowKey={rec => rec.request.id}
          scroll={{ y: scrollY, x: scrollX }}
          onChange={(pagination, filter, sorter) => this.props.handleSorter(sorter)}
          footer={() => (
            <HomyPagination
              showSizeChanger
              showQuickJumper
              current={this.props.pagination.page}
              pageSize={this.props.pagination.pageSize}
              totalCount={this.props.pagination.totalCount}
              onShowSizeChange={this.props.handlePaginationSizeChange}
              onChange={this.props.handlePaginationChange}
            />
          )}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getRequestsTableData: url => dispatch(getRequestsTableData(url, false)),
      createNewDealFromRequest: data => dispatch(createNewDealFromRequest(data)),
    },
  };
}

function mapStateToProps(state) {
  return {
    requestsData: state.Requests.requestsData,
    filter: state.Requests.filter,
    filterVersion: state.Requests.filterVersion,
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TableDataRequests);
