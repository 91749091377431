import {
  CONTACTS_LIST_ERROR,
  CONTACTS_LIST_SUCCESS,
  CONTACTS_METASOURCE_ERROR,
  CONTACTS_METASOURCE_SUCCESS,
  CONTACTS_TABLEDATA_ERROR,
  CONTACTS_TABLEDATA_FILTER,
  CONTACTS_TABLEDATA_SUCCESS,
  NEW_CONTACT_CREATE_ERROR,
  NEW_CONTACT_CREATE_SUCCESS,
  NEW_CONTACT_LOCATION_ERROR,
  NEW_CONTACT_LOCATION_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  filter: {
    name: '',
    lastOTradeName: '',
    jobTitle: '',
    email: '',
    identificationCode: '',
    phoneNumber: '',
    contactType: '',
  },
  filterVersion: 0,
  contactsData: {
    tableData: [],
    totalCount: 0,
    error: false,
    errorDetail: '',
  },
  contactCreate: {
    error: false,
    errorDetail: '',
  },
  contactCreateSuccess: false,
  contactLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
};

const Contacts = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case CONTACTS_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case CONTACTS_TABLEDATA_FILTER: {
      const filterVersion = state.filterVersion + 1;

      return Object.assign({}, state, {
        filter: action.filterCriteria,
        filterVersion,
      });
    }

    case CONTACTS_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        contactsData: {
          tableData: action.tableData,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
        },
      });
    }

    case CONTACTS_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        contactsData: {
          tableData: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_CONTACT_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        contactLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_CONTACT_LOCATION_ERROR: {
      return Object.assign({}, state, {
        contactLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case CONTACTS_LIST_SUCCESS: {
      const filterVersion =
        state.contactsList && state.contactsList.filterVersion
          ? state.contactsList.filterVersion + 1
          : 0;
      let contactsList;

      if (state.hasOwnProperty('contactsList')) {
        contactsList = Object.assign({}, state.contactsList);
        contactsList.list = action.contactsList.contactList;
        contactsList.totalCount = parseInt(action.totalCount, 10);
        contactsList.error = false;
        contactsList.errorDetail = '';
        contactsList.componentId = action.componentId;
        contactsList.filterVersion = filterVersion;
      } else {
        contactsList = {
          list: action.contactsList.contactList,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
          componentId: action.componentId,
          filterVersion,
        };
      }

      if (action.componentId) {
        contactsList[action.componentId] = action.contactsList.contactList;
      }

      return Object.assign({}, state, {
        contactsList,
        contactCreateSuccess: false,
      });
    }

    case CONTACTS_LIST_ERROR: {
      return Object.assign({}, state, {
        contactsList: {
          list: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_CONTACT_CREATE_SUCCESS: {
      return Object.assign({}, state, {
        contactCreate: {
          error: false,
          errorDetail: '',
        },
        contactCreateSuccess: true,
      });
    }

    case NEW_CONTACT_CREATE_ERROR: {
      return Object.assign({}, state, {
        contactCreate: {
          error: true,
          errorDetail: action.createNewContactErrorDetails,
        },
        contactCreateSuccess: false,
      });
    }

    default:
      return state;
  }
};

export default Contacts;
