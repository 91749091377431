import {
  CALCULATE_DEAL_SCORE_ERROR,
  CALCULATE_DEAL_SCORE_SUCCESS,
  DEALS_LIST_ERROR,
  DEALS_LIST_SUCCESS,
  DEALS_METASOURCE_ERROR,
  DEALS_METASOURCE_SUCCESS,
  DEALS_RENTAL_REQUEST_STAGE_COUNT_ERROR,
  DEALS_RENTAL_REQUEST_STAGE_COUNT_SUCCESS,
  DEALS_TABLEDATA_ERROR,
  DEALS_TABLEDATA_FILTER,
  DEALS_TABLEDATA_SUCCESS,
  NEW_DEAL_FROM_REQUEST,
  NEW_DEAL_LOCATION_ERROR,
  NEW_DEAL_LOCATION_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  filter: {
    identificationCode: undefined,
    locality: undefined,
    hsagent: undefined,
    companyId: undefined,
    rentalRequestType: undefined,
    rentalRequestStage: undefined,
  },
  filterVersion: 0,
  DealsData: {
    tableData: [],
    totalCount: 0,
    error: false,
    errorDetail: '',
  },
  // dealsCurrentUser: {},
  rentalRequestStageCounter: {
    data: null,
    error: false,
    errorDetail: '',
  },
  score: {
    dealScore: {},
    error: false,
    errorDetail: '',
  },
  newDealFromRequestData: {},
};

const Deals = (state = initialState, action) => {
  switch (action.type) {
    case DEALS_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case DEALS_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case DEALS_TABLEDATA_FILTER: {
      const filterVersion = state.filterVersion + 1;

      return Object.assign({}, state, {
        filter: action.filterCriteria,
        filterVersion: filterVersion,
      });
    }

    case DEALS_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        dealsData: {
          tableData: action.tableData,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
        },
      });
    }

    case DEALS_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        dealsData: {
          tableData: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_DEAL_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        dealLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_DEAL_LOCATION_ERROR: {
      return Object.assign({}, state, {
        dealLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_DEAL_FROM_REQUEST: {
      return Object.assign({}, state, {
        newDealFromRequestData: action.data,
      });
    }

    // case DEALS_CURRENT_USER_SUCCESS: {
    //   return Object.assign({}, state, {
    //     dealsCurrentUser: action.data
    //   });
    // }
    case DEALS_LIST_SUCCESS: {
      const filterVersion =
        state.dealsList && state.dealsList.filterVersion ? state.dealsList.filterVersion + 1 : 0;

      return Object.assign({}, state, {
        dealsList: {
          list: action.dealsList.dealList,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
          filterVersion,
        },
      });
    }

    case DEALS_LIST_ERROR: {
      return Object.assign({}, state, {
        dealsList: {
          list: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case DEALS_RENTAL_REQUEST_STAGE_COUNT_SUCCESS: {
      return Object.assign({}, state, {
        rentalRequestStageCounter: {
          data: action.rentalRequestStageCounter,
        },
      });
    }

    case DEALS_RENTAL_REQUEST_STAGE_COUNT_ERROR: {
      return Object.assign({}, state, {
        stagesCounter: {
          data: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case CALCULATE_DEAL_SCORE_SUCCESS: {
      return Object.assign({}, state, {
        score: {
          dealScore: action.dealScore,
          error: false,
          errorDetail: '',
        },
      });
    }

    case CALCULATE_DEAL_SCORE_ERROR: {
      return Object.assign({}, state, {
        score: {
          dealScore: {},
          error: true,
          errorDetail: action.calculateDealScoreError,
        },
      });
    }

    default:
      return state;
  }
};

export default Deals;
