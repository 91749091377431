import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class HomyEmail extends Input {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    id: 'email',
    label: 'Correo electrónico',
    name: 'email',
    type: 'email',
    placeholder: 'ejemplo@dominio.com',
  };

  render() {
    return (
      <label htmlFor={this.props.id}>
        {this.props.label}
        <Input {...this.props} autoComplete='email' minLength={4} inputMode='email' />
      </label>
    );
  }
}

export default HomyEmail;
