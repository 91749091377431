import React, { Component } from 'react';
import { Alert, DatePicker } from 'antd';
import moment from 'moment';
import { checkDateBefore } from '../../shared/utils/formUtils';
import { normalizeNumberToDecimals } from './../../shared/utils/formUtils';
import { DATE_FORMAT, PARSE_DATE_FORMAT, TIME_FORMAT } from '../../shared/appConfig.js';

//PARAMETROS A CONFIGURAR
//callbackFuncionOnCreate -> función a llamar cuando se ha creado un nuevo contacto
//periodPrice -> (OPTIONAL) objeto con las fechas de inicio y fin del periodo

class FormNewPeriodPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPeriodPrice: {
        start:
          props.periodPrice && props.periodPrice.start
            ? this.getDateIntoCurrentYear(props.periodPrice.start)
            : new Date(),
        end:
          props.periodPrice && props.periodPrice.end
            ? this.getDateIntoCurrentYear(props.periodPrice.end)
            : new Date(),
        price: {
          currency: 'EUR',
          amount: 0,
        },
      },
      submitting: false,
      invalidInput: false,
      allPeriodPrices: this.props.allPeriodPrices,
      invalidRange: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.getDateIntoCurrentYear = this.getDateIntoCurrentYear.bind(this);
    this.checkValidPeriodDates = this.checkValidPeriodDates.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.periodPrice &&
      (this.state.newPeriodPrice.start !== nextProps.periodPrice.start ||
        this.state.newPeriodPrice.end !== nextProps.periodPrice.end)
    ) {
      const statePeriodPrice = this.state.newPeriodPrice;

      statePeriodPrice.start = nextProps.periodPrice.start;
      statePeriodPrice.end = nextProps.periodPrice.end;
      this.setState({
        newPeriodPrice: statePeriodPrice,
      });
    }
  }

  getDateIntoCurrentYear(date) {
    const currentDate = moment();

    return moment(date).set('year', currentDate.year());
  }

  handleChangeStart(value) {
    let dateValue = new Date();

    if (value) {
      value = this.getDateIntoCurrentYear(value);
      dateValue = moment(
        value.format(DATE_FORMAT) + ' 00:00',
        DATE_FORMAT + ' ' + TIME_FORMAT
      ).toDate();
    }

    const localNewPeriodPrice = this.state.newPeriodPrice;

    localNewPeriodPrice.start = dateValue;
    this.setState({ newPeriodPrice: localNewPeriodPrice });
  }

  handleChangeEnd(value) {
    let dateValue = new Date();

    if (value) {
      value = this.getDateIntoCurrentYear(value);
      dateValue = moment(
        value.format(DATE_FORMAT) + ' 23:59',
        DATE_FORMAT + ' ' + TIME_FORMAT
      ).toDate();
    }

    const localNewPeriodPrice = this.state.newPeriodPrice;

    localNewPeriodPrice.end = dateValue;
    this.setState({ newPeriodPrice: localNewPeriodPrice });
  }

  handleChangePrice(e) {
    let localNewPeriodPrice = this.state.newPeriodPrice;

    if (e.target.value != null && e.target.value !== '') {
      if (localNewPeriodPrice) {
        localNewPeriodPrice.price.amount = parseFloat(e.target.value);
      }

      localNewPeriodPrice.price.currency = 'EUR';
    } else {
      localNewPeriodPrice = null;
    }

    this.setState({ newPeriodPrice: localNewPeriodPrice });
  }

  handleSubmit() {
    if (this.checkValidPeriodDates()) {
      this.setState({
        submitting: true,
        invalidInput: false,
      });
      const startDate = moment(this.state.newPeriodPrice.start)
        .format(PARSE_DATE_FORMAT)
        .toString();
      const endDate = moment(this.state.newPeriodPrice.end)
        .format(PARSE_DATE_FORMAT)
        .toString();

      this.props.callbackFuncionOnCreate(startDate, endDate, this.state.newPeriodPrice.price);
    }
  }

  checkValidPeriodDates() {
    // comprobamos que la fecha de inicio sea anterior a la fecha de fin,
    // que no se solapen 2 periodos
    // y que el precio sea > 0
    if (
      !checkDateBefore(this.state.newPeriodPrice.start, this.state.newPeriodPrice.end) ||
      !this.state.newPeriodPrice.price.amount > 0
    ) {
      this.setState({
        submitting: false,
        invalidInput: true,
      });

      return false;
    }

    if (this.state.allPeriodPrices) {
      let periodPrice;

      for (let i = 0; i < this.state.allPeriodPrices.length; i++) {
        periodPrice = this.state.allPeriodPrices[i];
        if (
          moment(this.state.newPeriodPrice.start).isBetween(
            moment(periodPrice.start),
            moment(periodPrice.end)
          ) ||
          moment(this.state.newPeriodPrice.end).isBetween(
            moment(periodPrice.start),
            moment(periodPrice.end)
          ) ||
          moment(periodPrice.start).isBetween(
            moment(this.state.newPeriodPrice.start),
            moment(this.state.newPeriodPrice.end)
          ) ||
          moment(periodPrice.end).isBetween(
            moment(this.state.newPeriodPrice.start),
            moment(this.state.newPeriodPrice.end)
          )
        ) {
          this.setState({
            submitting: false,
            invalidRange: true,
          });

          return false;
        }
      }
    }

    return true;
  }

  render() {
    return (
      <div className='col-sm-12'>
        {this.state.invalidInput && (
          <Alert message='Error en los datos introducidos' type='error' showIcon closable={true} />
        )}
        {this.state.invalidRange && (
          <Alert
            message='El periodo seleccionado se solapa con otro introducido anteriormente'
            type='error'
            showIcon
            closable={true}
          />
        )}
        <form id='formNewPeriodPrice' action='' method='put'>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='row'>
                <div className='col-sm-12'>
                  <button
                    disabled={this.state.submitting}
                    type='button'
                    className='btn btn-primary'
                    onClick={this.handleSubmit}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>

            <div className='col-sm-10'>
              <div className='row'>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label htmlFor='newPeriodPriceStart'>Inicio</label>
                    <br />
                    <DatePicker
                      id='newPeriodPriceStart'
                      name='start'
                      value={moment(this.state.newPeriodPrice.start, DATE_FORMAT)}
                      format={DATE_FORMAT}
                      onChange={this.handleChangeStart}
                    />
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label htmlFor='newPeriodPriceEnd'>Fin</label>
                    <br />
                    <DatePicker
                      id='newReservationEnd'
                      name='end'
                      value={moment(this.state.newPeriodPrice.end, DATE_FORMAT)}
                      format={DATE_FORMAT}
                      onChange={this.handleChangeEnd}
                    />
                  </div>
                </div>
                <div className='col-sm-4'>
                  <div className='form-group'>
                    <label htmlFor='newPeriodPricePrice'>Precio</label>
                    <br />
                    <input
                      type='number'
                      className='form-control'
                      id='newPeriodPricePrice'
                      name='price'
                      /*placeholder="0"*/ value={normalizeNumberToDecimals(
                        this.state.newPeriodPrice.price.amount,
                        2
                      )}
                      onChange={this.handleChangePrice}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default FormNewPeriodPrice;
