import { createQueryString, getAxiosInstance, parseQueryString } from '../../shared/utils/apiUtils';
import api from '../../shared/utils/api';
import { resolveParams } from '../../shared/utils/appUtils';

class ContactDetailsApi {
  static getMetasource(url, id) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url + '/' + id);
  }

  static getContactDetailsData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static saveContactDetailsData(url, contactDetails, parent, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    if (url && parent) {
      let urlBase = url;

      if (url.indexOf('?') > 0) {
        urlBase = url.substring(0, url.indexOf('?'));
      }

      const urlParsed = parseQueryString(url);

      urlParsed.parent = parent;
      url = urlBase + '?' + createQueryString(urlParsed);
    }

    return axiosInstance.put(url, contactDetails);
  }

  static doDelete(url, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.delete(url);
  }

  /**
   * Saves or updates a contact, retrieving first the location from the back
   * @param {*} contactData
   * @param {*} contactId
   */
  static async saveContactData(contact, etag) {
    let url;
    let res;
    if (!contact.id) {
      try {
        res = await api.generateId('contact');
        const location = res.headers.get('location');
        url = resolveParams(location);
      } catch (e) {
        console.error('Error retrieving location for a new Contact' + e);
        return;
      }
      try {
        return await api.saveUrl(url, contact);
      } catch (e) {
        console.error('Error saving Contact' + e);
        throw new Error('ContactDetailsApi.saveContactData: ' + e);
      }
    } else {
      try {
        return await api.save('contact', contact.id, {
          ...contact,
          etag,
        });
      } catch (e) {
        console.error('Error saving Contact' + e);
        throw new Error('ContactDetailsApi.saveContactData: ' + e);
      }
    }
  }
}

export default ContactDetailsApi;
