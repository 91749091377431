import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class HomyNIF extends Input {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    label: 'NIF',
    id: 'nif',
    name: 'nif',
    placeholder: '12345678A',
  };

  render() {
    return (
      <label htmlFor={this.props.id}>
        {this.props.label}
        <Input {...this.props} />
      </label>
    );
  }
}

export default HomyNIF;
