import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormCompanyDetails from '../FormCompanyDetails';
import {
  getCompanyDetailsData,
  resetState as resetStateCompany,
} from '../../modules/CompanyDetails/CompanyDetailsActions';
import FormCompanyContacts from '../FormCompanyContacts';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import truncate from 'lodash/truncate';
import { browserHistory } from 'react-router';

// PARAMETROS A CONFIGURAR
// leftOffset -> columnas bootstrap a desplazar el botón
// withSelfLink -> boolean to indicate if we need to follow the self link
// compressed -> boolean that indicates if we need to render the compressed version of the button

class InfoButtonCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyDetail: false,
      companyDetails: {},
      companyDetailsMetasourceLinkReduced: '',
    };
    this.showCompanyDetail = this.showCompanyDetail.bind(this);
    this.showCompanyDetailsView = this.showCompanyDetailsView.bind(this);
    if (!props.companyDetails || isEmpty(props.companyDetails)) {
      this.props.actions.getCompanyDetailsData();
    }
  }

  componentDidMount() {
    if (
      isEmpty(this.state.companyDetails) &&
      JSON.stringify(this.props.companyDetails) !== JSON.stringify(this.state.companyDetails)
    ) {
      this.setState({
        companyDetails: this.props.companyDetails,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.companyDetails && !isEqual(this.state.companyDetails, nextProps.companyDetails)) {
      this.setState({ companyDetails: nextProps.companyDetails });
    }

    if (
      JSON.stringify(this.state.companyDetailsMetasourceLinkReduced) !==
      JSON.stringify(nextProps.companyDetailsMetasourceLinkReduced)
    ) {
      // si cambia el enlace del recurso, cargamos de nuevo los datos del recurso
      this.setState(
        {
          companyDetailsMetasourceLinkReduced: nextProps.companyDetailsMetasourceLinkReduced,
        },
        () => this.props.actions.getCompanyDetailsData()
      );
    }
  }

  componentWillUnmount() {
    this.props.actions.resetStateCompany();
  }

  componentDidUpdate() {
    if (document.getElementById('infoButtonCompanyDetails')) {
      if (this.state.showCompanyDetail) {
        document.getElementById('infoButtonCompanyDetails').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonCompanyDetails').style.zIndex = 1;
      }
    }
  }

  showCompanyDetail(event) {
    if (event.target.nodeName !== 'A') {
      const showOrHide = !this.state.showCompanyDetail;

      this.setState({
        showCompanyDetail: showOrHide,
      });
    }
  }

  showCompanyDetailsView(event) {
    if (this.props.withSelfLink) {
      event.preventDefault();
      browserHistory.push(`/companyDetails/${this.state.companyDetails.id}`);
    }
  }

  render() {
    let leftOffset = 0;

    if (this.props.leftOffset) {
      leftOffset = parseInt(this.props.leftOffset, 10) * -(!this.props.compressed ? 20 : 33.3);
    }

    const styleInfoButtonDetail = {
      marginLeft: `${leftOffset}%`,
    };
    let colStyle = 'col-sm-5';
    let colStyleBlock = 'col-sm-10 noPadding';

    if (this.props.compressed) {
      colStyle = 'col-sm-3';
      colStyleBlock = 'col-sm-12';
    }

    const hasAcceptedGeneralConditions =
      isNumber(get(this.state, 'companyDetails.generalConditionsDate')) &&
      !isEmpty(get(this.state, 'companyDetails.generalConditionsOrigin'));

    return (
      <div id='infoButtonCompanyDetails' className={`${colStyle} noPadding`}>
        {get(this.state, 'companyDetails.serviceType', '') === 'premium' && (
          <div className='ribbon'>
            <span>PREMIUM</span>
          </div>
        )}
        <div
          className='card card-inverse companyBackgroundColor infoButton'
          onClick={this.showCompanyDetail}
        >
          {this.state.companyDetails && this.state.companyDetails.baseContact && (
            <div className='card-block'>
              <div className='row'>
                {!this.props.compressed && (
                  <div className='col-sm-2 fontSizeMax alignCenter'>
                    <i className='icon-briefcase' />
                  </div>
                )}
                <div className={`${colStyleBlock} floatLeft`}>
                  <div className='h4 m-0 bold linkBehavior' onClick={this.showCompanyDetailsView}>
                    {this.state.companyDetails.baseContact.name}
                    <br />
                    <small>
                      {this.state.companyDetails.baseContact.lastOTradeName
                        ? this.state.companyDetails.baseContact.lastOTradeName
                        : ''}
                    </small>
                  </div>
                  <div>
                    {this.state.companyDetails.baseContact.worldWideAddress &&
                      this.state.companyDetails.baseContact.worldWideAddress.formattedAddress}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-2 floatLeft noPadding' />
                <div className='col-sm-5 floatLeft noPadding fontSizeMedium'>
                  {this.state.companyDetails.baseContact.email && (
                    <div>
                      <i className='fa fa-envelope' aria-hidden='true' />{' '}
                      <a
                        className='whiteLink'
                        href={`mailto:${this.state.companyDetails.baseContact.email}`}
                      >
                        {this.state.companyDetails.baseContact.email}
                      </a>
                    </div>
                  )}
                  {this.state.companyDetails.baseContact.email2 && (
                    <div>
                      <i className='fa fa-envelope' aria-hidden='true' />{' '}
                      <a
                        className='whiteLink'
                        href={`mailto:${this.state.companyDetails.baseContact.email2}`}
                      >
                        {this.state.companyDetails.baseContact.email2}
                      </a>
                    </div>
                  )}
                  {hasAcceptedGeneralConditions && (
                    <div>
                      <i className='fa fa-check-square-o' aria-hidden='true' /> Condiciones
                      generales aceptadas
                    </div>
                  )}
                </div>
                <div className='col-sm-5 floatLeft noPadding fontSizeMedium'>
                  {this.state.companyDetails.baseContact.phoneNumber && (
                    <div>
                      <i className='fa fa-phone' aria-hidden='true' />{' '}
                      <a
                        className='whiteLink'
                        href={`tel:${this.state.companyDetails.baseContact.phoneNumber}`}
                      >
                        {this.state.companyDetails.baseContact.phoneNumber}
                      </a>
                    </div>
                  )}
                  {this.state.companyDetails.baseContact.mobilePhoneNumber && (
                    <div>
                      <i className='fa fa-mobile' aria-hidden='true' /> &nbsp;{' '}
                      <a
                        className='whiteLink'
                        href={`tel:${this.state.companyDetails.baseContact.mobilePhoneNumber}`}
                      >
                        {this.state.companyDetails.baseContact.mobilePhoneNumber}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {this.state.companyDetails.sector && (
                <div className='row'>
                  <div className='col-sm-2 floatLeft noPadding' />
                  <div
                    className='col-sm-10 floatLeft noPadding fontSizeMedium'
                    title={this.state.companyDetails.sector}
                  >
                    {truncate(this.state.companyDetails.sector, {
                      length: 72,
                    })}
                  </div>
                </div>
              )}
              {this.state.companyDetails.cnae && (
                <div className='row'>
                  <div className='col-sm-2 floatLeft noPadding' />
                  <div
                    className='col-sm-10 floatLeft noPadding fontSizeMedium'
                    title={this.state.companyDetails.cnae}
                  >
                    {truncate(this.state.companyDetails.cnae, {
                      length: 72,
                    })}
                  </div>
                </div>
              )}
              {this.state.showCompanyDetail && (
                <div className='openIcon'>
                  <i className='fa fa-caret-down fa-2x' />
                </div>
              )}
            </div>
          )}
        </div>

        {this.state.showCompanyDetail && !this.props.listOfContacts && (
          <div
            style={styleInfoButtonDetail}
            className='infoButtonDetail cardNoMargin noPadding animated fadeIn companyBorderTopColor'
          >
            <div className='card'>
              <div className='card-block'>
                <FormCompanyDetails mainView={this.props.mainView} />
              </div>
            </div>
          </div>
        )}

        {this.state.showCompanyDetail && this.props.listOfContacts && (
          <div
            style={styleInfoButtonDetail}
            className='infoButtonDetail cardNoMargin noPadding animated fadeIn companyBorderTopColor'
          >
            <div className='card'>
              <div className='card-block'>
                <FormCompanyContacts callbackFunctionOnUpdateOrSave={() => {}} />
              </div>
            </div>
          </div>
        )}
        <style jsx>
          {`
            .box {
              width: 200px;
              height: 300px;
              position: relative;
              border: 1px solid #bbb;
              background: #eee;
            }
            .ribbon {
              position: absolute;
              right: -2px;
              top: -3px;
              z-index: 1;
              overflow: hidden;
              width: 75px;
              height: 75px;
              text-align: right;
            }
            .ribbon span {
              font-size: 10px;
              font-weight: bold;
              color: #fff;
              text-transform: uppercase;
              text-align: center;
              line-height: 20px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              width: 100px;
              display: block;
              background: linear-gradient(#eeaf30 0%, #eeaf50 100%);
              box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
              position: absolute;
              top: 19px;
              right: -21px;
            }
            .ribbon span::before {
              content: '';
              position: absolute;
              left: 0px;
              top: 100%;
              z-index: -1;
              border-left: 3px solid #eeaf30;
              border-right: 3px solid transparent;
              border-bottom: 3px solid transparent;
              border-top: 3px solid #eeaf30;
            }
            .ribbon span::after {
              content: '';
              position: absolute;
              right: 0px;
              top: 100%;
              z-index: -1;
              border-left: 3px solid transparent;
              border-right: 3px solid #eeaf30;
              border-bottom: 3px solid transparent;
              border-top: 3px solid #eeaf30;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCompanyDetailsData: () => dispatch(getCompanyDetailsData()),
      resetStateCompany: () => dispatch(resetStateCompany()),
    },
  };
}

function mapStateToProps(state) {
  return {
    companyDetails: state.CompanyDetails.data.detail,
    companyDetailsMetasourceLinkReduced: state.CompanyDetails.metasource
      ? state.CompanyDetails.metasource.links.reduced
      : '',
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButtonCompanyDetails);
