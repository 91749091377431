import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { Icon, Layout, Menu } from 'antd';
import { closeSession } from '../../modules/LoginControl/LoginControlActions';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.closeSessionEvent = this.closeSessionEvent.bind(this);
  }

  closeSessionEvent() {
    this.props.actions.closeSession();
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  handleClick(e) {
    e.preventDefault();
    switch (e.target.name) {
      case 'providers':
        browserHistory.push('/providers');
        break;
      case 'properties':
        browserHistory.push('/properties');
        break;
      case 'companies':
        browserHistory.push('/companies');
        break;
      case 'contacts':
        browserHistory.push('/contacts');
        break;
      case 'deals':
        browserHistory.push('/deals');
        break;
      case 'requests':
        browserHistory.push('/requests');
        break;
      case 'invoices':
        browserHistory.push('/invoices');
        break;
      case 'pmas':
        browserHistory.push('/pmas');
        break;
      case 'supplyDemand':
        browserHistory.push('/supplydemand');
        break;
      case 'followups':
        browserHistory.push('/followups');
        break;
      default:
    }

    this.props.handleNavigateTo(null);
  }

  render() {
    const currentLocation = this.props.navigateTo || this.props.location.pathname;

    return (
      <Layout.Sider
        collapsed={this.props.isCollapsed}
        collapsible
        onCollapse={this.props.setPageWidth}
        theme='light'
        style={{
          overflow: 'hidden',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <div
          className={`logo ${this.props.pageWidth > 150 ? 'logo-normal-size' : 'logo-collapsed'}`}
          onClick={() => browserHistory.push('/')}
        />
        <Menu theme='light' style={{ minHeight: '100vh' }}>
          {this.props.mainMetasource.requests && (
            <Menu.Item key={1}>
              <Link
                name='requests'
                className={`nav-link requestBorderLeft ${
                  currentLocation.includes('requests') ||
                  currentLocation.includes('dealfromrequest')
                    ? 'whiteLink requestBackgroundColor'
                    : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='bars' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => <svg width='1em' height='1em' className='fas fa-skating'></svg>}
                ></Icon> */}
                <span>Solicitudes</span>
              </Link>
            </Menu.Item>
          )}

          {this.props.mainMetasource.deals && (
            <Menu.Item key={2}>
              <Link
                name='deals'
                className={`nav-link dealBorderLeft ${
                  currentLocation.includes('deals') || currentLocation.includes('dealDetails')
                    ? 'whiteLink dealBackgroundColor'
                    : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='bell' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => <svg width='1em' height='1em' className='fas fa-gifts'></svg>}
                ></Icon> */}
                <span>Oportunidades</span>
              </Link>
            </Menu.Item>
          )}
          {this.props.mainMetasource.properties && (
            <Menu.Item key={3}>
              <Link
                name='providers'
                className={`nav-link providerBorderLeft ${
                  currentLocation.includes('providers') ||
                  currentLocation.includes('providerDetails')
                    ? 'whiteLink providerBackgroundColor'
                    : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='star' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => (
                    <svg width='1em' height='1em' className='fas fa-holly-berry'></svg>
                  )}
                ></Icon> */}
                <span>Proveedores</span>
              </Link>
            </Menu.Item>
          )}
          {this.props.mainMetasource.providers && (
            <Menu.Item key={4}>
              <Link
                name='properties'
                className={`nav-link propertyBorderLeft ${
                  currentLocation.includes('properties') ||
                  currentLocation.includes('propertyDetails')
                    ? 'whiteLink propertyBackgroundColor'
                    : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='home' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => (
                    <svg width='1em' height='1em' className='fas fa-candy-cane'></svg>
                  )}
                ></Icon> */}
                <span>Inmuebles</span>
              </Link>
            </Menu.Item>
          )}
          {this.props.mainMetasource.companies && (
            <Menu.Item key={5}>
              <Link
                name='companies'
                className={`nav-link companyBorderLeft ${
                  currentLocation.includes('companies') ||
                  currentLocation.includes('companyDetails')
                    ? 'whiteLink companyBackgroundColor'
                    : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='team' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => <svg width='1em' height='1em' className='fas fa-mitten'></svg>}
                ></Icon> */}
                <span>Clientes</span>
              </Link>
            </Menu.Item>
          )}
          {this.props.mainMetasource.contacts && (
            <Menu.Item key={6}>
              <Link
                name='contacts'
                className={`nav-link contactBorderLeft ${
                  currentLocation.includes('contacts') || currentLocation.includes('contactDetails')
                    ? 'whiteLink contactBackgroundColor'
                    : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='contacts' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => <svg width='1em' height='1em' className='fas fa-snowman'></svg>}
                ></Icon> */}
                <span>Contactos</span>
              </Link>
            </Menu.Item>
          )}
          {this.props.mainMetasource.deals && (
            <Menu.Item key={7}>
              <Link
                name='invoices'
                className={`nav-link invoiceBorderLeft ${
                  currentLocation.includes('invoices') || currentLocation.includes('invoiceDetails')
                    ? 'whiteLink invoiceBackgroundColor'
                    : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='file-done' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => <svg width='1em' height='1em' className='fas fa-mug-hot'></svg>}
                ></Icon> */}
                <span>Facturas</span>
              </Link>
            </Menu.Item>
          )}
          {this.props.mainMetasource.pmas && (
            <Menu.Item key={8}>
              <Link
                name='pmas'
                className={`nav-link pmaBorderLeft ${
                  currentLocation.includes('pmas') ? 'whiteLink pmaBackgroundColor' : ''
                }`}
                activeClassName='active'
                onClick={this.handleClick}
              >
                <Icon type='dollar' />
                {/* <Icon
                  style={{ fontSize: '18px' }}
                  component={() => (
                    <svg width='1em' height='1em' className='fa fa-thermometer-empty'></svg>
                  )}
                ></Icon> */}
                <span>PMA</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key={9}>
            <Link
              name='supplyDemand'
              className={`nav-link supplyDemandBorderLeft ${
                currentLocation.includes('supplydemand')
                  ? 'whiteLink supplyDemandBackgroundColor'
                  : ''
              }`}
              activeClassName='active'
              onClick={this.handleClick}
            >
              <Icon type='bar-chart' />
              {/* <Icon
                style={{ fontSize: '18px' }}
                component={() => <svg width='1em' height='1em' className='fa fa-snowflake-o'></svg>}
              ></Icon> */}
              <span>Oferta/Demanda</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={10}>
            <Link
              name='followups'
              className={`nav-link followupsBorderLeft ${
                currentLocation.includes('followups') ? 'whiteLink followupsBackgroundColor' : ''
              }`}
              activeClassName='active'
              onClick={this.handleClick}
            >
              <Icon type='bar-chart' />
              {/* <Icon
                style={{ fontSize: '18px' }}
                component={() => (
                  <svg width='1em' height='1em' className='fas fa-holly-berry'></svg>
                )}
              ></Icon> */}
              <span>Seguimientos</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={11} onClick={this.closeSessionEvent} className='close-session'>
            <img
              src='/img/avatars/default_user_small.png'
              // src='img/xmas/window.png'
              className='img-avatar'
              title={this.props.currentUserInformation.username}
              alt='usuario'
              height={35}
            />
            <Icon />
            <span>Cerrar sesión</span>
          </Menu.Item>
        </Menu>
        <style jsx>
          {`
            .ant-menu-item {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
              padding: 0 !important;
              background-color: inherit !important;
            }

            .ant-menu-item:hover {
              cursor: pointer !important;
            }

            .ant-menu-inline-collapsed > .ant-menu-item {
              padding: 0 !important;
            }

            .ant-menu-submenu-title {
              padding: 0 !important;
            }

            .ant-menu-item {
              height: inherit !important;
            }

            .img-avatar {
              margin-left: 5px !important;
            }

            .nav-link {
              transition: background-color 600ms ease-in-out, color 100ms ease;
            }

            .nav-link:hover {
              cursor: pointer;
            }

            .nav-link:hover > span,
            .nav-link:hover > i {
              color: white !important;
            }

            .requestBorderLeft:hover {
              background-color: #75b8c8;
            }

            .dealBorderLeft:hover {
              background-color: rgb(59, 133, 157);
            }

            .providerBorderLeft:hover {
              background-color: rgb(9, 82, 134);
            }

            .propertyBorderLeft:hover {
              background-color: rgb(17, 138, 112);
            }

            .companyBorderLeft:hover {
              background-color: rgb(85, 66, 108);
            }

            .contactBorderLeft:hover {
              background-color: rgb(57, 62, 77);
            }

            .invoiceBorderLeft:hover {
              background-color: #cf335f;
            }

            .pmaBorderLeft:hover {
              background-color: #80c468;
            }

            .supplyDemandBorderLeft:hover {
              background-color: #66433c;
            }

            .followupsBorderLeft:hover {
              background-color: #eeaf30;
            }

            .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
              padding: 0 15px !important;
            }

            .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > div > span,
            .ant-menu-inline-collapsed
              > .ant-menu-submenu
              > .ant-menu-submenu-title
              > .ant-menu-submenu-arrow {
              display: none !important;
            }

            .ant-menu-submenu-popup {
              left: 200px !important;
              bottom: 200px !important;
            }

            .close-session {
              text-align: center !important;
              position: absolute !important;
              bottom: 50px !important;
              list-style-type: none !important;
              padding-bottom: 0.5em !important;
            }

            .ant-layout-sider-trigger {
              border-right: 1px solid #e8e8e8 !important;
            }

            .ant-menu-inline-collapsed > .ant-menu-item > .nav-link {
              text-align: center;
            }

            .ant-menu-inline-collapsed > .close-session {
              padding-bottom: 0.5em !important;
              text-align: center !important;
              margin-left: 15px !important;
            }

            .ant-menu-item-active {
              transition: color 100ms ease;
              color: rgba(0, 0, 0, 0.65) !important;
            }

            /* Logo del top sidebar */
            .logo {
              height: 55px;
              margin-right: 0;
              padding: 0.5rem 1rem;
              background-color: #eeaf30;
              background-repeat: no-repeat;
              background-position: center center;
            }

            .logo:hover {
              cursor: pointer;
            }

            .logo-normal-size {
              width: 200px;
              background-size: 150px auto;
              background-image: url('../img/logo.png');
            }

            .logo-collapsed {
              width: 85px;
              background-size: 60px auto;
              background-image: url('../img/icono_blanco.png');
            }
          `}
        </style>
      </Layout.Sider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      closeSession: () => dispatch(closeSession()),
    },
  };
}

function mapStateToProps(state) {
  return {
    mainMetasource: state.Preload.mainMetasource,
    currentUserInformation: state.Preload.currentUserInformation,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
