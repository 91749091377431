/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ENDPOINT_V2 } from '../../shared/appConfig';
import api from '../../shared/utils/api';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import keys from 'lodash/keys';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';

const columns = [
  {
    title: 'Gestor',
    dataIndex: 'hsagent',
    key: 'hsagent',
    align: 'center',
  },
  {
    title: 'Solicitudes pendientes',
    dataIndex: 'pendingRequests',
    key: 'pendingRequests',
    align: 'center',
  },
  {
    title: 'Nuevas',
    dataIndex: 'newRequests',
    key: 'newRequests',
    align: 'center',
  },
  {
    title: 'Búsqueda',
    dataIndex: 'propertySearch',
    key: 'propertySearch',
    align: 'center',
  },
  {
    title: 'Propuestas enviadas',
    dataIndex: 'sendSearchResults',
    key: 'sendSearchResults',
    align: 'center',
  },
  {
    title: 'Check-in',
    dataIndex: 'checkIn',
    key: 'checkIn',
    align: 'center',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    align: 'center',
  },
];

const TableGlobalActivity = () => {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const requestsUrl = `${ENDPOINT_V2}/requests?isConvertedToOpportunity=false&hasHsagent=true&isDiscardedRequest=false`;
    const opportunitiesUrl = `${ENDPOINT_V2}/opportunities/search`;

    Promise.all([
      api.request(requestsUrl, 'get'),
      api.request(opportunitiesUrl, 'post', {
        body: JSON.stringify({
          criteria: {
            rentalRequestStages: [
              'S000_REQUEST',
              'S010_PROPERTY_SEARCH',
              'S020_SEND_SEARCH_RESULTS',
              'S040_CHECK_IN',
            ],
          },
          page: {
            index: 0,
            size: 5000,
          },
          simpleSearch: true,
        }),
      }),
    ])
      .then(([requestsResponse, opportunitiesResponse]) => {
        const requests = get(requestsResponse, 'json', []);
        let opportunities = get(opportunitiesResponse, 'json', []);

        if (isEmpty(requests) && isEmpty(opportunities)) {
          return;
        }

        opportunities = opportunities.searchItems.filter(opportunity => !!opportunity.hsagent);

        const requestsByAgent = reduce(
          requests,
          (result, request) => {
            const agent = request.hsAgent;
            const currentValue = result[agent] || 0;

            set(result, `['${agent}']`, currentValue + 1);

            return result;
          },
          {}
        );

        const opportunitiesByAgent = reduce(
          opportunities,
          (result, opportunity) => {
            const agent = opportunity.hsagent;
            const stage = opportunity.rentalRequestStage;
            const currentValue = get(result, `['${agent}'].${stage}`, 0);

            set(result, `['${agent}'].${stage}`, currentValue + 1);

            return result;
          },
          {}
        );

        const hsAgents = uniq([...keys(requestsByAgent), ...keys(opportunitiesByAgent)]);

        setDataSource(
          sortBy(
            map(hsAgents, (hsagent, key) => {
              const pendingRequests = requestsByAgent[hsagent] || 0;
              const newRequests = get(opportunitiesByAgent, `['${hsagent}'].S000_REQUEST`, 0);
              const propertySearch = get(
                opportunitiesByAgent,
                `['${hsagent}'].S010_PROPERTY_SEARCH`,
                0
              );
              const sendSearchResults = get(
                opportunitiesByAgent,
                `['${hsagent}'].S020_SEND_SEARCH_RESULTS`,
                0
              );
              const checkIn = get(opportunitiesByAgent, `['${hsagent}'].S040_CHECK_IN`, 0);
              const total =
                pendingRequests + newRequests + propertySearch + sendSearchResults + checkIn;

              return {
                key,
                hsagent: prettyPrintAgentFromEmail(hsagent),
                pendingRequests,
                newRequests,
                propertySearch,
                sendSearchResults,
                checkIn,
                total,
              };
            }),
            'hsagent'
          )
        );
      })
      .catch(err => {
        console.error(`Call failed: ${err.message}`);
      });
  }, []);

  return (
    <>
      <Table dataSource={dataSource} columns={columns} bordered pagination={false} />
      <style jsx>
        {`
          .ant-table-wrapper {
            width: 50%;
          }

          .ant-table-thead > tr {
            background: #3b859d;
          }

          span.ant-table-column-title {
            font-weight: bold;
          }

          span.ant-table-header-column {
            color: white;
          }
        `}
      </style>
    </>
  );
};

export default TableGlobalActivity;
