import React from 'react';

const FloatingButton = ({ text, action, color, name, value }) => (
  <button
    className='btn button floating-button'
    name={name}
    style={{
      backgroundColor: color,
      height: 50,
      width: 120,
      margin: 5,
      color: 'white',
    }}
    onClick={action}
    value={value}
  >
    {text}
  </button>
);
export default FloatingButton;
