import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import { normalizeNumberToDecimals } from '../../shared/utils/formUtils';
import ImageCarousel from '../Images/ImageCarousel';
import { Button, Modal } from 'antd';
import FormPrintablePropertyDetails from '../FormPrintablePropertyDetails/FormPrintablePropertyDetails';
import { useCheckAvailabilityHandler } from '../../shared/utils/hooks/useCheckAvailabilityHandler';
import api from '../../shared/utils/api';
import { DATE_FORMAT, ENDPOINT, HOMYSPACE_WEB_BASEPATH } from '../../shared/appConfig';
import DataOfContact from './DataOfContact';
import { BUSINESS_EVENT_TYPES, openNotificationWithIcon } from '../../shared/utils/appUtils';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';
import ProposalsApi from '../../modules/Proposals/ProposalsApi';
import { adjustDay, dateIsBetweenInterval, getDays } from '../../shared/utils/dateUtils';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPropertyUsualContact } from '../../shared/utils/contactUtils';
import ProvidersApi from '../../modules/Providers/ProvidersApi';

const getBusinessEvents = async (id = false) => {
  if (!id) {
    return false;
  }

  const url = `${ENDPOINT}/businessevent/property/${id}/pages?eventType=AVAILABILITY_QUERY&page=0&size=1`;

  try {
    const response = await api.request(url, 'get');
    const eventsList = response.json.businessEventList;

    if (!eventsList.length) {
      return false;
    }

    return eventsList[0].businessEvent;
  } catch (err) {
    console.error('Error calling to ' + url + ': ' + err.message);

    return false;
  }
};

const getEventTypeText = eventTypes => {
  const eventType = eventTypes.filter(item => item !== BUSINESS_EVENT_TYPES.AVAILABILITY_QUERY);
  const textEquivalences = {
    [BUSINESS_EVENT_TYPES.WHATSAPP]: 'Whatsapp',
    [BUSINESS_EVENT_TYPES.CALL]: 'Teléfono',
    [BUSINESS_EVENT_TYPES.EMAIL]: 'Mail',
  };

  // eslint-disable-next-line no-prototype-builtins
  if (!eventType.length || !textEquivalences.hasOwnProperty(eventType[0])) {
    return '';
  }

  return ` (${textEquivalences[eventType[0]]})`;
};

const isProposalInBondedDeal = (dealProposals, property) => {
  let hasBondedDeal = false;

  if (!isNil(dealProposals)) {
    hasBondedDeal = get(
      find(dealProposals, o => o.proposal.property.id === property),
      'proposal.property.id',
      false
    );
  }

  return hasBondedDeal;
};

const RenderCheckAvailabilityByEmail = props => (
  <Button
    icon='mail'
    className='btn availability-button email'
    onClick={() => {
      props.handleCheckAvailabilityByEmail(props.contactForAvailability);
      props.onAvailabilityCheck();
    }}
  >
    Dispo. Correo
  </Button>
);
const RenderCheckAvailabilityByWhatsApp = props => (
  <Button
    icon='whatsapp'
    className='btn availability-button whatsapp'
    disabled={!get(props, 'contactForAvailability.mobilePhoneNumber')}
    onClick={() => {
      props.handleCheckAvailabilityByWhatsapp(props.contactForAvailability);
      props.onAvailabilityCheck();
    }}
  >
    <i className='fab fa-whatsapp'></i>
    Dispo. WhatsApp
  </Button>
);
const RenderCheckAvailabilityByCall = props => (
  <Button
    className='btn availability-button call'
    disabled={
      !get(
        props.props,
        'data.providerBack.baseContact.mobilePhoneNumber',
        get(props.props, 'data.usualContactBack.mobilePhoneNumber')
      )
    }
    onClick={() => {
      props.handleCheckAvailabilityByCall(props.contactForAvailability);
      props.onAvailabilityCheck();
    }}
  >
    <i className='fas fa-phone'></i>
    Dispo. Llamada
  </Button>
);
const RenderCreatedProposal = () => (
  <div className='row mt-2 justify-content-center'>
    <div className='col-12 text-center'>
      <div className='d-flex flex-column mt-2 propuesta-estado-container'>
        <span className='propuesta-creada-color'>
          <i className='far fa-check-circle icon-bold mr-1' /> Propuesta creada
        </span>
      </div>
    </div>
  </div>
);
const RenderCreateProposal = props => (
  <div className='row justify-content-center'>
    <div className='col-10 text-center'>
      <div className='d-flex flex-column'>
        <Button
          onClick={() => props.setNewProposalModal(true)}
          className='btn btn-outline-homyColor mt-2'
        >
          Crear propuesta
        </Button>
        {props.viewNewProposalModal && (
          <Modal
            title='Crear propuesta'
            visible={props.viewNewProposalModal}
            onCancel={() => props.setNewProposalModal(false)}
            width={1080}
            footer={null}
          >
            <FormPrintablePropertyDetails
              images={props.imagesToRender}
              propertyDetails={props.data}
              bondedDeal={props.bondedDeal}
              options={props.options}
            />
          </Modal>
        )}
      </div>
    </div>
  </div>
);
const RenderCreateProposalBeta = ({ property, bondedDeal }) => (
  <div className='row mt-2 justify-content-center'>
    <div className='col-10 text-center'>
      <div className='d-flex flex-column'>
        <Button
          onClick={() =>
            window.open(
              `${HOMYSPACE_WEB_BASEPATH}providers/proposals/new?opportunity_id=${bondedDeal.id}&property_id=${property.id}`,
              '_blank'
            )
          }
          className='btn btn-outline-homyColor mt-2'
        >
          Crear propuesta (Beta)
        </Button>
      </div>
    </div>
  </div>
);
const ProposalsAtSameDates = props => (
  <>
    {get(props, 'proposals', []).length > 0 && (
      <div className='row mt-2 justify-content-center'>
        <div className='col-10 text-center'>
          <div className='property-state'>
            <i className='fas fa-exclamation-circle' />
            <span> Hay otras propuestas para estas fechas</span>
          </div>
        </div>
      </div>
    )}
  </>
);

const AvailabilityMessage = ({ property, dealCheckInDate, dealCheckOutDate }) => {
  const maxDate = new Date(2200, 0, 1).getTime();
  let maxCheckInDate = 0;
  let minCheckOutDate = maxDate;

  const reservations = !isEmpty(property.reservations) ? property.reservations : [];
  const reservationsGrouped = [];
  // const log = [];
  for (const r of reservations) {
    for (const reservation of reservations) {
      if (getDays(r.end, reservation.start) <= 1) {
        reservationsGrouped.push([r.start, reservation.end]);
        // log.push([
        //   moment(r.start).format(DATE_FORMAT),
        //   moment(reservation.end).format(DATE_FORMAT),
        // ]);
      }
      if (getDays(r.start, reservation.end) <= 1) {
        reservationsGrouped.push([reservation.start, r.end]);
        // log.push([
        //   moment(reservation.start).format(DATE_FORMAT),
        //   moment(r.end).format(DATE_FORMAT),
        // ]);
      }
    }
  }
  // console.log(log);

  for (const reservation of reservationsGrouped) {
    const reservationStart = get(reservation, 'start', 0);
    const reservationEnd = get(reservation, 'end', maxDate);
    // console.log('******************************************');
    // console.log('reservationStart: ', moment(reservationStart).format(DATE_FORMAT));
    // console.log('reservationEnd: ', moment(reservationEnd).format(DATE_FORMAT));
    // console.log('dealCheckInDate: ', moment(dealCheckInDate).format(DATE_FORMAT));
    // console.log('dealCheckOutDate: ', moment(dealCheckOutDate).format(DATE_FORMAT));
    maxCheckInDate =
      reservationStart > maxCheckInDate &&
      dateIsBetweenInterval(reservationStart, dealCheckInDate, dealCheckOutDate)
        ? reservationStart
        : maxCheckInDate;
    minCheckOutDate =
      reservationEnd < minCheckOutDate &&
      dateIsBetweenInterval(reservationEnd, dealCheckInDate, dealCheckOutDate)
        ? reservationEnd
        : minCheckOutDate;
    // console.log(
    //   'Result for this it.: ',
    //   moment(maxCheckInDate).format(DATE_FORMAT),
    //   ' - ',
    //   moment(minCheckOutDate).format(DATE_FORMAT)
    // );
  }
  // console.log('--- RESULT ---');
  // console.log('maxCheckInDate: ', moment(maxCheckInDate).format(DATE_FORMAT));
  // console.log('minCheckOutDate: ', moment(minCheckOutDate).format(DATE_FORMAT));
  let message =
    minCheckOutDate < maxDate ? ` a partir del ${moment(minCheckOutDate).format(DATE_FORMAT)}` : '';

  message += maxCheckInDate > 0 ? ` hasta el ${moment(maxCheckInDate).format(DATE_FORMAT)}` : '';

  return (
    <>
      {message !== '' && (
        <div className='row mt-2 justify-content-center'>
          <div className='col-10 text-center availability-message__font-size pt-1'>
            {/* <div className='property-state'> */}
            <span className='availability-message__green-text mid-bold'>Disponible</span>
            <span>{message}</span>
          </div>
        </div>
      )}
    </>
  );
};
const usePropertyProposalsCounter = propertyId => {
  const [proposalsForCounter, setProposalsForCounter] = useState();

  async function fetchProposals(propertyId) {
    const proposals = await ProposalsApi.getAcceptedProposalsByPropertyId(propertyId);

    setProposalsForCounter(proposals);
  }

  useEffect(() => {
    if (propertyId) {
      fetchProposals(propertyId);
    }
  }, [propertyId]);

  return proposalsForCounter;
};

const useProposalsForSameDates = (propertyId, bondedDeal, dateCheckIn, dateCheckOut) => {
  const [proposalsForSameDates, setProposalsForSameDates] = useState();
  const dateCheckInForSearch = dateCheckIn || get(bondedDeal, 'rentalStartDate');
  const dateCheckOutForSearch = dateCheckOut || get(bondedDeal, 'rentalEndDate');

  async function fetchProposals(
    propertyId,
    dateCheckInForSearch,
    dateCheckOutForSearch,
    bondedDeal
  ) {
    if (dateCheckInForSearch && dateCheckOutForSearch) {
      const proposals = await ProposalsApi.getProposalsForSameDates(
        propertyId,
        get(bondedDeal, 'id'),
        adjustDay(dateCheckInForSearch).valueOf(),
        adjustDay(dateCheckOutForSearch).valueOf()
      );

      setProposalsForSameDates(proposals);
    }
  }

  useEffect(() => {
    if (propertyId && dateCheckInForSearch && dateCheckOutForSearch) {
      fetchProposals(propertyId, dateCheckInForSearch, dateCheckOutForSearch, bondedDeal);
    }
  }, [propertyId, dateCheckInForSearch, dateCheckOutForSearch, bondedDeal]);

  return proposalsForSameDates;
};

const useLastAvailabilityQuery = propertyId => {
  const [lastAvailabilityCheck, setLastAvailabilityCheck] = useState();

  async function fetchAvailabilityQueries(propertyId) {
    const businessEvent = await getBusinessEvents(propertyId);

    if (!businessEvent) {
      setLastAvailabilityCheck(false);

      return;
    }

    setLastAvailabilityCheck({
      date: moment(businessEvent.date, 'x').format('DD/MM/YYYY'),
      hour: moment(businessEvent.date, 'x').format('HH:mm'),
      user: prettyPrintAgentFromEmail(businessEvent.from),
      how: getEventTypeText(businessEvent.type),
    });
  }

  useEffect(() => {
    if (propertyId) {
      fetchAvailabilityQueries(propertyId);
    }
  }, [propertyId]);

  return lastAvailabilityCheck;
};

const PropertyInfo = ({ ...props }) => {
  const [viewNewProposalModal, setNewProposalModal] = useState(false);
  const {
    handleCheckAvailabilityByEmail,
    handleCheckAvailabilityByWhatsapp,
    handleCheckAvailabilityByCall,
  } = useCheckAvailabilityHandler(props.bondedDeal, props.currentUsername, props.data);
  const proposalsForCounter = usePropertyProposalsCounter(props.data.id);
  const lastAvailabilityCheck = useLastAvailabilityQuery(props.data.id);

  const dateCheckInFilter = get(props, 'propertiesFilter.startDate');
  const dateCheckOutFilter = get(props, 'propertiesFilter.endDate');
  const proposalsForSameDates = useProposalsForSameDates(
    props.data.id,
    props.bondedDeal,
    dateCheckInFilter,
    dateCheckOutFilter
  );

  const { id: providerId } = props.data.providerBack;
  const [contactForAvailability, setContactForAvailability] = useState();
  const { usualContactBack } = props.data;
  const { baseContact: providerBaseContact } = props.data.providerBack;
  useEffect(() => {
    const fetchProviderChildren = async () => {
      const children = await ProvidersApi.loadProviderWithChildren(providerId);
      const providerContacts = get(children, 'baseContact.contact.children');

      // Determine contact for availability query
      setContactForAvailability(
        getPropertyUsualContact(usualContactBack, providerBaseContact, providerContacts)
      );
    };

    if (providerId) {
      fetchProviderChildren();
    }
  }, [providerId, usualContactBack, providerBaseContact]);

  const monthlyPrice = get(props, 'data.monthlyPrice.amount', 0);

  const imagesToRender = get(props, 'data.pictures');
  // const contactForAvailability =
  //   get(props, 'data.usualContactBack') || get(providerInfo, 'baseContact');

  const isProposalOfDeal = isProposalInBondedDeal(
    get(props, 'bondedDeal.proposals'),
    props.data.id
  );

  const providerInfo = get(props, 'data.providerBack');

  const onClickAvailabilityLink = async () => {
    try {
      const longLink = `${HOMYSPACE_WEB_BASEPATH}disponibilidad/${get(
        props,
        'data.homyspaceReference'
      )}?opportunityId=${get(props, 'bondedDeal.id')}&propertyId=${get(
        props,
        'data.id'
      )}&step=available_price&utm_medium=whatsapp`;

      const response = await api.request(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBqwPnorVaDN8Wma4864Ey-Y9PHwEZEaPA`,
        'POST',
        {
          body: JSON.stringify({
            dynamicLinkInfo: {
              domainUriPrefix: 'https://homyspace.page.link',
              link: longLink,
            },
          }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: '',
          },
        }
      );

      await navigator.clipboard.writeText(get(response, 'json.shortLink'));

      openNotificationWithIcon('info', 'Enlace copiado!');
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', 'Oops', 'Ha ocurrido alguna movida, avisa al lab');
    }
  };

  return (
    <>
      <div className='container' style={{ maxHeight: 1000 }}>
        <div className='row main-row'>
          <div className='col-4 property-chart'>
            <div>
              <i className='fas fa-times close-icon' onClick={props.close} />
            </div>
            <h4 className='property-chart-title pt-3'>
              <a
                id='propertyDetailTitle'
                href={`/propertyDetails/${props.data.id}`}
                onClick={e => {
                  e.preventDefault();
                  if (e.target.name !== 'providerLink') {
                    props.showPropertyDetails(props.data.id);
                  }
                }}
              >
                {get(props, 'data.title') || 'Sin título'}
              </a>
            </h4>
            <div className='property-chart-picture'>
              {get(props, 'data.relevance') && (
                <div className='points'>
                  <div>
                    <span>{normalizeNumberToDecimals(get(props, 'data.relevance') * 100, 2)}</span>
                  </div>
                </div>
              )}

              {/* <!-- SLIDER WITH CONTROLS --> */}
              <div className='carouselMini carousel slide' style={{ marginBottom: '2rem' }}>
                <ImageCarousel data={get(props, 'data')} />
              </div>
            </div>
            <div className='property-info mt-2'>
              <div>
                {/* <div className='col-6'>
                  <i className='homy-color-icon fas fa-user' />
                  <a
                    className='pl-2 property-provider'
                    onClick={e =>
                      e.target.name !== 'providerLink'
                        ? props.showProviderDetails(get(providerInfo, 'baseContact.id'))
                        : ''
                    }
                  >
                    {get(providerInfo, 'baseContact.name') || '[Proveedor sin nombre]'}{' '}
                    {get(providerInfo, 'baseContact.lastOTradeName') || ''}
                  </a>
                </div>

                {providerType && (
                  <div className='col-6 justify-content-end'>
                    <div className='bg-property'>
                      <span className='type-of-property span-bg'>
                        {find(PROVIDER_TYPES, { name: providerType }).title}
                      </span>
                    </div>
                  </div>
                )}
              </div> */}
                <div className='row mt-5 mb-2'>
                  <div className='col-6'>
                    <span
                      className='mid-bold'
                      style={{ paddingTop: 10, paddingBottom: 10, fontSize: 25 }}
                    >
                      {parseFloat(monthlyPrice).toFixed(2)}€
                      <span style={{ fontSize: 12, paddingLeft: 4 }}>/mes</span>
                    </span>
                  </div>
                  {get(props, 'data.distance') && (
                    <div className='col-6' style={{ textAlign: 'right', fontSize: 18 }}>
                      {normalizeNumberToDecimals(get(props, 'data.distance'), 2)}km
                    </div>
                  )}
                </div>
                <div className='row mt-2'>
                  <div className='col-12'>
                    <span id='propertyDetailAddress' style={{ fontSize: 15 }}>
                      {`${get(props, 'data.address.formattedAddress') || ''} ${
                        get(props, 'data.address.addressComplement')
                          ? ' - '.concat(get(props, 'data.address.addressComplement'))
                          : ''
                      }`}
                    </span>
                  </div>
                </div>
              </div>
              <div className='row my-3'>
                <div className='col-3'>
                  <i className='homy-color-icon fas fa-home' style={{ fontSize: 16 }} />
                  <span className='pl-1' id='propertyDetailSize' style={{ fontSize: 16 }}>
                    {get(props, 'data.size') || ''} <span style={{ fontSize: 12 }}>m²</span>
                  </span>
                </div>
                <div className='col-3'>
                  <i className='homy-color-icon fas fa-door-open' style={{ fontSize: 16 }} />
                  <span className='pl-2' id='propertyDetailRooms' style={{ fontSize: 16 }}>
                    {get(props, 'data.rooms') || ''}
                  </span>
                </div>

                <div className='col-3'>
                  <i className='homy-color-icon fas fa-bed' style={{ fontSize: 16 }} />
                  <span className='pl-2' id='propertyDetailBeds' style={{ fontSize: 16 }}>
                    {get(props, 'data.beds') || ''}
                  </span>
                </div>

                <div className='col-3'>
                  <i className='homy-color-icon fas fa-shower' style={{ fontSize: 16 }} />
                  <span className='pl-2' id='propertyDetailBathrooms' style={{ fontSize: 16 }}>
                    {get(props, 'data.bathrooms') || ''}
                  </span>
                </div>
              </div>
              <div className='row my-2'>
                <div className='col-12 text-center'>
                  <span style={{ color: 'gray', fontWeight: 600 }}>
                    Reservado {size(proposalsForCounter) || 0} veces
                  </span>
                </div>
              </div>
              <div className='row my-1'>
                <div className='col-12'>
                  <i className='homy-color-icon fas fa-user' />
                  <a
                    className='pl-2 property-provider'
                    onClick={e =>
                      e.target.name !== 'providerLink'
                        ? props.showProviderDetails(get(providerInfo, 'baseContact.id'))
                        : ''
                    }
                  >
                    <b>Proveedor</b>:{' '}
                    {get(providerInfo, 'baseContact.name') || '[Proveedor sin nombre]'}{' '}
                    {get(providerInfo, 'baseContact.lastOTradeName') || ''}
                  </a>
                </div>
              </div>
              <DataOfContact
                data={props.data}
                bondedDeal={props.bondedDeal}
                onAvailabilityCheck={props.handleCheckAvailability.bind(
                  this,
                  props.data.providerId
                )}
              />
              {lastAvailabilityCheck && (
                <p className='my-2'>
                  Último contacto: {get(lastAvailabilityCheck, 'date', '')}{' '}
                  {get(lastAvailabilityCheck, 'user', '')} {get(lastAvailabilityCheck, 'how', '')}
                </p>
              )}
              <ProposalsAtSameDates proposals={proposalsForSameDates} />
              <AvailabilityMessage
                property={props.data}
                dealCheckInDate={dateCheckInFilter}
                dealCheckOutDate={dateCheckOutFilter}
              />
              {isProposalOfDeal && <RenderCreatedProposal />}
              {!isProposalOfDeal && props.bondedDeal && (
                <>
                  <div className='row justify-content-center my-3'>
                    <div className='col-10 text-center'>
                      <div className='d-flex flex-row mt-2 justify-content-between'>
                        <RenderCheckAvailabilityByWhatsApp
                          handleCheckAvailabilityByWhatsapp={handleCheckAvailabilityByWhatsapp}
                          contactForAvailability={contactForAvailability}
                          props={props}
                          onAvailabilityCheck={props.handleCheckAvailability.bind(
                            this,
                            props.data.providerId
                          )}
                        />
                        <RenderCheckAvailabilityByCall
                          handleCheckAvailabilityByCall={handleCheckAvailabilityByCall}
                          contactForAvailability={contactForAvailability}
                          props={props}
                          onAvailabilityCheck={props.handleCheckAvailability.bind(
                            this,
                            props.data.providerId
                          )}
                        />
                      </div>
                      <div className='d-flex flex-row mt-2 justify-content-center'>
                        <RenderCheckAvailabilityByEmail
                          handleCheckAvailabilityByEmail={handleCheckAvailabilityByEmail}
                          contactForAvailability={contactForAvailability}
                          onAvailabilityCheck={props.handleCheckAvailability.bind(
                            this,
                            props.data.providerId
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col-10 text-center'>
                      <div className='d-flex flex-column mt-2'>
                        <span className='availability-link' onClick={onClickAvailabilityLink}>
                          Copiar enlace a la landing de disponibilidad
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row justify-content-center mb-1'>
                    <div className='col-10 text-center'>
                      <div className='d-flex flex-column'>
                        <RenderCreateProposalBeta
                          property={props.data}
                          bondedDeal={props.bondedDeal}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!isProposalOfDeal && (
                <>
                  <div className='row justify-content-center mb-4'>
                    <div className='col-10 text-center'>
                      <div className='d-flex flex-column'>
                        <RenderCreateProposal
                          viewNewProposalModal={viewNewProposalModal}
                          setNewProposalModal={setNewProposalModal}
                          imagesToRender={imagesToRender}
                          data={props.data}
                          bondedDeal={props.bondedDeal}
                          options={props.options}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .availability-message__green-text {
            color: #47b386;
            font-weight: 600;
          }

          .availability-message__font-size {
            font-size: 1.2em;
          }

          .hs-btn-link {
            color: black;
            font-family: 'Open Sans';
          }

          .hs-btn-link:hover .hs-btn-link:focus {
            border: 1px solid transparent !important;
          }

          .col-4.property-chart {
            min-width: 401px !important;
          }

          .availability-link {
            cursor: pointer;
          }

          .availability-link:hover {
            text-decoration: underline;
          }

          .property-chart {
            width: 401px;
            border-radius: 3px;
            background-color: #ffffff;
            box-shadow: 0 2px 9px 0 rgba(181, 181, 181, 0.5);
          }

          .property-chart-title a {
            color: #000000;
            font-family: 'Open Sans';
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
          }

          .property-chart-picture img {
            height: 203px;
            background-color: #dad6d6;
            position: relative;
          }

          .points {
            display: flex;
            align-items: center;

            color: #000000;
            font-family: 'Open Sans';
            font-size: 28px;
            font-weight: 600;
            line-height: 38px;
            position: absolute;
            z-index: 99;
          }

          .points div {
            height: 50px;
            width: 50px;
            border-radius: 2px;
            background-color: #eeaf30;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 8px;
          }

          .property-info {
            position: relative;
          }

          .bg-property {
            height: 22px;
            border-radius: 2px;
            background-color: #868686;
            padding: 0 8px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
          }

          .bg-property .span-bg,
          .bg-green .span-bg {
            height: 19px;
            color: #ffffff;
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            text-transform: uppercase;
            letter-spacing: 0.03rem;
          }

          .flex-center {
            display: flex;
            justify-content: center;
          }

          .no-margins {
            padding-left: 0px !important;
            padding-right: 0px !important;
          }

          .no-margins div {
            /* Reponer cuando se añadan las funcs. de añadir ocupación y copiar mensaje de disponibilidad */
            /* width: 134px; */
            width: 100%;
            padding: 10px;

            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            color: #000000;
            font-family: 'Open Sans';
            font-size: 12px;
          }

          .less-space {
            width: 50%;
          }

          .frame-picture {
            width: 100%;
            height: 100%;
          }

          .frame-picture img {
            margin: 0 auto;
            width: 936px;
          }

          .main-row {
            position: relative;
            height: 100%;
            /* width: 0; */
          }

          .bg-green {
            background-color: #47b386;
            height: 22px;
            width: 6rem;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
          }

          .fas.homy-color-icon {
            color: #eeaf30;
            font-size: 15px;
          }

          .property-state {
            color: #ec6e54 !important;
          }

          .property-provider,
          .homy-color-icon + span,
          .bg-green + span,
          .more-details,
          .list-info,
          .property-state,
          .no-margins div a {
            color: #000000;
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 0px;
          }

          .more-details {
            padding: 0px;
          }

          .no-margins div a {
            font-size: 12px;
          }

          .more-details {
            text-decoration: underline;
          }

          .flat-details {
            color: #000000;
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 19px;
            margin: 0;
          }

          .space-before-icon img {
            margin-top: -6px;
          }

          .properties-icon-container {
            height: 40px;
            width: 40px;
            border: 1px solid #eeaf30;
            border-radius: 3px;

            display: flex;
            justify-content: center;
            align-items: center;
          }

          .properties-icon-icon {
            font-size: 20px;
            color: black;
          }

          .modal-lg {
            width: 90%;
            max-width: 1800px;
          }

          .icon-table-container {
            width: 190px;
          }

          .modal-content {
            height: 700px;
          }

          .table td {
            border-top: 0;
          }

          h5 {
            font-size: 27px;
          }

          .whatsapp-button-disabled {
            opacity: 0.3;
          }

          .whatsapp-button-disabled:hover {
            cursor: not-allowed;
            text-decoration: none;
          }

          .address-complement {
            padding-left: 20px;
            color: #000000;
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 19px;
          }

          .propuesta-estado-container {
            min-height: 80px;
          }

          .propuesta-creada-color {
            color: #47b386;
            background-color: transparent;
            background-image: none;
            border-color: #47b386;

            color: #47b386;
            background-color: transparent;
            background-image: none;
            border: 1px solid #47b386;
            padding: 0.375rem 0.75rem;

            font-size: 1rem;
            font-weight: 600;
          }

          .icon-bold {
            font-weight: 600;
            font-size: 16px;
          }

          .infoWindow-bottom {
            cursor: pointer;
            padding: 15px !important;
          }

          .infoWindow-bottom > i {
            font-size: 20px;
            margin-right: 10px;
          }

          .infoWindow-bottom:hover > span {
            text-decoration: underline;
          }

          .availability-button {
            width: 150px;
          }

          .availability-button.email {
            background-color: transparent;
            color: #2c9a9e;
            border: 1px solid #2c9a9e;
          }

          .availability-button.whatsapp {
            background-color: #2c9a9e;
            color: white;
          }

          .availability-button.whatsapp:hover {
            background-color: #30adb2;
            color: white;
            border: 1px solid transparent;
          }

          .availability-button.whatsapp:disabled {
            color: rgba(0, 0, 0, 0.25);
            background-color: #f5f5f5;
            border-color: #d9d9d9;
          }

          .availability-button.whatsapp > i {
            margin-right: 5px;
            font-size: 14px;
          }

          .availability-button.call {
            background-color: transparent;
            color: #2c9a9e;
            border: 1px solid #2c9a9e;
          }

          .availability-button.call:hover,
          .availability-button.email:hover {
            background-color: whitesmoke;
          }

          .availability-button.call:disabled {
            color: rgba(0, 0, 0, 0.25);
            background-color: #f5f5f5;
            border-color: #d9d9d9;
          }

          .availability-button.call > i {
            margin-right: 5px;
            font-size: 14px;
          }

          hr {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }
        `}
      </style>
    </>
  );
};

PropertyInfo.propTypes = {
  data: PropTypes.shape({}),
  bondedDeal: PropTypes.shape({}),
  currentUsername: PropTypes.string,
  handleCheckAvailability: PropTypes.func,
};
PropertyInfo.defaultProps = {
  handleCheckAvailability: () => {},
};

const mapStateToProps = function(state) {
  return {
    currentUsername: state.Preload.currentUserInformation.username,
  };
};

export default connect(mapStateToProps)(PropertyInfo);
