import { getAxiosInstance } from '../../shared/utils/apiUtils';
import api from '../../shared/utils/api';
import { getProviderWithChildren, getUpdateLeadStatusOfProvider } from '../../shared/apiUrls';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

class ProvidersApi {
  static getMetasource(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getProvidersTableData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getNewProviderLocation(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(url);
  }

  static getProvidersMarketingSourceMediumOptions(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static loadProviderWithChildren = async providerId => {
    if (!isNil(providerId)) {
      const url = getProviderWithChildren(providerId);

      try {
        const res = await api.request_retry(url, 'get', 3);
        const providerWithChildren = get(res, 'json.provider', []);

        return providerWithChildren;
      } catch (e) {
        console.error(`Call to ${url} failed: ${e.message}`);
      }
    }
  };

  static async updateLeadStatusOfProvider(providerId) {
    return await api.request(getUpdateLeadStatusOfProvider(providerId), 'put');
  }
}

export default ProvidersApi;
