import { getAxiosInstance } from '../../shared/utils/apiUtils';

class ProviderDetailsApi {
  static getMetasource(url, id) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url + '/' + id);
  }

  static getProviderDetailsData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static saveProviderDetailsData(url, providerDetails, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, providerDetails);
  }

  static doDelete(url, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.delete(url);
  }

  static getNewContactLocation(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(url);
  }
}

export default ProviderDetailsApi;
