import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row, Typography } from 'antd';
import TableDataInvoices from '../../components/TableDataInvoices';
import {
  filterTableDataInvoices,
  getNewInvoiceLocation,
} from '../../modules/Invoices/InvoicesActions';
import {
  getOptionsForSelect,
  openNotificationWithIcon,
  parseStringValuesToComboValue,
  setKeyLabelESToLabel,
} from '../../shared/utils/appUtils';
import FormNewInvoice from '../../components/FormNewInvoice';
import InvoicesFilter from './InvoicesFilter';
import tableUtils from '../../shared/utils/tableUtils';
import api from '../../shared/utils/api';
import isNil from 'lodash/isNil';
import { INVOICE_TYPES_OPTIONS_ES } from 'hs-utils';

const { Title } = Typography;
// TODO: Revisar el filtrado por tipo de destinatario y nº de factura
// TODO: Revisar a ordenación por fecha e importes

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      table: {
        data: [],
      },
      filter: {
        ...props.filter,
        showMoreFilters: false,
      },
      options: {
        rentalRequestType: getOptionsForSelect(this.props.optionLabels, 'RentalRequestType'),
        receiverType: getOptionsForSelect(this.props.optionLabels, 'ReceiverType'),
        invoiceState: getOptionsForSelect(this.props.optionLabels, 'InvoiceState'),
        // invoiceType: getOptionsForSelect(this.props.optionLabels, 'InvoiceType'),
        invoiceType: INVOICE_TYPES_OPTIONS_ES,
        marketingSourceMedium: setKeyLabelESToLabel(this.props.marketingSourceMedium),
      },
      modal: false,
      invoiceLocation: props.invoiceLocation,
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      sorter: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleRecurrentChange = this.handleRecurrentChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleFilterCollapse = this.handleFilterCollapse.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleExportation = this.handleExportation.bind(this);
  }

  componentDidMount() {
    this.handleSubmit();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.invoiceLocation !== newProps.invoiceLocation) {
      this.setState(
        {
          invoiceLocation: newProps.invoiceLocation,
        },
        () => this.toggle()
      );
    }
  }

  handleSubmit = () => {
    const { pagination, sorter } = this.state;
    const filters = this.filterData();

    const params = {
      pagination,
      filters,
      sorter,
    };

    api
      .getTableData('invoice', params)
      .then(res => {
        this.setState({
          loading: false,
          table: {
            data: res.json.invoiceList,
          },
          pagination: {
            ...pagination,
            totalCount: parseInt(res.headers.get('x-total-count'), 10) || 0,
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de procesar datos en Facturas... ${err.message}`
        );
      });
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleFilterChange(e) {
    e.preventDefault();
    const { filter } = this.state;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    filter[e.target.name] = value;
    this.setState({ filter });
  }

  handleSelectChange(name, selectedOption, mode = 'default') {
    const { filter } = this.state;
    const options = parseStringValuesToComboValue(selectedOption, this.state.options[name], mode);

    this.setState({ filter: { ...filter, [name]: options || null } });
  }

  handleRecurrentChange(value) {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        recurrent: value.target.checked,
      },
    }));
  }

  handleDataChange = (name, date) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [name]: isNil(date) ? null : date.valueOf(),
      },
    }));
  };

  handleFilter = e => {
    e.preventDefault();

    const { pagination, filter } = this.state;

    this.setState(
      {
        loading: true,
        pagination: {
          ...pagination,
          page: 1,
        },
      },
      () => {
        this.props.actions.filterTableDataInvoices(filter);
        this.handleSubmit();
      }
    );
  };

  sorterData = sorter => {
    const condition = tableUtils.getSortCondition(sorter, 'invoice');

    this.setState(
      {
        sorter: condition,
        loading: true,
      },
      () => this.handleSubmit()
    );
  };

  handleFilterCollapse() {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        showMoreFilters: !prevState.filter.showMoreFilters,
      },
    }));
  }

  handlePaginationChange = (page, pageSize) => {
    this.setState(
      prevState => ({
        loading: true,
        pagination: {
          ...prevState.pagination,
          pageSize,
          page,
        },
      }),
      () => this.handleSubmit()
    );
  };

  filterData() {
    const { filter } = this.state;

    return filter;
  }

  handleExportation() {
    const filters = this.filterData();

    api
      .getExportToExcelData('invoice', filters)
      .then(res => {
        openNotificationWithIcon(
          'info',
          <span role='img' aria-label='ok'>
            Exportación realizada con éxito &#128076;
          </span>,
          res.message
        );
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          <span aria-label='error'>El servidor está ocupado</span>,
          'En este momento se está generando un informe para otro agente. Inténtalo de nuevo dentro de unos minutos.'
        );

        console.error('Error in exportation', err);
      });
  }

  render() {
    return (
      <div className='animated fadeIn invoices'>
        <Row align='middle' gutter={8}>
          <Col xxl={20} xl={18} lg={12} md={12} sm={24} xs={24}>
            <Title level={2} className='title-invoices'>
              Facturas
              {/* <img src='img/xmas/snowman.png' alt='snowman' height={50} /> */}
            </Title>
          </Col>
          <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
            <Button className='exportation' icon='download' onClick={this.handleExportation}>
              Exportar
            </Button>
          </Col>
          <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
            <Button
              className='btn-primary create-invoice'
              onClick={() => this.props.actions.getNewInvoiceLocation()}
            >
              Crear factura
            </Button>
            <Modal
              title='Nueva factura'
              visible={this.state.modal}
              onCancel={this.toggle}
              width={920}
              footer={null}
            >
              <FormNewInvoice />
            </Modal>
          </Col>
        </Row>
        <Row className='row-filter-invoices'>
          <Col span={24}>
            <InvoicesFilter
              filter={this.state.filter}
              handleFilterChange={this.handleFilterChange}
              handleDataChange={this.handleDataChange}
              loading={this.state.loading}
              options={this.state.options}
              handleSelectChange={this.handleSelectChange}
              handleFilter={this.handleFilter}
              handleFilterCollapse={this.handleFilterCollapse}
              handleRecurrentChange={this.handleRecurrentChange}
            />
          </Col>
        </Row>
        <Row className='row-table-invoices'>
          <Col span={24}>
            <TableDataInvoices
              data={this.state.table.data}
              loading={this.state.loading}
              options={this.state.options}
              pagination={this.state.pagination}
              handleSorter={this.sorterData}
              handlePaginationChange={this.handlePaginationChange}
              handlePaginationSizeChange={this.handlePaginationChange}
            />
          </Col>
        </Row>
        <style jsx>
          {`
            .invoices {
              margin: 10px;
            }

            .create-invoice {
              width: 100%;
            }

            .title-invoices {
              margin: 0;
            }

            .row-filter-invoices {
              margin-top: 10px;
            }

            .row-table-invoices {
              margin-top: 10px;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.Invoices.metasource,
    filter: state.Invoices.filter,
    optionLabels: state.Preload.optionLabels,
    invoiceLocation: state.Invoices.invoiceLocation.location,
    marketingSourceMedium: state.Companies.invoiceMarketingSourceMediumOptions.options.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      filterTableDataInvoices: filterCriteria => {
        dispatch(filterTableDataInvoices(filterCriteria));
      },
      getNewInvoiceLocation: () => {
        dispatch(getNewInvoiceLocation());
      },
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
