import React from 'react';
import { HomySwitch, InformationBlock } from 'homy-ui';

const PropertiesSmartSearch = ({ checked = false, onChange, disabled }) => {
  const infoMessage = disabled
    ? 'El algoritmo de búsqueda de inmuebles inteligente no se puede utilizar porque falta el NIF del cliente'
    : 'El algoritmo de búsqueda inteligente ignora el valor de los filtros';
  return (
    <div className='d-flex mb-2' style={{ marginTop: '-10px', minHeight: 30 }}>
      <HomySwitch
        label='Mostrar los resultados del algoritmo de búsqueda inteligente'
        id='smartSearch'
        name='smartSearch'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {(disabled || checked) && (
        <div className='pl-3'>
          <InformationBlock type='warning' icon={['fas', 'info-circle']}>
            {infoMessage}
          </InformationBlock>
        </div>
      )}
      <style jsx>
        {`
          .hs-switch {
            margin-bottom: 0px;
          }
        `}
      </style>
    </div>
  );
};

export default PropertiesSmartSearch;
