import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Row } from 'antd';
import { createNewProperty } from '../../modules/PropertyDetails/PropertyDetailsActions';
import SelectProvider from '../SelectProvider';
import { validateInputs } from '../../shared/utils/formUtils';
import get from 'lodash/get';
import { getNewPropertyLocation } from '../../modules/Properties/PropertiesActions';

// PROPERTIES TO BE CONFIGURED IN PARENT
// providerDetails -> (optional) to preload provider select when creating a new property

class FormNewProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPropertyDetails: {
        title: '',
        providerId: get(props, 'providerDetails.id', ''),
      },
      submitting: false,
      invalidInput: false,
    };
    this.props.propertyDetailsCreate.error = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);

    this.props.actions.getNewPropertyLocation();
  }

  handleChange(e) {
    const localNewPropertyDetails = this.state.newPropertyDetails;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    localNewPropertyDetails[e.target.name] = value;
    this.setState({ newPropertyDetails: localNewPropertyDetails });
  }

  handleSelectChange(name, selectedOption) {
    const newProperty = this.state.newPropertyDetails;

    newProperty[name] = selectedOption
      ? selectedOption.value
      : this.state.newPropertyDetails.providerId;
    this.setState({ newPropertyDetails: newProperty });
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !validForm });

    return validForm;
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.isValidForm()) {
      this.setState({ submitting: true }, () => {
        this.props.actions.createNewProperty(
          this.props.propertyLocation,
          this.state.newPropertyDetails
        );
      });
    }
  };

  render() {
    return (
      <div>
        {this.props.propertyDetailsCreate.error && (
          <Alert
            message='Error'
            description={this.props.propertyDetailsCreate.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
        {this.state.invalidInput && (
          <Alert message='Error en los datos introducidos' type='error' showIcon closable />
        )}
        <form id='formNewPropertyDetails' onSubmit={this.handleSubmit}>
          <Row>
            <Col span={24}>
              <label htmlFor='propertyDetailTitle' className='required'>
                Título
              </label>
              <input
                type='text'
                className='form-control validate validate-required'
                id='propertyDetailTitle'
                name='title'
                placeholder='Piso en Mallorca'
                value={this.state.newPropertyDetails.title}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <SelectProvider
                name='providerId'
                id='propertyDetailProvider'
                label='Proveedor'
                value={
                  this.state.newPropertyDetails.providerId
                    ? this.state.newPropertyDetails.providerId
                    : ''
                }
                isRequired
                handleChange={this.handleSelectChange}
              />
            </Col>
          </Row>
          <br />
          <Row type='flex' justify='end'>
            <Col>
              <Button loading={this.state.submitting} htmlType='submit' className='btn-primary'>
                Guardar
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getNewPropertyLocation: () => dispatch(getNewPropertyLocation()),
      createNewProperty: (url, propertyDetailsData) =>
        dispatch(createNewProperty(url, propertyDetailsData)),
    },
  };
}

function mapStateToProps(state) {
  return {
    propertyDetailsCreate: state.PropertyDetails.create,
    propertyLocation: state.Properties.propertyLocation.location,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormNewProperty);
