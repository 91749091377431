import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../../shared/appConfig';
import HomyPagination from '../HomyComponents/HomyPagination';
import tableUtils from '../../shared/utils/tableUtils';
import { browserHistory } from 'react-router';

class TableDataCompanies extends Component {
  constructor(props) {
    super(props);

    this.showCompanyDetail = this.showCompanyDetail.bind(this);
  }

  showCompanyDetail(companyId) {
    browserHistory.push(`/companyDetails/${companyId}`);
  }

  render() {
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        sorter: true,
        width: 350,
        render: (text, record) => <a href={`/companyDetails/${record.id}`}>{text}</a>,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showCompanyDetail(record.id);
        },
      },
      {
        title: 'Apellidos / Razón social',
        dataIndex: 'lastOTradeName',
        sorter: true,
        width: 350,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showCompanyDetail(record.id);
        },
      },
      {
        title: 'NIF',
        dataIndex: 'identificationCode',
        sorter: true,
        width: 150,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
        width: 250,
        render: val => <a href={`mailto:pruebas@homyspace.com`}>{val}</a>,
      },
      {
        title: 'Teléfono',
        dataIndex: 'phoneNumber',
        sorter: true,
        width: 200,
        render: val => <a href={`tel:${val}`}>{val}</a>,
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'creationDate',
        sorter: true,
        render: val => moment(val).format(DATE_FORMAT),
      },
    ];

    const scrollX = tableUtils.getTableWidth();
    const scrollY = tableUtils.getTableHeight() - 15;

    return (
      <div className={`animated fadeIn companyBorderTopColor`}>
        <Table
          size='middle'
          className='fixed-width-table'
          pagination={false}
          dataSource={this.props.data}
          columns={columns}
          rowKey={rec => rec.id}
          onChange={(pagination, filter, sorter) => this.props.handleSorter(sorter)}
          scroll={{ x: scrollX, y: scrollY }}
          loading={this.props.loading}
          footer={() => (
            <HomyPagination
              showSizeChanger
              showQuickJumper
              current={this.props.pagination.page}
              pageSize={this.props.pagination.pageSize}
              totalCount={this.props.pagination.totalCount}
              onShowSizeChange={this.props.handlePaginationSizeChange}
              onChange={this.props.handlePaginationChange}
            />
          )}
        />
      </div>
    );
  }
}

export default TableDataCompanies;
