import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Alert } from 'antd';
import { getProviderDetailsData } from '../../modules/ProviderDetails/ProviderDetailsActions';
import FormProviderDetails from '../FormProviderDetails';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

// PARAMETROS A CONFIGURAR
// leftOffset -> columnas bootstrap a desplazer el botón
// withSelfLink -> boolean to indicate if we need to follow the self link
// compressed -> boolean that indicates if we need to render the compressed version of the button

class InfoButtonProviderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProviderDetail: false,
      providerDetails: {},
      providerDetailsMetasourceLinkReduced: '',
    };
    this.showProviderDetail = this.showProviderDetail.bind(this);
    this.showProviderDetailsView = this.showProviderDetailsView.bind(this);

    this.props.actions.getProviderDetailsData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.providerDetails !== nextProps.providerDetails) {
      this.setState({ providerDetails: nextProps.providerDetails });
    }

    if (
      this.state.providerDetailsMetasourceLinkReduced !==
      nextProps.providerDetailsMetasourceLinkReduced
    ) {
      // si cambia el enlace del recurso, cargamos de nuevo los datos del recurso
      this.setState({
        providerDetailsMetasourceLinkReduced: nextProps.providerDetailsMetasourceLinkReduced,
      });
      this.props.actions.getProviderDetailsData();
    }
  }

  componentDidUpdate() {
    if (document.getElementById('infoButtonProviderDetails')) {
      if (this.state.showProviderDetail) {
        document.getElementById('infoButtonProviderDetails').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonProviderDetails').style.zIndex = 1;
      }
    }
  }

  showProviderDetail(event) {
    if (event.target.nodeName !== 'A') {
      const showOrHide = !this.state.showProviderDetail;

      this.setState({
        showProviderDetail: showOrHide,
      });
    }
  }

  showProviderDetailsView(event) {
    if (this.props.withSelfLink) {
      event.preventDefault();
      browserHistory.push(`/providerDetails/${this.state.providerDetails.id}`);
    }
  }

  render() {
    let leftOffset = 0;

    if (this.props.leftOffset) {
      leftOffset = parseInt(this.props.leftOffset, 10) * -(!this.props.compressed ? 20 : 33.3);
    }

    const styleInfoButtonDetail = {
      marginLeft: `${leftOffset}%`,
    };
    let colStyle = 'col-sm-5';
    let iconStyle = 'p-1 mr-1';
    let detailStyle = 'col-sm-5 floatLeft noPadding';

    if (this.props.compressed) {
      colStyle = 'col-sm-3';
      iconStyle = '';
      detailStyle = 'col-sm-12 pl-3';
    }

    const hasAcceptedGeneralConditions =
      isNumber(this.state.providerDetails.generalConditionsDate) &&
      !isEmpty(this.state.providerDetails.generalConditionsOrigin);

    return (
      <div id='infoButtonProviderDetails' className={`${colStyle} noPadding`}>
        {this.props.providerDetails.error && (
          <Alert
            message='Error'
            description={this.props.providerDetails.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}

        <div
          className='card card-inverse providerBackgroundColor infoButton'
          onClick={this.showProviderDetail}
        >
          {this.state.providerDetails.baseContact && (
            <div className='card-block'>
              <div className='row'>
                <div className='col-sm-2'>
                  {this.state.providerDetails.providerType === 'COMPANY' && (
                    <i className={`fa fa-building font-3xl ${iconStyle}`} />
                  )}
                  {this.state.providerDetails.providerType === 'MANAGER' && (
                    <i className={`fa fa-home font-3xl ${iconStyle}`} />
                  )}
                  {this.state.providerDetails.providerType === 'PRIVATE' && (
                    <i className={`fa fa-user font-3xl ${iconStyle}`} />
                  )}
                </div>
                <div className='col-sm-10 noPadding'>
                  <div className='h4 m-0 bold linkBehavior' onClick={this.showProviderDetailsView}>
                    {this.state.providerDetails.baseContact.name}
                    <br />
                    <small>
                      {this.state.providerDetails.baseContact.lastOTradeName
                        ? this.state.providerDetails.baseContact.lastOTradeName
                        : ''}
                    </small>
                  </div>
                  <div>
                    {this.state.providerDetails.baseContact.worldWideAddress &&
                      this.state.providerDetails.baseContact.worldWideAddress.formattedAddress}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-2 floatLeft noPadding' />
                <div className={`${detailStyle} fontSizeMedium`}>
                  {this.state.providerDetails.baseContact.email && (
                    <div>
                      {!this.props.compressed && (
                        <i className='fa fa-envelope' aria-hidden='true' />
                      )}{' '}
                      <a className='whiteLink' href={`mailto:pruebas@homyspace.com`}>
                        {this.state.providerDetails.baseContact.email}
                      </a>
                    </div>
                  )}
                  {this.state.providerDetails.baseContact.email2 && (
                    <div>
                      {!this.props.compressed && (
                        <i className='fa fa-envelope' aria-hidden='true' />
                      )}{' '}
                      <a className='whiteLink' href={`mailto:pruebas@homyspace.com`}>
                        {this.state.providerDetails.baseContact.email2}
                      </a>
                    </div>
                  )}
                  {hasAcceptedGeneralConditions && (
                    <div>
                      <i className='fa fa-check-square-o' aria-hidden='true' /> Condiciones
                      generales aceptadas
                    </div>
                  )}
                </div>
                <div className={`${detailStyle} fontSizeMedium`}>
                  {this.state.providerDetails.baseContact.phoneNumber && (
                    <div>
                      {!this.props.compressed && <i className='fa fa-phone' aria-hidden='true' />}{' '}
                      <a
                        className='whiteLink'
                        href={`tel:${this.state.providerDetails.baseContact.phoneNumber}`}
                      >
                        {this.state.providerDetails.baseContact.phoneNumber}
                      </a>
                    </div>
                  )}
                  {this.state.providerDetails.baseContact.mobilePhoneNumber && (
                    <div>
                      {!this.props.compressed && <i className='fa fa-mobile' aria-hidden='true' />}{' '}
                      &nbsp;{' '}
                      <a
                        className='whiteLink'
                        href={`tel:${this.state.providerDetails.baseContact.mobilePhoneNumber}`}
                      >
                        {this.state.providerDetails.baseContact.mobilePhoneNumber}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {this.state.showProviderDetail && (
                <div className='openIcon'>
                  <i className='fa fa-caret-down fa-2x' />
                </div>
              )}
            </div>
          )}
        </div>

        {this.state.showProviderDetail && (
          <div
            style={styleInfoButtonDetail}
            className='infoButtonDetail cardNoMargin noPadding animated fadeIn providerBorderTopColor'
          >
            <div className='card'>
              <div className='card-block'>
                <FormProviderDetails />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProviderDetailsData: () => dispatch(getProviderDetailsData()),
    },
  };
}

function mapStateToProps(state) {
  return {
    providerDetails: state.ProviderDetails.data.detail,
    providerDetailsMetasourceLinkReduced: state.ProviderDetails.metasource
      ? state.ProviderDetails.metasource.links.reduced
      : '',
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButtonProviderDetails);
