import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDealsList, openNotificationWithIcon } from '../../shared/utils/appUtils';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import assign from 'lodash/assign';
import { showOrLoadDealDetailsView } from '../../modules/DealDetails/DealDetailsActions';
import { PARSE_DATE_FORMAT } from '../../shared/appConfig.js';
import { Select, Spin } from 'antd';
import api from '../../shared/utils/api';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 handleChange -> function to be called when active state change
 isRequired -> boolean to indicate if component is required
 value -> select's value
 selectedDeal -> select's object value
 prefilter -> prefilter to apply besides search string
 name -> name of the component
 id -> id of the component
 ******************************************************* */

class SelectDeal extends Component {
  static MINIMUM_CHARS_TO_SEARCH = 3;

  static INIT_MODE_WITH_DEAL_ID = 'WITH_DEAL_ID';

  static INIT_MODE_WITH_DEAL_OBJECT = 'WITH_DEAL_OBJECT';

  static INIT_MODE_WITH_NO_PARAMS = 'WITH_NO_PARAMS';

  static TRANSACTION_LOAD_NEW_DEAL_LIST = 'LOAD_NEW_DEAL_LIST';

  static TRANSACTION_LOAD_NEW_DEAL = 'LOAD_NEW_DEAL';

  constructor(props) {
    super(props);
    this.CURRENT_TRANSACTION = null;
    this.INIT_MODE = SelectDeal.INIT_MODE_WITH_NO_PARAMS;
    let theValue;
    let theDealData;

    if (props.selectedDeal) {
      theValue = props.selectedDeal.id;
      theDealData = { detail: props.selectedDeal, error: null };
      this.INIT_MODE = SelectDeal.INIT_MODE_WITH_DEAL_OBJECT;
    } else if (props.value) {
      theValue = props.value;
      this.INIT_MODE = SelectDeal.INIT_MODE_WITH_DEAL_ID;
    }

    this.state = {
      value: theValue,
      options: props.options || [],
      initialOptions: props.options || [],
      firstLoad: true,
      isLoading: false,
      dealData: theDealData,
      dealsList: [],
      label: '',
    };

    this.setSelectedDealInState = this.setSelectedDealInState.bind(this);
    this.setStateToNull = this.setStateToNull.bind(this);
    this.getDealData = this.getDealData.bind(this);
    this.loadDefaultList = this.loadDefaultList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getItemInList = this.getItemInList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getDeals = this.getDeals.bind(this);
  }

  componentDidMount() {
    if (this.INIT_MODE === SelectDeal.INIT_MODE_WITH_NO_PARAMS) {
      this.loadDefaultList();
    } else if (this.INIT_MODE === SelectDeal.INIT_MODE_WITH_DEAL_ID) {
      this.getDealData();
    } else if (this.INIT_MODE === SelectDeal.INIT_MODE_WITH_DEAL_OBJECT) {
      this.setSelectedDealInState(this.state.dealData.detail);
    }
  }

  setSelectedDealInState(dealDetail) {
    this.setState({
      value: dealDetail.id,
      label: !isEmpty(dealDetail)
        ? `(${moment(dealDetail.creationDate).format(PARSE_DATE_FORMAT)} - ${get(
            dealDetail,
            'hsagent.username',
            '[Sin agente asignado]'
          )}) ${dealDetail.identificationCode}`
        : '',
      options: !isEmpty(dealDetail)
        ? [
            {
              label: `(${moment(dealDetail.creationDate).format(PARSE_DATE_FORMAT)} - ${get(
                dealDetail,
                'hsagent.username',
                '[Sin agente asignado]'
              )}) ${dealDetail.identificationCode}`,
              value: dealDetail.id,
            },
          ]
        : [],
      isLoading: false,
      dealData: dealDetail,
      dealsList: [],
    });
  }

  setStateToNull() {
    this.CURRENT_TRANSACTION = null;
    this.setState({
      value: null,
      options: [],
      isLoading: false,
      dealData: null,
      dealsList: [],
    });
  }

  getDealData() {
    this.CURRENT_TRANSACTION = SelectDeal.TRANSACTION_LOAD_NEW_DEAL;
    this.setState({ isLoading: true }, () => {
      this.props.actions.getDealData(this.state.value);
    });
  }

  loadDefaultList() {
    this.getDealsListData();
  }

  getDealsListData = searchString => {
    this.CURRENT_TRANSACTION = SelectDeal.TRANSACTION_LOAD_NEW_DEAL_LIST;
    this.setState({ isLoading: true }, () => {
      this.getDeals(searchString);
    });
  };

  getDeals = async searchString => {
    try {
      const params = {
        filters: assign(
          {},
          this.props.prefilter || {},
          searchString ? { globalSearch: searchString } : {}
        ),
        sorter: '-creationDate',
      };

      const response = await api.getTableDataV2('opportunities', params);

      if (this.CURRENT_TRANSACTION === SelectDeal.TRANSACTION_LOAD_NEW_DEAL_LIST) {
        const deals = get(response, 'json.searchItems');
        const dealsListed = getDealsList(deals);

        const { firstLoad, initialDealsList } = this.state;

        this.setState({
          value: null,
          options: dealsListed,
          dealData: null,
          dealsList: deals,
          initialDealsList: firstLoad ? dealsListed : initialDealsList,
          firstLoad: false,
        });
      }
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Ha ocurrido un error al cargar la lista de Oportunidades',
        e.message
      );
      console.error(e);
    }

    this.setState({
      isLoading: false,
    });
  };

  handleChange = e => {
    let selectedObject;

    if (e) {
      selectedObject = this.getItemInList(e.target.value);
      this.setState(
        {
          label: e.target.label,
          isLoading: false,
          dealData: selectedObject,
          // dealsList: [selectedObject],
        },
        () => {
          this.setChangeToForm(e);
          if (this.props.handleChange) {
            this.props.handleChange({
              target: {
                ...e.target,
                details: selectedObject,
              },
            });
          }
        }
      );
    } else {
      this.loadDefaultList();
    }
  };

  setChangeToForm = e => {
    const { onChange } = this.props;

    if (onChange) {
      onChange({
        label: e.target.label,
        value: e.target.value,
      });
    }
  };

  getItemInList(id) {
    const list = get(this.state, 'dealsList');

    if (list) {
      for (let d = 0; d < list.length; d++) {
        if (list[d].id === id) {
          return list[d];
        }
      }
    } else {
      return null;
    }
  }

  handleInputChange(value) {
    if (value.length >= SelectDeal.MINIMUM_CHARS_TO_SEARCH) {
      this.getDealsListData(value);
    } else if (value.length === 0) {
      this.setState({
        options: this.state.initialDealsList,
      });
    } else {
      this.setState({
        options: [],
      });
    }
  }

  render() {
    const { options, isLoading } = this.state;

    return (
      <Select
        id={this.props.id}
        name={this.props.name}
        disabled={this.props.disabled}
        value={get(this.state, 'label')}
        onChange={(value, option) => {
          this.handleChange({
            target: {
              name: get(this.props, 'name'),
              label: get(option, 'key'),
              value,
              type: 'select',
              mode: 'default',
            },
          });
        }}
        filterOption={false}
        onSearch={value => {
          this.handleInputChange(value);
        }}
        loading={isLoading}
        placeholder='Escribe para empezar a filtrar...'
        notFoundContent={isLoading ? <Spin size='small' /> : null}
        showArrow
        showSearch
        allowClear
      >
        {!isEmpty(options) &&
          options.map(option => (
            <Select.Option
              className='homy-option'
              key={option.label}
              value={option.initialValue || option.value}
            >
              {option.label}
            </Select.Option>
          ))}
      </Select>
    );
  }
}

function mapStateToProps(state) {
  return {
    dealsList: state.Deals.dealsList,
    dealsMetasource: state.Deals.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    dealData: state.DealDetails.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getDealData: id => dispatch(showOrLoadDealDetailsView(id, false, true)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDeal);
