import { getAxiosInstance } from '../../shared/utils/apiUtils';

class CompaniesApi {
  static getMetasource(url) {
    return this.doGet(url);
  }

  static getNewCompanyLocation(url) {
    return this.doPost(url).then(res => res.headers['location']);
  }

  static createNewCompany(companyData, url) {
    return this.getNewCompanyLocation(url).then(location => this.doPut(location, companyData));
  }

  static doGet(url) {
    return getAxiosInstance().get(url);
  }

  static doPost(url) {
    return getAxiosInstance().post(url);
  }

  static doPut(url, data) {
    return getAxiosInstance().put(url, data);
  }
}

export default CompaniesApi;
