export const geocodeAddress = (address, callback) => {
  const { google } = window;

  if (!google) {
    return;
  }

  var geocoder = new google.maps.Geocoder();

  return geocoder.geocode({ address: address }, function(results, status) {
    if (status === 'OK') {
      callback(results[0]);
    } else {
      console.error('Geocode was not successful for the following reason: ' + status);
    }
  });
};

export const formatPlaceToWorldWideAddress = place => {
  if (!place) {
    return;
  }

  const worldWideAddress = {};

  worldWideAddress['formattedAddress'] = place.formatted_address;
  worldWideAddress['placeId'] = place.place_id;
  const lat = place.geometry.location.lat();
  const lng = place.geometry.location.lng();

  worldWideAddress['geoLocation'] = { lat, lng };
  place.address_components.forEach(item => {
    worldWideAddress[formatJavaString(item.types[0])] = item.long_name;
  });

  if (!worldWideAddress.locality) {
    worldWideAddress.locality = worldWideAddress.postalTown;
  }

  return worldWideAddress;
};

function formatJavaString(string) {
  if (!string) {
    return;
  }

  const stringDivided = string.split('_');
  let stringFormatted = '';

  stringDivided.forEach((value, index) => {
    if (index === 0) {
      stringFormatted = value;
    } else {
      stringFormatted += capitalizeFirstLetter(value);
    }
  });

  return stringFormatted;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
