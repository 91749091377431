import {
  CLONE_PROPERTY_ERROR,
  CLONE_PROPERTY_SUCCESS,
  NEW_PROPERTY_CREATE_ERROR,
  PROPERTYDETAILS_DATA_ERROR,
  PROPERTYDETAILS_DATA_SUCCESS,
  PROPERTYDETAILS_IMAGES_SUBMIT_ERROR,
  PROPERTYDETAILS_METASOURCE_ERROR,
  PROPERTYDETAILS_METASOURCE_SUCCESS,
  PROPERTYDETAILS_PRINTABLE_DATA,
  PROPERTYDETAILS_SUBMIT_ERROR,
  PROPERTYDETAILS_SUBMIT_SUCCESS,
  PROPOSALS_TABLEDATA_ERROR,
  PROPOSALS_TABLEDATA_SUCCESS,
  RESET_CLONE_PROPERTY_STATUS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  data: {
    url: '',
    etag: '',
    detail: {},
    error: false,
    errorDetail: '',
  },
  submit: {
    error: false,
    errorDetail: '',
  },
  submitImages: {
    error: false,
    errorDetail: '',
  },
  create: {
    error: false,
    errorDetail: '',
  },
  printableData: {},
  clone: {
    index: 0,
    error: false,
    errorDetail: '',
  },
  proposalsData: {
    tableData: [],
    totalCount: 0,
    error: false,
    errorDetail: '',
  },
};

const PropertyDetails = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTYDETAILS_METASOURCE_SUCCESS: {
      return Object.assign({}, initialState, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        submitImages: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPERTYDETAILS_METASOURCE_ERROR: {
      return Object.assign({}, initialState, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.metasourceErrorDetail,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        submitImages: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPERTYDETAILS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          url: action.url,
          etag: action.etag,
          detail: action.propertyDetailsData,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPERTYDETAILS_DATA_ERROR: {
      return Object.assign({}, state, {
        data: {
          url: '',
          etag: '',
          detail: {},
          error: true,
          errorDetail: action.propertyDetailsErrorDetails,
        },
      });
    }

    case PROPERTYDETAILS_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.propertyDetailsSubmitErrorDetails,
        },
      });
    }

    case PROPERTYDETAILS_IMAGES_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        submitImages: {
          error: true,
          errorDetail: action.propertyDetailsImagesSubmitErrorDetails,
        },
      });
    }

    case PROPERTYDETAILS_SUBMIT_SUCCESS: {
      const data = Object.assign({}, state.data, {
        etag: action.etag,
      });

      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
        },
        data: data,
      });
    }

    case NEW_PROPERTY_CREATE_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: true,
          errorDetail: action.createNewPropertyErrorDetails,
        },
      });
    }

    case PROPERTYDETAILS_PRINTABLE_DATA: {
      const newState = {
        printableData: action.printableData,
      };

      if (action.propertyDetailsData) {
        const { data } = state;

        data.detail = action.propertyDetailsData;
        newState.data = data;
      }

      return Object.assign({}, state, newState);
    }

    case CLONE_PROPERTY_SUCCESS: {
      return Object.assign({}, state, {
        clone: {
          index: state.clone.index + 1,
        },
      });
    }

    case CLONE_PROPERTY_ERROR: {
      return Object.assign({}, state, {
        clone: {
          error: true,
          errorDetail: action.clonePropertyErrorDetails,
        },
      });
    }

    case RESET_CLONE_PROPERTY_STATUS: {
      return Object.assign({}, state, {
        clone: {
          index: 0,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPOSALS_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        proposalsData: {
          tableData: action.tableData,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPOSALS_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        proposalsData: {
          tableData: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    default:
      return state;
  }
};

export default PropertyDetails;
