import isEmpty from 'lodash/isEmpty';
import api from '../../shared/utils/api';
import { getOrSaveReservationsURL, deleteReservationURL } from '../../shared/apiUrls';

class AvailabilityApi {
  static getReservations = async (propertyId, providerId) => {
    if (isEmpty(propertyId)) {
      throw new Error('AvailabilityApi.getReservations: no propertyId specified');
    }
    const url = getOrSaveReservationsURL(propertyId, providerId);
    return api
      .request_retry(url, 'get', 5)
      .then(res => res.json)
      .catch(e => {
        throw new Error('AvailabilityApi.getReservations: ' + e.errorMessage);
      });
  };

  static saveReservation = async (propertyId, reservation, providerId) => {
    if (isEmpty(propertyId) || isEmpty(reservation)) {
      throw new Error('AvailabilityApi.saveReservation: no propertyId or reservation specified');
    }
    const url = getOrSaveReservationsURL(propertyId, providerId);
    return api
      .request_retry(url, 'post', 5, {
        body: JSON.stringify(reservation),
      })
      .then(res => res.json)
      .catch(e => {
        throw new Error('AvailabilityApi.saveReservations: ' + e.errorMessage);
      });
  };

  static deleteReservation = async (propertyId, reservationId, providerId) => {
    if (isEmpty(reservationId)) {
      throw new Error('AvailabilityApi.deleteReservation: no reservationId specified');
    }
    const url = deleteReservationURL(propertyId, reservationId, providerId);
    return api
      .request_retry(url, 'delete', 5)
      .then(res => res.json)
      .catch(e => {
        throw new Error('AvailabilityApi.deleteReservations: ' + e.errorMessage);
      });
  };
}

export default AvailabilityApi;
