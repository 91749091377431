import { getAxiosInstance } from '../../shared/utils/apiUtils';
import { ENDPOINT } from '../../shared/appConfig.js';

class PreloadApi {
  static preloadApp() {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(ENDPOINT + '/definition');
  }

  static preloadPublicApp() {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(ENDPOINT + '/public/definition');
  }

  static getMainMetasource() {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(ENDPOINT + '/metasource');
  }

  static getPublicMainMetasource() {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(ENDPOINT + '/metasource/public');
  }

  static getCurrentUserInformation() {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(ENDPOINT + '/user/current');
  }
}

export default PreloadApi;
