import React from 'react';
import { normalizePhoneNumber } from '../../../shared/utils/formUtils';
import { Row, Col, Form, Input, Checkbox } from 'antd';
import SelectLazy from '../../../components/SelectContact/SelectLazy';
const { Item: FormItem } = Form;

const NOOP = () => {};

const ContactInfo = ({
  deal: {
    companyId = '',
    contactId = '',
    contactDetails: {
      name = '',
      lastOTradeName: surname = '',
      email = '',
      phoneNumber = '',
      mobilePhoneNumber = '',
      jobTitle = '',
      isTravelManager = false,
    } = {},
    companyDetails: { isNotClient = false } = {},
  } = {},
  setDeal = NOOP,
}) => {
  const setContactDeal = (name, value) => {
    setDeal(prev => ({
      ...prev,
      contactDetails: {
        ...prev.contactDetails,
        [name]: value,
        contactType: 'PERSON',
      },
    }));
  };

  const getFilter = () => {
    let filter = '';

    if (companyId) {
      filter = '&sort=%2BcontactType&provOrComp=COMPANY&contactType=PERSON';
      isNotClient ? (filter += `&contactId=${companyId}`) : (filter += `&companyId=${companyId}`);
    }

    return filter;
  };

  return (
    <div className='border-component p-2' style={{ height: '100%' }} data-testid='contact-form'>
      <Row>
        <Col>
          <label className='mt-1'>
            <b className='title-component'>Contacto</b>
          </label>
          <div style={{ marginBottom: 10 }} />
        </Col>
      </Row>

      {companyId && (
        <Row>
          <Col>
            <FormItem label='Contacto del Cliente'>
              <SelectLazy
                name='contactId'
                type='contact'
                value={contactId}
                filter={getFilter()}
                onChange={id => {
                  if (id) {
                    setDeal(prev => ({
                      ...prev,
                      contactId: id,
                    }));
                  } else {
                    setDeal(prev => ({
                      ...prev,
                      isContact: false,
                      contactId: null,
                      contactDetails: {},
                    }));
                  }
                }}
              />
            </FormItem>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <FormItem label='Nombre*'>
            <Input
              allowClear
              placeholder='Geralt'
              name='name'
              required
              value={name}
              onChange={e => setContactDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormItem label='Apellidos'>
            <Input
              allowClear
              placeholder='De Rivia'
              name='lastOTradeName'
              value={surname}
              onChange={e => setContactDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormItem label='Cargo'>
            <Input
              allowClear
              placeholder='Recursos humanos'
              name='jobTitle'
              value={jobTitle}
              onChange={e => setContactDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormItem label='Email*'>
            <Input
              allowClear
              placeholder='geraltderivia@witcher.com'
              name='email'
              type='email'
              required
              value={email}
              onChange={e => setContactDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <FormItem label='Teléfono fijo'>
            <Input
              allowClear
              placeholder='961234567'
              name='phoneNumber'
              type='tel'
              value={phoneNumber}
              onChange={e => setContactDeal(e.target.name, normalizePhoneNumber(e.target.value))}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <FormItem label='Teléfono móvil'>
            <Input
              allowClear
              placeholder='666565956'
              name='mobilePhoneNumber'
              type='tel'
              value={mobilePhoneNumber}
              onChange={e => setContactDeal(e.target.name, normalizePhoneNumber(e.target.value))}
            />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormItem>
            {/* <Checkbox
              name='isTravelManager'
              checked={roles.includes('TRAVEL_MANAGER')}
              onChange={e => {
                e.target.checked
                  ? roles.push('TRAVEL_MANAGER')
                  : roles.splice(roles.indexOf('TRAVEL_MANAGER'), 1);

                setDeal(prev => ({
                  ...prev,
                  contactDetails: {
                    ...prev.contactDetails,
                    relatedCompany: {
                      ...get(prev, 'contactDetails.relatedCompany', {}),
                      roles,
                    },
                    contactType: 'PERSON',
                  },
                }));
              }}
            >
              ¿Es el encargado en gestionar los desplazamientos?
            </Checkbox> */}
            <Checkbox
              name='isTravelManager'
              checked={isTravelManager}
              onChange={e => {
                setDeal(prev => ({
                  ...prev,
                  contactDetails: {
                    ...prev.contactDetails,
                    isTravelManager: e.target.checked,
                    contactType: 'PERSON',
                  },
                }));
              }}
            >
              ¿Es el encargado en gestionar los desplazamientos?
            </Checkbox>
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};
export default ContactInfo;
