import React from 'react';
import FilterButtonsDeal from '../../components/FilterButtonsDeal/FilterButtonsDeal';
import TableGlobalActivity from '../../components/TableGlobalActivity/TableGlobalActivity';
import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

const Dashboard = () => (
  <div className='animated fadeIn marginTop2 dashboard'>
    <Row>
      <Col span={24}>
        <Title level={2}>
          <Title level={2}>
            {/* <img src='img/xmas/xmas-tree.png' alt='xmas-tree' height={50} /> */}
            Mis oportunidades
            {/* <img src='img/xmas/xmas-tree.png' alt='xmas-tree' height={50} /> */}
          </Title>
        </Title>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <FilterButtonsDeal />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Title level={2} className='global-dashboard'>
          {/* <img src='img/xmas/reyes-magos.png' alt='snowman' height={50} /> */}
          Actividad global
          {/* <img src='img/xmas/present.png' alt='snowman' height={50} /> */}
        </Title>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <TableGlobalActivity />
      </Col>
    </Row>
    <style jsx>
      {`
        .dashboard {
          margin: 10px;
        }
        .global-dashboard {
          margin-top: 50px;
        }
      `}
    </style>
  </div>
);

export default Dashboard;
