import React, { Component } from 'react';
import { Alert, Col, Icon, Row, Upload } from 'antd';
import { connect } from 'react-redux';
import { uploadImportFile } from '../../modules/Properties/PropertiesActions';

// PARAMETROS A CONFIGURAR
// callbackFuncionOnCreate -> función a llamar cuando se ha finalizado la subida del fichero

const { Dragger } = Upload;
const STATUS_UPLOADED = 'uploaded';

class FormImportProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importStatus: {
        error: false,
      },
      fileName: '',
      draggerProps: {
        multiple: false,
        showUploadList: false,
        accept: '.csv, .txt',
        onChange: info => {
          console.error(info);
        },
        customRequest: option => {
          this.props.actions.uploadImportFile(option.file);
        },
        beforeUpload: file => {
          this.setState(
            {
              fileName: file.name,
              importStatus: {
                error: false,
              },
            },
            () => {
              setTimeout(() => {
                if (
                  window.confirm('El fichero se subirá y comenzará la importación, ¿estás seguro?')
                ) {
                  this.props.actions.uploadImportFile(file);

                  return true;
                }

                this.setState({ fileName: '' });

                return false;
              }, 500);
            }
          );

          return false;
        },
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const messagePreffix = 'Se ha producido un error al subir el fichero';

    if (nextProps.importStatus.error) {
      this.setState({
        importStatus: {
          error: true,
          errorDetail:
            nextProps.importStatus.errorDetail && nextProps.importStatus.errorDetail !== ''
              ? `${messagePreffix}: ${nextProps.importStatus.errorDetail}`
              : messagePreffix,
        },
      });
    } else if (nextProps.importStatus.status === STATUS_UPLOADED) {
      this.props.callbackFuncionOnCreate();
    }
  }

  render() {
    return (
      <form id='formImportProperties'>
        {this.state.importStatus.error && (
          <Alert
            message={this.state.importStatus.errorDetail}
            type='error'
            showIcon
            closable
            onClose={this.handleCloseAlert}
          />
        )}
        <br />
        <Row>
          <Col span={24} className='alignCenter ant-upload-hint'>
            <a
              href='https://docs.google.com/spreadsheets/d/1KBXe44qRRCQMrWVkMAmSCCM3zMHxdPkLmC0i56nf1CQ/edit#gid=0'
              target='_blank'
              rel='noopener noreferrer'
            >
              Descarga aquí la plantilla para la importación
            </a>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24} style={{ marginBottom: 25, height: 180 }}>
            <Dragger {...this.state.draggerProps}>
              <p className='ant-upload-drag-icon noMargin'>
                <Icon type='upload' style={{ color: '#eeaf30' }} />
              </p>
              <p className='ant-upload-text'>
                {!this.state.importStatus.error && this.state.fileName !== ''
                  ? `Procesando: ${this.state.fileName}`
                  : 'Haz click o arrastra el fichero hasta aquí'}
              </p>
            </Dragger>
          </Col>
        </Row>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      uploadImportFile: file => dispatch(uploadImportFile(file)),
    },
  };
}

function mapStateToProps(state) {
  return {
    importStatus: state.Properties.importStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormImportProperties);
