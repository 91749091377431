import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getCompanyList, openNotificationWithIcon } from '../../shared/utils/appUtils';
import { showOrLoadCompanyDetailsView } from '../../modules/CompanyDetails/CompanyDetailsActions';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import api from '../../shared/utils/api';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 label -> label for the select (default: Cliente)
 handleChange -> function to be called when active state change
 isRequired -> boolean to indicate if component is required
 value -> select's value
 name -> name of the component
 id -> id of the component
 ******************************************************* */

class SelectCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      companiesList: null,
      options: [],
      isLoading: false,
      companyData: null,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.state.value && this.state.value !== '') {
      this.props.actions.getCompanyData(this.state.value);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState(
        {
          value: nextProps.value,
        },
        () => this.props.actions.getCompanyData(this.state.value)
      );
    }

    if (
      JSON.stringify(nextProps.companyData) !== JSON.stringify(this.state.companyData) &&
      this.state.value !== ''
    ) {
      if (
        nextProps.companyData.detail &&
        nextProps.companyData.detail.id &&
        nextProps.companyData.detail.baseContact
      ) {
        let labelValue = '';

        if (nextProps.companyData.detail.companyType === 'PERSON') {
          labelValue = '(Persona física) - ';
        } else if (nextProps.companyData.detail.companyType === 'COMPANY') {
          labelValue = '(Persona jurídica) - ';
        }

        labelValue += `${nextProps.companyData.detail.baseContact.name} ${
          nextProps.companyData.detail.baseContact.lastOTradeName
            ? nextProps.companyData.detail.baseContact.lastOTradeName
            : ''
        }`;
        const option = {
          label: labelValue,
          value: nextProps.value,
        };

        this.setState(
          {
            options: [option],
            companyData: nextProps.companyData,
          },
          () => {
            if (this.props.onFirstLoad) {
              this.props.onFirstLoad(this.state.companyData);
            }
          }
        );
      } else if (nextProps.companyData.error) {
        openNotificationWithIcon(
          'error',
          'Ha ocurrido un error al cargar la información del cliente',
          nextProps.companyData.errorDetail
        );
      }
    }
  }

  handleSelectChange(selectedOption) {
    this.setState({
      value: selectedOption ? selectedOption.value : '',
      options: selectedOption ? [selectedOption] : [],
    });
    let companySelected = null;

    if (selectedOption && !isEmpty(get(this.state, 'companiesList'))) {
      companySelected = this.state.companiesList.find(c => c.id === selectedOption.value);
    }

    this.props.handleChange(this.props.name, selectedOption, companySelected);
  }

  handleInputChange = async value => {
    if (value.length >= 3) {
      try {
        const params = {
          filters: { globalSearch: value },
        };

        const response = await api.getTableDataV2('companies', params);

        const companies = get(response, 'json.searchItems');
        const companiesListed = getCompanyList(companies);

        this.setState({
          value: null,
          options: companiesListed,
          companiesList: companies,
        });
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'Ha ocurrido un error al cargar la lista de Clientes',
          e.message
        );
        console.error(e);
      }
    } else {
      if (!this.state.value) {
        this.setState({ options: [] });
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  render() {
    return (
      <div>
        <label htmlFor={this.props.id} className={this.props.isRequired ? 'required' : ''}>
          {this.props.label ? this.props.label : 'Cliente'}
        </label>
        <Select
          filterOptions={options => options}
          name={this.props.name}
          id={this.props.id}
          className={`form-control ${this.props.isRequired ? ' validate validate-required' : ''} ${
            this.props.extraClassName
          }`}
          value={this.state.value ? this.state.value : ''}
          options={this.state.options}
          onChange={value => {
            this.handleSelectChange(value);
          }}
          onInputChange={value => {
            this.handleInputChange(value);
          }}
          isLoading={this.state.isLoading}
          noResultsText='No hay resultados'
          placeholder='Escribe para empezar a filtrar...'
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyData: state.CompanyDetails.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCompanyData: id => dispatch(showOrLoadCompanyDetailsView(id, false, true)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompany);
