/*
  Special attention:
  getFieldDecorator can not be used to decorate stateless component.
  https://ant.design/components/form/
*/
/* eslint-disable react/prefer-stateless-function */

import { Form } from 'antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HomyAddress from '../HomyComponents/HomyAddress';
import InputAntdValidator from './InputAntdValidator';

class FormAddress extends Component {
  static propTypes = {
    fieldDecorator: PropTypes.string.isRequired,
    form: PropTypes.shape({}).isRequired,
    id: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    initialValue: PropTypes.shape({}),
  };

  static defaultProps = {
    isDisabled: false,
    isRequired: false,
    type: 'object',
    initialValue: {},
  };

  checkAddress = (rule, value, callback) => {
    if (this.props.isRequired && !value.administrativeAreaLevel1) {
      callback('Por favor, se más específico con la dirección');
    }

    callback();
  };

  handleSelectedAddress = address => {
    this.props.form.setFieldsValue({
      [this.props.fieldDecorator]: address,
    });
  };

  render() {
    const {
      fieldDecorator,
      form,
      id,
      isDisabled,
      isRequired,
      label,
      name,
      type,
      initialValue,
    } = this.props;

    return (
      <InputAntdValidator
        fieldDecorator={fieldDecorator}
        form={form}
        isRequired={isRequired}
        label={label}
        type={type}
        validator={this.checkAddress}
        initialValue={initialValue}
      >
        <HomyAddress
          id={id}
          isDisabled={isDisabled}
          label=''
          name={name}
          onSelectedAddress={this.handleSelectedAddress}
          value={initialValue}
        />
      </InputAntdValidator>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    return {
      [props.fieldDecorator]: Form.createFormField({
        ...props,
        value: props.value,
      }),
    };
  },
})(FormAddress);
