import React, { Component } from 'react';
import { Col, Row } from 'antd';

/**
 * buttonLabel -> text to show in te FilterButton
 * count -> number to show in the button
 * filter -> filter object to modify by the FilterButton
 * callback -> function to execute when the FilterButton is pressed
 *             This function will receive the previous filter ready to apply
 * width -> width of te component in %
 * className
 */

class FilterButton extends Component {
  constructor(props) {
    super(props);
    this.applyFilter = this.applyFilter.bind(this);
  }

  applyFilter() {
    this.props.callback(this.props.filter);
  }

  render() {
    return (
      <div
        className={`infobutton-deal filter-button ${this.props.className}`}
        onClick={this.applyFilter}
      >
        <Row>
          <Col
            // xxl={20}
            // xl={18}
            // lg={16}
            // md={12}
            // sm={12}
            // xs={24}
            span={24}
            className='filter-button-text'
          >
            {this.props.buttonLabel}
          </Col>
        </Row>
        <Row>
          <Col
            // xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}
            offset={22}
            className='filter-button-counter'
          >
            {this.props.count}
          </Col>
        </Row>
        <style jsx>
          {`
            .filter-button {
              cursor: pointer;
              min-height: 60px;
              height: 80px;
              max-height: 85px;
              font-weight: 600;
              font-size: 0.9em !important;
            }

            .filter-button-text {
              font-size: 1.1em;
            }

            .filter-button-counter {
              font-size: 1.2em;
            }

            .infobutton-deal {
              padding: 0.9em 0.9em 0.9em 0.9em;
              color: white;
              border-radius: 4px;
            }
          `}
        </style>
      </div>
    );
  }
}

export default FilterButton;
