import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { browserHistory } from 'react-router';
import FilterButton from '../FilterButton/FilterButton';
import { getOptionsForSelect } from '../../shared/utils/appUtils';
import {
  filterTableDataDeals,
  getRentalRequestStageCountByUser,
} from '../../modules/Deals/DealsActions';
import DealsApi from '../../modules/Deals/DealsApi';
import { ENDPOINT } from '../../shared/appConfig';
import isEmpty from 'lodash/isEmpty';

export class FilterButtonsDeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalRequestStageCounter: {},
      filter: this.props.filter,
      dealsMetasource: this.props.dealsMetasource,
      metasourceStrings: this.props.metasourceStrings,
      options: {
        rentalRequestStage: getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage'),
      },
    };
    this.buttonClickedCallback = this.buttonClickedCallback.bind(this);
    this.fecthData = this.fecthData.bind(this);
  }

  componentDidMount = () => {
    if (this.props.currentUsername) {
      this.fecthData();
    }
  };

  componentDidUpdate(oldProps) {
    if (this.props.currentUsername !== oldProps.currentUsername) {
      this.fecthData();
    }
  }

  fecthData() {
    DealsApi._doGet(`${ENDPOINT}/deal/stage-counter-by-user/${this.props.currentUsername}`)
      .then(res => {
        this.setState({
          rentalRequestStageCounter: res.data.rentalRequestStageBackList,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  buttonClickedCallback(filter) {
    if (browserHistory.getCurrentLocation().pathname !== '/deals') {
      browserHistory.push('/deals');
    }

    this.props.actions.filterTableDataDeals(filter);
  }

  render() {
    const { options, rentalRequestStageCounter } = this.state;

    return (
      <Row gutter={8}>
        {!isEmpty(options.rentalRequestStage) &&
          options.rentalRequestStage.map((option, index) => (
            <Col span={3} key={index}>
              <FilterButton
                buttonLabel={option.label.replace(new RegExp(/\d\d - /g), '')}
                count={
                  !isEmpty(rentalRequestStageCounter) ? rentalRequestStageCounter[index].count : 0
                }
                filter={{
                  rentalRequestStages: [option.value],
                  hsagent: this.props.currentUsername,
                }}
                callback={this.buttonClickedCallback}
                className='dealBackgroundColor'
              />
            </Col>
          ))}
      </Row>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      filterTableDataDeals: filterCriteria => dispatch(filterTableDataDeals(filterCriteria)),
      getRentalRequestStageCountByUser: username =>
        dispatch(getRentalRequestStageCountByUser(username)),
    },
  };
}

function mapStateToProps(state) {
  return {
    filter: state.Deals.filter,
    currentUsername: state.Preload.currentUserInformation.username,
    metasource: state.Deals.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    optionLabels: state.Preload.optionLabels,
    rentalRequestStageCounter: state.Deals.rentalRequestStageCounter,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterButtonsDeal);
