import { getAxiosInstance } from '../../shared/utils/apiUtils';

class PmasApi {
  static getMetasource(url) {
    return this.processGet(url);
  }

  static getPmasAveragesTableData(url) {
    return this.processGet(url);
  }

  static getPmasTableData(url) {
    return this.processGet(url);
  }

  static getPmaCities(url) {
    return this.processGet(url);
  }

  static getPmaZipCodes(url) {
    return this.processGet(url);
  }

  static getPmaApproximate(url, params = null) {
    return this.processGet(url, params);
  }

  static processGet(url, params = null) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url, { params: params });
  }
}

export default PmasApi;
