import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import {
  showOrLoadContactDetailsView,
  resetContactState,
} from '../../modules/ContactDetails/ContactDetailsActions';
import {
  showOrLoadProviderDetailsView,
  resetState as resetProviderState,
} from '../../modules/ProviderDetails/ProviderDetailsActions';
import {
  showOrLoadCompanyDetailsView,
  resetState as resetCompanyState,
} from '../../modules/CompanyDetails/CompanyDetailsActions';
import InfoButtonProviderDetails from '../../components/InfoButtonProviderDetails';
import InfoButtonCompanyDetails from '../../components/InfoButtonCompanyDetails';
import InfoButtonContactDetails from '../../components/InfoButtonContactDetails';
import TimelinePanel from '../../components/TimelinePanel';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      shouldLoadCompanyAndProvider: true,
    };
  }

  componentDidMount() {
    this.props.actions.showOrLoadContactDetailsView(this.props.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contactDetails, providerDetails, companyDetails } = nextProps;

    if (!isEmpty(contactDetails)) {
      const companyId = get(contactDetails, 'relatedCompany.idOfContact');
      const providerId = get(contactDetails, 'relatedProvider.idOfContact');

      if (this.state.shouldLoadCompanyAndProvider) {
        if (companyId) {
          this.props.actions.showOrLoadCompanyDetailsView(companyId);
        }

        if (providerId) {
          this.props.actions.showOrLoadProviderDetailsView(providerId);
        }

        this.setState({
          shouldLoadCompanyAndProvider: false,
        });
      }

      if (
        this.state.isLoading &&
        (isNil(providerId) || !isEmpty(providerDetails)) &&
        (isNil(companyId) || !isEmpty(companyDetails))
      ) {
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.actions.resetContactState();
    this.props.actions.resetCompanyState();
    this.props.actions.resetProviderState();
  }

  render() {
    const { contactDetails, providerDetails, companyDetails } = this.props;
    const { isLoading } = this.state;

    const offset = !isEmpty(companyDetails) && !isEmpty(providerDetails) ? '10' : '5';

    return (
      <div className='animated fadeIn col-sm-12'>
        <div>
          {this.props.metasource.error && (
            <Alert
              message='Error'
              description={this.props.metasource.errorDetail}
              type='error'
              showIcon
              closable
            />
          )}
        </div>
        {isLoading ? (
          <div key='1' className='loader loading-background loading-background-full'>
            &nbsp;
          </div>
        ) : (
          <>
            <div className='marginTop2 col-sm-12'>
              <div className='row'>
                <InfoButtonContactDetails contact={{ data: contactDetails, etag: '1' }} />
                {!isEmpty(providerDetails) && (
                  <InfoButtonProviderDetails withSelfLink leftOffset='5' compressed />
                )}
                {!isEmpty(companyDetails) && (
                  <InfoButtonCompanyDetails withSelfLink leftOffset={offset} compressed />
                )}
              </div>
            </div>
            <TimelinePanel contextType='CONTACT' metasource={this.props.metasource} />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.ContactDetails.metasource,
    contactDetails: state.ContactDetails.data.detail,
    providerDetails: state.ProviderDetails.data.detail,
    companyDetails: state.CompanyDetails.data.detail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      showOrLoadContactDetailsView: id => dispatch(showOrLoadContactDetailsView(id, false)),
      showOrLoadProviderDetailsView: id => dispatch(showOrLoadProviderDetailsView(id, false)),
      showOrLoadCompanyDetailsView: id => dispatch(showOrLoadCompanyDetailsView(id, false)),
      resetContactState: () => dispatch(resetContactState()),
      resetCompanyState: () => dispatch(resetCompanyState()),
      resetProviderState: () => dispatch(resetProviderState()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactDetails));
