import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Alert, Button, Col, Modal, Row } from 'antd';
import Select from 'react-select';
import SelectContact from '../SelectContact';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import {
  CONTACT_TYPE,
  getOptionsForSelect,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import TableDataDealContacts from '../TableDataDealContacts';

import { saveDealDetailsData } from '../../modules/DealDetails/DealDetailsActions';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// PARAMETROS A CONFIGURAR
// callbackFunctionOnUpdateOrSave -> Función a llamar cuando se finaliza correctamente un guardado de información
// mainView -> main view in wich this component is included (optional)

const SIGNER_IS_COMPANY_MESSAGE =
  'El contacto seleccionado no puede tener el rol de "Firma contrato" porque es un contacto de tipo "Persona jurídica"';

class FormDealContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealDetails: props.dealDetailsData ? props.dealDetailsData.detail : {},
      contact: {},
      newContact: {},
      contactsVersion: props.companyContactsVersion,
      url: {},
      submitting: false,
      submitSuccess: false,
      invalidInput: false,
      contactList: null,
      modalContact: false,
      editingContact: false,
      options: {
        rentalRequestStage: getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage'),
        contactType: getOptionsForSelect(this.props.optionLabels, 'ContactType'),
        contactRole: getOptionsForSelect(this.props.optionLabels, 'DealContactRole'),
      },
      modal: false,
      alert: {
        show: false,
      },
      contactLocation: props.contactLocation,
      deleteContactStatus: {
        contact: {},
        error: false,
        errorDetail: '',
      },
      editingContactStatus: {
        contact: {},
        error: false,
        errorDetail: '',
      },
      contactSubmit: {
        error: false,
        errorDetail: '',
      },
      editContact: {},
      contactToUnlink: {},
      companyBaseContact: props.companyBaseContact,
      contactsOfCompanyWithoutBase: props.dealDetailsData.detail.contactsOfCompany
        ? props.dealDetailsData.detail.contactsOfCompany
        : // ? getContactsOfCompany(
          //     props.dealDetailsData.detail.contactsOfCompany,
          //     props.companyBaseContact
          //   )
          {},
      signerIsCompanyError: false,
    };

    this.selectContactPrefilter =
      this.props.mainView === 'DealDetails' ? '&provOrComp=COMPANY' : '';
    this.contactSelected = this.contactSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleContact = this.toggleContact.bind(this);
    this.toggleeditingContact = this.toggleEditingContact.bind(this);
    this.handleUnlinkContact = this.handleUnlinkContact.bind(this);
    this.handleEditUnlinkContact = this.handleEditUnlinkContact.bind(this);
    this.submitAddContact = this.submitAddContact.bind(this);
    this.handleRequiredFields = this.handleRequiredFields.bind(this);
    this.getStageIndex = this.getStageIndex.bind(this);
    this.checkIfSignerIsCompany = this.checkIfSignerIsCompany.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting && !nextProps.contact.error) {
      this.setState({ submitSuccess: true });
      this.props.callbackFunctionOnUpdateOrSave();
    } else {
      this.setState({ submitSuccess: false });
    }

    this.setState({ submitting: false });
    if (this.state.contactLocation !== nextProps.contactLocation) {
      this.setState({
        contactLocation: nextProps.contactLocation,
      });
      this.toggle();
    }

    if (this.state.submitting) {
      this.setState({ submitting: false });
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  getStageIndex() {
    const stages = this.state.options.rentalRequestStage;
    const { rentalRequestStage } = this.state.dealDetails;
    let stageIndex = 0;

    stages.forEach((stage, index) => {
      if (stage.value === rentalRequestStage) {
        stageIndex = index;
      }
    });

    return stageIndex;
  }

  handleChange(e) {
    const { contact } = this.state;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'phoneNumber' || e.target.name === 'mobilePhoneNumber') {
      value = normalizePhoneNumber(value);
    }

    contact[e.target.name] = value;
    this.setState({ contact });
  }

  handlePlaceChanged(place) {
    const { contact } = this.state;

    contact.address = place;
    this.setState({ contact });
  }

  handleSelectChange(name, selectedOption) {
    const localContact = this.state.editingContact ? this.state.editContact : this.state.newContact;
    const key = this.state.editingContact ? 'editContact' : 'newContact';

    name = name.length > 0 ? name : 'contactInfo';
    localContact[name] = selectedOption || null;
    this.checkIfSignerIsCompany(localContact.contactInfo, localContact.contactRole);
    this.setState({ [key]: localContact });
  }

  checkIfSignerIsCompany(contact, roles) {
    if (
      get(contact, 'contact.contactType', '') === CONTACT_TYPE.COMPANY &&
      roles &&
      !isEmpty(filter(roles, ['value', 'CONTRACT_SIGNING']))
    ) {
      this.setState({ signerIsCompanyError: true });

      return true;
    }

    this.setState({ signerIsCompanyError: false });

    return false;
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !validForm });

    return validForm;
  }

  handleSubmit() {
    if (this.isValidForm() && this.handleRequiredFields()) {
      this.setState({ submitting: true }, () =>
        this.props.actions.saveDealDetailsData(this.state.dealDetails)
      );
    }
  }

  handleRequiredFields() {
    let isFilled = true;

    if (this.getStageIndex() >= 4 && !this.state.dealDetails.customerRate) {
      openNotificationWithIcon(
        'error',
        'Sobre mi código fuente',
        '¡No puedes asociar un contacto si el campo "Tarifa Cliente" en esta etapa de la Oportunidad no está informado!'
      );

      isFilled = false;
    }

    if (!this.state.dealDetails.hsagent || this.state.dealDetails.hsagent.username.length <= 0) {
      openNotificationWithIcon(
        'error',
        'Has estado cerca, peeeero...',
        '¡No puedes asociar un contacto si el campo "Gestor Asignado" en esta etapa de la Oportunidad no está informado!'
      );
      isFilled = false;
    }

    return isFilled;
  }

  contactSelected = contact => {
    browserHistory.push(`/contactDetails/${contact.id}`);
  };

  submitAddContact() {
    const { contactsOfCompany } = this.state.dealDetails;
    const contactBase = this.state.companyBaseContact;
    let newContact = {};

    if (this.state.editingContact) {
      newContact = this.state.editContact;
      contactsOfCompany.forEach((value, index) => {
        if (value.contact.id === newContact.contactInfo.contact.id) {
          contactsOfCompany[index] = {
            roles: newContact.contactRole
              ? newContact.contactRole.map(rol => rol.value)
              : 'UNKNOWN',
            contact: newContact.contactInfo.contact,
          };
        }
      });
    } else {
      if (this.state.contactsOfCompanyWithoutBase) {
        for (let i = 0; i < this.state.contactsOfCompanyWithoutBase.length; i++) {
          const { contact } = this.state.contactsOfCompanyWithoutBase[i];

          if (contact && this.state.newContact.contactInfo.contact.id === contact.id) {
            openNotificationWithIcon(
              'error',
              'No me engañes :P',
              'El contacto que has seleccionado ya está en la lista de contactos de la propuesta'
            );

            return false;
          }
        }
      }

      newContact = this.state.newContact;
      newContact.contactInfo.contact.relatedCompany.idOfContact = contactBase.id;
      contactsOfCompany.push({
        roles: newContact.contactRole ? newContact.contactRole.map(rol => rol.value) : 'UNKNOWN',
        contact: newContact.contactInfo.contact,
      });
    }

    this.handleSubmit();
    this.setState({ signerIsCompanyError: false });
  }

  toggleContact() {
    this.setState(
      {
        modalContact: !this.state.modalContact,
        signerIsCompanyError: false,
        editContact: {},
        newContact: {},
      },
      () => {
        if (this.state.editingContact) {
          this.setState({
            editingContact: !this.state.editingContact,
          });
        }
      }
    );
  }

  toggleEditingContact(editContact) {
    this.setState({
      modalContact: !this.state.modalContact,
      editingContact: !this.state.editingContact,
      editContact,
    });
  }

  handleUnlinkContact(contactId) {
    if (this.state.contactsOfCompanyWithoutBase) {
      if (
        this.state.contactsOfCompanyWithoutBase.length > 1 &&
        window.confirm('¿Estás seguro de que quieres desvincular este contacto?')
      ) {
        const { contactsOfCompany } = this.state.dealDetails;
        let contactListIsModified = false;

        for (let i = 0; i < contactsOfCompany.length; i++) {
          const contact = contactsOfCompany[i];

          if (contact && contactId === contact.contact.id) {
            contactsOfCompany.splice(i, 1);
            contactListIsModified = true;
          }
        }

        if (contactListIsModified) {
          this.handleSubmit();
        }
      } else if (this.state.contactsOfCompanyWithoutBase.length <= 1) {
        openNotificationWithIcon(
          'error',
          'Ojo cuidao',
          'La Oportunidad ha de tener como mínimo un Contacto asociado'
        );

        return false;
      }
    }
  }

  handleEditUnlinkContact(contactId) {
    let companyContact = {};

    if (!this.state.editingContact) {
      companyContact = this.state.contactsOfCompanyWithoutBase.find(
        e => e.contact.id === contactId
      );
    }

    const editContact = {};

    editContact.contactInfo = {};
    editContact.contactRole = [];
    editContact.contactInfo.contact = companyContact.contact;

    if (companyContact.roles && companyContact.roles.length > 0) {
      for (let i = 0; i < companyContact.roles.length; i++) {
        editContact.contactRole[i] = this.state.options.contactRole.find(
          e => e.value === companyContact.roles[i]
        );
      }
    }

    this.toggleEditingContact(editContact);
  }

  render() {
    const localContact = this.state.editingContact ? this.state.editContact : this.state.newContact;
    const contactHasNoRoles =
      !localContact.contactInfo ||
      !(localContact.contactRole && localContact.contactRole.length > 0);

    const contactModalDisabled = this.state.signerIsCompanyError || contactHasNoRoles;

    return (
      <div>
        <div className='row'>
          <div className='col-sm-2'>
            <Button
              type='button'
              className='btn btn-primary btn-block alignLeft buttonBreakLine'
              onClick={this.toggleContact}
            >
              Añadir contacto
            </Button>
            <Modal
              visible={this.state.modalContact}
              style={{ top: '13%' }}
              title={
                this.state.editingContact
                  ? 'Modificar función de contacto'
                  : 'Asociar nuevos contactos'
              }
              onOk={this.submitAddContact}
              onCancel={this.toggleContact}
              okButtonProps={{
                disabled: contactModalDisabled,
              }}
              confirmLoading={this.state.submitting}
            >
              {this.state.signerIsCompanyError && (
                <>
                  <Row>
                    <Col span={24}>
                      <Alert message={SIGNER_IS_COMPANY_MESSAGE} type='error' showIcon />
                    </Col>
                  </Row>
                  <br />
                </>
              )}
              <Row>
                <Col span={24}>
                  {this.state.editingContact && (
                    <SelectContact
                      name=''
                      id=''
                      label='Contacto'
                      value={get(this.state, 'editContact.contactInfo.contact.id', '')}
                      disabled
                    />
                  )}
                  {!this.state.editingContact && (
                    <SelectContact
                      name=''
                      companyId={this.state.dealDetails.companyId}
                      id=''
                      label='Contacto'
                      isRequired={false}
                      handleChange={this.handleSelectChange}
                      prefilter={this.selectContactPrefilter}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <label htmlFor='contactRole' className='required'>
                    Función
                  </label>
                  {this.state.editingContact && (
                    // <HomySelect
                    //   name="contactRoleEdit"
                    //   className="validate validate-required"
                    //   value={
                    //     this.state.editContact
                    //       ? this.state.editContact.contactRole
                    //       : ''
                    //   }
                    //   placeholder="Selecciona un rol para el contacto"
                    //   options={this.state.options.contactRole}
                    //   onChange={value => {
                    //     this.handleSelectChange('contactRole', value);
                    //   }}
                    //   mode="multiple"
                    //   showArrow
                    //   showSearch
                    // />
                    <div>
                      <Select
                        name='contactRoleEdit'
                        className='form-control validate validate-required'
                        placeholder='Selecciona una o varias funciones'
                        value={(this.state.editContact && this.state.editContact.contactRole) || ''}
                        options={this.state.options.contactRole}
                        onChange={value => {
                          this.handleSelectChange('contactRole', value);
                        }}
                        multi
                      />
                    </div>
                  )}
                  {!this.state.editingContact && (
                    // <HomySelect
                    //   name="contactRole"
                    //   className="validate validate-required"
                    //   value={
                    //     this.state.newContact.contactRole
                    //       ? this.state.newContact.contactRole
                    //       : ''
                    //   }
                    //   placeholder="Selecciona un rol para el contacto"
                    //   options={this.state.options.contactRole}
                    //   onChange={value => {
                    //     this.handleSelectChange('contactRole', value);
                    //   }}
                    //   mode="multiple"
                    //   showArrow
                    //   showSearch
                    // />
                    <Select
                      name='contactRole'
                      className='form-control validate validate-required'
                      value={
                        this.state.newContact.contactRole ? this.state.newContact.contactRole : ''
                      }
                      options={this.state.options.contactRole}
                      onChange={value => {
                        this.handleSelectChange('contactRole', value);
                      }}
                      multi
                    />
                  )}
                </Col>
              </Row>
            </Modal>
          </div>
          <div className='col-sm-10'>
            {this.props.companyContacts && (
              <TableDataDealContacts
                callbackFunctionOnContactSelect={this.contactSelected}
                callbackFunctionOnContactUnlink={this.handleUnlinkContact}
                callbackEditFunctionOnContactUnlink={this.handleEditUnlinkContact}
              />
            )}
          </div>
        </div>
        <div id='formContactDetail'>&nbsp;</div>
        {this.props.contact && this.props.contact.error && (
          <Alert
            message='Error'
            description={this.props.contact.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
        {this.state.invalidInput && (
          <Alert message='Error en los datos introducidos' type='error' showIcon closable />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveDealDetailsData: data => dispatch(saveDealDetailsData(data)),
    },
  };
}

function mapStateToProps(state) {
  return {
    dealDetailsData: state.DealDetails.data,
    companyContacts: state.CompanyDetails.contacts.detail,
    companyBaseContact: state.CompanyDetails.data.detail,
    optionLabels: state.Preload.optionLabels,
    contact: state.CompanyDetails.contact,
    companyContactsVersion: state.CompanyDetails.contactsVersion,
    contactLocation: state.CompanyDetails.contactLocation.location,
    deleteContactStatus: state.ContactDetails.delete,
    editingContactStatus: state.ContactDetails.delete,
    contactSubmit: state.ContactDetails.submit,
    dealDetailsSubmit: state.DealDetails.submit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDealContacts);
