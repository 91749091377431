import { browserHistory } from 'react-router';
import {
  CLONE_PROPERTY_ERROR,
  CLONE_PROPERTY_SUCCESS,
  NEW_PROPERTY_CREATE_ERROR,
  PROPERTYDETAILS_DATA_ERROR,
  PROPERTYDETAILS_DATA_SUCCESS,
  PROPERTYDETAILS_IMAGES_SUBMIT_ERROR,
  PROPERTYDETAILS_METASOURCE_ERROR,
  PROPERTYDETAILS_METASOURCE_SUCCESS,
  PROPERTYDETAILS_PRINTABLE_DATA,
  PROPERTYDETAILS_SUBMIT_ERROR,
  PROPERTYDETAILS_SUBMIT_SUCCESS,
  PROPOSALS_TABLEDATA_ERROR,
  PROPOSALS_TABLEDATA_SUCCESS,
  RESET_CLONE_PROPERTY_STATUS,
} from '../../shared/actionTypes';
import PropertyDetailsApi from './PropertyDetailsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink, resolveParams } from '../../shared/utils/appUtils';
import PropertiesApi from '../Properties/PropertiesApi';

export const metasourceSuccess = metasourceLinks => ({
  type: PROPERTYDETAILS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: PROPERTYDETAILS_METASOURCE_ERROR,
  errorDetails: errorMessage,
});

export const propertyDetailsDataSuccess = (data, url, etag) => ({
  type: PROPERTYDETAILS_DATA_SUCCESS,
  propertyDetailsData: data,
  url,
  etag,
});

export const propertyDetailsDataError = errorMessage => ({
  type: PROPERTYDETAILS_DATA_ERROR,
  propertyDetailsErrorDetails: errorMessage,
});

export const propertyDetailsSubmitSuccess = etag => ({
  type: PROPERTYDETAILS_SUBMIT_SUCCESS,
  etag,
});

export const propertyDetailsSubmitError = errorMessage => ({
  type: PROPERTYDETAILS_SUBMIT_ERROR,
  propertyDetailsSubmitErrorDetails: errorMessage,
});

export const propertyDetailsImagesSubmitError = errorMessage => ({
  type: PROPERTYDETAILS_IMAGES_SUBMIT_ERROR,
  propertyDetailsImagesSubmitErrorDetails: errorMessage,
});

export const createNewPropertyError = errorMessage => ({
  type: NEW_PROPERTY_CREATE_ERROR,
  createNewPropertyErrorDetails: errorMessage,
});

export const setPrintablePropertyDetailsData = (printableData, propertyDetails) => ({
  type: PROPERTYDETAILS_PRINTABLE_DATA,
  printableData,
  propertyDetailsData: propertyDetails,
});

export const clonePropertySuccess = () => ({
  type: CLONE_PROPERTY_SUCCESS,
});

export const clonePropertyError = errorMessage => ({
  type: CLONE_PROPERTY_ERROR,
  clonePropertyErrorDetails: errorMessage,
});

export const resetClonePropertyStatus = () => ({
  type: RESET_CLONE_PROPERTY_STATUS,
});

export const proposalsTableDataSuccess = (tableData, totalCount) => ({
  type: PROPOSALS_TABLEDATA_SUCCESS,
  tableData,
  totalCount,
});

export const proposalsTableDataError = errorDetail => ({
  type: PROPOSALS_TABLEDATA_ERROR,
  errorDetail,
});

export const showPropertyDetailsView = id =>
  function(dispatch, getState) {
    return PropertyDetailsApi.getMetasource(getState().Preload.mainMetasource.properties.href, id)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
        dispatch(getPropertyDetailsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const getPropertyDetailsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().PropertyDetails;
    const { metasourceStrings } = getState().Preload;
    const urlReduced = getMetasourceLink(
      metasource,
      metasourceStrings,
      'PROPERTY_DETAIL_METASOURCE_PROPERTY_REDUCED'
    );

    if (!urlReduced) {
      return null;
    }

    return PropertyDetailsApi.getPropertyDetailsData(urlReduced.href)
      .then(response => {
        const { etag } = response.headers;

        dispatch(propertyDetailsDataSuccess(response.data.property, urlReduced.href, etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(propertyDetailsDataError(extractErrorMessage(error)));
      });
  };

export const savePropertyDetailsData = propertyDetailsData =>
  function(dispatch, getState) {
    const { etag } = getState().PropertyDetails.data;
    const { url } = getState().PropertyDetails.data;

    return PropertyDetailsApi.savePropertyDetailsData(url, propertyDetailsData, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(propertyDetailsSubmitSuccess(etag));
        dispatch(propertyDetailsDataSuccess(propertyDetailsData, url, etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(propertyDetailsSubmitError(extractErrorMessage(error)));
      });
  };

export const createNewProperty = (url, propertyDetailsData) =>
  function(dispatch) {
    return PropertyDetailsApi.savePropertyDetailsData(url, propertyDetailsData)
      .then(response => {
        browserHistory.push(`/propertyDetails/${response.data.property.id}`);
      })
      .catch(error => {
        console.error(error);
        dispatch(createNewPropertyError(extractErrorMessage(error)));
      });
  };

export const uploadPropertyImages = files =>
  function(dispatch, getState) {
    const { metasource } = getState().PropertyDetails;
    const { metasourceStrings } = getState().Preload;
    const url = getMetasourceLink(
      metasource,
      metasourceStrings,
      'PROPERTY_DETAIL_METASOURCE_ADD_PICTURES'
    );

    return PropertyDetailsApi.uploadPropertyImages(
      resolveParams(url.href).replace('8080', '9090'),
      files
    )
      .then(response => {
        const { etag } = response.headers;

        dispatch(propertyDetailsSubmitSuccess(etag));
        dispatch(getPropertyDetailsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(propertyDetailsImagesSubmitError(extractErrorMessage(error)));
      });
  };

export const printPropertyDetails = (id, printableData, propertyDetails = null) =>
  function(dispatch) {
    dispatch(setPrintablePropertyDetailsData(printableData, propertyDetails));
    // window.open(`${ENDPOINT}/printable/propertyDetails/${id}`);
    // if (!loadOutsidePage) {
    browserHistory.push(`/printable/propertyDetails/${id}`);
    // }
  };

export const printPublicDossier = (printableData, propertyDetails = null) =>
  function(dispatch) {
    dispatch(setPrintablePropertyDetailsData(printableData, propertyDetails));
  };

export const cloneProperty = propertyDetails =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Properties.metasource,
      getState().Preload.metasourceStrings,
      'PROPERTY_MAIN_METASOURCE_NEW_PROPERTY'
    );

    return PropertiesApi.getNewPropertyLocation(url.href)
      .then(response =>
        PropertyDetailsApi.savePropertyDetailsData(response.headers.location, propertyDetails)
          .then(() => {
            dispatch(clonePropertySuccess());
          })
          .catch(error => {
            console.error(error);
            dispatch(clonePropertyError(extractErrorMessage(error)));
          })
      )
      .catch(error => {
        console.error(error);
        dispatch(clonePropertyError(extractErrorMessage(error)));
      });
  };

export const getPropertyProposalsData = (purl = null) =>
  function(dispatch, getState) {
    const { metasource } = getState().PropertyDetails;
    const { metasourceStrings } = getState().Preload;
    let url = purl;

    if (!url) {
      url = getMetasourceLink(metasource, metasourceStrings, 'PROPERTY_INVOLVED_PROPOSALS');
    }

    return PropertyDetailsApi.getPropertyDetailsData(url.href)
      .then(response => {
        dispatch(proposalsTableDataSuccess(response.data, response.headers['x-total-count']));
      })
      .catch(error => {
        console.error(error);
        dispatch(proposalsTableDataError(extractErrorMessage(error)));
      });
  };
