import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { logInUser } from '../../modules/LoginControl/LoginControlActions';
import { Alert } from 'antd';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {
        username: '',
        password: '',
      },
      loginInProcess: false,
      loginInputsError: false,
      loginDisabledUser: false,
    };
    this.changeCredentials = this.changeCredentials.bind(this);
    this.performLogin = this.performLogin.bind(this);
  }

  componentDidMount() {
    if (localStorage.jwt) {
      browserHistory.push('/');
    }
  }

  changeCredentials(event) {
    const field = event.target.name;
    const { credentials } = this.state;

    credentials[field] = event.target.value;
    this.setState({
      credentials,
    });

    return credentials;
  }

  performLogin(event) {
    event.preventDefault();
    let disabledUsers = [
      'leo.aguado@homyspace.com',
      'silvia.dediego@homyspace.com',
      'guzman.bustelo@homyspace.com',
      'judit.martinez@homyspace.com',
      'mafer.salazar@homyspace.com',
      'esther.boix@homyspace.com',
      'joseignacio.florido@homyspace.com',
      'alina@homyspace.com',
      'alejandro.albert@homyspace.com',
      'sarai.tarin@homyspace.com',
      'alvaro.delafuente@homyspace.com',
      'gemma.domenech@homyspace.com',
      'beatriz.boada@homyspace.com',
      'lydia.perez@homyspace.com',
      'marta.illan@homyspace.com',
      'cristina.molla@homyspace.com',
      'natalia.roldan@homyspace.com',
      'andreina@homyspace.com',
      'sergio.querol@homyspace.com',
      'angel.mayoral@homyspace.com',
      'borja.gromaz@homyspace.com',
    ];
    if (
      this.state.credentials.username !== '' &&
      disabledUsers.includes(this.state.credentials.username)
    ) {
      this.setState({
        loginDisabledUser: true,
      });
      return;
    } else if (this.state.credentials.username === '' || this.state.credentials.password === '') {
      this.setState({
        loginInputsError: true,
      });

      return;
    }

    this.setState({
      loginInputsError: false,
      loginInProcess: true,
    });

    this.props.actions.logInUser(this.state.credentials);
  }

  render() {
    return (
      <div className='container height100'>
        <div>
          {this.state.loginDisabledUser && (
            <Alert
              message='Atención'
              description='Este usuario se encuentra deshabilitado. Si necesitas acceder, comunícaselo al LAB'
              type='warning'
              showIcon
              closable={true}
            />
          )}
          {this.props.loginError && !this.state.loginInputsError && (
            <Alert
              message='Error'
              description='Error al iniciar sesión. Por favor, comprueba tu usuario y contraseña'
              type='error'
              showIcon
              closable={true}
            />
          )}
          {this.props.loginProblem && !this.state.loginInputsError && (
            <Alert
              message='Error'
              description={this.props.loginProblemDetails}
              type='error'
              showIcon
              closable={true}
            />
          )}
          {this.state.loginInputsError && (
            <Alert
              message='Error'
              description='Por favor, indica tu usuario y contraseña'
              type='error'
              showIcon
              closable={true}
            />
          )}
        </div>
        <form id='formLogin' onSubmit={this.performLogin}>
          <div className='row justify-content-center marginTop20'>
            <div className='col-md-8'>
              <div className='card-group mb-0 shadow'>
                <div className='card p-4 noMargin'>
                  <div className='card-block'>
                    <h1 className='nexa-bold font-5xl'>
                      homybrain
                      {/* <img src='img/xmas/claus-hat.png' className='img-avatar' height={35} /> */}
                    </h1>
                    <p className='text-muted'>Introduce tu usuario y contraseña</p>
                    <div className='input-group mb-3'>
                      <span className='input-group-addon'>
                        <i className='icon-user' />
                      </span>
                      <input
                        name='username'
                        type='text'
                        className='form-control'
                        placeholder='Usuario'
                        onChange={this.changeCredentials}
                      />
                    </div>
                    <div className='input-group mb-4'>
                      <span className='input-group-addon'>
                        <i className='icon-lock' />
                      </span>
                      <input
                        type='password'
                        name='password'
                        className='form-control'
                        placeholder='Contraseña'
                        onChange={this.changeCredentials}
                      />
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <button type='submit' className='btn btn-primary pr-3 pl-3'>
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='card card-inverse card-primary py-5 hidden-md-down noMargin'
                  style={{ width: 44 + '%' }}
                >
                  <div className='card-block text-center'>
                    <div className='iconHs'>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <style jsx>
          {`
            .input-group-addon {
              padding: 0.55rem 0.65rem;
              margin-bottom: 0;
              font-size: 0.875rem;
              font-weight: normal;
              line-height: 1.25;
              color: #55595c;
              text-align: center;
              background-color: #d1d4d7;
              border: 1px solid rgba(0, 0, 0, 0.15);
            }
          `}
        </style>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logInUser: credentials => dispatch(logInUser(credentials)),
    },
  };
}

function mapStateToProps(state) {
  return {
    loginError: state.LoginControl.loginError,
    loginProblem: state.LoginControl.loginProblem,
    loginProblemDetails:
      'Error en el proceso de login. Detalle: ' + state.LoginControl.loginProblemDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
