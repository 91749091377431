import React, { Component } from 'react';
import { Col, Row, Typography } from 'antd';
import moment from 'moment/moment';
import TableAveragePmas from '../../components/TableAveragePmas';
import TableDataPmas from '../../components/TableDataPmas';
import { PARSE_DATE_FORMAT } from '../../shared/appConfig';
import PmasFilter from './PmasFilter';
import { getPmaAveragesData, getPmaGroupedData } from '../../shared/apiUrls';
import api from '../../shared/utils/api';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';

const { Title } = Typography;

class Pmas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // filterButtonDisabled: true,
      // zipCodeSelectDisabled: true,
      // checkBoxesDisabled: true,
      filter: { ...props.filter },
      // options: {
      //   cities: [],
      //   zipCodes: [],
      // },
      loading: false,
      zipCodesView: false,
      pmasAverageData: [],
      pmasGroupedData: [],
    };
    this.handleSubmitFilter = this.handleSubmitFilter.bind(this);
    this.getAveragePmasData = this.getAveragePmasData.bind(this);
    this.getGroupedPmasData = this.getGroupedPmasData.bind(this);
  }

  handleSubmitFilter(filter) {
    if (filter.city) {
      this.setState({ loading: true });
      let stringQuery = `?city=${filter.city}`;

      if (filter.zipCodes && filter.zipCodes.length > 0) {
        stringQuery += `&zipCode=${filter.zipCodes[0]}`;
        for (let z = 1; z < filter.zipCodes.length; z++) {
          stringQuery += ` ${filter.zipCodes[z]}`;
        }
      }

      if (!isEmpty(filter.dateFrom)) {
        stringQuery += `&dateFrom=${moment(filter.dateFrom, PARSE_DATE_FORMAT).valueOf()}`;
      }

      if (!isEmpty(filter.dateTo)) {
        stringQuery += `&dateTo=${moment(filter.dateTo, PARSE_DATE_FORMAT).valueOf()}`;
      }

      if (!isNil(filter.rooms)) {
        stringQuery += `&rooms=${filter.rooms}`;
      }

      if (!isEmpty(filter.detailedBy)) {
        stringQuery += `&detailedBy=${filter.detailedBy}`;
      }

      this.getGroupedPmasData(stringQuery);
      this.getAveragePmasData(stringQuery);
    }
  }

  getAveragePmasData(stringQuery) {
    const url = getPmaAveragesData(stringQuery);

    api
      .request_retry(url, 'get', 3)
      .then(response => {
        const values = get(response, 'json.pmaaverages', []);

        this.setState({
          pmasAverageData: values,
          loading: false,
        });
      })
      .catch(err => {
        console.error(`Error calling pmaAverages: ${err.message}`);
        this.setState({ loading: false });
        openNotificationWithIcon('error', 'Oppss :(', err.message);
      });
  }

  getGroupedPmasData(stringQuery) {
    const url = getPmaGroupedData(stringQuery);

    api
      .request_retry(url, 'get', 3)
      .then(response => {
        const values = get(response, 'json.pmas', []);

        this.setState({
          pmasGroupedData: values,
          loading: false,
        });
      })
      .catch(err => {
        console.error(`Error calling pmaGrouped: ${err.message}`);
        this.setState({ loading: false });
        openNotificationWithIcon('error', 'Oppss :(', err.message);
      });
  }

  changeView() {
    this.setState({
      zipCodesView: !this.state.zipCodesView,
    });
  }

  render() {
    return (
      <div className='animated fadeIn pma'>
        <Row align='middle' gutter={24}>
          <Col xs={24}>
            <Title level={2} className='title-pma'>
              PMA
              {/* <img src='img/xmas/fire.png' alt='snowman' height={50} /> */}
            </Title>
          </Col>
        </Row>
        <Row className='row-filter-pma'>
          <Col span={24}>
            <PmasFilter
              filter={this.state.filter}
              loading={this.state.loading}
              options={this.state.options}
              handleOnChange={this.handleOnChangeFilter}
              handleSubmit={this.handleSubmitFilter}
            />
          </Col>
        </Row>
        <Row className='row-table-pma'>
          <Col span={24}>
            <div className='card pmaBorderTopColor'>
              <div className='card-block'>
                <button
                  type='button'
                  className={
                    !this.state.zipCodesView
                      ? 'btn width50PerCent btn-primary'
                      : 'btn width50PerCent'
                  }
                  onClick={() => {
                    this.changeView();
                  }}
                >
                  Vista resumen
                </button>
                <button
                  type='button'
                  className={
                    this.state.zipCodesView
                      ? 'btn width50PerCent btn-primary'
                      : 'btn width50PerCent'
                  }
                  onClick={() => {
                    this.changeView();
                  }}
                >
                  Vista detalle
                </button>
                {this.state.zipCodesView && (
                  <div className='row marginTop2'>
                    <div className='col-sm-12'>
                      <TableDataPmas
                        data={this.state.pmasGroupedData}
                        loading={this.state.loading}
                      />
                    </div>
                  </div>
                )}
                {!this.state.zipCodesView && (
                  <div className='row marginTop2'>
                    <div className='col-sm-12'>
                      <TableAveragePmas
                        data={this.state.pmasAverageData}
                        loading={this.state.loading}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <style jsx>
          {`
            .pma {
              margin: 10px;
            }

            .title-pma {
              margin: 0;
            }

            .row-filter-pma {
              margin-top: 10px;
            }

            .row-table-pma {
              margin-top: 10px;
            }
          `}
        </style>
      </div>
    );
  }
}

export default Pmas;
