import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Alert, Icon, Modal, Popover } from 'antd';
import Select from 'react-select';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import {
  getOptionsForSelect,
  isEmptyObject,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import TableDataProviderContacts from '../TableDataProviderContacts';
import {
  deleteProviderContactData,
  getNewContactLocation,
  getProviderContactEtag,
  saveProviderContactData,
} from '../../modules/ProviderDetails/ProviderDetailsActions';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import { saveContactDetailsDataFromParent } from '../../modules/ContactDetails/ContactDetailsActions';
import FormNewContact from '../FormNewContact';
import get from 'lodash/get';
import set from 'lodash/set';

// PARAMETROS A CONFIGURAR
// callbackFunctionOnUpdateOrSave -> Función a llamar cuando se finaliza correctamente un guardado de información
// mainView -> main view in wich this component is included (optional)

class FormProviderContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      contactsVersion: props.providerContactsVersion,
      url: {},
      providerContacts: this.props.providerContacts,
      submitting: false,
      submitSuccess: false,
      invalidInput: false,
      editingContact: false,
      editContact: {},
      options: {
        contactType: getOptionsForSelect(this.props.optionLabels, 'ContactType'),
      },
      modal: false,
      contactLocation: props.contactLocation,
      deleteContactStatus: {
        contact: {},
        error: false,
        errorDetail: '',
      },
      contactSubmit: {
        error: false,
        errorDetail: '',
      },
      contactToUnlink: {},
      contactToRemove: {},
    };

    this.contactSelected = this.contactSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.contactCreated = this.contactCreated.bind(this);
    this.handleDeleteContact = this.handleDeleteContact.bind(this);
    this.handleUnlinkContact = this.handleUnlinkContact.bind(this);
    this.findContact = this.findContact.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting && !nextProps.contact.error) {
      this.setState({ submitSuccess: true });
      this.props.callbackFunctionOnUpdateOrSave();
    } else {
      this.setState({ submitSuccess: false });
    }

    this.setState({ submitting: false });
    if (this.state.contactLocation !== nextProps.contactLocation) {
      this.setState({
        contactLocation: nextProps.contactLocation,
      });
      this.toggle();
    }

    // For deleteContact
    if (
      JSON.stringify(this.state.deleteContactStatus) !==
      JSON.stringify(nextProps.deleteContactStatus)
    ) {
      if (nextProps.deleteContactStatus.error && !isEmptyObject(this.state.contactToRemove)) {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error eliminando el contacto: \n${nextProps.deleteContactStatus.errorDetail}`
        );
      } else if (!isEmptyObject(this.state.contactToRemove)) {
        openNotificationWithIcon(
          'success',
          'Todo OK :)',
          'El contacto se ha eliminado correctamente'
        );
        this.props.callbackFunctionOnUpdateOrSave();
      }

      // Se elimina el contacto que estaba reservado en handleDeleteContact para controlar el borrado del contacto
      this.setState({
        contactToRemove: {},
        deleteContactStatus: {},
      });
    }

    // For unlinkContact
    if (JSON.stringify(this.state.contactSubmit) !== JSON.stringify(nextProps.contactSubmit)) {
      if (nextProps.contactSubmit.error) {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error al desvincular el contacto: \n${nextProps.contactSubmit.errorDetail}`
        );
      }
    }

    if (!isEmptyObject(this.state.contactToUnlink)) {
      openNotificationWithIcon(
        'success',
        'Todo OK :)',
        'El contacto se ha desvinculado del proveedor correctamente'
      );
      this.props.callbackFunctionOnUpdateOrSave();
      // Se elimina el contacto que estaba reservado en handleUnlinkContact para controlar la desvinculacion
      this.setState({ contactToUnlink: {} });
    }

    if (
      JSON.stringify(this.state.providerContacts) !== JSON.stringify(nextProps.providerContacts)
    ) {
      this.setState({
        providerContacts: nextProps.providerContacts,
      });
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
    if (this.state.editingContact) {
      this.setState({
        editingContact: !this.state.editingContact,
      });
    }
  }

  editToggleContact(contactId) {
    const editContact = this.findContact(this.state.providerContacts, contactId);

    this.setState({
      editContact,
      editingContact: !this.state.editingContact,
      modal: !this.state.modal,
    });
  }

  handleChange(e) {
    const newContact = this.state.contact;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'phoneNumber' || e.target.name === 'mobilePhoneNumber') {
      value = normalizePhoneNumber(value);
    }

    newContact[e.target.name] = value;
    this.setState({ contact: newContact });
  }

  handlePlaceChanged(place) {
    const { contact } = this.state;

    set(contact, 'address', {
      ...place,
      addressComplement: get(contact, 'address.addressComplement', null),
    });

    this.setState({
      contact,
    });
  }

  handleSelectChange(name, selectedOption) {
    const newContact = this.state.contact;

    newContact[name] = selectedOption ? selectedOption.value : this.props.providerBaseContact.id;
    this.setState({ contact: newContact });
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !validForm });

    return validForm;
  }

  handleSubmit() {
    if (this.isValidForm()) {
      this.setState({ submitting: true });
      this.props.actions.saveProviderContactData(this.state.contact, this.state.url);
    }
  }

  contactCreated() {
    this.props.callbackFunctionOnUpdateOrSave();
    if (this.state.modal) {
      this.toggle();
    }
  }

  contactSelected = contact => {
    browserHistory.push(`/contactDetails/${contact.id}`);
  };

  handleDeleteContact(contactId) {
    if (
      this.props.providerContacts &&
      window.confirm('¿Estás seguro de que quieres eliminar este contacto?')
    ) {
      const contact = this.findContact(this.props.providerContacts, contactId);

      if (contact) {
        // Se reserva el contacto en el estado para eliminarlo del array de contactos en el onSuccess de saveContactDetailsData(contact)
        this.setState({ contactToRemove: contact }, this.props.actions.deleteContact(contact));
      }
    }
  }

  handleUnlinkContact(contactId) {
    if (
      this.props.providerContacts &&
      window.confirm('¿Estás seguro de que quieres desvincular este contacto?')
    ) {
      const contact = this.findContact(this.props.providerContacts, contactId);

      if (contact) {
        contact.contact.relatedProvider = null;
        // Se reserva el contacto en el estado para eliminarlo del array de contactos en el onSuccess de saveContactDetailsData(contact)
        this.setState(
          { contactToUnlink: contact },
          this.props.actions.saveContactDetailsData(contact)
        );
      }
    }
  }

  findContact(contactList, contactId) {
    let contact;
    const contactsWithClidren = [];

    for (let i = 0; i < contactList.length; i++) {
      contact = contactList[i];
      if (contact) {
        if (contactId === contact.contact.id) {
          break;
        } else if (contact.children) {
          contactsWithClidren.push(contact);
        }

        contact = null;
      }
    }

    if (!contact && contactsWithClidren.length > 0) {
      for (let i = 0; i < contactsWithClidren.length; i++) {
        contact = this.findContact(contactsWithClidren[i].children, contactId);
        if (contact) {
          return contact;
        }
      }
    } else {
      return contact;
    }
  }

  render() {
    const deleteRender = contactId => {
      const content = (
        <div>
          <div className='row alignCenter popup-option'>
            <div className='col-sm-12'>
              <a className='popup-option' onClick={() => this.handleDeleteContact(contactId)}>
                Eliminar de homybrain
              </a>
            </div>
          </div>
          <div className='row alignCenter popup-option'>
            <a className='popup-option' onClick={() => this.handleUnlinkContact(contactId)}>
              Desvincular del proveedor
            </a>
          </div>
        </div>
      );
      return (
        <div className='row' style={{ display: '-webkit-box' }}>
          <div>
            <button
              className='btn btn-primary btn-sm'
              onClick={() => this.editToggleContact(contactId)}
              style={{ borderColor: 'white' }}
            >
              <Icon type='edit' />
            </button>
          </div>
          <div>
            <Popover content={content} trigger='hover'>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                style={{ borderColor: 'white' }}
              >
                <Icon type='delete' />
              </button>
            </Popover>
          </div>
        </div>
      );
    };

    return (
      <div>
        <div className='row'>
          <div className='col-sm-2'>
            <button
              type='button'
              className='btn btn-primary btn-block alignLeft'
              onClick={() => this.props.actions.getNewContactLocation()}
            >
              Nuevo Contacto
            </button>
            <Modal
              title={this.state.editingContact ? 'Modificar función de contacto' : 'Nuevo Contacto'}
              width={800}
              style={{ top: '20px' }}
              visible={this.state.modal}
              footer={null}
              onCancel={this.toggle}
              destroyOnClose
            >
              <FormNewContact
                contactType='relatedProvider'
                editContact={this.state.editContact}
                editingContact={this.state.editingContact}
                providerOrCompany={this.props.providerBaseContact}
                mainView={this.props.mainView}
                callbackFuncionOnCreate={this.contactCreated}
              />
            </Modal>
          </div>
          <div className='col-sm-10'>
            {this.props.providerContacts && (
              <TableDataProviderContacts
                callbackFunctionOnContactSelect={this.contactSelected}
                deleteRender={deleteRender}
              />
            )}
          </div>
        </div>
        <div id='formContactDetail'>&nbsp;</div>
        {this.props.contact && this.props.contact.error && (
          <Alert
            message='Error'
            description={this.props.contact.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
        {this.state.invalidInput && (
          <Alert message='Error en los datos introducidos' type='error' showIcon closable />
        )}
        {this.state.contact.id && (
          <div className='animated fadeIn row detailRow col-sm-12'>
            <div className='col-sm-2'>
              {/*
              <button type="button" className="btn btn-primary btn-block alignLeft" onClick={this.handleSubmit}>
                Guardar
              </button>
              <button type="button" className="btn btn-primary btn-block alignLeft" onClick={this.handleDelete}>
                Eliminar
              </button>
              */}
            </div>
            <div className='col-sm-10'>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactType' className='required'>
                      Tipo
                    </label>
                    <Select
                      name='contactType'
                      className='form-control validate validate-required'
                      value={this.state.contact.contactType || ''}
                      options={this.state.options.contactType}
                      onChange={value => {
                        this.handleSelectChange('contactType', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='parent' className='required'>
                      Contacto padre
                    </label>
                    <Select
                      name='parent'
                      className='form-control validate validate-required'
                      value={this.state.contact.parent || ''}
                      options={this.state.options.contactList}
                      onChange={value => {
                        this.handleSelectChange('parent', value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactIdentificationCode'>NIF</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactIdentificationCode'
                      name='identificationCode'
                      placeholder='12121212Z'
                      value={this.state.contact.identificationCode || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactName' className='required'>
                      Nombre
                    </label>
                    <input
                      type='text'
                      className='form-control validate validate-required'
                      id='contactName'
                      name='name'
                      placeholder='Ana'
                      value={this.state.contact.name}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactLastOTradeName'>Apellidos/Razón Social</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactLastOTradeName'
                      name='lastOTradeName'
                      placeholder='García García'
                      value={this.state.contact.lastOTradeName || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  {this.state.contact.contactType === 'PERSON' && (
                    <div className='form-group'>
                      <label htmlFor='contactJobTitle'>Cargo</label>
                      <input
                        type='text'
                        className='form-control'
                        id='contactJobTitle'
                        name='jobTitle'
                        placeholder='Cargo'
                        value={this.state.contact.jobTitle || ''}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactEmail'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      id='contactEmail'
                      name='email'
                      placeholder='pruebas@pruebas.com'
                      value={this.state.contact.email || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactEmail2'>2º Email</label>
                    <input
                      type='email'
                      className='form-control'
                      id='contactEmail2'
                      name='email2'
                      placeholder='pruebas@pruebas.com'
                      value={this.state.contact.email2 || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactPhoneNumber'>Teléfono fijo</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactPhoneNumber'
                      name='phoneNumber'
                      placeholder='961231212'
                      value={this.state.contact.phoneNumber || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactMobilePhoneNumber'>Teléfono móvil</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactMobilePhoneNumber'
                      name='mobilePhoneNumber'
                      placeholder='616232323'
                      value={this.state.contact.mobilePhoneNumber || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactAddress'>Direccion</label>
                    <GoogleMapsAutocomplete
                      handlePlaceChanged={this.handlePlaceChanged}
                      initValue={
                        this.state.contact.address && this.state.contact.address.formattedAddress
                          ? this.state.contact.address
                          : null
                      }
                      inputClassName='form-control'
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactAddressComplement'>Complemento dirección</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactAddressComplement'
                      name='addressComplement'
                      placeholder='n.3 '
                      value={
                        this.state.contact.address && this.state.contact.address.addressComplement
                          ? this.state.contact.address.addressComplement
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactAccountNumber'>Número de cuenta</label>
                    <input
                      type='text'
                      className='form-control validate validate-accountNumber'
                      id='contactAccountNumber'
                      name='accountNumber'
                      placeholder='ES12 0000 0000 0000 0000 0000'
                      value={this.state.contact.accountNumber || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='bicOrSwift'>BIC o SWIFT</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactBicOrSwift'
                      name='bicOrSwift'
                      placeholder='ES12 0000 0000 0000 0000 0000'
                      value={this.state.contact.bicOrSwift || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='contactDescription'>Descripción</label>
                    <textarea
                      id='contactDescription'
                      name='description'
                      rows='8'
                      className='form-control'
                      placeholder='Descripción'
                      value={this.state.contact.description || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='contactObservations'>Observaciones</label>
                    <textarea
                      id='contactObservations'
                      name='observations'
                      rows='4'
                      className='form-control'
                      placeholder='Observaciones'
                      value={this.state.contact.observations || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveProviderContactData: (data, url) => {
        dispatch(saveProviderContactData(data, url));
      },
      getProviderContactEtag: url => {
        dispatch(getProviderContactEtag(url));
      },
      deleteContact: contact => {
        dispatch(deleteProviderContactData(contact));
      },
      getNewContactLocation: () => {
        dispatch(getNewContactLocation());
      },
      saveContactDetailsData: contact => {
        dispatch(saveContactDetailsDataFromParent(contact));
      },
    },
  };
}

function mapStateToProps(state) {
  return {
    providerContacts: state.ProviderDetails.contacts.detail,
    providerBaseContact: state.ProviderDetails.data.detail,
    optionLabels: state.Preload.optionLabels,
    contact: state.ProviderDetails.contact,
    providerContactsVersion: state.ProviderDetails.contactsVersion,
    contactLocation: state.ProviderDetails.contactLocation.location,
    deleteContactStatus: state.ContactDetails.delete,
    contactSubmit: state.ContactDetails.submit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormProviderContacts);
