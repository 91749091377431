import React from 'react';
import { Button, Modal } from 'antd';

const Alert = ({
  header,
  message,
  confirmButtonText = 'Aceptar',
  onConfirmClick,
  onCancelClick,
}) => (
  <Modal
    visible
    onCancel={onCancelClick}
    title={header}
    footer={[
      <Button key='cancel' onClick={onCancelClick}>
        Cancelar
      </Button>,
      <Button key='confirm' type='primary' className='btn-primary' onClick={onConfirmClick}>
        {confirmButtonText}
      </Button>,
    ]}
  >
    <div>{message}</div>
  </Modal>
);
export default Alert;
