import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'antd';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete/GoogleMapsAutocomplete';

const { TextArea } = Input;
const NOOP = () => {};

const GenerateProposalExtraInfo = ({ ...props }) => (
  <>
    <Row className='hs-row'>
      <Col span={24}>
        <label htmlFor='printableDataTitle'>Título</label>
        <Input
          id='printableDataTitle'
          name='title'
          placeholder='Título inmueble'
          value={props.data.title}
          onChange={props.handleChange}
        />
      </Col>
    </Row>
    <Row className='hs-row'>
      <Col span={24}>
        <label htmlFor='printableDataAddress'>Dirección</label>
        <GoogleMapsAutocomplete
          handlePlaceChanged={props.handleChange}
          initValue={props.data.address}
          name='address'
          id='Printable'
        />
      </Col>
    </Row>
    <Row>
      <Col span={24} className='hs-row'>
        <label htmlFor='printableDataObservations'>Observaciones</label>
        <TextArea
          id='printableDataObservations'
          name='observations'
          autosize={{ minRows: 4 }}
          placeholder='Es un inmueble ubicado en la playa...'
          value={props.data.observations}
          onChange={props.handleChange}
        />
      </Col>
    </Row>
    <style jsx>
      {`
        .hs-row {
          margin-bottom: 15px;
        }
      `}
    </style>
  </>
);
GenerateProposalExtraInfo.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    observations: PropTypes.string,
  }),
  handleChange: PropTypes.func,
};

GenerateProposalExtraInfo.defaultProps = {
  data: {
    title: '',
    address: '',
    observations: '',
  },
  handleChange: NOOP,
};

export default GenerateProposalExtraInfo;
