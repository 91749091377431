import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { browserHistory } from 'react-router';
import moment from 'moment';
import BigCalendar from 'react-big-calendar';
import {
  BackTop,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Tooltip,
} from 'antd';
import {
  savePropertyDetailsData,
  uploadPropertyImages,
} from '../../modules/PropertyDetails/PropertyDetailsActions';
import {
  checkDateBefore,
  normalizeNumberToDecimals,
  validateInputs,
} from '../../shared/utils/formUtils';
import { getNewPropertyLocation } from '../../modules/Properties/PropertiesActions';
import {
  getExtendedContactList,
  getMetasourceLink,
  getOptionsForSelect,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import ImageManager from '../ImageManager';
import FormPrintablePropertyDetails from '../FormPrintablePropertyDetails';
import CheckboxButton from '../CheckboxButton';
import ImageLoader from '../ImageLoader';
import SelectProvider from '../SelectProvider';
import { getProviderBaseContactAndChildren } from '../../modules/ProviderDetails/ProviderDetailsActions';
import FormNewPeriodPrice from '../FormNewPeriodPrice';
import FormCloneProperty from '../FormCloneProperty/FormCloneProperty';
import ProgressCloneProperty from '../ProgressPropertyClone/ProgressCloneProperty';
import { DATE_FORMAT, DATETIME_FORMAT, PARSE_DATE_FORMAT } from '../../shared/appConfig';
import BondedDealInfo from '../BondedDealInfo/BondedDealInfo';
import 'moment/locale/es';
import get from 'lodash/get';
import set from 'lodash/set';
import PropertyAvailability from './PropertyAvailability';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import api from '../../shared/utils/api';
import replace from 'lodash/replace';
import isNil from 'lodash/isNil';
import { DeletedPropertyInfo } from './DeletedPropertyInfo';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';
import { PROPERTY_PUBLICATION_STATUS_LABELS_ES, PROPERTY_PUBLICATION_STATUS } from 'hs-utils';
import PropertyDetailsApi from '../../modules/PropertyDetails/PropertyDetailsApi';

class FormPropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.CONFIRM_CLONE_MESSAGE_PREFFIX = '¿Estás seguro de que quieres clonar el inmueble ';
    this.state = {
      propertyDetails: props.propertyDetailsData.data.detail,
      providerData: {},
      submitting: false,
      submittingImages: false,
      modalPrint: false,
      imagesLoaded: [],
      options: {
        housingType: getOptionsForSelect(this.props.optionLabels, 'HousingType'),
        cleaningChargeType: getOptionsForSelect(this.props.optionLabels, 'CleaningChargeType'),
        classification: getOptionsForSelect(this.props.optionLabels, 'Classification'),
        taxType: getOptionsForSelect(this.props.optionLabels, 'TaxType'),
      },
      providerBaseContactAndChildren: null,
      contactList: null,
      modalNewPeriodPrice: false,
      newPeriodPrice: {},
      formattedPeriodPrices: [],
      modalClone: false,
      modalDelete: false,
      modalCloneProgress: false,
      totalCopies: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectProviderChange = this.handleSelectProviderChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.handleImagesChange = this.handleImagesChange.bind(this);
    this.togglePrint = this.togglePrint.bind(this);
    this.handleLoadImagesComplete = this.handleLoadImagesComplete.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.eventStyleGetter = this.eventStyleGetter.bind(this);
    this.handleImageDelete = this.handleImageDelete.bind(this);
    this.toggleNewPeriodPrice = this.toggleNewPeriodPrice.bind(this);
    this.handleNewPeriodPrice = this.handleNewPeriodPrice.bind(this);
    this.handleNewSlotPeriodPrice = this.handleNewSlotPeriodPrice.bind(this);
    this.handleDeletePeriodPrice = this.handleDeletePeriodPrice.bind(this);
    this.eventTitleGetter = this.eventTitleGetter.bind(this);
    this.toggleClone = this.toggleClone.bind(this);
    this.handleCloneProperty = this.handleCloneProperty.bind(this);
    this.toggleCloneProgress = this.toggleCloneProgress.bind(this);
    this.curatePeriodPrices = this.curatePeriodPrices.bind(this);
    this.handleAddressComplementChange = this.handleAddressComplementChange.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCopyUrl = this.handleCopyUrl.bind(this);
    this.handlePublishProperty = this.handlePublishProperty.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.metasourceStrings).length > 0) {
      const urlProviderWithChildren = getMetasourceLink(
        this.props.metasource,
        this.props.metasourceStrings,
        'PROPERTY_PROVIDER_WITH_CHILDREN'
      ).href;

      this.props.actions.getProviderBaseContactAndChildren(urlProviderWithChildren);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting) {
      if (!nextProps.propertyDetailsSubmit.error) {
        openNotificationWithIcon(
          'success',
          'Todo OK :)',
          'Los cambios se han registrado correctamente'
        );
        this.refs.imageLoader.loadImages();
      } else {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          this.props.propertyDetailsSubmit.errorDetail
        );
      }

      this.setState({
        submitting: false,
      });
    }

    if (this.state.submittingImages) {
      if (!nextProps.propertyDetailsImagesSubmit.error) {
        openNotificationWithIcon(
          'success',
          'Todo OK :)',
          'Las imágenes que has incluido se han guardado correctamente.'
        );
        this.refs.imageManager.refreshState();
        this.refs.imageLoader.loadImages();
      } else {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          this.props.propertyDetailsImagesSubmit.errorDetail
        );
      }

      this.setState({
        submittingImages: false,
      });
    }

    if (
      JSON.stringify(this.state.propertyDetails) !==
      JSON.stringify(nextProps.propertyDetailsData.data.detail)
    ) {
      const propertyDetailsDataCurated = this.curatePeriodPrices(
        nextProps.propertyDetailsData.data.detail
      );

      this.setState({ propertyDetails: propertyDetailsDataCurated });
    }

    if (
      Array.isArray(nextProps.providerBaseContactAndChildren) &&
      JSON.stringify(this.state.providerBaseContactAndChildren) !==
        JSON.stringify(nextProps.providerBaseContactAndChildren)
    ) {
      const contactList = getExtendedContactList(nextProps.providerBaseContactAndChildren);

      this.setState({
        providerBaseContactAndChildren: nextProps.providerBaseContactAndChildren,
        contactList,
      });
    }
  }

  curatePeriodPrices(data) {
    if (!data.periodPrices || (data.periodPrices && data.periodPrices.length <= 0)) {
      return data;
    }

    let formattedPeriodPrice;
    const formattedPeriodPrices = [];
    const { periodPrices } = data;
    const periodPricesCurated = [];

    for (let i = 0; i < periodPrices.length; i++) {
      if (checkDateBefore(periodPrices[i].start, periodPrices[i].end)) {
        periodPrices[i].start = moment(periodPrices[i].start)
          .set('year', moment().year)
          .toDate();
        periodPrices[i].end = moment(periodPrices[i].end)
          .set('year', moment().year)
          .toDate();
        periodPricesCurated.push(periodPrices[i]);
        formattedPeriodPrice = periodPrices[i];
        formattedPeriodPrice.price.amount = normalizeNumberToDecimals(
          formattedPeriodPrice.price.amount,
          2
        );
        formattedPeriodPrices.push(formattedPeriodPrice);
      }
    }

    data.periodPrices = periodPricesCurated;
    this.setState({ formattedPeriodPrices });

    return data;
  }

  handleImagesChange(files) {
    if (files && files.length > 0) {
      this.setState({ submittingImages: true });
      this.props.actions.uploadPropertyImages(files);
    }
  }

  handleImageDelete(imageIndex) {
    const { propertyDetails, imagesLoaded } = this.state;
    const { pictures } = propertyDetails;

    pictures.splice(imageIndex, 1);
    imagesLoaded.splice(imageIndex, 1);
    propertyDetails['pictures'] = pictures;

    this.setState({
      propertyDetails,
      imagesLoaded,
    });
    this.handleSubmit();
  }

  handleChange(e) {
    const { propertyDetails } = this.state;

    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'chargesIncluded' && !e.target.value) {
      propertyDetails.spendingLimit = null;
    }

    if (e.target.name === 'acceptedVerification' && value && !propertyDetails.verificationDate) {
      propertyDetails.verificationDate = new Date();
      propertyDetails.verificationBy = this.props.currentUsername;
    }

    this.setState({
      propertyDetails: {
        ...propertyDetails,
        [e.target.name]: value,
      },
    });
  }

  handleAddressComplementChange(e) {
    const newPropertyDetails = this.state.propertyDetails;
    const targetComponents = e.target.name.split('.');

    if (newPropertyDetails[targetComponents[0]]) {
      newPropertyDetails[targetComponents[0]][targetComponents[1]] = e.target.value;
    } else {
      newPropertyDetails[targetComponents[0]] = {
        addressComplement: e.target.value,
      };
    }

    this.setState({ propertyDetails: newPropertyDetails });
  }

  handlePriceChange(e) {
    const { propertyDetails } = this.state;

    this.setState({
      propertyDetails: {
        ...propertyDetails,
        [e.target.name]: {
          amount: e.target.value ? parseFloat(e.target.value) : null,
          currency: 'EUR',
        },
      },
    });
  }

  handleSelectChange(name, selectedOption) {
    const newPropertyDetails = this.state.propertyDetails;

    newPropertyDetails[name] = selectedOption ? selectedOption : null;
    this.setState({ propertyDetails: newPropertyDetails });
  }

  handleSelectProviderChange(name, selectedOption) {
    const newPropertyDetails = this.state.propertyDetails;

    newPropertyDetails[name] = selectedOption ? selectedOption.value : null;
    this.setState({ propertyDetails: newPropertyDetails });
  }

  handlePlaceChanged(place) {
    const { propertyDetails } = this.state;

    set(propertyDetails, 'address', {
      ...place,
      addressComplement: get(propertyDetails, 'address.addressComplement', null),
    });

    this.setState({
      propertyDetails,
    });
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    if (!validForm) {
      openNotificationWithIcon(
        'error',
        'Revisa los datos que has puesto',
        'Parece que hay algún error en los datos que has introducido'
      );
    }

    return validForm;
  }

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    if (this.isValidForm()) {
      const { propertyDetails, contactList } = this.state;

      if (
        isString(propertyDetails.usualContactBack) &&
        !isEmpty(propertyDetails.usualContactBack)
      ) {
        propertyDetails.usualContactBack = get(
          find(contactList, {
            value: propertyDetails.usualContactBack,
          }),
          'contact',
          null
        );
      }

      this.setState({ submitting: true }, () => {
        this.props.actions.savePropertyDetailsData(propertyDetails);
      });
    }
  };

  togglePrint() {
    this.setState({
      modalPrint: !this.state.modalPrint,
    });
  }

  handleLoadImagesComplete(imagesLoaded) {
    this.setState({
      imagesLoaded,
    });
  }

  eventStyleGetter(event) {
    let backColor;

    if (event.external) {
      backColor = 'gray';
    } else if (event.proposalId) {
      backColor = '#23586c';
    } else {
      backColor = 'rgb(17, 138, 112)';
    }

    const style = {
      backgroundColor: backColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
      height: '2.5vh',
      fontWeight: event.price ? 'bold' : 'unset',
      marginTop: '1px',
    };

    return {
      style,
    };
  }

  eventTitleGetter(event) {
    let title = '';

    if (event.price) {
      title = `${event.price.amount} ${event.price.currency}`;
    }

    return title;
  }

  toggleNewPeriodPrice() {
    const newPeriodPrice = {};

    this.setState({
      modalNewPeriodPrice: !this.state.modalNewPeriodPrice,
      newPeriodPrice,
    });
  }

  handleNewPeriodPrice(start, end, price) {
    if (this.state.modalNewPeriodPrice) {
      this.toggleNewPeriodPrice();
    }

    if (
      window.confirm(
        `¿Quieres registrar el periodo del ${moment(start).format(DATE_FORMAT)} al ${moment(
          end
        ).format(DATE_FORMAT)} ?`
      )
    ) {
      const newPeriodPrice = {
        start,
        end,
        price,
        external: false,
      };
      const { propertyDetails } = this.state;

      if (propertyDetails.periodPrices) {
        propertyDetails.periodPrices.push(newPeriodPrice);
      } else {
        propertyDetails.periodPrices = [newPeriodPrice];
      }

      this.setState(
        {
          propertyDetails,
          submitting: true,
        },
        () => this.props.actions.savePropertyDetailsData(propertyDetails)
      );
    }
  }

  handleNewSlotPeriodPrice(slotInfo) {
    const start = moment(slotInfo.start)
      .format(PARSE_DATE_FORMAT)
      .toString();
    const end = moment(slotInfo.end)
      .format(PARSE_DATE_FORMAT)
      .toString();
    const newPeriodPrice = {
      start,
      end,
    };

    this.setState({
      newPeriodPrice,
      modalNewPeriodPrice: !this.state.modalNewPeriodPrice,
    });
  }

  handleDeletePeriodPrice(event) {
    if (!event.external) {
      if (
        !window.confirm(
          `¿Quieres borrar el periodo del ${moment(event.start).format(DATE_FORMAT)} al ${moment(
            event.end
          ).format(DATE_FORMAT)} ?`
        )
      ) {
        return false;
      }

      const { periodPrices } = this.state.propertyDetails;

      for (let i = 0; i < periodPrices.length; i++) {
        const periodPrice = periodPrices[i];

        if (periodPrice === event) {
          periodPrices.splice(i, 1);
          break;
        }
      }

      const { propertyDetails } = this.state;

      propertyDetails.periodPrices = periodPrices;
      this.setState(
        {
          propertyDetails,
          submitting: true,
        },
        () => this.props.actions.savePropertyDetailsData(propertyDetails)
      );
    }
  }

  toggleClone() {
    this.setState({
      modalClone: !this.state.modalClone,
    });
  }

  toggleDelete() {
    this.setState({
      modalDelete: !this.state.modalDelete,
    });
  }

  handleCloneProperty(totalCopies) {
    if (this.state.modalClone) {
      this.toggleClone();
    }

    const messageSuffix = totalCopies === 1 ? 'vez?' : 'veces?';

    if (window.confirm(`${this.CONFIRM_CLONE_MESSAGE_PREFFIX + totalCopies} ${messageSuffix}`)) {
      this.setState({
        totalCopies,
        modalCloneProgress: true,
      });
    }
  }

  toggleCloneProgress() {
    this.setState({
      modalCloneProgress: !this.state.modalCloneProgress,
    });
  }

  handleDelete = async () => {
    const id = get(this.state, 'propertyDetails.id');

    try {
      const propertyToDelete = await api.getDetailData('property', id);
      const etag = replace(propertyToDelete.headers.get('etag'), /"/g, '');

      await api.remove('property', id, {
        etag,
      });
      browserHistory.push('/properties');
      openNotificationWithIcon('success', 'Inmueble eliminado con éxito');
    } catch (e) {
      openNotificationWithIcon('error', `No ha sido posible eliminar el inmueble. ${e.message}`);
    }

    this.setState({
      submitting: false,
    });
  };

  handleCopyUrl() {
    navigator.clipboard
      .writeText(window.decodeURIComponent(this.state.propertyDetails.portalUrl))
      .then(
        function() {
          openNotificationWithIcon('info', 'URL copiada al portapapeles!!!', '', 5);
        },
        function() {
          openNotificationWithIcon('error', 'No se ha podido copiar la URL');
        }
      );
  }

  handlePublishProperty() {
    try {
      PropertyDetailsApi.publishProperty(
        this.state.propertyDetails.homyspaceReference,
        this.state.propertyDetails.id
      );
      const newPropertyDetails = this.state.propertyDetails;
      newPropertyDetails.publicationStatus = PROPERTY_PUBLICATION_STATUS.PUBLISHED;
      this.setState({
        propertyDetails: newPropertyDetails,
      });
      openNotificationWithIcon(
        'success',
        'Todo OK :)',
        'El inmueble se ha publicado correctamente'
      );
    } catch (e) {
      console.error(e);
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        'Ha ocurrido un error al publicar el inmueble'
      );
    }
  }

  render() {
    const monthlyPrice = get(this.state, 'propertyDetails.monthlyPrice.amount', 0);
    const deletedProperty = get(this.state, 'propertyDetails.deletionDate') ? true : false;
    const publishedProperty =
      get(
        this.state,
        'propertyDetails.publicationStatus',
        PROPERTY_PUBLICATION_STATUS.PUBLISHED
      ) === PROPERTY_PUBLICATION_STATUS.PUBLISHED;

    return (
      <div className='col-sm-12'>
        <BackTop />
        <Form id='formPropertyDetails' onSubmit={this.handleSubmit}>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='row'>
                <div className='col-sm-12'>
                  <Button
                    loading={this.state.submitting}
                    htmlType='submit'
                    className='btn btn-primary btn-block alignLeft'
                  >
                    Guardar
                  </Button>
                  <Button
                    disabled={this.state.submitting || deletedProperty}
                    className='btn btn-primary btn-block alignLeft buttonBreakLine'
                    onClick={this.togglePrint}
                  >
                    Crear propuesta
                  </Button>
                  <Modal
                    title='Crear propuesta'
                    visible={this.state.modalPrint}
                    onCancel={this.togglePrint}
                    width={860}
                    footer={null}
                  >
                    {this.state.modalPrint && (
                      <FormPrintablePropertyDetails
                        images={this.state.imagesLoaded}
                        printableData={this.state.printableData}
                        propertyDetails={this.state.propertyDetails}
                        bondedDeal={this.props.bondedDeal}
                        options={this.state.options}
                      />
                    )}
                  </Modal>
                  <Button
                    className='btn btn-primary btn-block alignLeft buttonBreakLine'
                    disabled={this.state.submitting || deletedProperty}
                    onClick={this.toggleClone}
                  >
                    Clonar inmueble
                  </Button>
                  <Modal
                    title='Clonar inmueble'
                    visible={this.state.modalClone}
                    onCancel={this.toggleClone}
                    width={720}
                    footer={null}
                  >
                    <FormCloneProperty callbackFuncionOnCreate={this.handleCloneProperty} />
                  </Modal>
                  <Button
                    className='btn btn-primary btn-block alignLeft buttonBreakLine'
                    disabled={this.state.submitting || publishedProperty}
                    onClick={this.handlePublishProperty}
                  >
                    Publicar
                  </Button>
                  <Button
                    className='btn btn-primary btn-block alignLeft buttonBreakLine'
                    disabled={this.state.submitting || deletedProperty}
                    onClick={this.toggleDelete}
                  >
                    Eliminar
                  </Button>
                  <Modal
                    title='Eliminar inmueble'
                    visible={this.state.modalDelete}
                    onCancel={this.toggleDelete}
                    confirmLoading={this.state.submitting}
                    onOk={() => {
                      this.setState(
                        {
                          submitting: true,
                        },
                        () => this.handleDelete()
                      );
                    }}
                  >
                    ¿Estás seguro que quieres eliminar el Inmueble? Esta acción es irreversible.
                  </Modal>
                  <br />
                  {this.state.propertyDetails.creationDate ? (
                    <p>
                      <b>Creado por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(get(this.state, 'propertyDetails.createdBy'), '')}
                      <br />
                      {moment(this.state.propertyDetails.creationDate).format(DATETIME_FORMAT)}
                    </p>
                  ) : (
                    ''
                  )}
                  {this.state.propertyDetails.updateDate ? (
                    <p>
                      <b>Última actualización por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(get(this.state, 'propertyDetails.updatedBy'), '')}
                      <br />
                      {moment(this.state.propertyDetails.updateDate).format(DATETIME_FORMAT)}
                    </p>
                  ) : (
                    ''
                  )}
                  {this.state.propertyDetails.incorporationDate ? (
                    <p>
                      <b>Fecha de incorporación:</b> <br />{' '}
                      {moment(this.state.propertyDetails.incorporationDate).format(DATETIME_FORMAT)}
                    </p>
                  ) : (
                    ''
                  )}
                  {this.state.propertyDetails.acceptedVerification &&
                  !isNil(this.state.propertyDetails.verificationDate) ? (
                    <p>
                      <b>Verificación:</b> <br />{' '}
                      {moment(this.state.propertyDetails.verificationDate).format(DATETIME_FORMAT)}
                      <br />
                      {get(this.state, 'propertyDetails.verificationBy', '')}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <div className='col-sm-10'>
              <DeletedPropertyInfo visible={deletedProperty} />
              <BondedDealInfo fromPropertyDetails />
              <Row>
                <Col>
                  <Collapse defaultActiveKey={['1']}>
                    <Collapse.Panel header='Fotografías' key='1'>
                      <ImageLoader
                        ref='imageLoader'
                        s3bucket={this.state.propertyDetails.bucketName}
                        imagesToLoad={this.state.propertyDetails.pictures}
                        handleLoadComplete={this.handleLoadImagesComplete}
                      />
                      <ImageManager
                        ref='imageManager'
                        s3bucket={this.state.propertyDetails.bucketName}
                        imagesToRender={this.state.imagesLoaded}
                        handleImagesChange={this.handleImagesChange}
                        handleImageDelete={this.handleImageDelete}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
              <br />
              {!isEmpty(get(this.state, 'propertyDetails.portalUrl')) && (
                <div className='row'>
                  <div className='col-sm-11'>
                    <div className='form-group'>
                      <label htmlFor='propertyDetailportalUrl'>URL para editar el inmueble</label>
                      <input
                        type='text'
                        className='form-control noBorder'
                        disabled
                        id='propertyDetailportalUrl'
                        name='propertyDetailportalUrl'
                        placeholder='https://...'
                        value={
                          this.state.propertyDetails.portalUrl
                            ? this.state.propertyDetails.portalUrl
                            : ''
                        }
                      />
                    </div>
                  </div>
                  <div className='col-sm-1 pt-4 m-0'>
                    <Tooltip placement='top' title='Copiar URL'>
                      <i className='far fa-copy copy-icon' onClick={this.handleCopyUrl} />
                    </Tooltip>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailHousingType' className='required'>
                      Tipo
                    </label>
                    <Select
                      name='housingType'
                      id='propertyDetailHousingType'
                      className='form-control validate validate-required'
                      value={get(this.state, 'propertyDetails.housingType')}
                      options={this.state.options.housingType}
                      onChange={value => {
                        this.handleSelectChange('housingType', value.value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailClassification'>Clasificación</label>
                    <Select
                      name='classification'
                      className='form-control'
                      value={get(this.state, 'propertyDetails.classification')}
                      options={this.state.options.classification}
                      onChange={value => {
                        this.handleSelectChange('classification', value.value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailHomyspaceReference'>Ref. HomySpace</label>
                    <Input
                      id='propertyDetailHomyspaceReference'
                      name='homyspaceReference'
                      placeholder='HS123'
                      value={get(this.state, 'propertyDetails.homyspaceReference')}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailsacceptedVerification'>&nbsp;</label>
                    <CheckboxButton
                      data-testid='acceptedVerification'
                      borderStyle='propertyCheckboxActive'
                      id='propertyDetailsacceptedVerification'
                      name='acceptedVerification'
                      handleChange={this.handleChange}
                      content='Verificado'
                      value={get(this.state, 'propertyDetails.acceptedVerification', false)}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-9'>
                  <SelectProvider
                    label='Proveedor'
                    data-testid='selectProvider'
                    name='providerId'
                    id='propertyDetailProvider'
                    value={get(this.state, 'propertyDetails.providerId')}
                    isRequired
                    handleChange={this.handleSelectProviderChange}
                  />
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='publicationStatus'>Estado</label>
                    <input
                      type='text'
                      className='form-control'
                      id='publicationStatus'
                      value={
                        !isEmpty(get(this.state, 'propertyDetails.publicationStatus'))
                          ? PROPERTY_PUBLICATION_STATUS_LABELS_ES[
                              get(this.state, 'propertyDetails.publicationStatus')
                            ]
                          : PROPERTY_PUBLICATION_STATUS_LABELS_ES.PUBLISHED
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailTitle' className='required'>
                      Título (Uso interno y para clientes)
                    </label>
                    <Input
                      style={{ width: '100%' }}
                      className='validate validate-required'
                      id='propertyDetailTitle'
                      name='title'
                      placeholder='Pruebas'
                      value={get(this.state, 'propertyDetails.title')}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailProviderReference'>Ref. Proveedor</label>
                    <Input
                      style={{ width: '100%' }}
                      id='propertyDetailProviderReference'
                      name='providerReference'
                      placeholder='PR123'
                      value={get(this.state, 'propertyDetails.providerReference')}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailAddress'>Dirección</label>
                    <GoogleMapsAutocomplete
                      handlePlaceChanged={this.handlePlaceChanged}
                      initValue={get(this.state, 'propertyDetails.address')}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailAddressComplement'>
                      Complemento de dirección
                    </label>
                    <Input
                      style={{ width: '100%' }}
                      id='propertyDetailAddressComplement'
                      name='address.addressComplement'
                      placeholder='n.3'
                      value={get(this.state, 'propertyDetails.address.addressComplement')}
                      onChange={this.handleAddressComplementChange}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='propertyDetailDescription'>Descripción</label>
                    <Input.TextArea
                      style={{ width: '100%' }}
                      id='propertyDetailDescription'
                      name='description'
                      autosize={{ minRows: 4 }}
                      placeholder='Es un inmueble ubicado en la playa...'
                      value={get(this.state, 'propertyDetails.description')}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-header'>Contactos</div>
                    <div className='card-block'>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label htmlFor='parent'>Contacto habitual</label>
                            <Select
                              name='usualContactBack'
                              className='form-control'
                              value={get(this.state, 'propertyDetails.usualContactBack.id')}
                              options={this.state.contactList}
                              onChange={value => {
                                this.handleSelectChange(
                                  'usualContactBack',
                                  value ? value.contact : null
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label htmlFor='parent'>Contacto para factura</label>
                            <Select
                              name='billerId'
                              className='form-control'
                              value={
                                this.state.propertyDetails.billerId
                                  ? this.state.propertyDetails.billerId
                                  : null
                              }
                              options={this.state.contactList}
                              onChange={value => {
                                this.handleSelectChange('billerId', value ? value.value : null);
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label htmlFor='parent'>Contacto para contrato</label>
                            <Select
                              name='contractorId'
                              className='form-control'
                              value={
                                this.state.propertyDetails.contractorId
                                  ? this.state.propertyDetails.contractorId
                                  : null
                              }
                              options={this.state.contactList}
                              onChange={value => {
                                this.handleSelectChange('contractorId', value ? value.value : null);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-header'>Disponibilidad</div>
                    <div className='card-block'>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailMinimumStay'>
                              Estancias de mínimo (días)
                            </label>
                            <InputNumber
                              id='propertyDetailMinimumStay'
                              name='minimumStay'
                              value={get(this.state, 'propertyDetails.minimumStay')}
                              min={0}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'minimumStay',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailMaximumStay'>
                              Estancias de máximo (días)
                            </label>
                            <InputNumber
                              id='propertyDetailMaximumStay'
                              name='maximumStay'
                              value={get(this.state, 'propertyDetails.maximumStay')}
                              min={0}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'maximumStay',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <PropertyAvailability
                        propertyId={this.state.propertyDetails.id}
                        providerId={get(this.state, 'propertyDetails.providerId')}
                        proposals={this.props.proposals}
                        iCalURL={this.state.propertyDetails.iCalURL}
                        iCalChangeHandler={this.handleChange}
                        currentUserName={this.props.currentUsername}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-header'>Precio</div>
                    <div className='card-block'>
                      <div className='row'>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailMonthlyPrice'>Precio mensual (€)</label>
                            <InputNumber
                              className='propertyInput'
                              id='propertyDetailMonthlyPrice'
                              name='monthlyPrice'
                              placeholder='0'
                              value={normalizeNumberToDecimals(monthlyPrice, 2)}
                              onChange={value =>
                                this.handlePriceChange({ target: { name: 'monthlyPrice', value } })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailDeposit'>Fianza (€)</label>
                            <InputNumber
                              id='propertyDetailDeposit'
                              name='deposit'
                              min={0}
                              placeholder='0'
                              value={
                                get(this.state, 'propertyDetails.deposit.amount')
                                  ? normalizeNumberToDecimals(
                                      get(this.state, 'propertyDetails.deposit.amount'),
                                      2
                                    )
                                  : ''
                              }
                              onChange={value =>
                                this.handlePriceChange({
                                  target: {
                                    name: 'deposit',
                                    value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label>&nbsp;</label>
                            <CheckboxButton
                              data-testid='chargesIncluded'
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailcChargesIncluded'
                              name='chargesIncluded'
                              handleChange={this.handleChange}
                              content='Suministros incluidos'
                              value={get(this.state, 'propertyDetails.chargesIncluded') || false}
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label
                              data-testid='spendingLimitLabel'
                              htmlFor='propertyDetailSpendingLimit'
                            >
                              {get(this.state, 'propertyDetails.chargesIncluded')
                                ? 'Límite de suministros (€)'
                                : 'Importe fijo suministros (€)'}
                            </label>
                            <InputNumber
                              data-testid='spendingLimit'
                              id='propertyDetailSpendingLimit'
                              name='spendingLimit'
                              min={0}
                              placeholder={
                                get(this.state, 'propertyDetails.chargesIncluded')
                                  ? 'Sin límite'
                                  : 'No incluido'
                              }
                              value={
                                get(this.state, 'propertyDetails.spendingLimit.amount')
                                  ? normalizeNumberToDecimals(
                                      get(this.state, 'propertyDetails.spendingLimit.amount'),
                                      2
                                    )
                                  : ''
                              }
                              onChange={value =>
                                this.handlePriceChange({
                                  target: {
                                    name: 'spendingLimit',
                                    value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailCleaningCharges'>
                              Gastos de limpieza(€)/mes
                            </label>
                            <InputNumber
                              id='propertyDetailCleaningCharges'
                              name='cleaningCharges'
                              placeholder='0'
                              min={0}
                              value={
                                get(this.state, 'propertyDetails.cleaningCharges.amount')
                                  ? normalizeNumberToDecimals(
                                      get(this.state, 'propertyDetails.cleaningCharges.amount'),
                                      2
                                    )
                                  : ''
                              }
                              onChange={value =>
                                this.handlePriceChange({
                                  target: {
                                    name: 'cleaningCharges',
                                    value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='cleaningChargeType'>Periodicidad limpieza</label>
                            <Select
                              name='cleaningChargeType'
                              className='form-control'
                              value={
                                this.state.propertyDetails.cleaningChargeType
                                  ? this.state.propertyDetails.cleaningChargeType
                                  : ''
                              }
                              options={this.state.options.cleaningChargeType}
                              onChange={value => {
                                this.handleSelectChange('cleaningChargeType', value.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <Collapse>
                            <Collapse.Panel header='Precios por temporada' key='1'>
                              <div className='row'>
                                <div className='col-sm-2'>
                                  <button
                                    type='button'
                                    className='btn btn-primary alignLeft'
                                    onClick={this.toggleNewPeriodPrice}
                                  >
                                    Nuevo periodo
                                  </button>
                                  <Modal
                                    visible={this.state.modalNewPeriodPrice}
                                    title='Nuevo periodo'
                                    width={860}
                                    onCancel={this.toggleNewPeriodPrice}
                                  >
                                    <FormNewPeriodPrice
                                      callbackFuncionOnCreate={this.handleNewPeriodPrice}
                                      periodPrice={this.state.newPeriodPrice}
                                      allPeriodPrices={this.state.propertyDetails.periodPrices}
                                    />
                                  </Modal>
                                </div>
                                <div className='col-sm-10 height50 hsCalendar'>
                                  <BigCalendar
                                    selectable
                                    events={
                                      this.state.formattedPeriodPrices
                                        ? this.state.formattedPeriodPrices
                                        : []
                                    }
                                    views={['month']}
                                    messages={{
                                      today: 'Hoy',
                                      previous: '<<',
                                      next: '>>',
                                    }}
                                    startAccessor='start'
                                    endAccessor='end'
                                    culture='es'
                                    eventPropGetter={this.eventStyleGetter}
                                    onSelectEvent={this.handleDeletePeriodPrice}
                                    onSelectSlot={this.handleNewSlotPeriodPrice}
                                    titleAccessor={this.eventTitleGetter}
                                  />
                                </div>
                              </div>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-header'>Caracteríticas</div>
                    <div className='card-block'>
                      <div className='row'>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailTenants'>Num. de Inquilinos</label>
                            <InputNumber
                              className='propertyInput'
                              id='propertyDetailTenants'
                              name='tenants'
                              placeholder='0'
                              min={0}
                              value={get(this.state, 'propertyDetails.tenants')}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'tenants',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailRooms'>Habitaciones</label>
                            <InputNumber
                              id='propertyDetailRooms'
                              name='rooms'
                              placeholder='0'
                              min={0}
                              value={get(this.state, 'propertyDetails.rooms')}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'rooms',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailBeds'>Camas</label>
                            <InputNumber
                              id='propertyDetailBeds'
                              name='beds'
                              placeholder='0'
                              min={0}
                              value={get(this.state, 'propertyDetails.beds')}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'beds',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailSofaBed'>Sofás Cama</label>
                            <InputNumber
                              id='propertyDetailSofaBed'
                              name='sofaBed'
                              placeholder='0'
                              value={get(this.state, 'propertyDetails.sofaBed')}
                              min={0}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'sofaBed',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailBathrooms'>Baños</label>
                            <InputNumber
                              id='propertyDetailBathrooms'
                              name='bathrooms'
                              placeholder='0'
                              value={get(this.state, 'propertyDetails.bathrooms')}
                              min={0}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'bathrooms',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                            <br />
                            <label htmlFor='propertyDetailToilets'>Aseos</label>
                            <InputNumber
                              id='propertyDetailToilets'
                              name='toilets'
                              placeholder='0'
                              value={get(this.state, 'propertyDetails.toilets')}
                              min={0}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'toilets',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <label htmlFor='propertyDetailSize'>Tamaño (m2)</label>
                            <InputNumber
                              id='propertyDetailSize'
                              name='size'
                              placeholder='0'
                              value={get(this.state, 'propertyDetails.size')}
                              min={0}
                              onChange={value =>
                                this.handleChange({
                                  target: {
                                    name: 'size',
                                    value,
                                    type: 'number',
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-header'>Comodidades</div>
                    <div className='card-block'>
                      <div className='row'>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailTv'
                              name='tv'
                              handleChange={this.handleChange}
                              content='TV'
                              value={
                                this.state.propertyDetails.tv
                                  ? this.state.propertyDetails.tv
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailWifi'
                              name='wifi'
                              handleChange={this.handleChange}
                              content='WIFI'
                              value={
                                this.state.propertyDetails.wifi
                                  ? this.state.propertyDetails.wifi
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailSmokingAllowed'
                              name='smokingAllowed'
                              handleChange={this.handleChange}
                              content='Se permite fumar'
                              value={
                                this.state.propertyDetails.smokingAllowed
                                  ? this.state.propertyDetails.smokingAllowed
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailLift'
                              name='lift'
                              handleChange={this.handleChange}
                              content='Ascensor'
                              value={
                                this.state.propertyDetails.lift
                                  ? this.state.propertyDetails.lift
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailAirConditioning'
                              name='airConditioning'
                              handleChange={this.handleChange}
                              content='Aire acondicionado'
                              value={
                                this.state.propertyDetails.airConditioning
                                  ? this.state.propertyDetails.airConditioning
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailHeating'
                              name='heating'
                              handleChange={this.handleChange}
                              content='Calefacción'
                              value={
                                this.state.propertyDetails.heating
                                  ? this.state.propertyDetails.heating
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <hr className='hs-hr' />
                      <div className='row'>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailOven'
                              name='oven'
                              handleChange={this.handleChange}
                              content='Horno'
                              value={
                                this.state.propertyDetails.oven
                                  ? this.state.propertyDetails.oven
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailMicrowaveOven'
                              name='microwaveOven'
                              handleChange={this.handleChange}
                              content='Microondas'
                              value={
                                this.state.propertyDetails.microwaveOven
                                  ? this.state.propertyDetails.microwaveOven
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailKitchenware'
                              name='kitchenware'
                              handleChange={this.handleChange}
                              content='Menaje'
                              value={
                                this.state.propertyDetails.kitchenware
                                  ? this.state.propertyDetails.kitchenware
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailWasherMachine'
                              name='washerMachine'
                              handleChange={this.handleChange}
                              content='Lavadora'
                              value={
                                this.state.propertyDetails.washerMachine
                                  ? this.state.propertyDetails.washerMachine
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailDryerMachine'
                              name='dryerMachine'
                              handleChange={this.handleChange}
                              content='Secadora'
                              value={
                                this.state.propertyDetails.dryerMachine
                                  ? this.state.propertyDetails.dryerMachine
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailIron'
                              name='iron'
                              handleChange={this.handleChange}
                              content='Plancha'
                              value={
                                this.state.propertyDetails.iron
                                  ? this.state.propertyDetails.iron
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailTowels'
                              name='towels'
                              handleChange={this.handleChange}
                              content='Toallas'
                              value={
                                this.state.propertyDetails.towels
                                  ? this.state.propertyDetails.towels
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailBedding'
                              name='bedding'
                              handleChange={this.handleChange}
                              content='Ropa de cama'
                              value={
                                this.state.propertyDetails.bedding
                                  ? this.state.propertyDetails.bedding
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailPetsAllowed'
                              name='petsAllowed'
                              handleChange={this.handleChange}
                              content='Se permite mascotas'
                              value={
                                this.state.propertyDetails.petsAllowed
                                  ? this.state.propertyDetails.petsAllowed
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <hr className='hs-hr' />
                      <div className='row'>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailFreeCarPark'
                              name='freeCarPark'
                              handleChange={this.handleChange}
                              content='Parking gratuito'
                              value={
                                this.state.propertyDetails.freeCarPark
                                  ? this.state.propertyDetails.freeCarPark
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailPearbyCarPark'
                              name='pearbyCarPark'
                              handleChange={this.handleChange}
                              content='Parking de pago'
                              value={
                                this.state.propertyDetails.pearbyCarPark
                                  ? this.state.propertyDetails.pearbyCarPark
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-2'>
                          <div className='form-group'>
                            <CheckboxButton
                              borderStyle='propertyCheckboxActive'
                              id='propertyDetailEasyParking'
                              name='easyParking'
                              handleChange={this.handleChange}
                              content='Facilidad para aparcar'
                              value={
                                this.state.propertyDetails.easyParking
                                  ? this.state.propertyDetails.easyParking
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        <Modal
          title='Clonando inmueble'
          visible={this.state.modalCloneProgress}
          width={860}
          onCancel={this.toggleCloneProgress}
          footer={null}
        >
          <ProgressCloneProperty
            totalCopies={this.state.totalCopies}
            callbackFuncionOnCreate={this.toggleCloneProgress}
          />
        </Modal>
        <style jsx>{`
          .ant-input-number {
            width: 100% !important;
          }
          .copy-icon {
            cursor: pointer;
            font-size: 20px;
          }
        `}</style>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      savePropertyDetailsData: data => dispatch(savePropertyDetailsData(data)),
      uploadPropertyImages: files => dispatch(uploadPropertyImages(files)),
      getProviderBaseContactAndChildren: url => dispatch(getProviderBaseContactAndChildren(url)),
      getNewPropertyLocation: () => dispatch(getNewPropertyLocation()),
    },
  };
}

function mapStateToProps(state) {
  return {
    propertyDetailsData: state.PropertyDetails,
    propertyDetailsSubmit: state.PropertyDetails.submit,
    propertyDetailsImagesSubmit: state.PropertyDetails.submitImages,
    optionLabels: state.Preload.optionLabels,
    metasource: state.PropertyDetails.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    providerBaseContactAndChildren: state.ProviderDetails.baseContact.detail,
    bondedDeal: state.DealDetails.bondedDeal,
    currentUsername: state.Preload.currentUserInformation.username,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPropertyDetails);
