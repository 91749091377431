import { browserHistory } from 'react-router';
import {
  NEW_CONTACT_LOCATION_ERROR,
  NEW_CONTACT_LOCATION_SUCCESS,
  NEW_PROVIDER_CREATE_ERROR,
  PROVIDERBASECONTACTANDCHILDREN_DATA_ERROR,
  PROVIDERBASECONTACTANDCHILDREN_DATA_SUCCESS,
  PROVIDERCONTACT_ETAG_SUCCESS,
  PROVIDERCONTACT_SUBMIT_ERROR,
  PROVIDERCONTACT_SUBMIT_SUCCESS,
  PROVIDERCONTACTS_DATA_ERROR,
  PROVIDERCONTACTS_DATA_SUCCESS,
  PROVIDERDETAILS_DATA_ERROR,
  PROVIDERDETAILS_DATA_SUCCESS,
  PROVIDERDETAILS_DELETE_ERROR,
  PROVIDERDETAILS_METASOURCE_ERROR,
  PROVIDERDETAILS_METASOURCE_SUCCESS,
  PROVIDERDETAILS_RESET,
  PROVIDERDETAILS_RESET_CREATE_ERROR,
  PROVIDERDETAILS_SUBMIT_ERROR,
  PROVIDERDETAILS_SUBMIT_SUCCESS,
} from '../../shared/actionTypes';
import ProviderDetailsApi from './ProviderDetailsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink, normalizeChildrenContacts } from '../../shared/utils/appUtils';
import { deleteContactError, deleteContactSuccess } from '../ContactDetails/ContactDetailsActions';
import ContactDetailsApi from '../ContactDetails/ContactDetailsApi';

export const resetState = () => ({
  type: PROVIDERDETAILS_RESET,
});

export const metasourceSuccess = metasourceLinks => ({
  type: PROVIDERDETAILS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: PROVIDERDETAILS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const providerDetailsDataSuccess = (data, url, etag) => ({
  type: PROVIDERDETAILS_DATA_SUCCESS,
  providerDetailsData: data,
  url,
  etag,
});

export const providerDetailsDataError = errorMessage => ({
  type: PROVIDERDETAILS_DATA_ERROR,
  providerDetailsErrorDetails: errorMessage,
});

export const providerContactsDataSuccess = data => ({
  type: PROVIDERCONTACTS_DATA_SUCCESS,
  providerContactsData: data,
});

export const providerContactsDataError = errorMessage => ({
  type: PROVIDERCONTACTS_DATA_ERROR,
  providerContactsErrorDetails: errorMessage,
});

export const providerBaseContactAndChildrenDataSuccess = data => ({
  type: PROVIDERBASECONTACTANDCHILDREN_DATA_SUCCESS,
  providerBaseContactAndChildrenData: data,
});

export const providerBaseContactAndChildrenDataError = errorMessage => ({
  type: PROVIDERBASECONTACTANDCHILDREN_DATA_ERROR,
  providerBaseContactAndChildrenErrorDetails: errorMessage,
});

export const providerDetailsSubmitSuccess = etag => ({
  type: PROVIDERDETAILS_SUBMIT_SUCCESS,
  etag,
});

export const providerDetailsSubmitError = errorMessage => ({
  type: PROVIDERDETAILS_SUBMIT_ERROR,
  providerDetailsSubmitErrorDetails: errorMessage,
});

export const providerContactEtagSuccess = etag => ({
  type: PROVIDERCONTACT_ETAG_SUCCESS,
  etag,
});

export const providerContactSubmitSuccess = etag => ({
  type: PROVIDERCONTACT_SUBMIT_SUCCESS,
  etag,
});

export const providerContactSubmitError = errorMessage => ({
  type: PROVIDERCONTACT_SUBMIT_ERROR,
  providerContactSubmitErrorDetails: errorMessage,
});

export const createNewProviderError = errorMessage => ({
  type: NEW_PROVIDER_CREATE_ERROR,
  createNewProviderErrorDetails: errorMessage,
});

export const newContactLocationSuccess = location => ({
  type: NEW_CONTACT_LOCATION_SUCCESS,
  location,
});

export const newContactLocationError = errorMessage => ({
  type: NEW_CONTACT_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const deleteProviderSuccess = () => {
  const data = {};

  return providerContactsDataSuccess(data);
};

export const deleteProviderError = errorMessage => ({
  type: PROVIDERDETAILS_DELETE_ERROR,
  errorDetail: errorMessage,
});

export const resetCreateProviderError = () => ({
  type: PROVIDERDETAILS_RESET_CREATE_ERROR,
});

export const getProviderDetailsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().ProviderDetails;
    const { metasourceStrings } = getState().Preload;
    const urlReduced = getMetasourceLink(
      metasource,
      metasourceStrings,
      'PROVIDER_DETAIL_METASOURCE_PROVIDER_REDUCED'
    );

    if (!urlReduced || !urlReduced.href) {
      return null;
    }

    return ProviderDetailsApi.getProviderDetailsData(urlReduced.href)
      .then(response => {
        const { etag } = response.headers;

        dispatch(providerDetailsDataSuccess(response.data.provider, urlReduced.href, etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(providerDetailsDataError(extractErrorMessage(error)));
      });
  };

export const showOrLoadProviderDetailsView = id =>
  function(dispatch, getState) {
    const url = getState().Preload.mainMetasource.providers.href;

    return ProviderDetailsApi.getMetasource(url, id)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
        dispatch(getProviderDetailsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const getProviderContactsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().ProviderDetails;
    const { metasourceStrings } = getState().Preload;
    const urlChildren = getMetasourceLink(
      metasource,
      metasourceStrings,
      'PROVIDER_DETAIL_METASOURCE_PROVIDER_CHILDREN'
    );

    if (!urlChildren) {
      return null;
    }

    return ProviderDetailsApi.getProviderDetailsData(urlChildren.href)
      .then(response => {
        const normalizedContacts = normalizeChildrenContacts(
          response.data.provider.baseContact.contact.children
        );

        dispatch(providerContactsDataSuccess(normalizedContacts));
      })
      .catch(error => {
        console.error(error);
        dispatch(providerContactsDataError(extractErrorMessage(error)));
      });
  };

export const getProviderBaseContactAndChildren = url =>
  function(dispatch) {
    return ProviderDetailsApi.getProviderDetailsData(url)
      .then(response => {
        const normalizedContacts = normalizeChildrenContacts(
          new Array(response.data.provider.baseContact)
        );

        dispatch(providerBaseContactAndChildrenDataSuccess(normalizedContacts));
      })
      .catch(error => {
        console.error(error);
        dispatch(providerBaseContactAndChildrenDataError(extractErrorMessage(error)));
      });
  };

export const saveProviderDetailsData = () =>
  function(dispatch, getState) {
    const providerDetailsData = getState().ProviderDetails.data.detail;
    const { etag } = getState().ProviderDetails.data;
    const { url } = getState().ProviderDetails.data;

    return ProviderDetailsApi.saveProviderDetailsData(url, providerDetailsData, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(providerDetailsSubmitSuccess(etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(providerDetailsSubmitError(extractErrorMessage(error)));
      });
  };

export const saveProviderContactData = (data, url) =>
  function(dispatch, getState) {
    const { etag } = getState().ProviderDetails.contact;
    const { parent } = data;

    return ProviderDetailsApi.saveProviderContactData(url, data, parent, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(providerContactSubmitSuccess(etag));
        dispatch(getProviderContactsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(providerContactSubmitError(extractErrorMessage(error)));
      });
  };

export const deleteProviderContactData = contact => {
  if (contact) {
    return function(dispatch) {
      const url = contact._links.self.href;

      // 1. Se recupera el contacto para obtener el version
      return ContactDetailsApi.getContactDetailsData(url)
        .then(response => {
          const { etag } = response.headers;

          // 2. Se elimina el contacto con el etag recuperado
          return ContactDetailsApi.doDelete(url, etag)
            .then(() => {
              dispatch(deleteContactSuccess());
              dispatch(getProviderContactsData());
            })
            .catch(error => {
              console.error(error);
              dispatch(deleteContactError(extractErrorMessage(error)));
            });
        })
        .catch(error => {
          console.error(error);
          dispatch(deleteContactError(extractErrorMessage(error)));
        });
    };
  }
};

export const getProviderContactEtag = url =>
  function(dispatch) {
    return ProviderDetailsApi.getProviderDetailsData(url)
      .then(response => {
        const { etag } = response.headers;

        dispatch(providerContactEtagSuccess(etag));
      })
      .catch(error => {
        console.error(error);
      });
  };

export const createNewProvider = (url, providerDetailsData) =>
  function(dispatch) {
    return ProviderDetailsApi.saveProviderDetailsData(url, providerDetailsData)
      .then(response => {
        browserHistory.push(`/providerDetails/${response.data.provider.id}`);
      })
      .catch(error => {
        console.error(error);
        dispatch(createNewProviderError(extractErrorMessage(error)));
      });
  };

export const getNewContactLocation = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().ProviderDetails.metasource,
      getState().Preload.metasourceStrings,
      'PROVIDER_DETAIL_METASOURCE_NEW_CONTACT'
    );

    return ProviderDetailsApi.getNewContactLocation(url.href)
      .then(location => {
        dispatch(newContactLocationSuccess(location.headers.location));
      })
      .catch(error => {
        console.error(error);
        dispatch(newContactLocationError(extractErrorMessage(error)));
      });
  };

export const deleteProvider = (url, etag) => {
  if (url) {
    return function(dispatch) {
      return ProviderDetailsApi.doDelete(url, etag)
        .then(() => {
          dispatch(deleteProviderSuccess());
          browserHistory.push('/providers');
        })
        .catch(error => {
          console.error(error);
          dispatch(deleteProviderError(extractErrorMessage(error)));
        });
    };
  }
};
