import React, { useState, useEffect, useCallback } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { ENDPOINT } from '../../shared/appConfig';
import api from '../../shared/utils/api';

const { Option } = Select;

const getCompanyList = companies => {
  const companyList = [];

  if (!isEmpty(companies)) {
    for (let i = 0; i < companies.length; i++) {
      const company = companies[i];
      const name = get(company, 'company.baseContact.name') || '[Sin nombre]';
      const surname = get(company, 'company.baseContact.lastOTradeName') || '[Sin apellidos]';
      const type = get(company, 'company.companyType');

      let labelValue = '';

      if (type === 'PERSON') {
        labelValue = '(Persona física) - ';
      } else if (type === 'COMPANY') {
        labelValue = '(Persona jurídica) - ';
      }

      labelValue += `${name} ${surname}`;

      companyList.push({
        label: labelValue,
        value: get(company, 'company.id'),
        data: get(company, 'company'),
      });
    }
  }

  return companyList;
};

const getContactList = contacts => {
  let contactList = [];

  if (!isEmpty(contacts)) {
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      const name = get(contact, 'contact.name') || '[Sin nombre]';
      const surname = get(contact, 'contact.lastOTradeName') || '[Sin apellidos]';
      const email = get(contact, 'contact.email') || '[Sin email]';
      const type = get(contact, 'contact.contactType');

      let label = '';

      if (type === 'PERSON') {
        label = `${setClientOrProviderLabel(
          contact
        )} (Persona física) - ${name} ${surname} ${email}`;
      } else {
        label = `${setClientOrProviderLabel(
          contact
        )} (Persona jurídica) - ${name} ${surname} ${email}`;
      }

      contactList.push({
        label,
        value: get(contact, 'contact.id'),
        data: get(contact, 'contact'),
      });

      if (contact.children) {
        contactList = contactList.concat(getContactList(contact.children));
      }
    }
  }

  return contactList;
};

const setClientOrProviderLabel = data => {
  if (get(data, 'relatedProvider')) {
    return '(Proveedor) -';
  } else if (get(data, 'relatedCompany')) {
    return '(Cliente) -';
  }

  return '';
};

const SelectLazy = ({
  filter = '',
  value: defaultValue = undefined,
  type = 'company',
  placeholder = 'Introduce un nombre',
  ...props
}) => {
  const [previousId, setPreviousId] = useState('');
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (type === 'company') {
      const clientUrl = defaultValue ? getUrlForDetail() : getUrlForList();
      fetchList(clientUrl);
    }

    if (type === 'contact' && !isEmpty(filter)) {
      const contactUrl = defaultValue ? getUrlForDetail() : getUrlForList();
      fetchList(contactUrl);
    }
  }, [defaultValue, fetchList, filter, getUrlForDetail, getUrlForList, type]);

  const getUrlForList = useCallback(() => `${ENDPOINT}/${type}/page/0?size=15${filter}`, [
    filter,
    type,
  ]);
  const getUrlForDetail = useCallback(() => `${ENDPOINT}/${type}/${defaultValue}`, [
    defaultValue,
    type,
  ]);

  const fetchList = useCallback(
    async url => {
      const data = await api.request(url);
      const res =
        defaultValue && previousId !== defaultValue
          ? [{ [type]: data.json[type] }]
          : data.json[`${type}List`];

      if (isEmpty(res)) {
        setIsLoading(false);
        return;
      }

      let values;
      if (type === 'company') {
        values = getCompanyList(res);
      }

      if (type === 'contact') {
        values = getContactList(res);
      }

      setOptions(values);
      setIsLoading(false);

      if (defaultValue) {
        setValue(values);
        setPreviousId(defaultValue);
      }
    },
    [defaultValue, previousId, type]
  );

  const handleChange = (value, option) => {
    setValue(value);

    if (props.onChange) {
      const entityId = get(value, 'key') || '';
      const entityData = get(option, 'props.data-info');

      props.onChange(entityId, entityData);
    }
  };

  const handleSearch = value => {
    setOptions([]);
    setIsLoading(true);
    setValue([]);

    const url = `${getUrlForList()}&globalSearch=${value
      .replace('&', '%26')
      .replace('=', '%3D')
      .replace('+', '%2B')}`;

    fetchList(url);
  };

  const debouncedSearch = debounce(handleSearch, 800);

  return (
    <Select
      allowClear
      showSearch
      labelInValue
      dropdownMatchSelectWidth={false}
      value={value}
      placeholder={placeholder}
      notFoundContent={isLoading ? <Spin size='small' /> : <div>Sin resultados</div>}
      filterOption={false}
      onSearch={el => {
        if (el.length >= 3) {
          debouncedSearch(el);
        }
      }}
      onChange={(value, option) => {
        handleChange(value, option);
      }}
      style={{ width: '100%' }}
    >
      {options.map(d => (
        <Option key={d.value} data-info={d.data}>
          {d.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectLazy;
