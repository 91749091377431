import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getOptionsForSelect, openNotificationWithIcon } from '../../shared/utils/appUtils';
import { pairRoundAmount } from '../../shared/utils/formUtils';
import { setInvoiceDetailsEntries } from '../../modules/InvoiceDetails/InvoiceDetailsActions';
import isNil from 'lodash/isNil';

class TableDataInvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.invoiceDetailsData ? props.invoiceDetailsData.entries : null,
      cachedData: props.invoiceDetailsData ? props.invoiceDetailsData.entries : null,
      options: {
        taxType: getOptionsForSelect(this.props.optionLabels, 'TaxType'),
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.deleteEntry = this.deleteEntry.bind(this);
    this.addEntry = this.addEntry.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.invoiceDetailsData &&
      JSON.stringify(this.state.data) !== JSON.stringify(nextProps.invoiceDetailsData.entries)
    ) {
      this.setState({
        data: nextProps.invoiceDetailsData.entries,
      });
    }
  }

  handleChange(e, config) {
    const newData = [...this.state.data];
    const target = newData[config.index];

    if (target) {
      target[e.target.name] = config.isNumeric ? parseFloat(e.target.value) : e.target.value;
      // la modificaciones se tienen que hacer en el estado de redux ya que afectan al componente FormInoviceDetails
      this.props.actions.setInvoiceDetailsEntries(newData);
    }
  }

  handlePriceChange(e, config) {
    const newData = [...this.state.data];
    const target = newData[config.index];

    if (target) {
      target[e.target.name] = {
        amount: e.target.value,
        currency: config.currency,
      };
      // la modificaciones se tienen que hacer en el estado de redux ya que afectan al componente FormInoviceDetails
      this.props.actions.setInvoiceDetailsEntries(newData);
    }
  }

  handleSelectChange(e, config) {
    const newData = [...this.state.data];
    const target = newData[config.index];

    if (target) {
      if (e.value) {
        target[config.field] = e.value;
      } else {
        target[config.field] = null;
      }

      if (e.value === 'NO_TAX') {
        target.taxPercentage = '';
      }

      // la modificaciones se tienen que hacer en el estado de redux ya que afectan al componente FormInoviceDetails
      this.props.actions.setInvoiceDetailsEntries(newData);
    }
  }

  deleteEntry(e, config) {
    if (this.state.data.length === 1) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        'No podemos dejar la factura sin líneas... ¿Qué vamos a cobrar entonces?'
      );

      return;
    }

    if (window.confirm('¿Seguro que quieres borrar esta línea de factura?')) {
      const newData = [...this.state.data];

      newData.splice(config.index, 1);
      // la modificaciones se tienen que hacer en el estado de redux ya que afectan al componente FormInoviceDetails
      this.props.actions.setInvoiceDetailsEntries(newData);
    }
  }

  addEntry() {
    const newData = this.state.data ? [...this.state.data] : [];
    const newEntry = {
      description: null,
      price: null,
      taxPercentage: null,
      taxType: null,
      units: 1,
      discountPercentage: 0,
    };

    newData.push(newEntry);
    // la modificaciones se tienen que hacer en el estado de redux ya que afectan al componente FormInoviceDetails
    this.props.actions.setInvoiceDetailsEntries(newData);
  }

  render() {
    const disabled =
      this.props.invoiceDetailsData && this.props.invoiceDetailsData.invoiceState !== 'CREATED';

    return (
      <div className='row'>
        <div className='col-sm-2'>
          <button
            type='button'
            disabled={disabled}
            className='btn btn-primary btn-block alignLeft buttonBreakLine'
            onClick={this.addEntry}
          >
            Nueva línea de factura
          </button>
        </div>
        <div className='col-sm-10'>
          {this.state.data && this.state.data.length > 0 && (
            <div className='row colorWhite invoiceBackgroundColor p-1 mb-1'>
              <div className='col-sm-5 p-0'>Concepto</div>
              <div className='col-sm-2 text-right'>Base imponible</div>
              <div className='col-sm-2 text-right'>Tipo impositivo</div>
              <div className='col-sm-2 text-right'>Impuesto</div>
            </div>
          )}
          {this.state.data &&
            this.state.data.length > 0 &&
            this.state.data.map((entry, index) => (
              <div className='row' key={`invoiceEntry${index}`}>
                <div className='col-sm-5 p-0 margin1'>
                  <textarea
                    className='form-control validate validate-required'
                    id={`description_${index}`}
                    name='description'
                    rows='4'
                    placeholder='Concepto'
                    disabled={disabled}
                    value={entry.description ? entry.description : ''}
                    onChange={e => this.handleChange(e, { index })}
                  />
                </div>
                <div className='col-sm-2 p-0 margin1'>
                  <input
                    type='number'
                    className='form-control validate validate-required'
                    id={`price_${index}`}
                    name='price'
                    placeholder='100'
                    disabled={disabled}
                    value={entry.price ? pairRoundAmount(entry.price.amount) : ''}
                    onChange={e => this.handlePriceChange(e, { index, currency: 'EUR' })}
                  />
                </div>
                <div className='col-sm-2 p-0 margin1'>
                  <input
                    type='number'
                    className='form-control'
                    id={`taxPercentage_${index}`}
                    name='taxPercentage'
                    value={!isNil(entry.taxPercentage) ? pairRoundAmount(entry.taxPercentage) : ''}
                    disabled={disabled || entry.taxType === 'NO_TAX'}
                    onChange={e => this.handleChange(e, { index, isNumeric: true })}
                  />
                </div>
                <div className='col-sm-2 p-0 margin1'>
                  <Select
                    id={`taxType_${index}`}
                    name='taxType'
                    className='form-control'
                    disabled={disabled}
                    value={entry.taxType ? entry.taxType : ''}
                    options={this.state.options.taxType}
                    onChange={e => {
                      this.handleSelectChange(e, { index, field: 'taxType' });
                    }}
                  />
                </div>
                <div className='col-sm-1' style={{ marginTop: '10px', marginRight: '-10px' }}>
                  {!disabled && (
                    <a id={`deleteAction_${index}`} onClick={e => this.deleteEntry(e, { index })}>
                      {' '}
                      <i className='icon-trash' />{' '}
                    </a>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setInvoiceDetailsEntries: entries => dispatch(setInvoiceDetailsEntries(entries)),
    },
  };
}

function mapStateToProps(state) {
  return {
    invoiceDetailsData: state.InvoiceDetails.data.detail,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableDataInvoiceDetails);
