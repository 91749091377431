import React, { useState, useEffect } from 'react';
import BigCalendar from 'react-big-calendar';
import { Link } from 'react-router';
import { Button, Col, Modal, Row, Tooltip } from 'antd';
import FormNewReservation from '../FormNewReservation';
import moment from 'moment';
import { DATE_FORMAT, PARSE_DATE_FORMAT } from '../../shared/appConfig';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import { AVAILABILITY_ORIGIN } from 'hs-utils';
import AvailabilityApi from '../../modules/Availability/AvailabilityApi';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { showOrLoadDealDetailsView } from '../../modules/DealDetails/DealDetailsActions';

BigCalendar.momentLocalizer(moment);

const PropertyAvailability = ({
  propertyId,
  providerId,
  proposals,
  iCalURL,
  iCalChangeHandler,
  currentUserName,
}) => {
  const [showNewReservation, setShowNewReservation] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchReservations = async (propertyId, providerId) => {
      try {
        const result = await AvailabilityApi.getReservations(propertyId, providerId);
        setReservations(result);
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error al recuperar las reservas de este inmueble`
        );
        console.error('PropertyAvailability: error fetching property reservations', e);
      }
    };
    if (propertyId && providerId) {
      fetchReservations(propertyId, providerId);
    }
  }, [propertyId, providerId]);

  useEffect(() => {
    const proposalsDetails = get(proposals, 'data');
    if (!isEmpty(reservations) && !isEmpty(proposalsDetails)) {
      for (const reservation of reservations) {
        for (const proposal of proposalsDetails) {
          if (reservation.proposalId === proposal.proposal.id) {
            reservation.reservationInfo = `${proposal.proposal.dealIdentificationCode} - ${proposal.proposal.title}`;
            reservation.dealId = proposal.proposal.dealId;
          }
        }
      }
    }

    setReservations(reservations || []);
  }, [reservations, proposals]);

  const handleNewReservation = async (start, end) => {
    try {
      setShowNewReservation(false);
      setSubmitting(true);

      if (
        window.confirm(
          `¿Quieres registrar la reserva del ${moment(start).format(DATE_FORMAT)} al ${moment(
            end
          ).format(DATE_FORMAT)} ?`
        )
      ) {
        const newReservation = {
          start: moment(start).format(PARSE_DATE_FORMAT),
          end: moment(end).format(PARSE_DATE_FORMAT),
          origin: AVAILABILITY_ORIGIN.PROPERTY_DETAIL,
          createdBy: currentUserName,
          dummyId: Date.now(),
        };

        const res = await AvailabilityApi.saveReservation(propertyId, newReservation, providerId);

        reservations.push(res);
        setReservations(reservations);
      }
    } catch (e) {
      openNotificationWithIcon('error', 'Opps!! :(', `Ha ocurrido un error al crear la reserva`);
      console.error(e);
    }
    setSubmitting(false);
  };

  const handleNewSlotReservation = slotInfo => {
    const start = slotInfo.start;
    const end = slotInfo.end;

    handleNewReservation(start, end);
  };

  const handleDeleteReservation = event => {
    try {
      if (event.origin !== 'EXTERNAL') {
        if (event.proposalId) {
          openNotificationWithIcon(
            'error',
            'Opps!! :(',
            'Esta reserva esta vinculada a una propuesta!!!'
          );
        } else {
          if (
            !window.confirm(
              `¿Quieres borrar la reserva del ${moment(event.start).format(
                DATE_FORMAT
              )} al ${moment(event.end).format(DATE_FORMAT)} ?`
            )
          ) {
            return;
          }

          AvailabilityApi.deleteReservation(propertyId, event.reservationId, providerId);
          const newReservations = reservations.filter(r => r.reservationId !== event.reservationId);
          setReservations(newReservations);
        }
      }
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        `Ha ocurrido un error al eliminar esta reserva`
      );
      console.error('PropertyAvailability: error deleteing reservation', e);
    }
  };

  const renderReservationInfo = e => {
    if (e.reservationInfo) {
      return (
        <Tooltip placement='topLeft' title='Haz clic para ir a la oportunidad'>
          <Link
            className='whiteLink'
            onClick={event => {
              event.stopPropagation();
              if (e.dealId) {
                dispatch(showOrLoadDealDetailsView(e.dealId, true));
              } else {
                openNotificationWithIcon(
                  'error',
                  'Opps!! :(',
                  'No es posible alcanzar la oportunidad'
                );
              }
            }}
          >
            <i className='icon-basket-loaded' /> &nbsp;{e.reservationInfo}
          </Link>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='form-group'>
            <label htmlFor='propertyDetailICal'>iCal</label>
            <input
              type='text'
              className='form-control validate validate-valid-url'
              id='propertyDetailICalUrl'
              name='iCalURL'
              placeholder='http://ical'
              value={iCalURL || ''}
              onChange={iCalChangeHandler}
            />
          </div>
          <hr className='hs-hr' />
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <p>
              Para <b>registrar</b> una <b>reserva interna</b> en el Inmueble, haz clic en
              &quot;Nueva reserva&quot;, o selecciona en el calendario los días para los que quieres
              indicar que el inmueble está ocupado.
              <br /> Para <b>eliminar</b> la <b>reserva interna</b> haz clic en el bloque verde
              generado en el calendario, y confirma que estás seguro de realizar la acción.
            </p>
          </div>
        </div>
      </div>
      <Row>
        <Col span={4}>
          <Button className='btn btn-primary alignLeft' onClick={() => setShowNewReservation(true)}>
            Añadir disponibilidad
          </Button>
          <Modal
            visible={showNewReservation}
            title='Añadir disponibilidad'
            footer={null}
            onCancel={() => setShowNewReservation(false)}
          >
            <FormNewReservation
              callbackFuncionOnCreate={handleNewReservation}
              submitting={submitting}
            />
          </Modal>

          <h6>Tipo de reservas:</h6>
          <div>
            <b>
              <u>Reservas internas</u>
            </b>
            <div
              style={{
                marginLeft: '15px',
              }}
            >
              <div>
                <span>Creada por usuario</span>{' '}
                <Tooltip
                  placement='topLeft'
                  title='Reserva generada desde Homyspace y creada manualmente'
                >
                  <i className='far fa-question-circle' />
                </Tooltip>
                <span
                  className='badge'
                  style={{
                    backgroundColor: 'rgb(17, 138, 112)',
                    width: '100px',
                    height: '10px',
                  }}
                >
                  {' '}
                </span>
              </div>
              <div>
                <span>Vinculado a Propuesta</span>{' '}
                <Tooltip
                  placement='topLeft'
                  title='Reserva generada desde Homyspace a través de la aceptación de una Propuesta. Es el código de la Oportunidad más el título de la Propuesta'
                >
                  <i className='far fa-question-circle' />
                </Tooltip>
                <span
                  className='badge'
                  style={{
                    backgroundColor: '#276377',
                    width: '100px',
                    height: '10px',
                  }}
                >
                  {' '}
                </span>
              </div>
            </div>
            <br />
            <div>
              <b>
                <u>Reservas externas</u>{' '}
                <Tooltip
                  placement='topLeft'
                  title='Reserva proveniente de un canal externo. P.ej: Airbnb, Homeaway, Booking...'
                >
                  <i className='far fa-question-circle' />
                </Tooltip>
              </b>
              <span
                className='badge'
                style={{
                  backgroundColor: 'gray',
                  width: '100px',
                  height: '10px',
                }}
              >
                {' '}
              </span>
            </div>
          </div>
        </Col>
        <Col span={20} className='height50 hsCalendar'>
          <BigCalendar
            selectable
            events={reservations ? reservations : []}
            views={['month']}
            messages={{
              today: 'Hoy',
              previous: '<<',
              next: '>>',
            }}
            titleAccessor={renderReservationInfo}
            startAccessor='start'
            endAccessor='end'
            culture='es'
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleDeleteReservation}
            onSelectSlot={handleNewSlotReservation}
            popup
            popupOffset={{
              x: 50,
              y: 30,
            }}
          />
        </Col>
      </Row>
      <style jsx>{`
        .rbc-calendar {
          height: 90% !important;
        }
      `}</style>
    </>
  );
};

const eventStyleGetter = event => {
  let backColor;

  if (event.origin === 'EXTERNAL') {
    backColor = 'gray';
  } else if (event.proposalId) {
    backColor = '#23586c';
  } else {
    backColor = 'rgb(17, 138, 112)';
  }

  const style = {
    backgroundColor: backColor,
    borderRadius: '0px',
    opacity: 0.8,
    color: 'black',
    border: '0px',
    display: 'block',
    height: '2.5vh',
    fontWeight: event.price ? 'bold' : 'unset',
    marginTop: '1px',
  };

  return {
    style,
  };
};

export default PropertyAvailability;
