import React, { Component } from 'react';
import { connect } from 'react-redux';
import { preloadApp } from '../../modules/Preload/PreloadActions';
import messages from './messages.json';
import './Preload.css';

class Preload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.randomQuote(),
    };

    this.props.actions.preloadApp();
  }

  randomIntFromInterval = length => Math.floor(Math.random() * length);

  getMessageForDay = day => messages[day][this.randomIntFromInterval(messages[day].length)];

  randomQuote = () => {
    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date().getDay()];

    let messageToShow;

    switch (weekday) {
      case 'Mon':
      case 'Fri':
      case 'Sat':
      case 'Sun': {
        messageToShow =
          Math.round(Math.random() * 3) <= 2
            ? this.getMessageForDay('Random')
            : this.getMessageForDay(weekday);
        break;
      }

      default: {
        messageToShow = this.getMessageForDay('Random');
        break;
      }
    }

    return messageToShow;
  };

  render() {
    return (
      <div className='preload-body'>
        <div className='preload-message' dangerouslySetInnerHTML={{ __html: this.state.message }} />
        <div className='height75 loading-background-hs rotate-center' />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      preloadApp: () => dispatch(preloadApp()),
    },
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Preload);
