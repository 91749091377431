import { ENDPOINT, ENDPOINT_DOSSIER, ENDPOINT_V2 } from './appConfig';
import { createQueryString } from './utils/apiUtils';
// import isEmpty from 'lodash/isEmpty';

// PROVIDER
const providerWithChildren = `${ENDPOINT}/provider/{id}/children`;
const updateLeadStatusOfProvider = `${ENDPOINT_V2}/providers/{id}/availability`;

// PMAs
const pmaCities = `${ENDPOINT}/pma/city`;
const pmaZipCodes = `${ENDPOINT}/pma/city`;
const pmaAveragesData = `${ENDPOINT}/pma/averages`;
const pmaGroupedData = `${ENDPOINT}/pma/grouped`;

// PROPOSAL
const proposalsFiltered = `${ENDPOINT_V2}/proposals`;

// DOSSIER
// const dossier = `${ENDPOINT_DOSSIER}/{dealId}`;
const newDossier = `${ENDPOINT_DOSSIER}/opportunities/{dealId}`;
// const dossierAndProposal = `${ENDPOINT_DOSSIER}/{dealId}/proposal/{proposalId}`;
const newDossierAndProposal = `${ENDPOINT_DOSSIER}/proposals/{proposalId}`;
const dossierProvider = `${ENDPOINT_DOSSIER}/proposal/{proposalId}`;

// PROPERTY
const reservations = `${ENDPOINT_V2}/properties/{propertyId}/reservations/{reservationId}`;

// PROVIDER
export const getProviderWithChildren = providerId =>
  `${providerWithChildren.replace('{id}', providerId)}`;
export const getUpdateLeadStatusOfProvider = providerId =>
  `${updateLeadStatusOfProvider.replace('{id}', providerId)}`;

// PMAs
export const getPmaCities = () => pmaCities;
export const getPmaZipCodes = city => `${pmaZipCodes}/${city}`;
export const getPmaAveragesData = stringQuery => `${pmaAveragesData}${stringQuery}`;
export const getPmaGroupedData = stringQuery => `${pmaGroupedData}${stringQuery}`;

// PROPOSAL
export const getProposalsFiltered = params => `${proposalsFiltered}?${createQueryString(params)}`;

// DOSSIER
// export const getDossier = dealId => dossier.replace('{dealId}', dealId);
export const getNewDossier = dealId => newDossier.replace('{dealId}', dealId);
export const getDossierProvider = proposalId => dossierProvider.replace('{proposalId}', proposalId);
// export const getDossierAndProposal = (dealId, proposalId, hsagent) =>
//   dossierAndProposal.replace('{dealId}', dealId).replace('{proposalId}', proposalId) +
//   (!isEmpty(hsagent) ? `?agent=${hsagent}` : '');
export const getNewDossierAndProposal = (dealId, proposalId) =>
  newDossierAndProposal.replace('{dealId}', dealId).replace('{proposalId}', proposalId);

// PROPERTY
export const getOrSaveReservationsURL = (propertyId, providerId) =>
  reservations.replace('{propertyId}', propertyId).replace('/{reservationId}', '') +
  `?providerId=${providerId}`;
export const deleteReservationURL = (propertyId, reservationId, providerId) =>
  `${ENDPOINT_V2}/properties/${propertyId}/reservations/${reservationId}?providerId=${providerId}`;
export const publishPropertyURL = (propertyReference, propertyId) =>
  `${ENDPOINT_V2}/properties/${propertyReference}/pubStatus/PUBLISHED?propertyId=${propertyId}`;
export const getSignedAvailabilityUrl = (propertyReference, dealId, propertyId) =>
  `${ENDPOINT_V2}/properties/${propertyReference}/avalabilityLandingLink?opportunityId=${dealId}&propertyId=${propertyId}`;

// OPPORTUNITY
export const getPropertiesBySmartSearchURL = dealId =>
  `${ENDPOINT_V2}/opportunities/${dealId}/topScored`;

// COMPANY
export const qualifyingQuestionsURL = companyId =>
  `${ENDPOINT_V2}/companies/${companyId}/qualifyingQuestions`;
export const saveCompanyDataURL = companyId =>
  companyId ? `${ENDPOINT_V2}/companies/${companyId}` : `${ENDPOINT_V2}/companies`;
