import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Alert } from 'antd';
import InfoButtonCompanyDetails from '../../components/InfoButtonCompanyDetails';
import InfoButtonCompanyContacts from '../../components/InfoButtonCompanyContacts';
import TimelinePanel from '../../components/TimelinePanel';
import { showOrLoadCompanyDetailsView } from '../../modules/CompanyDetails/CompanyDetailsActions';
import isEmpty from 'lodash/isEmpty';

const CompanyDetails = ({ params }) => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const { metasource, companyDetails } = useSelector(
    state => ({
      metasource: state.CompanyDetails.metasource,
      companyDetails: state.CompanyDetails.data.detail,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(showOrLoadCompanyDetailsView(params.id));
  }, [dispatch, params.id]);

  useEffect(() => {
    if (!isEmpty(companyDetails) && isLoading) {
      setIsLoading(false);
    }
  }, [companyDetails, isLoading]);

  return (
    <div className='animated fadeIn col-sm-12'>
      <div>
        {metasource.error && (
          <Alert
            message='Error'
            description={metasource.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
      </div>
      {isLoading ? (
        <div key='1' className='loader loading-background loading-background-full'>
          &nbsp;
        </div>
      ) : (
        <>
          <div className='marginTop2 col-sm-12'>
            <div className='row'>
              <InfoButtonCompanyDetails mainView='CompanyDetails' />
              <InfoButtonCompanyContacts mainView='CompanyDetails' leftOffset='3.33' />
            </div>
          </div>
          <TimelinePanel contextType='COMPANY' metasource={metasource} />
        </>
      )}
    </div>
  );
};

export default CompanyDetails;
