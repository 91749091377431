import { getAxiosInstance } from '../../shared/utils/apiUtils';

class DealsApi {
  static getMetasource(url) {
    return this._doGet(url);
  }

  static loadCurrentUserDeals(url, username) {
    return this._doGet(url + username);
  }

  static getRentalRequestStageCountByUser(url) {
    return this._doGet(url);
  }

  static getNewDealLocation(url) {
    return this._doPost(url);
  }

  static _doGet(url) {
    return getAxiosInstance().get(url);
  }

  static _doPost(url) {
    return getAxiosInstance().post(url);
  }

  static _doPostWithData(url, data) {
    return getAxiosInstance().post(url, data);
  }
}

export default DealsApi;
