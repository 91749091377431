import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Button, Modal, Input } from 'antd';
import api from '../../shared/utils/api';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import { ENDPOINT_V2 } from '../../shared/appConfig';

const RequestObservations = ({ text = '', record = {} }) => {
  const [description, setDescription] = useState({
    isVisible: false,
    initialValue: text,
    value: text,
    id: get(record, 'request.id'),
  });

  const handleTableObservations = async () => {
    if (get(description, 'initialValue') === get(description, 'value')) {
      setDescription(obj => ({
        ...obj,
        isVisible: false,
      }));

      return;
    }

    const id = get(description, 'id');
    const value = get(description, 'value') || '';

    if (isEmpty(id)) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        `Ha ocurrido un error a la hora de almacenar la observación. No se ha podido obtener el id de la Solicitud.`
      );

      return;
    }

    const body = {
      observations: value.trim() || null,
    };

    try {
      await api.post(`${ENDPOINT_V2}/requests/${id}`, body);
      setDescription(obj => ({
        ...obj,
        initialValue: get(description, 'value'),
        isVisible: false,
      }));

      openNotificationWithIcon('success', 'Todo OK :)', `Se ha almacenado la observación`);
    } catch (e) {
      console.error(e);
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        `Ha ocurrido un error a la hora de almacenar la observación...
${e.errorMessage}`
      );
    }
  };

  return (
    <>
      <Modal
        title={`Descripción de la Solicitud`}
        visible={get(description, 'isVisible', false)}
        destroyOnClose
        onOk={handleTableObservations}
        onCancel={() =>
          setDescription(obj => {
            return {
              ...obj,
              isVisible: false,
            };
          })
        }
      >
        <Input.TextArea
          placeholder={`Introduce una descripción para la Solicitud`}
          autosize={{ minRows: 2, maxRows: 15 }}
          onChange={e => {
            e.persist();
            setDescription(obj => ({
              ...obj,
              value: e.target.value,
            }));
          }}
          defaultValue={get(description, 'initialValue', '')}
        />
      </Modal>
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Button
          onClick={() =>
            setDescription(obj => ({
              ...obj,
              isVisible: true,
            }))
          }
        >
          {isEmpty(get(description, 'initialValue')) ? (
            <i className='far fa-comment' />
          ) : (
            <i className='fas fa-comment' />
          )}
        </Button>
      </div>
    </>
  );
};

RequestObservations.propTypes = {
  text: PropTypes.string,
  record: PropTypes.object,
};

export default RequestObservations;
