import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Popover } from 'antd';
import api from '../../shared/utils/api';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import { ENDPOINT } from '../../shared/appConfig';
import isEmpty from 'lodash/isEmpty';

const hasMobilePrefix = tel => {
  let telephoneWithPrefix;

  if ((tel.length === 9 && tel.charAt(0) === '6') || tel.charAt(0) === '7') {
    telephoneWithPrefix = '34' + tel;
  } else if (tel.charAt(0) === '+') {
    telephoneWithPrefix = tel.substring(1);
  } else {
    telephoneWithPrefix = tel;
  }

  return telephoneWithPrefix.trim();
};

const RequestFollowUps = ({ imsCount = 0, request = {}, refresh = () => {} }) => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const count = imsCount || 0;

  const onSendFollowUp = async () => {
    try {
      const message =
        count === 0
          ? `¡Hola! Soy de _Homyspace_. Tengo una *solicitud de alojamiento* por tu parte para *${request.city}*. Me gustaría concretar qué necesitáis exactamente para poder mandarte las mejores opciones cuanto antes.\r\n\n¿Te puedo llamar ahora?`
          : `¡Hola de nuevo!\r\nTe dejo este enlace para que rellenes los detalles de lo que necesitáis en vuestro alojamiento, así te puedo enviar opciones disponibles en la zona: https://homyspace.typeform.com/to/Suo8Vo${
              !isEmpty(request.contactEmail) ? `#user=${request.contactEmail}` : ''
            }\r\n\nCuantos más detalles nos des, mejores propuestas podremos enviarte. ¡Estamos en contacto!`;

      window.open(
        `https://web.whatsapp.com/send?phone=${hasMobilePrefix(
          request.phoneNumber
        )}&text=${window.encodeURIComponent(message)}`
      );

      const url = `${ENDPOINT}/request/${request.id}/ims`;

      const payload = {
        imsCount: imsCount + 1,
      };

      await api.request(url, 'put', { body: JSON.stringify(payload) });
      setIsModalOpen(false);
      refresh();
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        `No se ha podido realizar el follow up.
        ${e}`
      );
    }
  };

  const countText = count === 1 ? 'segundo' : 'primer';
  const isDisabled = count >= 2;

  return (
    <>
      <Modal
        title='Follow Up'
        visible={isModalOpen}
        destroyOnClose
        onOk={onSendFollowUp}
        onCancel={() => setIsModalOpen(false)}
      >
        Se registrará el {countText} follow up. ¿Estás segura?
      </Modal>
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {isDisabled ? (
          <Button className='follow-up-request disabled'>
            <i className='fab fa-whatsapp' style={{ fontSize: 20 }} />
          </Button>
        ) : (
          <Popover
            placement='right'
            content={
              <div className={'row popup-option'} style={{ paddingTop: 5, paddingBottom: 1 }}>
                <div className='col-sm-12 noPadding'>
                  <a
                    className={'popup-option'}
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <div className='row'>
                      <div className='col-sm-9 noPadding' style={{ alignSelf: 'center' }}>
                        Enviar {countText} follow up
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            }
            trigger='click'
            visible={visiblePopover}
            onVisibleChange={() => setVisiblePopover(!visiblePopover)}
          >
            <Button className='follow-up-request'>
              <i className='fab fa-whatsapp' style={{ fontSize: 20 }} />
            </Button>
          </Popover>
        )}
      </div>
    </>
  );
};

RequestFollowUps.propTypes = {
  text: PropTypes.string,
  record: PropTypes.object,
};

export default RequestFollowUps;
