import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import CheckboxButton from '../../components/CheckboxButton/CheckboxButton';
import GoogleMapsAutocomplete from '../../components/GoogleMapsAutocomplete';
import { SlideDown } from 'react-slidedown';

const { Item: FormItem } = Form;

const ProvidersFilter = ({ ...props }) => (
  <Form id='formProviders' onSubmit={props.handleFilter} layout='vertical'>
    <Row type='flex' justify='start' align='bottom' gutter={8}>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Nombre'>
          <Input
            id='filterName'
            name='name'
            placeholder='Dolores'
            value={props.filter.name}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Apellidos / Razón social'>
          <Input
            id='filterLastOTradeName'
            name='lastOTradeName'
            placeholder='de la Muela'
            value={props.filter.lastOTradeName}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Email'>
          <Input
            type='email'
            id='filterEmail'
            name='email'
            placeholder='miguelangel.nadal@homyspace.com'
            value={props.filter.email}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='NIF'>
          <Input
            id='filterIdentificationCode'
            name='identificationCode'
            placeholder='00000000A'
            value={props.filter.identificationCode}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Teléfono'>
          <Input
            id='filterPhoneNumber'
            name='phoneNumber'
            placeholder='960258741'
            value={props.filter.phoneNumber}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Ciudad'>
          <GoogleMapsAutocomplete
            id='filterLocality'
            name='locality'
            inputClassName='form-control antd-input-style'
            initValue={props.filter.locality}
            handlePlaceChanged={props.handleChangeLocalityFilter}
            placeholder='Valencia'
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem>
          <Button
            className='filter-buttons more-filters'
            icon={props.filter.showMoreFilters ? 'up' : 'down'}
            onClick={props.handleFilterCollapse}
          >
            Más filtros
          </Button>
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem>
          <Button
            icon='search'
            className='btn-primary filter-buttons'
            htmlType='submit'
            loading={props.loading}
          >
            Filtrar
          </Button>
        </FormItem>
      </Col>
    </Row>
    <SlideDown>
      {props.filter.showMoreFilters && (
        <div className='slide-down-row'>
          <Row gutter={8} type='flex' align='bottom'>
            <Col xxl={4} xl={8} lg={12} md={12} sm={24} xs={24}>
              <FormItem>
                <CheckboxButton
                  borderStyle='primaryCheckboxActive'
                  id='filterBillable'
                  name='billable'
                  handleChange={props.handleChangeOnFilterConditions}
                  content='Hace factura'
                  value={props.filter.billable || false}
                />
              </FormItem>
            </Col>
          </Row>
        </div>
      )}
    </SlideDown>
    <style jsx>
      {`
        .slide-down-row {
          overflow-x: hidden;
          overflow-y: hidden;
        }

        .filter-buttons {
          width: 100% !important;
        }
      `}
    </style>
  </Form>
);
export default ProvidersFilter;
