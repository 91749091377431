import React, { Component } from 'react';
import FormProviderProperties from '../FormProviderProperties/FormProviderProperties';

// PROPERTIES TO BE CONFIGURED IN PARENT
// leftOffset -> columnas bootstrap a desplazer el botón
// providerDetails -> to preload provider when creating a new property from properties list

class InfoButtonPropertyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPropertyListDetail: false,
      numberOfProperties: 0,
    };

    this.handlePropertyListChange = this.handlePropertyListChange.bind(this);
    this.showPropertyListDetail = this.showPropertyListDetail.bind(this);
  }

  componentDidUpdate() {
    if (document.getElementById('infoButtonPropertyList')) {
      if (this.state.showPropertyListDetail) {
        document.getElementById('infoButtonPropertyList').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonPropertyList').style.zIndex = 1;
      }
    }
  }

  showPropertyListDetail(event) {
    if (event.target.nodeName !== 'A') {
      const showOrHide = !this.state.showPropertyListDetail;

      this.setState({
        showPropertyListDetail: showOrHide,
      });
    }
  }

  handlePropertyListChange(propertyList) {
    if (propertyList) {
      this.setState({
        numberOfProperties: propertyList.length,
      });
    }
  }

  render() {
    let leftOffset = 0;

    if (this.props.leftOffset) {
      leftOffset = parseInt(this.props.leftOffset, 10) * -50;
    }

    const styleInfoButtonDetail = {
      marginLeft: `${leftOffset}%`,
    };

    if (!this.state.showPropertyListDetail) {
      styleInfoButtonDetail.display = 'none';
    } else {
      styleInfoButtonDetail.display = 'block';
    }

    return (
      <div id='infoButtonPropertyList' className='col-sm-2 noPadding'>
        <div
          className='card card-inverse propertyBackgroundColor bold infoButton'
          onClick={this.showPropertyListDetail}
        >
          <div className='card-block'>
            <div className='row positionRelative'>
              <div className='col-sm-12'>
                <h1 className='nexa-bold'>{this.state.numberOfProperties} </h1>
                inmuebles
              </div>
            </div>
            {this.state.showPropertyListDetail && (
              <div className='openIcon'>
                <i className='fa fa-caret-down fa-2x' />
              </div>
            )}
          </div>
        </div>

        <div
          style={styleInfoButtonDetail}
          className='infoButtonDetail cardNoMargin noPadding animated fadeIn propertyBorderTopColor'
        >
          <div className='card'>
            <div className='card-block'>
              <FormProviderProperties
                handlePropertyListChange={this.handlePropertyListChange}
                providerDetails={this.props.providerDetails}
              />
              {/* <TableDataProviderProperties
                urlPropertyList={this.state.urlPropertyList}
                handlePropertyListChange={this.handlePropertyListChange}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoButtonPropertyList;

// const useInfoButtonHandler = () => {
//   const [propertiesNumber, setPropertiesNumber] = useState(0);
//   const [showDetails, setShowDetails] = useState(false);
//   const [urlPropertyList, setUrlPropertyList] = useState('');

//   const onClickInfoButton = () => {
//     setShowDetails(!showDetails)
//   }

//   const handlePropertiesListChange = properties => {
//     if (!isEmpty(properties)) {
//       setPropertiesNumber(properties.length)
//     }
//   }

//   useEffect(() => {
//     if (document.getElementById('infoButtonPropertyList')) {
//       if (showDetails) {
//         document.getElementById('infoButtonPropertyList').style.zIndex = 99;
//       } else {
//         document.getElementById('infoButtonPropertyList').style.zIndex = 1;
//       }
//     }
//   }, [showDetails, setUrlPropertyList])

//   return {
//     onClickInfoButton,
//     handlePropertiesListChange,
//     propertiesNumber,
//     urlPropertyList,
//     showDetails
//   }
// }

// const InfoButtonPropertyList = ({ ...props }) => {
//   const { onClickInfoButton, handlePropertiesListChange } = useInfoButtonHandler();
//   let leftOffset = 0;
//     if (leftOffset) {
//       leftOffset = parseInt(leftOffset, 10) * -50;
//     }
//     const styleInfoButtonDetail = {
//       marginLeft: `${leftOffset}%`,
//     };
//     if (!showDetails) {
//       styleInfoButtonDetail.display = 'none';
//     } else {
//       styleInfoButtonDetail.display = 'block';
//     }

//   return (
//     <div id='infoButtonPropertyList' className='col-sm-2 noPadding'>
//       <div
//         className='card card-inverse propertyBackgroundColor bold infoButton'
//         onClick={onClickInfoButton}>
//         <div className='card-block'>
//           <div className='row positionRelative'>
//             <div className='col-sm-12'>
//               <h1>{propertiesNumber} </h1>
//               inmuebles
//             </div>
//           </div>
//           {showDetails && (
//             <div className='openIcon'>
//               <i className='fa fa-caret-down fa-2x' />
//             </div>
//           )}
//         </div>
//       </div>

//       <div
//         style={styleInfoButtonDetail}
//         className='infoButtonDetail cardNoMargin noPadding animated fadeIn propertyBorderTopColor'>
//         <div className='card'>
//           <div className='card-block'>
//             <TableDataProperties
//               urlPropertyList={urlPropertyList}
//               handlePropertyListChange={handlePropertiesListChange}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
