import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HomyModal from '../modals/HomyModal';
import ContactsBigTable from './ContactsBigTable';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import ProvidersApi from '../../modules/Providers/ProvidersApi';
import { useCheckAvailabilityHandler } from '../../shared/utils/hooks/useCheckAvailabilityHandler';
import { connect } from 'react-redux';
import { BUSINESS_EVENT_TYPES } from '../../shared/utils/appUtils';

const NOOP = () => {};

const DataOfContact = ({ ...props }) => {
  const [viewContactsModal, setViewContactsModal] = useState(false);
  const [providerId, setProviderId] = useState('');
  const [contacts, setContacts] = useState([]);
  const { handleRegisterBusinessEvent } = useCheckAvailabilityHandler(
    props.bondedDeal,
    props.currentUsername,
    props.data
  );

  const providerBackId = get(props, 'data.providerBack.id');
  const bondedDeal = get(props, 'bondedDeal', {});

  const loadChildren = async () => {
    // Avoid to get provider contacts if providerId not changed
    if (
      providerBackId &&
      (providerId !== providerBackId || (providerId === providerBackId && !isEmpty(contacts)))
    ) {
      const children = await ProvidersApi.loadProviderWithChildren(providerBackId);
      const providerContacts = get(children, 'baseContact.contact.children');

      setProviderId(providerBackId);
      setContacts(providerContacts);
    } else if (!isEmpty(bondedDeal)) {
      const contactsOfCompany = get(props, 'bondedDeal.contactsOfCompany');
      const contactsWithoutUsual = filter(contactsOfCompany, obj => !obj.roles.includes('USUAL'));

      setContacts(contactsWithoutUsual);
    }

    setViewContactsModal(true);
  };

  return (
    <>
      <div className='row mt-2'>
        <div className='col-8'>
          <span className='mid-bold'>
            {!isNil(providerBackId) ? 'Datos de contacto' : 'Contacto habitual'}
          </span>
        </div>
        <div className='col-4'>
          <button
            type='button'
            className='more-details btn btn-link'
            data-toggle='modal'
            data-target='#ver-contactos-inmueble'
            onClick={async () => await loadChildren()}
          >
            Ver contactos
          </button>
          <HomyModal
            title={getProviderContactsModalTitle(contacts)}
            visible={viewContactsModal}
            onCancel={() => setViewContactsModal(false)}
            destroyOnClose={true}
          >
            <ContactsBigTable
              contacts={contacts}
              bondedDeal={props.bondedDeal}
              propertyData={props.data}
              onAvailabilityCheck={props.onAvailabilityCheck}
            />
          </HomyModal>
        </div>
      </div>
      {providerBackId &&
        renderFavouriteChannel(
          get(props, 'data.providerBack'),
          handleRegisterBusinessEvent,
          props.onAvailabilityCheck
        )}
      {isNil(providerBackId) && renderUsualContact(bondedDeal)}
    </>
  );
};

const getProviderContactsModalTitle = providerContacts => {
  if (!isEmpty(providerContacts)) {
    let contactsWithChildren = providerContacts;

    for (const contact of providerContacts) {
      const children = get(contact, 'contact.children', []);

      if (!isEmpty(children)) {
        for (const child in children) {
          contactsWithChildren = [...contactsWithChildren, child.contact];
        }
      }
    }

    const contactsString = contactsWithChildren.length > 1 ? 'contactos' : 'contacto';

    return (
      <h2>
        {contactsWithChildren.length} {contactsString}
      </h2>
    );
  }

  return <h2>0 contactos</h2>;
};

const renderUsualContact = bondedDeal => {
  const contactsOfCompany = get(bondedDeal, 'contactsOfCompany');
  const usualContact = find(contactsOfCompany, obj => obj.roles.includes('USUAL'));

  const name = get(usualContact, 'contact.name') || '';
  const surname = get(usualContact, 'contact.lastOTradeName') || '';
  const email = get(usualContact, 'contact.email') || get(usualContact, 'contact.email2') || '';
  const phone = get(usualContact, 'contact.phoneNumber') || '';
  const mobile = get(usualContact, 'contact.mobilePhoneNumber') || '';

  return (
    <div className='row mt-0'>
      <div className='col-12 d-flex secondary-contact-data'>
        <span>
          {name} {surname}
        </span>
      </div>
      {email && (
        <div className='col-12 d-flex secondary-contact-data'>
          <span>
            <a className='contact-link' href={`mailto:pruebas@homyspace.com`}>
              {email}
            </a>
          </span>
        </div>
      )}
      {phone && (
        <div className='col-12 d-flex secondary-contact-data'>
          <span>
            <a className='contact-link' href={`tel:${phone}`}>
              {phone}
            </a>
          </span>
        </div>
      )}
      {mobile && (
        <div className='col-12 d-flex secondary-contact-data'>
          <span>
            <a className='contact-link' href={`tel:${mobile}`}>
              {mobile}
            </a>
          </span>
        </div>
      )}
    </div>
  );
};

const renderFavouriteChannel = (provider, handleRegisterBusinessEvent, onAvailabilityCheck) => {
  let renderFav;
  let renderEmail;
  let renderPhone;
  let renderMobile;
  const providerContact = get(provider, 'baseContact');
  let favouriteContactChannel = get(provider, 'favoriteContactChannel');

  favouriteContactChannel = favouriteContactChannel ? favouriteContactChannel.toLowerCase() : '';
  let favEmail = false;
  let favPhone = false;
  let favMobile = false;
  let hasEmail = false;

  switch (favouriteContactChannel) {
    case 'mail': {
      favEmail = true;
      renderFav = (
        <div className='row mt-1'>
          <div className='col-12 d-flex'>
            <div className='favorite-channel'>
              <i className='far fa-star'></i>
              <span className='type-of-property span-bg'>email</span>
            </div>
            <span className='ml-2'>
              <a
                className='contact-link'
                href={`mailto:pruebas@homyspace.com`}
                onClick={() => {
                  handleRegisterBusinessEvent(providerContact, BUSINESS_EVENT_TYPES.EMAIL);
                  onAvailabilityCheck();
                }}
              >
                {get(providerContact, 'email')}
              </a>
            </span>
          </div>
        </div>
      );
      break;
    }

    case 'phone': {
      favPhone = true;
      renderFav = (
        <div className='row mt-1'>
          <div className='col-12 d-flex'>
            <div className='favorite-channel'>
              <i className='far fa-star'></i>
              <span className='type-of-property span-bg'>teléfono</span>
            </div>
            <span className='ml-2'>
              <a
                className='contact-link'
                href={`tel:${get(providerContact, 'phoneNumber') || ''}`}
                onClick={() => {
                  handleRegisterBusinessEvent(providerContact, BUSINESS_EVENT_TYPES.CALL);
                  onAvailabilityCheck();
                }}
              >
                {get(providerContact, 'phoneNumber') || ''}
              </a>
            </span>
          </div>
        </div>
      );
      break;
    }

    case 'whatsapp': {
      favMobile = true;
      renderFav = (
        <div className='row mt-1'>
          <div className='col-12 d-flex'>
            <div className='favorite-channel'>
              <i className='far fa-star'></i>
              <span className='type-of-property span-bg'>whatsapp</span>
            </div>
            <span className='ml-2'>
              <a
                className='contact-link'
                href={`tel:${get(providerContact, 'mobilePhoneNumber')}`}
                onClick={() => {
                  handleRegisterBusinessEvent(providerContact, BUSINESS_EVENT_TYPES.CALL);
                  onAvailabilityCheck();
                }}
              >
                {get(providerContact, 'mobilePhoneNumber')}
              </a>
            </span>
          </div>
        </div>
      );
      break;
    }

    default: {
      renderFav = null;
    }
  }

  if (!favEmail && get(providerContact, 'email', '') !== '') {
    hasEmail = true;
    renderEmail = (
      <div className='row mt-0'>
        <div className='col-12 d-flex secondary-contact-data'>
          <span>
            <a
              className='contact-link'
              href={`mailto:pruebas@homyspace.com`}
              onClick={() => {
                handleRegisterBusinessEvent(providerContact, BUSINESS_EVENT_TYPES.EMAIL);
                onAvailabilityCheck();
              }}
            >
              {get(providerContact, 'email')}
            </a>
          </span>
        </div>
      </div>
    );
  }

  if (!favEmail && !hasEmail && get(providerContact, 'email2', '') !== '') {
    renderEmail = (
      <div className='row mt-0'>
        <div className='col-12 d-flex secondary-contact-data'>
          <span>
            <a
              className='contact-link'
              href={`mailto:pruebas@homyspace.com`}
              onClick={() => {
                handleRegisterBusinessEvent(providerContact, BUSINESS_EVENT_TYPES.EMAIL);
                onAvailabilityCheck();
              }}
            >
              {get(providerContact, 'email2')}
            </a>
          </span>
        </div>
      </div>
    );
  }

  if (!favPhone && get(providerContact, 'phoneNumber', '') !== '') {
    renderPhone = (
      <div className='row mt-0'>
        <div className='col-12 d-flex secondary-contact-data'>
          <span>
            <a
              className='contact-link'
              href={`tel:${get(providerContact, 'phoneNumber')}`}
              onClick={() => {
                handleRegisterBusinessEvent(providerContact, BUSINESS_EVENT_TYPES.CALL);
                onAvailabilityCheck();
              }}
            >
              {get(providerContact, 'phoneNumber')}
            </a>
          </span>
        </div>
      </div>
    );
  }

  if (!favMobile && get(providerContact, 'mobilePhoneNumber', '') !== '') {
    renderMobile = (
      <div className='row mt-0'>
        <div className='col-12 d-flex secondary-contact-data'>
          <span>
            <a
              className='contact-link'
              href={`tel:${get(providerContact, 'mobilePhoneNumber')}`}
              onClick={() => {
                handleRegisterBusinessEvent(providerContact, BUSINESS_EVENT_TYPES.CALL);
                onAvailabilityCheck();
              }}
            >
              {get(providerContact, 'mobilePhoneNumber')}
            </a>
          </span>
        </div>
      </div>
    );
  }

  return <>{[renderFav, renderEmail, renderPhone, renderMobile]}</>;
};

DataOfContact.propTypes = {
  loadProviderWithChildren: PropTypes.func,
  data: PropTypes.shape({}),
  bondedDeal: PropTypes.object,
  currentUsername: PropTypes.string,
};

DataOfContact.defaultProps = {
  loadProviderWithChildren: NOOP,
  data: {},
  bondedDeal: {},
};

const mapStateToProps = function(state) {
  return {
    currentUsername: state.Preload.currentUserInformation.username,
  };
};

export default connect(mapStateToProps)(DataOfContact);
