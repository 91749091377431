import get from 'lodash/get';
import ProviderTemplates from '../../../templates/ProviderTemplates/ProviderTemplates';
import { BUSINESS_EVENT_TYPES, openNotificationWithIcon } from '../appUtils';
import EventsApi from '../../../modules/Events/EventsApi';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import startCase from 'lodash/startCase';
import first from 'lodash/first';
import split from 'lodash/split';
import moment from 'moment';
import ProvidersApi from '../../../modules/Providers/ProvidersApi';
import { ENDPOINT_V2 } from '../../appConfig';
import api from '../api';
import { sanitizeMobilePrefix } from '../phoneUtils';

/**
 * Handle availability comunications for provider by email and whatsapp
 * Registers a businessEvent of type AVAILABILITY_QUERY after open
 * mail/phone/whatsapp window
 * opportunityInfo -> not mandatory, for fill the message if present
 */

export const useCheckAvailabilityHandler = (
  opportunityInfo = {},
  currentUsername = '',
  propertyData
) => {
  const handleCheckAvailabilityByEmail = async contact => {
    let { providersContacted } = opportunityInfo;
    let alreadyContacted = false;

    if (!providersContacted) {
      providersContacted = [];
    }

    const contactEmail = get(contact, 'email') || get(contact, 'email2');

    if (isEmpty(contactEmail)) {
      return openNotificationWithIcon(
        'error',
        'Opps!! :(',
        'El contacto seleccionado no tiene ningún correo electrónico'
      );
    }

    if (providersContacted.indexOf(contactEmail) >= 0) {
      if (
        !window.confirm(
          'Ya has consultado disponibilidad con este contacto para esta oportunidad, ¿deseas continuar de todos modos?'
        )
      ) {
        alreadyContacted = true;
      }
    } else {
      if (contactEmail && contactEmail !== '') {
        providersContacted.push(contactEmail);
        opportunityInfo.providersContacted = providersContacted;
      }
    }

    if (alreadyContacted) {
      return;
    }

    checkAvailabilityEmail();

    handleRegisterBusinessEvent(contact, BUSINESS_EVENT_TYPES.EMAIL);
    updateLeadStatusForProvider();
  };

  const checkAvailabilityEmail = () => {
    const emptyData = checkForEmptyData({
      checkInDate: get(opportunityInfo, 'rentalStartDate') || false,
      checkOutDate: get(opportunityInfo, 'rentalEndDate') || false,
      opportunityAddress:
        get(opportunityInfo, 'address.locality') ||
        get(opportunityInfo, 'address.formattedAddress') ||
        false,
      propertyAddress: get(propertyData, 'address', {}),
      PAX: get(opportunityInfo, 'numberOfPeople') || false,
    });

    if (emptyData.length) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        `No se ha podido enviar el mensaje. Los siguientes campos están vacíos: 
        ${emptyData.join(', ')}`
      );

      return;
    }

    sendAvailability();
  };

  const sendAvailability = async channel => {
    const opportunityId = get(opportunityInfo, 'id');
    const propertyId = get(propertyData, 'id');
    let url = `${ENDPOINT_V2}/opportunities/${opportunityId}/availabilityQuery/${propertyId}`;

    if (!isEmpty(channel)) {
      url += `?channel=${channel}`;
    }

    try {
      await api.request(url, 'post');

      openNotificationWithIcon('success', 'Todo OK :)', '¡Consulta enviada!');
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Opps!! :(',
        `No se ha podido enviar el mensaje. 
        ${e}`
      );
    }
  };

  const checkForEmptyData = data => {
    const elements = Object.keys(data);
    const missingElements = elements.filter(element => !data[element]);

    return missingElements;
  };

  const handleCheckAvailabilityByWhatsapp = async contact => {
    let { providersContacted } = opportunityInfo;

    if (!providersContacted) {
      providersContacted = [];
    }

    const contactPhone = get(contact, 'mobilePhoneNumber', '');
    let alreadyContacted = false;

    if (providersContacted.indexOf(contactPhone) >= 0) {
      if (
        !window.confirm(
          'Ya has consultado disponibilidad con este contacto para esta oportunidad, ¿deseas continuar de todos modos?'
        )
      ) {
        alreadyContacted = true;
      }
    } else {
      if (contactPhone && contactPhone !== '') {
        providersContacted.push(contactPhone);
        opportunityInfo.providersContacted = providersContacted;
      } else {
        openNotificationWithIcon(
          'warning',
          'El contacto habitual para este inmueble no dispone de telefono móvil',
          '',
          5
        );
        return;
      }
    }

    if (!alreadyContacted) {
      const message = await getWhatsAppMessage(contact);
      window.open(
        `https://web.whatsapp.com/send?phone=${sanitizeMobilePrefix(
          contactPhone.replace(/\s/g, '')
        )}&text=${message}`
      );
      handleRegisterBusinessEvent(contact, BUSINESS_EVENT_TYPES.WHATSAPP);
      updateLeadStatusForProvider();
    }
  };

  const handleCheckAvailabilityByCall = async contact => {
    let { providersContacted } = opportunityInfo;

    if (!providersContacted) {
      providersContacted = [];
    }

    const contactPhone = get(contact, 'mobilePhoneNumber', get(contact, 'phoneNumber', ''));
    let alreadyContacted = false;

    if (providersContacted.indexOf(contactPhone) >= 0) {
      if (
        !window.confirm(
          'Ya has consultado disponibilidad con este contacto para esta oportunidad, ¿deseas continuar de todos modos?'
        )
      ) {
        alreadyContacted = true;
      }
    } else {
      if (contactPhone && contactPhone !== '') {
        providersContacted.push(contactPhone);
        opportunityInfo.providersContacted = providersContacted;
      } else {
        openNotificationWithIcon(
          'warning',
          'El contacto habitual para este inmueble no dispone de telefono móvil',
          '',
          5
        );
      }
    }

    if (!alreadyContacted && !isEmpty(contactPhone)) {
      const linkElement = document.createElement('a');
      linkElement.href = `tel:${contactPhone}`;
      linkElement.click();
      handleRegisterBusinessEvent(contact, BUSINESS_EVENT_TYPES.CALL);
      updateLeadStatusForProvider();
    }
  };

  const handleCopyAvailabilityMessage = async () => {
    const providerTemplates = new ProviderTemplates();
    const checkInDate = get(opportunityInfo, 'rentalStartDate');
    const checkOutDate = get(opportunityInfo, 'rentalEndDate');
    const formattedCheckInDate =
      !isNil(checkInDate) && (isNumber(checkInDate) || isDate(checkInDate))
        ? moment(checkInDate).format('DD/MM/YYYY')
        : '[[Sin fecha de entrada]]';
    const formattedCheckOutDate =
      !isNil(checkOutDate) && (isNumber(checkOutDate) || isDate(checkOutDate))
        ? moment(checkOutDate).format('DD/MM/YYYY')
        : '[[Sin fecha de salida]]';
    const opportunityAddress =
      get(opportunityInfo, 'address.locality', '') ||
      get(opportunityInfo, 'address.formattedAddress', '') ||
      '[[Sin localidad]]';
    const propertyAddress = get(propertyData, 'address', {});

    const text = providerTemplates.getEmailConfirmAvailability(
      formattedCheckInDate,
      formattedCheckOutDate,
      opportunityAddress,
      propertyAddress,
      get(opportunityInfo, 'numberOfPeople') || '[[Sin número de personas]]'
    );

    navigator.clipboard.writeText(window.decodeURIComponent(text)).then(
      function() {
        openNotificationWithIcon(
          'info',
          'Mensaje de disponibilidad copiado al portapapeles!!!',
          '',
          5
        );
      },
      function() {
        openNotificationWithIcon('error', 'No se ha podido copiar el mensaje de disponibilidad');
      }
    );
  };

  const messageObject = props => {
    const checkInDate = get(opportunityInfo, 'rentalStartDate');
    const checkOutDate = get(opportunityInfo, 'rentalEndDate');
    const propertyAddress = get(propertyData, 'address');

    return {
      propertyReference: get(propertyData, 'homyspaceReference'),
      propertyAddress: get(propertyAddress, 'formattedAddress'),
      // propertyAddress:
      //   get(propertyAddress, 'formattedAddress') +
      //   !isEmpty(
      //     get(propertyAddress, 'addressComplement', '')
      //       ? `, ${get(propertyAddress, 'addressComplement', '')}`
      //       : ''
      //   ),
      identificationCode: get(props, 'identificationCode')
        ? ' (' + get(props, 'identificationCode') + ')'
        : '',
      address:
        get(props, 'address.locality') ||
        get(props, 'address.postalTown') ||
        get(props, 'address.administrativeAreaLevel2') ||
        get(props, 'address.administrativeAreaLevel1'),
      pax: get(props, 'numberOfPeople'),
      formattedCheckInDate:
        !isNil(checkInDate) && (isNumber(checkInDate) || isDate(checkInDate))
          ? moment(checkInDate).format('DD/MM/YYYY')
          : '[[Sin fecha de entrada]]',
      formattedCheckOutDate:
        !isNil(checkOutDate) && (isNumber(checkOutDate) || isDate(checkOutDate))
          ? moment(checkOutDate).format('DD/MM/YYYY')
          : '[[Sin fecha de salida]]',
    };
  };

  const getWhatsAppMessage = async contact => {
    const message = messageObject(opportunityInfo);
    const { username } = get(opportunityInfo, 'hsagent', false);

    const msg = `Hola ${contact.name || ''}, soy ${startCase(
      first(split(username, '@'))
    )} de Homyspace, tengo una solicitud a la cual le puede interesar el inmueble de *${
      message.propertyAddress
    }*.\nTienes disponibilidad del *${message.formattedCheckInDate}* al *${
      message.formattedCheckOutDate
    }*?\nSon ${message.pax} persona/s`;

    return window.encodeURIComponent(msg);
  };

  const getEventTypeText = eventType => {
    const textEquivalences = {
      [BUSINESS_EVENT_TYPES.WHATSAPP]: 'Whatsapp',
      [BUSINESS_EVENT_TYPES.CALL]: 'Teléfono',
      [BUSINESS_EVENT_TYPES.EMAIL]: 'Correo electrónico',
    };

    if (!textEquivalences[eventType]) {
      return '';
    }

    return ` a través de ${textEquivalences[eventType]}`;
  };

  const handleRegisterBusinessEvent = (contact, secondaryType = false) => {
    if (get(opportunityInfo, 'id') && !isEmpty(propertyData)) {
      const businessEvent = {};
      let usualContactName;
      let contactPhone;
      let contactEmail;

      if (contact) {
        usualContactName =
          (contact.name ? contact.name + ' ' : '') +
          (contact.lastOTradeName ? contact.lastOTradeName : '');
        contactPhone = get(contact, 'mobilePhoneNumber', '');
        contactEmail = get(contact, 'email') || get(contact, 'email2');
      }

      businessEvent.text = `${
        currentUsername ? `<b>${currentUsername}</b>` : 'Se'
      } ha consultado disponibilidad${
        usualContactName ? ' a ' + usualContactName : ''
      }${getEventTypeText(secondaryType)}
    <br/>
    para el inmueble: <b>${propertyData.title} (${propertyData.homyspaceReference})</b>`;
      businessEvent.date = new Date();
      businessEvent.from = currentUsername;
      businessEvent.availabilityContact =
        secondaryType === BUSINESS_EVENT_TYPES.EMAIL ? contactEmail : contactPhone;

      const params = {
        dealId: get(opportunityInfo, 'id'),
        providerId: get(propertyData, 'providerId'),
        contactId: get(contact, 'id'),
        propertyId: get(propertyData, 'id'),
        companyId: get(opportunityInfo, 'companyId'),
      };

      const eventTypes = [BUSINESS_EVENT_TYPES.AVAILABILITY_QUERY];

      if (secondaryType) {
        eventTypes.push(secondaryType);
      }

      try {
        EventsApi.createNewBusinessEvent(businessEvent, eventTypes, params);
      } catch (e) {
        console.error(
          'useCheckAvailabilityHandler: error while creating AVAILABILITY_QUERY event business'
        );
      }
    }
  };

  const updateLeadStatusForProvider = () => {
    try {
      ProvidersApi.updateLeadStatusOfProvider(propertyData.providerId);
    } catch (e) {
      openNotificationWithIcon(
        'error',
        `Ha ocurrido un error al actualizar el estado del lead del proveedor.
      Por favor, contacta con el departamento técnico.`,
        e.message
      );
    }
  };

  return {
    handleCheckAvailabilityByEmail,
    handleCheckAvailabilityByWhatsapp,
    handleCopyAvailabilityMessage,
    handleRegisterBusinessEvent,
    handleCheckAvailabilityByCall,
  };
};
