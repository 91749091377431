import React from 'react';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import CheckboxButton from '../CheckboxButton/CheckboxButton';
import HomySelect from '../HomyComponents/HomySelect';
import { SlideDown } from 'react-slidedown';
import { DATE_FORMAT } from '../../shared/appConfig';
import moment from 'moment';

const { Item: FormItem } = Form;

const RequestsFilter = props => (
  <Form data-test-id='requestFilter' onSubmit={props.handleSubmitFilter} layout='vertical'>
    <Row gutter={8} type='flex' justify='start' align='bottom'>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Gestor asignado'>
          <HomySelect
            name='selectAgents'
            placeholder='Selecciona el Gestor'
            value={props.filter.hsAgent}
            options={props.salesAgents}
            onChange={value => props.handleChangeAgent(value)}
            allowClear
            mode='multiple'
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Ciudad'>
          <Input
            id='city'
            name='city'
            placeholder='Valencia'
            value={props.filter.city}
            onChange={props.handleChange}
          />
        </FormItem>
      </Col>
      <Col xxl={3} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Creación desde'>
          <DatePicker
            style={{ width: '100%' }}
            placeholder='dd/mm/yyyy'
            value={props.filter.creationDateFrom ? moment(props.filter.creationDateFrom) : null}
            format={DATE_FORMAT}
            onChange={props.handleDateChange.bind(this, 'creationDateFrom')}
          />
        </FormItem>
      </Col>
      <Col xxl={3} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Creación hasta'>
          <DatePicker
            style={{ width: '100%' }}
            value={props.filter.creationDateTo ? moment(props.filter.creationDateTo) : null}
            placeholder='dd/mm/yyyy'
            format={DATE_FORMAT}
            onChange={props.handleDateChange.bind(this, 'creationDateTo')}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Email'>
          <Input
            id='contactEmail'
            name='contactEmail'
            placeholder='laura.jaraiz@hs.com'
            value={props.filter.contactEmail}
            onChange={props.handleChange}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Teléfono'>
          <Input
            id='phoneNumber'
            name='phoneNumber'
            placeholder='612345678'
            value={props.filter.phoneNumber}
            onChange={props.handleChange}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem>
          <Button
            className='filter-buttons more-filters'
            icon={props.filter.showMoreFilters ? 'up' : 'down'}
            onClick={props.handleFilterCollapse}
          >
            Más filtros
          </Button>
        </FormItem>
      </Col>
      <Col xxl={2} xl={6} lg={6} md={6} sm={12} xs={24}>
        <FormItem>
          <Button
            icon='search'
            className='btn-primary filter-buttons'
            htmlType='submit'
            loading={props.loading}
          >
            Filtrar
          </Button>
        </FormItem>
      </Col>
    </Row>
    <SlideDown>
      {props.filter.showMoreFilters && (
        <Row className='slide-down-row' gutter={8} type='flex' justify='start' align='bottom'>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                id='filterFirstRequest'
                name='firstRequest'
                handleChange={props.handleChange}
                content='Primera solicitud'
                value={props.filter.firstRequest}
              />
            </FormItem>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                id='filterRecurrent'
                name='recurrent'
                handleChange={props.handleChange}
                content='Solicitud recurrente'
                value={props.filter.recurrent}
              />
            </FormItem>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                id='filterConverted2Deal'
                name='converted2Deal'
                handleChange={props.handleChange}
                content='Convertida a Oportunidad'
                value={props.filter.converted2Deal}
              />
            </FormItem>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                id='filterNotConverted2Deal'
                name='notConverted2Deal'
                handleChange={props.handleChange}
                content='No convertida a Oportunidad'
                value={props.filter.notConverted2Deal}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </SlideDown>
    <style jsx>
      {`
        .request-btn-font {
          color: #212529;
        }
        .slide-down-row {
          overflow-x: hidden;
          overflow-y: hidden;
        }
        .filter-buttons {
          width: 100% !important;
        }
      `}
    </style>
  </Form>
);
export default RequestsFilter;
