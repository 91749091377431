import {
  COMPANIES_METASOURCE_ERROR,
  COMPANIES_METASOURCE_SUCCESS,
  COMPANIES_TABLEDATA_FILTER,
  COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR,
  COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS,
  NEW_COMPANY_LOCATION_ERROR,
  NEW_COMPANY_LOCATION_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  filter: {},
  filterVersion: 0,
  companyLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
  invoiceMarketingSourceMediumOptions: {
    options: [],
    error: false,
    errorDetail: '',
  },
};

const Companies = (state = initialState, action) => {
  switch (action.type) {
    case COMPANIES_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case COMPANIES_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case COMPANIES_TABLEDATA_FILTER: {
      const filterVersion = state.filterVersion + 1;

      return Object.assign({}, state, {
        filter: action.filterCriteria,
        filterVersion,
      });
    }

    case NEW_COMPANY_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        companyLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_COMPANY_LOCATION_ERROR: {
      return Object.assign({}, state, {
        companyLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS: {
      return Object.assign({}, state, {
        invoiceMarketingSourceMediumOptions: {
          options: action.options,
          error: false,
          errorDetail: '',
        },
      });
    }

    case COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR: {
      return Object.assign({}, state, {
        invoiceMarketingSourceMediumOptions: {
          options: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    default:
      return state;
  }
};

export default Companies;
