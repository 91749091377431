import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Row } from 'antd';
import Select from 'react-select';
import { createNewCompany } from '../../modules/CompanyDetails/CompanyDetailsActions';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import { getOptionsForSelect } from '../../shared/utils/appUtils';

class FormNewCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCompanyDetails: {
        baseContact: {
          name: '',
          lastOTradeName: '',
          email: '',
          email2: '',
          phoneNumber: '',
          mobilePhoneNumber: '',
          creationDate: new Date(),
          updateDate: new Date(),
        },
      },
      submitting: false,
      invalidInput: false,
      options: {
        companyType: getOptionsForSelect(this.props.optionLabels, 'CompanyType'),
      },
    };
    this.props.companyDetailsCreate.error = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.getLastOrTradeNameLabel = this.getLastOrTradeNameLabel.bind(this);
  }

  handleChange(e) {
    const localNewCompanyDetails = this.state.newCompanyDetails;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'phoneNumber' || e.target.name === 'mobilePhoneNumber') {
      value = normalizePhoneNumber(value);
    }

    localNewCompanyDetails.baseContact[e.target.name] = value;
    this.setState({ newCompanyDetails: localNewCompanyDetails });
  }

  handleSelectChange(name, selectedOption) {
    const localNewCompanyDetails = this.state.newCompanyDetails;

    localNewCompanyDetails[name] = selectedOption ? selectedOption.value : null;
    this.setState({ newCompanyDetails: localNewCompanyDetails });
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !validForm });

    return validForm;
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.isValidForm()) {
      this.setState({ submitting: true }, () => {
        this.props.actions.createNewCompany(
          this.props.companyLocation,
          this.state.newCompanyDetails
        );
      });
    }
  };

  getLastOrTradeNameLabel() {
    if (this.state.newCompanyDetails) {
      if (this.state.newCompanyDetails.companyType === 'PERSON') {
        return 'Apellidos';
      }

      if (this.state.newCompanyDetails.companyType === 'COMPANY') {
        return 'Razón Social';
      }
    }

    return 'Apellidos/Razón Social';
  }

  render() {
    return (
      <form id='formNewCompanyDetails' onSubmit={this.handleSubmit}>
        {this.props.companyDetailsCreate.error && (
          <div>
            <Alert
              message='Error'
              description={this.props.companyDetailsCreate.errorDetail}
              type='error'
              showIcon
            />
            <br />
          </div>
        )}
        {this.state.invalidInput && (
          <div>
            <Alert message='Error en los datos introducidos' type='error' showIcon />
            <br />
          </div>
        )}

        <Row>
          <Col span={24}>
            <label htmlFor='companyDetailCompanyType' className='required'>
              Tipo
            </label>
            <Select
              name='companyType'
              className='form-control validate validate-required'
              value={
                this.state.newCompanyDetails.companyType
                  ? this.state.newCompanyDetails.companyType
                  : ''
              }
              options={this.state.options.companyType}
              onChange={value => {
                this.handleSelectChange('companyType', value);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='companyDetailName' className='required'>
              Nombre
            </label>
            <input
              type='text'
              className='form-control validate validate-required'
              id='companyDetailName'
              name='name'
              placeholder='Ana'
              value={this.state.newCompanyDetails.name}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='companyDetailLastName'>{this.getLastOrTradeNameLabel()}</label>
            <input
              type='text'
              className='form-control'
              id='companyDetailLastOTradeName'
              name='lastOTradeName'
              placeholder='García García'
              value={this.state.newCompanyDetails.lastOTradeName}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='companyDetailEmail'>Email</label>
            <input
              type='email'
              className='form-control'
              id='companyDetailEmail'
              name='email'
              placeholder='pruebas@pruebas.com'
              value={this.state.newCompanyDetails.email}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='companyDetailPhoneNumber'>Teléfono fijo</label>
            <input
              type='text'
              className='form-control'
              id='companyDetailPhoneNumber'
              name='phoneNumber'
              placeholder='961231212'
              value={this.state.newCompanyDetails.phoneNumber}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='CompanyDetailMobilePhoneNumber'>Teléfono móvil</label>
            <input
              type='text'
              className='form-control'
              id='companyDetailMobilePhoneNumber'
              name='mobilePhoneNumber'
              placeholder='616232323'
              value={this.state.newCompanyDetails.mobilePhoneNumber}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row type='flex' justify='end'>
          <Col>
            <Button loading={this.state.submitting} htmlType='submit' className='btn-primary'>
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createNewCompany: (url, companyDetailsData) =>
        dispatch(createNewCompany(url, companyDetailsData)),
    },
  };
}

function mapStateToProps(state) {
  return {
    companyDetailsCreate: state.CompanyDetails.create,
    companyLocation: state.Companies.companyLocation.location,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormNewCompany);
