import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Alert, Modal } from 'antd';
import Select from 'react-select';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import {
  getOptionsForSelect,
  isEmptyObject,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import TableDataCompanyContacts from '../TableDataCompanyContacts';
import {
  deleteCompanyContactData,
  getCompanyContactEtag,
  getNewContactLocation,
  saveCompanyContactData,
} from '../../modules/CompanyDetails/CompanyDetailsActions';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import {
  getContactDetailsData,
  saveContactDetailsDataFromParent,
} from '../../modules/ContactDetails/ContactDetailsActions';
import FormNewContact from '../FormNewContact';
import get from 'lodash/get';
import set from 'lodash/set';

// PARAMETROS A CONFIGURAR
// callbackFunctionOnUpdateOrSave -> Función a llamar cuando se finaliza correctamente un guardado de información
// mainView -> main view in wich this component is included (optional)

class FormCompanyContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      contactsVersion: props.companyContactsVersion,
      url: {},
      companyContacts: this.props.companyContacts,
      submitting: false,
      submitSuccess: false,
      invalidInput: false,
      contactList: null,
      modalContact: false,
      editingContact: false,
      options: {
        contactType: getOptionsForSelect(this.props.optionLabels, 'ContactType'),
        contactRole: getOptionsForSelect(this.props.optionLabels, 'ContactRole'),
      },
      modal: false,
      alert: {
        show: false,
      },
      contactLocation: props.contactLocation,
      deleteContactStatus: {
        contact: {},
        error: false,
        errorDetail: '',
      },
      editingContactStatus: {
        contact: {},
        error: false,
        errorDetail: '',
      },
      contactSubmit: {
        error: false,
        errorDetail: '',
      },
      contactToUnlink: {},
      editContact: {},
      contactToRemove: {},
    };
    this.selectContactPrefilter =
      this.props.mainView === 'DealDetails' ? '&provOrComp=COMPANY' : '';

    this.contactSelected = this.contactSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.toggle = this.toggle.bind(this);
    this.contactCreated = this.contactCreated.bind(this);
    this.toggleContact = this.toggleContact.bind(this);
    this.handleDeleteContact = this.handleDeleteContact.bind(this);
    this.handleUnlinkContact = this.handleUnlinkContact.bind(this);
    this.editToggleContact = this.editToggleContact.bind(this);
    this.findContact = this.findContact.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitting && !nextProps.contact.error) {
      this.setState({ submitSuccess: true });
      this.props.callbackFunctionOnUpdateOrSave();
    } else {
      this.setState({ submitSuccess: false });
    }

    this.setState({ submitting: false });
    if (this.state.contactLocation !== nextProps.contactLocation) {
      this.setState({
        contactLocation: nextProps.contactLocation,
      });
      this.toggle();
    }

    // For deleteContact
    if (
      JSON.stringify(this.state.deleteContactStatus) !==
      JSON.stringify(nextProps.deleteContactStatus)
    ) {
      if (nextProps.deleteContactStatus.error && !isEmptyObject(this.state.contactToRemove)) {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error eliminando el contacto:\n${nextProps.deleteContactStatus.errorDetail}`
        );
      } else if (!isEmptyObject(this.state.contactToRemove)) {
        openNotificationWithIcon(
          'success',
          'Todo OK :)',
          'El contacto se ha eliminado correctamente'
        );
        this.props.callbackFunctionOnUpdateOrSave();
      }

      // Se elimina el contacto que estaba reservado en handleDeleteContact para controlar el borrado del contacto
      this.setState({
        contactToRemove: {},
        deleteContactStatus: {},
      });
    }

    // For unlinkContact
    if (JSON.stringify(this.state.contactSubmit) !== JSON.stringify(nextProps.contactSubmit)) {
      if (nextProps.contactSubmit.error) {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error al desvincular el contacto: \n${nextProps.contactSubmit.errorDetail}`
        );
      }
    }

    if (!isEmptyObject(this.state.contactToUnlink)) {
      openNotificationWithIcon(
        'success',
        'Todo OK :)',
        'El contacto se ha desvinculado del cliente correctamente'
      );
      this.props.callbackFunctionOnUpdateOrSave();
      // Se elimina el contacto que estaba reservado en handleUnlinkContact
      // para poder hacer el splice en el array de contactos
      this.setState({ contactToUnlink: {} });
    }

    if (JSON.stringify(this.state.companyContacts) !== JSON.stringify(nextProps.companyContacts)) {
      this.setState({
        companyContacts: nextProps.companyContacts,
      });
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
    if (this.state.editingContact) {
      this.setState({
        editingContact: !this.state.editingContact,
      });
    }
  }

  editToggleContact(contactId) {
    const editContact = this.findContact(this.state.companyContacts, contactId);

    this.setState({
      modal: !this.state.modal,
      editingContact: !this.state.editingContact,
      editContact,
    });
  }

  handleChange(e) {
    const newContact = this.state.contact;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'phoneNumber' || e.target.name === 'mobilePhoneNumber') {
      value = normalizePhoneNumber(value);
    }

    newContact[e.target.name] = value;
    this.setState({ contact: newContact });
  }

  handlePlaceChanged(place) {
    const { contact } = this.state;

    set(contact, 'address', {
      ...place,
      addressComplement: get(contact, 'address.addressComplement', null),
    });

    this.setState({
      contact,
    });
  }

  handleSelectChange(name, selectedOption) {
    if (this.state.editingContact) {
      const localEditontact = this.state.editContact;

      name = name.length > 0 ? name : 'contactInfo';
      localEditontact[name] = selectedOption || this.props.companyBaseContact.id;
      this.setState({ editContact: localEditontact });
    } else {
      const newContact = this.state.contact;

      newContact[name] = selectedOption ? selectedOption.value : this.props.companyBaseContact.id;
      this.setState({ contact: newContact });
    }
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !validForm });

    return validForm;
  }

  handleSubmit() {
    if (this.isValidForm()) {
      this.setState({ submitting: true });
      this.props.actions.saveCompanyContactData(this.state.contact, this.state.url);
    }
  }

  handleDelete() {
    if (window.confirm('Vas a eliminar el contacto del sistema, ¿estas seguro?')) {
      this.setState({ submitting: true });
      this.props.actions.deleteCompanyContactData(this.state.url);
    }
  }

  contactCreated() {
    this.props.callbackFunctionOnUpdateOrSave();
    if (this.state.modal) {
      this.toggle();
    }
  }

  contactSelected = contact => {
    browserHistory.push(`/contactDetails/${contact.id}`);
    // let contactListBaseContact = [
    //   {
    //     label:
    //       this.props.companyBaseContact.baseContact.name +
    //       ' ' +
    //       (this.props.companyBaseContact.baseContact.lastOTradeName
    //         ? this.props.companyBaseContact.baseContact.lastOTradeName
    //         : ''),
    //     value: this.props.companyBaseContact.id
    //   }
    // ];
    // const contactList = getContactList(
    //   this.props.companyContacts,
    //   contact.contact.id
    // );
    // let options = this.state.options;
    // options.contactList = contactListBaseContact.concat(contactList);

    // const parentContact = getParentContact(
    //   this.props.companyContacts,
    //   contact.contact.id
    // );
    // contact.contact.parent = parentContact;
    // if (!parentContact) {
    //   contact.contact.parent = this.props.companyBaseContact.id;
    // }

    // this.setState({
    //   contact: contact.contact,
    //   options: options,
    //   url: contact._links.self.href
    // });

    // this.props.actions.getCompanyContactEtag(contact._links.self.href);

    // document.location.href = '#formContactDetail';
  };

  toggleContact() {
    this.setState({
      modalContact: !this.state.modalContact,
    });
    if (this.state.editingContact) {
      this.setState({
        editingContact: !this.state.editingContact,
      });
    }
  }

  handleDeleteContact(contactId) {
    if (
      this.props.companyContacts &&
      window.confirm('¿Estás seguro de que quieres eliminar este contacto?')
    ) {
      const contact = this.findContact(this.props.companyContacts, contactId);

      if (contact) {
        // Se reserva el contacto en el estado para eliminarlo del array de contactos en el onSuccess de saveContactDetailsData(contact)
        this.setState({ contactToRemove: contact }, this.props.actions.deleteContact(contact));
      }
    }
  }

  handleUnlinkContact(contactId) {
    if (
      this.props.companyContacts &&
      window.confirm('¿Estás seguro de que quieres desvincular este contacto?')
    ) {
      const contact = this.findContact(this.props.companyContacts, contactId);

      if (contact) {
        contact.contact.relatedCompany = null;
        // Se reserva el contacto para eliminarlo del array de contactos en el onSuccess de saveContactDetailsData(contact)
        this.setState(
          { contactToUnlink: contact },
          this.props.actions.saveContactDetailsData(contact)
        );
      }
    }
  }

  findContact(contactList, contactId) {
    let contact;
    const contactsWithClidren = [];

    for (let i = 0; i < contactList.length; i++) {
      contact = contactList[i];
      if (contact) {
        if (contactId === contact.contact.id) {
          break;
        } else if (contact.children) {
          contactsWithClidren.push(contact);
        }

        contact = null;
      }
    }

    if (!contact && contactsWithClidren.length > 0) {
      for (let i = 0; i < contactsWithClidren.length; i++) {
        contact = this.findContact(contactsWithClidren[i].children, contactId);
        if (contact) {
          return contact;
        }
      }
    } else {
      return contact;
    }
  }

  render() {
    // const deleteRender = (contactId) => {
    //   const content = (
    //     <div>
    //       <p className="alignCenter"><a onClick={() => this.handleDeleteContact(contactId)}>Eliminar de homybrain</a></p>
    //       <p className="alignCenter"><a onClick={() => this.handleUnlinkContact(contactId)}>Desvincular del proveedor</a></p>
    //     </div>
    //   );
    //   return (
    //     <div>
    //       <Popover content={content} trigger="hover">
    //         <button type='button' className='btn btn-primary btn-sm'>
    //           <Icon type="delete" />
    //         </button>
    //       </Popover>
    //     </div>
    //   )
    // };

    return (
      <div>
        <div className='row'>
          <div className='col-sm-2'>
            <button
              type='button'
              className='btn btn-primary btn-block alignLeft'
              onClick={() => this.props.actions.getNewContactLocation()}
            >
              Nuevo Contacto
            </button>
            <Modal
              title={this.state.editingContact ? 'Modificar función de contacto' : 'Nuevo Contacto'}
              width={800}
              style={{ top: '20px' }}
              visible={this.state.modal}
              footer={null}
              onCancel={this.toggle}
              destroyOnClose
            >
              <FormNewContact
                contactType='relatedCompany'
                editContact={this.state.editContact}
                editingContact={this.state.editingContact}
                providerOrCompany={this.props.companyBaseContact}
                mainView={this.props.mainView}
                callbackFuncionOnCreate={this.contactCreated}
              />
            </Modal>
          </div>
          <div className='col-sm-10'>
            {this.props.companyContacts && (
              <TableDataCompanyContacts
                callbackFunctionOnContactSelect={this.contactSelected}
                callbackFunctionOnContactDelete={this.handleDeleteContact}
                callbackFunctionOnContactUnlink={this.handleUnlinkContact}
                callbackEditFunctionOnContactUnlink={this.editToggleContact}
              />
            )}
          </div>
        </div>
        <div id='formContactDetail'>&nbsp;</div>
        {this.props.contact && this.props.contact.error && (
          <Alert
            message='Error'
            description={this.props.contact.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
        {this.state.invalidInput && (
          <Alert message='Error en los datos introducidos' type='error' showIcon closable />
        )}
        {this.state.contact.id && (
          <div className='animated fadeIn row detailRow col-sm-12'>
            <div className='col-sm-2'>
              {/*
              <button type="button" className="btn btn-primary btn-block alignLeft" onClick={this.handleSubmit}>
                Guardar
              </button>
              <button type="button" className="btn btn-primary btn-block alignLeft" onClick={this.handleDelete}>
                Eliminar
              </button>
              */}
            </div>
            <div className='col-sm-10'>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactType' className='required'>
                      Tipo
                    </label>
                    <Select
                      name='contactType'
                      className='form-control validate validate-required'
                      value={this.state.contact.contactType ? this.state.contact.contactType : ''}
                      options={this.state.options.contactType}
                      onChange={value => {
                        this.handleSelectChange('contactType', value);
                      }}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='parent' className='required'>
                      Contacto padre
                    </label>
                    <Select
                      name='parent'
                      className='form-control validate validate-required'
                      value={this.state.contact.parent ? this.state.contact.parent : ''}
                      options={this.state.options.contactList}
                      onChange={value => {
                        this.handleSelectChange('parent', value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactIdentificationCode'>NIF</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactIdentificationCode'
                      name='identificationCode'
                      placeholder='12121212Z'
                      value={
                        this.state.contact.identificationCode
                          ? this.state.contact.identificationCode
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactName' className='required'>
                      Nombre
                    </label>
                    <input
                      type='text'
                      className='form-control validate validate-required'
                      id='contactName'
                      name='name'
                      placeholder='Ana'
                      value={this.state.contact.name}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactLastOTradeName'>Apellidos/Razón Social</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactLastOTradeName'
                      name='lastOTradeName'
                      placeholder='García García'
                      value={
                        this.state.contact.lastOTradeName ? this.state.contact.lastOTradeName : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  {this.state.contact.contactType === 'PERSON' && (
                    <div className='form-group'>
                      <label htmlFor='contactJobTitle'>Cargo</label>
                      <input
                        type='text'
                        className='form-control'
                        id='contactJobTitle'
                        name='jobTitle'
                        placeholder='Cargo'
                        value={this.state.contact.jobTitle ? this.state.contact.jobTitle : ''}
                        onChange={this.handleChange}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactEmail'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      id='contactEmail'
                      name='email'
                      placeholder='pruebas@pruebas.com'
                      value={this.state.contact.email || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactEmail2'>2º Email</label>
                    <input
                      type='email'
                      className='form-control'
                      id='contactEmail2'
                      name='email2'
                      placeholder='pruebas@pruebas.com'
                      value={this.state.contact.email2 || ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactPhoneNumber'>Teléfono fijo</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactPhoneNumber'
                      name='phoneNumber'
                      placeholder='961231212'
                      value={this.state.contact.phoneNumber ? this.state.contact.phoneNumber : ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='form-group'>
                    <label htmlFor='contactMobilePhoneNumber'>Teléfono móvil</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactMobilePhoneNumber'
                      name='mobilePhoneNumber'
                      placeholder='616232323'
                      value={
                        this.state.contact.mobilePhoneNumber
                          ? this.state.contact.mobilePhoneNumber
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactAddress'>Direccion</label>
                    <GoogleMapsAutocomplete
                      handlePlaceChanged={this.handlePlaceChanged}
                      initValue={
                        this.state.contact.address && this.state.contact.address.formattedAddress
                          ? this.state.contact.address
                          : null
                      }
                      inputClassName='form-control'
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactAddressComplement'>Complemento dirección</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactAddressComplement'
                      name='addressComplement'
                      placeholder='n.3 '
                      value={
                        this.state.contact.address && this.state.contact.address.addressComplement
                          ? this.state.contact.address.addressComplement
                          : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='contactAccountNumber'>Número de cuenta</label>
                    <input
                      type='text'
                      className='form-control validate validate-accountNumber'
                      id='contactAccountNumber'
                      name='accountNumber'
                      placeholder='ES12 0000 0000 0000 0000 0000'
                      value={
                        this.state.contact.accountNumber ? this.state.contact.accountNumber : ''
                      }
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label htmlFor='bicOrSwift'>BIC o SWIFT</label>
                    <input
                      type='text'
                      className='form-control'
                      id='contactBicOrSwift'
                      name='bicOrSwift'
                      placeholder='ES12 0000 0000 0000 0000 0000'
                      value={this.state.contact.bicOrSwift ? this.state.contact.bicOrSwift : ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <hr className='hs-hr' />

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='contactDescription'>Descripción</label>
                    <textarea
                      id='contactDescription'
                      name='description'
                      rows='8'
                      className='form-control'
                      placeholder='Descripción'
                      value={this.state.contact.description ? this.state.contact.description : ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form-group'>
                    <label htmlFor='contactObservations'>Observaciones</label>
                    <textarea
                      id='contactObservations'
                      name='observations'
                      rows='4'
                      className='form-control'
                      placeholder='Observaciones'
                      value={this.state.contact.observations ? this.state.contact.observations : ''}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getContactDetailsData: () => dispatch(getContactDetailsData()),
      saveCompanyContactData: (data, url) => {
        dispatch(saveCompanyContactData(data, url));
      },
      getCompanyContactEtag: url => {
        dispatch(getCompanyContactEtag(url));
      },
      deleteContact: contact => {
        dispatch(deleteCompanyContactData(contact));
      },
      getNewContactLocation: () => {
        dispatch(getNewContactLocation());
      },
      saveContactDetailsData: contact => {
        dispatch(saveContactDetailsDataFromParent(contact));
      },
    },
  };
}

function mapStateToProps(state) {
  return {
    companyContacts: state.CompanyDetails.contacts.detail,
    companyBaseContact: state.CompanyDetails.data.detail,
    optionLabels: state.Preload.optionLabels,
    contact: state.CompanyDetails.contact,
    companyContactsVersion: state.CompanyDetails.contactsVersion,
    contactLocation: state.CompanyDetails.contactLocation.location,
    deleteContactStatus: state.ContactDetails.delete,
    contactSubmit: state.ContactDetails.submit,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCompanyContacts);
