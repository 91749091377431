import { getAxiosInstance } from '../../shared/utils/apiUtils';
import { qualifyingQuestionsURL, saveCompanyDataURL } from '../../shared/apiUrls';
import isEmpty from 'lodash/isEmpty';
import remove  from 'lodash/remove';
import api from '../../shared/utils/api';
import { parseQualifiyingQuestions, unparseQualifiyingQuestions } from 'hs-utils';
import get from 'lodash/get';

class CompanyDetailsApi {
  static getMetasource(url, id) {
    return this.doGet(url + '/' + id);
  }

  static getCompanyDetailsData(url) {
    return this.doGet(url);
  }

  static saveCompanyDetailsData(url, companyDetails, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, companyDetails);
  }

  static saveCompanyContactData(url, contactDetails, parent, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, contactDetails, {
      params: { parent: parent },
    });
  }

  static deleteCompanyContactData(url, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.delete(url);
  }

  static getNewContactLocation(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(url);
  }

  static doGet(url) {
    return getAxiosInstance().get(url);
  }

  static doPost(url) {
    return getAxiosInstance().doPost(url);
  }

  static async saveQualifyingQuestions(companyId, questions) {
    if (isEmpty(companyId) || isEmpty(companyId)) {
      throw new Error('CompanyDetailsApi.saveQualifyingQuestions: no companyId specified');
    }
    if (!isEmpty(questions)) {
      let parsedQuestions = parseQualifiyingQuestions(questions);
      remove(parsedQuestions, (question) => {
        return question.answer === '';
      });

      const url = qualifyingQuestionsURL(companyId);
      return api
        .request_retry(url, 'POST', 3, {
          body: JSON.stringify({ questions: parsedQuestions }),
        })
        .then(res => res.json)
        .catch(e => {
          console.error('CompanyDetailsApi.saveQualifyingQuestions', e);
          throw new Error('CompanyDetailsApi.saveQualifyingQuestions: ' + e);
        });
    }
  }

  static async getQualifyingQuestions(companyId) {
    if (isEmpty(companyId) || isEmpty(companyId)) {
      throw new Error('CompanyDetailsApi.getQualifyingQuestions: no companyId specified');
    }
    const url = qualifyingQuestionsURL(companyId);
    const questionsRes = await api.request_retry(url, 'GET', 3);

    const qualifyingQuestions = get(questionsRes, 'json') || [];

    return unparseQualifiyingQuestions(qualifyingQuestions);
  }

  /**
   * Save company details for v2 endpoints.
   * If "companyId" specified, updates the company info, if not, it creates a new company
   * @param {*} companyId
   * @param {*} companyData
   */
  static async saveCompanyData(company) {
    const url = saveCompanyDataURL(company.id);
    return api
      .request_retry(url, 'POST', 3, {
        body: JSON.stringify(this.parseCompanyDataToV2(company)),
      })
      .then(res => res.json)
      .catch(e => {
        console.error('CompanyDetailsApi.saveCompanyData', e);
        throw new Error('CompanyDetailsApi.saveCompanyData: ' + e);
      });
  }

  static parseCompanyDataToV2(companyData) {
    return {
      ...companyData,
      companyLeadLifecycle: 'ACCOUNT',
      baseContact: {
        ...companyData.baseContact,
        // cif: companyData.baseContact.identificationCode,
        [companyData.companyType === 'PERSON' ? 'dninie' : 'cif']: companyData.baseContact
          .identificationCode,
        email: companyData.baseContact.email !== '' ? companyData.baseContact.email : null,
        // tradeName: companyData.baseContact.lastOTradeName,
        [companyData.companyType === 'PERSON' ? 'lastName' : 'tradeName']: companyData.baseContact
          .lastOTradeName,
        contactType: companyData.companyType,
        marketingSourceMedium: !isEmpty(companyData.marketingSourceMedium)
          ? companyData.marketingSourceMedium
          : null,
      },
    };
  }
}

export default CompanyDetailsApi;
