import React, { Component } from 'react';
import TableDataPropertyProposals from '../TableDataPropertyProposals/TableDataPropertyProposals';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import map from 'lodash/map';

class InfoButtonPropertyProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyId: props.propertyId,
      showProposals: false,
      propertyProposals: {},
    };
    this.showPropertyProposals = this.showPropertyProposals.bind(this);
  }

  componentDidUpdate = () => {
    if (document.getElementById('infoButtonPropertyProposals')) {
      if (this.state.showCompanyContacts) {
        document.getElementById('infoButtonPropertyProposals').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonPropertyProposals').style.zIndex = 1;
      }
    }
  };

  showPropertyProposals(event) {
    if (event.target.nodeName !== 'A') {
      const showProposals = !this.state.showProposals;

      this.setState({
        showProposals,
      });
    }
  }

  render() {
    const { leftOffset, proposals } = this.props;
    const offset = leftOffset ? parseInt(leftOffset, 10) * -50 : 0;

    const styleInfoButtonDetail = {
      marginLeft: `${offset}%`,
    };

    if (!this.state.showProposals) {
      styleInfoButtonDetail.display = 'none';
    } else {
      styleInfoButtonDetail.display = 'block';
    }

    const { showProposals } = this.state;
    const proposalsCount = get(proposals, 'data', []).length;
    const proposalsList = map(get(proposals, 'data'), 'proposal');

    return (
      <div id='infoButtonPropertyProposals' className='col-sm-2 noPadding'>
        <div
          className='card card-inverse proposalBackgroundColor bold infoButton'
          onClick={proposalsCount > 0 && this.showPropertyProposals}
        >
          <div className='card-block'>
            <div className='row positionRelative'>
              <div className='col-sm-12'>
                <h1 className='nexa-bold'>{proposalsCount} </h1>
                propuestas
              </div>
            </div>
            {showProposals && (
              <div className='openIcon'>
                <i className='fa fa-caret-down fa-2x' />
              </div>
            )}
          </div>
        </div>

        <div
          style={styleInfoButtonDetail}
          className='infoButtonDetail cardNoMargin noPadding animated fadeIn proposalBorderTopColor'
        >
          <div className='card'>
            <div className='card-block'>
              <TableDataPropertyProposals proposals={proposalsList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InfoButtonPropertyProposals);
