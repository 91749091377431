export const SUPPLYDEMAND_SET_FILTER = 'SUPPLYDEMAND_SET_FILTER';
export const SUPPLYDEMAND_SET_RESPONSE = 'SUPPLYDEMAND_SET_RESPONSE';

export const setFilter = filter => ({
  type: SUPPLYDEMAND_SET_FILTER,
  filter,
});

export const setResponse = response => ({
  type: SUPPLYDEMAND_SET_RESPONSE,
  response,
});
