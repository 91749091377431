import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isString from 'lodash/isString';
import startsWith from 'lodash/startsWith';
import { Button, Carousel, Col, Icon, Row, Skeleton } from 'antd';
import AwsUtils from '../../shared/utils/awsUtils.js';

const getExtensionFromBase64 = data => {
  const decoded = window.atob(data);
  const lowerCase = decoded.toLowerCase();
  let extension = 'jpg';

  if (lowerCase.indexOf('png') !== -1) {
    extension = 'png';
  } else if (lowerCase.indexOf('jpg') !== -1 || lowerCase.indexOf('jpeg') !== -1) {
    extension = 'jpg';
  }

  return extension;
};

const useImages = (data, position, carouselRef) => {
  const [images, setImages] = useState(
    get(data, 'pictures') && !isEmpty(data.pictures) ? [...data.pictures] : []
  );
  const [page, setPage] = useState(position);
  const bucket = get(data, 'bucketName');
  const imgLength = get(data, 'pictures') ? data.pictures.length - 1 : 0;

  const load = async (images, page, bucket) => {
    const awsUtils = new AwsUtils();

    try {
      const imageKey = images[page];

      if (isEmpty(bucket) || isEmpty(imageKey)) {
        return [];
      }

      const imgInfo = await awsUtils.getS3Resource(bucket, imageKey);
      const extension = getExtensionFromBase64(imgInfo.Body.toString('base64'));
      const image = 'data:image/' + extension + ';base64,' + imgInfo.Body.toString('base64');

      images[page] = image;
    } catch (e) {
      console.error(e);
      throw e;
    }

    return images;
  };

  useEffect(() => {
    !isEmpty(data.pictures) ? setImages([...data.pictures]) : setImages([]);
    setPage(0);
  }, [data.pictures]);

  useEffect(() => {
    const fetchImg = async (images, page, bucket) => {
      try {
        const result = await load(images, page, bucket);

        if (!isEmpty(result)) {
          setImages([...result]);
        }
      } catch (e) {
        console.error('Couldnt get the images', e);
      }
    };

    if (!startsWith(images[page], 'data:image/')) {
      fetchImg(images, page, bucket);
    }
  }, [images, page, bucket]);

  const goTo = index => {
    carouselRef.current.goTo(index);
    setPage(index);
  };

  const prev = () => {
    carouselRef.current.prev();
    setPage(obj => (obj === 0 ? imgLength : obj - 1));
  };

  const next = () => {
    carouselRef.current.next();
    setPage(obj => (obj === imgLength ? 0 : obj + 1));
  };

  return {
    images,
    goTo,
    prev,
    next,
  };
};

const ImageCarousel = ({ ...props }) => {
  const carouselRef = useRef(null);
  const { images, prev, next, goTo } = useImages(props.data, 0, carouselRef);
  const imgsToRender = imagesToRender(images);

  return isEmpty(imgsToRender) ? (
    <div className='loading-background-hs' style={{ height: '100%', width: '100%' }}>
      &nbsp;
    </div>
  ) : (
    <Row type='flex' justify='center' align='middle' gutter={8}>
      <Col span={24}>
        <Button
          onClick={() => {
            prev();
          }}
          className='carousel-buttons button-left'
        >
          <Icon type='caret-left' theme='outlined' />
        </Button>
        <Carousel
          ref={carouselRef}
          afterChange={i => {
            goTo(i);
          }}
        >
          {imgsToRender}
        </Carousel>
        <Button
          onClick={() => {
            next();
          }}
          className='carousel-buttons button-right'
        >
          <Icon type='caret-right' theme='outlined' />
        </Button>
      </Col>
      <style jsx>
        {`
          .carousel-buttons {
            padding: 0 0 0 10px !important;
            height: 100%;
            top: 0;
            color: white !important;
            background: 0 0;
            color: inherit;
            border: none;
            padding: 0 !important;
            position: absolute;
            margin: 0;
            text-transform: none;
            transition: all 0.3s ease-in-out;
            border-radius: 0;
            cursor: pointer;
            z-index: 2;
            background: transparent no-repeat center center !important;
            background-size: 100% 100% !important;
            font-size: 28px !important;
          }

          .carousel-buttons:hover {
            z-index: 2;
            background: transparent no-repeat center center !important;
          }

          .button-right {
            right: 1px;
          }

          .button-left {
            left: 1px;
          }
        `}
      </style>
    </Row>
  );
};

const imagesToRender = data => {
  const carouselImages = [];

  if (!isEmpty(data)) {
    data.forEach((image, index) => {
      if (isString(image)) {
        if (image.includes('data:image/')) {
          carouselImages.push(
            <img id={`image${index}`} key={`image-${index}`} src={image} alt={`image${index}`} />
          );
        } else {
          carouselImages.push(
            <div style={{ padding: '0px 10px 0 10px' }} key={`div-${index}`}>
              <Skeleton active style={{ background: 'white' }} />
            </div>
          );
        }
      } else {
        carouselImages.push(image);
      }
    });
  }

  return carouselImages;
};

export default ImageCarousel;
