import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, InputNumber, Row } from 'antd';
import { DATE_FORMAT } from '../../shared/appConfig';
import moment from 'moment';
import CheckboxButton from '../../components/CheckboxButton/CheckboxButton';
import HomySelect from '../../components/HomyComponents/HomySelect';
import api from '../../shared/utils/api';
import { getPmaCities, getPmaZipCodes } from '../../shared/apiUrls';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const useFilterHandler = initialFilter => {
  const [filter, setFilter] = useState(initialFilter);
  const [checkBoxesDisabled, setCheckBoxesDisabled] = useState(true);
  const [cityOptions, setCityOptions] = useState([]);
  const [zipOptions, setZipOptions] = useState([]);

  const updateFilter = (key, newValue) => {
    const newFilterValue = {};

    if (key === 'city') {
      if (newValue !== filter.city) {
        newFilterValue.city = newValue;
        newFilterValue.zipCodes = [];
      } else if (filter.city) {
        newFilterValue.city = null;
        setZipOptions([]);
        newFilterValue.zipCodes = null;
      }
    } else if (key === 'zipCodes') {
      newFilterValue[key] = [...newValue];
    } else {
      newFilterValue[key] = newValue;
    }

    const newFilterValues = { ...filter, ...newFilterValue };

    if (
      newFilterValues.city &&
      newFilterValues.city.length > 0 &&
      (!newFilterValues.zipCodes || newFilterValues.zipCodes.length === 0) &&
      (!newFilterValues.rooms || newFilterValues.rooms === 0) &&
      (!newFilterValues.dateFrom || newFilterValues.dateFrom.length === 0) &&
      (!newFilterValues.dateTo || newFilterValues.dateTo.length === 0)
    ) {
      setCheckBoxesDisabled(false);
      if (isEmpty(newFilterValues.detailedBy)) {
        newFilterValues.detailedBy = 'zipCode';
      }
    } else {
      setCheckBoxesDisabled(true);
      newFilterValues.detailedBy = null;
    }

    setFilter(newFilterValues);
  };

  // Initial load for cities
  useEffect(() => {
    const url = getPmaCities();

    api
      .request_retry(url, 'get', 3)
      .then(response => {
        const values = get(response, 'json.values', []);

        setCityOptions(values.map(c => ({ label: c, value: c })));
      })
      .catch(err => {
        console.error(`Call to ${url} failed: ${err.message}`);
      });
  }, []);

  // Load zipCodes for selected city
  useEffect(() => {
    const url = getPmaZipCodes(filter.city);

    api
      .request_retry(getPmaZipCodes(filter.city), 'get', 3)
      .then(response => {
        const values = get(response, 'json.values', []);

        setZipOptions(values.map(c => ({ label: c, value: c })));
      })
      .catch(err => {
        console.error(`Call to ${url} failed: ${err.message}`);
      });
  }, [filter.city]);

  return [filter, updateFilter, cityOptions, zipOptions, checkBoxesDisabled];
};

const { Item: FormItem } = Form;
const PmasFilter = ({ ...props }) => {
  const [filter, updateFilter, cityOptions, zipOptions, checkBoxesDisabled] = useFilterHandler(
    props.filter
  );

  const handleSubmit = e => {
    e.preventDefault();
    props.handleSubmit(filter);
  };

  return (
    <Form onSubmit={handleSubmit} layout='vertical'>
      <Row gutter={8} type='flex' justify='start' align='bottom'>
        <Col xxl={4} xl={4} lg={8} md={10} sm={12} xs={24}>
          <FormItem label='Ciudad'>
            <HomySelect
              name='pmasCity'
              placeholder='Selecciona una ciudad'
              value={filter.city ? filter.city : ''}
              options={cityOptions}
              onChange={value => updateFilter('city', value)}
              showArrow
              showSearch
              allowClear
            />
          </FormItem>
        </Col>
        <Col xxl={9} xl={9} lg={16} md={14} sm={12} xs={24}>
          <FormItem label='Código postal'>
            <HomySelect
              name='pmasZipCode'
              placeholder='Selecciona código postal'
              value={filter.zipCodes ? filter.zipCodes : []}
              options={zipOptions}
              onChange={value => updateFilter('zipCodes', value)}
              isDisabled={isEmpty(filter.city)}
              mode='multiple'
              showArrow
              showSearch
              allowClear
            />
          </FormItem>
        </Col>
        <Col xxl={3} xl={3} lg={3} md={8} sm={12} xs={24}>
          <FormItem label='Habitaciones'>
            <InputNumber
              id='rooms'
              min={0}
              max={30}
              value={filter.rooms}
              onChange={value => {
                updateFilter('rooms', value);
              }}
            />
          </FormItem>
        </Col>
        <Col xxl={4} xl={4} lg={5} md={8} sm={12} xs={24}>
          <FormItem label='Fecha inicio'>
            <DatePicker
              id='pmasDateFrom'
              style={{ width: '100%' }}
              placeholder='dd/mm/yyyy'
              value={filter.dateFrom ? moment(filter.dateFrom, DATE_FORMAT) : null}
              format={DATE_FORMAT}
              onChange={(moment, dateString) => updateFilter('dateFrom', dateString)}
            />
          </FormItem>
        </Col>
        <Col xxl={4} xl={4} lg={5} md={8} sm={12} xs={24}>
          <FormItem label='Fecha hasta'>
            <DatePicker
              id='pmasDateTo'
              style={{ width: '100%' }}
              placeholder='dd/mm/yyyy'
              value={filter.dateTo ? moment(filter.dateTo, DATE_FORMAT) : null}
              format={DATE_FORMAT}
              onChange={(moment, dateString) => updateFilter('dateTo', dateString)}
            />
          </FormItem>
        </Col>
        <Col xxl={4} xl={6} lg={4} md={8} sm={12} xs={24}>
          <FormItem>
            <CheckboxButton
              borderStyle='pmaCheckboxActive'
              id='pmaDetailedByZipCode'
              name='pmaDetailedByZipCode'
              handleChange={() => updateFilter('detailedBy', 'zipCode')}
              content='Detallado por códigos postales'
              value={filter.detailedBy === 'zipCode'}
              disabled={checkBoxesDisabled}
            />
          </FormItem>
        </Col>
        <Col xxl={4} xl={6} lg={4} md={8} sm={12} xs={24}>
          <FormItem>
            <CheckboxButton
              borderStyle='pmaCheckboxActive'
              id='pmaDetailedByWeek'
              name='pmaDetailedByWeek'
              handleChange={() => updateFilter('detailedBy', 'week')}
              content='Detallado por semanas'
              value={filter.detailedBy === 'week'}
              disabled={checkBoxesDisabled}
            />
          </FormItem>
        </Col>
        <Col xxl={14} xl={9} lg={0} md={0} sm={0} xs={24} />
        <Col xxl={2} xl={3} lg={3} md={8} sm={12} xs={24}>
          <FormItem>
            <Button
              htmlType='submit'
              icon='search'
              className='btn-primary filter-buttons'
              loading={props.loading}
            >
              Filtrar
            </Button>
          </FormItem>
        </Col>
      </Row>
      <style jsx>
        {`
          .Select {
            height: 61px !important;
          }

          div.Select-control {
            height: 30px !important;
            border: 1px solid #d9d9d9 !important;
            border-radius: 4px !important;
            -webkit-transition: all 0.3s !important;
            transition: all 0.3s !important;
          }

          div.Select-control:hover,
          div.Select-control:active,
          div.Select-control:focus {
            border-color: #f8dda6 !important;
          }

          /* Select de Ciudad y codigo postal */
          .Select-input {
            height: 30px !important;
          }

          /* Select de Ciudad y codigo postal */
          label[for='companyId'] {
            display: none !important;
          }

          .ant-input-number {
            width: 100%;
          }

          .switch-request {
            margin-left: 10px !important;
          }

          .filter-buttons {
            width: 100% !important;
          }

          div.Select-control {
            height: 34px !important;
          }
        `}
      </style>
    </Form>
  );
};

export default PmasFilter;
