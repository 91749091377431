import React, { Component } from 'react';
import { Button, DatePicker, Form, Row } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from '../../shared/appConfig.js';
import get from 'lodash/get';

// PARAMETROS A CONFIGURAR
// callbackFuncionOnCreate -> función a llamar cuando se ha creado un nuevo contacto

class FormNewReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newReservation: {
        start: new Date(),
        end: new Date(new Date().setDate(new Date().getDate() + 7)),
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = date => {
    const { newReservation } = this.state;

    const checkIn = date[0].startOf('day');
    const checkOut = date[1].endOf('day');

    newReservation.start = checkIn;
    newReservation.end = checkOut;

    this.setState({
      newReservation,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const { newReservation } = this.state;

    const startDate = newReservation.start;
    const endDate = newReservation.end;

    this.props.callbackFuncionOnCreate(startDate, endDate, get(this.props, 'property'));
  };

  render() {
    const { newReservation } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <label htmlFor='datePickerReservation' id='labelForDate'>
            <DatePicker.RangePicker
              id='datePickerReservation'
              onChange={this.handleChange}
              format={DATE_FORMAT}
              style={{ width: '100%' }}
              defaultValue={[
                moment(newReservation.start, DATE_FORMAT),
                moment(newReservation.end, DATE_FORMAT),
              ]}
            />
          </label>
        </Row>
        <br />
        <Row>
          <Button
            loading={this.props.submitting}
            style={{ float: 'right' }}
            htmlType='submit'
            className='btn-primary'
          >
            Guardar
          </Button>
        </Row>
      </Form>
    );
  }
}

export default FormNewReservation;
