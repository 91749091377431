import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { showPropertyDetailsView } from '../../modules/PropertyDetails/PropertyDetailsActions';
import InfoButtonProviderDetails from '../../components/InfoButtonProviderDetails';
import FormPropertyDetails from '../../components/FormPropertyDetails';
import InfoButtonPropertyProposals from '../../components/InfoButtonPropertyProposals/InfoButtonPropertyProposals';

import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import get from 'lodash/get';
import replace from 'lodash/replace';
import isEmpty from 'lodash/isEmpty';
import api from '../../shared/utils/api';

class PropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyDetails: {},
      proposals: {},
      isLoading: true,
    };

    this.loadContactData = this.loadContactData.bind(this);
  }

  componentDidMount() {
    this.props.actions.showPropertyDetailsView(this.props.params.id);
    this.loadContactData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.isLoading && !isEmpty(nextProps.propertyDetails)) {
      this.setState({
        isLoading: false,
      });
    }
  }

  loadContactData = async () => {
    const id = get(this.props, 'params.id') || get(this.state, 'propertyDetails.id');

    if (!id) {
      openNotificationWithIcon('error', 'Oops!', 'No se ha podido obtener los datos del contacto');

      return;
    }

    try {
      const details = await api.getTableData(`proposal/property/${id}`);

      this.setState({
        proposals: {
          data: details.json.proposalList,
          etag: replace(details.headers.get('etag'), /"/g, ''),
        },
      });
    } catch (e) {
      console.error('Error a la hora de obtener datos del contacto', e);
    }
  };

  render() {
    return (
      <div className='animated fadeIn col-sm-12'>
        <div>
          {this.props.metasource.error && (
            <Alert
              message='Error'
              description={this.props.metasource.errorDetail}
              type='error'
              showIcon
              closable
            />
          )}
        </div>
        {this.state.isLoading ? (
          <div key='1' className='loader loading-background loading-background-full'>
            &nbsp;
          </div>
        ) : (
          <>
            <div className='marginTop2 col-sm-12'>
              <div className='row'>
                <InfoButtonProviderDetails withSelfLink />
                <InfoButtonPropertyProposals
                  propertyId={this.props.propertyDetails.id}
                  proposals={this.state.proposals}
                  leftOffset='5'
                />
              </div>
              <div className='row'>
                <div className='card col-sm-12 noPadding'>
                  <div className='card-block'>
                    <FormPropertyDetails proposals={this.state.proposals} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.PropertyDetails.metasource,
    propertyDetails: state.PropertyDetails.data.detail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      showPropertyDetailsView: id => dispatch(showPropertyDetailsView(id)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
