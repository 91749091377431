/* eslint-disable react/display-name */
import React from 'react';
import { Alert, Col, Row } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const ContractData = props => {
  let tenants = [];

  if (isEmpty(get(props, 'data'))) {
    return <h4>Sin información sobre el contrato</h4>;
  }

  const pax = get(props, 'data.tenants');

  if (pax) {
    tenants = pax.split(';');
  }

  const warnings = get(props, 'data.warnings', []);
  const descriptionOfWarnings = warnings.map((w, index) => (
    <p key={`warning-${index}`} style={{ margin: 0 }}>
      · {w}
    </p>
  ));
  const hasCompanyInfo = get(props, 'hasCompanyInfo', false);
  const hasProviderInfo = get(props, 'hasProviderInfo', false);
  const providerIsContractSpecific = get(props, 'data.providerIsContractSpecific', false);
  const companyIsContractSpecific = get(props, 'data.companyIsContractSpecific', false);

  return (
    <Row type='flex'>
      <Col span={5}>
        {!hasCompanyInfo && (
          <div>
            <Alert
              description='El formulario de Cliente no se ha cumplimentado. Es posible que falten datos para el contrato.'
              type='warning'
            />
            <br />
          </div>
        )}
        {hasCompanyInfo && (
          <div>
            <Alert message='El Cliente ha rellenado el formulario.' type='success' />
            <br />
          </div>
        )}
        {!hasProviderInfo && (
          <div>
            <Alert
              description='El formulario de Proveedor no se ha cumplimentado. Es posible que falten datos para el contrato.'
              type='warning'
            />
            <br />
          </div>
        )}
        {hasProviderInfo && (
          <div>
            <Alert message='El Proveedor ha rellenado el formulario.' type='success' />
            <br />
          </div>
        )}
        {companyIsContractSpecific && (
          <div>
            <Alert
              message='El Cliente emplea un CONTRATO ESPECÍFICO. Por favor, usa el contrato correspondiente.'
              type='warning'
            />
            <br />
          </div>
        )}
        {providerIsContractSpecific && (
          <div>
            <Alert
              message='El Proveedor emplea un CONTRATO ESPECÍFICO. Por favor, usa el contrato correspondiente.'
              type='warning'
            />
            <br />
          </div>
        )}

        {!isEmpty(descriptionOfWarnings) && (
          <Alert description={descriptionOfWarnings} type='warning' />
        )}
      </Col>
      <div style={{ borderLeft: '1px solid lightgrey', margin: '0 5px' }}></div>
      <Col span={6}>
        <h5>Parte arrendadora</h5>
        <div style={{ paddingLeft: '5%' }}>
          <h4>
            <b>Datos del Arrendador</b>
          </h4>
          <ul>
            <li>
              <b>Nombre:</b> {props.data.landLordName}
            </li>
            <li>
              <b>Apellidos/Razón social:</b> {props.data.landLordLastOrTradeName}
            </li>
            <li>
              <b>Dirección:</b> {props.data.landLordAddress}
            </li>
            <li>
              <b>NIF:</b> {props.data.landLordNif}
            </li>
            <li>
              <b>IBAN:</b> {props.data.landLordIBAN}
            </li>
          </ul>
          <h4>
            <b>Proveedor del Inmueble</b>
          </h4>
          <ul>
            <li>
              <b>Nombre:</b> {props.data.providerName}
            </li>
            <li>
              <b>Apellidos/Razón social:</b> {props.data.providerLastName}
            </li>
            <li>
              <b>Tipo de gestión del proveedor:</b> {props.data.providerManagementType}
            </li>
          </ul>
          <h4>
            <b>Representante</b>
          </h4>
          <ul>
            <li>
              <b>Nombre:</b> {props.data.landLordContractSignatureName}
            </li>
            <li>
              <b>Apellidos:</b> {props.data.landLordContractSignatureLastName}
            </li>
            <li>
              <b>Cargo:</b> {props.data.landLordContractSignatureRole}
            </li>
            <li>
              <b>NIF:</b> {props.data.landLordContractSignatureNif}
            </li>
            <li>
              <b>Correo electrónico:</b> {props.data.landLordContractSignatureEmail}
            </li>
          </ul>
        </div>
      </Col>
      <div style={{ borderLeft: '1px solid lightgrey', margin: '0 5px' }}></div>
      <Col span={6}>
        <h5>Parte arrendataria</h5>
        <div style={{ paddingLeft: '5%' }}>
          <h4>
            <b>Datos del Arrendatario</b>
          </h4>
          <ul>
            <li>
              <b>Nombre:</b> {props.data.leaseHolderName}
            </li>
            <li>
              <b>Apellidos/Razón social:</b> {props.data.leaseHolderLastOrTradeName}
            </li>
            <li>
              <b>Dirección:</b> {props.data.leaseHolderAddress}
            </li>
            <li>
              <b>NIF:</b> {props.data.leaseHolderNif}
            </li>
            <li>
              <b>IBAN:</b> {props.data.leaseHolderIBAN}
            </li>
          </ul>
          <h4>
            <b>Representante</b>
          </h4>
          <ul>
            <li>
              <b>Nombre:</b> {props.data.leaseHolderContractSignatureName}
            </li>
            <li>
              <b>Apellidos:</b> {props.data.leaseHolderContractSignatureLastName}
            </li>
            <li>
              <b>Cargo:</b> {props.data.leaseHolderContractSignatureRole}
            </li>
            <li>
              <b>DNI:</b> {props.data.leaseHolderContractSignatureNif}
            </li>
            <li>
              <b>Correo electrónico:</b> {props.data.leaseHolderContractSignatureEmail}
            </li>
          </ul>
          <h4>
            <b>Trabajadores que se van a alojar</b>
          </h4>
          <ul>
            {tenants.length > 0 &&
              tenants.map((tenant, index) => {
                const tenantData = tenant.split(',');

                return (
                  <li key={`tenant-${index}`}>
                    <b>{tenantData[0]}</b> con DNI <b>{tenantData[1]}</b>
                  </li>
                );
              })}
            {tenants.length === 0 && <li>No hay Inquilinos agregados.</li>}
          </ul>
        </div>
      </Col>
      <div style={{ borderLeft: '1px solid lightgrey', margin: '0 5px' }}></div>
      <Col span={6}>
        <h5>Condiciones del contrato</h5>
        <div>
          <ul>
            <li>
              <b>Dirección del inmueble:</b> {props.data.proposalAddress}
            </li>
            <li>
              <b>Fecha de entrada:</b> {props.data.startDate}
            </li>
            <li>
              <b>Fecha de salida:</b> {props.data.endDate}
            </li>
            <li>
              <b>Número de días:</b> {props.data.days}
            </li>
            <li>
              <b>Total inquilinos:</b> {props.data.pax}
            </li>
            <li>
              <b>Límite de suministros incluidos:</b> {props.data.spendingLimit}
            </li>
            {props.data.showTotalPrice && (
              <li>
                <b>Precio total:</b> {props.data.price}
              </li>
            )}
            {!props.data.showTotalPrice && (
              <li>
                <b>Precio mes:</b> {props.data.price}
              </li>
            )}
            <li>
              <b>Fiscalidad de la renta:</b> {props.data.taxInfo.replace('NO_TAX', 'impuestos')}
            </li>
            <li>
              <b>Fianza:</b> {props.data.deposit}
            </li>
            <li>
              <b>Gastos de limpieza (imp. incl.):</b> {props.data.cleaningCharges}
            </li>
            <li>
              <b>Periodicidad de la limpieza:</b> {props.data.cleaningChargeType}
            </li>
          </ul>
        </div>
      </Col>
      <style jsx>
        {`
          ul {
            padding-left: 25px;
          }
        `}
      </style>
    </Row>
  );
};

export default ContractData;
