import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import numeral from 'numeral';
import moment from 'moment';
import { getPmaTableData } from '../../modules/Pmas/PmasActions';
import { externalPriceAdjustment } from '../../shared/utils/appUtils';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 showHomybrain -> OPTIONAL boolean - show homybrain pma
 showExternal -> OPTIONAL boolean - show external pma
 showTitle -> OPTIONAL string - title to show in table
 showLegend -> show info at the bottom of the table for
 the periods marked in red. if there are
 various tables, preferably mark "true"
 only in the last one.
 ******************************************************* */

class TableDataPmas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pmasApproximate: {},
      showLegend: false,
    };

    this.getTimePeriod = this.getTimePeriod.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.state.pmasApproximate) !== JSON.stringify(nextProps.pmasApproximate)) {
      this.setState({
        pmasApproximate: nextProps.pmasApproximate,
      });
    }
  }

  getTimePeriod(period) {
    if (!period) {
      return '';
    }

    const firstWeek = period[0];
    const lastWeek = period.pop();
    // eslint-disable-next-line
    let firstDate = firstWeek ? moment(parseInt(firstWeek) + 1, 'YYYYww').locale('es') : null;
    // eslint-disable-next-line
    let lastDate = lastWeek ? moment(parseInt(lastWeek) + 1, 'YYYYww').locale('es') : null;

    return (
      (firstDate ? firstDate.startOf('week').format('DD/MM/YYYY') : '') +
      (lastDate ? `-${lastDate.endOf('week').format('DD/MM/YYYY')}` : '')
    );
  }

  render() {
    const columns = [
      {
        title: 'Hab.',
        dataIndex: 'rooms',
      },
    ];

    if (this.props.showHomybrain) {
      columns.push(
        {
          title: '€',
          dataIndex: '',
          render: record =>
            record && record.homybrainPrice
              ? numeral(record.homybrainPrice.amount).format('0.00')
              : 'No hay info',
        },
        {
          title: 'Periodo',
          // className : 'fontSizeSmall',
          dataIndex: '',
          // render: (record) => (<span style={{color: + record && !record.requiredExternalPeriod ? 'red' : 'inherit'}}>
          //     {record && record.homybrainCount > 0 ? this.getTimePeriod(record.homybrainPeriod) : ''}
          // </span>)
          render: record => (
            <span
              style={{
                color: +record && !record.requiredHomybrainPeriod ? 'red' : 'inherit',
              }}
            >
              {record && record.homybrainCount > 0
                ? this.getTimePeriod(record.homybrainPeriod)
                : ''}
            </span>
          ),
        }
      );
    }

    if (this.props.showExternal) {
      columns.push(
        {
          title: '€',
          dataIndex: '',
          render: record =>
            record && record.externalPrice && externalPriceAdjustment(record.externalPrice) !== ''
              ? externalPriceAdjustment(record.externalPrice)
              : 'No hay info',
        },
        {
          title: 'Periodo',
          // className : 'fontSizeSmall',
          dataIndex: '',
          render: record => (
            <span
              style={{
                color: +(record && !record.requiredExternalPeriod ? 'red' : 'inherit'),
              }}
            >
              {record && record.externalCount > 0 ? this.getTimePeriod(record.externalPeriod) : ''}
            </span>
          ),
        }
      );
    }

    return (
      <div className='animated fadeIn'>
        <div className='col-sm-12 noPadding'>
          {this.state.pmasApproximate &&
            this.state.pmasApproximate.list &&
            this.state.pmasApproximate.list.pMAApproxList && (
              <div>
                {this.props.showTitle && (
                  <div className='fontSizeBig bold'>{this.props.showTitle}</div>
                )}
                <Table
                  dataSource={this.state.pmasApproximate.list.pMAApproxList}
                  columns={columns}
                  rowKey={rec =>
                    `${(this.props.showHomybrain ? 'B' : '') +
                      (this.props.showExternal ? 'E' : '') +
                      rec.city}-${rec.zipCode}-${rec.week}-${rec.rooms}`
                  }
                  pagination={false}
                  size='small'
                />
              </div>
            )}
          {(!this.state.pmasApproximate ||
            !this.state.pmasApproximate.list ||
            !this.state.pmasApproximate.list.pMAApproxList) && (
            <p>
              No hay información de PMA {this.props.showHomybrain ? ' de homybrain' : ''}
              {this.props.showExternal ? ' de fuentes externas' : ''} para la zona
            </p>
          )}
        </div>
        {this.state.showLegend && (
          <div className='col-sm-12 fontSizeSmall' style={{ color: 'red' }}>
            * En rojo los PMAs disponibles en Homybrain, cuyo periodo no corresponde con las fechas
            de inicio/fin de alquiler indicadas.
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pmasApproximate: state.Pmas.pmasApproximate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getPmaTableData: url => dispatch(getPmaTableData(url)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableDataPmas);
