import {
  MAIN_METASOURCE_SUCCESS,
  METASOURCE_STRINGS_ERROR,
  METASOURCE_STRINGS_SUCCESS,
  OPTION_LABELS_SUCCESS,
  PARENT_VALUES_SUCCESS,
  RENTAL_REQUEST_ACTIVE_STAGES_FOR_FILTER,
  USER_INFORMATION_SUCCESS,
} from '../../shared/actionTypes';
import AwsUtils from '../../shared/utils/awsUtils.js';

const initialState = {
  metasourceStrings: {},
  optionLabels: {},
  parentValues: {},
  metasourceStringsError: false,
  metasourceStringsErrorDetail: '',
  mainMetasource: {},
  currentUserInformation: {},
  rentalRequestActiveStagesForFilteredCall: '',
};

const Preload = (state = initialState, action) => {
  switch (action.type) {
    case METASOURCE_STRINGS_SUCCESS: {
      return Object.assign({}, state, {
        metasourceStrings: action.metasourceStrings,
        metasourceStringsError: false,
        metasourceStringsErrorDetail: '',
      });
    }

    case OPTION_LABELS_SUCCESS: {
      return Object.assign({}, state, {
        optionLabels: action.optionLabels,
      });
    }

    case PARENT_VALUES_SUCCESS: {
      return Object.assign({}, state, {
        parentValues: action.parentValues,
      });
    }

    case METASOURCE_STRINGS_ERROR: {
      return Object.assign({}, state, {
        metasourceStrings: {},
        optionLabels: {},
        metasourceStringsError: true,
        metasourceStringsErrorDetail: action.errorDetail,
      });
    }

    case MAIN_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        mainMetasource: action.mainMetasource,
      });
    }

    case USER_INFORMATION_SUCCESS: {
      const awsUtils = new AwsUtils();

      awsUtils.setAwsCredentials(action.awsCredentials);
      awsUtils.setS3Endpoint(action.s3Endpoint);

      return Object.assign({}, state, {
        currentUserInformation: {
          username: action.username,
          awsCredentials: action.awsCredentials,
          s3Endpoint: action.s3Endpoint,
        },
      });
    }

    case RENTAL_REQUEST_ACTIVE_STAGES_FOR_FILTER: {
      return Object.assign({}, state, {
        rentalRequestActiveStagesForFilteredCall: action.rentalRequestActiveStagesForFilteredCall,
      });
    }

    default:
      return state;
  }
};

export default Preload;
