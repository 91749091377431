import React, { Component } from 'react';
import { Table } from 'antd';
import numeral from 'numeral';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 data -> data to show intable
 loading -> to show a spiner
 ******************************************************* */

class TableAveragePmas extends Component {
  externalPriceAdjustment(price) {
    if (price) {
      let { amount } = price;

      if (!amount || amount === 0) {
        return '';
      }

      amount += amount * 0.3 + 100;

      return numeral(amount).format('0.00');
    }

    return '';
  }

  render() {
    const columns = [
      {
        title: 'Habitaciones',
        dataIndex: 'rooms',
      },
      {
        title: 'Precio homybrain',
        dataIndex: 'homybrainPrice',
        render: record => (record ? numeral(record.amount).format('0.00') : ''),
      },
      {
        title: 'Muestras homybrain',
        dataIndex: 'homybrainCount',
      },
      {
        title: 'Precio externo',
        dataIndex: 'externalPrice',
        render: record => this.externalPriceAdjustment(record),
      },
      {
        title: 'Muestras externas',
        dataIndex: 'externalCount',
      },
    ];

    return (
      <div className='animated fadeIn'>
        <div className='col-sm-12'>
          {this.props.data && (
            <div>
              <Table
                bordered
                dataSource={this.props.data}
                columns={columns}
                rowKey={rec => rec.rooms}
                pagination={false}
                loading={this.props.loading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TableAveragePmas;

// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { Table } from 'antd';
// import numeral from 'numeral';
// import { getPmaAveragesTableData } from '../../modules/Pmas/PmasActions';
// import { openNotificationWithIcon } from '../../shared/utils/appUtils';

// /** *****************************************************
//  PROPS TO CONFIGURE IN PARENT
//  url -> url to obtain PmaAverages
//  ******************************************************* */

// class TableAveragePmas extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       loading: true,
//     };
//   }

//   componentDidMount() {
//     this.setState({ loading: true }, () =>
//       this.props.actions.getPmaAveragesTableDataPrincipal(this.props.url)
//     );
//   }

//   UNSAFE_componentWillReceiveProps(nextProps) {
//     if (nextProps.pmasAverageList && nextProps.pmasAverageList.error) {
//       openNotificationWithIcon(
//         'error',
//         'Oppss :(',
//         nextProps.pmasAverageList.errorDetail
//       );
//     } else if (
//       JSON.stringify(nextProps.pmasAverageList) !==
//       JSON.stringify(this.state.pmasAverageList)
//     ) {
//       this.setState({
//         pmasAverageList: nextProps.pmasAverageList,
//         loading: false,
//       });
//     }
//     if (this.props.url !== nextProps.url) {
//       this.props.actions.getPmaAveragesTableDataPrincipal(nextProps.url);
//     }
//   }

//   externalPriceAdjustment(price) {
//     if (price) {
//       let amount = price.amount;
//       if (!amount || amount === 0) {
//         return '';
//       }
//       amount += amount * 0.3 + 100;
//       return numeral(amount).format('0.00');
//     }
//     return '';
//   }

//   render() {
//     const columns = [
//       {
//         title: 'Habitaciones',
//         dataIndex: 'rooms',
//       },
//       {
//         title: 'Precio homybrain',
//         dataIndex: 'homybrainPrice',
//         render: record => (record ? numeral(record.amount).format('0.00') : ''),
//       },
//       {
//         title: 'Muestras homybrain',
//         dataIndex: 'homybrainCount',
//       },
//       {
//         title: 'Precio externo',
//         dataIndex: 'externalPrice',
//         render: record => this.externalPriceAdjustment(record),
//       },
//       {
//         title: 'Muestras externas',
//         dataIndex: 'externalCount',
//       },
//     ];
//     return (
//       <div className="animated fadeIn">
//         <div className="col-sm-12">
//           {this.state.pmasAverageList &&
//             this.state.pmasAverageList.list &&
//             this.state.pmasAverageList.list.pmaaverages && (
//               <div>
//                 <Table
//                   bordered
//                   dataSource={this.state.pmasAverageList.list.pmaaverages}
//                   columns={columns}
//                   rowKey={rec => rec.rooms}
//                   pagination={false}
//                   loading={this.state.loading}
//                 />
//               </div>
//             )}
//         </div>
//       </div>
//     );
//   }
// }

// function mapStateToProps(state) {
//   return {
//     pmasAverageList: state.Pmas.pmasAverageList,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     actions: {
//       getPmaAveragesTableDataPrincipal: url =>
//         dispatch(getPmaAveragesTableData(url)),
//     },
//   };
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TableAveragePmas);
