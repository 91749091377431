import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import tableUtils from '../../shared/utils/tableUtils';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';

const mapStyle = {
  width: '100%',
  height: tableUtils.getTableHeight() + 135,
};

const DEMAND_COLOR = '#FF0000';
const SUPPLY_COLOR = '#23dc00';

class SupplyDemandMap extends Component {
  constructor(props) {
    super(props);
    if (!window.google) {
      return;
    }

    this.state = {
      maps: window.google.maps,
      map: {},
      supplyCircles: [],
      demandCircles: [],
      initialPosition: {
        latitude: 40.416775,
        longitude: -3.70379,
      },
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadMap();
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(oldProps.data)) {
      for (const circle of this.state.supplyCircles) {
        circle.setMap(null);
      }
      for (const circle of this.state.demandCircles) {
        circle.setMap(null);
      }
      this.setState({ circles: [] }, () => this.loadData());
    }

    if (this.props.showSupply !== oldProps.showSupply) {
      for (const circle of this.state.supplyCircles) {
        circle.setMap(this.props.showSupply ? this.state.map : null);
      }
    }

    if (this.props.showDemand !== oldProps.showDemand) {
      for (const circle of this.state.demandCircles) {
        circle.setMap(this.props.showDemand ? this.state.map : null);
      }
    }
  }

  loadData() {
    const opos = get(this.props, 'data.listClustersOpportunities', []);
    const properties = get(this.props, 'data.listClustersProperties', []);
    const radius = get(this.props, 'radius', 0) !== 0 ? this.props.radius * 1000 : 2500;

    if (!isEmpty(opos) || !isEmpty(properties)) {
      const demandCircles = [];
      const supplyCircles = [];
      const map = this.state.map;

      for (const opo of opos) {
        const circle = new this.state.maps.Circle({
          strokeColor: DEMAND_COLOR,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: DEMAND_COLOR,
          fillOpacity: 0.35,
          map,
          center: {
            lat: opo.center.latitude,
            lng: opo.center.longitude,
          },
          radius,
        });
        circle.addListener('click', () => {
          this.props.onClusterClick(opo);
        });
        demandCircles.push(circle);
      }
      this.setState({ demandCircles });

      for (const p of properties) {
        const circle = new this.state.maps.Circle({
          strokeColor: SUPPLY_COLOR,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: SUPPLY_COLOR,
          fillOpacity: 0.35,
          map,
          center: {
            lat: p.center.latitude,
            lng: p.center.longitude,
          },
          radius,
        });
        circle.addListener('click', () => {
          this.props.onClusterClick(p);
        });
        supplyCircles.push(circle);
      }
      this.setState({ supplyCircles });
    }
  }

  loadMap() {
    const { maps, initialPosition } = this.state;

    if (maps) {
      const { loadCallback, clickCallback, dblclickCallback } = this.props;

      const mapRef = this.refs.map;
      // eslint-disable-next-line react/no-find-dom-node
      const node = ReactDOM.findDOMNode(mapRef);

      if (!node) {
        return;
      }

      const zoom = 7;
      const lat = initialPosition.latitude;
      const lng = initialPosition.longitude;
      const center = new this.state.maps.LatLng(lat, lng);
      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
          zoomControl: true,
          zoomControlOptions: {
            position: maps.ControlPosition.TOP_LEFT,
          },
          streetViewControl: true,
          streetViewControlOptions: {
            position: maps.ControlPosition.LEFT_TOP,
          },
          fullscreenControl: false,
          styles: [
            {
              featureType: 'poi',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }
      );

      this.map = new this.state.maps.Map(node, mapConfig);

      if (!isNull(loadCallback) && isFunction(loadCallback)) {
        maps.event.addListenerOnce(this.map, 'idle', loadCallback);
      }

      if (!isNull(clickCallback) && isFunction(clickCallback)) {
        maps.event.addListener(this.map, 'click', clickCallback);
      }

      if (!isNull(dblclickCallback) && isFunction(dblclickCallback)) {
        maps.event.addListener(this.map, 'dblclick', dblclickCallback);
      }

      this.setState({ map: this.map }, () => this.loadData());
    }
  }

  render() {
    return (
      <div style={mapStyle} ref='map'>
        <div className='height100PerCent loading-background' />
      </div>
    );
  }
}

export default SupplyDemandMap;
