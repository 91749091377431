import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { Button, Col, Modal, Row } from 'antd';
import { pairRoundAmount, validateInputs } from '../../shared/utils/formUtils';
import { getOptionsForSelect, openNotificationWithIcon } from '../../shared/utils/appUtils';
import CheckboxButton from '../CheckboxButton';
import { PARSE_DATE_FORMAT } from '../../shared/appConfig.js';
import TableDataInvoiceDetails from '../TableDataInvoiceDetails';
import {
  disableErrorAction,
  generateInvoicePdf,
  getInvoiceDetailsData,
  saveInvoiceDetailsData,
} from '../../modules/InvoiceDetails/InvoiceDetailsActions.js';
import { getNextInvoiceData, nextInvoiceDataSuccess } from '../../modules/Invoices/InvoicesActions';
import get from 'lodash/get';
import { INVOICE_TYPES, INVOICE_TYPES_OPTIONS_ES } from 'hs-utils';

class FormInvoiceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceDetails: get(props, 'invoiceDetailsData.data.detail', {}),
      dealDetails: get(props, 'dealDetails.data.detail', {}),
      submitting: false,
      submitChangeState: false,
      changeState: false,
      nextInvoiceData: {},
      modalNextInvoice: false,
      generatePdf: {
        inProgress: false,
        error: false,
      },
      readyToGenerate: false,
      options: {
        invoiceType: INVOICE_TYPES_OPTIONS_ES,
        invoiceState: getOptionsForSelect(this.props.optionLabels, 'InvoiceState'),
        receiverType: getOptionsForSelect(this.props.optionLabels, 'ReceiverType'),
        registeredInRoi: getOptionsForSelect(this.props.optionLabels, 'RegisteredInRoi'),
      },
      auxIssueDate: moment
        .utc()
        .hour(12)
        .minute(0)
        .second(0)
        .millisecond(0)
        .valueOf(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.openCloseModalNextInvoice = this.openCloseModalNextInvoice.bind(this);
    this.handleNextInvoiceChange = this.handleNextInvoiceChange.bind(this);
    this.handleNextInvoiceDateChange = this.handleNextInvoiceDateChange.bind(this);
    this.handleGeneratePdf = this.handleGeneratePdf.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.getInvoiceFileName = this.getInvoiceFileName.bind(this);
    this.showModalToChangeStateToDispatched = this.showModalToChangeStateToDispatched.bind(this);
    this.handleChangeStateToDispatched = this.handleChangeStateToDispatched.bind(this);
    this.handleChangeStateToPayed = this.handleChangeStateToPayed.bind(this);
    this.handleChangeStateToRectified = this.handleChangeStateToRectified.bind(this);
    this.handleChangeStateBackToDispatched = this.handleChangeStateBackToDispatched.bind(this);
    this.changeInvoiceState = this.changeInvoiceState.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.handleDisableError = this.handleDisableError.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.resetNextInvoiceNumber();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.state.invoiceDetails) !==
      JSON.stringify(nextProps.invoiceDetailsData.data.detail)
    ) {
      this.setState({
        invoiceDetails: nextProps.invoiceDetailsData.data.detail,
      });

      if (this.state.readyToGenerate) {
        this.setState(
          {
            generatePdf: {
              inProgress: true,
            },
            readyToGenerate: false,
          },
          () => {
            this.props.actions.generateInvoicePdf();
          }
        );
      }
    }

    if (
      JSON.stringify(this.state.dealDetails) !==
      JSON.stringify(nextProps.dealDetailsData.data.detail)
    ) {
      this.setState({ dealDetails: nextProps.dealDetailsData.data.detail });
    }

    if (
      JSON.stringify(this.state.generatePdf) !==
      JSON.stringify(nextProps.invoiceDetailsData.generatePdf)
    ) {
      if (nextProps.invoiceDetailsData.generatePdf.error) {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          nextProps.invoiceDetailsData.generatePdf.errorDetail
        );
        this.setState({
          generatePdf: {
            inProgress: false,
            error: false,
          },
        });
        this.handleDisableError();
      } else if (
        this.state.generatePdf.inProgress &&
        !nextProps.invoiceDetailsData.generatePdf.inProgress
      ) {
        this.downloadPdf(
          nextProps.invoiceDetailsData.generatePdf.pdfData,
          nextProps.invoiceDetailsData.generatePdf.fileName
        );
        this.setState({
          generatePdf: {
            data: '',
            inProgress: false,
            error: false,
            errorDetail: '',
          },
        });
      }
    }

    if (this.state.submitting) {
      this.props.actions.getInvoiceDetailsData();
      if (!nextProps.invoiceDetailsData.submit.error) {
        if (!this.state.submitChangeState) {
          // Don't show submit success message if it
          // comes from changing invoice state to SEND
          openNotificationWithIcon(
            'success',
            'Todo OK :)',
            'Los cambios se han registrado correctamente'
          );
        }

        if (this.state.changeState) {
          if (
            this.state.invoiceDetails.invoiceType === 'REGULAR' ||
            this.state.invoiceDetails.invoiceType === 'CORRECTIVE'
          ) {
            this.props.actions.getNextInvoiceData(
              this.state.invoiceDetails.invoiceType,
              this.state.invoiceDetails.proposalIds
            );
          } else {
            const auxNextInvoiceData = {
              minimunDate: moment
                .utc()
                .hour(12)
                .minute(0)
                .second(0)
                .millisecond(0)
                .valueOf(),
              nextNumber: '0',
            };
            this.setState({
              nextInvoiceData: auxNextInvoiceData,
              modalNextInvoice: true,
              submitChangeState: false,
            });
          }
        }
      } else {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          nextProps.invoiceDetailsData.submit.errorDetail
        );
      }

      this.setState({
        submitting: false,
        changeState: false,
      });
    }

    if (
      this.state.submitChangeState &&
      JSON.stringify(this.state.nextInvoiceData) !== JSON.stringify(nextProps.nextInvoiceData.data)
    ) {
      const auxNextInvoiceData = nextProps.nextInvoiceData.data;

      if (auxNextInvoiceData && auxNextInvoiceData.minimunDate) {
        auxNextInvoiceData.minimunDate = moment
          .utc(auxNextInvoiceData.minimunDate)
          .hour(12)
          .minute(0)
          .second(0)
          .millisecond(0)
          .valueOf();
      }

      this.setState({
        nextInvoiceData: auxNextInvoiceData,
        modalNextInvoice: true,
        submitChangeState: false,
      });
    }
  }

  handleChange(e) {
    if (this.isDisabled()) {
      return;
    }

    const newInvoiceDetails = this.state.invoiceDetails;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    newInvoiceDetails[e.target.name] = value;
    this.setState({ invoiceDetails: newInvoiceDetails });
  }

  handleDateChange(e) {
    if (this.isDisabled()) {
      return;
    }

    const newInvoiceDetails = this.state.invoiceDetails;

    newInvoiceDetails[e.target.name] = e.target.value ? moment(e.target.value) : null;
    this.setState({ invoiceDetails: newInvoiceDetails });
  }

  handleNextInvoiceChange(e) {
    const { nextInvoiceData } = this.state;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    nextInvoiceData[e.target.name] = value;
    this.setState({ nextInvoiceData });
  }

  handleNextInvoiceDateChange(e) {
    const newDate = e.target.value
      ? moment
          .utc(e.target.value)
          .hour(12)
          .minute(0)
          .second(0)
          .millisecond(0)
          .valueOf()
      : null;

    if (newDate && newDate >= this.state.nextInvoiceData.minimunDate) {
      this.setState({ auxIssueDate: newDate });
    }
  }

  handlePriceChange(e) {
    if (this.isDisabled()) {
      return;
    }

    const newInvoiceDetails = this.state.invoiceDetails;
    const targetComponents = e.target.name.split('.');

    if (e.target.value !== null && e.target.value !== '') {
      if (newInvoiceDetails[targetComponents[0]]) {
        newInvoiceDetails[targetComponents[0]][targetComponents[1]] = parseFloat(e.target.value);
      } else {
        newInvoiceDetails[targetComponents[0]] = {
          currency: 'EUR',
          amount: parseFloat(e.target.value),
        };
      }
    } else {
      newInvoiceDetails[targetComponents[0]] = null;
    }

    this.setState({ invoiceDetails: newInvoiceDetails });
  }

  handleSelectChange(name, selectedOption) {
    if (this.isDisabled()) {
      return;
    }

    const newInvoiceDetails = this.state.invoiceDetails;

    if (selectedOption) {
      newInvoiceDetails[name] = selectedOption.value;
    } else {
      newInvoiceDetails[name] = null;
    }

    this.setState({ invoiceDetails: newInvoiceDetails });
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    if (!validForm) {
      openNotificationWithIcon(
        'error',
        'Revisa los datos que has puesto',
        'Parece que hay algún error en los datos que has introducido'
      );
    }

    return validForm;
  }

  handleSubmit(isChangingState = false) {
    if (this.isValidForm()) {
      this.setState(
        {
          submitting: true,
          changeState: isChangingState,
        },
        () => this.props.actions.saveInvoiceDetailsData(this.state.invoiceDetails)
      );
    }
  }

  openCloseModalNextInvoice() {
    this.setState({
      modalNextInvoice: !this.state.modalNextInvoice,
    });
  }

  showModalToChangeStateToDispatched() {
    this.setState(
      {
        submitChangeState: true,
        nextInvoiceData: {},
      },
      () => this.handleSubmit(true)
    );
  }

  handleChangeStateToDispatched() {
    if (this.isValidForm()) {
      const { invoiceDetails } = this.state;

      if (this.props.invoiceDetailsData.data.detail.invoiceType !== INVOICE_TYPES.PROFORMA) {
        invoiceDetails.invoiceNumber = this.state.nextInvoiceData.nextNumber;
      } else {
        invoiceDetails.invoiceNumber = null;
      }

      invoiceDetails.issueDate = this.state.auxIssueDate;
      invoiceDetails.invoiceState = 'DISPATCHED';
      this.setState(
        {
          modalNextInvoice: false,
          invoiceDetails,
        },
        () => this.handleSubmit()
      );
    }
  }

  handleGeneratePdf() {
    this.setState({
      readyToGenerate: true,
    });
    this.handleSubmit();
  }

  // eslint-disable-next-line no-unused-vars
  downloadPdf(data, responseFileName) {
    // const fileName = responseFileName ? responseFileName.split('"') : ''; // quitar los comentarios cuando esté gestionado desde el back
    const a = document.createElement('a');
    const file = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);

    a.href = url;
    a.type = 'application/pdf';
    // a.download = responseFileName ? fileName[1] : this.getInvoiceFileName();
    a.download = this.getInvoiceFileName();
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getInvoiceFileName() {
    const invoice = this.state.invoiceDetails;
    const DRAFT = 'borrador';
    const PROFORMA = 'proforma';
    const CORRECTIVE = 'corrective';
    const lastOTradeName =
      invoice.lastOTradeName && invoice.lastOTradeName !== ''
        ? invoice.lastOTradeName.replace(new RegExp(/[\\\\/:*?"<>|.]/g), '')
        : '';

    if (invoice.invoiceType.toLowerCase() === PROFORMA) {
      return (
        PROFORMA +
        (lastOTradeName !== '' ? ` ${lastOTradeName}` : '') +
        (invoice.dealReference && invoice.dealReference !== '' ? ` ${invoice.dealReference}` : '')
      );
    }

    if (invoice.invoiceNumber) {
      let fileName = '';
      if (invoice.invoiceNumberSequence === 'IGIC_SEQUENCE') {
        fileName += 'CN';
      }
      if (invoice.invoiceType.toLowerCase() === CORRECTIVE) {
        fileName += 'FRE';
      }
      return fileName + invoice.invoiceNumber + (lastOTradeName !== '' ? ` ${lastOTradeName}` : '');
    }

    return DRAFT + (lastOTradeName !== '' ? ` ${lastOTradeName}` : '');
  }

  handleChangeStateToPayed() {
    this.changeInvoiceState('PAYED');
  }

  handleChangeStateToRectified() {
    this.changeInvoiceState('RECTIFIED');
  }

  handleChangeStateBackToDispatched() {
    this.changeInvoiceState('DISPATCHED');
  }

  handleDisableError() {
    this.props.actions.disableErrorAction();
  }

  changeInvoiceState(nextState) {
    if (this.isValidForm()) {
      const { invoiceDetails } = this.state;

      invoiceDetails.invoiceState = nextState;
      this.setState(
        {
          invoiceDetails,
        },
        () => this.handleSubmit()
      );
    }
  }

  isDisabled() {
    return (
      !this.state.invoiceDetails ||
      (this.state.invoiceDetails && this.state.invoiceDetails.invoiceState !== 'CREATED')
    );
  }

  render() {
    return (
      <div className='row'>
        <div className='col-sm-2'>
          {/* BOTONES DE ACCIÓN ************************************** */}
          <div className='row'>
            <div className='col-sm-12'>
              {this.state.invoiceDetails && this.state.invoiceDetails.invoiceState === 'CREATED' && (
                <Button
                  loading={this.state.submitting}
                  htmlType='submit'
                  className='btn btn-primary btn-block alignLeft'
                  onClick={this.handleSubmit}
                >
                  Guardar
                </Button>
              )}
              <Button
                disabled={this.state.submitting || this.state.invoiceDetails.entries === null}
                className='btn btn-primary btn-block alignLeft buttonBreakLine'
                onClick={this.handleGeneratePdf}
                loading={this.state.generatePdf.inProgress}
              >
                {this.state.generatePdf.inProgress ? 'Generando pdf...' : 'Generar pdf'}
              </Button>
              {this.state.invoiceDetails && this.state.invoiceDetails.invoiceState === 'CREATED' && (
                <Button
                  disabled={this.state.submitting}
                  className='btn btn-primary btn-block alignLeft buttonBreakLine'
                  onClick={this.showModalToChangeStateToDispatched}
                >
                  Expedir factura
                </Button>
              )}
              {this.state.invoiceDetails &&
                this.state.invoiceDetails.invoiceState === 'DISPATCHED' && (
                  <Button
                    disabled={this.state.submitting}
                    className='btn btn-primary btn-block alignLeft buttonBreakLine'
                    onClick={this.handleChangeStateToPayed}
                  >
                    Marcar como pagada
                  </Button>
                )}
              {this.state.invoiceDetails &&
                this.state.invoiceDetails.invoiceState === 'DISPATCHED' && (
                  <Button
                    disabled={this.state.submitting}
                    className='btn btn-primary btn-block alignLeft buttonBreakLine'
                    onClick={this.handleChangeStateToRectified}
                  >
                    Marcar como rectificada
                  </Button>
                )}
              {this.state.invoiceDetails &&
                (this.state.invoiceDetails.invoiceState === 'PAYED' ||
                  this.state.invoiceDetails.invoiceState === 'RECTIFIED') && (
                  <Button
                    disabled={this.state.submitting}
                    className='btn btn-primary btn-block alignLeft buttonBreakLine'
                    onClick={this.handleChangeStateBackToDispatched}
                  >
                    Volver a estado expedida
                  </Button>
                )}
              <Modal
                visible={this.state.modalNextInvoice}
                onOk={this.handleChangeStateToDispatched}
                okText='Aceptar'
                cancelText='Cancelar'
                onCancel={this.openCloseModalNextInvoice}
                title={`Fecha & Número de factura ${
                  this.state.invoiceDetails &&
                  this.state.invoiceDetails.invoiceType === INVOICE_TYPES.CORRECTIVE
                    ? 'rectificativa'
                    : ''
                }`}
              >
                <Row>
                  <Col span={24}>
                    <label htmlFor='nextInvoiceDataNumber' className='required'>
                      Número de factura
                    </label>
                    <div className='input-group'>
                      {this.state.invoiceDetails.invoiceType === INVOICE_TYPES.CORRECTIVE && (
                        <span className='input-group-addon'>FRE</span>
                      )}
                      <input
                        type='number'
                        className='form-control validate validate-required'
                        id='nextInvoiceDataNumber'
                        name='nextNumber'
                        placeholder='180001'
                        value={
                          this.state.nextInvoiceData.nextNumber
                            ? this.state.nextInvoiceData.nextNumber
                            : ''
                        }
                        onChange={this.handleNextInvoiceChange}
                      />
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col span={24}>
                    <label htmlFor='nextInvoiceDataDate' className='required'>
                      Fecha&nbsp;(mínima:&nbsp;
                      {this.state.nextInvoiceData.minimunDate
                        ? moment(this.state.nextInvoiceData.minimunDate).format(PARSE_DATE_FORMAT)
                        : ''}
                      )
                    </label>
                    <input
                      type='date'
                      className='form-control validate validate-required'
                      id='nextInvoiceDataDate'
                      name='minimunDate'
                      placeholder='01/01/2019'
                      value={moment(this.state.auxIssueDate).format(PARSE_DATE_FORMAT)}
                      onChange={this.handleNextInvoiceDateChange}
                    />
                  </Col>
                </Row>
              </Modal>
            </div>
          </div>
        </div>
        {/* CAMPOS DE FORMULARIO ************************************** */}
        <div className='col-sm-10'>
          <form id='formInvoiceDetails' action='' method='post'>
            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailInvoiceNumber'>Número de factura</label>
                  <input
                    type='text'
                    className='form-control backgroundWhite'
                    id='invoiceDetailInvoiceNumber'
                    name='invoiceNumber'
                    placeholder='180000'
                    disabled
                    value={
                      this.state.invoiceDetails.invoiceNumber
                        ? this.state.invoiceDetails.invoiceNumber
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailIssueDate'>Fecha </label>
                  <input
                    type='date'
                    className='form-control backgroundWhite'
                    id='invoiceDetailIssueDate'
                    name='issueDate'
                    placeholder='01/10/2017'
                    disabled
                    value={
                      this.state.invoiceDetails.issueDate
                        ? moment(this.state.invoiceDetails.issueDate).format(PARSE_DATE_FORMAT)
                        : ''
                    }
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailsInvoiceType' className='required'>
                    Tipo
                  </label>
                  <Select
                    name='invoiceType'
                    disabled
                    className='form-control validate validate-required backgroundWhite'
                    value={
                      this.state.invoiceDetails.invoiceType
                        ? this.state.invoiceDetails.invoiceType
                        : ''
                    }
                    options={this.state.options.invoiceType}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailAmount'>Total (imp. incl.)</label>
                  <input
                    type='text'
                    className='form-control invoiceBackgroundColor whiteText'
                    id='invoiceDetailAmount'
                    name='amount'
                    placeholder='100'
                    disabled
                    value={
                      this.state.invoiceDetails.amount
                        ? `${pairRoundAmount(this.state.invoiceDetails.amount.amount)} ${
                            this.state.invoiceDetails.amount.currency
                          }`
                        : ''
                    }
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailAmountWithNoTax'>Total base imponible</label>
                  <input
                    type='text'
                    className='form-control backgroundWhite'
                    id='invoiceDetailAmountWithNoTax'
                    name='amountWithNoTax'
                    placeholder='100'
                    disabled
                    value={
                      this.state.invoiceDetails.amount
                        ? `${pairRoundAmount(this.state.invoiceDetails.amountWithNoTax.amount)} ${
                            this.state.invoiceDetails.amountWithNoTax.currency
                          }`
                        : ''
                    }
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailsInvoiceState' className='required'>
                    Estado
                  </label>
                  <Select
                    name='invoiceState'
                    className='form-control validate validate-required backgroundWhite'
                    disabled
                    value={
                      this.state.invoiceDetails.invoiceState
                        ? this.state.invoiceDetails.invoiceState
                        : ''
                    }
                    options={this.state.options.invoiceState}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailsRecurrent'>&nbsp;</label>
                  <CheckboxButton
                    borderStyle='invoiceCheckboxActive'
                    id='invoiceDetailsRecurrent'
                    name='recurrent'
                    disabled={!!this.isDisabled()}
                    handleChange={this.handleChange}
                    content='Recurrente'
                    value={
                      this.state.invoiceDetails.recurrent
                        ? this.state.invoiceDetails.recurrent
                        : false
                    }
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailMarketingSourceMedium'>Fuente / medio</label>
                  <input
                    type='text'
                    className='form-control'
                    id='invoiceDetailMarketingSourceMedium'
                    name='marketingSourceMedium'
                    placeholder='Linkedin'
                    value={
                      this.state.invoiceDetails.marketingSourceMedium
                        ? this.state.invoiceDetails.marketingSourceMedium
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailHsAgent'>Creada por</label>
                  <input
                    type='text'
                    className='form-control'
                    disabled
                    id='invoiceDetailHsAgent'
                    name='username'
                    placeholder='manolete@homyspace.com'
                    value={
                      this.state.invoiceDetails.hsagent
                        ? this.state.invoiceDetails.hsagent.username
                        : ''
                    }
                  />
                </div>
              </div>
            </div>

            <hr className='hs-hr' />

            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailReceiverType' className='required'>
                    Destinatario
                  </label>
                  <Select
                    name='receiverType'
                    className='form-control'
                    value={get(this.state, 'invoiceDetails.receiverType', '')}
                    options={get(this.state, 'options.receiverType', '')}
                    disabled
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailIdentificationCode' className='required'>
                    NIF
                  </label>
                  <input
                    type='text'
                    className='form-control validate validate-required'
                    id='invoiceDetailIdentificationCode'
                    name='identificationCode'
                    placeholder='B123456789'
                    value={
                      this.state.invoiceDetails.identificationCode
                        ? this.state.invoiceDetails.identificationCode
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailLastOTradeName' className='required'>
                    Razón social
                  </label>
                  <input
                    type='text'
                    className='form-control validate validate-required'
                    id='invoiceDetailLastOTradeName'
                    name='lastOTradeName'
                    placeholder='El ladrillo feliz S.L.'
                    value={
                      this.state.invoiceDetails.lastOTradeName
                        ? this.state.invoiceDetails.lastOTradeName
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailReceiverAddress'>Dirección</label>
                  <input
                    type='text'
                    className='form-control'
                    id='invoiceDetailReceiverAddress'
                    name='receiverAddress'
                    placeholder='C/ 13 Rue del Percebe, 2'
                    value={
                      this.state.invoiceDetails.receiverAddress
                        ? this.state.invoiceDetails.receiverAddress
                        : ''
                    }
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailRegisteredInRoi'>Registrado en el ROI</label>
                  <Select
                    name='registeredInRoi'
                    className='form-control'
                    value={
                      this.state.invoiceDetails.registeredInRoi
                        ? this.state.invoiceDetails.registeredInRoi
                        : ''
                    }
                    options={this.state.options.registeredInRoi}
                    onChange={value => {
                      this.handleSelectChange('registeredInRoi', value);
                    }}
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='form-group'>
                  <label htmlFor='invoiceDetailSpanishReceiver'>&nbsp;</label>
                  <CheckboxButton
                    borderStyle='invoiceCheckboxActive'
                    id='invoiceDetailSpanishReceiver'
                    name='spanishReceiver'
                    handleChange={this.handleChange}
                    disabled={!!this.isDisabled()}
                    content='Receptor español'
                    value={
                      this.state.invoiceDetails.spanishReceiver
                        ? this.state.invoiceDetails.spanishReceiver
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          </form>
          <hr className='hs-hr' />
          <TableDataInvoiceDetails />
          <hr className='hs-hr' />
          <label htmlFor='footnotes'>Nota al pie</label>
          <textarea
            className='form-control'
            id='footnotes'
            name='footnotes'
            placeholder='Notas'
            value={this.state.invoiceDetails.footnotes ? this.state.invoiceDetails.footnotes : ''}
            onChange={this.handleChange}
          />
        </div>
        <style jsx>
          {`
            .input-group-addon {
              padding: 0.55rem 0.65rem;
              margin-bottom: 0;
              font-weight: normal;
              color: #55595c;
              text-align: center;
              background-color: #eee;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-right: 0px;
              border-top-left-radius: 0.25em;
              border-bottom-left-radius: 0.25em;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveInvoiceDetailsData: data => dispatch(saveInvoiceDetailsData(data)),
      getInvoiceDetailsData: () => dispatch(getInvoiceDetailsData()),
      getNextInvoiceData: (invoiceType, proposalIds) =>
        dispatch(getNextInvoiceData(invoiceType, proposalIds)),
      generateInvoicePdf: () => dispatch(generateInvoicePdf()),
      resetNextInvoiceNumber: () => dispatch(nextInvoiceDataSuccess({})),
      disableErrorAction: () => dispatch(disableErrorAction()),
    },
  };
}

function mapStateToProps(state) {
  return {
    invoiceDetailsData: state.InvoiceDetails,
    nextInvoiceData: state.Invoices.nextInvoiceData,
    dealDetailsData: state.DealDetails,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormInvoiceDetails);
