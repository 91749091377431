import {
  PROPOSALDETAILS_CONTRACTDATA_ERROR,
  PROPOSALDETAILS_CONTRACTDATA_RESET_ERROR,
  PROPOSALDETAILS_CONTRACTDATA_SUCCESS,
  PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_ERROR,
  PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_SUCCESS,
  PROPOSALDETAILS_DATA_SUCCESS,
  PROPOSALDETAILS_DELETE_ERROR,
  PROPOSALDETAILS_DELETE_SUCCESS,
  PROPOSALDETAILS_SUBMIT_ERROR,
  PROPOSALDETAILS_SUBMIT_SUCCESS,
  PROPOSALDETAILS_SUBMITTING,
} from '../../shared/actionTypes';

const initialState = {
  data: {
    url: '',
    etag: '',
    detail: {},
    error: false,
    errorDetail: '',
    links: {},
  },
  proposalDetails: {},
  submit: {
    error: false,
    errorDetail: '',
    submitting: false,
  },
  contractData: {
    data: {},
    error: false,
    errorDetail: '',
  },
};

const ProposalDetails = (state = initialState, action) => {
  switch (action.type) {
    case PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_SUCCESS:
    case PROPOSALDETAILS_SUBMIT_SUCCESS: {
      return Object.assign({}, state, {
        proposalDetails: action.proposalDetails,
        submit: {
          error: false,
          errorDetail: '',
          submitting: false,
        },
      });
    }

    case PROPOSALDETAILS_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.proposalDetailsSubmitErrorDetails,
          submitting: false,
        },
      });
    }

    case PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_ERROR: {
      return Object.assign({}, state, {
        submit: {
          errorStatus: action.proposalDetailsSubmitErrorStatus,
        },
      });
    }

    case PROPOSALDETAILS_DELETE_SUCCESS: {
      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
          submitting: false,
        },
      });
    }

    case PROPOSALDETAILS_SUBMITTING: {
      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
          submitting: action.submitting,
        },
      });
    }

    case PROPOSALDETAILS_DELETE_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.proposalDetailsDeleteErrorDetails,
          submitting: false,
        },
      });
    }

    case PROPOSALDETAILS_CONTRACTDATA_RESET_ERROR: {
      return Object.assign({}, state, {
        contractData: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPOSALDETAILS_CONTRACTDATA_SUCCESS: {
      return Object.assign({}, state, {
        contractData: {
          data: action.contractData,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROPOSALDETAILS_CONTRACTDATA_ERROR: {
      return Object.assign({}, state, {
        contractData: {
          data: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROPOSALDETAILS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          url: action.url,
          etag: action.etag,
          detail: action.proposalDetailsData,
          error: false,
          errorDetail: '',
          links: action.links,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
      });
    }

    default:
      return state;
  }
};

export default ProposalDetails;
