import { getAxiosInstance } from '../../shared/utils/apiUtils';

class RequestsApi {
  static getMetasource(url) {
    return this._doGet(url);
  }

  static getRequestsTableData(url) {
    return this._doGet(url);
  }

  static _doGet(url) {
    return getAxiosInstance().get(url);
  }

  static _doPost(url, body = {}) {
    return getAxiosInstance().post(url, body);
  }

  static _doPut(url, body) {
    return getAxiosInstance().put(url, body);
  }

  static _doPostWithData(url, data) {
    return getAxiosInstance().post(url, data);
  }
}

export default RequestsApi;
