import { browserHistory } from 'react-router';
import {
  NEW_PROVIDER_LOCATION_ERROR,
  NEW_PROVIDER_LOCATION_SUCCESS,
  PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR,
  PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS,
  PROVIDERS_METASOURCE_ERROR,
  PROVIDERS_METASOURCE_SUCCESS,
  PROVIDERS_TABLEDATA_ERROR,
  PROVIDERS_TABLEDATA_SUCCESS,
} from '../../shared/actionTypes';
import ProvidersApi from './ProvidersApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink } from '../../shared/utils/appUtils';

export const metasourceSuccess = metasourceLinks => ({
  type: PROVIDERS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: PROVIDERS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const tableDataSuccess = (tableData, totalCount) => ({
  type: PROVIDERS_TABLEDATA_SUCCESS,
  tableData,
  totalCount,
});

export const tablaDataError = errorMessage => ({
  type: PROVIDERS_TABLEDATA_ERROR,
  errorDetail: errorMessage,
});

export const newProviderLocationSuccess = location => ({
  type: NEW_PROVIDER_LOCATION_SUCCESS,
  location,
});

export const newProviderLocationError = errorMessage => ({
  type: NEW_PROVIDER_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const getMarketingSourceMediumOptionsSuccess = options => ({
  type: PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS,
  options,
});

export const getMarketingSourceMediumOptionsError = errorMessage => ({
  type: PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR,
  errorDetail: errorMessage,
});

export const getProvidersTableData = (purl = null) =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Providers;
      const { metasourceStrings } = getState().Preload;
      let url = purl;

      if (!url) {
        url = getMetasourceLink(
          metasource,
          metasourceStrings,
          'PROVIDER_MAIN_METASOURCE_PROVIDERS_PAGINATED'
        );
      }

      return ProvidersApi.getProvidersTableData(url.href)
        .then(response => {
          dispatch(tableDataSuccess(response.data, response.headers['x-total-count']));
        })
        .catch(error => {
          console.error(error);
          dispatch(tablaDataError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getMarketingSourceMediumOptions = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Providers.metasource,
      getState().Preload.metasourceStrings,
      'PROVIDER_MAIN_METASOURCE_PROVIDERS_MARKETING_SOURCE_MEDIUM'
    );

    return ProvidersApi.getProvidersMarketingSourceMediumOptions(url.href)
      .then(response => {
        dispatch(getMarketingSourceMediumOptionsSuccess(response));
      })
      .catch(error => {
        console.error(error);
        dispatch(getMarketingSourceMediumOptionsError(extractErrorMessage(error)));
      });
  };

export const loadProvidersMetasource = () =>
  function(dispatch, getState) {
    try {
      return ProvidersApi.getMetasource(getState().Preload.mainMetasource.providers.href)
        .then(response => {
          dispatch(metasourceSuccess(response.data._links));
          dispatch(getMarketingSourceMediumOptions());
          // dispatch(getProvidersTableData(1, 15));
        })
        .catch(error => {
          console.error(error);
          dispatch(metasourceError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getNewProviderLocation = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Providers.metasource,
      getState().Preload.metasourceStrings,
      'PROVIDER_MAIN_METASOURCE_NEW_PROVIDER'
    );

    return ProvidersApi.getNewProviderLocation(url.href)
      .then(response => {
        dispatch(newProviderLocationSuccess(response.headers.location));
      })
      .catch(error => {
        console.error(error);
        dispatch(newProviderLocationError(extractErrorMessage(error)));
      });
  };
