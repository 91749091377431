import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const HomyModal = ({ ...props }) => (
  <Modal
    className='hs-homy-modal'
    title={props.title}
    visible={props.visible}
    footer={null}
    // onOk={this.handleOk}
    destroyOnClose={props.destroyOnClose || false}
    onCancel={props.onCancel}
  >
    {props.children}
    <style jsx>
      {`
        .hs-homy-modal {
          width: 90% !important;
        }
        .hs-homy-modal .ant-modal-header {
          background-color: white !important;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 0.5rem;
          border-bottom: 1px solid #e9ecef;
          text-align: center;
        }
        .hs-homy-modal .ant-modal-title {
          color: black;
          font-size: 1.75rem;
          padding-top: 1rem;
          width: 100%;
        }
        .hs-homy-modal .ant-modal-body {
          font-family: 'Open Sans';
        }
        .hs-homy-modal h2 {
          font-family: 'nexa-bold' !important;
        }
      `}
    </style>
  </Modal>
);
HomyModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  children: PropTypes.element,
};

HomyModal.defaultProps = {
  title: '',
  visible: false,
  destroyOnClose: false,
};

export default HomyModal;
