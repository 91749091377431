import {
  INVOICES_METASOURCE_ERROR,
  INVOICES_METASOURCE_SUCCESS,
  INVOICES_NEXTINVOICEDATA_ERROR,
  INVOICES_NEXTINVOICEDATA_SUCCESS,
  INVOICES_TABLEDATA_ERROR,
  INVOICES_TABLEDATA_FILTER,
  INVOICES_TABLEDATA_SUCCESS,
  NEW_INVOICE_CREATE_ERROR,
  NEW_INVOICE_LOCATION_ERROR,
  NEW_INVOICE_LOCATION_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  filter: {},
  filterVersion: 0,
  invoicesData: {
    tableData: [],
    totalCount: 0,
    error: false,
    errorDetail: '',
  },
  nextInvoiceData: {
    data: {},
    error: false,
    errorDetail: '',
  },
  invoiceLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
};

const Invoices = (state = initialState, action) => {
  switch (action.type) {
    case INVOICES_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case INVOICES_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case INVOICES_TABLEDATA_FILTER: {
      const filterVersion = state.filterVersion + 1;

      return Object.assign({}, state, {
        filter: action.filterCriteria,
        filterVersion,
      });
    }

    case INVOICES_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        invoicesData: {
          tableData: action.tableData,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
        },
      });
    }

    case INVOICES_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        invoicesData: {
          tableData: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_INVOICE_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        invoiceLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_INVOICE_LOCATION_ERROR: {
      return Object.assign({}, state, {
        invoiceLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case INVOICES_NEXTINVOICEDATA_SUCCESS: {
      return Object.assign({}, state, {
        nextInvoiceData: {
          data: action.nextInvoiceData,
          error: false,
          errorDetail: '',
        },
      });
    }

    case INVOICES_NEXTINVOICEDATA_ERROR: {
      return Object.assign({}, state, {
        nextInvoiceData: {
          data: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_INVOICE_CREATE_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: true,
          errorDetail: action.createNewInvoiceErrorDetails,
        },
      });
    }

    default:
      return state;
  }
};

export default Invoices;
