import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { getAxiosInstance } from '../../shared/utils/apiUtils';
import { ENDPOINT } from '../../shared/appConfig.js';

class ProposalConfirmationThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      successMessage:
        props.location.query.confirmed === 'true'
          ? 'La confirmación se ha realizado. Gracias.'
          : 'Se van a revisar los datos del inmueble. Un agente contactará con usted pronto',
      errorMessage: 'Ha ocurrido un error. Por favor, contacta con un agente',
      submitting: true,
    };
  }

  componentDidMount() {
    const { proposalId } = this.props.location.query;
    const { confirmed } = this.props.location.query;

    getAxiosInstance()
      .put(ENDPOINT + `/proposal/public/${proposalId}/confirmation?confirmed=${confirmed}`)
      .then(_ => this.setState({ submitting: false, message: this.state.successMessage }))
      .catch(_ => this.setState({ submitting: false, message: this.state.errorMessage }));
  }

  render() {
    return (
      <div className='app flex-row align-items-center'>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 loadingCard'>
          <div className='shadow' style={{ width: 40 + 'vw' }}>
            <div className='bg-primary p-2'>
              <div className='logoHs'>&nbsp;</div>
            </div>
            {/* <div className='p-3 alignCenter' style={{backgroundColor:'white'}} dangerouslySetInnerHTML={{__html: this.state.message}}/> */}
            <div className='p-3 alignCenter' style={{ backgroundColor: 'white' }}>
              <br />
              {this.state.submitting && <h5>Espera, por favor...</h5>}
              {!this.state.submitting && <h5>{ReactHtmlParser(this.state.message)}</h5>}
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProposalConfirmationThankYouPage;
