import { SUPPLYDEMAND_SET_FILTER, SUPPLYDEMAND_SET_RESPONSE } from './SupplyDemandActions';

const SupplyDemand = (state = { response: {}, filter: {} }, action) => {
  switch (action.type) {
    case SUPPLYDEMAND_SET_FILTER: {
      return Object.assign({}, state, {
        filter: action.filter,
      });
    }
    case SUPPLYDEMAND_SET_RESPONSE: {
      return Object.assign({}, state, {
        response: action.response,
      });
    }
    default:
      return state;
  }
};

export default SupplyDemand;
