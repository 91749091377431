import { browserHistory } from 'react-router';
import {
  GET_IMPORT_STATUS_ERROR,
  GET_IMPORT_STATUS_SUCCESS,
  NEW_PROPERTY_LOCATION_ERROR,
  NEW_PROPERTY_LOCATION_SUCCESS,
  PROPERTIES_ACTIVE_BUSINESS_DATA,
  PROPERTIES_BY_DISTANCE_INMAP_FILTERED_ERROR,
  PROPERTIES_BY_DISTANCE_INMAP_FILTERED_SUCCESS,
  PROPERTIES_EXPORTDATA_ERROR,
  PROPERTIES_EXPORTDATA_SUCCESS,
  PROPERTIES_EXTERNAL_FILTER,
  PROPERTIES_FILTER,
  PROPERTIES_INMAP_FILTERED_ERROR,
  PROPERTIES_INMAP_FILTERED_SUCCESS,
  PROPERTIES_LIST_ERROR,
  PROPERTIES_LIST_SUCCESS,
  PROPERTIES_METASOURCE_ERROR,
  PROPERTIES_METASOURCE_SUCCESS,
  UPLOAD_IMPORT_FILE_ERROR,
  UPLOAD_IMPORT_FILE_SUCCESS,
} from '../../shared/actionTypes';
import PropertiesApi from './PropertiesApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink, resolveParams } from '../../shared/utils/appUtils';
import { getProvidersTableData } from '../Providers/ProvidersActions';
import { setPrintablePropertyDetailsData } from '../PropertyDetails/PropertyDetailsActions';
import moment from 'moment';
import get from 'lodash/get';

export const metasourceSuccess = metasourceLinks => ({
  type: PROPERTIES_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: PROPERTIES_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const propertiesInMapFilteredSuccess = (properties, filter, byRelevance = false) => ({
  type: PROPERTIES_INMAP_FILTERED_SUCCESS,
  properties,
  filter,
  byRelevance,
});

export const propertiesFilter = filter => ({
  type: PROPERTIES_FILTER,
  filter,
});

export const propertiesInMapFilteredError = errorMessage => ({
  type: PROPERTIES_INMAP_FILTERED_ERROR,
  errorDetail: errorMessage,
});

export const exportDataSuccess = data => ({
  type: PROPERTIES_EXPORTDATA_SUCCESS,
  message: data.message,
});

export const exportDataError = errorMessage => ({
  type: PROPERTIES_EXPORTDATA_ERROR,
  errorDetail: errorMessage,
});

export const propertiesByDistanceInMapFilteredSuccess = properties => ({
  type: PROPERTIES_BY_DISTANCE_INMAP_FILTERED_SUCCESS,
  properties,
});

export const propertiesByDistanceInMapFilteredError = errorMessage => ({
  type: PROPERTIES_BY_DISTANCE_INMAP_FILTERED_ERROR,
  errorDetail: errorMessage,
});

export const newPropertyLocationSuccess = location => ({
  type: NEW_PROPERTY_LOCATION_SUCCESS,
  location,
});

export const newPropertyLocationError = errorMessage => ({
  type: NEW_PROPERTY_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const propertyListSuccess = properties => ({
  type: PROPERTIES_LIST_SUCCESS,
  properties,
});

export const propertyListError = errorMessage => ({
  type: PROPERTIES_LIST_ERROR,
  errorDetail: errorMessage,
});

export const saveActiveBusinessData = activeBusiness => ({
  type: PROPERTIES_ACTIVE_BUSINESS_DATA,
  activeBusiness,
});

export const setPropertyExternalFilter = (filter, activeBusiness) => ({
  type: PROPERTIES_EXTERNAL_FILTER,
  filter,
  activeBusiness,
});

export const uploadImportFileSuccess = () => ({
  type: UPLOAD_IMPORT_FILE_SUCCESS,
});

export const uploadImportFileError = errorMessage => ({
  type: UPLOAD_IMPORT_FILE_ERROR,
  errorDetail: errorMessage,
});

export const getImportStatusSuccess = data => ({
  type: GET_IMPORT_STATUS_SUCCESS,
  data,
});

export const getImportStatusError = errorMessage => ({
  type: GET_IMPORT_STATUS_ERROR,
  errorDetail: errorMessage,
});

export const loadPropertiesMetasource = () =>
  function(dispatch, getState) {
    return PropertiesApi.getMetasource(getState().Preload.mainMetasource.properties.href)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const exportFilteredProperties = (
  maxPrice = null,
  guest = null,
  billable = null,
  comissionable = null,
  filter
) =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Properties.metasource,
      getState().Preload.metasourceStrings,
      'PROPERTY_MAIN_METASOURCE_PROPERTIES_EXPORTATION'
    );

    return PropertiesApi.getExportationProperties(url.href, {
      maxPrice,
      guest,
      billable,
      comissionable,
      startDate: filter.startDate ? filter.startDate : null,
      endDate: filter.endDate ? filter.endDate : null,
      beds: filter.beds,
      bedsMoreThan: filter.bedsMoreThan,
      rooms: filter.rooms,
      roomsMoreThan: filter.roomsMoreThan,
      bathsAndToilets: filter.baths,
      publishedInWeb: filter.publishedInWeb ? !filter.publishedInWeb : null,
      airConditioning: filter.airConditioning || null,
      wifi: filter.wifi || null,
      freeCarPark: filter.freeCarPark || null,
      lift: filter.lift || null,
      petsAllowed: filter.petsAllowed || null,
      cleaningChargeType: filter.cleaningChargeType || null,
      incorporationDateStart: filter.incorporationDateStart
        ? moment()
            .subtract(1, 'months')
            .valueOf()
        : null,
      providerId: filter.providerId,
    })
      .then(response => {
        dispatch(exportDataSuccess(response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(exportDataError(extractErrorMessage(error)));
      });
  };

export const getPropertiesInMapFilteredByReference = (type, reference, filter) =>
  function(dispatch, getState) {
    let url = '';

    if (type === 'HS') {
      url = getMetasourceLink(
        getState().Properties.metasource,
        getState().Preload.metasourceStrings,
        'PROPERTY_MAIN_METASOURCE_PROPERTIES_HS_REFERENCE'
      );
    }

    if (type === 'PROVIDER') {
      url = getMetasourceLink(
        getState().Properties.metasource,
        getState().Preload.metasourceStrings,
        'PROPERTY_MAIN_METASOURCE_PROPERTIES_PROVIDER_REFERENCE'
      );
    }

    if (url === '') {
      return;
    }

    return PropertiesApi.getPropertiesInMapFilteredByReference(url.href, reference)
      .then(response => {
        let properties = [];

        if ((type === 'HS' || type === 'PROVIDER') && response.data) {
          properties = response.data;
        }

        dispatch(propertiesInMapFilteredSuccess(properties, filter, 1));
        dispatch(setPrintablePropertyDetailsData({}));
      })
      .catch(error => {
        console.error(error);
        dispatch(propertiesInMapFilteredError(extractErrorMessage(error)));
      });
  };

/* export const getPropertiesByDistanceInMapFiltered= (lat, lng, radius, price=null) => {
  return function (dispatch, getState) {
    const url = getMetasourceLink(getState().Properties.metasource, getState().Preload.metasourceStrings, 'PROPERTY_MAIN_METASOURCE_PROPERTIES_MAP_DISTANCE')
    return PropertiesApi.getPropertiesByDistanceInMapFiltered(url.href, lat, lng, radius, price)
    .then(response => {
      dispatch(propertiesByDistanceInMapFilteredSuccess(response.data))
    })
    .catch(error => {
      console.error(error);
      dispatch(propertiesByDistanceInMapFilteredError(extractErrorMessage(error)))
    })
  }
} */

export const getNewPropertyLocation = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Properties.metasource,
      getState().Preload.metasourceStrings,
      'PROPERTY_MAIN_METASOURCE_NEW_PROPERTY'
    );

    return PropertiesApi.getNewPropertyLocation(url.href)
      .then(response => {
        dispatch(newPropertyLocationSuccess(response.headers.location));
        dispatch(getProvidersTableData(null, true));
      })
      .catch(error => {
        console.error(error);
        dispatch(newPropertyLocationError(extractErrorMessage(error)));
      });
  };

export const getPropertyList = url =>
  function(dispatch) {
    if (!url) {
      return;
    }

    return PropertiesApi.getPropertyList(url.href)
      .then(response => {
        dispatch(propertyListSuccess(response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(propertyListError(extractErrorMessage(error)));
      });
  };

export const setPropertyExternalFilterAndLoad = filter =>
  function(dispatch, getState) {
    if (!filter) {
      return;
    }

    let filterState = getState().Properties.filter;
    let { activeBusiness } = getState().Properties;

    filterState = {
      ...filterState,
      guest: get(filter, 'guest'),
      maxPrice: get(filter, 'maxPrice.amount'),
      address: get(filter, 'address'),
      startDate: get(filter, 'startDate'),
      endDate: get(filter, 'endDate'),
      billable: get(filter, 'invoiceRequired') || false,
      beds: get(filter, 'beds'),
      rooms: get(filter, 'rooms'),
      baths: get(filter, 'baths'),
      comissionable: get(filter, 'comissionable'),
      airConditioning: get(filter, 'aCRequired'),
      cleaningChargeType: get(filter, 'cleaningRequired'),
      wifi: get(filter, 'wifiRequired'),
      freeCarPark: get(filter, 'garageRequired'),
      petsAllowed: get(filter, 'petsAllowedRequired'),
      lift: get(filter, 'liftRequired'),
      refProvider: null,
      refHomyspace: null,
      publishedInWeb: null,
      incorporationDateStart: null,
      providerId: null,
      smartSearch: get(filter, 'smartSearch'),
    };

    activeBusiness = {
      ...activeBusiness,
      id: get(filter, 'dealIdentificacionCode'),
      tenants: get(filter, 'guest'),
      address: get(filter, 'address.formattedAddress'),
      startDate: get(filter, 'startDate'),
      endDate: get(filter, 'endDate'),
    };

    dispatch(setPropertyExternalFilter(filterState, activeBusiness));
    browserHistory.push('/properties');
  };

export const uploadImportFile = file =>
  function(dispatch, getState) {
    const { metasource } = getState().Properties;
    const { metasourceStrings } = getState().Preload;
    const url = getMetasourceLink(
      metasource,
      metasourceStrings,
      'PROPERTY_MAIN_METASOURCE_UPLOAD_IMPORT_FILE'
    );

    return PropertiesApi.uploadImportFile(resolveParams(url.href).replace('8080', '9090'), file)
      .then(() => {
        dispatch(uploadImportFileSuccess());
      })
      .catch(error => {
        console.error(error);
        dispatch(uploadImportFileError(extractErrorMessage(error)));
      });
  };

export const getImportStatus = () =>
  function(dispatch, getState) {
    const { metasource } = getState().Properties;
    const { metasourceStrings } = getState().Preload;
    const url = getMetasourceLink(
      metasource,
      metasourceStrings,
      'PROPERTY_MAIN_METASOURCE_MASSIVE_UPLOADS'
    );

    return PropertiesApi.getImportStatus(url.href)
      .then(response => {
        dispatch(getImportStatusSuccess(response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(getImportStatusError(extractErrorMessage(error)));
      });
  };
