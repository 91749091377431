import React, { useCallback } from 'react';
import { Col, InputNumber, Row } from 'antd';
import { COMMISSION_TO_PROVIDER_DATE_THRESHOLD } from '../../shared/appConfig';

const FormProviderCommission = ({
  data = {},
  dealDetails = {},
  onChange = () => {},
  hasFieldsBlocked = false,
}) => {
  const onPriceChange = useCallback((field, value) =>
    onChange(field, { amount: value, currency: 'EUR' })
  );
  return (
    <>
      <Row gutter={12} className='hs-row'>
        <Col span={12}>
          <label htmlFor='proposalDetailProviderCommisionPercentage'>% Proveedor</label>
          <InputNumber
            style={{ width: '100%' }}
            id='proposalDetailProviderCommisionPercentage'
            name='commissionPercentage'
            placeholder='0'
            min={0}
            value={data.commissionPercentage || ''}
            onChange={onChange.bind(this, 'commissionPercentage')}
            disabled={hasFieldsBlocked}
          />
        </Col>
        <Col span={12}>
          <label htmlFor='proposalDetailProviderRate'>Tarifa proveedor</label>
          <InputNumber
            style={{ width: '100%' }}
            className='dealBackgroundColor colorWhite bold'
            id='proposalDetailProviderRate'
            name='providerRate'
            placeholder='0'
            disabled
            value={data.providerRate ? data.providerRate.amount : ''}
          />
        </Col>
      </Row>
      {dealDetails.creationDate > COMMISSION_TO_PROVIDER_DATE_THRESHOLD && (
        <>
          <Row gutter={12} className='hs-row'>
            <Col span={8}>
              <label htmlFor='proposalDetailProviderTotalNetAmount'>Precio promedio mensual</label>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailProviderAverageMonthlyPrice'
                name='providerAverageMonthlyPrice'
                placeholder='0'
                disabled={data.taxIncluded || hasFieldsBlocked}
                value={
                  data.providerAverageMonthlyPrice ? data.providerAverageMonthlyPrice.amount : ''
                }
                onChange={onPriceChange.bind(this, 'providerAverageMonthlyPrice')}
                // onChange={e => onChange(e.target.name, { amount: e.target.value, currency: 'EUR' })}
              />
            </Col>
            <Col span={8}>
              <label htmlFor='proposalDetailProviderTotalNetAmount'>
                Precio neto total por todo el alquiler
              </label>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailProviderTotalNetAmount'
                name='providerTotalNetAmount'
                placeholder='0'
                disabled
                value={data.providerTotalNetAmount ? data.providerTotalNetAmount.amount : ''}
                // onChange={onPriceChange.bind(this, 'providerTotalNetAmount')}
              />
            </Col>
            <Col span={8}>
              <label htmlFor='proposalDetailProviderMonthlyNetAmount'>
                Precio neto primera mensualidad
              </label>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailProviderFirstMonthNetAmount'
                name='providerFirstMonthNetAmount'
                placeholder='0'
                disabled
                value={
                  data.providerFirstMonthNetAmount ? data.providerFirstMonthNetAmount.amount : ''
                }
              />
            </Col>
          </Row>
          <Row gutter={12} className='hs-row'>
            <Col span={8}>
              <label htmlFor='proposalDetailProviderAverageMontlyPriceTaxIncluded'>
                Precio promedio mensual (imp. incluidos)
              </label>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailProviderAverageMontlyPriceTaxIncluded'
                name='providerAverageMontlyPriceTaxIncluded'
                placeholder='0'
                disabled={!data.taxIncluded || hasFieldsBlocked}
                value={
                  data.providerAverageMonthlyPriceWithTax
                    ? data.providerAverageMonthlyPriceWithTax.amount
                    : ''
                }
                onChange={onPriceChange.bind(this, 'providerAverageMontlyPriceTaxIncluded')}
                // onChange={e => onChange(e.target.name, { amount: e.target.value, currency: 'EUR' })}
              />
            </Col>
            <Col span={8}>
              <label htmlFor='proposalDetailProviderTotalAmountTaxIncluded'>
                Precio total (imp. incluidos)
              </label>
              <InputNumber
                style={{ width: '100%' }}
                id='proposalDetailProviderTotalAmountTaxIncluded'
                name='providerTotalAmountTaxIncluded'
                placeholder='0'
                disabled
                value={data.providerTotalPriceWithTax ? data.providerTotalPriceWithTax.amount : ''}
                // onChange={onPriceChange.bind(this, 'providerTotalNetAmount')}
              />
            </Col>
          </Row>
        </>
      )}
      <style jsx>
        {`
          .hs-row {
            margin-bottom: 15px;
          }
        `}
      </style>
    </>
  );
};

export default FormProviderCommission;
