import { browserHistory } from 'react-router';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import {
  PMA_APPROXIMATE_ERROR,
  PMA_APPROXIMATE_SUCCESS,
  PMA_AVERAGES_TABLEDATA_ERROR,
  PMA_AVERAGES_TABLEDATA_SUCCESS,
  PMA_CITIES_ERROR,
  PMA_CITIES_SUCCESS,
  PMA_METASOURCE_ERROR,
  PMA_METASOURCE_SUCCESS,
  PMA_TABLEDATA_ERROR,
  PMA_TABLEDATA_SUCCESS,
  PMA_ZIP_CODES_ERROR,
  PMA_ZIP_CODES_SUCCESS,
} from '../../shared/actionTypes';
import PmasApi from './PmasApi';
import { getMetasourceLink } from '../../shared/utils/appUtils';

export const metasourceSuccess = metasourceLinks => ({
  type: PMA_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: PMA_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const tablePmaAveragesDataSuccess = tableData => ({
  type: PMA_AVERAGES_TABLEDATA_SUCCESS,
  tableData,
});

export const tablePmaAveragesDataError = errorMessage => ({
  type: PMA_AVERAGES_TABLEDATA_ERROR,
  errorDetail: errorMessage,
});

export const tablePmaDataSuccess = tableData => ({
  type: PMA_TABLEDATA_SUCCESS,
  tableData,
});

export const tablePmaDataError = errorMessage => ({
  type: PMA_TABLEDATA_ERROR,
  errorDetail: errorMessage,
});

export const listPmaCitiesSuccess = list => ({
  type: PMA_CITIES_SUCCESS,
  list: list.values,
});

export const listPmaCitiesError = errorMessage => ({
  type: PMA_CITIES_ERROR,
  errorDetail: errorMessage,
});

export const listPmaZipCodesSuccess = list => ({
  type: PMA_ZIP_CODES_SUCCESS,
  list: list.values,
});

export const listPmaZipCodesError = errorMessage => ({
  type: PMA_ZIP_CODES_ERROR,
  errorDetail: errorMessage,
});

export const listPmaApproximateSuccess = list => ({
  type: PMA_APPROXIMATE_SUCCESS,
  list,
});

export const listPmaApproximateError = errorMessage => ({
  type: PMA_APPROXIMATE_ERROR,
  errorDetail: errorMessage,
});

export const getPmaAveragesTableData = url =>
  function(dispatch) {
    try {
      return PmasApi.getPmasAveragesTableData(url)
        .then(response => {
          dispatch(tablePmaAveragesDataSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(tablePmaAveragesDataError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getPmaTableData = url =>
  function(dispatch) {
    try {
      return PmasApi.getPmasTableData(url)
        .then(response => {
          dispatch(tablePmaDataSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(tablePmaDataError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const loadPmasMetasource = () =>
  function(dispatch, getState) {
    try {
      return PmasApi.getMetasource(getState().Preload.mainMetasource.pmas.href)
        .then(response => {
          dispatch(metasourceSuccess(response.data._links));
        })
        .catch(error => {
          console.error(error);
          dispatch(metasourceError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getPmaCities = () =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Pmas;
      const { metasourceStrings } = getState().Preload;
      const url = getMetasourceLink(metasource, metasourceStrings, 'PMA_MAIN_METASOURCE_CITIES');

      return PmasApi.getPmaCities(url.href)
        .then(response => {
          dispatch(listPmaCitiesSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(listPmaCitiesError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getPmaZipCodes = city =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Pmas;
      const { metasourceStrings } = getState().Preload;
      const url = getMetasourceLink(metasource, metasourceStrings, 'PMA_MAIN_METASOURCE_CITIES');

      return PmasApi.getPmaZipCodes(`${url.href}/${city}`)
        .then(response => {
          dispatch(listPmaZipCodesSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(listPmaZipCodesError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getPmaApproximate = (city, zipcode, startDate, endDate) =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Pmas;
      const { metasourceStrings } = getState().Preload;
      const url = getMetasourceLink(
        metasource,
        metasourceStrings,
        'PMA_MAIN_METASOURCE_APPROXIMATE'
      );
      const params = {};

      if (city) {
        params.city = city;
      }

      if (zipcode) {
        params.zipcode = zipcode;
      }

      if (startDate) {
        params.dateFrom = startDate;
      }

      if (endDate) {
        params.dateTo = endDate;
      }

      return PmasApi.getPmaApproximate(url.href, params)
        .then(response => {
          dispatch(listPmaApproximateSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(listPmaApproximateError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };
