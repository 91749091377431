import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { isValidBIC } from 'ibantools';
import HomyBIC from '../HomyComponents/HomyBIC';
import InputAntdValidator from './InputAntdValidator';

class FormBIC extends Component {
  static propTypes = {
    fieldDecorator: PropTypes.string,
    form: PropTypes.shape({}).isRequired,
    isRequired: PropTypes.bool,
    isRequiredMessage: PropTypes.string,
    isWrongMessage: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    initialValue: PropTypes.string,
  };

  static defaultProps = {
    fieldDecorator: 'bicOrSwift',
    isRequired: false,
    isRequiredMessage: 'Por favor, introduzca un BIC o SWIFT',
    isWrongMessage: 'Introduzca un BIC / SWIFT válidos',
    label: 'BIC / SWIFT',
    name: 'bicOrSwift',
    initialValue: '',
  };

  handleValidBIC = (rule, value, callback) => {
    if (value) {
      const isValid = isValidBIC(value);

      if (!isValid) {
        callback('El BIC / SWIFT introducido no es válido.');
      }
    }

    callback();
  };

  render() {
    const {
      fieldDecorator,
      form,
      isRequired,
      isRequiredMessage,
      isWrongMessage,
      label,
      initialValue,
      name,
    } = this.props;

    return (
      <InputAntdValidator
        form={form}
        fieldDecorator={fieldDecorator}
        initialValue={initialValue}
        isRequired={isRequired}
        isRequiredMessage={isRequiredMessage}
        isWrongMessage={isWrongMessage}
        label={label}
        validator={this.handleValidBIC}
      >
        <HomyBIC name={name} label='' />
      </InputAntdValidator>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    return {
      [props.name]: Form.createFormField({
        ...props,
      }),
    };
  },
})(FormBIC);

// // export default BICInput;

// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Input, Form } from 'antd';
// import IBAN from 'iban';
// import { isValidBIC } from 'ibantools';
// import { openNotificationWithIcon } from '../../shared/utils/appUtils';

// const FormItem = Form.Item;

// export const validateIBAN = () => {
//   const iban = document.getElementsByClassName('validate-accountNumber');
//   const isValidIban = IBAN.isValid(IBAN.electronicFormat(iban.value));

//   if (!isValidIban) {
//     openNotificationWithIcon(
//       'error',
//       'Error',
//       <div>
//         <p style={{ marginBottom: 0 }}>
//           El formato del campo "BIC / SWIFT" no es correcto.
//         </p>
//       </div>
//     );
//   }

//   return isValidIban;
// };

// export const isValidSwiftBIC = (rule, value, callback) => {
//   if (value) {
//     const isValid = isValidBIC(value);
//     if (!isValid) {
//       callback('El BIC / SWIFT introducido no es válido.');
//     }
//   }

//   callback();
// };

// const BICInput = Form.create({
//   mapPropsToFields(props) {
//     return {
//       [props.name]: Form.createFormField({
//         ...props,
//       }),
//     };
//   },
// })((props) => {
//   const {
//     label,
//     fieldDecorator,
//     value,
//     isRequired,
//     name,
//     placeholder,
//     onChange,
//     form,
//   } = props;
//   const { getFieldDecorator } = form;

//   return ( <FormItem label={label}>
//     {getFieldDecorator(fieldDecorator, {
//       initialValue: value,
//       rules: [
//         {
//           type: 'string',
//           message: 'Introduzca un BIC / SWIFT válidos',
//         },
//         {
//           required: isRequired,
//           message: 'Por favor, introduzca un BIC o SWIFT',
//         },
//         {
//           validator: this.isValidSwiftBIC,
//         },
//       ],
//     })(
//       <Input
//         type="text"
//         className="form-control"
//         name={name}
//         placeholder={placeholder}
//         style={{ marginBottom: '3px' }}
//         onChange={onChange}
//       />
//     )}
//   </FormItem>)
// });

// BICInput.propTypes = {
//   label: PropTypes.string,
//   fieldDecorator: PropTypes.string,
//   value: PropTypes.string,
//   isRequired: PropTypes.bool,
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
//   onChange: PropTypes.func,
//   form: PropTypes.shape({}).isRequired,
// };

// BICInput.defaultProps = {
//   label: 'BIC / SWIFT',
//   fieldDecorator: 'bicOrSwift',
//   value: '',
//   isRequired: false,
//   name: 'bicOrSwift',
//   placeholder: 'XXVAESMM',
//   onChange: null,
// };
