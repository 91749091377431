import { getAxiosInstance } from '../../shared/utils/apiUtils';

class InvoiceDetailsApi {
  static getMetasource(url, id) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url + '/' + id);
  }

  static getInvoiceDetailsData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static saveInvoiceDetailsData(url, invoiceDetails, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, invoiceDetails);
  }

  static getInvoicePdf(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url, {
      responseType: 'arraybuffer',
    });
  }
}

export default InvoiceDetailsApi;
