import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormDealContacts from '../FormDealContacts';
import { getDealDetailsData } from '../../modules/DealDetails/DealDetailsActions';

// PARAMETROS A CONFIGURAR
// leftOffset -> columnas bootstrap a desplazer el botón
// filterContacts -> (OPTIONAL) filter contacts from the specified context (DEAL, etc.)
// mainView -> main view in wich this component is included (optional)

class InfoButtonDealContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyContacts: false,
      companyContacts: {},
      // companyContactsVersion: props.companyContactsVersion,
    };
    this.showCompanyContacts = this.showCompanyContacts.bind(this);
    this.refreshCompanyDetailsData = this.refreshCompanyDetailsData.bind(this);
    this.getContactsNumberWithoutBase = this.getContactsNumberWithoutBase.bind(this);

    // this.props.actions.getCompanyContactsData();
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (
  //     JSON.stringify(this.state.companyContacts) !==
  //     JSON.stringify(nextProps.companyContacts.detail)
  //   ) {
  //     this.setState({companyContacts: nextProps.companyContacts.detail});
  //     // this.setState(
  //     //   { companyContacts: nextProps.companyContacts.detail },
  //     //   () => {
  //     //     if (this.props.filterContacts) {
  //     //       if (this.props.filterContacts === 'DEAL') {
  //     //         this.props.actions.filterCompanyContactsData(
  //     //           this.props.dealDetails.contactsOfCompany
  //     //         );
  //     //       }
  //     //     }
  //     //   }
  //     // );
  //   }
  // }

  componentDidUpdate() {
    if (document.getElementById('infoButtonCompanyContacts')) {
      if (this.state.showCompanyContacts) {
        document.getElementById('infoButtonCompanyContacts').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonCompanyContacts').style.zIndex = 1;
      }
    }
  }

  showCompanyContacts(event) {
    if (event.target.nodeName !== 'A') {
      const showCompanyContacts = !this.state.showCompanyContacts;

      this.setState(
        {
          showCompanyContacts,
        },
        () => {
          if (showCompanyContacts) {
            this.props.actions.getDealDetailsData();
          }
        }
      );
    }
  }

  refreshCompanyDetailsData() {
    this.props.actions.getDealDetailsData();
  }

  getContactsNumberWithoutBase() {
    const { companyId } = this.props.dealDetails;
    const contacts = this.props.dealDetails.contactsOfCompany;

    return contacts.filter(
      contactInfo =>
        contactInfo.contact.id !== companyId ||
        contactInfo.roles.includes('CONTRACT_SIGNING') ||
        contactInfo.roles.includes('LEASEHOLDER')
    ).length;
  }

  render() {
    let leftOffset = 0;

    if (this.props.leftOffset) {
      leftOffset = parseInt(this.props.leftOffset, 10) * -50;
    }

    const styleInfoButtonDetail = {
      marginLeft: `${leftOffset}%`,
    };

    return (
      <div id='infoButtonCompanyContacts' className='col-sm-2 noPadding'>
        {/* {this.props.companyContacts.error && (
          <Alert
            message="Error"
            description={this.props.companyContacts.errorDetail}
            type="error"
            showIcon
          />
        )} */}
        <div
          className='card card-inverse contactBackgroundColor bold infoButton'
          onClick={this.showCompanyContacts}
        >
          <div className='card-block'>
            <div className='row positionRelative'>
              <div className='col-sm-12'>
                <h1 className='nexa-bold'>
                  {this.props.dealDetails.contactsOfCompany
                    ? this.props.dealDetails.contactsOfCompany.length
                    : // ? this.getContactsNumberWithoutBase()
                      0}{' '}
                </h1>
                contactos
              </div>
            </div>
            {this.state.showCompanyContacts && (
              <div className='openIcon'>
                <i className='fa fa-caret-down fa-2x' />
              </div>
            )}
          </div>
        </div>
        {this.state.showCompanyContacts && (
          <div
            style={styleInfoButtonDetail}
            className='infoButtonDetail cardNoMargin noPadding animated fadeIn contactBorderTopColor'
          >
            <div className='card'>
              <div className='card-block'>
                <FormDealContacts
                  mainView={this.props.mainView}
                  callbackFunctionOnUpdateOrSave={this.refreshCompanyDetailsData}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      // getCompanyContactsData: () => dispatch(getCompanyContactsData()),
      // filterCompanyContactsData: listOfContacts =>
      //   dispatch(filterCompanyContactsData(listOfContacts)),
      getDealDetailsData: () => dispatch(getDealDetailsData()),
    },
  };
}

function mapStateToProps(state) {
  return {
    // companyContacts: state.CompanyDetails.contacts,
    // companyContactsVersion: state.CompanyDetails.contactsVersion,
    dealDetails: state.DealDetails.data.detail,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButtonDealContacts);
