import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getOptionsForSelect, getTextValueFromSelectOptions } from '../../shared/utils/appUtils';
import { normalizeNumberToDecimals } from '../../shared/utils/formUtils';
import InfoButtonDealDetails from '../../components/InfoButtonDealDetails';
import {
  getDealDetailsData,
  showOrLoadDealDetailsView,
} from '../../modules/DealDetails/DealDetailsActions';
import {
  getContactDetailsData,
  resetState as resetStateContact,
  showOrLoadContactDetailsView,
} from '../../modules/ContactDetails/ContactDetailsActions';
import { showOrLoadInvoiceDetailsView } from '../../modules/InvoiceDetails/InvoiceDetailsActions';
import {
  getProviderDetailsData,
  resetState as resetStateProvider,
  showOrLoadProviderDetailsView,
} from '../../modules/ProviderDetails/ProviderDetailsActions';
import {
  getCompanyDetailsData,
  resetState as resetStateCompany,
  showOrLoadCompanyDetailsView,
} from '../../modules/CompanyDetails/CompanyDetailsActions';
import InfoButtonProposalDetails from '../../components/InfoButtonProposalDetails';
import { getInvoiceDetailsData } from '../../modules/InvoiceDetails/InvoiceDetailsActions';
import InfoButtonProviderDetails from '../../components/InfoButtonProviderDetails';
import InfoButtonCompanyDetails from '../../components/InfoButtonCompanyDetails';
import { DATE_FORMAT } from '../../shared/appConfig';
import FormInvoiceDetails from '../../components/FormInvoiceDetails';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { INVOICE_TYPES, INVOICE_TYPES_OPTIONS_ES } from 'hs-utils';

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      invoiceDetails: {},
      dealDetailsMetasource: {},
      dealDetails: {},
      contactDetailsMetasource: {},
      contactDetails: {},
      contactLoaded: false,
      providerDetailsMetasource: {},
      providerMetasourceLoaded: false,
      providerDetails: {},
      companyDetailsMetasource: {},
      companyMetasourceLoaded: false,
      companyDetails: {},
      options: {
        invoiceType: INVOICE_TYPES_OPTIONS_ES,
        invoiceState: getOptionsForSelect(this.props.optionLabels, 'InvoiceState'),
      },
    };
  }

  componentDidMount() {
    if (!isEmpty(this.props.params.id)) {
      this.props.actions.showOrLoadInvoiceDetailsView(this.props.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.isLoading && !isEmpty(nextProps.invoiceDetails)) {
      if (!isEmpty(nextProps.invoiceDetails.dealId)) {
        this.props.actions.showOrLoadDealDetailsView(nextProps.invoiceDetails.dealId);
      }
      this.props.actions.showOrLoadContactDetailsView(nextProps.invoiceDetails.contactId);
      this.setState({
        isLoading: false,
      });
    }

    // if (
    //   nextProps.invoiceDetails &&
    //   JSON.stringify(nextProps.invoiceDetails.detail) !== JSON.stringify(this.props.invoiceDetails)
    // ) {
    //   if (nextProps.invoiceDetails.error) {
    //     openNotificationWithIcon('error', 'Opps!! :(', nextProps.invoiceDetails.errorDetail);
    //     return;
    //   }
    //   this.props.actions.showOrLoadDealDetailsView(nextProps.invoiceDetails.detail.dealId);
    //   this.props.actions.showOrLoadContactDetailsView(nextProps.invoiceDetails.detail.contactId);
    // }
    // if (
    //   JSON.stringify(nextProps.dealDetailsMetasource) !==
    //   JSON.stringify(this.state.dealDetailsMetasource)
    // ) {
    //   if (nextProps.dealDetailsMetasource.error) {
    //     openNotificationWithIcon('error', 'Opps!! :(', nextProps.dealDetailsMetasource.errorDetail);
    //     return;
    //   }
    //   this.setState(
    //     {
    //       dealDetailsMetasource: nextProps.dealDetailsMetasource,
    //     },
    //     () => {
    //       if (this.props.invoiceDetails.dealId) {
    //         this.props.actions.getDealDetailsData();
    //       }
    //     }
    //   );
    // }
    // if (
    //   JSON.stringify(nextProps.contactDetailsMetasource) !==
    //   JSON.stringify(this.state.contactDetailsMetasource)
    // ) {
    //   if (nextProps.contactDetailsMetasource.error) {
    //     openNotificationWithIcon(
    //       'error',
    //       'Opps!! :(',
    //       nextProps.contactDetailsMetasource.errorDetail
    //     );
    //     return;
    //   }
    //   this.setState(
    //     {
    //       contactDetailsMetasource: nextProps.contactDetailsMetasource,
    //     },
    //     () => {
    //       if (this.props.invoiceDetails.contactId) {
    //         this.props.actions.getContactDetailsData();
    //       }
    //     }
    //   );
    // }
    // if (
    //   nextProps.dealDetails &&
    //   JSON.stringify(nextProps.dealDetails.detail) !== JSON.stringify(this.state.dealDetails)
    // ) {
    //   if (nextProps.dealDetails.error) {
    //     openNotificationWithIcon('error', 'Opps!! :(', nextProps.dealDetails.errorDetail);
    //     return;
    //   }
    //   this.setState({
    //     dealDetails: nextProps.dealDetails.detail,
    //   });
    // }
    // if (
    //   !this.state.contactLoaded &&
    //   nextProps.contactDetails &&
    //   JSON.stringify(nextProps.contactDetails.detail) !== JSON.stringify(this.state.contactDetails)
    // ) {
    //   if (nextProps.contactDetails.error) {
    //     openNotificationWithIcon('error', 'Opps!! :(', nextProps.contactDetails.errorDetail);
    //     return;
    //   }
    //   this.setState(
    //     {
    //       contactDetails: nextProps.contactDetails.detail,
    //     },
    //     () => {
    //       if (nextProps.contactDetails.detail.providerId) {
    //         this.props.actions.showOrLoadProviderDetailsView(
    //           nextProps.contactDetails.detail.providerId
    //         );
    //       }
    //       if (nextProps.contactDetails.detail.companyId) {
    //         this.props.actions.showOrLoadCompanyDetailsView(
    //           nextProps.contactDetails.detail.companyId
    //         );
    //       }
    //       this.setState({ contactLoaded: true });
    //     }
    //   );
    // }
    // if (
    //   this.state.contactLoaded &&
    //   nextProps.contactDetails &&
    //   nextProps.contactDetails.detail &&
    //   nextProps.contactDetails.detail.providerId
    // ) {
    //   if (
    //     nextProps.providerDetailsMetasource &&
    //     JSON.stringify(nextProps.providerDetailsMetasource) !==
    //       JSON.stringify(this.state.providerDetailsMetasource)
    //   ) {
    //     if (nextProps.providerDetailsMetasource.error) {
    //       openNotificationWithIcon(
    //         'error',
    //         'Opps!! :(',
    //         nextProps.providerDetailsMetasource.errorDetail
    //       );
    //       return;
    //     }
    //     this.setState(
    //       {
    //         providerDetailsMetasource: nextProps.providerDetailsMetasource,
    //         providerMetasourceLoaded: true,
    //       },
    //       () => {
    //         if (this.props.invoiceDetails.contactId) {
    //           this.props.actions.getProviderDetailsData();
    //         }
    //       }
    //     );
    //   }
    //   if (
    //     this.state.providerMetasourceLoaded &&
    //     nextProps.providerDetails &&
    //     JSON.stringify(nextProps.providerDetails.detail) !==
    //       JSON.stringify(this.state.providerDetails)
    //   ) {
    //     if (nextProps.providerDetails.error) {
    //       openNotificationWithIcon('error', 'Opps!! :(', nextProps.providerDetails.errorDetail);
    //       return;
    //     }
    //     this.setState({
    //       providerDetails: nextProps.providerDetails.detail,
    //     });
    //   }
    // }
    // if (
    //   this.state.contactLoaded &&
    //   nextProps.contactDetails &&
    //   nextProps.contactDetails.detail &&
    //   nextProps.contactDetails.detail.companyId
    // ) {
    //   if (
    //     nextProps.companyDetailsMetasource &&
    //     JSON.stringify(nextProps.companyDetailsMetasource) !==
    //       JSON.stringify(this.state.companyDetailsMetasource)
    //   ) {
    //     if (nextProps.companyDetailsMetasource.error) {
    //       openNotificationWithIcon(
    //         'error',
    //         'Opps!! :(',
    //         nextProps.companyDetailsMetasource.errorDetail
    //       );
    //       return;
    //     }
    //     this.setState(
    //       {
    //         companyDetailsMetasource: nextProps.companyDetailsMetasource,
    //         companyMetasourceLoaded: true,
    //       },
    //       () => {
    //         if (this.props.invoiceDetails.contactId) {
    //           this.props.actions.getCompanyDetailsData();
    //         }
    //       }
    //     );
    //   }
    //   if (
    //     this.state.companyMetasourceLoaded &&
    //     nextProps.companyDetails &&
    //     JSON.stringify(nextProps.companyDetails.detail) !==
    //       JSON.stringify(this.state.companyDetails)
    //   ) {
    //     if (nextProps.companyDetails.error) {
    //       openNotificationWithIcon('error', 'Opps!! :(', nextProps.companyDetails.errorDetail);
    //       return;
    //     }
    //     this.setState({
    //       companyDetails: nextProps.companyDetails.detail,
    //     });
    //   }
    // }
  }

  componentWillUnmount() {
    this.props.actions.resetStateContact();
    this.props.actions.resetStateProvider();
    this.props.actions.resetStateCompany();
  }

  getInvoiceNumber() {
    const invoiceDetails = this.state.invoiceDetails;
    if (!isEmpty(invoiceDetails)) {
      switch (invoiceDetails.invoiceType) {
        case INVOICE_TYPES.PROFORMA:
          return 'PROFORMA';
        case INVOICE_TYPES.REGULAR:
          return invoiceDetails.invoiceNumber;
        case INVOICE_TYPES.RECTIFICATION:
          return !isNil(invoiceDetails.invoiceNumber) ? `FRE${invoiceDetails.invoiceNumber}` : '';
      }
    }
    return '';
  }

  render() {
    return this.state.isLoading ? (
      <div key='1' className='loader loading-background loading-background-full'>
        &nbsp;
      </div>
    ) : (
      <div className='animated fadeIn col-sm-12'>
        <div className='marginTop2 col-sm-12'>
          <div className='row'>
            {this.props.invoiceDetails && (
              <div className='card invoiceBorderTopColor infoButton col-sm-3'>
                <div className='card-block paddingInfoButtonDeal'>
                  <div className='row'>
                    <div className='col-sm-12 fontSizeMax invoiceColor'>
                      {this.props.invoiceDetails.invoiceType !== 'PROFORMA'
                        ? this.props.invoiceDetails.invoiceNumber
                        : 'PROFORMA'}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12'>
                      {this.props.invoiceDetails.issueDate
                        ? moment(this.props.invoiceDetails.issueDate).format(DATE_FORMAT)
                        : ''}
                      <br />
                      {this.props.invoiceDetails &&
                        this.props.invoiceDetails.amount &&
                        `${normalizeNumberToDecimals(this.props.invoiceDetails.amount.amount, 2)} ${
                          this.props.invoiceDetails.amount.currency
                        }`}
                      <br />
                      {this.props.invoiceDetails &&
                        getTextValueFromSelectOptions(
                          this.props.invoiceDetails.invoiceType,
                          this.state.options.invoiceType
                        )}
                      &nbsp; &nbsp;
                      {this.props.invoiceDetails &&
                        getTextValueFromSelectOptions(
                          this.props.invoiceDetails.invoiceState,
                          this.state.options.invoiceState
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.invoiceDetails && this.props.invoiceDetails.dealId && (
              <InfoButtonDealDetails withSelfLink leftOffset='3' />
            )}
            {this.props.providerDetails && this.props.providerDetails.id && (
              <InfoButtonProviderDetails withSelfLink leftOffset='8' compressed />
            )}
            {this.props.companyDetails && this.props.companyDetails.id && (
              <InfoButtonCompanyDetails withSelfLink leftOffset='8' compressed />
            )}
          </div>

          <div className='row'>
            <div className='col-sm-12'>
              {this.props.dealDetails &&
                this.props.dealDetails.proposals &&
                this.props.invoiceDetails &&
                this.props.invoiceDetails.proposalIds &&
                this.props.invoiceDetails.proposalIds.length > 0 &&
                this.props.dealDetails.proposals.map((proposal, i) => {
                  if (this.props.invoiceDetails.proposalIds.indexOf(proposal.id) >= 0) {
                    return (
                      <InfoButtonProposalDetails key={`infoButtonPropal${i}`} proposalIndex={i} />
                    );
                  }

                  return null;
                })}
            </div>
          </div>

          <div className='row'>
            <div className='card col-sm-12'>
              <div className='card-block'>
                <FormInvoiceDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    invoiceDetails: state.InvoiceDetails.data.detail,
    dealDetailsMetasource: state.DealDetails.metasource,
    dealDetails: state.DealDetails.data,
    contactDetailsMetasource: state.ContactDetails.metasource,
    contactDetails: state.ContactDetails.data,
    providerDetailsMetasource: state.ProviderDetails.metasource,
    providerDetails: state.ProviderDetails.data,
    companyDetailsMetasource: state.CompanyDetails.metasource,
    companyDetails: state.CompanyDetails.data,
    optionLabels: state.Preload.optionLabels,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getInvoiceDetailsData: () => dispatch(getInvoiceDetailsData()),
      getDealDetailsData: () => dispatch(getDealDetailsData()),
      getContactDetailsData: () => dispatch(getContactDetailsData()),
      getProviderDetailsData: () => dispatch(getProviderDetailsData()),
      getCompanyDetailsData: () => dispatch(getCompanyDetailsData()),
      showOrLoadInvoiceDetailsView: id => dispatch(showOrLoadInvoiceDetailsView(id)),
      showOrLoadDealDetailsView: id => dispatch(showOrLoadDealDetailsView(id)),
      showOrLoadContactDetailsView: id => dispatch(showOrLoadContactDetailsView(id)),
      showOrLoadProviderDetailsView: id => dispatch(showOrLoadProviderDetailsView(id)),
      showOrLoadCompanyDetailsView: id => dispatch(showOrLoadCompanyDetailsView(id)),
      resetStateContact: () => dispatch(resetStateContact()),
      resetStateProvider: () => dispatch(resetStateProvider()),
      resetStateCompany: () => dispatch(resetStateCompany()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
