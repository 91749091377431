import {
  CONTACTDETAILS_DATA_ERROR,
  CONTACTDETAILS_DATA_SUCCESS,
  CONTACTDETAILS_DELETE_ERROR,
  CONTACTDETAILS_DELETE_SUCCESS,
  CONTACTDETAILS_METASOURCE_ERROR,
  CONTACTDETAILS_METASOURCE_SUCCESS,
  CONTACTDETAILS_RESET,
  CONTACTDETAILS_SUBMIT_ERROR,
  CONTACTDETAILS_SUBMIT_SUCCESS,
} from '../../shared/actionTypes';

import ContactDetailsApi from './ContactDetailsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink } from '../../shared/utils/appUtils';
import {
  getProviderContactsData,
  providerContactSubmitSuccess,
} from '../ProviderDetails/ProviderDetailsActions';
import { ENDPOINT } from '../../shared/appConfig';

export const CONTACTDETAILSROUTES = {
  PROVIDER: 'providerDetails',
  COMPANY: 'companyDetails',
  CONTACT: 'contactDetails',
};

export const resetState = () => ({
  type: CONTACTDETAILS_RESET,
});

export const metasourceSuccess = metasourceLinks => ({
  type: CONTACTDETAILS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: CONTACTDETAILS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const contactDetailsDataSuccess = (data, url, etag) => ({
  type: CONTACTDETAILS_DATA_SUCCESS,
  contactDetailsData: data,
  url,
  etag,
});

export const contactDetailsDataError = errorMessage => ({
  type: CONTACTDETAILS_DATA_ERROR,
  contactDetailsErrorDetails: errorMessage,
});

export const contactDetailsSubmitSuccess = etag => ({
  type: CONTACTDETAILS_SUBMIT_SUCCESS,
  etag,
});

export const contactDetailsSubmitError = errorMessage => ({
  type: CONTACTDETAILS_SUBMIT_ERROR,
  contactDetailsSubmitErrorDetails: errorMessage,
});

export const deleteContactSuccess = () => ({
  type: CONTACTDETAILS_DELETE_SUCCESS,
});

export const deleteContactError = errorMessage => ({
  type: CONTACTDETAILS_DELETE_ERROR,
  errorMessage,
});

export const getContactDetailsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().ContactDetails;
    const { metasourceStrings } = getState().Preload;
    const urlReduced = getMetasourceLink(
      metasource,
      metasourceStrings,
      'CONTACT_DETAIL_METASOURCE_CONTACT_REDUCED'
    );

    if (!urlReduced || !urlReduced.href) {
      return null;
    }

    return ContactDetailsApi.getContactDetailsData(urlReduced.href)
      .then(response => {
        const { etag } = response.headers;

        dispatch(contactDetailsDataSuccess(response.data.contact, urlReduced.href, etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(contactDetailsDataError(extractErrorMessage(error)));
      });
  };

export const setNewContactDetails = contactDetails =>
  function(dispatch) {
    dispatch(contactDetailsDataSuccess(contactDetails, null, null));
  };

export const showOrLoadContactDetailsView = id =>
  function(dispatch, getState) {
    if (!id) {
      dispatch(contactDetailsDataSuccess(null, null, null));

      return;
    }

    const url = getState().Preload.mainMetasource.contacts.href;

    return ContactDetailsApi.getMetasource(url, id)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
        dispatch(getContactDetailsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const resetContactState = () =>
  function(dispatch) {
    dispatch(metasourceSuccess({}));
    dispatch(contactDetailsDataSuccess({}, null, null));
  };

export const saveContactDetailsData = contactDetailsData =>
  function(dispatch, getState) {
    // let contactDetailsData = getState().ContactDetails.data.detail;
    const { etag } = getState().ContactDetails.data;
    // const url = getState().ContactDetails.data.url;
    const url = `${ENDPOINT}/'contact'/${contactDetailsData.id}`;

    return ContactDetailsApi.saveContactDetailsData(url, contactDetailsData, null, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(contactDetailsSubmitSuccess(etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(contactDetailsSubmitError(extractErrorMessage(error)));
      });
  };

export const deleteProviderContactData = (contact, callbackFunctionOnDelete = () => {}) => {
  if (contact) {
    return function(dispatch) {
      const url = contact._links.self.href;

      // 1. Se recupera el contacto para obtener el version
      return ContactDetailsApi.getContactDetailsData(url)
        .then(response => {
          const { etag } = response.headers;

          // 2. Se elimina el contacto con el etag recuperado
          return ContactDetailsApi.doDelete(url, etag)
            .then(() => {
              dispatch(deleteContactSuccess());
              dispatch(getProviderContactsData());
              callbackFunctionOnDelete(null);
            })
            .catch(error => {
              console.error(error);
              dispatch(deleteContactError(extractErrorMessage(error)));
            });
        })
        .catch(error => {
          console.error(error);
          dispatch(deleteContactError(extractErrorMessage(error)));
          callbackFunctionOnDelete(error);
        });
    };
  }
};

// Para guardar un contacto desde la vista de Proveedores o Clientes
export const saveContactDetailsDataFromParent = (contact, parent) =>
  function(dispatch, getState) {
    let contactDetailsData;
    let url;

    if (contact) {
      if (contact._links) {
        contactDetailsData = contact.contact;
        url = contact._links.self.href;
      } else {
        contactDetailsData = contact;
        const { metasource } = getState().Contacts;
        const { metasourceStrings } = getState().Preload;

        url = `${
          getMetasourceLink(metasource, metasourceStrings, 'CONTACT_MAIN_METASOURCE_CONTACTS').href
        }/${contactDetailsData.id}`;
      }

      // 1. Se recupera el contacto para obtener el version
      return ContactDetailsApi.getContactDetailsData(url)
        .then(response => {
          const { etag } = response.headers;

          // 2. Se guarda el contacto con el etag recuperado
          return ContactDetailsApi.saveContactDetailsData(url, contactDetailsData, parent, etag)
            .then(() => {
              dispatch(contactDetailsSubmitSuccess(etag));
              dispatch(providerContactSubmitSuccess(etag));
              dispatch(getProviderContactsData());
            })
            .catch(error => {
              console.error(error);
              dispatch(contactDetailsSubmitError(extractErrorMessage(error)));
            });
        })
        .catch(error => {
          console.error(error);
          dispatch(contactDetailsSubmitError(extractErrorMessage(error)));
        });
    }
  };

export const deleteContact = contact => {
  if (contact) {
    return function(dispatch) {
      const url = contact._links.self.href;

      return ContactDetailsApi.doDelete(url)
        .then(() => {
          dispatch(deleteContactSuccess());
        })
        .catch(error => {
          console.error(error);
          dispatch(deleteContactError(extractErrorMessage(error)));
        });
    };
  }
};
