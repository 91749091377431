import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { formatPlaceToWorldWideAddress } from '../../shared/utils/geoUtils';
import {
  isCeutaMelillaOrCanary,
  isSpain,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import isEmpty from 'lodash/isEmpty';

/** ************************************************
 PROPS TO CONFIGURE IN PARENT
 handlePlaceChanged -> method to call where the place changes
 initValue -> initial value of the component
 inputClassName -> className for the input
 id -> id to concat to ref field
 placeholder -> pues el placeholder
 ************************************************* */

class GoogleMapsAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.state = {
      worldWideAddress: this.props.initValue || this.props.value || {},
    };

    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.renderAutoComplete(this.state.worldWideAddress);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { worldWideAddress } = this.state;

    if (nextProps.initValue && worldWideAddress !== nextProps.initValue) {
      this.setState({
        worldWideAddress: nextProps.initValue,
      });
      this.renderAutoComplete(nextProps.initValue);
    } else if (nextProps.value && worldWideAddress !== nextProps.value) {
      this.setState({
        worldWideAddress: nextProps.value,
      });

      this.renderAutoComplete(nextProps.value);
    }
  }

  onSuccess(worldWideAddress) {
    this.setState({ worldWideAddress }, () => {
      if (this.props.handlePlaceChanged) {
        this.props.handlePlaceChanged(worldWideAddress);
      }
    });
  }

  onError() {
    this.setState(
      {
        worldWideAddress: null,
      },
      () => {
        const node = this.getDOMNode();

        node.value = '';
        openNotificationWithIcon(
          'error',
          'Error en la dirección',
          'La dirección seleccionada no se encuentra en nuestra Base de Datos.\nIntente seleccionar una dirección que aparezca en el desplegable.'
        );
      }
    );
  }

  getDOMNode() {
    const aRef = this.refs[`autocomplete${this.props.id ? this.props.id : ''}`];

    // eslint-disable-next-line react/no-find-dom-node
    return ReactDOM.findDOMNode(aRef);
  }

  setPacContainer(google, context, isSelected = false) {
    google.maps.event.trigger(context, 'focus', {});
    if (!isSelected) {
      google.maps.event.trigger(context, 'keydown', { keyCode: 40 });
    }

    google.maps.event.trigger(context, 'keydown', {
      keyCode: 13,
      triggered: true,
    });
  }

  checkPacContainer(pacContainer, functionContext, google) {
    if (pacContainer.length > 1) {
      Array.from(pacContainer).forEach((c, i) => {
        const computedStyleDisplay = window
          .getComputedStyle(pacContainer[i], null)
          .getPropertyValue('display');

        if (
          (c.style.display !== 'none' || computedStyleDisplay !== 'none') &&
          c.matches(':hover') === false
        ) {
          if (c.querySelectorAll('.pac-item-selected').length > 0) {
            this.setPacContainer(google, functionContext, true);
          } else {
            this.setPacContainer(google, functionContext);
          }
        }
      });
    } else if (
      (window.getComputedStyle(pacContainer[0], null).getPropertyValue('display') !== 'none' ||
        pacContainer[0].style.display !== 'none') &&
      pacContainer[0].matches(':hover') === false
    ) {
      if (pacContainer[0].querySelectorAll('.pac-item-selected').length > 0) {
        this.setPacContainer(google, functionContext, true);
      } else {
        this.setPacContainer(google, functionContext);
      }
    }
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();

    if (!isEmpty(place) && place.name !== '') {
      const value = formatPlaceToWorldWideAddress(place);

      if (value) {
        const worldWideAddressEvent = {
          target: {
            name: 'address',
            value,
            type: 'worldWideAddress',
          },
        };

        // De momento se asigna tambien el "value" para campos antiguos.
        // En un futuro, se pasara unicamente como value dentro del target
        const worldWideAddress = Object.assign({}, worldWideAddressEvent, value);
        if (
          isSpain(worldWideAddress.country) &&
          !isCeutaMelillaOrCanary(worldWideAddress.administrativeAreaLevel1)
        ) {
          worldWideAddress.administrativeAreaLevel1 && worldWideAddress.administrativeAreaLevel2
            ? this.onSuccess(worldWideAddress)
            : this.onError();
        } else {
          // Uncontrolled corner case: Grecia only has AdministrativeAreaLevel3 and throws error
          worldWideAddress.administrativeAreaLevel1
            ? this.onSuccess(worldWideAddress)
            : this.onError();
        }
      }
    } else {
      this.onSuccess();
    }
  }

  renderAutoComplete(addressValue) {
    const { google } = window;

    if (!google) {
      return false;
    }

    const context = this;
    const node = this.getDOMNode();

    if (!this.autocomplete) {
      this.autocomplete = new google.maps.places.Autocomplete(node);

      google.maps.event.addDomListener(node, 'keydown', function doKeydown(e) {
        const pacContainer = document.getElementsByClassName('pac-container');

        if (e.keyCode === 13 && !e.triggered) {
          context.checkPacContainer(pacContainer, this, google);
        }
      });

      google.maps.event.addDomListener(node, 'blur', function doBlur() {
        const pacContainer = document.getElementsByClassName('pac-container');

        context.checkPacContainer(pacContainer, this, google);
      });

      google.maps.event.addListener(this.autocomplete, 'place_changed', this.handlePlaceChanged);
      document.getElementById(`google${this.props.id}`).addEventListener('input', e => {
        if (!e.target.value) {
          this.autocomplete.set('place', null);
        }
      });
    }

    if (addressValue && addressValue.formattedAddress) {
      node.value = addressValue.formattedAddress;
    } else {
      node.value = '';
    }
  }

  render() {
    return (
      <div>
        <input
          id={`google${this.props.id}`}
          disabled={this.props.disabled}
          ref={`autocomplete${this.props.id ? this.props.id : ''}`}
          type='text'
          className={`${this.props.inputClassName} ant-input`}
          placeholder={
            this.props.placeholder
              ? this.props.placeholder
              : 'Ejemplo: Paseo de la Castellana, 35, Madrid, España'
          }
          data={
            this.state.worldWideAddress != undefined
              ? this.state.worldWideAddress.formattedAddress
              : ''
          }
          required={this.props.required || false}
        />
      </div>
    );
  }
}

export default GoogleMapsAutocomplete;
