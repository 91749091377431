import React, { Component } from 'react';
import AwsUtils from '../../shared/utils/awsUtils.js';

/*******************************************************
 PROPS TO CONFIGURE IN PARENT
 s3bucket -> id of the s3's bucket wherethe images will be loaded
 imagesToLoad -> array with the ids of the images to be loaded in the carousel
 handleLoadComplete -> function to be called when all the images is loaded.
 ********************************************************/

class ImageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingImages: true,
      imagesToLoad: props.imagesToLoad,
      imagesLoaded: [],
      s3bucket: props.s3bucket,
    };
    this.loadImages = this.loadImages.bind(this);
    this.handleLoadComplete = this.handleLoadComplete.bind(this);
  }

  componentDidMount() {
    this.loadImages();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.state.imagesToLoad) !== JSON.stringify(nextProps.imagesToLoad)) {
      this.setState({
        loadingImages: true,
        imagesToLoad: nextProps.imagesToLoad,
      });
    }

    if (this.state.s3bucket !== nextProps.s3bucket) {
      this.setState({ s3bucket: nextProps.s3bucket });
    }
  }

  componentDidUpdate(prevState) {
    if (JSON.stringify(this.state.imagesToLoad) !== JSON.stringify(prevState.imagesToLoad)) {
      if (this.state.s3bucket) {
        this.loadImages();
      }
    }
  }

  handleLoadComplete(imagesLoaded) {
    this.props.handleLoadComplete(imagesLoaded);
  }

  async loadImages() {
    if (
      !this.state.imagesToLoad ||
      this.state.imagesToLoad.length === this.state.imagesLoaded.length
    ) {
      return;
    }

    const self = this; //variable necesaria para acceder al contexto this dentro de la función del callback de la promesa
    const awsUtils = new AwsUtils();
    const imagesLoaded = [];

    try {
      for (let i = 0; i < this.state.imagesToLoad.length; i++) {
        const imageKey = this.state.imagesToLoad[i];

        const res = await awsUtils.getS3Resource(this.state.s3bucket, imageKey);
        const extension = getExtensionFromBase64(res.Body.toString('base64'));
        const image = 'data:image/' + extension + ';base64,' + res.Body.toString('base64');

        imagesLoaded[i] = image;
        if (imagesLoaded.length === self.state.imagesToLoad.length) {
          self.setState({
            loadingImages: false,
            imagesLoaded: imagesLoaded,
          });

          self.handleLoadComplete(imagesLoaded);
        }
      }
    } catch (e) {
      console.error('Error loading image', e);
    }
  }

  render() {
    return <div />;
  }
}

function getExtensionFromBase64(data) {
  const decoded = window.atob(data);
  const lowerCase = decoded.toLowerCase();
  let extension = 'jpg';

  if (lowerCase.indexOf('png') !== -1) {
    extension = 'png';
  } else if (lowerCase.indexOf('jpg') !== -1 || lowerCase.indexOf('jpeg') !== -1) {
    extension = 'jpg';
  }

  return extension;
}

export default ImageLoader;
