import React from 'react';
import PropTypes from 'prop-types';
import CheckboxButton from '../CheckboxButton';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

const NOOP = () => {};

const ImageSelector = ({
  // imageKeys = [],
  imagesToRender = '',
  selectedImages = '',
  id = '',
  borderStyle = '',
  handleImageSelect = NOOP,
}) => {
  const imageKeysSelected = selectedImages;
  const handleChange = e => {
    const name = e.target.name;
    // const selectedImageIndex = name.substring(name.indexOf('_') + 1);
    // const selectedImageIndex = e.target.name.substring(name.indexOf('_') + 1);
    const imagePath = name.substring(name.indexOf('_') + 1);
    if (isArray(imageKeysSelected)) {
      if (imageKeysSelected.includes(imagePath)) {
        imageKeysSelected.splice(imageKeysSelected.indexOf(imagePath), 1);
      } else {
        imageKeysSelected.push(imagePath);
      }
      handleImageSelect(imageKeysSelected);
    } else {
      handleImageSelect(imagePath);
    }
  };

  const imagesToSelect = imagesToRender.map((image, index) => {
    let isSelected = false;

    if (!isEmpty(selectedImages)) {
      if (isArray(selectedImages) && selectedImages.includes(image.imageKey)) {
        isSelected = true;
      } else if (selectedImages === image.imageKey) {
        isSelected = true;
      }
    }

    // if (!isEmpty(selectedImages)) {
    //   if (isArray(selectedImages) && selectedImages.includes(`${index}`)) {
    //     isSelected = true;
    //   } else if (selectedImages == index) {
    //     isSelected = true;
    //   }
    // }

    return (
      <CheckboxButton
        key={`${id}_${index}`}
        borderStyle={borderStyle}
        id={`${id}_${image.imageKey}`}
        name={`${id}_${image.imageKey}`}
        value={isSelected}
        className='image-selector'
        // handleChange={handleImageSelect}
        handleChange={e => handleChange(e)}
        content={
          <img
            id={`image${index}`}
            key={`image${index}`}
            src={image.imageData}
            alt={`image${index}`}
          />
        }
        noPadding
      />
    );
  });

  return (
    <div>
      {imagesToSelect}
      <style jsx>{`
        .image-selector {
          width: 45% !important;
          float: left !important;
          margin: 2%;
          height: 100% !important;
          opacity: 0.5;
        }
      `}</style>
      <style jsx global>{`
        .image-selector > img {
          width: auto !important;
          height: 80px !important;
        }
      `}</style>
    </div>
  );
};

ImageSelector.propTypes = {
  // imageKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  imagesToRender: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  selectedImages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
  borderStyle: PropTypes.string,
  handleImageSelect: PropTypes.func,
};

export default ImageSelector;
