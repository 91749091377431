import { Form } from 'antd';
import React, { Component } from 'react';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';

import IBAN from 'iban';
import PropTypes from 'prop-types';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import HomyIBAN from '../HomyComponents/HomyIBAN';
import InputAntdValidator from './InputAntdValidator';

export const validateIBAN = () => {
  const iban = [...document.getElementsByClassName('validate-accountNumber')];

  const isValidIban = iban.every(el =>
    el.value ? IBAN.isValid(IBAN.electronicFormat(el.value)) : true
  );

  if (!isValidIban) {
    openNotificationWithIcon(
      'error',
      'Se ha producido un error.',
      <div>
        <p>El formato del campo "Número de cuenta" no es correcto</p>
        <p style={{ marginBottom: 0 }}>
          Puedes comprobar si el IBAN es válido en{' '}
          <a href='https://www.iban.es' target='_blank' rel='noopener noreferrer'>
            la web del IBAN
          </a>
        </p>
      </div>
    );
  }

  return isValidIban;
};

class FormIBAN extends Component {
  static propTypes = {
    fieldDecorator: PropTypes.string,
    form: PropTypes.shape({}).isRequired,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    initialValue: PropTypes.string,
    isRequiredMessage: PropTypes.string,
    isWrongMessage: PropTypes.string,
  };

  static defaultProps = {
    fieldDecorator: 'accountNumber',
    isRequired: false,
    label: 'Número de cuenta (IBAN)',
    isRequiredMessage: 'Por favor, introduce un número de IBAN.',
    isWrongMessage: 'Introduce un número de IBAN válido.',
    name: 'accountNumber',
    initialValue: '',
  };

  handleValidIBAN = (rule, value, callback) => {
    if (value) {
      const isValid = isValidIBAN(electronicFormatIBAN(value));

      if (!isValid) {
        callback(
          <span>
            El IBAN introducido no es correcto. Consultalo en{' '}
            <a
              href='https://www.iban.es'
              target='_blank'
              rel='noopener noreferrer'
              className='has-error ant-form-explain'
            >
              <u>la web del IBAN.</u>
            </a>
          </span>
        );
      }
    }

    callback();
  };

  render() {
    const {
      fieldDecorator,
      form,
      isRequired,
      isRequiredMessage,
      isWrongMessage,
      label,
      name,
      initialValue,
    } = this.props;

    return (
      <InputAntdValidator
        fieldDecorator={fieldDecorator}
        form={form}
        isRequired={isRequired}
        isRequiredMessage={isRequiredMessage}
        isWrongMessage={isWrongMessage}
        label={label}
        validator={this.handleValidIBAN}
        initialValue={initialValue}
      >
        <HomyIBAN name={name} label='' onChange={this.props.onChange} />
      </InputAntdValidator>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    return {
      [props.name]: Form.createFormField({
        ...props,
      }),
    };
  },
})(FormIBAN);
