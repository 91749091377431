import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import has from 'lodash/has';
import toNumber from 'lodash/toNumber';
import join from 'lodash/join';
import get from 'lodash/get';
import set from 'lodash/set';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import { browserHistory } from 'react-router';
import { closeSession } from '../../modules/LoginControl/LoginControlActions';
import {
  ENDPOINT,
  ENDPOINT_V2,
  SORT_QUERY_STRING,
  ENDPOINT_PDF_SERVICE,
  MAXIMUM_PROPERTIES,
} from '../appConfig';
import { createQueryString } from './apiUtils';
import { adjustDay } from './dateUtils';
import { resolveParams } from './appUtils';
import moment from 'moment';

const etag = 'if-match';
const request = (url, method = 'GET', settings = {}) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.jwt,
      ORIGIN: localStorage.ORIGIN || '',
      ...(settings.headers || {}),
    };

    fetch(url, {
      ...settings,
      method,
      headers,
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          const contentType = response.headers.get('content-type');

          if (contentType && contentType.indexOf('application/json') !== -1) {
            return response
              .json()
              .then(json => ({
                headers: response.headers,
                status: response.status,
                json,
              }))
              .then(resolve)
              .catch(() => resolve());
          }

          return response
            .text()
            .then(text => ({
              headers: response.headers,
              status: response.status,
              text,
            }))
            .then(resolve)
            .catch(() => resolve());
        } else if (response.status == 403) {
          closeSession();
        }

        response
          .json()
          .then(reject)
          .catch(reject);
      })
      .catch(e => {
        console.error(e);
        reject(
          new Error(
            `Ha ocurrido un problema con su solicitud.
                  Este problema puede deberse a que no tengas conexión a Internet.
                  Por favor, verifica tu conexión y si el problema persiste contacta con soporte.
                  Ten en cuenta que tu última acción puede no haber llegado a procesarse`
          )
        );
      });
  });

const getFilter = filters => {
  filters = mapValues(filters, (value, key) => {
    if (includes(key.toLowerCase(), 'date') && value) {
      return adjustDay(value).valueOf();
    }

    if (isNil(value) || trim(value) === '') {
      return undefined;
    }

    return value;
  });

  return createQueryString(filters);
};

const getFilterV2 = filters =>
  mapValues(filters, (value, key) => {
    if (includes(key.toLowerCase(), 'date') && value) {
      return moment(value).format('YYYY-MM-DD');
    }

    if (isNil(value) || trim(value) === '') {
      return undefined;
    }

    return value;
  });

const getMapDataV2 = (entity, { geolocation = {}, filters = {} } = {}) => {
  const url = `${ENDPOINT_V2}/${entity}/search`;
  const criteria = {};

  if (!isEmpty(filters.providerType)) {
    set(criteria, 'ownerCriteria.providerTypes', filters.providerType.split(' '));
  }

  if (isBoolean(filters.verified)) {
    set(criteria, 'ownerCriteria.acceptedVerification', filters.verified);
  }

  if (isString(filters.providerId)) {
    set(criteria, 'ownerCriteria.providerId', filters.providerId);
  }

  if (isNumber(filters.startDate) || moment.isMoment(filters.startDate)) {
    set(criteria, 'availabilityCriteria.from', adjustDay(filters.startDate).format('YYYY-MM-DD'));
  }

  if (isNumber(filters.endDate) || moment.isMoment(filters.endDate)) {
    set(criteria, 'availabilityCriteria.to', adjustDay(filters.endDate).format('YYYY-MM-DD'));
  }

  if (isNumber(geolocation.latA)) {
    set(criteria, 'locationCriteria.boundedArea.upperLeftCorner.lat', geolocation.latA);
  }

  if (isNumber(geolocation.lonA)) {
    set(criteria, 'locationCriteria.boundedArea.upperLeftCorner.lng', geolocation.lonA);
  }

  if (isNumber(geolocation.latB)) {
    set(criteria, 'locationCriteria.boundedArea.bottomRightCorner.lat', geolocation.latB);
  }

  if (isNumber(geolocation.lonB)) {
    set(criteria, 'locationCriteria.boundedArea.bottomRightCorner.lng', geolocation.lonB);
  }

  if (isNumber(filters.ubicacionFromLat)) {
    set(criteria, 'locationCriteria.targetPoint.lat', filters.ubicacionFromLat);
  }

  if (isNumber(filters.ubicacionFromLng)) {
    set(criteria, 'locationCriteria.targetPoint.lng', filters.ubicacionFromLng);
  }

  if (isNumber(filters.maxPrice)) {
    set(criteria, 'pricingCriteria.monthlyPrice', { value: filters.maxPrice, comparator: 'LTE' });
  }

  if (isBoolean(filters.comissionable)) {
    set(criteria, 'pricingCriteria.commissionable', filters.comissionable);
  }

  if (isBoolean(filters.cleaningChargeType)) {
    set(criteria, 'pricingCriteria.withCleaningCharges', filters.cleaningChargeType);
  }

  if (isNumber(filters.highConversionProbability)) {
    set(criteria, 'pricingCriteria.conversionProbability', {
      value: filters.highConversionProbability,
      comparator: 'GTE',
    });
  }

  if (isNumber(filters.guest)) {
    set(criteria, 'amenitiesCriteria.tenants', {
      value: filters.guest,
      comparator: 'GTE',
    });
  }

  if (isNumber(filters.beds)) {
    set(criteria, 'amenitiesCriteria.beds', {
      value: filters.beds,
      comparator: filters.bedsFilter === 'bedsMoreThan' ? 'GTE' : 'EQ',
    });
  }

  if (isNumber(filters.rooms)) {
    set(criteria, 'amenitiesCriteria.rooms', {
      value: filters.rooms,
      comparator: filters.roomsFilter === 'roomsMoreThan' ? 'GTE' : 'EQ',
    });
  }

  if (isNumber(filters.roomsMoreThan)) {
    set(criteria, 'amenitiesCriteria.rooms', {
      value: filters.roomsMoreThan,
      comparator: 'GTE',
    });
  }

  if (isNumber(filters.bathsAndToilets)) {
    set(criteria, 'amenitiesCriteria.bathrooms', {
      value: filters.bathsAndToilets,
      comparator: 'GTE',
    });
  }

  if (isBoolean(filters.nearbyCarPark)) {
    set(criteria, 'amenitiesCriteria.nearbyCarPark', filters.nearbyCarPark);
  }

  if (isBoolean(filters.freeCarPark)) {
    set(criteria, 'amenitiesCriteria.freeCarPark', filters.freeCarPark);
  }

  if (isBoolean(filters.airConditioning)) {
    set(criteria, 'amenitiesCriteria.airConditioning', filters.airConditioning);
  }

  if (isBoolean(filters.wifi)) {
    set(criteria, 'amenitiesCriteria.wifi', filters.wifi);
  }

  if (isBoolean(filters.lift)) {
    set(criteria, 'amenitiesCriteria.lift', filters.lift);
  }

  if (isBoolean(filters.petsAllowed)) {
    set(criteria, 'amenitiesCriteria.petsAllowed', filters.petsAllowed);
  }

  if (isBoolean(filters.publishedInWeb)) {
    set(criteria, 'publicationCriteria.published', filters.publishedInWeb);
  }

  set(
    criteria,
    'publicationCriteria.publicationStatus',
    isBoolean(filters.publicationStatus) ? filters.publicationStatus : 'PUBLISHED'
  );

  if (isNumber(filters.incorporationDateStart)) {
    set(
      criteria,
      'publicationCriteria.incorporationFrom',
      adjustDay(filters.incorporationDateStart).format('YYYY-MM-DD')
    );
  }

  const body = {
    criteria,
    scope: {
      notDeleted: true,
      ownerNotDisabled: true,
    },
    page: {
      index: 0,
      size: MAXIMUM_PROPERTIES,
    },
  };

  return request(url, 'post', { body: JSON.stringify(body) });
};

const getTableData = (entity, { pagination = {}, filters = {}, sorter = '' } = {}) => {
  let url = `${ENDPOINT}/${entity}/page`;

  if (!isEmpty(pagination)) {
    url += `/${pagination.page - 1}?size=${pagination.pageSize}`;
  } else {
    url += `/0?size=15`;
  }

  if (!isEmpty(filters)) {
    const queryString = getFilter(filters);

    url += queryString ? `&${queryString}` : '';
  }

  if (sorter) {
    url += `&${SORT_QUERY_STRING}=${encodeURIComponent(sorter)}`;
  }

  return request(url, 'get');
};

const sortingSignMap = {
  '+': 'ASC',
  '-': 'DESC',
};

const sortingFieldMapByEntity = {
  opportunities: {
    identificationCode: 'identificationCode.raw',
    companyName: 'companyName.raw',
    rentalRequestType: 'rentalRequestType.raw',
    rentalRequestStage: 'rentalRequestStage.raw',
    location: 'location.locality.raw',
    customerRate: 'customerRate.amount',
    providerRate: 'providerRate.amount',
    hsagent: 'hsagent.raw',
  },
};

const getSearchSorting = (sorter, entity) => {
  if (!sorter) {
    return null;
  }

  const [sign, ...fieldChars] = sorter;
  const field = join(fieldChars, '');

  const sortingField = get(sortingFieldMapByEntity, `${entity}.${field}`, field);

  if (
    (entity === 'providers' && sortingField === 'name') ||
    sortingField === 'lastOTradeName' ||
    sortingField === 'locality'
  ) {
    return {
      by: sortingField + '.raw',
      direction: sortingSignMap[sign],
    };
  }

  return {
    by: sortingField,
    direction: sortingSignMap[sign],
  };
};

const getTableDataV2 = (entity, { pagination = {}, filters = {}, sorter = '' } = {}) => {
  const url = `${ENDPOINT_V2}/${entity}/search`;

  const body = {
    criteria: getFilterV2(filters),
    sorting: getSearchSorting(sorter, entity),
    page: {
      index: get(pagination, 'page', 1) - 1,
      size: get(pagination, 'pageSize', 15),
    },
  };

  return request(url, 'post', { body: JSON.stringify(body) });
};

const getExportToExcelData = (entity, filters = {}) => {
  let url = `${ENDPOINT}/${entity}/export`;

  if (!isEmpty(filters)) {
    const queryString = getFilter(filters);

    url += queryString ? `?${queryString}` : '';
  }

  return request(url, 'get');
};

const request_retry = async (url, method, n = 1, settings = {}) => {
  try {
    return await request(url, method, settings);
  } catch (err) {
    console.error('Error calling to ' + url + ': ' + err.message + '. Retrying...');
    if (n === 1) {
      throw err;
    }

    return await request_retry(url, method, n - 1, settings);
  }
};

const redirectToPage = (entity, id) => browserHistory.push(`/${entity}/${id}`);

const generateId = (entity, queryParams) => {
  const url = resolveParams(`${ENDPOINT}/${entity}`, queryParams);

  return request(url, 'post');
};

const save = (entity, id, body, queryParams) => {
  const url = resolveParams(`${ENDPOINT}/${entity}/${id}`, queryParams);
  let headers;

  if (has(body, 'etag')) {
    headers = {
      [etag]: body.etag,
    };
  }

  return request(url, 'put', { body: JSON.stringify(body), headers });
};

const post = (url, body) => {
  return request(url, 'post', { body: JSON.stringify(body) });
};

const saveUrl = (url, body) => {
  let headers;

  if (has(body, 'etag')) {
    headers = {
      [etag]: body.etag,
    };
  }

  return request(url, 'put', { body: JSON.stringify(body), headers });
};

const remove = (entity, id, body = {}) => {
  const url = `${ENDPOINT}/${entity}/${id}`;
  let headers;

  if (has(body, 'etag')) {
    headers = {
      [etag]: toNumber(body.etag),
    };
  }

  return request(url, 'delete', { body: JSON.stringify(body), headers });
};

const getDetailData = (entity, id) => {
  const url = `${ENDPOINT}/${entity}/${id}`;

  return request(url, 'get');
};

const getDetailDataV2 = (entity, id) => {
  const url = `${ENDPOINT_V2}/${entity}/${id}`;

  return request(url, 'get');
};

export async function getContractPDF(id) {
  const path = `pdf/${id}`;
  const url = encodeURI(`${ENDPOINT_PDF_SERVICE}/${path}`);

  try {
    const args = {
      method: 'POST',
      headers: {
        Accept: 'application/pdf',
        Authorization: 'Bearer ' + localStorage.jwt,
      },
      body: JSON.stringify({ location: `contract/leasing` }),
    };

    const res = await fetch(url, args);
    return res;
  } catch (e) {
    const error = 'No ha sido posible imprimir el documento.\n'.concat(e);
    console.error('error message concat', error);
    throw e;
  }
}

export default {
  save,
  post,
  saveUrl,
  remove,
  request,
  request_retry,
  getDetailData,
  getDetailDataV2,
  getMapDataV2,
  getTableData,
  getTableDataV2,
  getExportToExcelData,
  generateId,
  redirectToPage,
  getFilterV2,
};
