import { notification } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { DATE_FORMAT, TOTAL_STAGES } from '../appConfig';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';

export const CURRENCY_LETTER = {
  EUR: '€',
};

const TAXTYPE = {
  IGIC: 'IGIC',
  IPSI: 'IPSI',
  IVA: 'IVA',
  NO_TAX: 'NO_TAX',
};

const LOCATION = {
  SPAIN_COUNTRY: 'España',
  CEUTA_ADMIN_AREA_L1: 'Ceuta',
  MELILLA_ADMIN_AREA_L1: 'Melilla',
  CANARIAS_ADMIN_AREA_L1: 'Canarias',
};

export const CONTACT_ROLES = {
  USUAL: 'USUAL',
  INVOICING: 'INVOICING',
  CONTRACT_MANAGEMENT: 'CONTRACT_MANAGEMENT',
  CONTRACT_SIGNING: 'CONTRACT_SIGNING',
  TENANT: 'TENANT',
  LANDLORD: 'LANDLORD',
  LEASEHOLDER: 'LEASEHOLDER',
  UNKNOWN: 'UNKNOWN',
};

export const CONTACT_TYPE = {
  COMPANY: 'COMPANY',
  //...
};

export const BUSINESS_EVENT_TYPES = {
  EMAIL: 'EMAIL',
  CALL: 'CALL',
  WHATSAPP: 'WHATSAPP',
  NOTE: 'NOTE',
  PROCESS_EVENT: 'PROCESS_EVENT',
  TASK: 'TASK',
  FOLLOW_UP: 'FOLLOW_UP',
  AVAILABILITY_QUERY: 'AVAILABILITY_QUERY',
  SMS: 'SMS',
};
export const COMMODITIES = [
  { title: 'TV', name: 'tv' },
  { title: 'WIFI', name: 'wifi' },
  { title: 'Calefacción', name: 'heating' },
  { title: 'Aire acondicionado', name: 'airConditioning' },
  { title: 'Ascensor', name: 'lift' },
  { title: 'Microondas', name: 'microwaveOven' },
  { title: 'Horno', name: 'oven' },
  { title: 'Menaje', name: 'kitchenware' },
  { title: 'Lavadora', name: 'washerMachine' },
  { title: 'Secadora', name: 'dryerMachine' },
  { title: 'Plancha', name: 'iron' },
  { title: 'Ropa de cama', name: 'bedding' },
  { title: 'Toallas', name: 'towels' },
  { title: 'Facilidad para aparcar', name: 'easyParking' },
  { title: 'Parking gratuito', name: 'freeCarPark' },
  { title: 'Parking de pago', name: 'pearbyCarPark' },
  { title: 'Se permite fumar', name: 'smokingAllowed' },
  { title: 'Se permiten mascotas', name: 'petsAllowed' },
];

export const PROVIDER_TYPES = [
  { title: 'Particular', name: 'PRIVATE', label: 'Particulares' },
  { title: 'Property Manager', name: 'MANAGER', label: 'Properties' },
  { title: 'Prov. Empresa', name: 'COMPANY', label: 'Empresas' },
];

export const RENTAL_REQUEST_STAGES = {
  S000_REQUEST: 'S000_REQUEST',
  S010_PROPERTY_SEARCH: 'S010_PROPERTY_SEARCH',
  S020_SEND_SEARCH_RESULTS: 'S020_SEND_SEARCH_RESULTS',
  S030_CONTRACT_INVOICE_MANAGMENT: 'S030_CONTRACT_INVOICE_MANAGMENT',
  S040_CHECK_IN: 'S040_CHECK_IN',
  S050_RENTAL_CONFIRMED: 'S050_RENTAL_CONFIRMED',
  S070_LOST_NEGOTATION: 'S070_LOST_NEGOTATION',
};

export const YES_NO_NOINFO_OPTIONS = [
  { value: true, label: 'Sí' },
  { value: false, label: 'No' },
  { value: 'no-info', label: 'Sin información' },
];

export const TRANLATED_ROLES = {
  USUAL: 'Habitual',
  INVOICING: 'Facturación',
  CONTRACT_MANAGEMENT: 'Gestión de contrato',
  CONTRACT_SIGNING: 'Firma de contrato',
  TENANT: 'Inquilino',
  LANDLORD: 'Arrendador',
  LEASEHOLDER: 'Arrendatario',
  UNKNOWN: 'Desconocido',
};

export const DEAL_RENTAL_DURATION_TYPE = {
  EXACT: 'EXACT',
  APPROX: 'APPROX',
  EXACT_POSSIBLE_EXTENSION: 'EXACT_POSSIBLE_EXTENSION',
};

export const DEAL_ENTRY_DATE_TYPE = {
  EXACT: 'EXACT',
  APPROX: 'APPROX',
  EXACT_FLEXIBLE: 'EXACT_FLEXIBLE',
};

export const removeEmptyKeysFromObject = obj => {
  Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);

  return obj;
};

export const mapDateOptionLabels = options =>
  map(options, option => {
    if (option.value === 'EXACT_FLEXIBLE' || option.value === 'EXACT_POSSIBLE_EXTENSION') {
      return {
        ...option,
        label: `${option.label} (+ 5 días)`,
      };
    }

    if (option.value === 'APPROX') {
      return {
        ...option,
        label: `${option.label} (+ 10 días)`,
      };
    }

    return option;
  });

export const getAddressFromWorldWideAddress = address => {
  if (address && !address.locality) {
    const valuesByAdministrative = Object.keys(address)
      .filter(key => key.indexOf('administrative') === 0)
      .reduce((newData, k) => {
        newData[k] = address[k];

        return newData;
      }, {});

    for (let i = Object.keys(valuesByAdministrative).length; i >= 0; i--) {
      if (valuesByAdministrative[Object.keys(valuesByAdministrative)[i]]) {
        return valuesByAdministrative[Object.keys(valuesByAdministrative)[i]];
      }
    }
  } else {
    return address && address.locality ? address.locality : '';
  }
};

export const getLocalityOrAdministrativeAreaLevelTwoOrOne = address => {
  if (!isEmpty(get(address, 'locality'))) {
    return get(address, 'locality');
  }

  if (!isEmpty(get(address, 'postalTown'))) {
    return get(address, 'postalTown');
  }

  if (!isEmpty(get(address, 'administrativeAreaLevel2'))) {
    return get(address, 'administrativeAreaLevel2');
  }

  if (!isEmpty(get(address, 'administrativeAreaLevel1'))) {
    return get(address, 'administrativeAreaLevel1');
  }
};

// export const checkDateYear = date => {
//   const dateYear = date.year();

//   return (
//     dateYear === moment().year() ||
//     dateYear ===
//       moment()
//         .subtract(1, 'year')
//         .year() ||
//     dateYear ===
//       moment()
//         .subtract(2, 'year')
//         .year() ||
//     dateYear ===
//       moment()
//         .add(1, 'year')
//         .year() ||
//     dateYear ===
//       moment()
//         .add(2, 'year')
//         .year()
//   );
// };

// export const isValidDate = date => date && moment(date).isValid() && checkDateYear(moment(date));

// export const areValidDates = (dateIn, dateOut) => {
//   const checkIn = moment(dateIn);
//   const checkOut = moment(dateOut);

//   if (!checkIn || !checkOut || isNaN(checkIn) || isNaN(checkOut)) {
//     return false;
//   }

//   return isValidDate(dateIn) && isValidDate(dateOut) && checkOut.isAfter(checkIn);
// };

export const normalizeString = string =>
  string
    ? string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
    : '';

export const isCeuta = address =>
  normalizeString(address) === normalizeString(LOCATION.CEUTA_ADMIN_AREA_L1);

export const isMelilla = address =>
  normalizeString(address) === normalizeString(LOCATION.MELILLA_ADMIN_AREA_L1);

export const isCanaryIslands = address =>
  normalizeString(address) === normalizeString(LOCATION.CANARIAS_ADMIN_AREA_L1);

export const isCeutaMelillaOrCanary = address =>
  isCeuta(address) || isMelilla(address) || isCanaryIslands(address);

export const isCeutaMelilla = address => isCeuta(address) || isMelilla(address);

export const isSpain = address =>
  normalizeString(address) === normalizeString(LOCATION.SPAIN_COUNTRY);

export const getTaxTypeFromAddress = worldWideAddress => {
  // En funcion de la ubicación del inmueble
  if (worldWideAddress && isSpain(worldWideAddress.country)) {
    const address = worldWideAddress.administrativeAreaLevel1;

    if (isCanaryIslands(address)) {
      return TAXTYPE.NO_TAX;
    }

    if (isCeutaMelilla(address)) {
      return TAXTYPE.NO_TAX;
    }

    return TAXTYPE.IVA;
  }

  return TAXTYPE.NO_TAX;
};

export const getTaxPercentFromTaxType = taxType => {
  // En funcion de la ubicación del inmueble
  if (TAXTYPE.IGIC === taxType) {
    return 7;
  }

  if (TAXTYPE.IPSI === taxType) {
    return 4;
  }

  if (TAXTYPE.IVA === taxType) {
    return 21;
  }

  return 0;
};

export const getTaxPercentCleaningCharges = worldWideAddress => {
  // En funcion de la ubicación del inmueble, por determinar el porcentaje
  if (worldWideAddress && isSpain(worldWideAddress.country)) {
    const address = worldWideAddress.administrativeAreaLevel1;

    if (isCanaryIslands(address)) {
      return 7;
    }

    if (isCeuta(address)) {
      return 6;
    }

    if (isMelilla(address)) {
      return 4;
    }

    return 21;
  }

  return 0;
};

export const getTaxPercent = worldWideAddress => {
  // En funcion de la ubicación del inmueble, por determinar el porcentaje
  if (
    worldWideAddress &&
    isSpain(worldWideAddress.country) &&
    !isCeutaMelillaOrCanary(worldWideAddress.administrativeAreaLevel1)
  ) {
    return 21;
  }

  return 0;
};

export const getNumberOfApplicationsTaxArea = proposals => {
  // eslint-disable-next-line no-undef
  const applications = new Set();

  proposals.forEach(proposal => {
    const { address } = proposal;

    applications.add(getTaxTypeFromAddress(address));
  });

  return applications.size;
};

export const getNumberOfApplicationsTaxByTaxType = proposals => {
  const counts = {};

  proposals.forEach(proposal => {
    const taxType = getTaxTypeFromAddress(proposal.address);

    counts[taxType] = (counts[taxType] || 0) + 1;
  });

  return counts;
};

export const getContactsOfCompany = (contacts, baseContactId) =>
  contacts.filter(
    contactInfo =>
      contactInfo.contact.id !== baseContactId ||
      contactInfo.roles.includes('CONTRACT_SIGNING') ||
      contactInfo.roles.includes('LEASEHOLDER')
  );

export const getMetasourceLink = (metasource, metasourceStrings, metasourceString) => {
  if (metasource._links) {
    metasource.links = metasource._links;
  }

  if (!metasource || !metasource.links) {
    return;
  }

  return metasource.links[metasourceStrings[metasourceString]];
};

export const resolveParams = (url, params = {}) => {
  const idx = url.indexOf('{');
  const result = idx >= 0 ? url.substring(0, idx) : url;

  if (Object.keys(params).length === 0) {
    return result;
  }

  const paramsString = Object.keys(params)
    .map(p => `${p}=${params[p]}`)
    .join('&');

  return `${result}?${paramsString}`;
};

const isDeprecated = (options, value) => value && options.map(op => op.value).indexOf(value) < 0;

export const getOptionsForSelect = (optionLabels, arrayName) => {
  if (isEmpty(optionLabels)) {
    return;
  }

  let values = [];

  for (let i = 0; i < optionLabels.length; i++) {
    const optionLabel = optionLabels[i];

    if (optionLabel.arrayName === arrayName) {
      values = optionLabel.values;
      break;
    }
  }

  return values;
};

export const getOptionsForSelectWithDeprecationSupport = (options, currentValue) => {
  options = !isEmpty(options) ? options : [];
  if (isDeprecated(options, currentValue)) {
    options.push({ value: currentValue, label: currentValue });
  }

  return options;
};

export const getTextValueFromSelectOptions = (value, selectOptions) => {
  if (isEmpty(selectOptions)) {
    return;
  }

  for (let i = 0; i < selectOptions.length; i++) {
    if (selectOptions[i] && selectOptions[i].value === value) {
      return selectOptions[i].label;
    }
  }

  return value;
};

export const getTextValuesFromSelectedOptions = (value, selectOptions) => {
  let values = '';

  if (isEmpty(selectOptions)) {
    return;
  }

  for (let i = 0; i < selectOptions.length; i++) {
    for (let j = 0; j < value.length; j++) {
      if (selectOptions[i] && selectOptions[i].value === value[j]) {
        values += selectOptions[i].label.concat(value.length - 1 === j ? '' : ', ');
      }
    }
  }

  return values;
};

export const getValuesFromTextSelectOptions = (text, selectOptions) => {
  if (isEmpty(selectOptions)) {
    return;
  }

  for (let i = 0; i < selectOptions.length; i++) {
    if (selectOptions[i] && selectOptions[i].label === text) {
      return selectOptions[i].value;
    }
  }

  return text;
};

export const getChildrenOfSelectedParent = (parent, children) =>
  children.filter(child => child.parent === parent);

export const getChildrenValuesOfSelectedParent = (children, parent) => {
  const options = [];

  parent.forEach(option => {
    children.forEach(child => {
      if (option.value === child.value) {
        options.push(child);
      }
    });
  });

  return options;
};

export const findElementsInArrayByPropertyValue = (
  array,
  propertyLevel1,
  propertyLevel2 = null,
  value
) => {
  if (!array || array.length <= 0) {
    return;
  }

  const result = array.find(element => {
    if (!propertyLevel2) {
      return element[propertyLevel1] === value;
    }

    return element[propertyLevel1][propertyLevel2] === value;
  });

  return result;
};

export const getEndingPercentDealStage = (currentStage, stageList) => {
  if (!currentStage || !stageList || stageList.length <= 0) {
    return 0;
  }

  let stageIndex = 0;

  stageList.find((element, i) => {
    if (element.value === currentStage) {
      stageIndex = i;

      return true;
    }

    return false;
  });
  if (!stageIndex) {
    return 0;
  }

  return (stageIndex / TOTAL_STAGES) * 100;
};

export const getCompanyList = companies => {
  const companyList = [];

  if (!companies) {
    return companyList;
  }

  for (let i = 0; i < companies.length; i++) {
    const company = companies[i];
    let labelValue = '';

    if (get(company, 'companyType') === 'PERSON') {
      labelValue = '(Persona física) - ';
    } else if (get(company, 'companyType') === 'COMPANY') {
      labelValue = '(Persona jurídica) - ';
    }

    labelValue += `${get(company, 'name') || ''} ${get(company, 'lastOTradeName') || ''}`;
    companyList.push({
      label: labelValue,
      value: get(company, 'id'),
    });
  }

  return companyList;
};

export const getContactList = contacts => {
  let contactList = [];

  if (!contacts) {
    return contactList;
  }

  for (let i = 0; i < contacts.length; i++) {
    const contact = contacts[i];
    let labelValue = '';

    if (contact.contact.contactType === 'PERSON') {
      labelValue = `${
        contact.contact.relatedProvider
          ? '(Proveedor) - '
          : contact.contact.relatedCompany
          ? '(Cliente) - '
          : ''
      }(Persona física) - ${contact.contact.name ? contact.contact.name : '[Sin nombre]'}`;
      labelValue += ` ${contact.contact.lastOTradeName || '[Sin apellido]'}`;
    } else {
      labelValue += `${
        contact.contact.relatedProvider
          ? '(Proveedor) - '
          : contact.contact.relatedCompany
          ? '(Cliente) - '
          : ''
      }(Persona jurídica) - ${contact.contact.lastOTradeName ||
        (contact.contact.name ? contact.contact.name : '[Sin nombre]')}`;
    }

    contactList.push({
      label: `${labelValue}${get(contact.contact, 'email', '')}`,
      value: contact.contact.id,
    });

    if (contact.children) {
      contactList = contactList.concat(getContactList(contact.children));
    }
  }

  return contactList;
};

export const getTenantsList = (contacts, selectedContactId = null) => {
  let contactList = [];

  if (!contacts) {
    return contactList;
  }

  for (let i = 0; i < contacts.length; i += 1) {
    const contact = contacts[i];

    if (contact.contact.id !== selectedContactId && contact.roles.includes('TENANT')) {
      contactList.push({
        label: `${contact.contact.name ? contact.contact.name : ''} ${
          contact.contact.lastOTradeName ? contact.contact.lastOTradeName : ''
        } ${contact.contact.identificationCode ? `(${contact.contact.identificationCode})` : ''}`,
        value: contact.contact.id,
      });
      if (contact.children) {
        contactList = contactList.concat(getContactList(contact.children, selectedContactId));
      }
    }
  }

  return contactList;
};

export const getExtendedContactList = (contacts, selectedContactId = null) => {
  let contactList = [];

  if (!contacts) {
    return contactList;
  }

  for (let i = 0; i < contacts.length; i++) {
    const contact = contacts[i];

    if (contact.contact.id === selectedContactId) {
      continue;
    }

    let labelContent = contact.contact.name;

    if (contact.contact.lastOTradeName) {
      labelContent = `${labelContent} ${contact.contact.lastOTradeName}`;
    }

    if (contact.contact.phoneNumber) {
      labelContent = `${labelContent} - ${contact.contact.phoneNumber}`;
    }

    if (contact.contact.mobilePhoneNumber) {
      labelContent = `${labelContent} - ${contact.contact.mobilePhoneNumber}`;
    }

    if (contact.contact.email) {
      labelContent = `${labelContent} - ${contact.contact.email}`;
    }

    if (contact.contact.email2) {
      labelContent = `${labelContent} - ${contact.contact.email2}`;
    }

    if (contact.contact.accountNumber) {
      labelContent = `${labelContent} - ${contact.contact.accountNumber}`;
    }

    contactList.push({
      label: labelContent,
      value: contact.contact.id,
      contact: contact.contact,
    });
    if (contact.children) {
      contactList = contactList.concat(getExtendedContactList(contact.children, selectedContactId));
    }
  }

  return contactList;
};

export const getContactFromExtendedContactList = (contacts, selectedContactId = null) => {
  if (!contacts) {
    return;
  }

  for (let i = 0; i < contacts.length; i++) {
    const contact = contacts[i];

    if (contact.contact.id === selectedContactId) {
      return contact.contact;
    }

    if (contact.children) {
      const contactReturned = getContactFromExtendedContactList(
        contact.children,
        selectedContactId
      );

      if (contactReturned) {
        return contactReturned;
      }
    }
  }
};

export const getParentContact = (contacts, selectedContactId, parentContactId = null) => {
  if (!contacts) {
    return parentContactId;
  }

  for (let i = 0; i < contacts.length; i++) {
    const contact = contacts[i];

    if (contact.contact.id === selectedContactId) {
      return parentContactId;
    }

    if (contact.children) {
      const parentContactExplored = getParentContact(
        contact.children,
        selectedContactId,
        contact.contact.id
      );

      if (parentContactExplored !== null) {
        return parentContactExplored;
      }
    }
  }

  return null;
};

export const normalizeChildrenContacts = contactSet => {
  const normalizedContacts = [];

  if (!contactSet) {
    return normalizedContacts;
  }

  for (let i = 0; i < contactSet.length; i++) {
    const contact = contactSet[i];
    const normalizedContact = {
      contact: contact.contact,
      _links: contact._links,
      children: contact.contact.children,
    };

    delete normalizedContact.contact.children;
    if (normalizedContact.children) {
      normalizedContact.children = normalizeChildrenContacts(normalizedContact.children);
    }

    normalizedContacts.push(normalizedContact);
  }

  return normalizedContacts;
};

export const getProvidersList = providers => {
  const providersList = [];

  if (!providers) {
    return providersList;
  }

  for (let i = 0; i < providers.length; i++) {
    const provider = providers[i];

    providersList.push({
      label: `${provider.name || ''} ${provider.lastOTradeName || ''}${
        provider.email ? ` (${provider.email})` : ''
      }`,
      value: provider.id,
    });
  }

  return providersList;
};

export const getDealsList = deals => {
  const dealsList = [];

  if (!deals) {
    return dealsList;
  }

  for (let i = 0; i < deals.length; i++) {
    const deal = deals[i];

    dealsList.push({
      label: `${deal.identificationCode} - ${deal.companyName} - ${
        deal.location != null ? deal.location.administrativeAreaLevel2 : ''
      } - ${moment(deal.rentalStartDate).format(DATE_FORMAT)} / ${moment(deal.rentalEndDate).format(
        DATE_FORMAT
      )} - ${deal.hsagent ? deal.hsagent : 'sin gestor asignado'}`,
      value: deal.id,
    });
  }

  return dealsList;
};

export const getContactsList = contacts => {
  const contactsList = [];

  if (!contacts) {
    return contactsList;
  }

  for (let i = 0; i < contacts.length; i++) {
    const labelValue = formatContactLabel(contacts[i]);
    const contact = contacts[i];

    contactsList.push({
      label: labelValue,
      value: contact.contact.id,
      contact: contact.contact,
    });
  }

  return contactsList;
};

export const formatContactLabel = contact => {
  let labelValue = '';
  const contactRoles = translateRoles(contact.roles) ? ` - (${translateRoles(contact.roles)})` : '';

  if (contact.contact.contactType === 'PERSON') {
    labelValue = `${
      contact.contact.relatedProvider
        ? '(Proveedor) - '
        : contact.contact.relatedCompany
        ? '(Cliente) - '
        : ''
    }(Persona física)${contactRoles} - ${
      contact.contact.name ? contact.contact.name : '[Sin nombre]'
    }`;
    labelValue += ` ${contact.contact.lastOTradeName || '[Sin apellido]'}`;
  } else {
    labelValue += `${
      contact.contact.relatedProvider
        ? `(Proveedor) - `
        : contact.contact.relatedCompany
        ? `(Cliente) - `
        : ''
    }(Persona jurídica)${contactRoles} - ${contact.contact.lastOTradeName ||
      (contact.contact.name ? contact.contact.name : '[Sin nombre]')}`;
  }

  return labelValue;
};

const translateRoles = roles => {
  let rtn = '';

  if (roles) {
    for (let i = 0; i < roles.length; i++) {
      rtn += TRANLATED_ROLES[roles[i]];
      rtn += i < roles.length - 1 ? ', ' : '';
    }
  }

  return rtn !== '' ? rtn : undefined;
};

export const isEmptyObject = obj => !obj || Object.keys(obj).length === 0;

export const adjustI18 = i18 => {
  i18.locale = 'es';
  i18.DatePicker.lang = {
    backToToday: 'Hoy',
    clear: 'Limpiar',
    dateFormat: 'DD/MM/YYYY',
    dateSelect: 'Selecciona una fecha',
    dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
    dayFormat: 'DD',
    decadeSelect: 'Selecciona una década',
    month: 'Mes',
    monthBeforeYear: true,
    monthFormat: 'MMMM',
    monthSelect: 'Selecciona un mes',
    nextCentury: 'Próximo siglo',
    nextDecade: 'Próximo década',
    nextMonth: 'Próximo mes (PageDown)',
    nextYear: 'Próximo año (Control + right)',
    now: 'Ahora',
    ok: 'Ok',
    placeholder: 'Selecciona una fecha',
    previousCentury: 'Siglo anterior',
    previousDecade: 'Década anterior',
    previousMonth: 'Mes anterior (PageUp)',
    previousYear: 'Año anterior (Control + left)',
    rangePlaceholder: ['Inicio', 'Fin'],
    timeSelect: 'Selecciona una hora',
    today: 'Hoy',
    year: 'Año',
    yearFormat: 'YYYY',
    yearSelect: 'Selecciona una año',
  };
};

export const openNotificationWithIcon = (type, message, description, duration) => {
  const params = {
    message,
    description,
  };

  switch (type) {
    case 'success': {
      params.duration = 10;
      break;
    }

    case 'error': {
      params.duration = 30;
      break;
    }

    case 'info':
    case 'warn':
    case 'warning': {
      params.duration = 10;
      break;
    }

    default: {
      params.duration = 0;
      break;
    }
  }

  if (duration) {
    params.duration = duration;
  }

  notification[type](params);
};

export const replaceAccents = value => {
  if (!value) {
    return;
  }

  return value
    .replace(/á/gi, 'a')
    .replace(/à/gi, 'a')
    .replace(/é/gi, 'e')
    .replace(/í/gi, 'i')
    .replace(/ó/gi, 'o')
    .replace(/ò/gi, 'o')
    .replace(/ú/gi, 'u');
};

/**
 * Parses an array to a string separated by spaces, needed to make
 * a filtered call to the API
 */
export const parseArrayToStringForFilter = value =>
  value instanceof Array ? value.join(' ') : value;

/**
 * Parses a strign separated by spaces to an array needed
 * to set value of a multi-selection select
 * string: the stirng values separated by spaces
 * selectOptions: all the options of the select
 */
export const parseStringValuesToComboValue = (string, optionsOfSelect, mode) => {
  if (!string || string.length < 1) {
    return mode !== 'default' ? [] : undefined;
  }

  // For testing purposes
  // const optionsSelected = optionsOfSelect.filter(option =>
  //   string.includes(option.value)
  // );

  let optionsSelected;

  if (mode === 'default') {
    optionsSelected = optionsOfSelect.filter(option => string === option.value);
  } else {
    optionsSelected = optionsOfSelect.filter(option => string.includes(option.value));
  }

  if (!optionsSelected || optionsSelected.length < 1) {
    return mode !== 'default' ? [] : undefined;
  }

  // For testing purposes
  // const notDefaultMode = optionsSelected.map(option => option.value);
  // const defaultMode = optionsSelected.map(option => option.value).join(' ');

  return mode === 'default'
    ? optionsSelected.map(option => option.value).join(' ')
    : optionsSelected.map(option => option.value);
};

export const externalPriceAdjustment = price => {
  if (price) {
    let { amount } = price;

    if (!amount) {
      return '';
    }

    amount += amount * 0.3 + 100;

    return numeral(amount).format('0.00');
  }

  return '';
};

export const setKeyLabelESToLabel = obj => {
  if (obj) {
    for (let i = 0; i < obj.length; i++) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj[i].hasOwnProperty('labelES')) {
        obj[i].label = obj[i].labelES;
        delete obj[i].labelES;
      }
    }
  }

  return obj;
};

export const getYesNoOptionsForBooleanOptions = () => {
  const options = [];
  const yesOption = {
    label: 'Sí',
    value: true,
  };
  const noOption = {
    label: 'No',
    value: false,
  };

  options.push(yesOption);
  options.push(noOption);

  return options;
};
