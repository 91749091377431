import { browserHistory } from 'react-router';
import {
  GENERATE_INVOICE_PDF_ERROR,
  GENERATE_INVOICE_PDF_SUCCESS,
  INVOICEDETAILS_DATA_ERROR,
  INVOICEDETAILS_DATA_SUCCESS,
  INVOICEDETAILS_METASOURCE_ERROR,
  INVOICEDETAILS_METASOURCE_SUCCESS,
  INVOICEDETAILS_RESET,
  INVOICEDETAILS_SUBMIT_ENTRIES,
  INVOICEDETAILS_SUBMIT_ERROR,
  INVOICEDETAILS_SUBMIT_SUCCESS,
  NEW_INVOICE_CREATE_ERROR,
} from '../../shared/actionTypes';
import InvoiceDetailsApi from './InvoiceDetailsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink } from '../../shared/utils/appUtils';

export const resetState = () => ({
  type: INVOICEDETAILS_RESET,
});

export const metasourceSuccess = metasourceLinks => ({
  type: INVOICEDETAILS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: INVOICEDETAILS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const invoiceDetailsDataSuccess = (data, url, etag) => ({
  type: INVOICEDETAILS_DATA_SUCCESS,
  invoiceDetailsData: data,
  url,
  etag,
});

export const invoiceDetailsDataError = errorMessage => ({
  type: INVOICEDETAILS_DATA_ERROR,
  dealDetailsErrorDetails: errorMessage,
});

export const invoiceDetailsSubmitSuccess = etag => ({
  type: INVOICEDETAILS_SUBMIT_SUCCESS,
  etag,
});

export const invoiceDetailsSubmitError = errorMessage => ({
  type: INVOICEDETAILS_SUBMIT_ERROR,
  invoiceDetailsSubmitErrorDetails: errorMessage,
});

export const setInvoiceDetailsEntries = entries => ({
  type: INVOICEDETAILS_SUBMIT_ENTRIES,
  entries,
});

export const createNewInvoiceError = errorMessage => ({
  type: NEW_INVOICE_CREATE_ERROR,
  createNewInvoiceErrorDetails: errorMessage,
});

export const generateInvoicePdfSuccess = (pdfData, fileName) => ({
  type: GENERATE_INVOICE_PDF_SUCCESS,
  pdfData,
  fileName,
});

export const generateInvoicePdfError = errorMessage => ({
  type: GENERATE_INVOICE_PDF_ERROR,
  errorMessage,
});

export const disableErrorAction = () => ({
  type: 'DISABLE_ONCE',
  error: false,
});

export const showOrLoadInvoiceDetailsView = id =>
  function(dispatch, getState) {
    const url = getState().Preload.mainMetasource.invoices.href;

    return InvoiceDetailsApi.getMetasource(url, id)
      .then(response => {
        dispatch(metasourceSuccess(response.data._links));
        dispatch(getInvoiceDetailsData());
      })
      .catch(error => {
        console.error(error);
        dispatch(metasourceError(extractErrorMessage(error)));
      });
  };

export const getInvoiceDetailsData = () =>
  function(dispatch, getState) {
    const { metasource } = getState().InvoiceDetails;
    const { metasourceStrings } = getState().Preload;
    const urlReduced = getMetasourceLink(
      metasource,
      metasourceStrings,
      'INVOICE_DETAIL_METASOURCE_INVOICE_REDUCED'
    );

    if (!urlReduced || !urlReduced.href) {
      return null;
    }

    return InvoiceDetailsApi.getInvoiceDetailsData(urlReduced.href)
      .then(response => {
        const { etag } = response.headers;

        dispatch(invoiceDetailsDataSuccess(response.data.invoice, urlReduced.href, etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(invoiceDetailsDataError(extractErrorMessage(error)));
      });
  };

export const saveInvoiceDetailsData = invoiceDetailsData =>
  function(dispatch, getState) {
    const { etag } = getState().InvoiceDetails.data;
    const { url } = getState().InvoiceDetails.data;

    return InvoiceDetailsApi.saveInvoiceDetailsData(url, invoiceDetailsData, etag)
      .then(response => {
        const { etag } = response.headers;

        dispatch(invoiceDetailsSubmitSuccess(etag));
      })
      .catch(error => {
        console.error(error);
        dispatch(invoiceDetailsSubmitError(extractErrorMessage(error)));
      });
  };

export const createNewInvoice = (url, invoiceDetailsData) =>
  function(dispatch) {
    return InvoiceDetailsApi.saveInvoiceDetailsData(url, invoiceDetailsData)
      .then(response => {
        browserHistory.push(`/invoiceDetails/${response.data.invoice.id}`);
      })
      .catch(error => {
        console.error(error);
        dispatch(createNewInvoiceError(extractErrorMessage(error)));
      });
  };

export const generateInvoicePdf = () =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().InvoiceDetails;
      const { metasourceStrings } = getState().Preload;
      const url = getMetasourceLink(
        metasource,
        metasourceStrings,
        'INVOICE_DETAIL_METASOURCE_GET_INVOICE_PDF'
      );

      return InvoiceDetailsApi.getInvoicePdf(url.href)
        .then(response => {
          dispatch(
            generateInvoicePdfSuccess(response.data, response.headers['content-disposition'])
          );
        })
        .catch(error => {
          console.error(error);
          dispatch(generateInvoicePdfError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
    }
  };
