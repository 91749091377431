import React from 'react';
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import HomySelect from '../../components/HomyComponents/HomySelect';
import { DATE_FORMAT } from '../../shared/appConfig';
import moment from 'moment';
import CheckboxButton from '../../components/CheckboxButton/CheckboxButton';
import SlideDown from 'react-slidedown';

const { Item: FormItem } = Form;

const InvoicesFilter = ({ ...props }) => (
  <Form onSubmit={props.handleFilter} layout='vertical'>
    <Row gutter={8} type='flex' justify='start' align='bottom'>
      <Col xxl={2} xl={2} lg={4} md={4}>
        <FormItem label='Tipo de factura'>
          <HomySelect
            name='invoiceType'
            placeholder='Ordinaria o Proforma'
            value={props.filter.invoiceType}
            options={props.options.invoiceType}
            onChange={value => {
              props.handleSelectChange('invoiceType', value);
            }}
            allowClear
            showArrow
            showSearch
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={2} lg={4} md={4}>
        <FormItem label='NIF'>
          <Input
            id='filterIdentificationCode'
            name='identificationCode'
            placeholder='00000000A'
            value={props.filter.identificationCode}
            onChange={props.handleFilterChange}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={4} lg={4} md={4}>
        <FormItem label='Estado'>
          <HomySelect
            style={{ width: '100%' }}
            name='invoiceState'
            placeholder='Pagada, Expedida...'
            value={props.filter.invoiceState}
            options={props.options.invoiceState}
            onChange={value => {
              props.handleSelectChange('invoiceState', value);
            }}
            allowClear
            showArrow
            showSearch
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={2} lg={4} md={4}>
        <FormItem label='Nº de factura'>
          <Input
            id='filterInvoiceNumber'
            name='invoiceNumber'
            placeholder='181011'
            value={props.filter.invoiceNumber}
            onChange={props.handleFilterChange}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={3} lg={4} md={4}>
        <FormItem label='Creación desde'>
          <DatePicker
            placeholder='dd/mm/yyyy'
            style={{ width: '100%' }}
            value={props.filter.dateFrom ? moment(props.filter.dateFrom) : null}
            format={DATE_FORMAT}
            onChange={props.handleDataChange.bind(this, 'dateFrom')}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={3} lg={4} md={4}>
        <FormItem label='Creación hasta'>
          <DatePicker
            style={{ width: '100%' }}
            value={props.filter.dateTo ? moment(props.filter.dateTo) : null}
            placeholder='dd/mm/yyyy'
            format={DATE_FORMAT}
            onChange={props.handleDataChange.bind(this, 'dateTo')}
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={2} lg={4} md={4}>
        <FormItem label='Código oportunidad'>
          <Input
            id='filterDealReference'
            name='dealReference'
            placeholder='HHHH-SSSS'
            value={props.filter.dealReference}
            onChange={props.handleFilterChange}
          />
        </FormItem>
      </Col>

      <Col xxl={2} xl={3} lg={4} md={4}>
        <FormItem>
          <Button
            className='filter-buttons more-filters'
            icon={props.filter.showMoreFilters ? 'up' : 'down'}
            onClick={props.handleFilterCollapse}
          >
            Más filtros
          </Button>
        </FormItem>
      </Col>
      <Col xxl={2} xl={3} lg={4} md={4}>
        <FormItem>
          <Button
            htmlType='submit'
            icon='search'
            className='btn-primary filter-buttons'
            loading={props.loading}
          >
            Filtrar
          </Button>
        </FormItem>
      </Col>
    </Row>
    <SlideDown>
      {props.filter.showMoreFilters && (
        <Row gutter={8} type='flex' justify='start' align='bottom' className='slide-down-row'>
          <Col xxl={4} xl={4} lg={4} md={4}>
            <FormItem label='Tipo de destinatario'>
              <HomySelect
                name='receiverType'
                placeholder='Proveedor o Cliente'
                value={props.filter.receiverType}
                options={props.options.receiverType}
                onChange={value => {
                  props.handleSelectChange('receiverType', value);
                }}
                allowClear
                showArrow
                showSearch
              />
              {/* <Switch
              className='switch-request'
              name='firstRequest'
              size='small'
              onChange={props.handleFirstRequestChange}
              checked={props.filter.firstRequest || false}
            /> */}
            </FormItem>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4}>
            <FormItem label='Razón social'>
              <Input
                id='filterLastOTradeName'
                name='lastOTradeName'
                placeholder='Vane S.A.'
                value={props.filter.lastOTradeName}
                onChange={props.handleFilterChange}
              />
              {/* <Switch
              className='switch-request'
              name='firstRequest'
              size='small'
              onChange={props.handleFirstRequestChange}
              checked={props.filter.firstRequest || false}
            /> */}
            </FormItem>
          </Col>
          <Col xxl={2} xl={4} lg={4} md={4}>
            <FormItem label='Tipo de Oportunidad'>
              <HomySelect
                name='rentalRequestType'
                placeholder='A, B, C o D'
                value={props.filter.rentalRequestType}
                options={props.options.rentalRequestType}
                onChange={value => {
                  props.handleSelectChange('rentalRequestType', value);
                }}
                allowClear
                showArrow
                showSearch
              />
            </FormItem>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4}>
            <FormItem label='Gestor asignado'>
              <Input
                id='filterHsagent'
                name='hsagent.username'
                placeholder='alberto.pastor@homyspace.com'
                value={props.filter['hsagent.username']}
                onChange={props.handleFilterChange}
              />
              {/* <Switch
              className='switch-request'
              name='recurrentRequest'
              size='small'
              onChange={props.handleFirstRequestChange}
              checked={props.filter.recurrentRequest || false}
            /> */}
            </FormItem>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4}>
            <FormItem label='Fuente / Medio'>
              <HomySelect
                name='marketingSourceMedium'
                placeholder='LinkedIn, Adwords...'
                value={props.filter.marketingSourceMedium}
                options={props.options.marketingSourceMedium || []}
                onChange={value => {
                  props.handleSelectChange('marketingSourceMedium', value);
                }}
                allowClear
                showArrow
                showSearch
              />
            </FormItem>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4}>
            <FormItem>
              <CheckboxButton
                borderStyle='primaryCheckboxActive'
                id='filterRecurrent'
                name='recurrent'
                handleChange={props.handleRecurrentChange}
                content='Recurrente'
                value={props.filter.recurrent ? props.filter.recurrent : false}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </SlideDown>
    <style jsx>
      {`
        /* Select de "Cliente" */
        .Select {
          height: 61px !important;
        }

        /* Select de "Cliente" */
        div.Select-control {
          height: 30px !important;
          border: 1px solid #d9d9d9 !important;
          border-radius: 4px !important;
          -webkit-transition: all 0.3s !important;
          transition: all 0.3s !important;
        }

        div.Select-control:hover,
        div.Select-control:active,
        div.Select-control:focus {
          border-color: #f8dda6 !important;
        }

        /* Select de "Cliente" */
        .Select-input {
          height: 30px !important;
        }

        /* Select de "Cliente" */
        label[for='companyId'] {
          display: none !important;
        }

        .switch-request {
          margin-left: 10px !important;
        }

        /* Select de "Tipo" */
        .ant-select-selection:hover {
          border-color: #f8dda6 !important;
        }

        /* Select de "Tipo" */
        .ant-select-open,
        .ant-select-open > div {
          border-color: #f8dda6 !important;
          box-shadow: none !important;
        }

        .ant-select-selection,
        .ant-select-selection:focus {
          border: 1px solid #d9d9d9 !important;
        }

        .filter-buttons {
          width: 100% !important;
        }

        .slide-down-row {
          overflow-x: hidden;
          overflow-y: hidden;
        }
      `}
    </style>
  </Form>
);
export default InvoicesFilter;
