import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Row } from 'antd';
import { dealDetailsUnbonded } from '../../modules/DealDetails/DealDetailsActions';

/**
 * bondedDeal: the deal for wich is searching for
 * fromPropertyDetails: if the alert is showing in FormPropertyDetails view
 *
 */

class BondedDealInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bondedDeal: props.bondedDeal,
      propertyProposals: {},
      message: 'Estás buscando inmueble para la oportunidad ',
      cleaningBondedDeal: false,
    };
    this.findClientInProposals = this.findClientInProposals.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      // !isEmpty(nextProps.bondedDeal) &&
      JSON.stringify(this.state.bondedDeal) !== JSON.stringify(nextProps.bondedDeal)
    ) {
      this.setState({
        bondedDeal: nextProps.bondedDeal,
      });
      // debugger;
      if (this.state.cleaningBondedDeal) {
        if (this.props.onClose) {
          this.props.onClose();
        }

        this.setState({ cleaningBondedDeal: false });
      }
    }

    if (
      JSON.stringify(this.state.propertyProposals) !==
        JSON.stringify(nextProps.propertyProposals) &&
      this.state.bondedDeal
    ) {
      this.setState(
        {
          propertyProposals: nextProps.propertyProposals,
        },
        this.findClientInProposals(nextProps.propertyProposals)
      );
    }
  }

  findClientInProposals(propertyProposals) {
    if (propertyProposals.totalCount && propertyProposals.totalCount > 0) {
      for (const proposal of propertyProposals.tableData.proposalList) {
        if (
          proposal.proposal.dealCompanyId === this.state.bondedDeal.companyId &&
          proposal.proposal.shippingDate
        ) {
          this.setState({
            message:
              'Este inmueble ya se ha utilizado en otra propuesta para el mismo cliente de esta oportunidad: ',
          });
        }
      }
    }
  }

  onClose() {
    // if (this.props.onClose) {
    //   this.props.onClose();
    // }
    this.setState({ cleaningBondedDeal: true }, () => {
      this.props.actions.unbondDeal();
    });
  }

  render() {
    return (
      <>
        {this.state.bondedDeal && (
          <>
            <Row>
              <Col span={24}>
                <Alert
                  message={this.state.message}
                  description={this.state.bondedDeal.identificationCode}
                  type='info'
                  showIcon
                  closeText='Dejar de buscar para esta oportunidad'
                  onClose={() => {
                    this.onClose();
                    if (this.props.onClose) {
                      this.props.onClose();
                    }
                  }}
                />
              </Col>
            </Row>
            <br />
          </>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      unbondDeal: (url, data) => dispatch(dealDetailsUnbonded(url, data)),
    },
  };
}

function mapStateToProps(state) {
  return {
    bondedDeal: state.DealDetails.bondedDeal,
    propertyProposals: state.PropertyDetails.proposalsData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BondedDealInfo);
