import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class HomyIBAN extends Input {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    id: 'accountNumber',
    label: 'Número de cuenta (IBAN)',
    name: 'accountNumber',
    placeholder: 'ES12 0000 0000 0000 0000 0000',
  };

  render() {
    return (
      <label htmlFor={this.props.id}>
        {this.props.label}
        <Input {...this.props} />
      </label>
    );
  }
}

export default HomyIBAN;
