import React, { useCallback } from 'react';
import { Col, InputNumber, Row } from 'antd';

const FormProviderInitialPrice = ({ data = {}, onChange = () => {}, hasFieldsBlocked = false }) => {
  const onPriceChange = useCallback((field, value) =>
    onChange(field, { amount: value, currency: 'EUR' })
  );

  return (
    <>
      <Row gutter={12} className='hs-row'>
        <Col span={8}>
          <label htmlFor='proposalDetailPrice'>Precio mensual</label>
          <InputNumber
            id='proposalDetailPrice'
            name='monthlyPrice'
            min={0}
            disabled={data.taxIncluded || hasFieldsBlocked}
            value={data.monthlyPrice ? data.monthlyPrice.amount : ''}
            onChange={onPriceChange.bind(this, 'monthlyPrice')}
          />
        </Col>
        <Col span={8}>
          <label htmlFor='proposalDetailTotalPrice'>Precio total</label>
          <InputNumber
            id='proposalDetailTotalPrice'
            name='totalPrice'
            min={0}
            disabled={data.taxIncluded || hasFieldsBlocked}
            value={data.totalPrice ? data.totalPrice.amount : ''}
            onChange={onPriceChange.bind(this, 'totalPrice')}
          />
        </Col>
        <Col span={8}>
          <label htmlFor='proposalDetailDeposit'>Fianza</label>
          <InputNumber
            id='proposalDetailDeposit'
            name='deposit'
            min={0}
            value={data.deposit ? data.deposit.amount : ''}
            onChange={onPriceChange.bind(this, 'deposit')}
            disabled={hasFieldsBlocked}
          />
        </Col>
      </Row>
      <Row gutter={12} className='hs-row'>
        <Col span={8}>
          <label htmlFor='proposalDetailPriceWithTax'>Precio mensual (imp. incluido)</label>
          <InputNumber
            id='proposalDetailPriceWithTax'
            name='monthlyPriceWithTax'
            min={0}
            onChange={onPriceChange.bind(this, 'monthlyPriceWithTax')}
            disabled={!data.taxIncluded}
            value={data.monthlyPriceWithTax ? data.monthlyPriceWithTax.amount : ''}
          />
        </Col>
        <Col span={8}>
          <label htmlFor='proposalDetailTotalPriceWithTax'>Precio total (imp. incluido)</label>
          <InputNumber
            id='proposalDetailTotalPriceWithTax'
            name='totalPriceWithTax'
            min={0}
            onChange={onPriceChange.bind(this, 'totalPriceWithTax')}
            disabled={!data.taxIncluded}
            value={data.totalPriceWithTax ? data.totalPriceWithTax.amount : ''}
          />
        </Col>
        <Col span={8}>
          <label htmlFor='proposalCleaningChargesWithTax'>Gastos limpieza (imp. incl)</label>
          <InputNumber
            style={{ width: '100%' }}
            id='proposalCleaningChargesWithTax'
            name='cleaningChargesWithTax'
            disabled
            value={data.cleaningChargesWithTax ? data.cleaningChargesWithTax.amount : ''}
          />
        </Col>
      </Row>
      <style jsx>
        {`
          .hs-row {
            margin-bottom: 15px;
          }

          .ant-input-number {
            width: 100% !important;
          }
        `}
      </style>
    </>
  );
};

export default FormProviderInitialPrice;
