import React, { Component } from 'react';
import get from 'lodash/get';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 disabled -> OPTINAL -> indicates if the state can change
 content -> content to be rendered inside the checkboxButton
 borderStyle -> borderStyle to be applied when checkButton is active
 handleChange -> function to be called when active state change
 value -> is the CheckboxButton active?
 name -> name of the component
 id -> id of the component
 noPadding -> (OPTIONAL) no padding content
 ******************************************************* */

class CheckboxButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  handleChange() {
    const { name, disabled, handleChange: onChange } = this.props;

    if (disabled) {
      return;
    }

    const { value: newValue } = this.state;

    this.setState(
      {
        value: !newValue,
      },
      () => {
        onChange({
          target: {
            type: 'checkbox',
            checked: !newValue,
            name,
          },
        });
      }
    );
  }

  render() {
    let borderStyle = '';

    if (this.state.value) {
      borderStyle = `active ${this.props.borderStyle}`;
    }

    if (this.props.noPadding) {
      borderStyle += ' noPadding';
    }

    return (
      <div
        data-testid={get(this.props, 'data-testid')}
        name={get(this.props, 'name')}
        id={this.props.id}
        className={`checkbox-button ${borderStyle} ${this.props.className} ${
          this.props.disabled ? 'disabled' : ''
        }`}
        onClick={this.handleChange}
        style={this.props.style}
      >
        {this.props.content}
        <style jsx>
          {`
            .active {
              opacity: 1 !important;
            }
          `}
        </style>
      </div>
    );
  }
}

export default CheckboxButton;
