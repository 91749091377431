import moment from 'moment';

export const adjustDay = date => {
  return moment(date)
    .utc()
    .startOf('day');
};

export const getDays = (checkIn, checkOut) => {
  checkIn = adjustDay(checkIn);
  checkOut = adjustDay(checkOut);

  return checkOut && checkIn ? checkOut.diff(checkIn, 'days') : '';
};

export const monthsBetween = (start, end) => adjustDay(end).diff(adjustDay(start), 'months');

export const isExactOneMonth = (start, end) =>
  adjustDay(end).diff(adjustDay(start), 'months', true) === 1;

export const checkDatesIntervalOverlap = (
  startIntervalOne,
  endIntervalOne,
  startIntervalTwo,
  endIntervalTwo
) =>
  (startIntervalOne > startIntervalTwo && startIntervalOne < endIntervalTwo) ||
  (endIntervalOne > startIntervalTwo && endIntervalOne < endIntervalTwo) ||
  (startIntervalTwo > startIntervalOne && endIntervalTwo < endIntervalOne) ||
  (startIntervalTwo > startIntervalOne && endIntervalTwo.getTime() === endIntervalOne.getTime()) ||
  (startIntervalTwo.getTime() === startIntervalOne.getTime() && endIntervalTwo < endIntervalOne) ||
  (startIntervalTwo.getTime() === startIntervalOne.getTime() &&
    endIntervalTwo.getTime() === endIntervalOne.getTime());

export const checkDateYear = date => {
  const dateYear = date.year();

  return (
    dateYear === moment().year() ||
    dateYear ===
      moment()
        .subtract(1, 'year')
        .year() ||
    dateYear ===
      moment()
        .subtract(2, 'year')
        .year() ||
    dateYear ===
      moment()
        .add(1, 'year')
        .year() ||
    dateYear ===
      moment()
        .add(2, 'year')
        .year()
  );
};

export const isValidDate = date => date && moment(date).isValid() && checkDateYear(moment(date));

export const areValidDates = (dateIn, dateOut) => {
  const checkIn = moment(dateIn);
  const checkOut = moment(dateOut);

  if (!checkIn || !checkOut || isNaN(checkIn) || isNaN(checkOut)) {
    return false;
  }

  return isValidDate(dateIn) && isValidDate(dateOut) && checkOut.isAfter(checkIn);
};

export const dateIsBetweenInterval = (dateToCompare, intervalStartDate, intervalEndDate) =>
  intervalStartDate <= dateToCompare && dateToCompare <= intervalEndDate;
