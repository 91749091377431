import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import InfoButtonDealDetails from '../../components/InfoButtonDealDetails';
import InfoButtonCompanyDetails from '../../components/InfoButtonCompanyDetails';
import InfoButtonDealContacts from '../../components/InfoButtonDealContacts';
import {
  showOrLoadCompanyDetailsView,
  resetState as resetCompanyState,
} from '../../modules/CompanyDetails/CompanyDetailsActions';
import { resetContactState } from '../../modules/ContactDetails/ContactDetailsActions';
import { resetState as resetProviderState } from '../../modules/ProviderDetails/ProviderDetailsActions';
import { sendCheckin, sendProposals } from '../../modules/Proposals/ProposalsActions';
import { isFlagActive } from 'hs-feature-flags';
import {
  dealDetailsBonded,
  followUp,
  generateAutomaticProposal,
  getProposalsRelevance,
  showOrLoadDealDetailsView,
  proposalsEmailSentSuccessfully,
} from '../../modules/DealDetails/DealDetailsActions';
import TimelinePanel from '../../components/TimelinePanel';
import InfoButtonProposalDetails from '../../components/InfoButtonProposalDetails';
import { setPropertyExternalFilterAndLoad } from '../../modules/Properties/PropertiesActions';
import Alert from '../../components/Alert';
import CheckboxButton from '../../components/CheckboxButton';
import {
  BUSINESS_EVENT_TYPES,
  getOptionsForSelect,
  getTextValueFromSelectOptions,
  openNotificationWithIcon,
  RENTAL_REQUEST_STAGES,
} from '../../shared/utils/appUtils';
import { DATETIME_FORMAT, ENDPOINT, ENDPOINT_V2 } from '../../shared/appConfig.js';
import get from 'lodash/get';
import split from 'lodash/split';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import size from 'lodash/size';
import { FollowUpButton } from './FollowUpButton';
import { getUsualContact } from '../../shared/utils/contactUtils';
import EventsApi from '../../modules/Events/EventsApi';
import { createNewBusinessEventSuccess } from '../../modules/Events/EventsActions';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Checkbox, Modal, Select } from 'antd';
import api from '../../shared/utils/api';
import { sanitizeMobilePrefix, isMobilePhoneNumber } from '../../shared/utils/phoneUtils';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';
import * as dealUtils from '../../shared/utils/dealUtils';
import { hsAgents } from '../../shared/utils/agentUtils';
import { getNewDossier } from '../../shared/apiUrls';

class DealDetails extends Component {
  constructor(props) {
    super(props);
    this.originalPicturesLength = {};
    this.state = {
      isLoading: true,
      alert: {
        show: false,
      },
      alertCheckIn: false,
      options: {
        proposalStage: getOptionsForSelect(this.props.optionLabels, 'ProposalStage'),
        proposalStatus: getOptionsForSelect(this.props.optionLabels, 'ProposalStatus'),
        rentalRequestStage: getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage'),
      },
      showFollowUpConfirmation: false,
      showCheckinConfirmation: false,
      submittingFollowUp: false,
      submittingGenerateAutomaticProposal: false,
      isGenerateAutomaticProposalDisabled: false,
      sendProposalsDossier: true,
      sendProposalsByWhatsapp: true,
      sendWhatsappProposalsFlag: false,
      proposalsRelevance: [],
      proposalsToSend: [],
      proposalsList: [],
      proposalsCheckin: [],
      openNewTaskModal: false,
      dossierGeneratorAgent: null,
    };

    this.startSearch = this.startSearch.bind(this);
    this.handleSendProposals = this.handleSendProposals.bind(this);
    this.renderCheckIn = this.renderCheckIn.bind(this);
    this.renderProposalAsCheckbox = this.renderProposalAsCheckbox.bind(this);
    this.renderProposalCheckInAsCheckbox = this.renderProposalCheckInAsCheckbox.bind(this);
    this.isFollowUpButtonDisabled = this.isFollowUpButtonDisabled.bind(this);
    this.isCheckinButtonDisabled = this.isCheckinButtonDisabled.bind(this);
    this.getStageIndex = this.getStageIndex.bind(this);
    this.getFollowUpConfirmationMessage = this.getFollowUpConfirmationMessage.bind(this);
    this.sendProposalsConfirmation = this.sendProposalsConfirmation.bind(this);
    this.checkinConfirmation = this.checkinConfirmation.bind(this);
    this.onConfirmFollowUp = this.onConfirmFollowUp.bind(this);
    this.handleFollowUpCall = this.handleFollowUpCall.bind(this);
    this.handleFollowUpWhatsapp = this.handleFollowUpWhatsapp.bind(this);
    this.handleSendFollowUp = this.handleSendFollowUp.bind(this);
    this.checkin = this.checkin.bind(this);
    this.getAutomaticProposal = this.getAutomaticProposal.bind(this);
    this.reorder = this.reorder.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.proposalsListed = this.proposalsListed.bind(this);
    this.sendProposals = this.sendProposals.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeCheckboxCheckIn = this.onChangeCheckboxCheckIn.bind(this);
    this.sendProposalsByWhatsapp = this.sendProposalsByWhatsapp.bind(this);
    this.clearBookingState = this.clearBookingState.bind(this);
    this.sendListing = this.sendListing.bind(this);
  }

  async componentDidMount() {
    this.props.actions.showOrLoadDealDetailsView(this.props.params.id);
    this.setState({
      sendProposalsByWhatsapp: true,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.isLoading && !isEmpty(nextProps.dealDetailsError)) {
      openNotificationWithIcon(
        'error',
        'Oops!',
        nextProps.dealDetailsError || 'Ha ocurrido un error al cargar la oportunidad'
      );
      return;
    }
    if (this.state.isLoading && !isEmpty(nextProps.dealDetails)) {
      const proposalsList = map(get(nextProps, 'dealDetails.proposals', []), 'proposal');
      for (let proposal of proposalsList) {
        this.originalPicturesLength[proposal.id] = size(get(proposal, 'property.pictures', []));
      }
      this.setState(
        {
          isLoading: false,
          proposalsList,
          proposalsCheckin: filter(map(get(nextProps, 'dealDetails.proposals', []), 'proposal'), [
            'proposalStage',
            'ACCEPTED',
          ]),
          dossierGeneratorAgent: get(
            nextProps,
            'dealDetails.dossierGeneratorAgent',
            this.state.dossierGeneratorAgent
          ),
        },
        () => {
          this.getProposalsRelevance();
          this.proposalsListed();
        }
      );
    }

    if (
      !isEmpty(nextProps.dealDetails) &&
      nextProps.dealDetails.companyId &&
      isEmpty(nextProps.companyDetails)
    ) {
      this.props.actions.showOrLoadCompanyDetailsView(nextProps.dealDetails.companyId);
      return;
    }

    if (this.state.submittingFollowUp) {
      if (nextProps.followUpSubmit.error) {
        openNotificationWithIcon('error', 'Error!', nextProps.followUpSubmit.errorDetail);
      } else {
        openNotificationWithIcon(
          'success',
          'Hecho!',
          'Se ha efectuado el seguimiento correctamente'
        );
      }

      this.setState({
        submittingFollowUp: false,
        showFollowUpConfirmation: false,
      });
    }

    if (
      this.state.submittingGenerateAutomaticProposal &&
      this.state.isGenerateAutomaticProposalDisabled
    ) {
      if (nextProps.automaticProposal.error) {
        openNotificationWithIcon(
          'error',
          '¡Ha ocurrido un error!',
          nextProps.automaticProposal.errorDetail
        );
      }

      this.setState({
        isGenerateAutomaticProposalDisabled: false,
      });
    }

    if (JSON.stringify(nextProps.dealDetails) !== JSON.stringify(this.props.dealDetails)) {
      this.setState(
        {
          proposalsList: map(get(nextProps, 'dealDetails.proposals', []), 'proposal'),
          proposalsCheckin: filter(map(get(nextProps, 'dealDetails.proposals', []), 'proposal'), [
            'proposalStage',
            'ACCEPTED',
          ]),
          dossierGeneratorAgent: get(
            nextProps,
            'dealDetails.dossierGeneratorAgent',
            this.state.dossierGeneratorAgent
          ),
        },
        () => {
          this.getProposalsRelevance();
          this.proposalsListed();
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.actions.resetContactState();
    this.props.actions.resetCompanyState();
    this.props.actions.resetProviderState();
  }

  getProposalsRelevance() {
    const { id, proposals } = this.props.dealDetails;

    if (proposals && proposals.length > 0) {
      this.props.actions.getProposalsRelevance(id);
    }
  }

  renderCheckIn() {
    const proposalsCheckin = get(this.state, 'proposalsCheckin', []);

    const fields = proposalsCheckin.map((proposal, index) =>
      this.renderProposalCheckInAsCheckbox(proposal, index + 1)
    );

    return fields;
  }

  async sendProposals() {
    const originalProposalList = get(this.state, 'proposalsListOrigin', []);
    const sortedProposalList = get(this.state, 'proposalsList', []);

    const proposals = sortedProposalList.map((proposal, index) => {
      const prop = find(originalProposalList, ['id', proposal.id]);
      const orderChanged = isNil(get(prop, 'order')) || get(prop, 'order') !== index;

      return {
        proposalId: proposal.id,
        order: index,
        stage: proposal.proposalStage,
        stageChanged: get(proposal, 'stageChanged', false),
        orderChanged,
        fitsNeedsOfClient: get(proposal, 'fitsNeedsOfClient', false),
        externalSearch: get(proposal, 'externalSearch', false),
      };
    });

    try {
      const url = `${ENDPOINT}/proposal/dossier/${this.props.dealDetails.id}`;
      const body = {
        proposals,
        sendDossier:
          this.checkIsProposalSent() || this.isProposalChecked()
            ? get(this.state, 'sendProposalsDossier', false)
            : false,
        sendDossierByWhatsapp: this.isProposalChecked()
          ? get(this.state, 'sendProposalsByWhatsapp', false)
          : false,
        dossierGeneratorAgent: this.state.dossierGeneratorAgent,
      };

      await api.post(url, body);

      if (
        (get(this.state, 'sendProposalsDossier', false) && this.isProposalChecked()) ||
        this.checkIsProposalSent()
      ) {
        openNotificationWithIcon(
          'success',
          '¡Todo ha ido a pedir de Milhouse!',
          'Te he ordenado las propuestas y... ¡el correo va de camino!'
        );
        this.props.actions.proposalsEmailSentSuccessfully();
      } else {
        openNotificationWithIcon(
          'success',
          'Ey, sexy!',
          'Que sepas... ¡Que he ordenado las Propuestas!'
        );
      }

      this.props.actions.showOrLoadDealDetailsView(this.props.dealDetails.id);
    } catch (e) {
      console.error(e);
      openNotificationWithIcon('error', '¡Ha ocurrido un error!', e.message);
    } finally {
      this.setState({
        alert: {
          show: false,
        },
      });
    }
  }

  handleCheckin() {
    const aceptedProposals = this.props.dealDetails.proposals.filter(
      proposal => proposal.proposal.proposalStage === 'ACCEPTED'
    );
    const fields = aceptedProposals.map((proposal, index) =>
      this.renderProposalAsCheckbox(proposal, index + 1)
    );

    fields.unshift(<h6>Selecciona una o varias propuestas para enviar datos para checkin:</h6>);
    this.setState({
      alert: {
        show: false,
      },
    });
  }

  sendProposalsConfirmation() {
    const orderedProposalList = get(this.state, 'proposalsList', []);
    let hasError = false;

    if (isNil(get(this.props, 'dealDetails.hsagent'))) {
      openNotificationWithIcon(
        'error',
        'Oops!',
        `Asigname un gestor a la Oportunidad antes de hacer la acción, ¡please!`
      );

      return false;
    }

    orderedProposalList.forEach(proposal => {
      if (isEmpty(proposal.property.featuredPicturePath)) {
        openNotificationWithIcon(
          'error',
          'Oops!',
          `La propuesta "${get(proposal, 'title', '[SIN TÍTULO]')}" no tiene fotografía principal!`
        );
        hasError = true;
      }

      if (
        isEmpty(proposal.property.pictures) ||
        (this.originalPicturesLength[proposal.id] > get(proposal, 'property.pictures', []).length &&
          get(proposal, 'property.pictures', []).length < 20)
      ) {
        openNotificationWithIcon(
          'error',
          'Oops!',
          `La propuesta "${get(
            proposal,
            'title',
            '[SIN TÍTULO]'
          )}" debe tener al menos 20 imágenes seleccionadas. Si actualmente, el total de imágenes del inmueble dispone de menos, manten las fotos que tuviera seleccionadas o eliminalas desde la ficha del inmueble`
        );
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const originalProposalList = get(this.state, 'proposalsListOrigin', []);
    const sendProposals = get(this.state, 'sendProposalsDossier', false);
    const sendProposalsByWhatsapp = get(this.state, 'sendProposalsByWhatsapp', false);

    if (
      sendProposals ||
      sendProposalsByWhatsapp ||
      JSON.stringify(originalProposalList) !== JSON.stringify(orderedProposalList)
    ) {
      this.sendProposals();
    } else {
      this.onAlertCancelClick();
    }

    if (get(this.state, 'sendProposalsByWhatsapp', false)) {
      this.sendProposalsByWhatsapp(!sendProposals);
    }
  }

  checkinConfirmation() {
    const proposalsCheckin = filter(get(this.state, 'proposalsCheckin', []), 'stageChanged');

    if (isEmpty(proposalsCheckin)) {
      openNotificationWithIcon(
        'error',
        'Something happened!',
        'Seleccionas ALGUNA Propuesta para poder enviarla'
      );

      return false;
    }

    for (let i = 0; i < proposalsCheckin.length; i++) {
      const p = proposalsCheckin[i];

      if (isEmpty(p.property.featuredPicturePath)) {
        openNotificationWithIcon(
          'error',
          'Oops!',
          `La propuesta "${p.title}" no tiene fotografía principal!`
        );

        this.setState({
          alertCheckIn: false,
        });

        return false;
      }
    }

    this.checkin();
  }

  checkin() {
    const proposalsCheckin = filter(get(this.state, 'proposalsCheckin', []), 'stageChanged');
    const proposalIds = proposalsCheckin.map(proposal => proposal.id);

    this.setState(
      {
        alertCheckIn: false,
        proposalsCheckin: filter(map(get(this.props, 'dealDetails.proposals', []), 'proposal'), [
          'proposalStage',
          'ACCEPTED',
        ]),
      },
      async () => {
        this.props.actions.sendCheckin(proposalIds, this.props.dealDetails.id);

        const isDraftActive = await isFlagActive(
          'PROVIDER_INVOICE_DRAFT',
          this.props.currentUsername
        );

        if (isDraftActive) {
          proposalIds.forEach(id =>
            api
              .request(`${ENDPOINT_V2}/proposals/${id}/contracts`, 'POST')
              .catch(e =>
                openNotificationWithIcon(
                  'error',
                  'Oops!',
                  `Error a la hora de generar el borrador de factura ${e} con id ${id}`
                )
              )
          );
        }
      }
    );
  }

  onAlertCancelClick() {
    this.setState({
      alertCheckIn: false,
      alert: {
        show: false,
      },
      proposalsToSend: [],
    });
  }

  startSearch() {
    const { dealDetails: deal } = this.props;
    const { companyDetails } = this.props;

    const identificationCode = get(deal, 'identificationCode');
    const dealIdentificacionCode = !isEmpty(split(identificationCode, ' - '))
      ? split(identificationCode, ' - ')[0]
      : identificationCode;

    const filter = {
      guest: get(deal, 'numberOfPeople'),
      rooms: get(deal, 'numberOfRooms'),
      maxPrice: get(deal, 'budget'),
      startDate: get(deal, 'rentalStartDate'),
      endDate: get(deal, 'rentalEndDate'),
      dealIdentificacionCode,
      address: get(deal, 'address'),
      // invoiceRequired: get(deal, 'invoiceRequired'),
      aCRequired: get(deal, 'aCRequired'),
      cleaningRequired: get(deal, 'cleaningRequired'),
      wifiRequired: get(deal, 'wifiRequired'),
      garageRequired: get(deal, 'garageRequired'),
      petsAllowedRequired: get(deal, 'petsAllowedRequired'),
      liftRequired: get(deal, 'liftRequired'),
      smartSearch: !isEmpty(get(companyDetails, 'baseContact.identificationCode')),
    };

    this.props.actions.setPropertyExternalFilterAndLoad(filter);
    this.props.actions.setBondedDeal(this.props.dealDetails);
  }

  getStageIndex() {
    const stages = get(this.state, 'options.rentalRequestStage');
    const currentState = get(this.props, 'dealDetails.rentalRequestStage');
    let stageIndex = 0;

    if (stages && currentState) {
      stages.forEach((stage, index) => {
        if (stage.value === currentState) {
          stageIndex = index;
        }
      });
    }

    return stageIndex;
  }

  isFollowUpButtonDisabled() {
    return (
      this.getStageIndex() < 2 ||
      this.props.dealDetails.followUpStage === 'SECOND_EMAIL_SENT' ||
      this.props.dealDetails.rentalRequestStage !== RENTAL_REQUEST_STAGES.S020_SEND_SEARCH_RESULTS
    );
  }

  isCheckinButtonDisabled() {
    return this.getStageIndex() < 4;
  }

  getFollowUpConfirmationMessage() {
    if (
      !this.props.dealDetails.followUpStage ||
      this.props.dealDetails.followUpStage === 'NOT_STARTED'
    ) {
      return 'Se enviará el primer email de seguimiento. ¿Estás segura?';
    }

    if (this.props.dealDetails.followUpStage === 'FIRST_EMAIL_SENT') {
      return 'Se enviará el segundo email de seguimiento. ¿Estás segura?';
    }

    return '';
  }

  onConfirmFollowUp() {
    let nextStage;

    if (
      !this.props.dealDetails.followUpStage ||
      this.props.dealDetails.followUpStage === 'NOT_STARTED'
    ) {
      nextStage = 'FIRST_EMAIL_SENT';
    } else if (this.props.dealDetails.followUpStage === 'FIRST_EMAIL_SENT') {
      nextStage = 'SECOND_EMAIL_SENT';
    } else {
      return;
    }

    this.setState({ submittingFollowUp: true }, () => {
      this.props.actions.followUp(this.props.dealDetails.id, nextStage);
    });
  }

  async handleFollowUpCall() {
    const businessEvent = {};
    const contactsOfCompany = get(this.props, 'dealDetails.contactsOfCompany');
    const usualContact = getUsualContact(contactsOfCompany);

    let phoneNumber = get(usualContact, 'phoneNumber');

    if (isEmpty(phoneNumber)) {
      phoneNumber = get(usualContact, 'mobilePhoneNumber');
    }

    if (!usualContact) {
      openNotificationWithIcon(
        'error',
        'No tenemos contacto habitual!!!',
        'Para realizar el seguimiento tendrás que añadir un contacto habitual a la oportunidad'
      );

      return;
    }

    if (isEmpty(phoneNumber)) {
      openNotificationWithIcon(
        'error',
        'No tenemos teléfono para el contacto habitual!!!',
        'Para realizar el seguimiento tendrás que indicar el teléfono para el contacto habitual de la oportunidad'
      );

      return;
    }

    document.location.href = `tel:${phoneNumber}`;

    let usualContactName;

    if (usualContact) {
      usualContactName =
        (usualContact.name ? usualContact.name + ' ' : '') +
        (usualContact.lastOTradeName ? usualContact.lastOTradeName : '');
    }

    businessEvent.date = new Date();
    businessEvent.from = this.props.currentUsername;
    businessEvent.text = `<b>${
      this.props.currentUsername
    }</b> ha realizado un seguimiento por teléfono${
      usualContactName ? ' a ' + usualContactName : ''
    }`;

    const params = {
      dealId: get(this.props, 'dealDetails.id'),
    };
    const result = await EventsApi.createNewBusinessEvent(
      businessEvent,
      BUSINESS_EVENT_TYPES.FOLLOW_UP,
      params
    );

    if (result) {
      try {
        this.props.actions.createNewBusinessEventSuccess([BUSINESS_EVENT_TYPES.FOLLOW_UP]);
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'No se ha podido registrar el seguimiento correctamente',
          'Por favor, registra la llamada manualmente o consulta con el LAB.'
        );
      }
    }
  }

  async handleFollowUpWhatsapp() {
    const contactsOfCompany = get(this.props, 'dealDetails.contactsOfCompany');
    const usualContact = getUsualContact(contactsOfCompany);

    if (!usualContact) {
      return openNotificationWithIcon(
        'error',
        'No tenemos contacto habitual!!!',
        'Para realizar el seguimiento tendrás que añadir un contacto habitual a la oportunidad'
      );
    }

    let phoneNumber = get(usualContact, 'mobilePhoneNumber');
    phoneNumber = isEmpty(phoneNumber) ? get(usualContact, 'phoneNumber') : phoneNumber;

    if (isEmpty(phoneNumber)) {
      return openNotificationWithIcon(
        'error',
        'No tenemos teléfono móvil para el contacto habitual!!!',
        'Para realizar el seguimiento por whatsapp, tendrás que indicar el teléfono móvil para el contacto habitual de la oportunidad'
      );
    }

    const shouldSendListing = await dealUtils.shouldSendListing(this.props.dealDetails);
    const proposalInInvalidStages = find(
      get(this.props, 'dealDetails.proposals'),
      ({ proposal }) =>
        proposal.proposalStage === 'SHIPPED' ||
        proposal.proposalStage === 'ACCEPTED' ||
        proposal.proposalStage === 'REJECTED'
    );

    // const dossierUrl = !get(this.props, 'dealDetails.firstRequest', false)
    //   ? getNewDossier(this.props.dealDetails.id)
    //   : this.props.dealDetails.urlPublicDossier +
    //     `?agent=${get(this.props, 'dealDetails.hsagent.username')}`;
    const dossierUrl = getNewDossier(this.props.dealDetails.id);
    const message = window.encodeURIComponent(
      shouldSendListing && !proposalInInvalidStages
        ? '¡Hola! Te escribo desde *Homyspace*.\r\n\r\n' +
            `Acabo de intentar contactar contigo. ¿Has podido ver los inmuebles que tenemos para tu solicitud de desplazamiento ${this.props.dealDetails.identificationCode}?\r\n\r\n` +
            `Las puedes revisar *aquí*: ${dealUtils.getListingFollowUpUrl(
              this.props.dealDetails
            )}\r\n\r\n` +
            'Cualquier duda estoy a tu disposición.'
        : '¡Hola! Te escribo desde *Homyspace*.\r\n\r\n' +
            `Acabo de intentar contactar contigo. Te recuerdo que ya tienes disponibles las *propuestas de alojamiento* para tu solicitud de desplazamiento ${this.props.dealDetails.identificationCode}.\r\n\r\n` +
            `Las puedes revisar *aquí*: ${dossierUrl}\r\n\r\n` +
            'Cualquier duda estoy a tu disposición.'
    );

    window.open(
      `https://web.whatsapp.com/send?phone=${sanitizeMobilePrefix(
        phoneNumber.replace(/\s/g, '')
      )}&text=${message}`
    );

    let usualContactName;

    if (usualContact) {
      usualContactName =
        (usualContact.name ? usualContact.name + ' ' : '') +
        (usualContact.lastOTradeName ? usualContact.lastOTradeName : '');
    }

    const businessEvent = {
      date: new Date(),
      from: this.props.currentUsername,
      text: `<b>${prettyPrintAgentFromEmail(
        this.props.currentUsername
      )}</b> ha realizado un seguimiento por Whatsapp${
        usualContactName ? ' a ' + usualContactName : ''
      }`,
    };

    const params = {
      dealId: get(this.props, 'dealDetails.id'),
    };
    const result = await EventsApi.createNewBusinessEvent(
      businessEvent,
      [BUSINESS_EVENT_TYPES.FOLLOW_UP, BUSINESS_EVENT_TYPES.WHATSAPP],
      params
    );

    if (result) {
      try {
        this.props.actions.createNewBusinessEventSuccess([BUSINESS_EVENT_TYPES.FOLLOW_UP]);
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'No se ha podido registrar el seguimiento correctamente',
          'Por favor, registra la llamada manualmente o consulta con el LAB.'
        );
      }
    }
  }

  handleSendFollowUp() {
    this.setState({ showFollowUpConfirmation: true });
  }

  getAutomaticProposal() {
    this.setState(
      {
        submittingGenerateAutomaticProposal: true,
        isGenerateAutomaticProposalDisabled: true,
      },
      () => {
        this.props.actions.generateAutomaticProposal(this.props.dealDetails.id);
      }
    );
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.proposalsList,
      result.source.index,
      result.destination.index
    );

    this.setState({
      proposalsList: items,
    });
  }

  renderProposalAsCheckbox(proposal, index) {
    let proposalInfoButton;

    if (this.props.dealDetails.proposals[0]._links) {
      proposalInfoButton = map(get(this.props, 'dealDetails.proposals', []), 'proposal');
    } else {
      proposalInfoButton = get(this.props, 'dealDetails.proposals', []);
    }

    const indexInfoButton = proposalInfoButton.map((prop, i) => {
      if (proposal.id === prop.id) {
        return i + 1;
      }
    });

    const content = (
      <>
        <p style={{ margin: 0 }}>
          Propuesta {indexInfoButton} (
          {getTextValueFromSelectOptions(
            proposal.proposalStatus,
            this.state.options.proposalStatus
          )}
          ) - {get(proposal, 'price.amount', 0)} ({get(proposal, 'price.currency', 'EUR')})
        </p>
        <p style={{ margin: 0 }}>{get(proposal, 'title', 'Sin título')}</p>
        <p style={{ margin: 0 }}>
          {get(proposal, 'address.formattedAddress', 'Sin dirección especificada')}
        </p>
      </>
    );
    const isStageChanged = get(proposal, 'stageChanged', false);
    const isPreparing = get(proposal, 'proposalStage', '') === 'PREPARING' && !isStageChanged;

    return (
      <div
        style={{
          marginBottom: '15px',
          marginRight: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className='row col-sm-12'
      >
        <div className='col-sm-1'>
          <label>{index}ª</label>
        </div>
        <div className='col-sm-7'>
          <CheckboxButton
            content={content}
            borderStyle='dealCheckboxActive'
            name={proposal.id}
            disabled={!isPreparing && !isStageChanged}
            value={!isPreparing}
            handleChange={this.onChangeCheckbox}
            style={{ height: '100%', display: 'inherit' }}
          />
        </div>
        <div className='col-sm-2'>
          <label>
            {getTextValueFromSelectOptions(
              proposal.proposalStage,
              this.state.options.proposalStage
            )}
          </label>
        </div>
        <div className='col-sm-1'>
          <label>
            <Checkbox
              checked={proposal.fitsNeedsOfClient || false}
              onChange={e => {
                let newProposalList = [...this.state.proposalsList];
                newProposalList[index - 1] = {
                  ...newProposalList[index - 1],
                  fitsNeedsOfClient: e.target.checked,
                };

                this.setState({
                  proposalsList: newProposalList,
                });
              }}
            />
          </label>
        </div>
        <div className='col-sm-1'>
          <label>
            <Checkbox
              checked={proposal.externalSearch || false}
              onChange={e => {
                let newProposalList = [...this.state.proposalsList];
                newProposalList[index - 1] = {
                  ...newProposalList[index - 1],
                  externalSearch: e.target.checked,
                };

                this.setState({
                  proposalsList: newProposalList,
                });
              }}
            />
          </label>
        </div>
      </div>
    );
  }

  renderProposalCheckInAsCheckbox(proposal) {
    const proposalInfoButton = get(this.state, 'proposalsCheckin', []);
    const indexInfoButton = proposalInfoButton.map((prop, i) => {
      if (proposal.id === prop.id) {
        return i + 1;
      }
    });

    const content = (
      <>
        <p style={{ margin: 0 }}>
          Propuesta {indexInfoButton} (
          {getTextValueFromSelectOptions(
            proposal.proposalStatus,
            this.state.options.proposalStatus
          )}
          ) - {get(proposal, 'price.amount', 0)} ({get(proposal, 'price.currency', 'EUR')})
        </p>
        <p style={{ margin: 0 }}>{get(proposal, 'title', 'Sin título')}</p>
        <p style={{ margin: 0 }}>
          {get(proposal, 'address.formattedAddress', 'Sin dirección especificada')}
        </p>
      </>
    );
    const isStageChanged = get(proposal, 'stageChanged', false);

    return (
      <div
        style={{
          marginBottom: '15px',
          marginLeft: '30px',
          marginRight: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className='row col-sm-12'
      >
        <div className='col-sm-12'>
          <CheckboxButton
            content={content}
            borderStyle='dealCheckboxActive'
            name={proposal.id}
            value={isStageChanged}
            handleChange={this.onChangeCheckboxCheckIn}
            style={{ height: '100%', display: 'inherit' }}
          />
        </div>
      </div>
    );
  }

  onChangeCheckboxCheckIn(e) {
    const proposals = map(get(this.state, 'proposalsCheckin', []));
    const id = get(e, 'target.name', '');
    const checked = get(e, 'target.checked', false);

    const proposalsCheckin = proposals.map(proposal => {
      if (proposal.id === id) {
        return {
          ...proposal,
          stageChanged: checked,
        };
      }

      return {
        ...proposal,
      };
    });

    this.setState(() => ({
      proposalsCheckin,
    }));
  }

  onChangeCheckbox(e) {
    const proposalsList = get(this.state, 'proposalsList', []);
    const id = get(e, 'target.name', '');
    const checked = get(e, 'target.checked', false);

    const proposals = proposalsList.map(proposal => {
      if (proposal.id === id) {
        return {
          ...proposal,
          stageChanged: checked,
        };
      }

      return {
        ...proposal,
      };
    });

    this.setState({
      proposalsList: proposals,
    });
  }

  isProposalChecked() {
    const proposalsList = get(this.state, 'proposalsList', []);

    let flag = false;
    proposalsList.forEach(proposal => {
      if (proposal.stageChanged) {
        flag = true;
      }
    });
    return flag;
  }

  handleSendProposals() {
    if (!this.props.dealDetails.customerRate) {
      openNotificationWithIcon(
        'error',
        'Que mala pata :(',
        'La Tarifa del Cliente no está informada. ¡Es necesaria para Enviar Propuestas!'
      );

      return;
    }

    this.sendProposalsConfirmation();
  }

  checkIsProposalSent() {
    const proposalsList = get(this.state, 'proposalsList', []);

    return find(
      proposalsList,
      p => p.proposalStage === 'SHIPPED' || p.proposalStage === 'ACCEPTED'
    );
  }

  proposalsListed = () => {
    const proposals = get(this.state, 'proposalsList', []);

    const proposalsWithoutOrder = filter(proposals, proposal => isNil(get(proposal, 'order')));
    const proposalsSortedByCreatedDate = orderBy(proposalsWithoutOrder, ['creationDate'], ['desc']);
    const proposalsWithOrder = proposals.filter(proposal => {
      if (!isNil(get(proposal, 'order'))) {
        return proposal;
      }
    });
    const proposalsSortedByOrder = orderBy(proposalsWithOrder, ['order'], ['asc']);

    const proposalsSorted = [...proposalsSortedByCreatedDate, ...proposalsSortedByOrder];

    this.setState({
      proposalsListOrigin: proposalsWithOrder,
      proposalsList: proposalsSorted,
    });
  };

  sendProposalsByWhatsapp(openNewTaskModal) {
    const agentName = prettyPrintAgentFromEmail(get(this.props, 'dealDetails.hsagent.username'));
    const dealCity = get(
      this.props,
      'dealDetails.address.locality',
      get(this.props, 'dealDetails.address.postalTown')
    );
    // const dossierURL = !get(this.props, 'dealDetails.firstRequest', false)
    //   ? getNewDossier(this.props.dealDetails.id)
    //   : this.props.dealDetails.urlPublicDossier +
    //     '?medium=whatsapp' +
    //     (!isEmpty(get(this.props, 'dealDetails.hsagent.username'))
    //       ? `&agent=${get(this.props, 'dealDetails.hsagent.username')}`
    //       : '');
    const dossierURL = getNewDossier(this.props.dealDetails.id);
    const dealReference = get(this.props, 'dealDetails.identificationCode', '');
    const message = `¡Hola! Soy ${agentName}, de homyspace. He seleccionado unas propuestas disponibles para tu alojamiento en ${dealCity} que seguro te van a gustar.

Puedes verlas aquí: ${dossierURL} 
    
*¿Qué te parecen?* ID ${dealReference}`;

    const dealUsualContact = getUsualContact(this.props.dealDetails.contactsOfCompany);
    let contactPhone = get(dealUsualContact, 'mobilePhoneNumber');
    contactPhone = isEmpty(contactPhone) ? get(dealUsualContact, 'phoneNumber') : contactPhone;

    if (isMobilePhoneNumber(contactPhone)) {
      window.open(
        `https://web.whatsapp.com/send?phone=${sanitizeMobilePrefix(
          contactPhone.replace(/\s/g, '')
        )}&text=${encodeURIComponent(message)}`
      );
    }

    if (openNewTaskModal) {
      this.props.actions.proposalsEmailSentSuccessfully();
    }
  }

  async clearBookingState() {
    try {
      await api.request(
        `${ENDPOINT_V2}/opportunities/${this.props.dealDetails.id}/clear-booking-state`,
        'POST'
      );
      await api.request(`${ENDPOINT_V2}/interactions/public/BRAIN_CLEAR_BOOKING_STATE`, 'POST', {
        body: JSON.stringify({
          dealId: this.props.dealDetails.id,
          agent: this.props.currentUsername,
        }),
      });
      openNotificationWithIcon('success', 'Hecho!', 'El cliente puede volver a ver las propuestas');
    } catch (error) {
      console.error(error);
      openNotificationWithIcon(
        'error',
        'Que mala pata :(',
        'No se ha podido echar para atrás la oportunidad'
      );
    }
  }

  async sendListing() {
    const contactsOfCompany = get(this.props, 'dealDetails.contactsOfCompany');
    const usualContact = getUsualContact(contactsOfCompany);

    if (!usualContact) {
      return openNotificationWithIcon(
        'error',
        'No tenemos contacto habitual!!!',
        'Para enviar el listing tendrás que añadir un contacto habitual a la oportunidad'
      );
    }

    let phoneNumber = get(usualContact, 'mobilePhoneNumber');
    phoneNumber = isEmpty(phoneNumber) ? get(usualContact, 'phoneNumber') : phoneNumber;

    if (isEmpty(phoneNumber)) {
      return openNotificationWithIcon(
        'error',
        'No tenemos teléfono móvil para el contacto habitual!!!',
        'Para enviar el listing por whatsapp, tendrás que indicar el teléfono móvil para el contacto habitual de la oportunidad'
      );
    }

    try {
      await navigator.clipboard.writeText(dealUtils.getListingManualUrl(this.props.dealDetails));
      openNotificationWithIcon(
        'info',
        'Se ha copiado la URL del listing al portapapeles por si no se ha podido enviar desde el whatsapp',
        '',
        0
      );
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', 'No se ha podido copiar la URL');
    }

    const message = window.encodeURIComponent(
      `¡Hola! Te escribo desde *Homyspace* sobre la solicitud de alojamiento para ${dealUtils.getLocalityOrWider(
        this.props.dealDetails
      )}, con código ${this.props.dealDetails.identificationCode}.\r\n\r\n` +
        `He preparado un listado de pisos encajan con las necesidades de tu desplazamiento.\r\n\r\n` +
        'Dime qué opciones son las que más te encajan y contacto ya mismo para confirmar disponibilidad.\r\n\r\n' +
        `Échale un vistazo *aquí*: ${dealUtils.getListingManualUrl(
          this.props.dealDetails
        )}\r\n\r\n` +
        'Cualquier duda estoy a tu disposición.'
    );

    window.open(
      `https://web.whatsapp.com/send?phone=${sanitizeMobilePrefix(
        phoneNumber.replace(/\s/g, '')
      )}&text=${message}`
    );

    let usualContactName;

    if (usualContact) {
      usualContactName =
        (usualContact.name ? usualContact.name + ' ' : '') +
        (usualContact.lastOTradeName ? usualContact.lastOTradeName : '');
    }

    const businessEvent = {
      date: new Date(),
      from: this.props.currentUsername,
      text: `<b>${prettyPrintAgentFromEmail(
        this.props.currentUsername
      )}</b> ha enviado el listing por Whatsapp${usualContactName ? ' a ' + usualContactName : ''}`,
    };

    const params = {
      dealId: get(this.props, 'dealDetails.id'),
    };
    await EventsApi.createNewBusinessEvent(businessEvent, [BUSINESS_EVENT_TYPES.WHATSAPP], params);
  }

  render() {
    const dealUsualContact = getUsualContact(this.props.dealDetails.contactsOfCompany);
    let usualContactPhone = get(dealUsualContact, 'mobilePhoneNumber');
    usualContactPhone = isEmpty(usualContactPhone)
      ? get(dealUsualContact, 'phoneNumber')
      : usualContactPhone;
    const shouldShowWhatsappCheck = !isEmpty(usualContactPhone);
    return (
      <div className='animated fadeIn col-sm-12'>
        <div>
          {this.state.showFollowUpConfirmation && (
            <Alert
              header='Follow Up'
              message={this.getFollowUpConfirmationMessage()}
              onConfirmClick={this.onConfirmFollowUp}
              onCancelClick={() => this.setState({ showFollowUpConfirmation: false })}
            />
          )}
        </div>
        <Modal
          visible={this.state.alert.show}
          width={1200}
          title='Gestión de Propuestas'
          onCancel={this.onAlertCancelClick.bind(this)}
          footer={[
            <div key='button-row' style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                key='send'
                style={{ float: 'left' }}
                defaultChecked={true}
                onChange={e => {
                  this.setState({
                    sendProposalsDossier: e.target.checked,
                  });
                }}
              >
                <b>Enviar correo de propuestas seleccionadas</b>
              </Checkbox>
              {shouldShowWhatsappCheck && (
                <Checkbox
                  key='sendWhatsapp'
                  style={{ float: 'left' }}
                  defaultChecked={this.state.sendProposalsByWhatsapp}
                  onChange={e => {
                    this.setState({
                      sendProposalsByWhatsapp: e.target.checked,
                    });
                  }}
                >
                  <b>Enviar Whatsapp de propuestas seleccionadas</b>
                </Checkbox>
              )}
              <div style={{ marginLeft: 'auto' }}>
                <Button key='cancel' onClick={this.onAlertCancelClick.bind(this)}>
                  Cancelar
                </Button>
                <Button
                  key='confirm'
                  type='primary'
                  className='btn-primary'
                  onClick={this.handleSendProposals}
                >
                  Guardar
                </Button>
              </div>
            </div>,
          ]}
        >
          <>
            <h6>
              Arrastra y suelta las propuestas para establecer en que orden se mostrarán en el
              dossier.
            </h6>
            <br />
            <div
              style={{
                marginBottom: '15px',
                marginRight: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className='row col-sm-12'
            >
              <div className='col-sm-1'>
                <label>Orden</label>
              </div>
              <div className='col-sm-7'>
                <label>Propuesta</label>
              </div>
              <div className='col-sm-2'>
                <label>Estapa</label>
              </div>
              <div className='col-sm-1'>
                <label>Fit</label>
              </div>
              <div className='col-sm-1'>
                <label>Búsqueda externa</label>
              </div>
            </div>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.proposalsList.map((proposal, index) => (
                      <Draggable key={proposal.id} draggableId={proposal.id} index={index}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {this.renderProposalAsCheckbox(proposal, index + 1)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <br />
            <h6 style={{ display: 'inline' }}>Selecciona el gestor que ha hecho el dossier:</h6>
            <Select
              name='dossierGeneratorAgent'
              value={this.state.dossierGeneratorAgent}
              allowClear
              onChange={value => {
                this.setState({
                  dossierGeneratorAgent: value,
                });
              }}
              style={{ width: 300, marginLeft: 15 }}
            >
              {!isEmpty(hsAgents) &&
                hsAgents.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
            </Select>
          </>
        </Modal>
        <Modal
          visible={this.state.alertCheckIn}
          width={860}
          title='Enviar datos para checkin'
          onOk={this.checkinConfirmation}
          onCancel={this.onAlertCancelClick.bind(this)}
        >
          <>
            <h6>Selecciona una o varias propuestas para enviar datos para checkin</h6>
            {this.renderCheckIn()}
          </>
        </Modal>
        {this.state.isLoading ? (
          <div key='1' className='loader loading-background loading-background-full'>
            &nbsp;
          </div>
        ) : (
          <>
            <div className='marginTop2 col-sm-12'>
              <div className='row'>
                <InfoButtonDealDetails currentUsername={this.props.currentUsername} />
                <InfoButtonCompanyDetails leftOffset='5' withSelfLink compressed />
                <InfoButtonDealContacts
                  mainView='DealDetails'
                  leftOffset='8'
                  filterContacts='DEAL'
                />
              </div>
            </div>
            <div>
              <div className='card'>
                <div className='card-block'>
                  <div className='row'>
                    <div className='col-sm-2'>
                      <button
                        type='button'
                        className='btn btn-primary btn-block alignLeft buttonBreakLine'
                        onClick={() => {
                          this.startSearch();
                          this.props.collapseSidebar(true);
                          this.props.navigateTo('deals');
                        }}
                      >
                        Iniciar búsqueda
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary btn-block alignLeft buttonBreakLine'
                        onClick={() =>
                          this.setState({
                            alert: {
                              show: true,
                            },
                          })
                        }
                        disabled={
                          isNil(get(this.props, 'dealDetails.proposals')) ||
                          isEmpty(get(this.state, 'proposalsList')) ||
                          get(this.props, 'dealDetails.rentalRequestStage', '') ===
                            RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION
                        }
                      >
                        Gestionar propuestas
                      </button>
                      {get(this.props, 'dealDetails.rentalRequestType', '') === 'B' &&
                        get(this.props, 'dealDetails.rentalRequestStage', '') !==
                          RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION && (
                          <button
                            type='button'
                            className='btn btn-primary btn-block alignLeft buttonBreakLine'
                            onClick={this.getAutomaticProposal}
                            disabled={this.state.isGenerateAutomaticProposalDisabled}
                          >
                            Generar propuesta automática
                          </button>
                        )}

                      <FollowUpButton
                        disableSendFollowUpOption={this.isFollowUpButtonDisabled()}
                        dealFollowUpStage={this.props.dealDetails.followUpStage}
                        handleFollowUpCall={this.handleFollowUpCall}
                        handleSendFollowUp={this.handleSendFollowUp}
                        handleFollowUpWhatsapp={this.handleFollowUpWhatsapp}
                      />

                      <button
                        disabled={this.isCheckinButtonDisabled()}
                        className='btn btn-primary btn-block alignLeft buttonBreakLine'
                        onClick={() =>
                          this.setState({
                            alertCheckIn: true,
                          })
                        }
                      >
                        Enviar datos para check-in
                      </button>
                      <button
                        className='btn btn-primary btn-block alignLeft buttonBreakLine'
                        onClick={this.clearBookingState}
                      >
                        Volver a mostrar las propuestas en el dossier
                      </button>
                      {/* <button
                        className='btn btn-primary btn-block alignLeft buttonBreakLine'
                        onClick={this.sendListing}
                      >
                        Enviar enlace del listing por whatsapp
                      </button> */}
                      {this.props.dealDetails.firstFollowUpDate && (
                        <p className='annotation'>
                          Fecha de primer follow up: <br />
                          {moment(this.props.dealDetails.firstFollowUpDate).format(DATETIME_FORMAT)}
                        </p>
                      )}
                      {this.props.dealDetails.secondFollowUpDate && (
                        <p className='annotation'>
                          Fecha de segundo follow up: <br />
                          {moment(this.props.dealDetails.secondFollowUpDate).format(
                            DATETIME_FORMAT
                          )}
                        </p>
                      )}
                    </div>
                    <div className='col-sm-10'>
                      {this.props.dealDetails &&
                        this.props.dealDetails.proposals &&
                        this.props.dealDetails.proposals.map((proposal, i) => (
                          <InfoButtonProposalDetails
                            key={`infoButtonPropal${i}}`}
                            proposalIndex={i}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TimelinePanel
              onAction={() => {
                this.props.actions.showOrLoadDealDetailsView(this.props.dealDetails.id);
              }}
              contextType='DEAL'
              metasource={this.props.metasource}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.DealDetails.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    dealDetails: state.DealDetails.data.detail,
    dealDetailsError: state.DealDetails.data.errorDetail,
    followUpSubmit: state.DealDetails.followUpSubmit,
    companyDetails: state.CompanyDetails.data.detail,
    optionLabels: state.Preload.optionLabels,
    automaticProposal: state.DealDetails.generateProposal,
    currentUsername: state.Preload.currentUserInformation.username,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      showOrLoadDealDetailsView: id => dispatch(showOrLoadDealDetailsView(id)),
      showOrLoadCompanyDetailsView: id => dispatch(showOrLoadCompanyDetailsView(id)),
      setPropertyExternalFilterAndLoad: filter =>
        dispatch(setPropertyExternalFilterAndLoad(filter)),
      setBondedDeal: dealDetails => dispatch(dealDetailsBonded(dealDetails)),
      followUp: (id, stage) => dispatch(followUp(id, stage)),
      sendProposals: (ids, dealId) => dispatch(sendProposals(ids, dealId)),
      sendCheckin: (ids, dealId) => dispatch(sendCheckin(ids, dealId)),
      generateAutomaticProposal: id => dispatch(generateAutomaticProposal(id)),
      getProposalsRelevance: dealId => dispatch(getProposalsRelevance(dealId)),
      createNewBusinessEventSuccess: businessEventType =>
        dispatch(createNewBusinessEventSuccess(businessEventType)),
      resetContactState: () => dispatch(resetContactState()),
      resetCompanyState: () => dispatch(resetCompanyState()),
      resetProviderState: () => dispatch(resetProviderState()),
      proposalsEmailSentSuccessfully: () => dispatch(proposalsEmailSentSuccessfully()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealDetails);
