import moment from 'moment';
import isDate from 'lodash/isDate';
import get from 'lodash/get';
import { DATE_FORMAT } from '../../shared/appConfig.js';

const titleClientInfo = [
  'Para ofrecer tu alojamiento a nuestros cliente, necesito la siguiente información: %0D%0A',
  'Si tuvieras disponibilidad necesitaría saber: %0D%0A',
];

const randomTitle = Math.abs(Math.round(Math.random() * titleClientInfo.length - 1));

const askClientInfo =
  titleClientInfo[randomTitle] +
  '· Precio mensual (IVA del 10%25, 21%25, o exento) %0D%0A' +
  '· Suministros incluidos o límite de ____€ %0D%0A' +
  '· Fianza %0D%0A' +
  '· Limpieza Final %0D%0A' +
  '%0D%0A' +
  '<homyrefMessage>' +
  '%0D%0A';

const emailConfirmAvailability = [
  'Hola,%0D%0A' +
    '%0D%0A' +
    'Disponemos de una solicitud con las siguientes características: %0D%0A' +
    'Inmueble: <address>. <zone> %0D%0A' +
    'Ciudad: <city> %0D%0A' +
    'Número de personas (camas): <tenants> %0D%0A' +
    'Fecha de entrada: <dateFrom> %0D%0A' +
    'Fecha de salida: <dateTo> %0D%0A' +
    '%0D%0A' +
    '¿Tendríais algo disponible para estas fechas? %0D%0A' +
    '%0D%0A' +
    askClientInfo +
    'Muchas gracias de antemano. %0D%0A' +
    'Un saludo cordial, %0D%0A',
  'Hola, %0D%0A' +
    '%0D%0A' +
    'Me pongo en contacto contigo porque disponemos de una solicitud de alquiler que podría encajar con las características de tu inmueble: %0D%0A' +
    'Inmueble: <address>. <zone> %0D%0A' +
    'Ciudad: <city> %0D%0A' +
    'Número de personas (camas): <tenants> %0D%0A' +
    'Fecha de entrada: <dateFrom> %0D%0A' +
    'Fecha de salida: <dateTo> %0D%0A' +
    '%0D%0A' +
    '¿Tendríais algo disponible para estas fechas? %0D%0A' +
    '%0D%0A' +
    askClientInfo +
    'Muchas gracias de antemano. %0D%0A' +
    'Un saludo cordial, %0D%0A',
  'Hola, %0D%0A' +
    '%0D%0A' +
    'Nos ha entrado una nueva solicitud y quería saber si tienes disponibilidad. Te detallo la información a continuación. %0D%0A' +
    'Inmueble: <address>. <zone> %0D%0A' +
    'Ciudad: <city> %0D%0A' +
    'Número de personas (camas): <tenants> %0D%0A' +
    'Fecha de entrada: <dateFrom> %0D%0A' +
    'Fecha de salida: <dateTo> %0D%0A' +
    '%0D%0A' +
    askClientInfo +
    'Espero tus noticias. %0D%0A' +
    '%0D%0A' +
    'Muchas gracias de antemano. %0D%0A' +
    'Un saludo cordial, %0D%0A',
  'Hola, %0D%0A' +
    '%0D%0A' +
    'Te escribo desde homyspace porque tenemos una nueva solicitud de trabajadores que se desplazan por motivos laborales. %0D%0A' +
    'Quería consultar disponibilidad con estos datos: %0D%0A' +
    'Inmueble: <address>. <zone> %0D%0A' +
    'Ciudad: <city> %0D%0A' +
    'Número de personas (camas): <tenants> %0D%0A' +
    'Fecha de entrada: <dateFrom> %0D%0A' +
    'Fecha de salida: <dateTo> %0D%0A' +
    '%0D%0A' +
    askClientInfo +
    'Espero tu respuesta. %0D%0A' +
    '%0D%0A' +
    'Muchas gracias de antemano. %0D%0A' +
    'Un saludo cordial, %0D%0A',
  'Hola, %0D%0A' +
    '%0D%0A' +
    'En homyspace tenemos una nueva solicitud de una empresa que desplaza a sus trabajadores. %0D%0A' +
    'Me gustaría saber disponibilidad de tus inmuebles, con los siguientes datos: %0D%0A' +
    'Inmueble: <address>. <zone> %0D%0A' +
    'Ciudad: <city> %0D%0A' +
    'Número de personas (camas): <tenants> %0D%0A' +
    'Fecha de entrada: <dateFrom> %0D%0A' +
    'Fecha de salida: <dateTo> %0D%0A' +
    '%0D%0A' +
    askClientInfo +
    'Gracias de antemano. %0D%0A' +
    'Un saludo cordial, %0D%0A',
  'Hola, %0D%0A' +
    '%0D%0A' +
    'Disponemos de una nueva solicitud en homyspace y quería saber si tienes disponibilidad. %0D%0A' +
    'Te detallo la información a continuación: %0D%0A' +
    'Inmueble: <address>. <zone> %0D%0A' +
    'Ciudad: <city> %0D%0A' +
    'Número de personas (camas): <tenants> %0D%0A' +
    'Fecha de entrada: <dateFrom> %0D%0A' +
    'Fecha de salida: <dateTo> %0D%0A' +
    '%0D%0A' +
    askClientInfo +
    'Espero tus noticias. %0D%0A' +
    'Gracias de antemano. %0D%0A',
];

class ProviderTemplates {
  getEmailConfirmAvailability(dateFrom, dateTo, opportunityAddress, propertyAddress, tenants) {
    const templateIndex = getRandomArbitrary(0, emailConfirmAvailability.length - 1);
    const propertyStreet = get(propertyAddress, 'formattedAddress') || '[[Sin dirección exacta]]';
    const propertyAddressComplement = get(propertyAddress, 'addressComplement') || '';

    let templateText = emailConfirmAvailability[templateIndex];

    templateText = templateText.replace(
      '<dateFrom>',
      isDate(dateFrom) ? moment(dateFrom).format(DATE_FORMAT) : dateFrom
    );
    templateText = templateText.replace(
      '<dateTo>',
      isDate(dateTo) ? moment(dateTo).format(DATE_FORMAT) : dateTo
    );
    templateText = templateText.replace('<city>', opportunityAddress);
    templateText = templateText.replace('<address>', propertyStreet);
    templateText = templateText.replace('<zone>', propertyAddressComplement);
    templateText = templateText.replace('<tenants>', tenants);
    templateText = templateText.replace(
      '<homyrefMessage>',
      '¿Si no lo tienes disponible, podrías decirme qué día se libera? %0D%0A' +
        '¿Tienes otro inmueble disponible o que se libere antes, aunque esté en otra zona? %0D%0A'
    );

    return templateText;
  }
}

function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default ProviderTemplates;
