const CURRENCY_LETTER = {
  EUR: '€',
};

export const PROPOSAL_PAYMENT_TYPES = {
  MONTHLY_PAYMENT: 'MONTHLY_PAYMENT',
  ONE_TIME_PAYMENT: 'ONE_TIME_PAYMENT',
};

export const amountToString = (price = {}) => {
  if (!price) {
    return `0 €`;
  }

  return `${price.amount || 0} ${CURRENCY_LETTER[price.currency] || '€'}`;
};

export const getAmount = price => Number(price && price.amount != null ? price.amount : price);

// export const getRate = (price, percentage) => price * (percentage / 100);

// export const getPriceByDays = (price, days) => pairRoundAmount(days ? (price / 30) * days : price);

// export const getPriceByMonth = (price, days) => pairRoundAmount(days ? (price / days) * 30 : price);

// export const getPriceWithoutTax = (price, tax) => pairRoundAmount(price / (1 + tax / 100));

// export const getPriceWithExemptTax = (price, providerRate) =>
//   pairRoundAmount(getRate(price, providerRate) * 1.21 + price);

// export const getPriceForDossier = (price, rate) => pairRoundAmount(getRate(price, rate) + price);

// /////////////////
/* CLIENT PRICES */
// /////////////////
/* MONTHLY PRICE */
// export const getMonthlyPriceFromTotalPrice = (totalPrice, days) => {
//   const price = getAmount(totalPrice);

//   return {
//     amount: getPriceByMonth(price, days || 1),
//     currency: 'EUR',
//   };
// };

// export const getMonthlyPriceFromTax = (monthlyPriceWithTax, tax) => {
//   const price = getAmount(monthlyPriceWithTax);
//   const taxValue = getAmount(tax);

//   return {
//     amount: getPriceWithoutTax(price, taxValue || 0),
//     currency: 'EUR',
//   };
// };

/* TOTAL PRICE */
// export const getTotalPriceFromMonthlyPrice = (monthlyPrice, days) => {
//   const price = getAmount(monthlyPrice);

//   return {
//     amount: getPriceByDays(price, days || 1),
//     currency: 'EUR',
//   };
// };

// export const getTotalPriceFromTax = (totalPriceWithTax, tax) => {
//   const price = getAmount(totalPriceWithTax);
//   const taxValue = getAmount(tax);

//   return {
//     amount: getPriceWithoutTax(price, taxValue || 0),
//     currency: 'EUR',
//   };
// };

/* MONTHLY PRICE WITH TAX */
// export const getMonthlyPriceWithTax = (monthlyPrice, tax) => {
//   const price = getAmount(monthlyPrice);
//   const taxValue = getAmount(tax);

//   return {
//     amount: getPriceForDossier(price, taxValue || 0),
//     currency: 'EUR',
//   };
// };

/* TOTAL PRICE WITH TAX */
// export const getTotalPriceWithTax = (totalPrice, tax) => {
//   const price = getAmount(totalPrice);
//   const taxValue = getAmount(tax);

//   return {
//     amount: getPriceForDossier(price, taxValue || 0),
//     currency: 'EUR',
//   };
// };

// ///////////////////////
/* PROVIDER COMMISSION */
// ///////////////////////
// export const getProviderRate = (totalPrice, commission) => {
//   const price = getAmount(totalPrice);

//   return pairRoundAmount(getRate(price, commission));
// };

// //////////////////
/* DOSSIER PRICES */
// //////////////////
// export const getPriceDossierTaxExempt = (address, totalPrice, providerRate) => {
//   totalPrice = getAmount(totalPrice);
//   const rate = getAmount(providerRate);

//   // CALCULO para un FUTURO, aplicando TAX dependiendo de la ZONA
//   // HAROLD dira cuando habra que aplicarlo
//   // Modificar tambien el getPriceWithExemptTax() comentado
//   // if (addressAdministrative) {
//   //   if (!isCeutaMelilla(addressAdministrative) &&
//   //   !isCanaryIslands(addressAdministrative)) {
//   //     return getPriceWithExemptTax(totalPrice, rate ? rate : 0, 1.21);
//   //   } else {
//   //     const tax = getTaxPercent(address) / 100 + 1;
//   //     return getPriceWithExemptTax(totalPrice, rate ? rate : 0, tax);
//   //   }
//   // } else {
//   //   return getPriceWithExemptTax(totalPrice, rate ? rate : 0, 1.21);
//   // }

//   /*****************************************************************************************************************/
//   // Since HTI-3483 "Automatic proposal generation from availability landing" (July 02 2020)
//   // At back prices implementation, they have done the same change.

//   // BEFORE: getPriceWithExemptTax() -> it returns the price + (providerCommission * 1.21) of IVA always

//   // NOW: getPriceForDossier() for all cases -> IVA is not added to providerComission when taxExempt in proposal

//   // const addressAdministrative = address ? address.administrativeAreaLevel1 : null;
//   // const addressCountry = address ? address.country : null;
//   // if (
//   //   addressAdministrative &&
//   //   !isCeutaMelilla(addressAdministrative) &&
//   //   !isCanaryIslands(addressAdministrative) &&
//   //   isSpain(addressCountry)
//   // ) {
//   //   return getPriceWithExemptTax(totalPrice, rate || 0);
//   // }
//   /*****************************************************************************************************************/
//   // UPDATE FOR July 06 2020
//   // The booking team was not aware of this change and is not agree with it, so we replace it with previous version
//   /*****************************************************************************************************************/
//   // 2nd UPDATE FOR July 06 2020
//   // Changes in conditions to apply 21% IVA to provider comission to be equal than the back
//   // OLD:
//   // if (
//   //   addressAdministrative &&
//   //   !isCeutaMelilla(addressAdministrative) &&
//   //   !isCanaryIslands(addressAdministrative) &&
//   //   isSpain(addressCountry)
//   // ) {
//   //   return getPriceWithExemptTax(totalPrice, rate || 0);
//   // }
//   /*****************************************************************************************************************/

//   const addressAdministrative = address ? address.administrativeAreaLevel1 : null;
//   // const addressCountry = address ? address.country : null;
//   if (
//     addressAdministrative === null ||
//     (addressAdministrative !== null &&
//       !isCeutaMelilla(addressAdministrative) &&
//       !isCanaryIslands(addressAdministrative))
//   ) {
//     return getPriceWithExemptTax(totalPrice, rate || 0);
//   }

//   return getPriceForDossier(totalPrice, rate || 0);
// };

/* MONTHLY PRICE */
// export const getMonthlyPriceDossier = (price, providerRate) => {
//   price = getAmount(price);
//   const rate = getAmount(providerRate);

//   return getPriceForDossier(price, rate || 0);
// };

/* TOTAL PRICE */
// export const getTotalPriceDossier = (totalPrice, providerRate) => {
//   const price = getAmount(totalPrice);
//   const rate = getAmount(providerRate);

//   return getPriceForDossier(price, rate || 0);
// };

/* MONTHLY PRICE WITH TAX */
// export const getMonthlyPriceWithTaxDossier = (monthlyPriceDossier, taxValue) => {
//   const price = getAmount(monthlyPriceDossier);

//   return getPriceForDossier(price, taxValue || 0);
// };

/* TOTAL PRICE WITH TAX */
// export const getTotalPriceWithTaxDossier = (totalPriceDossier, tax) => {
//   const price = getAmount(totalPriceDossier);
//   const taxValue = getAmount(tax);

//   return getPriceForDossier(price, taxValue || 0);
// };

/* CLEANING CHARGES WITH TAX */
// export const getCleaningChargesWithTax = (address, cleaningCharges) => {
//   const price = getAmount(cleaningCharges);
//   const taxValue = getTaxPercentCleaningCharges(address);

//   return {
//     amount: getPriceForDossier(price, taxValue || 0),
//     currency: 'EUR',
//   };
// };
