import { getAxiosInstance, queryStringSlashEncode } from '../../shared/utils/apiUtils';
import isEmpty from 'lodash/isEmpty';
import { getPropertiesBySmartSearchURL } from '../../shared/apiUrls';
import api from '../../shared/utils/api';

class PropertiesApi {
  static getMetasource(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getExportationProperties(
    url,
    {
      maxPrice,
      guest,
      billable,
      comissionable,
      startDate,
      endDate,
      beds,
      bedsMoreThan,
      rooms,
      roomsMoreThan,
      bathsAndToilets,
      publishedInWeb,
      airConditioning,
      wifi,
      freeCarPark,
      lift,
      petsAllowed,
      cleaningChargeType,
      incorporationDateStart,
      providerId,
    }
  ) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url, {
      params: {
        maxPrice,
        minGuest: guest,
        billable: billable,
        comissionable: comissionable,
        startDate,
        endDate,
        beds,
        rooms,
        bedsMoreThan,
        roomsMoreThan,
        bathsAndToilets,
        publishedInWeb,
        airConditioning,
        wifi,
        freeCarPark,
        lift,
        petsAllowed,
        cleaningChargeType,
        incorporationDateStart,
        providerId,
      },
    });
  }

  static getNewPropertyLocation(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(url);
  }

  static getPropertyList(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getPropertiesInMapFilteredByReference(url, reference) {
    const axiosInstance = getAxiosInstance();

    reference = queryStringSlashEncode(reference);

    return axiosInstance.get(url + reference);
  }

  static uploadImportFile(url, file) {
    const axiosInstance = getAxiosInstance();
    const data = new FormData();

    data.append('file', file, file.name);
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    return axiosInstance.post(url, data, config);
  }

  static getImportStatus(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getPropertiesBySmartSearch(dealId) {
    if (isEmpty(dealId) || isEmpty(dealId)) {
      throw new Error('PropertiesApi.getPropertiesBySmartSearch: no dealId  specified');
    }
    const url = getPropertiesBySmartSearchURL(dealId);
    return api
      .request_retry(url, 'get', 5)
      .then(res => res.json)
      .catch(e => {
        throw new Error('PropertiesApi.getPropertiesBySmartSearch: ' + e.errorMessage);
      });
  }
}

export default PropertiesApi;
