import { browserHistory } from 'react-router';
import {
  COMPANIES_METASOURCE_ERROR,
  COMPANIES_METASOURCE_SUCCESS,
  COMPANIES_TABLEDATA_FILTER,
  COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR,
  COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS,
  NEW_COMPANY_LOCATION_ERROR,
  NEW_COMPANY_LOCATION_SUCCESS,
} from '../../shared/actionTypes';
import CompaniesApi from './CompaniesApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink } from '../../shared/utils/appUtils';

export const metasourceSuccess = metasourceLinks => ({
  type: COMPANIES_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: COMPANIES_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const filterTableDataCompanies = filterCriteria => ({
  type: COMPANIES_TABLEDATA_FILTER,
  filterCriteria,
});

export const newCompanyLocationSuccess = location => ({
  type: NEW_COMPANY_LOCATION_SUCCESS,
  location,
});

export const newCompanyLocationError = errorMessage => ({
  type: NEW_COMPANY_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const getInvoiceOptionsSuccess = options => ({
  type: COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS,
  options,
});

export const getInvoiceOptionsError = errorMessage => ({
  type: COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR,
  errorDetail: errorMessage,
});

export const getInvoiceMarketingSourceMediumOptions = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Companies.metasource,
      getState().Preload.metasourceStrings,
      'COMPANY_MAIN_METASOURCE_COMPANIES_MARKETING_SOURCE_MEDIUM'
    );

    return CompaniesApi.doGet(url.href)
      .then(response => {
        dispatch(getInvoiceOptionsSuccess(response));
      })
      .catch(error => {
        console.error(error);
        dispatch(getInvoiceOptionsError(extractErrorMessage(error)));
      });
  };

export const loadCompaniesMetasource = () =>
  function(dispatch, getState) {
    try {
      return CompaniesApi.getMetasource(getState().Preload.mainMetasource.companies.href)
        .then(response => {
          dispatch(metasourceSuccess(response.data._links));
          dispatch(getInvoiceMarketingSourceMediumOptions());
        })
        .catch(error => {
          console.error(error);
          dispatch(metasourceError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getNewCompanyLocation = () =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Companies.metasource,
      getState().Preload.metasourceStrings,
      'COMPANY_MAIN_METASOURCE_NEW_COMPANY'
    );

    return CompaniesApi.getNewCompanyLocation(url.href)
      .then(location => {
        dispatch(newCompanyLocationSuccess(location));
      })
      .catch(error => {
        console.error(error);
        dispatch(newCompanyLocationError(extractErrorMessage(error)));
      });
  };
