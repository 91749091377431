import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import {
  getAddressFromWorldWideAddress,
  getTextValueFromSelectOptions,
} from '../../shared/utils/appUtils';
import { DATE_FORMAT } from '../../shared/appConfig';
import HomyPagination from '../HomyComponents/HomyPagination';
import tableUtils from '../../shared/utils/tableUtils';

class TableDataDeals extends Component {
  constructor(props) {
    super(props);
    this.showDealDetail = this.showDealDetail.bind(this);
  }

  showDealDetail(dealId) {
    browserHistory.push(`/dealDetails/${dealId}`);
  }

  render() {
    const columns = [
      {
        title: 'Código',
        dataIndex: 'identificationCode',
        width: 100,
        fixed: 'left',
        sorter: true,
        render: (text, record) => <a href={`/dealDetails/${record.id}`}>{text}</a>,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showDealDetail(record.id);
        },
      },
      {
        title: 'Cliente',
        dataIndex: 'companyName',
        width: 200,
        fixed: 'left',
        sorter: true,
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        width: 100,
      },
      {
        title: 'Ciudad',
        width: 150,
        dataIndex: 'location',
        sorter: true,
        render: value => getAddressFromWorldWideAddress(value),
      },
      {
        title: 'Fecha de creación',
        width: 150,
        dataIndex: 'creationDate',
        sorter: true,
        render: val => moment(val).format(DATE_FORMAT),
      },
      {
        title: 'Fecha de inicio alquiler',
        width: 150,
        dataIndex: 'rentalStartDate',
        sorter: true,
        render: val => moment(val).format(DATE_FORMAT),
      },
      {
        title: 'Fecha de fin alquiler',
        width: 150,
        dataIndex: 'rentalEndDate',
        sorter: true,
        render: val => moment(val).format(DATE_FORMAT),
      },
      {
        title: 'Nº consultas',
        width: 100,
        dataIndex: 'availabilityQueries',
      },
      {
        title: 'Propuestas',
        width: 100,
        dataIndex: 'numberOfProposals',
      },
      {
        title: 'PAX',
        width: 75,
        dataIndex: 'numberOfPeople',
        sorter: true,
      },
      {
        title: 'Presupuesto',
        width: 100,
        dataIndex: 'budget',
        render: record => (record ? numeral(record.amount).format('0.00') : ''),
      },
      {
        title: 'Gestor asignado',
        width: 250,
        dataIndex: 'hsagent',
        sorter: true,
      },
      {
        title: 'Búsqueda externa',
        width: 100,
        dataIndex: 'externalSearch',
        render: record => (record ? 'Si' : 'No'),
      },
      {
        title: 'Dossier enviado',
        width: 100,
        dataIndex: 'dossierSent',
        render: record => (record ? 'Si' : 'No'),
      },
      {
        title: 'Etapa de la oportunidad',
        dataIndex: 'rentalRequestStage',
        width: 200,
        sorter: true,
        render: value =>
          getTextValueFromSelectOptions(value, this.props.options.rentalRequestStages),
      },
    ];

    const scrollX = tableUtils.getTableWidth();
    const scrollY = tableUtils.getTableHeight() - 75;

    return (
      <div className='dealBorderTopColor'>
        <Table
          size='middle'
          className='fixed-width-table'
          pagination={false}
          dataSource={this.props.data}
          loading={this.props.loading}
          columns={columns}
          rowKey={rec => rec.id}
          scroll={{ x: scrollX, y: scrollY }}
          onChange={(pagination, filter, sorter) => this.props.handleSorter(sorter)}
          footer={() => (
            <HomyPagination
              showSizeChanger
              showQuickJumper
              current={this.props.pagination.page}
              pageSize={this.props.pagination.pageSize}
              totalCount={this.props.pagination.totalCount}
              onShowSizeChange={this.props.handlePaginationSizeChange}
              onChange={this.props.handlePaginationChange}
            />
          )}
        />
      </div>
    );
  }
}

export default TableDataDeals;
