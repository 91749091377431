import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Row } from 'antd';
import Select from 'react-select';
import {
  createNewProvider,
  resetCreateProviderError,
} from '../../modules/ProviderDetails/ProviderDetailsActions';
import { normalizePhoneNumber, validateInputs } from '../../shared/utils/formUtils';
import {
  getOptionsForSelect,
  openNotificationWithIcon,
  setKeyLabelESToLabel,
} from '../../shared/utils/appUtils';
import get from 'lodash/get';

class FormNewProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newProviderDetails: {
        providerType: '',
        baseContact: {
          name: '',
          lastOTradeName: '',
          email: '',
          email2: '',
          phoneNumber: '',
          mobilePhoneNumber: '',
          creationDate: new Date(),
          updateDate: new Date(),
        },
      },
      submitting: false,
      invalidInput: false,
      marketingSourceMediumOptions: setKeyLabelESToLabel(this.props.marketingSourceMediumOptions),
      options: {
        providerType: getOptionsForSelect(this.props.optionLabels, 'ProviderType'),

        favoriteContactChannel: getOptionsForSelect(
          this.props.optionLabels,
          'FavoriteContactChannel'
        ),
      },
    };
    this.props.providerDetailsCreate.error = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateFavoriteContactChannel = this.validateFavoriteContactChannel.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if (props.providerDetailsCreate.error) {
      return { submitting: false };
    }

    return null;
  }

  componentWillUnmount() {
    this.setState({ newProviderDetails: {} });
  }

  handleChange(e) {
    const localNewProviderDetails = this.state.newProviderDetails;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (e.target.name === 'phoneNumber' || e.target.name === 'mobilePhoneNumber') {
      value = normalizePhoneNumber(value);
    }

    localNewProviderDetails.baseContact[e.target.name] = value;
    this.setState({ newProviderDetails: localNewProviderDetails });
  }

  handleSelectChange(name, selectedOption) {
    const localNewProviderDetails = this.state.newProviderDetails;

    localNewProviderDetails[name] = selectedOption ? selectedOption.value : null;
    this.setState({ newProviderDetails: localNewProviderDetails });
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');
    const validForm = validateInputs(inputsToValidate);

    this.setState({ invalidInput: !validForm });

    return validForm;
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.actions.resetCreateProviderError();
    const validFavoriteChannel = this.validateFavoriteContactChannel();

    if (this.isValidForm() && validFavoriteChannel) {
      this.setState({ submitting: true });
      this.props.actions.createNewProvider(
        this.props.providerLocation,
        this.state.newProviderDetails
      );
    }
  };

  validateFavoriteContactChannel() {
    if (
      this.state.newProviderDetails.favoriteContactChannel === 'PHONE' &&
      (!this.state.newProviderDetails.baseContact.phoneNumber ||
        this.state.newProviderDetails.baseContact.phoneNumber === '') &&
      (!this.state.newProviderDetails.baseContact.mobilePhoneNumber ||
        this.state.newProviderDetails.baseContact.mobilePhoneNumber === '')
    ) {
      openNotificationWithIcon(
        'error',
        'Ojo!!!',
        'Si seleccionas como canal favorito "Teléfono", debes rellenar el campo teléfono o móvil'
      );

      return false;
    }

    if (
      this.state.newProviderDetails.favoriteContactChannel === 'MAIL' &&
      (!this.state.newProviderDetails.baseContact.email ||
        this.state.newProviderDetails.baseContact.email === '')
    ) {
      openNotificationWithIcon(
        'error',
        'Ojo!!!',
        'Si seleccionas como canal favorito "Correo", debes rellenar el campo Email'
      );

      return false;
    }

    if (
      this.state.newProviderDetails.favoriteContactChannel === 'WHATSAPP' &&
      (!this.state.newProviderDetails.baseContact.mobilePhoneNumber ||
        this.state.newProviderDetails.baseContact.mobilePhoneNumber === '')
    ) {
      openNotificationWithIcon(
        'error',
        'Ojo!!!',
        'Si seleccionas como canal favorito "Whatsapp", debes rellenar el campo Teléfono móvil'
      );

      return false;
    }

    return true;
  }

  render() {
    return (
      <form id='formNewProviderDetails' onSubmit={this.handleSubmit}>
        {this.props.providerDetailsCreate.error && (
          <div>
            <Alert
              message='Error'
              description={this.props.providerDetailsCreate.errorDetail}
              type='error'
              showIcon
              closable
            />
            <br />
          </div>
        )}
        {this.state.invalidInput && (
          <div>
            <Alert message='Error en los datos introducidos' type='error' showIcon closable />
            <br />
          </div>
        )}
        <Row>
          <Col span={24}>
            <label htmlFor='providerDetailProviderOrigin' className='required'>
              Origen del proveedor
            </label>
            <Select
              id='providerDetailProviderOrigin'
              name='marketingSourceMedium'
              className='form-control validate validate-required'
              value={get(this.state, 'newProviderDetails.marketingSourceMedium') || ''}
              options={this.state.marketingSourceMediumOptions}
              onChange={value => {
                this.handleSelectChange('marketingSourceMedium', value);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='providerDetailProviderType' className='required'>
              Tipo
            </label>
            <Select
              id='providerDetailProviderType'
              name='providerType'
              className='form-control validate validate-required'
              value={get(this.state, 'newProviderDetails.providerType') || ''}
              options={this.state.options.providerType}
              onChange={value => {
                this.handleSelectChange('providerType', value);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='providerDetailName' className='required'>
              Nombre
            </label>
            <input
              type='text'
              className='form-control validate validate-required'
              id='providerDetailName'
              name='name'
              placeholder='Ana'
              value={this.state.newProviderDetails.name}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='providerDetailLastName'>Apellidos / Razón social</label>
            <input
              type='text'
              className='form-control'
              id='providerDetailLastOTradeName'
              name='lastOTradeName'
              placeholder='García García'
              value={this.state.newProviderDetails.lastOTradeName}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='favoriteContactChannel'>Canal de contacto preferido</label>
            <Select
              name='favoriteContactChannel'
              className='form-control'
              id='favoriteContactChannel'
              value={this.state.newProviderDetails.favoriteContactChannel || ''}
              options={this.state.options.favoriteContactChannel}
              onChange={value => {
                this.handleSelectChange('favoriteContactChannel', value);
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='providerDetailEmail'>Email</label>
            <input
              type='email'
              className='form-control'
              id='providerDetailEmail'
              name='email'
              placeholder='pruebas@pruebas.com'
              value={this.state.newProviderDetails.email}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='providerDetailPhoneNumber'>Teléfono fijo</label>
            <input
              type='text'
              className='form-control'
              id='providerDetailPhoneNumber'
              name='phoneNumber'
              placeholder='961231212'
              value={this.state.newProviderDetails.phoneNumber}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <label htmlFor='ProviderDetailMobilePhoneNumber'>Teléfono móvil</label>
            <input
              type='text'
              className='form-control'
              id='providerDetailMobilePhoneNumber'
              name='mobilePhoneNumber'
              placeholder='616232323'
              value={this.state.newProviderDetails.mobilePhoneNumber}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <br />

        <Row type='flex' justify='end'>
          <Col>
            <Button loading={this.state.submitting} htmlType='submit' className='btn-primary'>
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createNewProvider: (url, providerDetailsData) =>
        dispatch(createNewProvider(url, providerDetailsData)),
      resetCreateProviderError: () => dispatch(resetCreateProviderError()),
    },
  };
}

function mapStateToProps(state) {
  return {
    providerDetailsCreate: state.ProviderDetails.create,
    providerLocation: state.Providers.providerLocation.location,
    optionLabels: state.Preload.optionLabels,
    marketingSourceMediumOptions: state.Providers.providerMarketingSourceMediumOptions.options.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormNewProvider);
