import {
  CONTACTDETAILS_DATA_ERROR,
  CONTACTDETAILS_DATA_SUCCESS,
  CONTACTDETAILS_DELETE_ERROR,
  CONTACTDETAILS_DELETE_SUCCESS,
  CONTACTDETAILS_METASOURCE_ERROR,
  CONTACTDETAILS_METASOURCE_SUCCESS,
  CONTACTDETAILS_RESET,
  CONTACTDETAILS_SUBMIT_ERROR,
  CONTACTDETAILS_SUBMIT_SUCCESS,
  NEW_CONTACT_CREATE_ERROR,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  data: {
    url: '',
    etag: '',
    detail: {},
    error: false,
    errorDetail: '',
  },
  submit: {
    error: false,
    errorDetail: '',
  },
  create: {
    error: false,
    errorDetail: '',
  },
  delete: {
    contact: {},
    error: false,
    errorDetail: '',
  },
  contactUpdateSuccess: false,
  // contactUpdateCount: simplemente para incrementarlo y maperalo en FromNewContact
  // para que este detecte el cambio en el estado y lanze el UNSAFE_componentWillReceiveProps
  contactUpdateCount: 0,
};

const ContactDetails = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTDETAILS_RESET: {
      return Object.assign({}, initialState);
    }

    case CONTACTDETAILS_METASOURCE_SUCCESS: {
      return Object.assign({}, initialState, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case CONTACTDETAILS_METASOURCE_ERROR: {
      return Object.assign({}, initialState, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case CONTACTDETAILS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          url: action.url,
          etag: action.etag,
          detail: action.contactDetailsData,
          error: false,
          errorDetail: '',
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case CONTACTDETAILS_DATA_ERROR: {
      return Object.assign({}, state, {
        data: {
          url: '',
          etag: '',
          detail: {},
          error: true,
          errorDetail: action.contactDetailsErrorDetails,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case CONTACTDETAILS_SUBMIT_SUCCESS: {
      const data = Object.assign({}, state.data, {
        etag: action.etag,
      });
      const count = state.contactUpdateCount + 1;

      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
        },
        data,
        contactUpdateSuccess: true,
        contactUpdateCount: count,
      });
    }

    case CONTACTDETAILS_SUBMIT_ERROR: {
      const count = state.contactUpdateCount + 1;

      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.contactDetailsSubmitErrorDetails,
        },
        contactUpdateSuccess: false,
        contactUpdateCount: count,
      });
    }

    case NEW_CONTACT_CREATE_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: true,
          errorDetail: action.createNewContactErrorDetails,
        },
      });
    }

    case CONTACTDETAILS_DELETE_SUCCESS: {
      return Object.assign({}, state, {
        delete: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case CONTACTDETAILS_DELETE_ERROR: {
      return Object.assign({}, state, {
        delete: {
          error: true,
          errorDetail: action.errorMessage,
        },
      });
    }

    default:
      return state;
  }
};

export default ContactDetails;
