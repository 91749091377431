import {
  COMPANYBASECONTACTANDCHILDREN_DATA_ERROR,
  COMPANYBASECONTACTANDCHILDREN_DATA_SUCCESS,
  COMPANYCONTACT_ETAG_SUCCESS,
  COMPANYCONTACT_SUBMIT_ERROR,
  COMPANYCONTACT_SUBMIT_SUCCESS,
  COMPANYCONTACTS_DATA_ERROR,
  COMPANYCONTACTS_DATA_SUCCESS,
  COMPANYDETAILS_DATA_ERROR,
  COMPANYDETAILS_DATA_SUCCESS,
  COMPANYDETAILS_METASOURCE_ERROR,
  COMPANYDETAILS_METASOURCE_SUCCESS,
  COMPANYDETAILS_RESET,
  COMPANYDETAILS_SUBMIT_ERROR,
  COMPANYDETAILS_SUBMIT_SUCCESS,
  NEW_COMPANY_CREATE_ERROR,
  NEW_CONTACT_CREATE_ERROR,
  NEW_CONTACT_CREATE_SUCCESS,
  NEW_CONTACT_LOCATION_ERROR,
  NEW_CONTACT_LOCATION_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  data: {
    url: '',
    etag: '',
    detail: {},
    error: false,
    errorDetail: '',
  },
  contacts: {
    detail: {},
    error: false,
    errorDetail: '',
  },
  baseContact: {
    detail: {},
    error: false,
    errorDetail: '',
  },
  contactsVersion: 0,
  contact: {
    etag: 0,
    error: false,
    errorDetail: '',
  },
  submit: {
    error: false,
    errorDetail: '',
  },
  create: {
    error: false,
    errorDetail: '',
  },
  contactCreate: {
    error: false,
    errorDetail: '',
  },
  contactCreateSuccess: false,
  contactLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
  invoiceMarketingSourceMediumOptions: {
    options: [],
    error: false,
    errorDetail: '',
  },
};

const CompanyDetails = (state = initialState, action) => {
  switch (action.type) {
    case COMPANYDETAILS_RESET: {
      return Object.assign({}, initialState);
    }

    case COMPANYDETAILS_METASOURCE_SUCCESS: {
      return Object.assign({}, initialState, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case COMPANYDETAILS_METASOURCE_ERROR: {
      return Object.assign({}, initialState, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case COMPANYDETAILS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          url: action.url,
          etag: action.etag,
          detail: action.companyDetailsData,
          error: false,
          errorDetail: '',
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case COMPANYDETAILS_DATA_ERROR: {
      return Object.assign({}, state, {
        data: {
          url: '',
          etag: '',
          detail: {},
          error: true,
          errorDetail: action.companyDetailsErrorDetails,
        },
        submit: {
          error: false,
          errorDetail: '',
        },
        create: {
          error: false,
          errorDetail: '',
        },
      });
    }

    case COMPANYCONTACTS_DATA_SUCCESS: {
      return Object.assign({}, state, {
        contacts: {
          detail: action.companyContactsData,
          error: false,
          errorDetail: '',
        },
        contactsVersion: state.contactsVersion + 1,
      });
    }

    case COMPANYCONTACTS_DATA_ERROR: {
      return Object.assign({}, state, {
        contacts: {
          detail: {},
          error: true,
          errorDetail: action.companyContactsErrorDetails,
        },
      });
    }

    case COMPANYBASECONTACTANDCHILDREN_DATA_SUCCESS: {
      return Object.assign({}, state, {
        baseContact: {
          detail: action.companyBaseContactAndChildrenData,
          error: false,
          errorDetail: '',
        },
        contactsVersion: state.contactsVersion + 1,
      });
    }

    case COMPANYBASECONTACTANDCHILDREN_DATA_ERROR: {
      return Object.assign({}, state, {
        baseContact: {
          detail: {},
          error: true,
          errorDetail: action.companyBaseContactAndChildrenErrorDetails,
        },
      });
    }

    case COMPANYDETAILS_SUBMIT_SUCCESS: {
      const data = Object.assign({}, state.data, {
        etag: action.etag,
      });

      return Object.assign({}, state, {
        submit: {
          error: false,
          errorDetail: '',
        },
        data: data,
      });
    }

    case COMPANYDETAILS_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        submit: {
          error: true,
          errorDetail: action.companyDetailsSubmitErrorDetails,
        },
      });
    }

    case COMPANYCONTACT_ETAG_SUCCESS: {
      return Object.assign({}, state, {
        contact: {
          etag: action.etag,
        },
      });
    }

    case COMPANYCONTACT_SUBMIT_SUCCESS: {
      return Object.assign({}, state, {
        contact: {
          etag: action.etag,
          error: false,
          errorDetail: '',
        },
      });
    }

    case COMPANYCONTACT_SUBMIT_ERROR: {
      return Object.assign({}, state, {
        contact: {
          etag: 0,
          error: true,
          errorDetail: action.companyContactSubmitErrorDetails,
        },
      });
    }

    case NEW_COMPANY_CREATE_ERROR: {
      return Object.assign({}, state, {
        create: {
          error: true,
          errorDetail: action.createNewCompanyErrorDetails,
        },
      });
    }

    case NEW_CONTACT_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        contactLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
        contactCreateSuccess: false,
      });
    }

    case NEW_CONTACT_LOCATION_ERROR: {
      return Object.assign({}, state, {
        contactLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
        contactCreateSuccess: false,
      });
    }

    case NEW_CONTACT_CREATE_SUCCESS: {
      return Object.assign({}, state, {
        contactCreate: {
          error: false,
          errorDetail: '',
        },
        contactCreateSuccess: true,
      });
    }

    case NEW_CONTACT_CREATE_ERROR: {
      return Object.assign({}, state, {
        contactCreate: {
          error: true,
          errorDetail: action.createNewContactErrorDetails,
        },
        contactCreateSuccess: false,
      });
    }

    default:
      return state;
  }
};

export default CompanyDetails;
