import {
  CREATE_NEW_BUSINESS_EVENT_ERROR,
  CREATE_NEW_BUSINESS_EVENT_SUCCESS,
  DELETE_BUSINESS_EVENT_ERROR,
  DELETE_BUSINESS_EVENT_SUCCESS,
  EVENT_COUNTS_ERROR,
  EVENT_COUNTS_SUCCESS,
  EVENTS_LIST_ERROR,
  EVENTS_LIST_SUCCESS,
  NEW_BUSINESS_EVENT_LOCATION_ERROR,
  NEW_BUSINESS_EVENT_LOCATION_SUCCESS,
} from '../../shared/actionTypes';
import EventsApi from './EventsApi';
import {
  getMetasourceLink,
  openNotificationWithIcon,
  resolveParams,
} from '../../shared/utils/appUtils';

export const eventsListSuccess = eventsList => ({
  type: EVENTS_LIST_SUCCESS,
  eventsList: eventsList.businessEventList,
  eventsListNextPageUrl: eventsList._links
    ? eventsList._links.next
      ? eventsList._links.next.href
      : null
    : '',
});

export const eventsListError = errorMessage => ({
  type: EVENTS_LIST_ERROR,
  errorDetail: errorMessage,
});

export const eventTotalCountsSuccess = eventCounts => ({
  type: EVENT_COUNTS_SUCCESS,
  eventCounts,
});

export const eventTotalCountsError = errorMessage => ({
  type: EVENT_COUNTS_ERROR,
  errorDetail: errorMessage,
});

export const newBusinessEventLocationSuccess = location => ({
  type: NEW_BUSINESS_EVENT_LOCATION_SUCCESS,
  location,
});

export const newBusinessEventLocationError = errorMessage => ({
  type: NEW_BUSINESS_EVENT_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const createNewBusinessEventSuccess = businessEventType => ({
  type: CREATE_NEW_BUSINESS_EVENT_SUCCESS,
  businessEventType,
});

export const createNewBusinessEventError = errorMessage => ({
  type: CREATE_NEW_BUSINESS_EVENT_ERROR,
  errorDetail: errorMessage,
});

export const deleteBusinessEventSuccess = businessEventType => ({
  type: DELETE_BUSINESS_EVENT_SUCCESS,
  businessEventType,
});

export const deleteBusinessEventError = errorMessage => ({
  type: DELETE_BUSINESS_EVENT_ERROR,
  errorDetail: errorMessage,
});

const getUrl = (eventTypeMetasourceLink, metasource, state) =>
  getMetasourceLink(metasource, state.Preload.metasourceStrings, eventTypeMetasourceLink);

export const getEventTotalCounts = (contextType, metasource) =>
  function(dispatch, getState) {
    const url = getUrl(contextType, metasource, getState()).href;

    return EventsApi.getEventTotalCounts(url)
      .then(response => {
        dispatch(eventTotalCountsSuccess(response.data));
      })
      .catch(error => {
        dispatch(eventTotalCountsError(error.message));
      });
  };

export const getEventListData = (contextType, eventType = '', metasource, urlNextPage = '') =>
  function(dispatch, getState) {
    if (urlNextPage === null) {
      return;
    }

    let url = urlNextPage;

    if (url === '' || !url) {
      const getLink = getUrl(contextType, metasource, getState());

      url = getLink ? getLink.href : null;
    }

    if (url == null) {
      dispatch(resetEventListData());
    } else {
      if (eventType !== '') {
        const baseUrl = url.substring(0, url.indexOf('=') + 1);
        const paramsUrl = url.substring(url.indexOf('&'), url.length);

        url = `${baseUrl}${eventType}${paramsUrl}`;
      }

      return EventsApi.getEventListData(url)
        .then(response => {
          dispatch(eventsListSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(eventsListError({}));
        });
    }
  };

export const resetEventListData = () =>
  function(dispatch) {
    dispatch(eventsListSuccess([]));
    dispatch(eventTotalCountsSuccess({}));
  };

export const getNewBusinessEventLocation = (contextType, metasource) =>
  function(dispatch, getState) {
    const url = resolveParams(getUrl(contextType, metasource, getState()).href);

    return EventsApi.getNewBusinessEventLocation(url)
      .then(response => {
        const { location } = response.headers;

        dispatch(newBusinessEventLocationSuccess(location));
      })
      .catch(error => {
        console.error(error);
        dispatch(newBusinessEventLocationError(error.message));
      });
  };

export const createNewBusinessEvent = (url, businessEventData) => {
  const cleanUrl = resolveParams(url);

  return function(dispatch) {
    return EventsApi.saveBusinessEventData(cleanUrl, businessEventData)
      .then(response => {
        openNotificationWithIcon('success', 'Todo OK :)', 'El evento se ha creado correctamente');
        dispatch(createNewBusinessEventSuccess(response.data.businessEvent.type));
      })
      .catch(error => {
        console.error(error);
        dispatch(createNewBusinessEventError(error.message));
      });
  };
};

export const deleteBusinessEvent = url =>
  function(dispatch) {
    return EventsApi.deleteBusinessEvent(url)
      .then(response => {
        openNotificationWithIcon(
          'success',
          'Todo OK :)',
          'El evento se ha eliminado correctamente'
        );
        dispatch(deleteBusinessEventSuccess(response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(deleteBusinessEventError(error.message));
      });
  };
