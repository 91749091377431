import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FormProposalDetails from '../FormProposalDetails/FormProposalDetails';
import {
  getOptionsForSelect,
  getTextValueFromSelectOptions,
  isEmptyObject,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils.js';
import { DATE_FORMAT, ENDPOINT_V2 } from '../../shared/appConfig.js';
import { normalizeNumberToDecimals } from '../../shared/utils/formUtils';
import get from 'lodash/get';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import api from '../../shared/utils/api';
import minBy from 'lodash/minBy';
import round from 'lodash/round';
import isNil from 'lodash/isNil';
import indexOf from 'lodash/indexOf';
import CompanyDetailsApi from '../../modules/CompanyDetails/CompanyDetailsApi';
// import { useCheckAvailabilityHandler } from '../../shared/utils/hooks/useCheckAvailabilityHandler';

const moneySymbol = { EUR: '€' };
const printPrice = price =>
  `${get(price, 'amount', 0)} ${moneySymbol[get(price, 'currency', 'EUR')]}`;

const QQ_FREQUENCIES = [
  'NS/NC',
  'PROMPTLY',
  'ONE_TO_TWO',
  'THREE_TO_FOUR',
  'FIVE_TO_TEN',
  'TEN_TO_TWENTY',
  'PLUS_TWENTY',
];

// PARÁMETROS
// proposalIndex -> índice de la propuesta dentro del vector de propuestas de la oportunidad

class InfoButtonProposalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProposalDetail: false,
      dealDetails: props.dealDetails,
      proposalDetails: props.dealDetails.proposals[props.proposalIndex].proposal,
      proposalsRelevance: [],
      thisProposalRelevance: '',
      options: {
        proposalStage: getOptionsForSelect(this.props.optionLabels, 'ProposalStage'),
        proposalStatus: getOptionsForSelect(this.props.optionLabels, 'ProposalStatus'),
        cleaningChargeType: getOptionsForSelect(this.props.optionLabels, 'CleaningChargeType'),
      },
      marketPrice: {
        amount: '',
        differenceWithProposalPrice: '',
      },
      propertyPrice: {
        amount: '',
        updateDate: '',
        differenceWithProposalPrice: '',
      },
      qualifyingQuestions: {},
      isNegotiable: false,
      isAvailabilityQueried: null,
    };

    this.showProposalDetail = this.showProposalDetail.bind(this);
    this.setProposalRelevance = this.setProposalRelevance.bind(this);
    this.setProposalRelevance = this.setProposalRelevance.bind(this);
    this.calculatePrices = this.calculatePrices.bind(this);
    // this.handleAvailabilityQuery = this.handleAvailabilityQuery.bind(this);
  }

  async componentDidMount() {
    await this.calculatePrices();
    this.calculateInfoPrices();
    this.calculateIfNegotiable();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.state.dealDetails) !== JSON.stringify(nextProps.dealDetails)) {
      let proposalDetails = {};

      if (
        nextProps.dealDetails.proposals &&
        nextProps.dealDetails.proposals.length > this.props.proposalIndex
      ) {
        proposalDetails = nextProps.dealDetails.proposals[this.props.proposalIndex].proposal;
      }

      this.setState(
        {
          dealDetails: nextProps.dealDetails,
          proposalDetails,
        },
        () => {
          this.calculatePrices();
          this.calculateInfoPrices();
          this.calculateIfNegotiable();
        }
      );
    }

    if (
      JSON.stringify(nextProps.proposalsRelevance) !==
        JSON.stringify(this.state.proposalsRelevance) &&
      !isEmptyObject(nextProps.proposalsRelevance)
    ) {
      this.setProposalRelevance(nextProps.proposalsRelevance);
    }
  }

  async calculatePrices() {
    const prices = await api.request(
      `${ENDPOINT_V2}/proposals/${this.state.proposalDetails.id}/pricing`
    );

    if (!isEmpty(prices)) {
      this.setState({
        proposalDetails: assign({}, this.state.proposalDetails, prices.json),
      });
    }
  }

  setProposalRelevance(proposalsRelevance) {
    let thisProposalRelevance;
    let isAvailabilityQueried;

    for (const relevance of proposalsRelevance) {
      // Eventhough its called propertyId we compare it with proposalId as it is set in backend :/
      if (relevance.propertyId === this.state.proposalDetails.id) {
        thisProposalRelevance = relevance.relevance;
        isAvailabilityQueried = relevance.availabilityQueried;
        break;
      }
    }

    this.setState({ thisProposalRelevance, isAvailabilityQueried });
  }

  async calculateInfoPrices() {
    const proposalPrice = get(this.state.proposalDetails, 'price.amount', 0);

    // GET Property price
    const propertyPrice = get(this.state.proposalDetails, 'property.monthlyPrice.amount', 0);
    const propertyUpdateDate = get(this.state.proposalDetails, 'property.updateDate', '');
    const propertyPriceDifferenceWithProposalPrice = propertyPrice - proposalPrice;

    //GET minimum proposal price
    const creationDateFrom = moment(new Date())
      .add(-60, 'days')
      .format('YYYY-MM-DD');
    const creationDateTo = moment(new Date()).format('YYYY-MM-DD');
    const propertyId = get(this.state.proposalDetails, 'property.id', '');

    const propertyProposals = await api.request(
      `${ENDPOINT_V2}/proposals?propertyId=${propertyId}&proposalStatus=OK&creationDateFrom=${creationDateFrom}&creationDateTo=${creationDateTo}`
    );

    const minimumPriceProposal = get(minBy(propertyProposals.json, 'price.amount'), 'price.amount');

    const marketPrice =
      minimumPriceProposal && minimumPriceProposal < proposalPrice
        ? {
            amount: minimumPriceProposal,
            differenceWithProposalPrice: round(minimumPriceProposal - proposalPrice, 2),
          }
        : this.state.marketPrice;

    this.setState({
      propertyPrice: {
        amount: propertyPrice,
        updateDate: propertyUpdateDate,
        differenceWithProposalPrice: round(propertyPriceDifferenceWithProposalPrice, 2),
      },
      marketPrice,
    });
  }

  async calculateIfNegotiable() {
    const newState = {};
    let qqFrequency = get(this.state, 'qualifyingQuestions.frequency');

    if (isNil(qqFrequency)) {
      const companyId = get(this.state.dealDetails, 'companyId');

      try {
        const qualifyingQuestions = await CompanyDetailsApi.getQualifyingQuestions(companyId);

        newState.qualifyingQuestions = qualifyingQuestions;
        qqFrequency = qualifyingQuestions.frequency;
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'Oops!',
          'No se han podido cargar las Qualifying Questions del cliente'
        );
      }
    }

    const budgetType = get(this.state, 'dealDetails.otherRequestInfo.budget');
    const budget = get(this.state.dealDetails, 'budget.amount', 0);
    const proposalPrice = get(this.state.proposalDetails, 'price.amount', 0);
    const propertyPrice = get(this.state.proposalDetails, 'property.monthlyPrice.amount', 0);

    const isNegotiable =
      indexOf(QQ_FREQUENCIES, qqFrequency) > 3 && // Equal or more than 5 travels with is the 4 index in the QQ_FREQUENCIES array
      !isNil(budgetType) &&
      budgetType !== 'AGENT_ESTIMATION' &&
      proposalPrice > budget &&
      propertyPrice <= budget * 1.3;

    if (isNegotiable !== this.state.isNegotiable) {
      newState.isNegotiable = isNegotiable;
    }

    if (!isEmpty(newState)) {
      this.setState(newState);
    }
  }

  componentDidUpdate() {
    if (document.getElementById(`infoButtonProposalDetails${this.props.proposalIndex}`)) {
      if (this.state.showProoposalDetail) {
        document.getElementById(
          `infoButtonProposalDetails${this.props.proposalIndex}`
        ).style.zIndex = 99;
      } else {
        document.getElementById(
          `infoButtonProposalDetails${this.props.proposalIndex}`
        ).style.zIndex = 1;
      }
    }
  }

  showProposalDetail(event) {
    event.preventDefault();
    const showOrHide = !this.state.showProposalDetail;

    this.setState({
      showProposalDetail: showOrHide,
    });
  }

  // Removed availability query by messagebird.
  // Now, whatsapp web by default
  // async handleAvailabilityQuery() {
  //   try {
  //     await api.request(
  //       `${ENDPOINT_V2}/proposals/availabilityQuery/${this.state.proposalDetails.dealId}/${this.state.proposalDetails.property.id}`,
  //       'POST'
  //     );

  //     this.setState({ isAvailabilityQueried: true });
  //     openNotificationWithIcon('success', '¡Consulta enviada con éxito!');
  //   } catch (error) {
  //     console.error(error);
  //     openNotificationWithIcon('error', 'Ha ocurrido un error al consultar disponiblidad');
  //   }
  // }

  render() {
    return (
      <div className={'py-2'}>
        <div
          id={`infoButtonProposalDetails${this.props.proposalIndex}`}
          className='width100PerCent positionRelative py-0'
          onClick={this.showProposalDetail}
          data-proposalId={this.state.proposalDetails && this.state.proposalDetails.id}
        >
          {!isNil(this.state.proposalDetails.rootProposalId) && (
            <div className='proposal-ribbon reused-proposal-ribbon'>
              <span>Propuesta reutilizada</span>
            </div>
          )}
          {!isNil(this.state.isAvailabilityQueried) &&
            !this.state.isAvailabilityQueried &&
            this.state.proposalDetails.proposalStatus !== 'OK' &&
            this.state.proposalDetails.creationMethod === 'AUTOMATIC' && (
              <div className='proposal-ribbon not-queried-ribbon'>
                <span>Disponibilidad no consultada</span>
              </div>
            )}
          <div
            className={
              (this.state.proposalDetails && this.state.proposalDetails.proposalStage === 'REJECTED'
                ? 'rejectedProposalBackgroundColor card card-inverse'
                : 'proposalBackgroundColor card card-inverse') + ' my-0'
            }
          >
            {this.state.isNegotiable && (
              <div className='negotiable-proposal'>
                <i className='fas fa-info-circle mr-2' />
                <span>NEGOCIABLE</span>
              </div>
            )}
            <div className='card-block'>
              <div className='row col-sm-12'>
                <div className='col-sm-3'>
                  <span className='bold fontSizeBig'>
                    {`Propuesta ${this.props.proposalIndex + 1}`}{' '}
                    {` (${getTextValueFromSelectOptions(
                      this.state.proposalDetails.proposalStatus,
                      this.state.options.proposalStatus
                    )})`}
                  </span>
                  <div>
                    {this.state.proposalDetails &&
                      getTextValueFromSelectOptions(
                        this.state.proposalDetails.proposalStage,
                        this.state.options.proposalStage
                      )}
                  </div>
                  <div>
                    <i className='fa fa-calendar' /> Creación:{' '}
                    {this.state.proposalDetails.creationDate &&
                      moment(this.state.proposalDetails.creationDate).format(DATE_FORMAT)}
                  </div>
                  <div>
                    <i className='fa fa-calendar' /> Envío:{' '}
                    {this.state.proposalDetails.shippingDate &&
                      moment(this.state.proposalDetails.shippingDate).format(DATE_FORMAT)}
                  </div>
                  <div>
                    <i className='fa fa-calendar' /> Confirmación de datos:{' '}
                    {this.state.proposalDetails.providerConfirmationDate &&
                      moment(this.state.proposalDetails.providerConfirmationDate).format(
                        DATE_FORMAT
                      )}
                  </div>
                </div>
                <div className='col-sm-7'>
                  {this.state.proposalDetails.title && (
                    <span>
                      <i className='fa fa-home' /> {this.state.proposalDetails.title}
                    </span>
                  )}
                  {!this.state.proposalDetails.title && this.state.proposalDetails.property && (
                    <span>
                      <i className='fa fa-home' /> {this.state.proposalDetails.property.title}
                    </span>
                  )}
                  {this.state.proposalDetails.property && (
                    <div>
                      <i className='fa fa-map-marker' />
                      &nbsp;
                      {this.state.proposalDetails.address &&
                        this.state.proposalDetails.address.formattedAddress}
                      {!this.state.proposalDetails.address &&
                        this.state.proposalDetails.property &&
                        this.state.proposalDetails.property.address &&
                        this.state.proposalDetails.property.address.formattedAddress}
                    </div>
                  )}
                  {this.state.proposalDetails.property && (
                    <div>
                      <i className='fa fa-window-maximize' />{' '}
                      {this.state.proposalDetails.property.rooms}
                      &nbsp; &nbsp; <i className='fa fa-bed' />{' '}
                      {this.state.proposalDetails.property.beds}
                      &nbsp; &nbsp; <i className='fa fa-bath' />{' '}
                      {this.state.proposalDetails.property.bathrooms +
                        this.state.proposalDetails.property.toilets}
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <i className='fa fa-calendar' />{' '}
                      {(this.state.proposalDetails.dateCheckIn
                        ? moment(this.state.proposalDetails.dateCheckIn).format(DATE_FORMAT)
                        : '') +
                        (this.state.proposalDetails.dateCheckOut
                          ? ` - ${moment(this.state.proposalDetails.dateCheckOut).format(
                              DATE_FORMAT
                            )}`
                          : '')}
                    </div>
                  )}
                  <div>
                    <i className='fa fa-money' /> Precio:{' '}
                    {this.state.proposalDetails.paymentType === 'MONTHLY_PAYMENT'
                      ? `${printPrice(this.state.proposalDetails.monthlyPriceDossier)}/mes`
                      : `${printPrice(this.state.proposalDetails.totalPriceDossier)}`}
                    &nbsp; &nbsp; &nbsp; &nbsp; <i className='fa fa-money' /> Fianza:{' '}
                    {this.state.proposalDetails.deposit &&
                      printPrice(this.state.proposalDetails.deposit)}
                    &nbsp; &nbsp; &nbsp; &nbsp; <i className='fa fa-money' /> Limpieza:{' '}
                    {this.state.proposalDetails.cleaningCharges &&
                      printPrice(this.state.proposalDetails.cleaningCharges)}{' '}
                    {getTextValueFromSelectOptions(
                      this.state.proposalDetails.cleaningChargeType,
                      this.state.options.cleaningChargeType
                    )
                      ? getTextValueFromSelectOptions(
                          this.state.proposalDetails.cleaningChargeType,
                          this.state.options.cleaningChargeType
                        )
                      : ''}
                  </div>
                  <div>
                    <i className='fas fa-route' /> Distancia:{' '}
                    {round(this.state.proposalDetails.distance, 2)} km
                  </div>
                </div>
                <div className='col-sm-2 d-flex flex-column align-items-end align-self-end'>
                  {this.state.proposalDetails.creationMethod === 'AUTOMATIC' && (
                    <b>
                      <span>Automática</span>
                    </b>
                  )}
                  {this.state.thisProposalRelevance > 0 && (
                    <b>
                      <span>
                        Relevancia:{' '}
                        {normalizeNumberToDecimals(this.state.thisProposalRelevance * 100, 2)}
                      </span>
                    </b>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-7 offset-sm-3'>
                  <hr style={{ borderColor: 'white' }} />
                  {get(this.state, 'marketPrice.amount') > 0 && (
                    <p className='mb-0'>
                      <i className='fas fa-info-circle mr-2' />
                      Mejor precio (últimos 60 días): {this.state.marketPrice.amount}/€/mes (
                      {this.state.marketPrice.differenceWithProposalPrice}€ respecto a la propuesta)
                    </p>
                  )}
                  <p className='mb-0'>
                    <i className='fas fa-info-circle mr-2' />
                    Precio original del inmueble {
                      this.state.propertyPrice.updateDateFormatted
                    }: {this.state.propertyPrice.amount}/€/mes (
                    {this.state.propertyPrice.differenceWithProposalPrice}€ respecto a la propuesta)
                  </p>
                </div>
              </div>
            </div>
          </div>

          {this.state.showProposalDetail && (
            <div className='openIcon'>
              <i className='fa fa-caret-down fa-2x' />
            </div>
          )}
        </div>
        {this.state.showProposalDetail && (
          <div className='animated fadeIn'>
            <div className='card'>
              <div className='card-block'>
                <FormProposalDetails
                  proposalIndex={this.props.proposalIndex}
                  proposalDetails={this.props.proposalDetails}
                  // handleAvailabilityQuery={this.handleAvailabilityQuery}
                  onAvailabilityQueried={() => this.setState({ isAvailabilityQueried: true })}
                  isAvailabilityQueried={this.state.isAvailabilityQueried}
                />
              </div>
            </div>
          </div>
        )}
        <style jsx>{`
          .proposal-ribbon {
            position: absolute;
            right: -5px;
            top: -25px;
            z-index: 1;
            overflow: hidden;
            width: 180px;
            height: 116px;
            text-align: right;
          }

          .proposal-ribbon span {
            font-size: 11px;
            font-weight: bold;
            color: white;
            text-align: center;
            line-height: 25px;
            width: 240px;
            display: block;
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 35px;
            right: -31px;
            height: 25px;
          }

          .not-queried-ribbon span {
            background: #e74c3c;
          }

          .reused-proposal-ribbon span {
            background: #88ab27;
          }

          .negotiable-proposal {
            height: 30px;
            color: white;
            background: #e74c3c;
            padding-top: 7px;
            padding-left: 10px;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 0.7px;
          }
        `}</style>
      </div>
    );
  }
}

function mapDispatchToProps() {
  return {
    actions: {},
  };
}

function mapStateToProps(state) {
  return {
    dealDetails: state.DealDetails.data.detail,
    optionLabels: state.Preload.optionLabels,
    proposalDetails: state.ProposalDetails.proposalDetails,
    proposalsRelevance: state.DealDetails.proposalsRelevance,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButtonProposalDetails);
