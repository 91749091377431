import React, { useState } from 'react';
import { Col, Row, Typography } from 'antd';
import SupplyDemandFilter from './SupplyDemandFilter';
import { useDispatch, useSelector } from 'react-redux';
import SupplyDemandMap from '../../components/SupplyDemandMap/SupplyDemandMap';
import { normalizeNumberToDecimals } from '../../shared/utils/formUtils';
import api from '../../shared/utils/api';
import { ENDPOINT_V2 } from '../../shared/appConfig';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import { setFilter, setResponse } from '../../modules/SupplyDemand/SupplyDemandActions';

const { Title } = Typography;
const SUPPLY_TYPE = 'SUPPLY_TYPE';
const DEMAND_TYPE = 'DEMAND_TYPE';

const SupplyDemand = () => {
  const { response, filter } = useSelector(state => ({
    response: state.SupplyDemand.response,
    filter: state.SupplyDemand.filter,
  }));
  const dispatch = useDispatch();

  const [clusterInfo, setClusterInfo] = useState({ num: 0, price: '0€' });
  const [clusterType, setClusterType] = useState(SUPPLY_TYPE);
  const [isLoading, setIsLoading] = useState(false);
  const [showSupply, setShowSupply] = useState(true);
  const [showDemand, setShowDemand] = useState(true);

  const handleSubmitFilter = async filter => {
    // const adjustedFilter = adjustFilter(filter);
    dispatch(setFilter(filter));
    if (!isEmpty(filter) && !isLoading) {
      setIsLoading(true);
      const response = await api.request(
        `${ENDPOINT_V2}/properties/cluster-with-opportunities`,
        'POST',
        {
          body: JSON.stringify(adjustFilter(filter)),
        }
      );
      dispatch(setResponse(response.json));
      setIsLoading(false);
    }
  };

  const adjustFilter = filters =>
    mapValues(filters, (value, key) => {
      if (includes(key.toLowerCase(), 'date') && value) {
        return value
          .utc()
          .startOf('day')
          .format('YYYY-MM-DD');
      }

      if (isNil(value) || trim(value) === '') {
        return null;
      }

      return value;
    });

  const onClusterClick = clusterInfo => {
    const clusterType = typeOfCluster(clusterInfo);

    const price =
      clusterType === SUPPLY_TYPE ? clusterInfo.meanMarketMonthlyPrice : clusterInfo.meanBudget;
    let prettyPrice = price === 'NaN' ? '- sin info - ' : normalizeNumberToDecimals(price, 2) + '€';

    setClusterInfo({
      num:
        clusterType === SUPPLY_TYPE
          ? clusterInfo.numberOfProperties
          : clusterInfo.numberOfOpportunities,
      price: prettyPrice,
    });
  };

  const typeOfCluster = clusterInfo => {
    const clusterType = clusterInfo.numberOfOpportunities ? DEMAND_TYPE : SUPPLY_TYPE;
    setClusterType(clusterType);
    return clusterType;
  };

  return (
    <div className='animated fadeIn supply-demand-container'>
      <Row align='middle' gutter={24}>
        <Col xs={24}>
          <Title level={2} className='title'>
            Estudio Oferta/Demanda
            {/* <img src='img/xmas/hat.png' alt='xmas-hat' height={50} /> */}
          </Title>
        </Col>
      </Row>
      <Row className='row-filter'>
        <Col span={24}>
          <SupplyDemandFilter
            filter={filter}
            isLoading={isLoading}
            handleSubmitFilter={filter => handleSubmitFilter(filter)}
          />
        </Col>
      </Row>

      <Row className='row-table hs-row'>
        <Col xs={24}>
          <SupplyDemandMap
            data={response}
            radius={filter.radiusInKm}
            onClusterClick={clusterInfo => onClusterClick(clusterInfo)}
            showSupply={showSupply}
            showDemand={showDemand}
          />
        </Col>
        <div className='cluster-card'>
          <Row
            className='p-4 m-1'
            // style={{
            //   boxShadow: '0 2px 9px 0 rgba(181, 181, 181, 0.5)',
            //   height: tableUtils.getTableHeight() + 130,
            // }}
          >
            <Col>
              <h1 className='cluster-info-line'>{clusterInfo.num}</h1>
              <h4 className='cluster-info-line'>
                {clusterType === SUPPLY_TYPE ? 'inmueble/s' : 'oportunidad/es'}
              </h4>
              <br />
              <br />
              <h1 className='cluster-info-line'>{clusterInfo.price}</h1>
              <h4 className='cluster-info-line'>
                {clusterType === SUPPLY_TYPE ? 'precio/mes medio' : 'presupuesto medio'}
              </h4>
            </Col>
          </Row>
          <Row className='p-4'>
            <Col xs={12} className='d-flex' onClick={() => setShowSupply(!showSupply)}>
              <div
                className={`dot ${
                  showSupply ? 'supply-background-color' : 'deactivate-background-color'
                }`}
              ></div>
              <div style={{ fontSize: '14px' }} className='ml-1'>
                Oferta
              </div>
            </Col>
            <Col xs={12} className='d-flex' onClick={() => setShowDemand(!showDemand)}>
              <div
                className={`dot ${
                  showDemand ? 'demand-background-color' : 'deactivate-background-color'
                }`}
              ></div>
              <div style={{ fontSize: '14px' }} className='ml-1'>
                Demanda
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <style jsx>
        {`
          .supply-demand-container {
            margin: 10px;
          }

          .title {
            margin: 0;
          }

          .row-filter {
            margin-top: 10px;
          }

          .row-table {
            margin-top: 10px;
          }

          .cluster-card {
            min-width: 270px;
            background-color: white;
            position: absolute;
            top: 10px;
            right: 12px;
            box-shadow: 0 2px 9px 0 rgba(181, 181, 181, 0.5);
          }

          .cluster-info-line {
            font-weight: 600;
            margin: 0;
            line-height: 1;
          }

          .dot {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: inline-block;
          }

          .supply-background-color {
            background-color: #23dc00;
          }

          .demand-background-color {
            background-color: #ff0000;
          }

          .deactivate-background-color {
            background-color: #bbb;
          }
        `}
      </style>
    </div>
  );
};

export default SupplyDemand;
