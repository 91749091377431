import { browserHistory } from 'react-router';
import {
  LOGIN_ERROR,
  LOGIN_PROBLEM,
  LOGIN_SUCCESS,
  SESSION_CLOSED,
} from '../../shared/actionTypes';
import LoginControlApi from './LoginControlApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';

export const loginSuccess = () => ({ type: LOGIN_SUCCESS });

export const loginError = () => ({ type: LOGIN_ERROR });

export const loginProblem = error => ({
  type: LOGIN_PROBLEM,
  errorDetails: error.message,
});

export const sessionClosed = () => ({ type: SESSION_CLOSED });

export const logInUser = credentials =>
  function(dispatch) {
    localStorage.setItem('ORIGIN', `${window.location.protocol}//${window.location.hostname}`);

    return LoginControlApi.login(credentials)
      .then(response => {
        localStorage.setItem('jwt', response.data['result']);
        dispatch(loginSuccess());

        const urlBeforeLoginRedirect = localStorage.getItem('urlBeforeLoginRedirect');
        if (urlBeforeLoginRedirect) {
          browserHistory.push(urlBeforeLoginRedirect);
          localStorage.removeItem('urlBeforeLoginRedirect');
        } else {
          browserHistory.push('/');
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(loginProblem(extractErrorMessage(error)));
      });
  };

export const closeSession = () =>
  function(dispatch) {
    localStorage.setItem('jwt', '');
    dispatch(sessionClosed());
    browserHistory.push('/login');
  };
