export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_PROBLEM = 'LOGIN_PROBLEM';
export const SESSION_CLOSED = 'SESSION_CLOSED';
export const USER_INFORMATION_SUCCESS = 'USER_INFORMATION_SUCCESS';

export const METASOURCE_STRINGS_SUCCESS = 'METASOURCE_STRINGS_SUCCESS';
export const METASOURCE_STRINGS_ERROR = 'METASOURCE_STRINGS_ERROR';
export const OPTION_LABELS_SUCCESS = 'OPTION_LABELS_SUCCESS';
export const PARENT_VALUES_SUCCESS = 'PARENT_VALUES_SUCCESS';
export const MAIN_METASOURCE_SUCCESS = 'MAIN_METASOURCE_SUCCESS';

export const PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS =
  'PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS';
export const PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR =
  'PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR';

export const PROVIDERS_METASOURCE_SUCCESS = 'PROVIDERS_METASOURCE_SUCCESS';
export const PROVIDERS_METASOURCE_ERROR = 'PROVIDERS_METASOURCE_ERROR';
export const PROVIDERS_TABLEDATA_SUCCESS = 'PROVIDERS_TABLEDATA_SUCCESS';
export const PROVIDERS_TABLEDATA_ERROR = 'PROVIDERS_TABLEDATA_ERROR';
export const NEW_PROVIDER_LOCATION_SUCCESS = 'NEW_PROVIDER_LOCATION_SUCCESS';
export const NEW_PROVIDER_LOCATION_ERROR = 'NEW_PROVIDER_LOCATION_ERROR';
export const NEW_PROVIDER_CREATE_ERROR = 'NEW_PROVIDER_CREATE_ERROR';

export const PROVIDERDETAILS_RESET = 'PROVIDER_DETAILS_RESET';
export const PROVIDERDETAILS_METASOURCE_SUCCESS = 'PROVIDER_DETAILS_METASOURCE_SUCCESS';
export const PROVIDERDETAILS_METASOURCE_ERROR = 'PROVIDER_DETAILS_METASOURCE_ERROR';
export const PROVIDERDETAILS_DATA_SUCCESS = 'PROVIDERDETAILS_DATA_SUCCESS';
export const PROVIDERDETAILS_DATA_ERROR = 'PROVIDERDETAILS_DATA_ERROR';
export const PROVIDERDETAILS_SUBMIT_SUCCESS = 'PROVIDERDETAILS_SUBMIT_SUCCESS';
export const PROVIDERDETAILS_SUBMIT_ERROR = 'PROVIDERDETAILS_SUBMIT_ERROR';
export const PROVIDERDETAILS_DELETE_SUCCESS = 'PROVIDERDETAILS_DELETE_SUCCESS';
export const PROVIDERDETAILS_DELETE_ERROR = 'PROVIDERDETAILS_DELETE_ERROR';
export const PROPERTYDETAILS_IMAGES_SUBMIT_ERROR = 'PROPERTYDETAILS_IMAGES_SUBMIT_ERROR';
export const PROPERTYDETAILS_PRINTABLE_DATA = 'PROPERTYDETAILS_PRINTABLE_DATA';
export const PROVIDERDETAILS_RESET_CREATE_ERROR = 'PROVIDERDETAILS_RESET_CREATE_ERROR';

export const PROVIDERCONTACTS_DATA_SUCCESS = 'PROVIDERCONTACTS_DATA_SUCCESS';
export const PROVIDERCONTACTS_DATA_ERROR = 'PROVIDERCONTACTS_DATA_ERROR';
export const PROVIDERCONTACT_ETAG_SUCCESS = 'PROVIDERCONTACT_ETAG_SUCCESS';
export const PROVIDERCONTACT_SUBMIT_SUCCESS = 'PROVIDERCONTACT_SUBMIT_SUCCESS';
export const PROVIDERCONTACT_SUBMIT_ERROR = 'PROVIDERCONTACT_SUBMIT_ERROR';
export const NEW_CONTACT_LOCATION_SUCCESS = 'NEW_CONTACT_LOCATION_SUCCESS';
export const NEW_CONTACT_LOCATION_ERROR = 'NEW_CONTACT_LOCATION_ERROR';
export const NEW_CONTACT_CREATE_SUCCESS = 'NEW_CONTACT_CREATE_SUCCESS';
export const NEW_CONTACT_CREATE_ERROR = 'NEW_CONTACT_CREATE_ERROR';
export const PROVIDERBASECONTACTANDCHILDREN_DATA_SUCCESS =
  'PROVIDERBASECONTACTANDCHILDREN_DATA_SUCCESS';
export const PROVIDERBASECONTACTANDCHILDREN_DATA_ERROR =
  'PROVIDERBASECONTACTANDCHILDREN_DATA_ERROR';

export const EVENTS_LIST_SUCCESS = 'EVENTS_LIST_SUCCESS';
export const EVENTS_LIST_ERROR = 'EVENTS_LIST_ERROR';
export const EVENT_COUNTS_SUCCESS = 'EVENT_COUNTS_SUCCESS';
export const EVENT_COUNTS_ERROR = 'EVENT_COUNTS_ERROR';
export const NEW_BUSINESS_EVENT_LOCATION_SUCCESS = 'NEW_BUSINESS_EVENT_LOCATION_SUCCESS';
export const NEW_BUSINESS_EVENT_LOCATION_ERROR = 'NEW_BUSINESS_EVENT_LOCATION_ERROR';
export const CREATE_NEW_BUSINESS_EVENT_SUCCESS = 'CREATE_NEW_BUSINESS_EVENT_SUCCESS';
export const CREATE_NEW_BUSINESS_EVENT_ERROR = 'CREATE_NEW_BUSINESS_EVENT_ERROR';
export const DELETE_BUSINESS_EVENT_SUCCESS = 'DELETE_BUSINESS_EVENT_SUCCESS';
export const DELETE_BUSINESS_EVENT_ERROR = 'DELETE_BUSINESS_EVENT_ERROR';

export const PROPERTIES_METASOURCE_SUCCESS = 'PROPERTIES_METASOURCE_SUCCESS';
export const PROPERTIES_METASOURCE_ERROR = 'PROPERTIES_METASOURCE_ERROR';
export const PROPERTIES_INMAP_FILTERED_SUCCESS = 'PROPERTIES_INMAP_FILTERED_SUCCESS';
export const PROPERTIES_INMAP_FILTERED_ERROR = 'PROPERTIES_INMAP_FILTERED_ERROR';
export const PROPERTIES_BY_DISTANCE_INMAP_FILTERED_SUCCESS =
  'PROPERTIES_BY_DISTANCE_INMAP_FILTERED_SUCCESS';
export const PROPERTIES_BY_DISTANCE_INMAP_FILTERED_ERROR =
  'PROPERTIES_BY_DISTANCE_INMAP_FILTERED_ERROR';
export const PROPERTIES_LIST_SUCCESS = 'PROPERTIES_LIST_SUCCESS';
export const PROPERTIES_LIST_ERROR = 'PROPERTIES_LIST_ERROR';
export const PROPERTIES_ACTIVE_BUSINESS_DATA = 'PROPERTIES_ACTIVE_BUSINESS_DATA';
export const PROPERTIES_EXTERNAL_FILTER = 'PROPERTIES_EXTERNAL_FILTER';
export const PROPERTIES_EXPORTDATA_SUCCESS = 'PROPERTIES_EXPORTDATA_SUCCESS';
export const PROPERTIES_EXPORTDATA_ERROR = 'PROPERTIES_EXPORTDATA_ERROR';
export const PROPERTIES_FILTER = 'PROPERTIES_FILTER';

export const UPLOAD_IMPORT_FILE_SUCCESS = 'UPLOAD_IMPORT_FILE_SUCCESS';
export const UPLOAD_IMPORT_FILE_ERROR = 'UPLOAD_IMPORT_FILE_ERROR';
export const GET_IMPORT_STATUS_SUCCESS = 'GET_IMPORT_STATUS_SUCCESS';
export const GET_IMPORT_STATUS_ERROR = 'GET_IMPORT_STATUS_ERROR';

export const PROPERTYDETAILS_METASOURCE_SUCCESS = 'PROPERTYDETAILS_METASOURCE_SUCCESS';
export const PROPERTYDETAILS_METASOURCE_ERROR = 'PROPERTYDETAILS_METASOURCE_ERROR';
export const PROPERTYDETAILS_DATA_SUCCESS = 'PROPERTYDETAILS_DATA_SUCCESS';
export const PROPERTYDETAILS_DATA_ERROR = 'PROPERTYDETAILS_DATA_ERROR';
export const PROPERTYDETAILS_SUBMIT_SUCCESS = 'PROPERTYDETAILS_SUBMIT_SUCCESS';
export const PROPERTYDETAILS_SUBMIT_ERROR = 'PROPERTYDETAILS_SUBMIT_ERROR';
export const NEW_PROPERTY_LOCATION_SUCCESS = 'NEW_PROPERTY_LOCATION_SUCCESS';
export const NEW_PROPERTY_LOCATION_ERROR = 'NEW_PROPERTY_LOCATION_ERROR';
export const NEW_PROPERTY_CREATE_ERROR = 'NEW_PROPERTY_CREATE_ERROR';
export const CLONE_PROPERTY_SUCCESS = 'CLONE_PROPERTY_SUCCESS';
export const CLONE_PROPERTY_ERROR = 'CLONE_PROPERTY_ERROR';
export const RESET_CLONE_PROPERTY_STATUS = 'RESET_CLONE_PROPERTY_STATUS';
export const PROPOSALS_TABLEDATA_SUCCESS = 'PROPOSALS_TABLEDATA_SUCCESS';
export const PROPOSALS_TABLEDATA_ERROR = 'PROPOSALS_TABLEDATA_ERROR';

export const CONTACTS_METASOURCE_SUCCESS = 'CONTACTS_METASOURCE_SUCCESS';
export const CONTACTS_METASOURCE_ERROR = 'CONTACTS_METASOURCE_ERROR';
export const CONTACTS_TABLEDATA_FILTER = 'CONTACTS_TABLEDATA_FILTER';
export const CONTACTS_TABLEDATA_SUCCESS = 'CONTACTS_TABLEDATA_SUCCESS';
export const CONTACTS_TABLEDATA_ERROR = 'CONTACTS_TABLEDATA_ERROR';
export const CONTACTS_LIST_SUCCESS = 'CONTACTS_LIST_SUCCESS';
export const CONTACTS_LIST_ERROR = 'CONTACTS_LIST_ERROR';

export const CONTACTDETAILS_RESET = 'CONTACTDETAILS_RESET';
export const CONTACTDETAILS_METASOURCE_SUCCESS = 'CONTACTDETAILS_METASOURCE_SUCCESS';
export const CONTACTDETAILS_METASOURCE_ERROR = 'CONTACTDETAILS_METASOURCE_ERROR';
export const CONTACTDETAILS_DATA_SUCCESS = 'CONTACTDETAILS_DATA_SUCCESS';
export const CONTACTDETAILS_DATA_ERROR = 'CONTACTDETAILS_DATA_ERROR';
export const CONTACTDETAILS_SUBMIT_SUCCESS = 'CONTACTDETAILS_SUBMIT_SUCCESS';
export const CONTACTDETAILS_SUBMIT_ERROR = 'CONTACTDETAILS_SUBMIT_ERROR';
export const CONTACTDETAILS_DELETE_SUCCESS = 'CONTACTDETAILS_DELETE_SUCCESS';
export const CONTACTDETAILS_DELETE_ERROR = 'CONTACTDETAILS_DELETE_ERROR';

export const COMPANIES_METASOURCE_SUCCESS = 'COMPANIES_METASOURCE_SUCCESS';
export const COMPANIES_METASOURCE_ERROR = 'COMPANIES_METASOURCE_ERROR';
export const COMPANIES_TABLEDATA_FILTER = 'COMPANIES_TABLEDATA_FILTER';
export const NEW_COMPANY_LOCATION_SUCCESS = 'NEW_COMPANY_LOCATION_SUCCESS';
export const NEW_COMPANY_LOCATION_ERROR = 'NEW_COMPANY_LOCATION_ERROR';
export const NEW_COMPANY_CREATE_ERROR = 'NEW_COMPANY_CREATE_ERROR';

export const COMPANYDETAILS_RESET = 'COMPANY_DETAILS_RESET';
export const COMPANYDETAILS_METASOURCE_SUCCESS = 'COMPANY_DETAILS_METASOURCE_SUCCESS';
export const COMPANYDETAILS_METASOURCE_ERROR = 'COMPANY_DETAILS_METASOURCE_ERROR';
export const COMPANYDETAILS_DATA_SUCCESS = 'COMPANYDETAILS_DATA_SUCCESS';
export const COMPANYDETAILS_DATA_ERROR = 'COMPANYDETAILS_DATA_ERROR';
export const COMPANYDETAILS_SUBMIT_SUCCESS = 'COMPANYDETAILS_SUBMIT_SUCCESS';
export const COMPANYDETAILS_SUBMIT_ERROR = 'COMPANYDETAILS_SUBMIT_ERROR';

export const COMPANYCONTACTS_DATA_SUCCESS = 'COMPANYCONTACTS_DATA_SUCCESS';
export const COMPANYCONTACTS_DATA_ERROR = 'COMPANYCONTACTS_DATA_ERROR';
export const COMPANYCONTACT_ETAG_SUCCESS = 'COMPANYCONTACT_ETAG_SUCCESS';
export const COMPANYCONTACT_SUBMIT_SUCCESS = 'COMPANYCONTACT_SUBMIT_SUCCESS';
export const COMPANYCONTACT_SUBMIT_ERROR = 'COMPANYCONTACT_SUBMIT_ERROR';
export const COMPANYBASECONTACTANDCHILDREN_DATA_SUCCESS =
  'COMPANYBASECONTACTANDCHILDREN_DATA_SUCCESS';
export const COMPANYBASECONTACTANDCHILDREN_DATA_ERROR = 'COMPANYBASECONTACTANDCHILDREN_DATA_ERROR';
export const COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS =
  'COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS';
export const COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR =
  'COMPANY_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR';

export const DEALS_METASOURCE_SUCCESS = 'DEALS_METASOURCE_SUCCESS';
export const DEALS_METASOURCE_ERROR = 'DEALS_METASOURCE_ERROR';
export const DEALS_TABLEDATA_FILTER = 'DEALS_TABLEDATA_FILTER';
export const DEALS_TABLEDATA_SUCCESS = 'DEALS_TABLEDATA_SUCCESS';
export const DEALS_TABLEDATA_ERROR = 'DEALS_TABLEDATA_ERROR';
export const DEALS_LIST_SUCCESS = 'DEALS_LIST_SUCCESS';
export const DEALS_LIST_ERROR = 'DEALS_LIST_ERROR';

export const REQUESTS_METASOURCE_SUCCESS = 'REQUESTS_METASOURCE_SUCCESS';
export const REQUESTS_METASOURCE_ERROR = 'REQUESTS_METASOURCE_ERROR';
export const REQUESTS_TABLEDATA_SUCCESS = 'REQUESTS_TABLEDATA_SUCCESS';
export const REQUESTS_TABLEDATA_ERROR = 'REQUESTS_TABLEDATA_ERROR';
export const REQUESTS_TABLEDATA_FILTER = 'REQUESTS_TABLEDATA_FILTER';

export const DEALDETAILS_DELETE_ERROR = 'DEALDETAILS_DELETE_ERROR';
export const DEALDETAILS_DELETE_SUCCESS = 'DEALDETAILS_DELETE_SUCCESS';
export const DEALDETAILS_FOLLOW_UP_SUCCESS = 'DEALDETAILS_FOLLOW_UP_SUCCESS';
export const DEALDETAILS_FOLLOW_UP_ERROR = 'DEALDETAILS_FOLLOW_UP_ERROR';
export const DEALDETAILS_GENERATE_PROPOSAL_SUCCESS = 'DEALDETAILS_GENERATE_PROPOSAL_SUCCESS';
export const DEALDETAILS_GENERATE_PROPOSAL_ERROR = 'DEALDETAILS_GENERATE_PROPOSAL_ERROR';
export const DEALDETAILS_PROPOSALS_RELEVANCE_SUCCESS = 'DEALDETAILS_PROPOSALS_RELEVANCE_SUCCESS';
export const DEALDETAILS_PROPOSALS_EMAIL_SENT_SUCCESSFULLY =
  'DEALDETAILS_PROPOSALS_EMAIL_SENT_SUCCESSFULLY';
export const DEALDETAILS_RESET_PROPOSALS_EMAIL_SENT_SUCCESSFULLY =
  'DEALDETAILS_RESET_PROPOSALS_EMAIL_SENT_SUCCESSFULLY';
export const NEW_DEAL_LOCATION_SUCCESS = 'NEW_DEAL_LOCATION_SUCCESS';
export const NEW_DEAL_LOCATION_ERROR = 'NEW_DEAL_LOCATION_ERROR';
export const NEW_DEAL_FROM_REQUEST = 'NEW_DEAL_FROM_REQUEST';
export const RENTAL_REQUEST_ACTIVE_STAGES_FOR_FILTER = 'RENTAL_REQUEST_ACTIVE_STAGES_FOR_FILTER';
export const DEALS_RENTAL_REQUEST_STAGE_COUNT_SUCCESS = 'DEALS_RENTAL_REQUEST_STAGE_COUNT_SUCCESS';
export const DEALS_RENTAL_REQUEST_STAGE_COUNT_ERROR = 'DEALS_RENTAL_REQUEST_STAGE_COUNT_ERROR';
export const CALCULATE_DEAL_SCORE_SUCCESS = 'CALCULATE_DEAL_SCORE_SUCCESS';
export const CALCULATE_DEAL_SCORE_ERROR = 'CALCULATE_DEAL_SCORE_ERROR';

export const DEALDETAILS_RESET = 'DEALDETAILS_RESET';
export const DEALDETAILS_METASOURCE_SUCCESS = 'DEALDETAILS_METASOURCE_SUCCESS';
export const DEALDETAILS_METASOURCE_ERROR = 'DEALDETAILS_METASOURCE_ERROR';
export const DEALDETAILS_DATA_SUCCESS = 'DEALDETAILS_DATA_SUCCESS';
export const DEALDETAILS_DATA_ERROR = 'DEALDETAILS_DATA_ERROR';
export const DEALDETAILS_SUBMIT_SUCCESS = 'DEALDETAILS_SUBMIT_SUCCESS';
export const DEALDETAILS_SUBMIT_ERROR = 'DEALDETAILS_SUBMIT_ERROR';
export const DEALDETAILS_BONDED = 'DEALDETAILS_BONDED';
export const CREATE_NEW_DEAL_SUCCESS = 'CREATE_NEW_DEAL_SUCCESS';
export const CREATE_NEW_DEAL_ERROR = 'CREATE_NEW_DEAL_ERROR';

export const PROPOSALS_METASOURCE_SUCCESS = 'PROPOSALS_METASOURCE_SUCCESS';
export const PROPOSALS_METASOURCE_ERROR = 'PROPOSALS_METASOURCE_ERROR';
export const NEW_PROPOSAL_LOCATION_SUCCESS = 'NEW_PROPOSAL_LOCATION_SUCCESS';
export const NEW_PROPOSAL_LOCATION_ERROR = 'NEW_PROPOSAL_LOCATION_ERROR';
export const NEW_PROPOSAL_CREATE_ERROR = 'NEW_PROPOSAL_CREATE_ERROR';
export const PROPOSALS_SUBMIT_SUCCESS = 'PROPOSALS_SUBMIT_SUCCESS';
export const PROPOSALS_SUBMIT_ERROR = 'PROPOSALS_SUBMIT_ERROR';
export const PROPOSALS_SEND_SUCCESS = 'PROPOSALS_SEND_SUCCESS';
export const PROPOSALS_SEND_ERROR = 'PROPOSALS_SEND_ERROR';

export const PROPOSALDETAILS_DATA_SUCCESS = 'PROPOSALDETAILS_DATA_SUCCESS';
export const PROPOSALDETAILS_DATA_ERROR = 'PROPOSALDETAILS_DATA_ERROR';
export const PROPOSALDETAILS_CONTRACTDATA_RESET_ERROR = 'PROPOSALDETAILS_CONTRACTDATA_RESET_ERROR';
export const PROPOSALDETAILS_SUBMITTING = 'PROPOSALDETAILS_SUBMITTING';
export const PROPOSALDETAILS_SUBMIT_SUCCESS = 'PROPOSALDETAILS_SUBMIT_SUCCESS';
export const PROPOSALDETAILS_SUBMIT_ERROR = 'PROPOSALDETAILS_SUBMIT_ERROR';
export const PROPOSALDETAILS_DELETE_ERROR = 'PROPOSALDETAILS_DELETE_ERROR';
export const PROPOSALDETAILS_DELETE_SUCCESS = 'PROPOSALDETAILS_DELETE_SUCCESS';
export const PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_SUCCESS =
  'PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_SUCCESS';
export const PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_ERROR =
  'PROPOSALDETAILS_DATA_PUBLIC_DOSSIER_ERROR';
export const PROPOSALDETAILS_CONTRACTDATA_SUCCESS = 'PROPOSALDETAILS_CONTRACTDATA_SUCCESS';
export const PROPOSALDETAILS_CONTRACTDATA_ERROR = 'PROPOSALDETAILS_CONTRACTDATA_ERROR';

export const INVOICES_METASOURCE_SUCCESS = 'INVOICES_METASOURCE_SUCCESS';
export const INVOICES_METASOURCE_ERROR = 'INVOICES_METASOURCE_ERROR';
export const INVOICES_TABLEDATA_FILTER = 'INVOICES_TABLEDATA_FILTER';
export const INVOICES_TABLEDATA_SUCCESS = 'INVOICES_TABLEDATA_SUCCESS';
export const INVOICES_TABLEDATA_ERROR = 'INVOICES_TABLEDATA_ERROR';
export const NEW_INVOICE_LOCATION_SUCCESS = 'NEW_INVOICE_LOCATION_SUCCESS';
export const NEW_INVOICE_LOCATION_ERROR = 'NEW_INVOICE_LOCATION_ERROR';
export const NEW_INVOICE_CREATE_ERROR = 'NEW_INVOICE_CREATE_ERROR';
export const INVOICES_NEXTINVOICEDATA_SUCCESS = 'INVOICES_NEXTINVOICEDATA_SUCCESS';
export const INVOICES_NEXTINVOICEDATA_ERROR = 'INVOICES_NEXTINVOICEDATA_ERROR';
export const GENERATE_INVOICE_PDF_SUCCESS = 'GENERATE_INVOICE_PDF_SUCCESS';
export const GENERATE_INVOICE_PDF_ERROR = 'GENERATE_INVOICE_PDF_ERROR';

export const INVOICEDETAILS_RESET = 'INVOICEDETAILS_RESET';
export const INVOICEDETAILS_METASOURCE_SUCCESS = 'INVOICEDETAILS_METASOURCE_SUCCESS';
export const INVOICEDETAILS_METASOURCE_ERROR = 'INVOICEDETAILS_METASOURCE_ERROR';
export const INVOICEDETAILS_DATA_SUCCESS = 'INVOICEDETAILS_DATA_SUCCESS';
export const INVOICEDETAILS_DATA_ERROR = 'INVOICEDETAILS_DATA_ERROR';
export const INVOICEDETAILS_SUBMIT_SUCCESS = 'INVOICEDETAILS_SUBMIT_SUCCESS';
export const INVOICEDETAILS_SUBMIT_ERROR = 'INVOICEDETAILS_SUBMIT_ERROR';
export const INVOICEDETAILS_SUBMIT_ENTRIES = 'INVOICEDETAILS_SUBMIT_ENTRIES';

export const PMA_METASOURCE_SUCCESS = 'PMAS_METASOURCE_SUCCESS';
export const PMA_METASOURCE_ERROR = 'PMAS_METASOURCE_ERROR';
export const PMA_AVERAGES_TABLEDATA_SUCCESS = 'PMA_AVERAGES_TABLEDATA_SUCCESS';
export const PMA_AVERAGES_TABLEDATA_ERROR = 'PMA_AVERAGES_TABLEDATA_ERROR';
export const PMA_TABLEDATA_SUCCESS = 'PMA_TABLEDATA_SUCCESS';
export const PMA_TABLEDATA_ERROR = 'PMA_TABLEDATA_ERROR';
export const PMA_CITIES_SUCCESS = 'PMA_CITIES_SUCCESS';
export const PMA_CITIES_ERROR = 'PMA_CITIES_ERROR';
export const PMA_ZIP_CODES_SUCCESS = 'PMA_ZIP_CODES_SUCCESS';
export const PMA_ZIP_CODES_ERROR = 'PMA_ZIP_CODES_ERROR';
export const PMA_APPROXIMATE_SUCCESS = 'PMA_APPROXIMATE_SUCCESS';
export const PMA_APPROXIMATE_ERROR = 'PMA_APPROXIMATE_ERROR';
