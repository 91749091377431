import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

const { Item: FormItem } = Form;

const CompaniesFilter = ({ ...props }) => (
  <Form id='formFilterCompanies' onSubmit={props.handleFilter} layout='vertical'>
    <Row type='flex' justify='start' align='bottom' gutter={8}>
      <Col xxl={4} xl={8} lg={8} md={8} sm={12} xs={24}>
        <FormItem label='Nombre'>
          <Input
            id='filterName'
            name='name'
            placeholder='Benito'
            value={props.filter.name}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} sm={12} xs={24}>
        <FormItem label='Apellidos / Razón social'>
          <Input
            id='filterLastOTradeName'
            name='lastOTradeName'
            placeholder='Camelas'
            value={props.filter.lastOTradeName}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} sm={12} xs={24}>
        <FormItem label='Email'>
          <Input
            type='email'
            id='filterEmail'
            name='email'
            placeholder='luke.skywalker@gmail.com'
            value={props.filter.email}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} sm={12} xs={24}>
        <FormItem label='NIF'>
          <Input
            id='filterIdentificationCode'
            name='identificationCode'
            placeholder='00000000A'
            value={props.filter.identificationCode}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} sm={12} xs={24}>
        <FormItem label='Teléfono'>
          <Input
            id='filterPhoneNumber'
            name='phoneNumber'
            placeholder='666888666'
            value={props.filter.phoneNumber}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={8} lg={8} md={8} sm={12} xs={24}>
        <FormItem>
          <Button
            icon='search'
            className='btn-primary'
            htmlType='submit'
            style={{ width: '100%' }}
            loading={props.loading}
          >
            Filtrar
          </Button>
        </FormItem>
      </Col>
    </Row>
    <br />
    <style jsx>
      {`
        .slide-down-row {
          overflow-x: hidden;
          overflow-y: hidden;
        }
      `}
    </style>
  </Form>
);
export default CompaniesFilter;
