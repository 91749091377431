import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Col, Form, Input, Layout, Row, Skeleton, Switch, Tag } from 'antd';
import ReactGA from 'react-ga';
import moment from 'moment';

import { browserHistory } from 'react-router';
import { extractErrorMessage } from '../../shared/utils/apiUtils';

import { preloadPublicApp } from '../../modules/Preload/PreloadActions';
import { isEmptyObject } from '../../shared/utils/appUtils';
import ProposalDetailsApi from '../../modules/ProposalDetails/ProposalDetailsApi';

import FormAddress from '../HomyFormComponents/FormAddress';
import { DATE_FORMAT, ENDPOINT } from '../../shared/appConfig';

import ElementAntdValidator from '../HomyFormComponents/ElementAntdValidator';
import FormIBAN from '../HomyFormComponents/FormIBAN';
import FormBIC from '../HomyFormComponents/FormBIC';
import FormInput from '../HomyFormComponents/FormInput';
import FormNIF from '../HomyFormComponents/FormNIF';
import FormEmail from '../HomyFormComponents/FormEmail';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';

const { Header, Content, Footer } = Layout;
const FormItem = Form.Item;

class FormProposalProviderInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalDetails: {},
      proposalData: {},
      providerData: {},
      contractSigning: {},
      loading: true,
      originContractSigning: {},
      sameContacts: false,
      submitting: false,
      submitSuccess: false,
      generalConditionsDate: null,
      generalConditionsOrigin: null,
      privacyPolicyDate: null,
      privacyPolicyOrigin: null,
    };
    this.loaded = false;
    ReactGA.initialize('UA-75140213-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.checkGDPR = this.checkGDPR.bind(this);
    this.checkGeneralConditions = this.checkGeneralConditions.bind(this);
  }

  componentDidMount() {
    const { params } = this.props;
    const { providerData, contractSigning } = this.state;

    ProposalDetailsApi.doGet(`${ENDPOINT}/proposal/public/${params.id}`)
      .then(res => {
        const {
          generalConditionsDate,
          generalConditionsOrigin,
          privacyPolicyDate,
          privacyPolicyOrigin,
          proposalBack,
        } = get(res, 'data.publicProviderProposal', {});

        this.setState({
          loading: false,
          proposalDetails: proposalBack,
          showCheck: this.checkContactBaseType(proposalBack),
          propertyAddress: this.setPropertyAddress(proposalBack.property),
          generalConditionsDate,
          generalConditionsOrigin,
          privacyPolicyDate,
          privacyPolicyOrigin,
        });
      })
      .then(() => {
        if (this.state.proposalDetails) {
          const { contactsOfProvider } = this.state.proposalDetails;

          this.checkRoles(contactsOfProvider);
        }
      })
      .then(() => {
        if (
          !isEmptyObject(this.state.providerData) &&
          !isEmptyObject(this.state.contractSigning) &&
          providerData.id === contractSigning.id
        ) {
          this.validateIDFields();
        }
      })
      .catch(err => console.error('Error loading data', err));

    window.scrollTo(0, 0);
  }

  handleSameContacts = areEquals => {
    if (areEquals) {
      this.setSameValues();

      return true;
    }

    return false;
  };

  checkContactBaseType = proposal => {
    try {
      return proposal.property.providerBack.baseContact.contactType === 'PERSON';
    } catch (e) {
      console.error(`Couldn't get Type of Contact Base`);

      return true;
    }
  };

  componentWillUnmount() {
    this.setState({
      providerData: {},
      contractSigning: {},
      proposalDetails: {},
      isSubmitting: false,
      originContractSigning: {},
      gdpr: false,
      submitSuccess: false,
    });
  }

  setPropertyAddress = property => {
    if (property && property.address) {
      const { formattedAddress, addressComplement } = property.address;
      const label = formattedAddress ? `${formattedAddress}.` : '';

      return addressComplement ? label.concat(` [${addressComplement}]`) : label;
    }

    return 'Ninguna dirección establecida.';
  };

  // En ANTD si los campos no son visibles no se pueden obtener. Por lo tanto hay que hacerlo a mano
  getSameValues = () => {
    const { form } = this.props;

    return {
      ...form.getFieldValue('signature'),
      name: form.getFieldValue('landlord').name,
      lastOTradeName: form.getFieldValue('landlord').lastOTradeName,
      address: form.getFieldValue('landlord').address,
      nif: form.getFieldValue('landlord').nif,
      addressComplement: form.getFieldValue('landlord').addressComplement,
    };
  };

  setSameValues = () => {
    this.props.form.setFieldsValue({
      signature: this.getSameValues(),
    });
  };

  setRoles(role, contact) {
    switch (role) {
      case 'LANDLORD': {
        const providerData = {
          ...contact,
          nif: contact.identificationCode,
        };

        this.setState({
          providerData,
        });
        break;
      }

      case 'CONTRACT_SIGNING': {
        const contractSigning = {
          ...contact,
          nif: contact.identificationCode,
          lastOTradeName: contact.lastOTradeName,
          email: this.state.proposalDetails.emailContractSigning,
        };

        const { providerData } = this.state;

        if (providerData.id !== contractSigning.id) {
          this.setState({
            contractSigning,
            originContractSigning: contact,
          });
        } else {
          this.setState({
            contractSigning,
          });
        }

        break;
      }
      default: {
        break;
      }
    }
  }

  handleSameData = checked => {
    if (checked) {
      this.setSameValues();
    } else {
      this.props.form.resetFields('signature');
    }

    this.setState(
      {
        sameContacts: checked,
      },
      () => {
        if (!checked) {
          this.validateIDFields();
        }
      }
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    const { params, form } = this.props;

    if (this.state.sameContacts) {
      this.setSameValues();
    }

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { proposalDetails, sameContacts } = this.state;
        const providerData = Object.assign({}, values.landlord, {
          address: {
            ...values.landlord.address,
            addressComplement: values.landlord.addressComplement,
          },
        });
        const contractSigning = sameContacts
          ? Object.assign({}, this.getSameValues(), {
              address: {
                ...values.signature.address,
                addressComplement: values.signature.addressComplement,
              },
            })
          : Object.assign({}, values.signature, {
              address: {
                ...values.signature.address,
                addressComplement: values.signature.addressComplement,
              },
            });

        const proposal = Object.assign({}, proposalDetails, {
          providerData,
          contractSigning,
          gdpr: values.agreement,
          acceptedGeneralConditions: values.acceptedGeneralConditions,
          acceptedPrivacyPolicy: values.agreement,
        });

        this.setState(
          {
            isSubmitting: true,
            proposalDetails: proposal,
          },
          () => {
            ProposalDetailsApi.doPut(
              `${ENDPOINT}/proposal/public/${params.id}/providerInfo`,
              proposal
            )
              .then(() => {
                browserHistory.push('/thankYou');
              })
              .catch(error => {
                console.error(error);
                browserHistory.push({
                  pathname: '/thankYou',
                  search: `?message=${extractErrorMessage(error)}`,
                });
              });
          }
        );
      }
    });
  };

  checkGDPR = (rule, value, callback) => {
    if (!value) {
      callback('Por favor, acepte los términos de Política de Privacidad y Protección de Datos.');
    }

    callback();
  };

  checkGeneralConditions = (rule, value, callback) => {
    if (!value) {
      callback('Por favor, acepte los términos de Condiciones Generales.');
    }

    callback();
  };

  handleFormChange = () => {
    const { form } = this.props;
    const isSwitchMarked = this.state.showCheck;

    if (!isSwitchMarked) {
      const landlordNIF = form.getFieldValue('landlord.nif');
      const signatureNIF = form.getFieldValue('signature.nif');

      if (landlordNIF && signatureNIF) {
        form.validateFields(['landlord.nif', 'signature.nif'], (err, values) => {
          if (!err && values.landlord.nif === values.signature.nif) {
            this.setSameValues();
          }
        });
      }
    }
  };

  validateIDFields = () => {
    this.props.form.validateFields(['landlord.nif', 'signature.nif']);
  };

  compareToLandlordNIF = (rule, value, callback) => {
    if (value) {
      if (
        (rule.field.indexOf('signature') > -1 &&
          value === this.props.form.getFieldValue(`landlord.nif`)) ||
        (rule.field.indexOf('landlord') > -1 &&
          value === this.props.form.getFieldValue(`signature.nif`))
      ) {
        this.setState(
          {
            sameId: true,
          },
          () => {
            this.setSameValues();
            callback();
          }
        );
      } else {
        this.setState(
          {
            sameId: false,
          },
          () => callback()
        );
      }

      return false;
    }

    callback();
  };

  checkRoles(contactsOfProvider) {
    if (contactsOfProvider) {
      contactsOfProvider.forEach(contact => {
        if (
          (contact.roles && contact.roles.includes('LANDLORD')) ||
          contact.roles.includes('CONTRACT_SIGNING')
        ) {
          if (contact.roles.includes('LANDLORD') && contact.roles.includes('CONTRACT_SIGNING')) {
            this.setState({
              sameContacts: true,
            });
          }

          contact.roles.forEach(role => this.setRoles(role, contact.contact));
        }
      });
    }
  }

  render() {
    const {
      contractSigning,
      loading,
      propertyAddress,
      providerData,
      sameContacts,
      generalConditionsDate,
      generalConditionsOrigin,
      privacyPolicyDate,
      privacyPolicyOrigin,
    } = this.state;

    const responsive = {
      header: {
        xs: 0,
        sm: 0,
        md: 0,
        lg: 12,
        xl: 10,
        xxl: 8,
      },
      content: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
        xxl: 12,
      },
      submit: {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 8,
        xl: 8,
        xxl: 8,
      },
    };

    const hasAcceptedGeneralConditions =
      isNumber(generalConditionsDate) && !isEmpty(generalConditionsOrigin);

    const hasAcceptedPrivacyPolicy = isNumber(privacyPolicyDate) && !isEmpty(privacyPolicyOrigin);

    return (
      <Layout className='layout' style={{ background: 'white' }}>
        <Header style={{ background: 'white' }}>
          <Row>
            <Col>
              <div
                className='logoHs'
                style={{
                  width: '550px',
                  height: '80px',
                  float: 'left',
                }}
              />
            </Col>
            <Col {...responsive.header} style={{ float: 'right' }}>
              <b>¿Necesitas asistencia?</b>&nbsp;&nbsp;
              <i className='fa fa-phone' />
              &nbsp;
              <a href='tel:96+113+44+14'>
                <b>96 113 44 14</b>
              </a>
              &nbsp;&nbsp;
              <i className='fa fa-envelope-o' />
              &nbsp;
              <a href='mailto:reservas@homyspace.com'>
                <b>reservas@homyspace.com</b>
              </a>
            </Col>
          </Row>
        </Header>

        <Content style={{ padding: '3% 15% 3% 15%', maxWidth: '100%', fontFamily: 'Open sans' }}>
          <div
            style={{
              background: '#fff',
              padding: 24,
              minHeight: 280,
              border: '1px solid lightgray',
            }}
            className='contactBorderTopColor'
          >
            <Row>
              <Col
                className='positionRelative'
                style={{ backgroundColor: 'white', color: 'black', paddingBottom: '1.25rem' }}
              >
                <h2 style={{ fontFamily: 'Open sans' }}>
                  <b>Datos para el contrato de alquiler</b>
                </h2>
                Rellena estos datos para tramitar el contrato de alquiler
              </Col>
            </Row>
            <br />
            <Row gutter={32}>
              <Skeleton loading={loading} active>
                <div>
                  <Col {...responsive.content}>
                    <label htmlFor='propertyAddress'>
                      <strong>Dirección del Inmueble</strong>
                      <Input
                        className='form-control'
                        id='propertyAddress'
                        name='propertyAddress'
                        placeholder='Sin dirección del inmueble'
                        readOnly
                        type='text'
                        value={propertyAddress}
                      />
                    </label>
                    <br />
                  </Col>
                  <Col {...responsive.content}>
                    <label htmlFor='dealIdentificationCode'>
                      <strong>Código de la reserva</strong>
                      <Input
                        className='form-control'
                        id='dealIdentificationCode'
                        readOnly
                        type='text'
                        placeholder='Sin código de reserva.'
                        value={
                          (this.state.proposalDetails &&
                            this.state.proposalDetails.dealIdentificationCode) ||
                          ''
                        }
                      />
                    </label>
                  </Col>
                </div>
              </Skeleton>
            </Row>
            <br />
            <br />
            <Form
              layout='vertical'
              onSubmit={this.handleSubmit}
              onChange={this.handleFormChange}
              name='ProviderForm'
            >
              <Row gutter={32}>
                <div className='col-sm-12'>
                  <h4 style={{ color: 'black' }}>
                    <b>Datos del Arrendador</b>
                  </h4>
                  <hr className='hs-hr' />
                </div>
                <Skeleton loading={loading} active>
                  <div>
                    <Col {...responsive.content}>
                      <FormInput
                        fieldDecorator='landlord.name'
                        form={this.props.form}
                        isRequired
                        name='name'
                        label={(() => {
                          switch (providerData.contactType) {
                            case 'PERSON':
                              return 'Nombre';
                            case 'COMPANY':
                              return 'Nombre comercial';
                            default:
                              return 'Nombre';
                          }
                        })()}
                        initialValue={providerData.name}
                      />
                      <FormAddress
                        fieldDecorator='landlord.address'
                        form={this.props.form}
                        id='providerDataAddress'
                        isRequired={false}
                        label='Domicilio'
                        name='address'
                        initialValue={providerData.address}
                        whitespace={false}
                      />
                      <FormInput
                        fieldDecorator='landlord.addressComplement'
                        form={this.props.form}
                        name='addressComplement'
                        placeholder='Número de puerta, apartamento, escalera, piso, etc.'
                        initialValue={
                          providerData.address && providerData.address.addressComplement
                            ? providerData.address.addressComplement
                            : null
                        }
                      />
                    </Col>
                    <Col {...responsive.content}>
                      <FormInput
                        fieldDecorator='landlord.lastOTradeName'
                        form={this.props.form}
                        isRequired
                        label={(() => {
                          switch (providerData.contactType) {
                            case 'PERSON':
                              return 'Apellidos';
                            case 'COMPANY':
                              return 'Razón Social';
                            default:
                              return 'Apellidos / Razón Social';
                          }
                        })()}
                        name='lastOTradeName'
                        initialValue={providerData.lastOTradeName}
                      />
                      <FormNIF
                        fieldDecorator='landlord.nif'
                        form={this.props.form}
                        isRequired
                        label={(() => {
                          switch (providerData.contactType) {
                            case 'PERSON':
                              return 'DNI';
                            case 'COMPANY':
                              return 'NIF';
                            default:
                              return 'DNI o NIF';
                          }
                        })()}
                        name='nif'
                        validator={this.compareToLandlordNIF}
                        initialValue={providerData.nif}
                      />
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <br />
              <p style={{ color: 'black' }}>
                <b>Datos de pago</b>
              </p>
              <Row gutter={32}>
                <Skeleton loading={loading} active>
                  <div>
                    <Col {...responsive.content}>
                      <FormIBAN
                        fieldDecorator='landlord.accountNumber'
                        form={this.props.form}
                        isRequired
                        initialValue={providerData.accountNumber}
                      />
                      <FormInput
                        fieldDecorator='landlord.beneficiary'
                        form={this.props.form}
                        isRequired
                        label='Beneficiario de la cuenta'
                        name='beneficiary'
                        initialValue={providerData.beneficiary}
                      />
                    </Col>
                    <Col {...responsive.content}>
                      <FormBIC
                        fieldDecorator='landlord.bicOrSwift'
                        form={this.props.form}
                        isRequired
                        initialValue={providerData.bicOrSwift}
                      />
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <Row gutter={32}>
                <div className='col-sm-12'>
                  <h4 style={{ color: 'black' }}>
                    <b>Datos del firmante del contrato</b>
                  </h4>
                  <hr className='hs-hr' />
                  {this.state.showCheck && (
                    <div style={{ marginBottom: '10px' }}>
                      <label htmlFor='sameContactsCheck'>
                        <strong style={{ color: 'black' }}>
                          {' '}
                          ¿Es la misma persona que el Arrendador?
                        </strong>
                      </label>{' '}
                      <Switch
                        checked={sameContacts}
                        checkedChildren='Si'
                        id='sameContactsCheck'
                        onChange={this.handleSameData}
                        unCheckedChildren='No'
                        value={sameContacts}
                      />
                    </div>
                  )}
                </div>
                <Skeleton loading={loading} active>
                  <div>
                    <Col {...responsive.content}>
                      <div className={sameContacts ? 'hideElement' : ''}>
                        <FormInput
                          form={this.props.form}
                          isDisabled={this.state.sameId}
                          isRequired
                          label={(() => {
                            switch (contractSigning.contactType) {
                              case 'PERSON':
                                return 'Nombre';
                              case 'COMPANY':
                                return 'Nombre comercial';
                              default:
                                return 'Nombre';
                            }
                          })()}
                          name='name'
                          initialValue={contractSigning.name}
                          fieldDecorator='signature.name'
                        />
                      </div>
                      <FormInput
                        fieldDecorator='signature.jobTitle'
                        form={this.props.form}
                        isRequired
                        label='Cargo'
                        name='jobTitle'
                        initialValue={contractSigning.jobTitle}
                      />
                      <div className={sameContacts ? 'hideElement' : ''}>
                        <FormAddress
                          fieldDecorator='signature.address'
                          form={this.props.form}
                          id='contractSigningAddress'
                          isDisabled={this.state.sameId}
                          isRequired={false}
                          label='Domicilio'
                          name='address'
                          initialValue={contractSigning.address}
                          whitespace={false}
                        />
                        <FormInput
                          fieldDecorator='signature.addressComplement'
                          form={this.props.form}
                          isDisabled={this.state.sameId}
                          name='addressComplement'
                          placeholder='Número de puerta, apartamento, escalera, piso, etc.'
                          initialValue={
                            contractSigning.address && contractSigning.address.addressComplement
                              ? contractSigning.address.addressComplement
                              : null
                          }
                        />
                      </div>
                    </Col>
                    <Col {...responsive.content}>
                      <div className={sameContacts ? 'hideElement' : ''}>
                        <FormInput
                          fieldDecorator='signature.lastOTradeName'
                          form={this.props.form}
                          isDisabled={this.state.sameId}
                          isRequired
                          label={(() => {
                            switch (contractSigning.contactType) {
                              case 'PERSON':
                                return 'Apellidos';
                              case 'COMPANY':
                                return 'Razón Social';
                              default:
                                return 'Apellidos';
                            }
                          })()}
                          name='lastOTradeName'
                          initialValue={contractSigning.lastOTradeName}
                        />
                        <FormNIF
                          fieldDecorator='signature.nif'
                          form={this.props.form}
                          help={
                            this.state.sameId
                              ? 'Atención: El NIF de la persona con Capacidad de Firma y Arrendador son el mismo. Se utilizarán los datos del Arrendador.'
                              : ''
                          }
                          isDisabled={this.state.sameId}
                          isRequired
                          label={(() => {
                            switch (contractSigning.contactType) {
                              case 'PERSON':
                                return 'DNI';
                              case 'COMPANY':
                                return 'NIF';
                              default:
                                return 'DNI';
                            }
                          })()}
                          name='nif'
                          validator={this.compareToLandlordNIF}
                          initialValue={contractSigning.nif}
                        />
                      </div>
                      <FormEmail
                        fieldDecorator='signature.email'
                        form={this.props.form}
                        isRequired
                        label='Correo electrónico'
                        name='email'
                        type='email'
                        initialValue={contractSigning.email}
                      />
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <Row>
                <Skeleton loading={loading} active>
                  <div>
                    <Col>
                      <h4 style={{ color: 'black' }}>
                        <b>
                          Aceptación Política de Privacidad, Protección de Datos y Condiciones
                          generales
                        </b>
                      </h4>
                      <Row>
                        <Col>
                          <div style={{ margin: '15px 0px' }}>
                            <ElementAntdValidator
                              fieldDecorator='agreement'
                              form={this.props.form}
                              validator={this.checkGDPR}
                              isRequired
                              valuePropName='checked'
                              type='boolean'
                              value={hasAcceptedPrivacyPolicy}
                              isRequiredMessage='Por favor, acepte los términos de Política de Privacidad y Protección de Datos.'
                              style={{ display: 'inline-block', margin: 0, padding: 0 }}
                            >
                              <Checkbox disabled={hasAcceptedGeneralConditions}>
                                Acepto la Política de Privacidad y Protección de Datos.{' '}
                                <a
                                  href='https://www.homyspace.com/politica-privacidad-proteccion-datos/'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{ textDecoration: 'underline' }}
                                >
                                  Ver política de privacidad y protección de datos.
                                </a>
                              </Checkbox>
                            </ElementAntdValidator>
                            {hasAcceptedPrivacyPolicy && (
                              <Tag>
                                <b>Aceptadas: {moment(privacyPolicyDate).format(DATE_FORMAT)}</b>
                              </Tag>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div>
                            <ElementAntdValidator
                              fieldDecorator='acceptedGeneralConditions'
                              form={this.props.form}
                              validator={this.checkGeneralConditions}
                              isRequired
                              valuePropName='checked'
                              type='boolean'
                              value={hasAcceptedGeneralConditions}
                              isRequiredMessage='Por favor, acepte los términos de Condiciones Generales.'
                              style={{ display: 'inline-block', margin: 0, padding: 0 }}
                            >
                              <Checkbox disabled={hasAcceptedGeneralConditions}>
                                Acepto la Condiciones generales.{' '}
                                <a
                                  href='https://www.homyspace.com/condiciones-generales/'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{ textDecoration: 'underline' }}
                                >
                                  Ver condiciones generales.
                                </a>
                              </Checkbox>
                            </ElementAntdValidator>
                            {hasAcceptedGeneralConditions && (
                              <Tag>
                                <b>
                                  Aceptadas: {moment(generalConditionsDate).format(DATE_FORMAT)}
                                </b>
                              </Tag>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <br />
              <Row type='flex' justify='center'>
                <Col {...responsive.submit}>
                  <FormItem>
                    <Button
                      className='btn btn-primary btn-block'
                      disabled={this.state.isSubmitting}
                      htmlType='submit'
                      type='primary'
                    >
                      Confirmar Datos
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>

        <Footer
          style={{
            textAlign: 'center',
            background: 'white',
            borderTop: '1px solid lightgray',
            color: 'grey',
            fontSize: '12px',
            fontFamily: 'Open Sans',
          }}
        >
          <span className='copyright'>
            <b>Homyspace Business Housing S.L. @ {new Date().getFullYear()}</b>
          </span>
        </Footer>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    mainMetasource: state.Preload.mainMetasource,
    proposalData: state.ProposalDetails.data,
    submit: state.ProposalDetails.submit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      preloadPublicApp: () => dispatch(preloadPublicApp()),
      // getProposalDetailsForPublicForm: proposalId =>
      //   dispatch(getProposalDetailsForPublicForm(proposalId)),
      // saveProviderInfo: (info, url) => saveProviderInfo(info, url),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FormProposalProviderInfo));
