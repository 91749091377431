import React, { Component } from 'react';
import FormContactDetails from '../FormContactDetails';
import { browserHistory, withRouter } from 'react-router';
import get from 'lodash/get';

// PARAMETROS A CONFIGURAR
// leftOffset -> columnas bootstrap a desplazer el botón
// withSelfLink -> boolean to indicate if we need to follow the self link

class InfoButtonContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactDetail: false,
      contact: props.contact,
    };
    this.showContactDetail = this.showContactDetail.bind(this);
    this.showContactDetailsView = this.showContactDetailsView.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.contact) !== JSON.stringify(newProps.contact)) {
      this.setState({
        contact: newProps.contact,
      });
    }

    if (document.getElementById('infoButtonContactDetails')) {
      if (this.state.showContactDetail) {
        document.getElementById('infoButtonContactDetails').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonContactDetails').style.zIndex = 1;
      }
    }
  }

  // componentDidMount() {
  //   this.loadContactData();
  // }

  // loadContactData = async (contactId = '') => {
  //   const id = get(this.props, 'params.id') || contactId;

  //   if (!id) {
  //     openNotificationWithIcon(
  //       'error',
  //       'Oops!',
  //       'No se ha podido obtener los datos del contacto'
  //     );
  //     return;
  //   }

  //   try {
  //     const details = await api.getDetailData('contact', id);
  //     this.setState({
  //       contact: {
  //         data: details.json.contact,
  //         etag: replace(details.headers.get('etag'), /"/g, ''),
  //       },
  //     });
  //   } catch (e) {
  //     console.error('Error a la hora de obtener datos del contacto', e);
  //   }
  // };

  // componentDidUpdate() {
  // if (document.getElementById('infoButtonContactDetails')) {
  //   if (this.state.showContactDetail) {
  //     document.getElementById('infoButtonContactDetails').style.zIndex = 99;
  //   } else {
  //     document.getElementById('infoButtonContactDetails').style.zIndex = 1;
  //   }
  // }
  // }

  showContactDetail(event) {
    if (event.target.nodeName !== 'A') {
      this.setState(prevState => ({
        showContactDetail: !prevState.showContactDetail,
      }));
    }
  }

  showContactDetailsView(event) {
    event.preventDefault();

    const { contact } = this.state;
    const id = get(contact, 'data.id');

    if (id) {
      browserHistory.push(`/contactDetails/${id}`);
    }
  }

  render() {
    const { contact, showContactDetail: isVisible } = this.state;
    const details = get(contact, 'data');

    return (
      <div id='infoButtonContactDetails' className='col-sm-5 noPadding'>
        <div
          className='card card-inverse contactBackgroundColor infoButton'
          onClick={this.showContactDetail}
        >
          {details && (
            <div className='card-block'>
              <div className='row'>
                <div className='col-sm-2 fontSizeMax alignCenter'>
                  {details.contactType === 'COMPANY' && (
                    <i className='fa fa-building p-1 font-3xl mr-1' />
                  )}
                  {details.contactType === 'PERSON' && (
                    <i className='fa fa-user p-1 font-3xl mr-1' />
                  )}
                  {!details.contactType && <i className='far fa-meh-blank p-1 font-3xl mr-1' />}
                </div>
                <div className='col-sm-10 floatLeft noPadding'>
                  <div className='h4 m-0 bold linkBehavior' onClick={this.showContactDetailsView}>
                    {details.name}
                    <br />
                    {details.lastOTradeName || ''}
                  </div>
                  <div>{get(details, 'worldWideAddress.formattedAddress') || ''}</div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-2 floatLeft noPadding' />
                <div className='col-sm-5 floatLeft noPadding fontSizeMedium'>
                  {details.email && (
                    <div>
                      <i className='fa fa-envelope' aria-hidden='true' />{' '}
                      <a className='whiteLink' href={`mailto:pruebas@homyspace.com`}>
                        {details.email}
                      </a>
                    </div>
                  )}
                  {details.email2 && (
                    <div>
                      <i className='fa fa-envelope' aria-hidden='true' />{' '}
                      <a className='whiteLink' href={`mailto:pruebas@homyspace.com`}>
                        {details.email2}
                      </a>
                    </div>
                  )}
                </div>
                <div className='col-sm-5 floatLeft noPadding fontSizeMedium'>
                  {details.phoneNumber && (
                    <div>
                      <i className='fa fa-phone' aria-hidden='true' />{' '}
                      <a className='whiteLink' href={`tel:${details.phoneNumber}`}>
                        {details.phoneNumber}
                      </a>
                    </div>
                  )}
                  {details.mobilePhoneNumber && (
                    <div>
                      <i className='fa fa-mobile' aria-hidden='true' /> &nbsp;{' '}
                      <a className='whiteLink' href={`tel:${details.mobilePhoneNumber}`}>
                        {details.mobilePhoneNumber}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {isVisible && (
                <div className='openIcon'>
                  <i className='fa fa-caret-down fa-2x' />
                </div>
              )}
            </div>
          )}
        </div>

        {isVisible && (
          <div className='infoButtonDetail cardNoMargin noPadding animated fadeIn contactBorderTopColor'>
            <div className='card'>
              <div className='card-block'>
                <FormContactDetails contact={contact} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(InfoButtonContactDetails);
