import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Tooltip } from 'antd';
import { openNotificationWithIcon, RENTAL_REQUEST_STAGES } from '../../shared/utils/appUtils';
import { ENDPOINT_V2 } from '../../shared/appConfig';
import api from '../../shared/utils/api';

// VARS
const oneDayToCheckout = 'oneDayToCheckout';
const twoDaysToMonthlyPayment = 'twoDaysToMonthlyPayment';
const qualityProvider = 'qualityProvider';
const ERROR_MESSAGE =
  'La Oportunidad debe estar en estado Alquiler confirmado" o “Histórico convertidas“ y la propuesta en etapa Aceptada.';
const DATE_CHECKOUT_ERROR_MESSAGE = 'El alquiler aún no ha finalizado.';

const OportunityRentalHasBeenOccuped = rentalRequestStage => {
  const acceptedStates = [RENTAL_REQUEST_STAGES.S050_RENTAL_CONFIRMED];

  return acceptedStates.includes(rentalRequestStage);
};

const proposalHasBeenAccepted = proposalStage => {
  return proposalStage === 'ACCEPTED';
};

const isFeedbackEndpoint = endpoint => {
  const acceptedEndpoints = [qualityProvider];

  return acceptedEndpoints.includes(endpoint);
};

const proposalDateCheckOutHasPassed = proposalDateCheckOut => {
  return proposalDateCheckOut < Date.now();
};

const submitEmail = async (
  { proposalStage, rentalRequestStage, proposalId, proposalDateCheckOut },
  { setErrorMessage, setButtonDisabled },
  endpoint
) => {
  const url = `${ENDPOINT_V2}/proposals/${proposalId}/${endpoint}`;

  if (
    !OportunityRentalHasBeenOccuped(rentalRequestStage) ||
    !proposalHasBeenAccepted(proposalStage)
  ) {
    setErrorMessage(ERROR_MESSAGE);
    return;
  }

  if (isFeedbackEndpoint(endpoint) && !proposalDateCheckOutHasPassed(proposalDateCheckOut)) {
    setErrorMessage(DATE_CHECKOUT_ERROR_MESSAGE);

    return;
  }

  setErrorMessage(false);
  setButtonDisabled(true);

  try {
    await api.request(url, 'post');
    setButtonDisabled(false);
    openNotificationWithIcon(
      'success',
      'Todo OK :)',
      'Se ha enviado el correo satisfactoriamente.'
    );
  } catch (e) {
    setButtonDisabled(false);
    openNotificationWithIcon(
      'error',
      'Oops 💀',
      `Ha ocurrido un prolema a la hora de realizar la acción... ${e.message}`
    );
  }
};

const FormProposalAfterSales = ({
  proposalStage,
  rentalRequestStage,
  proposalId,
  proposalDateCheckOut,
}) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const proposalInfo = { proposalStage, rentalRequestStage, proposalId, proposalDateCheckOut };
  const stateHandlers = { setErrorMessage, setButtonDisabled };

  return (
    <div className='row'>
      <div className='col-sm-12'>
        {errorMessage && <Alert message={errorMessage} type='error' className='mb-4' showIcon />}

        <Tooltip
          placement='bottom'
          title='Este correo deberá enviarse 24 horas antes de la fecha de salida de la PROPUESTA'
        >
          <div style={{ display: 'inline-block' }}>
            <button
              disabled={buttonDisabled}
              type='button'
              className='btn btn-primary mr-4'
              onClick={submitEmail.bind(this, proposalInfo, stateHandlers, oneDayToCheckout)}
            >
              Devolución de llaves y fianza
            </button>
          </div>
        </Tooltip>

        <Tooltip
          placement='bottom'
          title='Se enviará antes de finalizar cada mes, dos días antes hasta fin de alquiler'
        >
          <div style={{ display: 'inline-block' }}>
            <button
              disabled={buttonDisabled}
              type='button'
              className='btn btn-primary mr-4'
              onClick={submitEmail.bind(this, proposalInfo, stateHandlers, twoDaysToMonthlyPayment)}
            >
              Pago mensualidad
            </button>
          </div>
        </Tooltip>
        <Tooltip
          placement='bottom'
          title='Se enviará el correo 1 día después de la fecha de salida del inmueble en la PROPUESTA.'
        >
          <div style={{ display: 'inline-block' }}>
            <button
              disabled={buttonDisabled}
              type='button'
              className='btn btn-primary mr-4'
              onClick={() => submitEmail(proposalInfo, stateHandlers, qualityProvider)}
            >
              Feedback proveedor
            </button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

FormProposalAfterSales.propTypes = {
  proposalStage: PropTypes.string,
  rentalRequestStage: PropTypes.string,
  proposalId: PropTypes.string,
  proposalDateCheckOut: PropTypes.number,
};

export default FormProposalAfterSales;
