import React from 'react';
import { normalizePhoneNumber } from '../../../shared/utils/formUtils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';
import capitalize from 'lodash/capitalize';
import toUpper from 'lodash/toUpper';
import { getOptionsForSelect, setKeyLabelESToLabel } from '../../../shared/utils/appUtils';
import { Row, Col, Radio, Form, Input, Select, Alert } from 'antd';
import SelectLazy from '../../../components/SelectContact/SelectLazy';
import InformationBlock from '../../../components/InformationBlock/InformationBlock';

const { Item: FormItem } = Form;

const NOOP = () => {};

const addContactCreationDate = (baseContact = {}) =>
  isEmpty(baseContact)
    ? {
        creationDate: new Date(),
        updateDate: new Date(),
      }
    : baseContact;

const getLastOrTradeNameLabel = (type = '') => {
  if (type === 'PERSON') {
    return 'Apellidos';
  }

  if (type === 'COMPANY') {
    return 'Razón Social';
  }

  return 'Apellidos/Razón Social';
};

const ClientInfo = ({
  deal: {
    companyId = undefined,
    companyDetails: {
      companyType = '',
      // marketingSourceMedium = '',
      baseContact: {
        name = '',
        lastOTradeName: surname = '',
        email = '',
        phoneNumber: phone = '',
        observations = '',
        identificationCode = '',
      } = {},
    } = {},
    contactDetails: { name: contactName = '', lastOTradeName: contactSurname = '' } = {},
    isContact = false,
    marketingSourceMedium = '',
  } = {},
  options = {},
  invoiceOptions = {},
  setDeal = NOOP,
  // isEditable = true,
}) => {
  const companyTypeOptions = !isEmpty(options) ? getOptionsForSelect(options, 'CompanyType') : null;
  const invoiceLabels = !isEmpty(invoiceOptions) ? setKeyLabelESToLabel(invoiceOptions) : null;

  const setBaseContactClientDeal = (name, value) => {
    setDeal(prev => ({
      ...prev,
      companyDetails: {
        ...prev.companyDetails,
        baseContact: {
          ...addContactCreationDate(get(prev, 'companyDetails.baseContact')),
          [name]: value,
        },
      },
    }));
  };

  return (
    <Col className='border-component p-2' data-testid='client-form'>
      <Row>
        <Col>
          <label className='mt-1'>
            <b className='title-component'>Cliente</b>
          </label>
          <div style={{ marginBottom: 10 }} />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormItem label='Nombre del Cliente'>
            <SelectLazy
              name='companyId'
              type='contact'
              filter='&provOrComp=COMPANY'
              value={companyId}
              onChange={(id, data) => {
                const isContact = !isEmpty(get(data, 'parent'));
                if (id) {
                  setDeal(prev => ({
                    ...prev,
                    companyId: isContact ? get(data, 'parent') : id,
                    contactId: isContact ? id : prev.contactId,
                    isContact,
                    marketingSourceMedium: get(data, 'marketingSourceMedium', ''),
                  }));
                } else {
                  setDeal(prev => ({
                    ...prev,
                    companyId: null,
                    contactId: null,
                    isContact: false,
                    companyDetails: {},
                    contactDetails: {},
                    marketingSourceMedium: '',
                    questions: {
                      travelManagement: 'EXTERNALLY',
                      frequency: 'ONE_TO_TWO',
                    },
                  }));
                }
              }}
            />
            {isContact && (
              <Alert
                message={
                  <span>
                    La empresa <b>{`${contactName || ''} ${contactSurname || ''}`}</b> es filial de{' '}
                    <b>{`${name || ''} ${surname || ''}`}</b>
                  </span>
                }
                type='warning'
                style={{ marginTop: '5px' }}
              ></Alert>
            )}
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <FormItem label='Tipo de persona*'>
            <Radio.Group
              value={companyType}
              style={{ width: '100%' }}
              size='large'
              className='company-type'
              buttonStyle='solid'
              // disabled={!isEditable}
              required
              onChange={e =>
                setDeal(prev => ({
                  ...prev,
                  companyDetails: {
                    ...prev.companyDetails,
                    companyType: e.target.value,
                  },
                }))
              }
            >
              {!isEmpty(companyTypeOptions) &&
                companyTypeOptions.map(type => (
                  <Radio.Button
                    key={`radio-${type.value}`}
                    value={type.value}
                    style={{ width: '100%' }}
                  >
                    {capitalize(replace(type.label, 'Persona ', ''))}
                  </Radio.Button>
                ))}
            </Radio.Group>
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <FormItem label='Fuente / Medio'>
            <Select
              name='marketingSourceMedium'
              allowClear
              showSearch
              value={marketingSourceMedium}
              style={{ width: '100%' }}
              onChange={value =>
                setDeal(prev => ({
                  ...prev,
                  marketingSourceMedium: value,
                  // companyDetails: {
                  //   ...prev.companyDetails,
                  //   marketingSourceMedium: value,
                  // },
                }))
              }
            >
              {!isEmpty(invoiceLabels) &&
                invoiceLabels.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
            </Select>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormItem label='Nombre*'>
            <Input
              allowClear
              placeholder='Compañia de luz'
              name='name'
              value={name}
              // disabled={!isEditable}
              required
              className='validate validate-required'
              onChange={e => setBaseContactClientDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormItem label={getLastOrTradeNameLabel(companyType)}>
            <Input
              allowClear
              name='lastOTradeName'
              placeholder='Luces de marte S.A.'
              // disabled={!isEditable}
              value={surname}
              onChange={e => setBaseContactClientDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormItem label='Email'>
            <Input
              allowClear
              type='email'
              name='email'
              value={email}
              placeholder='pruebas@marte.com'
              // disabled={!isEditable}
              onChange={e => setBaseContactClientDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormItem label='Teléfono'>
            <Input
              allowClear
              type='tel'
              name='phoneNumber'
              value={phone}
              placeholder='961234567'
              // disabled={!isEditable}
              onChange={e =>
                setBaseContactClientDeal(e.target.name, normalizePhoneNumber(e.target.value))
              }
            />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <FormItem label='NIF'>
            <Input
              allowClear
              name='identificationCode'
              value={identificationCode}
              placeholder='B12345678'
              onChange={e => setBaseContactClientDeal(e.target.name, toUpper(e.target.value))}
              required={marketingSourceMedium === 'OUTBOUND_SALES'}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className='pl-2 pt-2'>
          <InformationBlock label='Si rellenas el NIF vas a tener mejores resultados con las <b>Propuestas Intelingentes</b>' />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormItem label='Observaciones'>
            <Input.TextArea
              // allowClear
              value={observations}
              name='observations'
              autoSize={{ minRows: 3 }}
              placeholder='Introduce el resto de información relevante sobre el cliente aquí.'
              // disabled={!isEditable}
              onChange={e => setBaseContactClientDeal(e.target.name, e.target.value)}
            />
          </FormItem>
        </Col>
      </Row>
      <style jsx>{`
        .ant-radio-button-wrapper-checked {
          box-shadow: -1px 0 0 0 #eeaf30 !important;
        }

        .company-type {
          display: inline-flex !important;
        }

        .ant-radio-group-solid
          .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          color: black !important;
          background: #eeaf30 !important;
          border-color: #eeaf30 !important;
        }

        .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
          background-color: #eeaf30 !important;
          border-color: #eeaf30 !important;
          opacity: 0.7 !important;
        }

        .ant-form-explain {
          padding-top: 2px !important;
          font-size: 12px !important;
        }
      `}</style>
    </Col>
  );
};
export default ClientInfo;
