/* eslint-disable react/display-name */
import React, { useMemo, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { Alert, Table } from 'antd';
import moment from 'moment';
import { normalizeNumberToDecimals } from '../../shared/utils/formUtils';
import { getPropertyList } from '../../modules/Properties/PropertiesActions.js';
import { DATE_FORMAT } from '../../shared/appConfig.js';
import get from 'lodash/get';
import map from 'lodash/map';
import { getMetasourceLink } from '../../shared/utils/appUtils';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// PROPERTIES TO BE CONFIGURED IN PARENT
// handlePropertyListChange (OPTIONAL) -> function to be called when the propertyList change

const TableDataProviderProperties = ({ handlePropertyListChange }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const { metasource, metasourceStrings, propertyList } = useSelector(
    state => ({
      propertyList: state.Properties.propertyList.list,
      metasource: state.ProviderDetails.metasource,
      metasourceStrings: state.Preload.metasourceStrings,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isLoading) {
      const urlPropertyList = getMetasourceLink(
        metasource,
        metasourceStrings,
        'PROVIDER_DETAIL_METASOURCE_PROPERTY_LIST'
      );

      dispatch(getPropertyList(urlPropertyList));
      setIsLoading(false);
    }
  }, [dispatch, isLoading, metasource, metasourceStrings]);

  const normalizedPropertyList = useMemo(() => {
    const result = map(propertyList, entry => ({
      ...entry,
      property: {
        ...entry.property,
        monthlyPrice: normalizeNumberToDecimals(get(entry, 'property.monthlyPrice.amount', 0), 2),
      },
    }));

    if (handlePropertyListChange) {
      handlePropertyListChange(result);
    }

    return result;
  }, [handlePropertyListChange, propertyList]);

  const columns = [
    {
      title: 'Dirección',
      dataIndex: 'property.address.formattedAddress',
      sorter: (a, b) =>
        get(a, 'property.address.formattedAddress', '') <
        get(b, 'property.address.formattedAddress', '')
          ? -1
          : get(a, 'property.address.formattedAddress', '') >
            get(b, 'property.address.formattedAddress', '')
          ? 1
          : 0,
      render: text => <a href=''>{text}</a>,
      onCellClick: (record, event) => {
        event.preventDefault();
        browserHistory.push(`/propertyDetails/${record.property.id}`);
      },
    },
    {
      title: 'Título',
      // width: 200,
      dataIndex: 'property.title',
      sorter: (a, b) =>
        get(a, 'property.title', '') < get(b, 'property.title', '')
          ? -1
          : get(a, 'property.title', '') > get(b, 'property.title', '')
          ? 1
          : 0,
      render: text => <a href=''>{text}</a>,
      onCellClick: (record, event) => {
        event.preventDefault();
        browserHistory.push(`/propertyDetails/${record.property.id}`);
      },
    },
    {
      title: <span className='numericColumnTitle'>Precio mensual</span>,
      dataIndex: 'property.monthlyPrice',
      // width: 100,
      sorter: (a, b) =>
        get(a, 'property.monthlyPrice', '') < get(b, 'property.monthlyPrice', '')
          ? -1
          : get(a, 'property.monthlyPrice', '') > get(b, 'property.monthlyPrice', '')
          ? 1
          : 0,
    },
    {
      title: <span className='numericColumnTitle'>Habitaciones</span>,
      dataIndex: 'property.rooms',
      // width: 150,
      sorter: (a, b) =>
        get(a, 'property.rooms', '') < get(b, 'property.rooms', '')
          ? -1
          : get(a, 'property.rooms', '') > get(b, 'property.rooms', '')
          ? 1
          : 0,
      // render: text => <div style={{ textAlign: 'right' }}>{text}</div>,
    },
    {
      title: <span className='numericColumnTitle'>Camas</span>,
      dataIndex: 'property.beds',
      // width: 100,
      sorter: (a, b) =>
        get(a, 'property.beds', '') < get(b, 'property.beds', '')
          ? -1
          : get(a, 'property.beds', '') > get(b, 'property.beds', '')
          ? 1
          : 0,
      // render: text => <div style={{ textAlign: 'right' }}>{text}</div>,
    },
    {
      title: <span className='numericColumnTitle'>Sofás cama</span>,
      dataIndex: 'property.sofaBed',
      // width: 100,
      sorter: (a, b) =>
        get(a, 'property.sofaBed', '') < get(b, 'property.sofaBed', '')
          ? -1
          : get(a, 'property.sofaBed', '') > get(b, 'property.sofaBed', '')
          ? 1
          : 0,
    },
    {
      title: 'Fecha incorporación',
      dataIndex: 'property.incorporationDate',
      // width: 150,
      sorter: (a, b) =>
        new Date(a.property.incorporationDate) < new Date(b.property.incorporationDate)
          ? -1
          : new Date(a.property.incorporationDate) > new Date(b.property.incorporationDate)
          ? 1
          : 0,
      render: date => moment(new Date(date)).format(DATE_FORMAT),
    },
  ];

  return (
    <div className='animated fadeIn'>
      {normalizedPropertyList && normalizedPropertyList.error && (
        <Alert
          message='Error'
          description={normalizedPropertyList.errorDetail}
          type='error'
          showIcon
          closable
        />
      )}
      {normalizedPropertyList && (
        <Table
          size='medium'
          // scroll={{ y: scrollY, x: scrollX }}
          pagination={{ pageSize: 15 }}
          dataSource={normalizedPropertyList}
          columns={columns}
          rowKey={rec => rec.property.id}
          loading={isLoading}
        />
      )}
    </div>
  );
};

export default TableDataProviderProperties;
