/*
  Special attention:
  getFieldDecorator can not be used to decorate stateless component.
  https://ant.design/components/form/
*/
/* eslint-disable react/prefer-stateless-function */

import { Form } from 'antd';
import React, { Component } from 'react';

import PropTypes from 'prop-types';

const FormItem = Form.Item;

class ElementAntdValidator extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    fieldDecorator: PropTypes.string.isRequired,
    form: PropTypes.shape({}).isRequired,
    help: PropTypes.string,
    isRequired: PropTypes.bool,
    isRequiredMessage: PropTypes.string,
    isWrongMessage: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    validator: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ]),
    valuePropName: PropTypes.string.isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {
    help: undefined,
    isRequired: false,
    isRequiredMessage: 'Por favor, introduce la información necesaria.',
    isWrongMessage: 'El valor introducido no es correcto.',
    label: undefined,
    type: 'string',
    value: '',
    style: {},
  };

  render() {
    const {
      children,
      fieldDecorator,
      form,
      help,
      isRequired,
      isRequiredMessage,
      isWrongMessage,
      label,
      type,
      validator,
      value,
      valuePropName,
      style,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <FormItem label={label} help={help} style={style}>
        {getFieldDecorator(fieldDecorator, {
          initialValue: value,
          valuePropName,
          rules: [
            {
              type,
              message: isWrongMessage,
            },
            {
              required: isRequired,
              message: isRequiredMessage,
            },
            {
              validator,
            },
          ],
        })(children)}
      </FormItem>
    );
  }
}

export default Form.create({
  mapPropsToFields(props) {
    return {
      [props.fieldDecorator]: Form.createFormField({
        ...props,
        value: props.value,
      }),
    };
  },
})(ElementAntdValidator);
