import React from 'react';
import PropTypes from 'prop-types';
import { Col, DatePicker, Input, InputNumber, Row } from 'antd';
import CheckboxButton from '../CheckboxButton/CheckboxButton';
import HomySelect from '../HomyComponents/HomySelect';
import { DATE_FORMAT } from '../../shared/appConfig';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { normalizeNumberToDecimals } from '../../shared/utils/formUtils';
import moment from 'moment';
import { getDays } from '../../shared/utils/dateUtils';

const NOOP = () => {};

const GenerateProposalDefaultInfo = ({ ...props }) => {
  const checkIn = get(props, 'data.dateCheckIn') || get(props, 'bondedDeal.rentalStartDate');
  const checkOut = get(props, 'data.dateCheckOut') || get(props, 'bondedDeal.rentalEndDate');
  const tenants = get(props, 'data.tenants') || get(props, 'bondedDeal.numberOfPeople');
  const days = !isNil(checkIn) && !isNil(checkOut) ? getDays(checkIn, checkOut) : null;

  const cleaningCharges = get(
    props,
    'data.cleaningCharges.amount',
    get(props, 'data.cleaningCharges')
  );
  const spendingLimit = get(props, 'data.spendingLimit.amount', get(props, 'data.spendingLimit'));

  return (
    <>
      <Row gutter={12} className='hs-row'>
        <Col span={8}>
          <label htmlFor='printableDataDateFrom'>Fecha de entrada</label>
          <DatePicker
            className='hs-date-picker'
            data-testid='checkIn'
            id='printableDataDateFrom'
            name='dateCheckIn'
            placeholder='dd/mm/yyyy'
            value={checkIn && moment(checkIn)}
            format={DATE_FORMAT}
            onChange={moment =>
              props.handleChange({
                target: {
                  name: 'dateCheckIn',
                  value: moment ? moment.valueOf() : null,
                },
              })
            }
          />
        </Col>
        <Col span={8}>
          <label htmlFor='printableDataDateTo'>Fecha de salida</label>
          <DatePicker
            className='hs-date-picker'
            data-testid='checkOut'
            id='printableDataDateTo'
            name='dateCheckOut'
            placeholder='dd/mm/yyyy'
            value={checkOut && moment(checkOut)}
            format={DATE_FORMAT}
            onChange={moment =>
              props.handleChange({
                target: {
                  name: 'dateCheckOut',
                  value: moment ? moment.valueOf() : null,
                },
              })
            }
          />
        </Col>
        <Col span={8}>
          <label htmlFor='printableDataDays'>Noches de estancia</label>
          <Input
            type='number'
            className='noBorder'
            disabled
            id='printableDataDays'
            name='days'
            placeholder=''
            min='0'
            value={days}
          />
        </Col>
      </Row>
      <Row gutter={12} className='hs-row'>
        <Col span={8}>
          <label htmlFor='printableDataTenants'>Num. de inquilinos</label>
          <InputNumber
            style={{ width: '100%' }}
            id='printableDataTenants'
            name='tenants'
            placeholder='0'
            min={0}
            value={tenants}
            onChange={value =>
              props.handleChange({
                target: {
                  name: 'tenants',
                  value,
                },
              })
            }
          />
        </Col>
        <Col span={8}>
          <label>&nbsp;</label>
          <CheckboxButton
            borderStyle='primaryCheckboxActive'
            id='printableDataChargesIncluded'
            name='chargesIncluded'
            handleChange={props.handleChange}
            content='Suministros incluidos'
            value={props.data.chargesIncluded}
          />
        </Col>
        <Col span={8}>
          <label htmlFor='printableDataSpendingLimit'>
            {props.data.chargesIncluded
              ? 'Límite de suministros (€)'
              : 'Importe fijo suministros (€)'}
          </label>
          <InputNumber
            id='printableDataSpendingLimit'
            name='spendingLimit'
            style={{ width: '100%' }}
            min={0}
            placeholder={props.data.chargesIncluded ? 'Sin límite' : 'No incluido'}
            value={normalizeNumberToDecimals(spendingLimit, 2) || ''}
            onChange={value =>
              props.handleChange({
                target: {
                  name: 'spendingLimit',
                  value: {
                    amount: value,
                    currency: 'EUR',
                  },
                },
              })
            }
          />
        </Col>
      </Row>
      <Row gutter={12} className='hs-row'>
        <Col span={8}>
          <label htmlFor='cleaningCharges'>Gastos de limpieza/mes</label>
          <InputNumber
            style={{ width: '100%' }}
            id='cleaningCharges'
            name='cleaningCharges'
            placeholder='0'
            min={0}
            value={cleaningCharges}
            onChange={value =>
              props.handleChange({
                target: {
                  name: 'cleaningCharges',
                  value: {
                    amount: value,
                    currency: 'EUR',
                  },
                },
              })
            }
          />
        </Col>
        <Col span={8}>
          <HomySelect
            label='Periodicidad de limpieza'
            name='cleaningChargeType'
            value={props.data.cleaningChargeType}
            options={props.options.cleaningChargeType}
            onChange={value => {
              props.handleChange({
                target: {
                  name: 'cleaningChargeType',
                  value,
                  type: 'select',
                  mode: 'default',
                },
              });
            }}
            showArrow
            showSearch
            allowClear
          />
        </Col>
        {props.data.rentalRequestType === 'A' && (
          <Col span={8}>
            <HomySelect
              label='Motivo de la propuesta'
              name='proposalCreationReason'
              value={props.data.proposalCreationReason}
              options={props.options.proposalCreationReason}
              onChange={value => {
                props.handleChange({
                  target: {
                    name: 'proposalCreationReason',
                    value,
                    type: 'select',
                    mode: 'default',
                  },
                });
              }}
              showArrow
              showSearch
              allowClear
            />
          </Col>
        )}
      </Row>
      <style jsx>
        {`
          .hs-date-picker {
            width: 100%;
          }

          .hs-row {
            margin-bottom: 15px;
          }
        `}
      </style>
    </>
  );
};

GenerateProposalDefaultInfo.propTypes = {
  handleChange: PropTypes.func,
  normalizeNumberToDecimals: PropTypes.func,
  data: PropTypes.shape({
    chargesIncluded: PropTypes.bool,
    spendingLimit: PropTypes.number,
    days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    numberOfPeople: PropTypes.number,
    cleaningCharges: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cleaningChargeType: PropTypes.string,
    proposalCreationReason: PropTypes.string,
  }),
  options: PropTypes.object,
};
GenerateProposalDefaultInfo.defaultProps = {
  handleChange: NOOP,
  normalizeNumberToDecimals: NOOP,
  data: {
    chargesIncluded: false,
    spendingLimit: 0,
    days: '',
    numberOfPeople: 0,
    cleaningCharges: '',
    cleaningChargeType: '',
    proposalCreationReason: '',
  },
  options: {},
};

export default GenerateProposalDefaultInfo;
