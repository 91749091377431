import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { CONTACT_ROLES } from './appUtils';
import isEmpty from 'lodash/isEmpty';

// export const isUsualContact = contact => {
//   let roles = get(contact, 'relatedProvider.roles');

//   if (!roles) {
//     roles = get(contact, 'relatedCompany.roles');
//   }

//   // deal contactsOfCompany case
//   if (!roles) {
//     roles = get(contact, 'roles');
//   }

//   return roles ? roles.includes(CONTACT_ROLES.USUAL) : false;
// };

// export const getUsualContact = contactList => {
//   if (!isNil(contactList)) {
//     for (let contact of contactList) {
//       if (contact.contact) {
//         // For de duality in contact arrays that we manage
//         contact = contact.contact;
//       }
//       if (isUsualContact(contact)) {
//         return contact;
//       }
//     }
//   }
// };

export const isUsualContact = contact => {
  let roles = get(contact, 'roles');
  if (!roles) {
    roles = get(contact, 'relatedProvider.roles');
  }
  if (!roles) {
    roles = get(contact, 'relatedCompany.roles');
  }

  return roles ? roles.includes(CONTACT_ROLES.USUAL) : false;
};

export const getUsualContact = contactList => {
  if (!isNil(contactList)) {
    for (let contact of contactList) {
      if (isUsualContact(contact) || isUsualContact(contact.contact)) {
        if (contact.contact) {
          // For de duality in contact arrays that we manage
          return contact.contact;
        }
        return contact;
      }
    }
  }
};

export const getPropertyUsualContact = (
  propertyUsualContact,
  providerBaseContact,
  providerContactList
) => {
  const providerUsualContact = getUsualContact(providerContactList);
  if (!isEmpty(propertyUsualContact)) {
    return propertyUsualContact;
  } else if (!isEmpty(providerUsualContact)) {
    return providerUsualContact;
  } else {
    return providerBaseContact;
  }
};
