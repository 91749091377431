import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { getOptionsForSelect, getTextValueFromSelectOptions } from '../../shared/utils/appUtils';
import get from 'lodash/get';
import { DATE_FORMAT } from '../../shared/appConfig';
import { browserHistory } from 'react-router';

class TableDataProposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposals: get(this.props, 'proposals', []),
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      sorter: '',
      loading: true,
      options: {
        proposalStatus: getOptionsForSelect(this.props.optionLabels, 'ProposalStatus'),
        proposalStage: getOptionsForSelect(this.props.optionLabels, 'ProposalStage'),
      },
    };

    this.showDealDetails = this.showDealDetails.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.proposals) !== JSON.stringify(newProps.proposals)) {
      this.setState({
        proposals: newProps.proposals,
        loading: false,
      });
    }
  }

  sortData(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  showDealDetails(dealId) {
    browserHistory.push(`/dealDetails/${dealId}`);
  }

  render() {
    const columns = [
      {
        title: 'Oportunidad',
        dataIndex: 'dealIdentificationCode',
        sorter: (a, b) => this.sortData(a, b),
        render: text => <a href=''>{text}</a>,
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showDealDetails(record.dealId);
        },
      },
      {
        title: 'Tipo de oportunidad',
        dataIndex: 'dealRentalRequestType',
        width: 50,
        sorter: (a, b) =>
          this.sortData(get(a, 'dealRentalRequestType'), get(b, 'dealRentalRequestType')),
      },
      {
        title: 'Número de inquilinos',
        dataIndex: 'tenants',
        width: 50,
        sorter: (a, b) => this.sortData(get(a, 'tenants'), get(b, 'tenants')),
      },
      {
        title: 'Precio / mes',
        dataIndex: 'price.amount',
        sorter: (a, b) => this.sortData(get(a, 'price.amount'), get(b, 'price.amount')),
        render: record => (record ? numeral(record).format('0.00') : ''),
      },
      {
        title: 'Impuesto aplicado',
        dataIndex: 'taxType',
        sorter: (a, b) => this.sortData(get(a, 'taxType'), get(b, 'taxType')),
        render: val => (val === 'NO_TAX' ? 'Sin impuestos' : val),
      },
      {
        title: '% Impuesto',
        dataIndex: 'taxValue',
        sorter: (a, b) => this.sortData(get(a, 'taxValue'), get(b, 'taxValue')),
        render: val => (val <= 0 ? '-' : `${val}%`),
      },
      {
        title: 'Tipo de impuesto',
        sorter: (a, b) =>
          this.sortData(
            get(a, 'plusTax')
              ? '+ Impuesto'
              : get(a, 'taxExempt')
              ? 'Exento'
              : get(a, 'taxIncluded')
              ? 'Impuestos incluidos'
              : '',
            get(b, 'plusTax')
              ? '+ Impuesto'
              : get(b, 'taxExempt')
              ? 'Exento'
              : get(b, 'taxIncluded')
              ? 'Impuestos incluidos'
              : ''
          ),
        render: obj => {
          if (get(obj, 'plusTax')) {
            return '+ Impuesto';
          }

          if (get(obj, 'taxExempt')) {
            return 'Exento';
          }

          if (get(obj, 'taxIncluded')) {
            return 'Impuestos incluidos';
          }
        },
      },
      {
        title: 'Cliente',
        dataIndex: 'dealCompanyName',
        sorter: (a, b) => this.sortData(get(a, 'dealCompanyName'), get(b, 'dealCompanyName')),
      },
      {
        title: 'Estado',
        dataIndex: 'proposalStatus',
        sorter: (a, b) => this.sortData(get(a, 'proposalStatus'), get(b, 'proposalStatus')),
        render: val => getTextValueFromSelectOptions(val, this.state.options.proposalStatus),
      },
      {
        title: 'Etapa',
        dataIndex: 'proposalStage',
        sorter: (a, b) => this.sortData(get(a, 'proposalStage'), get(b, 'proposalStage')),
        render: val => getTextValueFromSelectOptions(val, this.state.options.proposalStage),
      },
      {
        title: 'Fecha de envío',
        dataIndex: 'shippingDate',
        sorter: (a, b) => this.sortData(get(a, 'shippingDate'), get(b, 'shippingDate')),
        render: val => (val ? moment(val).format(DATE_FORMAT) : '-'),
      },
      {
        title: 'Gestor',
        dataIndex: 'dealHsAgent.username',
        sorter: (a, b) =>
          this.sortData(get(a, 'dealHsAgent.username'), get(b, 'dealHsAgent.username')),
      },
    ];

    return (
      <div className='animated fadeIn'>
        <Table
          size='small'
          dataSource={get(this.state, 'proposals')}
          columns={columns}
          rowKey={rec => rec.id}
          loading={this.state.loading}
          pagination={{ pageSize: 15 }}
        />
        <span>
          El precio mostrado es el precio de alquiler mensual sin contar ni impuestos ni gastos
          extra
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, null)(TableDataProposals);
