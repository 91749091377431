import React from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { DATE_FORMAT } from '../../shared/appConfig';
import moment from 'moment';
import { hsAgents } from '../../shared/utils/agentUtils';
import isEmpty from 'lodash/isEmpty';

const { Item: FormItem } = Form;

const FollowUpsFilter = ({ ...props }) => (
  <Form onSubmit={props.handleFilter} layout='vertical'>
    <Row gutter={8} type='flex' justify='start' align='bottom'>
      <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Código'>
          <Input
            id='identificationCode'
            name='identificationCode'
            placeholder='XXXX-YYYY'
            value={props.filter.identificationCode}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      {/* <Col xxl={8} xl={6} lg={12} md={12} sm={24} xs={24}>
        <FormItem label='Cliente'>
          <SelectCompany
            name='companyId'
            id='companyId'
            style={{ width: '100%' }}
            value={props.filter.companyId || ''}
            isRequired={false}
            handleChange={props.handleSelectChangeOfSearch}
            extraClassName='select-company-filter'
          />
        </FormItem>
      </Col> */}
      <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Gestor asociado'>
          <Select
            name='agent'
            value={props.filter.agent}
            allowClear
            style={{ width: '100%' }}
            placeholder='carlos.lopez@homyspace.com'
            onChange={value => {
              props.handleChangeOnFilterConditions({
                target: {
                  name: 'agent',
                  value: value || '',
                },
              });
            }}
          >
            {!isEmpty(hsAgents) &&
              hsAgents.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
      </Col>
      <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Fecha tarea'>
          <DatePicker
            style={{ width: '100%' }}
            placeholder='dd/mm/yyyy'
            value={props.filter.date ? moment(props.filter.date) : null}
            format={DATE_FORMAT}
            onChange={(moment, dateString) =>
              props.handleDateChangeOnFilterConditions('date', moment, dateString)
            }
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
        <FormItem>
          <Button
            htmlType='submit'
            icon='search'
            className='btn-primary filter-buttons'
            loading={props.loading}
          >
            Filtrar
          </Button>
        </FormItem>
      </Col>
    </Row>
    <style jsx>
      {`
         {
          /* .Select {
          height: 66px !important;
          margin-left: 6px !important;
        }

        div.Select-control {
          border: 1px solid #d9d9d9 !important;
          border-radius: 4px !important;
          -webkit-transition: all 0.3s !important;
          transition: all 0.3s !important;
        }

        div.Select-control:hover,
        div.Select-control:active,
        div.Select-control:focus {
          border-color: #f8dda6 !important;
        }

        .Select-input {
          height: 30px !important;
        } */
        }

        label[for='companyId'] {
          display: none !important;
        }

        .switch-request {
          margin-left: 10px !important;
        }

        .filter-buttons {
          width: 100% !important;
        }

        .slide-down-row {
          overflow-x: hidden;
          overflow-y: hidden;
        }

        .select-company-filter {
          margin-top: 18px;
          height: 38px;
        }
      `}
    </style>
  </Form>
);
export default FollowUpsFilter;
