import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Typography } from 'antd';

import TableDataDeals from '../../components/TableDataDeals';
import { filterTableDataDeals, getNewDealLocation } from '../../modules/Deals/DealsActions';
import {
  getOptionsForSelect,
  openNotificationWithIcon,
  parseArrayToStringForFilter,
  parseStringValuesToComboValue,
} from '../../shared/utils/appUtils';
import FilterButtonsDeal from '../../components/FilterButtonsDeal/FilterButtonsDeal';
import DealsFilter from './DealsFilter';
import api from '../../shared/utils/api';
import tableUtils from '../../shared/utils/tableUtils';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';

const { Title } = Typography;

export class Deals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        data: [],
      },
      filter: {
        ...props.filter,
        hsagent:
          !isEmpty(props.filter) && !isNil(props.filter.hsagent)
            ? props.filter.hsagent
            : props.currentUsername,
        rentalRequestStages:
          !isEmpty(props.filter) && !isNil(props.filter.rentalRequestStages)
            ? props.filter.rentalRequestStages
            : parseStringValuesToComboValue(
                this.props.defaultRequestStageFilter,
                getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage')
              ),

        firstRequest: get(props, 'filter.firstRequest', undefined),
        recurrentRequest: !isNil(get(props, !'filter.firstRequest', undefined)),
        showMoreFilters: false,
      },
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      sorter: '',
      loading: true,
      options: {
        rentalRequestStages: getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage'),
        rentalRequestType: getOptionsForSelect(this.props.optionLabels, 'RentalRequestType'),
      },
    };

    this.loadTableData = this.loadTableData.bind(this);
    this.handleChangeOnFilterConditions = this.handleChangeOnFilterConditions.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.sorterData = this.sorterData.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectChangeOfSearch = this.handleSelectChangeOfSearch.bind(this);
    this.createNewDeal = this.createNewDeal.bind(this);
    this.handleFirstRequestChange = this.handleFirstRequestChange.bind(this);
    this.handleDateChangeOnFilterConditions = this.handleDateChangeOnFilterConditions.bind(this);
    this.handleFilterCollapse = this.handleFilterCollapse.bind(this);
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (JSON.stringify(oldProps.filter) !== JSON.stringify(newProps.filter)) {
      this.setState(
        {
          loading: true,
          filter: {
            ...newProps.filter,
          },
        },
        () => {
          this.loadTableData();
        }
      );
    }
  }

  handleFilterCollapse() {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        showMoreFilters: !prevState.filter.showMoreFilters,
      },
    }));
  }

  handlePaginationChange = (page, pageSize) => {
    this.setState(
      prevState => ({
        loading: true,
        pagination: {
          ...prevState.pagination,
          pageSize,
          page,
        },
      }),
      () => this.loadTableData()
    );
  };

  loadTableData = () => {
    const { pagination, sorter } = this.state;
    const filters = this.filterData();

    const params = {
      pagination,
      filters,
      sorter: sorter || '-creationDate',
    };

    api
      .getTableDataV2('opportunities', params)
      .then(res => {
        this.setState({
          loading: false,
          table: {
            data: get(res, 'json.searchItems', []),
          },
          pagination: {
            ...pagination,
            totalCount: get(res, 'json.totalHits', 0),
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de procesar datos en las Oportunidades... ${err.message}`
        );
      });
  };

  sorterData = sorter => {
    const condition = replace(
      tableUtils.getSortCondition(sorter, 'deal'),
      'hsagent.username',
      'hsagent'
    );

    this.setState(
      {
        sorter: condition,
        loading: true,
      },
      () => this.loadTableData()
    );
  };

  handleFilter = e => {
    e.preventDefault();

    const { pagination } = this.state;

    this.setState(
      {
        loading: true,
        pagination: {
          ...pagination,
          page: 1,
        },
      },
      () => {
        this.props.actions.filterTableDataDeals(this.state.filter);
        this.loadTableData();
      }
    );
  };

  handleFirstRequestChange = e => {
    const { filter } = this.state;

    this.setState(
      {
        filter: {
          ...filter,
          firstRequest: false,
          recurrentRequest: false,
          [e.target.name]: e.target.checked,
          // [e.target.name]: isChecked,
        },
      },
      () => {
        const { filter: updatedFilter } = this.state;

        if (updatedFilter.recurrentRequest) {
          this.setState({
            filter: {
              ...updatedFilter,
              firstRequest: false,
            },
          });
        } else if (!updatedFilter.firstRequest) {
          this.setState({
            filter: {
              ...updatedFilter,
              firstRequest: undefined,
            },
          });
        }
      }
    );
  };

  handleChangeOnFilterConditions(e) {
    const { filter } = this.state;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.setState({
      filter: {
        ...filter,
        [e.target.name]: value,
      },
    });
  }

  handleDateChangeOnFilterConditions = (name, date) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [name]: isNil(date) ? null : date.valueOf(),
      },
    }));
  };

  handleSelectChangeOfSearch(name, selectedOption) {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        [name]: selectedOption
          ? Array.isArray(selectedOption)
            ? selectedOption
            : selectedOption.value
          : null,
      },
    });
  }

  handleSelectChange(name, selectedOption, mode) {
    const { filter } = this.state;
    const options = parseStringValuesToComboValue(selectedOption, this.state.options[name], mode);

    this.setState({ filter: { ...filter, [name]: options || null } });
  }

  createNewDeal() {
    this.props.actions.getNewDealLocation();
  }

  filterData() {
    const { filter } = this.state;

    const urlFilters = {
      ...filter,
      rentalRequestType: parseArrayToStringForFilter(filter.rentalRequestType) || undefined,
      firstRequest: filter.firstRequest,
    };

    return urlFilters;
  }

  render() {
    return (
      <div className='animated fadeIn deals'>
        <Row align='middle' gutter={8}>
          <Col xxl={20} xl={20} lg={18} md={18} sm={24} xs={24}>
            <Title level={2} className='title-deals'>
              Oportunidades
              {/* <img src='img/xmas/santa-claus.png' alt='santa-claus' height={50} /> */}
            </Title>
          </Col>
          <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={24}>
            <Button
              data-testid='create-deal'
              className='btn-primary'
              style={{ width: '100%' }}
              onClick={this.createNewDeal}
            >
              Crear oportunidad
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FilterButtonsDeal />
          </Col>
        </Row>
        <Row className='row-filter-deals'>
          <Col span={24}>
            <DealsFilter
              filter={this.state.filter}
              handleChangeOnFilterConditions={this.handleChangeOnFilterConditions}
              handleDateChangeOnFilterConditions={this.handleDateChangeOnFilterConditions}
              loading={this.state.loading}
              handleSelectChangeOfSearch={this.handleSelectChangeOfSearch}
              options={this.state.options}
              handleSelectChange={this.handleSelectChange}
              handleFirstRequestChange={this.handleFirstRequestChange}
              handleFilter={this.handleFilter}
              handleFilterCollapse={this.handleFilterCollapse}
            />
          </Col>
        </Row>
        <Row className='row-table-deals'>
          <Col span={24}>
            <TableDataDeals
              data={this.state.table.data}
              loading={this.state.loading}
              options={this.state.options}
              pagination={this.state.pagination}
              handleSorter={this.sorterData}
              handlePaginationChange={this.handlePaginationChange}
              handlePaginationSizeChange={this.handlePaginationChange}
            />
          </Col>
        </Row>
        <style jsx>
          {`
            .deals {
              margin: 10px;
            }

            .title-deals {
              margin: 0;
            }

            .row-filter-deals {
              margin-top: 10px;
            }

            .row-table-deals {
              margin-top: 5px;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.Deals.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    filter: state.Deals.filter,
    optionLabels: state.Preload.optionLabels,
    currentUsername: state.Preload.currentUserInformation.username,
    defaultRequestStageFilter: ['S000_REQUEST', 'S010_PROPERTY_SEARCH'],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      filterTableDataDeals: filterCriteria => dispatch(filterTableDataDeals(filterCriteria)),
      getNewDealLocation: () => dispatch(getNewDealLocation()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
