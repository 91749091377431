import {
  PMA_APPROXIMATE_ERROR,
  PMA_APPROXIMATE_SUCCESS,
  PMA_AVERAGES_TABLEDATA_ERROR,
  PMA_AVERAGES_TABLEDATA_SUCCESS,
  PMA_CITIES_ERROR,
  PMA_CITIES_SUCCESS,
  PMA_METASOURCE_ERROR,
  PMA_METASOURCE_SUCCESS,
  PMA_TABLEDATA_ERROR,
  PMA_TABLEDATA_SUCCESS,
  PMA_ZIP_CODES_ERROR,
  PMA_ZIP_CODES_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  pmasAverageList: {
    list: [],
    error: false,
    errorDetail: '',
  },
  pmasList: {
    list: [],
    error: false,
    errorDetail: '',
  },
  pmasCitites: {
    list: [],
    error: false,
    errorDetail: '',
  },
  pmasZipCodes: {
    list: [],
    error: false,
    errorDetail: '',
  },
  pmasApproximate: {
    list: [],
    error: false,
    errorDetail: '',
  },
};

const Pmas = (state = initialState, action) => {
  switch (action.type) {
    case PMA_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PMA_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PMA_AVERAGES_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        pmasAverageList: {
          list: action.tableData,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PMA_AVERAGES_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        pmasAverageList: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PMA_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        pmasList: {
          list: action.tableData,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PMA_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        pmasList: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PMA_CITIES_SUCCESS: {
      return Object.assign({}, state, {
        pmasCitites: {
          list: action.list.map(c => ({ label: c, value: c })),
          error: false,
          errorDetail: '',
        },
      });
    }

    case PMA_CITIES_ERROR: {
      return Object.assign({}, state, {
        pmasCitites: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PMA_ZIP_CODES_SUCCESS: {
      return Object.assign({}, state, {
        pmasZipCodes: {
          list: action.list.map(c => ({ label: c, value: c })),
          error: false,
          errorDetail: '',
        },
      });
    }

    case PMA_ZIP_CODES_ERROR: {
      return Object.assign({}, state, {
        pmasZipCodes: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PMA_APPROXIMATE_SUCCESS: {
      return Object.assign({}, state, {
        pmasApproximate: {
          list: action.list,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PMA_APPROXIMATE_ERROR: {
      return Object.assign({}, state, {
        pmasApproximate: {
          list: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    default:
      return state;
  }
};

export default Pmas;
