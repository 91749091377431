import React from 'react';
import PropTypes from 'prop-types';

const InformationBlock = ({ label = '' }) => (
  <div>
    <div className='row'>
      <div className='col-12'>
        <div className='border border-warning py-1 pr-3 text-label'>
          <i className='fas fa-info-circle px-2 information-icon' width='20'></i>
          <span
            style={{ marginTop: '1px' }}
            dangerouslySetInnerHTML={{ __html: `${label}` }}
          ></span>
        </div>
      </div>
    </div>

    <style jsx>
      {`
        .text-label {
          font-family: 'Open Sans';
          font-size: 11px;
          background-color: #fcedd1;
          min-height: 35px;
          display: flex;
        }

        .information-icon {
          font-size: 14px;
        }

        i {
          color: #eeaf30;
        }
      `}
    </style>
  </div>
);

InformationBlock.propTypes = {
  label: PropTypes.string,
};

export default InformationBlock;
