import { createQueryString, getAxiosInstance, parseQueryString } from '../../shared/utils/apiUtils';

class ContactsApi {
  static getMetasource(url) {
    return this.doGet(url);
  }

  static getContactsTableData(url) {
    return this.doGet(url);
  }

  static getNewContactLocation(url) {
    return this.doPost(url).then(res => res.headers['location']);
  }

  static createNewContact(contactData, url) {
    return this.getNewContactLocation(url).then(location => this.doPut(location, contactData));
  }

  static doGet(url) {
    return getAxiosInstance().get(url);
  }

  static doPost(url) {
    return getAxiosInstance().post(url);
  }

  static doPut(url, data) {
    return getAxiosInstance().put(url, data);
  }

  static saveNewContactData(url, contactDetails, parent, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    if (url && parent) {
      let urlBase = url;

      if (url.indexOf('?') > 0) {
        urlBase = url.substring(0, url.indexOf('?'));
      }

      const urlParsed = parseQueryString(url);

      urlParsed.parent = parent;
      url = urlBase + '?' + createQueryString(urlParsed);
    }

    return axiosInstance.put(url, contactDetails);
  }
}

export default ContactsApi;
