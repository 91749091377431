import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { Button, Col, Form, Input, Modal, Row, Tooltip } from 'antd';
import { normalizePhoneNumber } from '../../shared/utils/formUtils';
import { getOptionsForSelect, openNotificationWithIcon } from '../../shared/utils/appUtils';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import { DATETIME_FORMAT } from '../../shared/appConfig';
import { validateIBAN } from '../HomyFormComponents/FormIBAN';
import api from '../../shared/utils/api';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import assign from 'lodash/assign';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';

import HomySelect from '../HomyComponents/HomySelect';
import HomyIBAN from '../HomyComponents/HomyIBAN';

const { Item: FormItem } = Form;

class FormContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {
        data: props.contact.data,
        etag: props.contact.etag,
        isSubmitting: false,
      },
      options: {
        contactType: getOptionsForSelect(this.props.optionLabels, 'ContactType'),
        registeredInRoi: getOptionsForSelect(this.props.optionLabels, 'RegisteredInRoi'),
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange(e) {
    const { form } = this.props;
    let { name } = e.target;
    let { value } = e.target;

    if (name.toLowerCase().includes('phone')) {
      value = normalizePhoneNumber(value);
    }

    if (name.toLowerCase() === 'address') {
      value = {
        ...value,
        addressComplement: form.getFieldValue('addressComplement'),
      };
    }

    if (name.toLowerCase() === 'addresscomplement') {
      const address = form.getFieldValue('address');

      value = {
        ...address,
        [name]: value,
      };

      name = 'address';
    }

    form.setFieldsValue({
      [name]: value,
    });
  }

  handleValidIBAN = (rule, value, callback) => {
    if (value) {
      const isValid = isValidIBAN(electronicFormatIBAN(value));

      if (!isValid) {
        callback(
          <span>
            El IBAN introducido no es correcto. Consultalo en{' '}
            <a
              href='https://www.iban.es'
              target='_blank'
              rel='noopener noreferrer'
              className='has-error ant-form-explain'
            >
              <u>la web del IBAN.</u>
            </a>
          </span>
        );
      }
    }

    callback();
  };

  handleDelete = async () => {
    this.setState(prevState => ({
      contact: {
        ...prevState.contact,
        isSubmitting: true,
      },
    }));
    const id = get(this.state, 'contact.data.id');

    if (!isNil(id)) {
      try {
        await api.remove('contact', id);
        openNotificationWithIcon('success', 'Au revoir!', 'Contacto eliminado con éxito!');
        browserHistory.push(`contacts`);
      } catch (e) {
        this.setState(
          {
            showDeleteContact: false,
          },
          () =>
            openNotificationWithIcon(
              'error',
              'Oops 💀',
              `Ha ocurrido un prolema a la hora de realizar la acción... ${e.message}`
            )
        );
      }
    } else {
      openNotificationWithIcon(
        'error',
        'Oops 💀',
        `No se ha podido obtener el identificador para eliminar al Contacto`
      );
    }

    this.setState(prevState => ({
      contact: {
        ...prevState.contact,
        isSubmitting: false,
      },
    }));
  };

  validateForm = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && validateIBAN()) {
        this.handleSubmit(values);
      }
    });
  };

  handleSubmit = async values => {
    this.setState({
      loading: true,
    });

    try {
      const { data, etag } = this.state.contact;
      const id = get(data, 'id');
      const body = assign(data, values);

      await api.save('contact', id, {
        ...body,
        etag,
      });

      openNotificationWithIcon('success', '¡Modificación realizada con éxito!');
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Oops ',
        `Ha ocurrido un prolema a la hora de realizar la acción... ${e.message}`
      );
    }

    this.setState({
      loading: false,
    });
  };

  render() {
    const { props } = this;
    const { getFieldDecorator } = props.form;
    const { contact, options } = this.state;
    const contactType = get(contact, 'data.contactType');

    return (
      <Form onSubmit={this.validateForm} layout='vertical'>
        <Row gutter={12}>
          <Col span={4}>
            <Row>
              <Col span={24}>
                <Button
                  loading={contact.isSubmitting}
                  htmlType='submit'
                  className='btn-block btn-primary'
                >
                  Guardar
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  loading={contact.isSubmitting}
                  className='btn-block btn-primary'
                  onClick={() => {
                    this.setState({
                      showDeleteContact: true,
                    });
                  }}
                >
                  Eliminar
                </Button>
                <Modal
                  title='Eliminar contacto'
                  visible={this.state.showDeleteContact}
                  onOk={this.handleDelete}
                  onCancel={() => {
                    this.setState({
                      showDeleteContact: false,
                    });
                  }}
                >
                  ¿Estás seguro de que deseas eliminar el contacto? Esta acción es IRREVERSIBLE.
                </Modal>
              </Col>
            </Row>
            {get(contact, 'data.creationDate') && (
              <Row>
                <Col span={24}>
                  <p>
                    <b>Creado por:</b>
                    <br />
                    {prettyPrintAgentFromEmail(get(contact, 'data.createdBy'), '')}
                    <br />
                    {moment(contact.data.creationDate).format(DATETIME_FORMAT)}
                  </p>
                </Col>
              </Row>
            )}
            {get(contact, 'data.updateDate') && (
              <Row>
                <Col span={24}>
                  <p>
                    <b>Última actualización por:</b>
                    <br />
                    {prettyPrintAgentFromEmail(get(contact, 'data.updatedBy'), '')}
                    <br />
                    {moment(contact.data.updateDate).format(DATETIME_FORMAT)}
                  </p>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={20}>
            <Row gutter={8}>
              <Col span={12}>
                <FormItem label='Tipo'>
                  {getFieldDecorator('contactType', {
                    initialValue: contact.data.contactType || '',
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, selecciona un valor para Tipo',
                      },
                    ],
                  })(
                    <HomySelect
                      name='contactType'
                      options={get(options, 'contactType', '')}
                      onChange={value =>
                        this.handleChange({
                          target: {
                            name: 'contactType',
                            value,
                            type: 'select',
                            mode: 'default',
                          },
                        })
                      }
                    />
                  )}
                </FormItem>
              </Col>
              {contactType === 'PERSON' && (
                <Col span={12}>
                  <FormItem label='Cargo'>
                    {getFieldDecorator('jobTitle', {
                      initialValue: contact.data.jobTitle,
                    })(<Input name='jobTitle' placeholder='Cargo' onChange={this.handleChange} />)}
                  </FormItem>
                </Col>
              )}
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <FormItem label='NIF'>
                  <Tooltip placement='topLeft' title='Número de Identificación Fiscal'>
                    <i className='far fa-question-circle' />
                  </Tooltip>
                  {getFieldDecorator('identificationCode', {
                    initialValue: contact.data.identificationCode,
                  })(
                    <Input
                      name='identificationCode'
                      placeholder='12121212Z'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label='Nombre'>
                  {getFieldDecorator('name', {
                    initialValue: contact.data.name,
                    rules: [
                      {
                        required: true,
                        message: 'Por favor, introduce un Nombre',
                      },
                    ],
                  })(<Input placeholder='Ana' name='name' onChange={this.handleChange} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label='Apellidos/Razón Social'>
                  {getFieldDecorator('lastOTradeName', {
                    initialValue: contact.data.lastOTradeName,
                  })(
                    <Input
                      name='lastOTradeName'
                      placeholder='García García'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                <FormItem label='Email'>
                  {getFieldDecorator('email', {
                    initialValue: contact.data.email,
                    rules: [
                      {
                        type: 'email',
                        message: '¡No es un email válido!',
                      },
                    ],
                  })(
                    <Input
                      name='email'
                      placeholder='correo@email.com'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label='Email alternativo'>
                  {getFieldDecorator('email2', {
                    initialValue: contact.data.email2,
                    rules: [
                      {
                        type: 'email',
                        message: '¡No es un email válido!',
                      },
                    ],
                  })(
                    <Input
                      name='email2'
                      placeholder='correo.alternativo@email.com'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label='Teléfono fijo'>
                  {getFieldDecorator('phoneNumber', {
                    initialValue: contact.data.phoneNumber,
                  })(
                    <Input
                      name='phoneNumber'
                      placeholder='961231212'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label='Teléfono móvil'>
                  {getFieldDecorator('mobilePhoneNumber', {
                    initialValue: contact.data.mobilePhoneNumber,
                  })(
                    <Input
                      name='mobilePhoneNumber'
                      placeholder='616232323'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <FormItem label='Dirección'>
                  <Tooltip
                    placement='topLeft'
                    title='Escribe la dirección y SELECCIONA una de las opciones del desplegable.'
                  >
                    <i className='far fa-question-circle' />
                  </Tooltip>
                  {getFieldDecorator('address', {
                    initialValue: get(contact, 'data.address'),
                  })(
                    <GoogleMapsAutocomplete
                      name='address'
                      handlePlaceChanged={value =>
                        this.handleChange({
                          target: {
                            name: 'address',
                            value,
                          },
                        })
                      }
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label='Complemento de dirección'>
                  {getFieldDecorator('addressComplement', {
                    initialValue: get(contact, 'data.address.addressComplement'),
                  })(
                    <Input
                      name='addressComplement'
                      placeholder='Número 3, Piso 10, Puerta 11...'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <FormItem label='Número de cuenta (IBAN)'>
                  {getFieldDecorator('accountNumber', {
                    initialValue: contact.data.accountNumber,
                    rules: [{ validator: this.handleValidIBAN }],
                  })(
                    <HomyIBAN
                      className='validate-accountNumber'
                      name='accountNumber'
                      label=''
                      onChange={this.handleChange}
                    />
                    // <FormIBAN
                    //   initialValue={contact.data.accountNumber || ''}
                    //   onChange={this.handleChange}
                    // />
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label='BIC o SWIFT'>
                  <Tooltip
                    placement='topLeft'
                    title='El código SWIFT (Society for Worldwide Interbank Financial Telecommunication) o también denominado código BIC (Bank Identifier Code) es una serie alfanumérica de 8 u 11 dígitos que sirve para identificar al banco receptor cuando se realiza una transferencia internacional.'
                  >
                    <i className='far fa-question-circle' />
                  </Tooltip>
                  {getFieldDecorator('bicOrSwift', {
                    initialValue: contact.data.bicOrSwift,
                  })(
                    <Input
                      name='bicOrSwift'
                      placeholder='HOMYESMMXXX'
                      initValue={contact.data.bicOrSwift}
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label='Beneficiario'>
                  <Tooltip
                    placement='topLeft'
                    title='Titular de la cuenta bancaria a quien se le hará la transferencia.'
                  >
                    <i className='far fa-question-circle' />
                  </Tooltip>
                  {getFieldDecorator('beneficiary', {
                    initialValue: contact.data.beneficiary,
                  })(
                    <Input
                      name='beneficiary'
                      placeholder='Número 3, Piso 10, Puerta 11...'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <FormItem label='Registrado en el ROI'>
                  <Tooltip
                    placement='topLeft'
                    title='¿Está el Cliente / Contacto / Proveedor registrada en el Registro de Operadores Intracomunitarios?'
                  >
                    <i className='far fa-question-circle' />
                  </Tooltip>
                  {getFieldDecorator('registeredInRoi', {
                    initialValue: contact.data.registeredInRoi,
                  })(
                    <HomySelect
                      name='registeredInRoi'
                      options={get(options, 'registeredInRoi', '')}
                      onChange={value =>
                        this.handleChange({
                          target: {
                            name: 'registeredInRoi',
                            value,
                            type: 'select',
                            mode: 'default',
                          },
                        })
                      }
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <FormItem label='Descripción'>
                  {getFieldDecorator('description', {
                    initialValue: contact.data.description,
                  })(
                    <Input.TextArea
                      name='description'
                      autoSize={{ minRows: 8 }}
                      placeholder='Introduce una descripción'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem label='Observaciones'>
                  {getFieldDecorator('observations', {
                    initialValue: contact.data.observations,
                  })(
                    <Input.TextArea
                      name='observations'
                      autoSize={{ minRows: 4 }}
                      placeholder='Introduce observaciones que consideres oportunas'
                      onChange={this.handleChange}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <style jsx>
          {`
            button {
              text-align: left !important;
              margin-bottom: 10px;
            }

            .fa-question-circle {
              margin-left: 3px;
            }
          `}
        </style>
      </Form>
    );
  }
}

function mapDispatchToProps() {
  return {
    // actions: {
    //   // filterTableDataContacts: filterCriteria =>
    //   //   dispatch(filterTableDataContacts(filterCriteria)),
    //   deleteContact: (contact, callbackFunctionOnDelete) => {
    //     dispatch(deleteProviderContactData(contact, callbackFunctionOnDelete));
    //   },
    // },
  };
}

function mapStateToProps(state) {
  return {
    // contactDetailsData: state.ContactDetails,
    // contactDetailsSubmit: state.ContactDetails.submit,
    // deleteContactStatus: state.ContactDetails.delete,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormContactDetails));
