import { getAxiosInstance } from '../../shared/utils/apiUtils';
import api from '../../shared/utils/api';
import { getProposalsFiltered } from '../../shared/apiUrls';

class ProposalsApi {
  static getMetasource(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static getNewProposalLocation(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.post(url);
  }

  static saveProposalData(url, proposal, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, proposal);
  }

  static deleteProposal(url, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.delete(url);
  }

  static sendProposals(url, body) {
    return getAxiosInstance().post(url, body);
  }

  static sendCheckin(url) {
    return getAxiosInstance().put(url);
  }

  static getContractData(url) {
    return getAxiosInstance().get(url);
  }

  static sendContractData(url) {
    return getAxiosInstance().put(url);
  }

  static requestConfirmation(url) {
    return getAxiosInstance().put(url);
  }

  static getAcceptedProposalsByPropertyId(propertyId) {
    const params = {
      proposalStage: 'ACCEPTED',
      propertyId,
    };
    return api
      .request_retry(getProposalsFiltered(params), 'get', 3)
      .then(res => res.json)
      .catch(e => {
        console.error(`ProposalApi.getProposalsFiltered: ${e}`);
      });
  }

  static getProposalsForSameDates(propertyId, dealId, dateCheckIn, dateCheckOut) {
    const params = {
      propertyId,
      includedInDatesFrom: dateCheckIn,
      includedInDatesTo: dateCheckOut,
    };
    // Yes, my friend, these are the params to filter by various proposal stages
    const stagesQueryString =
      '&proposalStages=ACCEPTED&proposalStages=PREPARING&proposalStages=SHIPPED';
    return api
      .request_retry(getProposalsFiltered(params) + stagesQueryString, 'get', 3)
      .then(res => res.json)
      .then(proposals => proposals.filter(p => p.dealId !== dealId))
      .catch(e => {
        console.error(`ProposalApi.getProposalsFiltered: ${e}`);
      });
  }
}

export default ProposalsApi;
