import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar/';
import { preloadApp } from '../../modules/Preload/PreloadActions';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { initHotjar } from '../../analytics';
import get from 'lodash/get';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

const metasources = [
  'metasourceStrings',
  'mainMetasource',
  'ProvidersMetaSources',
  'PropertiesMetaSources',
  'ContactsMetaSources',
  'CompaniesMetaSources',
  'DealsMetaSources',
  'RequestsMetaSources',
  'ProposalsMetaSources',
  'InvoicesMetaSources',
  'PmasMetaSources',
];

const areMetasourcesLoaded = props => every(metasources, metasource => !isEmpty(props[metasource]));

class Full extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 200,
      isCollapsed: false,
      navigateTo: null,
      isPreloading: true,
    };

    this.setPageWidth = this.setPageWidth.bind(this);
  }

  componentDidMount() {
    this.props.actions.preloadApp();
    initHotjar();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (areMetasourcesLoaded(nextProps)) {
      this.setState({
        isPreloading: false,
      });
    }
  }

  setPageWidth = collapsed => {
    this.setState({
      width: collapsed ? 75 : 200,
      isCollapsed: collapsed,
    });
  };

  setSidebarCollapse = collapsed => {
    this.setState(
      {
        isCollapsed: collapsed,
      },
      () => this.setPageWidth(collapsed)
    );
  };

  navigateTo = location => {
    this.setState({ navigateTo: location });
  };

  render() {
    const children = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        collapseSidebar: this.setSidebarCollapse,
        navigateTo: this.navigateTo,
      })
    );
    const { width, isCollapsed, navigateTo } = this.state;

    return (
      <>
        <Layout className='layout-general'>
          {!this.state.isPreloading && (
            <>
              <Sidebar
                {...this.props}
                isCollapsed={isCollapsed}
                navigateTo={navigateTo}
                handleNavigateTo={this.navigateTo}
                setPageWidth={this.setPageWidth}
                pageWidth={width}
              />
              <Layout style={{ marginLeft: width }}>
                <Layout.Content>
                  <div>{children}</div>
                </Layout.Content>
              </Layout>
            </>
          )}
          <style jsx global>
            {`
              .main,
              .ant-layout-content {
                background-color: white;
              }

              .ant-layout {
                background-color: white;
              }

              .layout-general {
                min-height: 100vh;
              }

              .exportation,
              .more-filters {
                width: 100%;
                border-color: #eeaf30;
                -webkit-transition: background-color 0.5s ease-out;
                -moz-transition: background-color 0.5s ease-out;
                -o-transition: background-color 0.5s ease-out;
                transition: background-color 0.5s ease-out;
              }

              .exportation:active,
              .more-filters:active,
              .exportation:focus,
              .more-filters:focus {
                border-color: #eeaf30 !important;
              }

              .exportation:hover,
              .more-filters:hover {
                background-color: #eeaf30;
                cursor: pointer;
                color: white;
                border-color: #eeaf30;
              }

              .more-filters > .anticon {
                vertical-align: -0.167em !important;
              }

              .link-behavior:hover {
                cursor: pointer;
                text-decoration: underline;
              }

              .ant-col > label,
              .ant-col .ant-form-item-label,
              .form-group > label {
                color: black;
              }

              .required {
                color: #a4a4a4 !important;
              }

              .ant-input[disabled],
              .ant-input-number-disabled {
                color: rgba(0, 0, 0, 0.5);
              }

              .ant-btn-primary.disabled,
              .ant-btn-primary[disabled] {
                cursor: not-allowed;
                opacity: 0.65;
                text-shadow: none;
                box-shadow: none;
              }
            `}
          </style>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasourceStrings: state.Preload.metasourceStrings,
    mainMetasource: state.Preload.mainMetasource,
    ProvidersMetaSources: get(state, 'Providers.metasource.links'),
    ProviderDetailsMetaSources: get(state, 'ProviderDetails.metasource.links'),
    PropertiesMetaSources: get(state, 'Properties.metasource.links'),
    PropertyDetailsMetaSources: get(state, 'PropertyDetails.metasource.links'),
    ContactsMetaSources: get(state, 'Contacts.metasource.links'),
    ContactDetailsMetaSources: get(state, 'ContactDetails.metasource.links'),
    CompaniesMetaSources: get(state, 'Companies.metasource.links'),
    CompanyDetailsMetaSources: get(state, 'CompanyDetails.metasource.links'),
    DealsMetaSources: get(state, 'Deals.metasource.links'),
    RequestsMetaSources: get(state, 'Requests.metasource.links'),
    DealDetailsMetaSources: get(state, 'DealDetails.metasource.links'),
    ProposalsMetaSources: get(state, 'Proposals.metasource.links'),
    ProposalDetailsMetaSources: get(state, 'ProposalDetails.metasource.links'),
    InvoicesMetaSources: get(state, 'Invoices.metasource.links'),
    InvoiceDetailsMetaSources: get(state, 'InvoiceDetails.metasource.links'),
    PmasMetaSources: get(state, 'Pmas.metasource.links'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      preloadApp: () => dispatch(preloadApp()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Full);
