import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'antd';
import {
  getExtendedContactList,
  normalizeChildrenContacts,
  openNotificationWithIcon,
  RENTAL_REQUEST_STAGES,
} from '../../shared/utils/appUtils';
import { ENDPOINT_V2 } from '../../shared/appConfig';
import CheckboxButton from '../CheckboxButton';
import SelectDeal from '../SelectDeal';
import get from 'lodash/get';
import last from 'lodash/last';
import split from 'lodash/split';
import isEmpty from 'lodash/isEmpty';
import api from '../../shared/utils/api';
import { browserHistory, withRouter } from 'react-router';
import HomySelect from '../HomyComponents/HomySelect';

class FormNewProposal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      providerConfirmation: false,
      proposal: props.proposal,
      submitting: false,
      options: {
        contacts: [],
      },
      bondedDeal: props.bondedDeal,
      auxDealId: get(props, 'bondedDeal.id', ''),
      // For images validation
      selectedImagesChanged: props.selectedImagesChanged,
      initialSelectedImagesLength: props.initialSelectedImagesLength,
    };

    this.createProposal = this.createProposal.bind(this);
    this.validateImages = this.validateImages.bind(this);
    this.handleSubmitProposal = this.handleSubmitProposal.bind(this);
    this.getPropertyData = this.getPropertyData.bind(this);
  }

  componentDidMount = async () => {
    this._isMounted = true;
    const preselectedContactId = get(this.props, 'preselectedContactId');

    if (preselectedContactId) {
      const contactData = await this.getContactData(preselectedContactId);

      this.setState({
        selectedContactDetails: contactData,
      });
    }

    const contacts = await this.getContactsOfProvider();

    this.setState({
      options: {
        contacts,
      },
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  getContactData = async id => {
    if (this._isMounted) {
      try {
        const res = await api.getDetailData('contact', id);

        const contactData = get(res, 'json.contact');

        return contactData;
      } catch (e) {
        console.error(e);
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido obtener información del contacto relacionado. ${e.message}`
        );
      }
    }
  };

  getPropertyData = async () => {
    const { proposal } = this.state;

    try {
      const propertyId = get(proposal, 'property.id');
      const res = await api.getDetailData('property', propertyId);
      const propertyDetails = res.json.property;

      return propertyDetails;
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Error',
        `No se han podido obtener los datos del Inmueble. ${e.message}`
      );

      console.error(e);
    }
  };

  getContactsOfProvider = async () => {
    if (this._isMounted) {
      try {
        const propertyDetails = await this.getPropertyData();

        if (!propertyDetails) {
          openNotificationWithIcon(
            'error',
            'Error',
            'No se han podido obtener los Contactos del Proveedor'
          );

          return false;
        }

        const providerBaseId = get(propertyDetails, 'providerBack.baseContact.id');
        const providerBase = await api.getDetailData('provider', `${providerBaseId}/children`);
        const providerBaseContact = get(providerBase, 'json.provider.baseContact');

        if (!providerBaseContact) {
          openNotificationWithIcon(
            'error',
            'Error',
            'No se han podido obtener los Contactos del Proveedor'
          );

          return false;
        }

        const normalizedContacts = normalizeChildrenContacts(new Array(providerBaseContact));
        const contactList = getExtendedContactList(normalizedContacts);

        return contactList;
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'Error',
          `No se han podido obtener los Contactos del Proveedor. ${e.message}`
        );
        console.error(e);
      }
    }
  };

  getRelevance = async res => {
    try {
      await api.request_retry(`${ENDPOINT_V2}/proposals`, 'post', 5, {
        body: JSON.stringify({ id: res.json.proposal.id }),
      });
    } catch (e) {
      console.error('Proposal RelevanceService call error: ' + e);
    }
  };

  createProposal = async (dealId, contact) => {
    const { proposal, providerConfirmation } = this.state;

    try {
      // Images validation
      if (!this.validateImages()) {
        this.setState({
          submitting: false,
        });
        return;
      }

      const generateId = await api.generateId('proposal');
      const location = generateId.headers.get('location');
      const id = last(split(location, '/'));

      const providerShouldConfirm = providerConfirmation
        ? '?providerConfirmation=true'
        : '?providerConfirmation=false';
      const locationParsed = id.replace('{?providerConfirmation}', providerShouldConfirm);

      if (!isEmpty(proposal.dealId) && dealId !== proposal.dealId) {
        // Avoid to copy tenants if isn't a booking extension
        proposal.tenants = 0;
        proposal.tenantContacts = [];
      }

      proposal.dealId = dealId;

      const contactData = await this.getContactData(contact);
      const contactsOfProvider = {
        contact: contactData,
        roles: ['USUAL'],
      };

      proposal.contactsOfProvider = [contactsOfProvider];

      /**
       * Since HTI-4393, proposal price service replaces the original value for the "monthlyPrice"
       * param of the proposal (a boolean), for a new value of type currency, and this call to
       * classic fails, so needs to be replaced again
       */
      proposal.monthlyPrice = false;

      const creationResponse = await api.save('proposal', locationParsed, proposal);

      if (get(creationResponse, 'json.proposal.id')) {
        const dealId = get(creationResponse, 'json.proposal.dealId');

        openNotificationWithIcon('success', 'Propuesta creada con éxito!');
        browserHistory.push(`/dealDetails/${dealId}`);

        const extraInfo = creationResponse.headers.get('hs-extra');

        if (extraInfo) {
          openNotificationWithIcon('error', 'Oops!', extraInfo);
        }

        this.getRelevance(creationResponse);
      }
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Siento tener que volver a aparecer...',
        `Ha ocurrido un error a la hora de crear la nueva Propuesta. ${e.message}`
      );
      console.error(e);
    }

    this.setState({
      submitting: false,
    });
  };

  validateImages = () => {
    const { proposal, selectedImagesChanged, initialSelectedImagesLength } = this.state;
    if (!proposal.pictures || !proposal.pictures.length) {
      openNotificationWithIcon(
        'error',
        'Sorry!!!',
        'La propuesta tiene que tener imagenes seleccionadas. Si no te aparecen es posible que el inmueble no tenga fotos.'
      );
      return false;
    }
    if (proposal.pictures.length > 20) {
      openNotificationWithIcon(
        'error',
        'Sorry!!!',
        'La propuesta solo puede tener como máximo 20 imágenes seleccionadas'
      );
      return false;
    }
    if (selectedImagesChanged) {
      if (
        window.confirm(
          '¡OJO! Las fotos que hayas desmarcado para el dossier, se eliminarán también del inmueble. ¿Quieres continuar?'
        )
      ) {
        if (
          initialSelectedImagesLength > proposal.pictures.length &&
          proposal.pictures.length < 20
        ) {
          openNotificationWithIcon(
            'error',
            'Sorry!!!',
            'La propuesta debe tener al menos 20 imágenes seleccionadas. Si actualmente, el total de imágenes del inmueble dispone de menos, manten las fotos que tuviera seleccionadas o eliminalas desde la ficha del inmueble'
          );
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  handleSubmitProposal = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState(
          {
            submitting: true,
          },
          () => {
            this.createProposal(values.proposalDeal.value, values.contactOfProvider);
          }
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const showButtons = get(this.props, 'showButtons', true);

    return (
      <Form onSubmit={this.handleSubmitProposal} layout='vertical'>
        <Row className='hs-row'>
          <Col span={24}>
            <Form.Item label='Oportunidad relacionada'>
              {getFieldDecorator('proposalDeal', {
                initialValue: get(this.state, 'bondedDeal.id')
                  ? { value: get(this.state, 'bondedDeal.id') }
                  : '',
                rules: [
                  {
                    required: true,
                    message: '¡Selecciona una Oportunidad donde se asignará la Propuesta!',
                  },
                ],
              })(
                <SelectDeal
                  name='proposalDeal'
                  disabled={!isEmpty(get(this.props, 'bondedDeal'))}
                  selectedDeal={get(this.state, 'bondedDeal')}
                  prefilter={{
                    rentalRequestStages: [
                      RENTAL_REQUEST_STAGES.S000_REQUEST,
                      RENTAL_REQUEST_STAGES.S010_PROPERTY_SEARCH,
                      RENTAL_REQUEST_STAGES.S020_SEND_SEARCH_RESULTS,
                      RENTAL_REQUEST_STAGES.S030_CONTRACT_INVOICE_MANAGMENT,
                      RENTAL_REQUEST_STAGES.S040_CHECK_IN,
                      RENTAL_REQUEST_STAGES.S050_RENTAL_CONFIRMED,
                    ],
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className='hs-row'>
          <Col span={24}>
            <Form.Item label='Contacto habitual'>
              {getFieldDecorator('contactOfProvider', {
                initialValue: get(this.state, 'auxContactsOfProvider.id', ''),
                rules: [
                  {
                    required: true,
                    message: '¡Selecciona un Contacto Habitual para la Propuesta!',
                  },
                ],
              })(
                <HomySelect
                  className='no-homy-select'
                  id='contactOfProvider'
                  options={this.state.options.contacts}
                  showArrow
                  showSearch
                  allowClear
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className='hs-row'>
          <Col span={24}>
            <Form.Item>
              <CheckboxButton
                borderStyle='dealCheckboxActive'
                handleChange={e => {
                  this.setState({ providerConfirmation: e.target.checked });
                }}
                value={this.state.providerConfirmation}
                content='Confirmar con el proveedor'
              />
            </Form.Item>
          </Col>
        </Row>

        <Row type='flex' justify='end' gutter={2}>
          {showButtons && (
            <Col>
              <Form.Item>
                <Button disabled={this.state.submitting} onClick={this.props.prev}>
                  Anterior
                </Button>
              </Form.Item>
            </Col>
          )}
          <Col>
            <Form.Item>
              <Button
                icon='shop'
                loading={this.state.submitting}
                htmlType='submit'
                className='btn-primary'
              >
                Crear propuesta
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <style jsx>
          {`
            .hs-row {
              margin-bottom: 15px;
            }

            .no-homy-select,
            .Select-control {
              margin: 10px 0 0 0 !important;
            }
          `}
        </style>
      </Form>
    );
  }
}

export default Form.create()(withRouter(FormNewProposal));
