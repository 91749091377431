/* global Promise */

import axios from 'axios';
import queryString from 'query-string';
import { browserHistory } from 'react-router';

const interceptToken = response => {
  //localStorage.setItem('jwt', response.headers['x-auth-token']);

  return response;
};

const appendToken = config => {
  //if (localStorage.jwt) {
  //  config.headers.common['x-auth-token'] = localStorage.jwt;
  //}

  if (localStorage.origin) {
    config.headers.common['ORIGIN'] = localStorage.origin;
  }

  return config;
};

export const getAxiosInstance = function() {
  const axiosInstance = axios.create();
  axiosInstance.defaults.headers.common = {};

  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

  axiosInstance.defaults.headers.common['Authorization'] = '';
  if (localStorage.jwt) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.jwt;
  }

  axiosInstance.interceptors.request.use(appendToken, error => Promise.reject(error));

  axiosInstance.interceptors.response.use(
    res => res,
    error => {
      if (error && !error.response && !window.location.href.endsWith('/login')) {
        const mess =
          'Parece que no tienes conexión a Internet!!\nPor favor, verifica tu conexión y si el problema persiste contacta con soporte\nTen en cuenta que tu última acción no ha llegado procesarse.';

        error['response'] = { data: { message: mess } };
        alert(mess);
      }

      if (error && error.response && error.response.status === 403) {
        alert('Tu sesión ha caducado');
        localStorage.removeItem('jwt');
        localStorage.setItem('urlBeforeLoginRedirect', window.location.pathname);
        browserHistory.push('/login');
      }

      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(interceptToken, error => Promise.reject(error));

  return axiosInstance;
};

export const removeTrace = message => {
  const msg = message.toLowerCase();

  if (msg.indexOf('class') !== -1 && msg.indexOf('exception') !== -1 && msg.indexOf(':') !== -1) {
    return message.split(':')[1].trim();
  }

  return message;
};

export const extractErrorMessage = error => {
  if (error) {
    if (error.response) {
      if (error.response.data && error.response.data.message) {
        return removeTrace(error.response.data.message);
      }
    } else if (error.message) {
      return removeTrace(error.message);
    }
  }

  return 'Undefined error!!';
};

export const getUrlBase = url =>
  url && url.indexOf('?') !== -1 ? url.substring(0, url.indexOf('?')) : url;

export const parseQueryString = url => (url ? queryString.parse(queryString.extract(url)) : url);

export const createQueryString = queryObject => queryString.stringify(queryObject);

export const queryStringSlashEncode = queryString => queryString.replace(/[\/]/g, '~');
