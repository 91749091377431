import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, Modal, Progress, Tabs, Tooltip, Select as SelectAntd } from 'antd';
import Select from 'react-select';
import moment from 'moment';
import {
  getEndingPercentDealStage,
  getOptionsForSelect,
  getOptionsForSelectWithDeprecationSupport,
  getTextValueFromSelectOptions,
  openNotificationWithIcon,
  RENTAL_REQUEST_STAGES,
} from '../../shared/utils/appUtils';
import CheckboxButton from '../CheckboxButton';
import GoogleMapsAutocomplete from '../GoogleMapsAutocomplete';
import SelectCompany from '../SelectCompany';
import {
  deleteDealDetailsData,
  getDealDetailsData,
  saveDealDetailsData,
} from '../../modules/DealDetails/DealDetailsActions';
import { DATETIME_FORMAT, PARSE_DATE_FORMAT, ENDPOINT_V2 } from '../../shared/appConfig';
import { validateInputs } from '../../shared/utils/formUtils';
import { areValidDates, isValidDate } from '../../shared/utils/dateUtils';
import round from 'lodash/round';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import first from 'lodash/first';
import map from 'lodash/map';
import size from 'lodash/size';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import { prettyPrintAgentFromEmail } from '../../shared/utils/agentUtils';
import {
  OPPORTUNITY_LOSS_REASONS_OPTIONS_ES,
  OPPORTUNITY_LOSS_REASONS_DETAILS_OPTIONS_ES,
  TAX_TYPE_LABELS_ES,
} from 'hs-utils';
import { isFlagActive } from 'hs-feature-flags';
import api from '../../shared/utils/api';
import { amountToString } from '../../shared/utils/priceUtils';
import {
  dealPreferenceQuestions,
  dealPreferenceOptions,
  dealCloseOrBudgetOptions,
} from '../../shared/utils/dealUtils';
import { InformationBlock } from 'homy-ui';

const dealPreferenceQuestionsByValue = keyBy(dealPreferenceQuestions, 'value');
const dealPreferenceOptionsByValue = keyBy(dealPreferenceOptions, 'value');
const dealCloseOrBudgetOptionsByValue = keyBy(dealCloseOrBudgetOptions, 'value');

const CANCELATIONS_FLAG = 'CANCELATIONS';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const PAGO_TARIFA_CLIENTE =
  'Este importe corresponde al que el Cliente nos transfiere en concepto de pago de Tarifa de Gestión';
const PAGOS_ALQUILERES = (
  <div>
    <p>
      Este importe equivale a la suma de los importes correspondientes a todos los alquileres a esta
      Oportunidad.
    </p>
    <p>
      Si necesitas ver el total de cada Propuesta aceptada, puedes verlo desglosado más abajo por
      cada Propuesta.
    </p>
  </div>
);
const TRANSFERENCIA_AL_OP_GENERAL =
  'Parte del importe que es para Homyspace en esta Oportunidad concreto.';

let paymentCustomerRate = 0;

const getAmount = price => get(price, 'amount', 0);

class FormDealDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentCustomerRate: 0,
      dealDetails: props.dealDetailsData ? props.dealDetailsData.detail : {},
      prices: {},
      confirmationData: {
        rentalRequestDataConfirmationDate: props.dealDetailsData
          ? props.dealDetailsData.detail.rentalRequestDataConfirmationDate
          : '',
        confirmationSender: props.dealDetailsData
          ? props.dealDetailsData.detail.confirmationSender
          : '',
      },
      changePhase: false,
      currentPhaseIndex: 0,
      submitting: false,
      options: {
        rentalRequestStage: getOptionsForSelect(this.props.optionLabels, 'RentalRequestStage'),
        rentalRequestType: getOptionsForSelect(this.props.optionLabels, 'RentalRequestType'),
        budgetType: getOptionsForSelect(this.props.optionLabels, 'Budget'),
      },
      showConfirmationDataDialog: false,
      alert: {
        show: false,
      },
      modalFields: {
        show: false,
      },
      modalLostDeal: {
        show: false,
      },
      alertFormFields: {
        rentalRequestStage: {},
        required: props.dealDetailsData
          ? this.getAlertRenderFieldsByPhase(props.dealDetailsData.detail.rentalRequestStage)
          : {},
        optional: props.dealDetailsData
          ? this.getAlertRenderOptionalFieldsByPhase(
              props.dealDetailsData.detail.rentalRequestStage
            )
          : {},
        lostDeal: props.dealDetailsData
          ? this.getAlertRenderFieldsByPhase(RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION)
          : {},
      },
      disableField: {
        hsagent: false,
        type: false,
        location: false,
        customerRate: false,
        providerRate: false,
        contractWritingDate: false,
        signedContract: false,
        lossReason: false,
        lossReasonDetail: false,
      },
      requiredField: {
        hsagent: false,
        customerRate: false,
      },
      lossReasonOptions: [],
      dealPreferences: {},
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.alertRequest = this.alertRequest.bind(this);
    this.checkIfButtonIsDisabled = this.checkIfButtonIsDisabled.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleNextPhase = this.handleNextPhase.bind(this);
    this.handlePreviousPhase = this.handlePreviousPhase.bind(this);
    this.handleProposalChange = this.handleProposalChange.bind(this);
    this.handleLossDeal = this.handleLossDeal.bind(this);
    this.getPhaseFields = this.getPhaseFields.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.getPreviousPhaseMessage = this.getPreviousPhaseMessage.bind(this);
    this.checkProviderRate = this.checkProviderRate.bind(this);
    this.isNotConfirmedYet = this.isNotConfirmedYet.bind(this);
    this.agentHasBeenIntroduced = this.agentHasBeenIntroduced.bind(this);
    this.onConfirmConfirmation = this.onConfirmConfirmation.bind(this);
    this.handleassignToSales = this.handleassignToSales.bind(this);
  }

  formFields = {
    DealDetailHsAgent: this.renderDealDetailHsAgent.bind(this),
    DealDetailRentalRequestStage: this.renderDealDetailRentalRequestStage.bind(this),
    DealDetailRentalRequestType: this.renderDealDetailRentalRequestType.bind(this),
    DealDetailCustomerRate: this.renderDealDetailCustomerRate.bind(this),
    DealDetailProviderRate: this.renderDealDetailProviderRate.bind(this),
    DealDetailturnoverGenerated: this.renderDealDetailturnoverGenerated.bind(this),
    DealDetailbudget: this.renderDealDetailbudget.bind(this),
    DealDetaillossReason: this.renderDealDetaillossReason.bind(this),
    DealDetailWhereEmployeesWillStayed: this.renderWhereEmployeesWillStayed.bind(this),
    DealDetailLossReasonDescription: this.renderDealDetailLossReasonDescription.bind(this),
    DealDetailcontractWritingDate: this.renderDealDetailcontractWritingDate.bind(this),
    DealDetailcontractSigningDate: this.renderDealDetailcontractSigningDate.bind(this),
    DealDetailNumberOfPeople: this.renderDealDetailNumberOfPeople.bind(this),
    DealDetailExternalSearch: this.renderDealDetailExternalSearch.bind(this),
    DealDetailRentalStartDate: this.renderDealDetailRentalStartDate.bind(this),
    DealDetailRentalEndDate: this.renderDealDetailRentalEndDate.bind(this),
    DealDetailAddress: this.renderDealDetailAddress.bind(this),
    ProposalsDetailShippingDate: this.renderProposalsDetailShippingDate.bind(this),
    ProviderPaymentInfo: this.renderProviderPaymentInfo.bind(this),
    PaymentTab: this.renderPaymentTab.bind(this),
    TaxTypeByArea: this.renderTaxTypeByArea.bind(this),
  };

  UNSAFE_componentWillMount() {
    this.getPhaseFields();
  }

  async componentDidMount() {
    try {
      const isCancelationFlagActive = await isFlagActive(
        CANCELATIONS_FLAG,
        this.props.currentUsername
      );

      const dealPreferences = await api.request(
        `${ENDPOINT_V2}/opportunities/${this.state.dealDetails.id}/preferences`
      );

      const dealPrices = await api.request(
        `${ENDPOINT_V2}/opportunities/${this.state.dealDetails.id}/pricing`
      );

      this.setState({
        lossReasonOptions: isCancelationFlagActive
          ? OPPORTUNITY_LOSS_REASONS_OPTIONS_ES
          : filter(
              OPPORTUNITY_LOSS_REASONS_OPTIONS_ES,
              option => option.value !== 'CONTRACT_RESOLUTION'
            ),
        prices: {
          ...(dealPrices.json || {}),
        },
        dealPreferences: {
          ...(dealPreferences.json || {}),
        },
      });
    } catch (error) {
      console.error(error);
      openNotificationWithIcon(
        'error',
        'Ooops',
        'Ha ocurrido un problema cargando los motivos de perdida'
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.dealDetailsData &&
      JSON.stringify(nextProps.dealDetailsData.detail) !==
        JSON.stringify(this.state.dealDetailsData)
    ) {
      this.setState(
        {
          dealDetails: nextProps.dealDetailsData.detail,
          confirmationData: {
            rentalRequestDataConfirmationDate:
              nextProps.dealDetailsData.detail.rentalRequestDataConfirmationDate,
            confirmationSender: nextProps.dealDetailsData.detail.confirmationSender,
          },
        },
        () => {
          if (this.state.submitting) {
            if (!nextProps.dealDetailsSubmit.error) {
              const invalidElements = document.getElementsByClassName('invalid-input');

              while (invalidElements.length) {
                invalidElements[0].classList.remove('invalid-input');
              }

              this.setState({
                submitting: false,
                showConfirmationDataDialog: false,
                alertFormFields: {
                  required: this.getAlertRenderFieldsByPhase(
                    nextProps.dealDetailsData.detail.rentalRequestStage
                  ),
                  optional: this.getAlertRenderOptionalFieldsByPhase(
                    nextProps.dealDetailsData.detail.rentalRequestStage
                  ),
                  lostDeal: this.getAlertRenderFieldsByPhase(
                    RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION
                  ),
                },
              });
            } else {
              this.setState({
                submitting: false,
                showConfirmationDataDialog: false,
              });
            }
          }

          if (this.state.changePhase) {
            this.setState(
              {
                changePhase: false,
              },
              () => {
                if (nextProps.dealDetailsSubmit.error) {
                  this.handleSelectChange(
                    'rentalRequestStage',
                    this.state.options.rentalRequestStage[this.state.currentPhaseIndex]
                  );
                }

                this.getPhaseFields();
              }
            );
          }
        }
      );
    }
  }

  handleSelectChange(name, selectedOption) {
    const { dealDetails } = this.state;

    set(dealDetails, name, selectedOption === null ? null : selectedOption.value);

    if (name === 'lossReason') {
      dealDetails.lossReasonDetail = null;
    }

    this.setState({ dealDetails });
  }

  checkIfSelectIsDisabled() {
    return isEmpty(OPPORTUNITY_LOSS_REASONS_DETAILS_OPTIONS_ES[this.state.dealDetails.lossReason]);
  }

  isNotConfirmedYet() {
    return !this.state.confirmationData.rentalRequestDataConfirmationDate;
  }

  isValidForm() {
    const inputsToValidate = document.getElementsByClassName('validate');

    validateInputs(inputsToValidate);
    this.checkProviderRate();

    return true;
  }

  checkProviderRate() {
    if (this.state.dealDetails.providerRate === null) {
      const { dealDetails } = this.state;

      delete dealDetails.providerRate;

      dealDetails.providerRate = {
        amount: 0,
        currency: 'EUR',
      };

      this.setState({ dealDetails });
    }
  }

  agentHasBeenIntroduced() {
    return this.state.dealDetails.hsagent;
  }

  checkDates(dateStart, dateEnd) {
    if (!areValidDates(dateStart, dateEnd)) {
      openNotificationWithIcon(
        'error',
        'Meeeeeec =(',
        'Hay un problema con las fechas introducidas. Por favor, revísalo.'
      );

      return false;
    }

    return true;
  }

  handleSubmit() {
    if (
      this.isValidForm() &&
      this.checkDates(
        this.state.dealDetails.rentalStartDate,
        this.state.dealDetails.rentalEndDate
      ) &&
      this.checkRequiredFieldsFor('submit')
    ) {
      if (this.isNotConfirmedYet() && this.agentHasBeenIntroduced()) {
        this.setState({ showConfirmationDataDialog: true });
      } else {
        this.setState(
          {
            submitting: true,
          },
          () => {
            this.props.actions.saveDealDetailsData(this.state.dealDetails);
          }
        );
      }
    }
  }

  checkRequiredFieldsFor(action) {
    let isFilled;

    if (action === 'increase') {
      const inputsToValidate = document.getElementsByClassName('validate');

      isFilled = validateInputs(inputsToValidate);
    } else {
      isFilled = true;
    }

    if (!isFilled) {
      openNotificationWithIcon(
        'error',
        'Woopsy!! :O',
        'Se te ha pasado rellenar alguno de los campos obligatorios. Por favor, completa todos los campos antes de proceder!'
      );
    } else if (this.getStageIndex() === 3 && action === 'increase') {
      isFilled = this.checkStageOfProposals();
    }

    return isFilled;
  }

  checkStageOfProposals() {
    const { proposals } = this.state.dealDetails;
    let hasOneAcceptedProposal = false;

    for (const [index, value] of proposals.entries()) {
      const { proposalStage, dateCheckIn, dateCheckOut } = value.proposal ? value.proposal : value;

      if (proposalStage === 'ACCEPTED') {
        if (!dateCheckIn || !dateCheckOut) {
          openNotificationWithIcon(
            'error',
            'Espejito espejito mágico',
            `Las Propuestas asociadas en etapa "Aceptada" deben de tener las fechas de entrada y salida informadas. ¡La Propuesta número ${index +
              1}, no lo está!`
          );

          return false;
        }

        hasOneAcceptedProposal = true;
      } else if (proposalStage !== 'REJECTED') {
        openNotificationWithIcon(
          'error',
          'Oh nononono',
          `Todas las Propuestas asociadas deben encontrarse en el estado de "Aceptada" o "Rechazada". ¡La Propuesta número ${index +
            1}, no lo está!`
        );

        return false;
      }
    }

    if (!hasOneAcceptedProposal) {
      openNotificationWithIcon(
        'error',
        'Rechazadito',
        `Debe existir al menos una Propuesta con estado "Aceptada" para avanzar a la siguiente etapa.`
      );
    }

    return hasOneAcceptedProposal;
  }

  checkIfButtonIsDisabled(buttonType) {
    let disabled;

    switch (buttonType) {
      case 'submit':
        disabled = this.state.submitting;
        break;
      case 'increasePhase':
        disabled =
          this.state.submitting ||
          this.state.dealDetails.rentalRequestStage ===
            RENTAL_REQUEST_STAGES.S050_RENTAL_CONFIRMED ||
          this.state.dealDetails.rentalRequestStage === RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION;
        break;
      case 'lossDeal':
        disabled = this.state.submitting || this.getStageIndex() > 6;
        break;
      case 'decreasePhase':
        disabled = this.state.submitting || this.getStageIndex() === 0;
        break;
      case 'delete':
        disabled =
          this.state.submitting ||
          this.state.dealDetails.rentalRequestStage ===
            RENTAL_REQUEST_STAGES.S050_RENTAL_CONFIRMED ||
          this.state.dealDetails.rentalRequestStage === RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION;
        break;
      default:
        disabled = true;
    }

    return disabled;
  }

  handleDelete() {
    if (this.state.submitting) {
      return;
    }

    if (this.dealHasAnyProposal()) {
      openNotificationWithIcon(
        'error',
        'Nope!',
        'No puedes eliminar una oportunidad con propuestas asociadas!'
      );

      return;
    }

    if (this.getStageIndex() >= 4) {
      openNotificationWithIcon(
        'error',
        'Nope!',
        "Sólo puedes eliminar una oportunidad en una etapa anterior a 'Gestión contrato/factura'"
      );

      return;
    }

    this.setState({
      alert: {
        show: true,
        header: 'Eliminación de la oportunidad',
        message: `Se va a proceder a eliminar la oportunidad de ${this.state.dealDetails.companyName} con código ${this.state.dealDetails.identificationCode}. ¿Estás seguro?`,
        onConfirmClick: this.onAlertConfirmClick.bind(this),
      },
    });
  }

  nestedKeys(obj, is, value) {
    if (typeof is === 'string') {
      return this.nestedKeys(obj, is.split('.'), value);
    }

    if (is.length === 1 && value !== 'undefined') {
      return (obj[is[0]] = value);
    }

    if (is.length === 0) {
      return obj;
    }

    return this.nestedKeys(obj[is[0]], is.slice(1), value);
  }

  handleProposalChange(e) {
    const { dealDetails } = this.state;

    dealDetails.proposals[e.target.attributes['data-index'].value].proposal.shippingDate = e.target
      .value
      ? moment(e.target.value)
      : null;

    this.setState({ dealDetails });
  }

  handleChange(e) {
    const { dealDetails } = this.state;
    let value;

    if (e.target.name === 'hsagent') {
      value = { username: e.target.value.trim() };
    } else {
      value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    }

    if (e.target.name.indexOf('.') >= 1) {
      this.nestedKeys(dealDetails, e.target.name, value);
    } else {
      dealDetails[e.target.name] = value;
    }

    this.setState({ dealDetails });
  }

  handlePlaceChange(place) {
    const { dealDetails } = this.state;

    dealDetails.address = place;
    this.setState({ dealDetails });
  }

  handleDateChange = e => {
    if (e.target.value && !isValidDate(e.target.value)) {
      openNotificationWithIcon('error', 'Quo vadis?', 'Comprueba la fecha... ¡Algo no cuadra!');
    }
  };

  handlePriceChange(e) {
    const { dealDetails } = this.state;

    const field = e.target.name.includes('.') ? e.target.name.split('.')[0] : e.target.name;
    let newPrice;

    if (e.target.value && e.target.value.length > 0) {
      newPrice = Object.assign({}, this.state.dealDetails[field]);
      newPrice.amount = parseFloat(e.target.value);
      newPrice.currency = 'EUR';
    } else {
      newPrice = null;
    }

    dealDetails[field] = newPrice;
    this.setState({ dealDetails });
  }

  handleCompanyChange(label, company, obj) {
    const { dealDetails } = this.state;

    dealDetails.companyName = company != null ? company.label : '';
    dealDetails.companyId = company != null ? company.value : '';
    dealDetails.contactsOfCompany =
      company != null
        ? [
            {
              idOfContact: company.value,
              contact: obj,
              roles: ['UNKNOWN'],
            },
          ]
        : [];
    this.setState({ dealDetails });
  }

  handleNextPhase() {
    this.setState({
      modalFields: {
        show: true,
        title: 'Avanzar Etapa',
        onConfirmPhase: this.increasePhase.bind(this),
      },
    });
  }

  handleLossDeal() {
    this.setState({
      changePhase: true,
      modalLostDeal: {
        show: true,
        lostDealMessage: 'Se va a dar la oportunidad como perdida. ¿Estás seguro?',
        lostDealTitle: 'Oportunidad perdida',
        lostDealOnConfirmPhase: this.increasePhase.bind(this),
        onConfirmLost: this.lostDeal.bind(this),
      },
    });
  }

  lostDeal() {
    const inputsToValidate = document.getElementsByClassName('lost-deal-validate');

    if (validateInputs(inputsToValidate)) {
      this.setState(
        {
          alert: {
            show: false,
          },
          modalLostDeal: {
            show: false,
          },
          submitting: true,
          changePhase: true,
          currentPhaseIndex: this.getStageIndex(),
        },
        () => {
          this.handleSelectChange(
            'rentalRequestStage',
            this.state.options.rentalRequestStage[this.state.options.rentalRequestStage.length - 1]
          );
          this.props.actions.saveDealDetailsData(this.state.dealDetails);
        }
      );
    } else {
      openNotificationWithIcon(
        'error',
        'Ops!!!',
        'Se te ha pasado rellenar alguno de los campos obligatorios. Por favor, completa todos los campos antes de proceder!'
      );
    }
  }

  increasePhase() {
    if (this.isValidForm() && this.checkRequiredFieldsFor('increase')) {
      this.setState(
        {
          modalFields: {
            show: false,
          },
          submitting: true,
          changePhase: true,
          currentPhaseIndex: this.getStageIndex(),
        },
        () => {
          this.handleSelectChange(
            'rentalRequestStage',
            this.state.options.rentalRequestStage[this.getStageIndex() + 1]
          );
          this.props.actions.saveDealDetailsData(this.state.dealDetails);
        }
      );
    }
  }

  decreasePhase() {
    if (this.getStageIndex() === 6) {
      this.setState(
        {
          alert: {
            show: false,
          },
          submitting: true,
          changePhase: true,
        },
        () => {
          this.handleSelectChange('rentalRequestStage', this.state.options.rentalRequestStage[1]);
          this.props.actions.saveDealDetailsData(this.state.dealDetails);
        }
      );
    } else if (this.checkRequiredFieldsFor('decrease')) {
      this.setState(
        {
          alert: {
            show: false,
          },
          submitting: true,
          changePhase: true,
          currentPhaseIndex: this.getStageIndex(),
        },
        () => {
          this.handleSelectChange(
            'rentalRequestStage',
            this.state.options.rentalRequestStage[this.getStageIndex() - 1]
          );
          this.props.actions.saveDealDetailsData(this.state.dealDetails);
        }
      );
    }
  }

  getPreviousPhaseMessage() {
    return this.getStageIndex() === 6
      ? getTextValueFromSelectOptions(
          this.state.options.rentalRequestStage[1].value,
          this.state.options.rentalRequestStage
        )
      : getTextValueFromSelectOptions(
          this.state.options.rentalRequestStage[this.getStageIndex() - 1].value,
          this.state.options.rentalRequestStage
        );
  }

  handlePreviousPhase() {
    this.setState({
      alert: {
        show: true,
        header: 'Cambio de etapa de la oportunidad',
        message: `Se va a proceder a pasar del estado de oportunidad "${getTextValueFromSelectOptions(
          this.state.dealDetails.rentalRequestStage,
          this.state.options.rentalRequestStage
        )}" al estado de oportunidad "${this.getPreviousPhaseMessage()}". ¿Estás seguro?`,
        onConfirmClick: this.decreasePhase.bind(this),
      },
    });
  }

  getStageIndex() {
    const stages = this.state.options.rentalRequestStage;
    const { rentalRequestStage } = this.state.dealDetails;
    let stageIndex = 0;

    stages.forEach((stage, index) => {
      if (stage.value === rentalRequestStage) {
        stageIndex = index;
      }
    });

    return stageIndex;
  }

  dealHasAnyProposal() {
    return this.state.dealDetails.proposals && this.state.dealDetails.proposals.length > 0;
  }

  onAlertCancelClick() {
    this.setState({
      alert: {
        show: false,
      },
      modalFields: {
        show: false,
      },
      modalLostDeal: {
        show: false,
      },
    });
  }

  alertRequest() {
    return this.props.actions.deleteDealDetailsData(this.state.dealDetails);
  }

  onAlertConfirmClick() {
    this.setState(
      {
        alert: {
          show: false,
        },
        submitting: true,
      },
      () => {
        this.alertRequest();
      }
    );
  }

  getPhaseFields() {
    switch (this.state.dealDetails.rentalRequestStage) {
      case RENTAL_REQUEST_STAGES.S010_PROPERTY_SEARCH:
        this.setState({
          disableField: {
            type: true,
            location: true,
          },
          requiredField: {
            hsagent: true,
          },
        });
        break;
      case RENTAL_REQUEST_STAGES.S020_SEND_SEARCH_RESULTS:
        this.setState({
          disableField: {
            type: true,
            location: true,
          },
          requiredField: {
            hsagent: true,
            customerRate: true,
          },
        });

        break;
      case RENTAL_REQUEST_STAGES.S030_CONTRACT_INVOICE_MANAGMENT:
        this.setState({
          disableField: {
            type: true,
            location: true,
            customerRate: true,
          },
          requiredField: {
            hsagent: true,
          },
        });
        break;
      case RENTAL_REQUEST_STAGES.S040_CHECK_IN:
        this.setState({
          disableField: {
            type: true,
            location: true,
            customerRate: true,
            providerRate: true,
            turnoverGenerated: true,
          },
          requiredField: {
            hsagent: true,
          },
        });
        break;
      case RENTAL_REQUEST_STAGES.S050_RENTAL_CONFIRMED:
      case RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION:
        this.setState({
          disableField: {
            hsagent: true,
            type: true,
            location: true,
            customerRate: true,
            providerRate: true,
            turnoverGenerated: true,
            contractWritingDate: true,
            signedContract: true,
            lossReason: true,
            lossReasonDetail: true,
          },
        });
        break;
      default: {
        const fields = this.state.disableField;

        Object.keys(fields).forEach(v => (fields[v] = false));
        this.setState({
          disableField: fields,
        });
        break;
      }
    }
  }

  getProposalsDetailShippingDate() {
    const proposalsDetailShippingDate = [];

    if (typeof this.state.dealDetails.proposals === 'undefined') {
      return proposalsDetailShippingDate;
    }

    return this.state.dealDetails.proposals.map((currentValue, index) => {
      if (
        currentValue.proposal.proposalStage === 'SHIPPED' ||
        currentValue.proposal.proposalStage === 'ACCEPTED'
      ) {
        return (
          <div className='form-group' key={`shipping-date-${index}`}>
            <label htmlFor='proposals'>Propuesta {index + 1}: Fecha de envío</label>
            <input
              type='date'
              className='form-control'
              id='proposalDetailShippingDate'
              name='shippingDate'
              value={
                currentValue.proposal.shippingDate
                  ? moment(currentValue.proposal.shippingDate).format(PARSE_DATE_FORMAT)
                  : ''
              }
            />
          </div>
        );
      }

      return <div key={`shipping-date-${index}`} />;
    });
  }

  getAlertRenderFieldsByPhase(phase) {
    switch (phase) {
      case RENTAL_REQUEST_STAGES.S000_REQUEST:
        return [
          'DealDetailRentalRequestStage',
          'DealDetailHsAgent',
          'DealDetailRentalRequestType',
          'DealDetailAddress',
        ];
      case RENTAL_REQUEST_STAGES.S020_SEND_SEARCH_RESULTS:
      case RENTAL_REQUEST_STAGES.S030_CONTRACT_INVOICE_MANAGMENT:
        return [
          'DealDetailRentalRequestStage',
          'DealDetailHsAgent',
          'DealDetailRentalRequestType',
          'DealDetailCustomerRate',
          'DealDetailturnoverGenerated',
        ];
      case RENTAL_REQUEST_STAGES.S040_CHECK_IN:
        return [
          'DealDetailRentalRequestStage',
          'DealDetailHsAgent',
          'DealDetailCustomerRate',
          'DealDetailcontractWritingDate',
          'DealDetailcontractSigningDate',
          'DealDetailProviderRate',
        ];
      case RENTAL_REQUEST_STAGES.S070_LOST_NEGOTATION:
        return [
          'DealDetaillossReason',
          'DealDetailLossReasonDescription',
          'DealDetailWhereEmployeesWillStayed',
        ];
      default:
        return [];
    }
  }

  getAlertRenderOptionalFieldsByPhase(phase) {
    switch (phase) {
      case RENTAL_REQUEST_STAGES.S000_REQUEST:
        return ['DealDetailbudget'];
      case RENTAL_REQUEST_STAGES.S010_PROPERTY_SEARCH:
        return [
          'DealDetailRentalRequestStage',
          'DealDetailRentalRequestType',
          'DealDetailCustomerRate',
          'ProposalsDetailShippingDate',
        ];
      case RENTAL_REQUEST_STAGES.S020_SEND_SEARCH_RESULTS:
        return [
          'DealDetailNumberOfPeople',
          'DealDetailRentalStartDate',
          'DealDetailRentalEndDate',
          'DealDetailProviderRate',
        ];
      case RENTAL_REQUEST_STAGES.S030_CONTRACT_INVOICE_MANAGMENT:
        return ['DealDetailProviderRate'];
      default:
        return [];
    }
  }

  isRequired(field) {
    return (
      !!this.state.alertFormFields.required &&
      this.state.alertFormFields.required.indexOf(field) > -1
    );
  }

  isRequiredForLostDeal(field) {
    return (
      !!this.state.alertFormFields.lostDeal &&
      this.state.alertFormFields.lostDeal.indexOf(field) > -1
    );
  }

  getAcceptedProposals() {
    const { proposals } = this.state.dealDetails;
    let filterProposals;

    if (proposals) {
      filterProposals = proposals.filter((proposal, i) => {
        const prop = proposal.proposal ? proposal.proposal : proposal;

        prop.arrayPosition = i + 1;

        return prop.proposalStage === 'ACCEPTED';
      });

      return filterProposals;
    }

    return false;
  }

  renderDealDetailRentalRequestStage() {
    return (
      <div className='form-group'>
        <label htmlFor='dealDetailRentalRequestStage' className='required'>
          Etapa de la oportunidad
        </label>
        <input
          type='text'
          className='form-control dealBackgroundColor colorWhite bold noBorder'
          id='dealDetailRentalRequestStage'
          name='rentalRequestStage'
          readOnly
          value={
            this.state.dealDetails.rentalRequestStage
              ? getTextValueFromSelectOptions(
                  this.state.dealDetails.rentalRequestStage,
                  this.state.options.rentalRequestStage
                )
              : ''
          }
        />
        <Progress
          status='active'
          showInfo={false}
          percent={getEndingPercentDealStage(
            this.state.dealDetails.rentalRequestStage,
            this.state.options.rentalRequestStage
          )}
        />
      </div>
    );
  }

  renderDealDetailRentalRequestType() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailRentalRequestType'
          className={this.isRequired('DealDetailRentalRequestType') ? 'required' : ''}
        >
          Tipo
        </label>
        <Select
          id='dealDetailRentalRequestType'
          name='rentalRequestType'
          className={
            this.isRequired('DealDetailRentalRequestType')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          disabled={this.state.disableField.type}
          value={
            this.state.dealDetails.rentalRequestType ? this.state.dealDetails.rentalRequestType : ''
          }
          options={this.state.options.rentalRequestType}
          onChange={value => {
            this.handleSelectChange('rentalRequestType', value);
          }}
        />
      </div>
    );
  }

  renderDealDetailbudget() {
    return (
      <div className='form-group'>
        <div className='d-inline-block w-50'>
          <label
            htmlFor='dealDetailbudget'
            className={this.isRequired('DealDetailbudget') ? 'required' : ''}
          >
            Presupuesto
          </label>
          <input
            type='number'
            className={
              this.isRequired('DealDetailbudget')
                ? 'form-control validate validate-required budget-amount-input'
                : 'form-control budget-amount-input'
            }
            id='dealDetailbudget'
            name='budget.amount'
            placeholder=''
            value={this.state.dealDetails.budget ? this.state.dealDetails.budget.amount : ''}
            onChange={this.handlePriceChange}
          />
        </div>
        <div className='d-inline-block w-50 align-bottom'>
          <label
            htmlFor='dealDetailbudget'
            className={this.isRequired('DealDetailbudget') ? 'required' : ''}
          >
            Tipo
          </label>
          <SelectAntd
            name='otherRequestInfo.budget'
            value={
              this.state.dealDetails.otherRequestInfo &&
              this.state.dealDetails.otherRequestInfo.budget
                ? this.state.dealDetails.otherRequestInfo.budget
                : ''
            }
            allowClear
            showSearch
            onChange={value => {
              this.handleSelectChange('otherRequestInfo.budget', value ? { value } : null);
            }}
          >
            {!isEmpty(this.state.options.budgetType) &&
              this.state.options.budgetType.map(option => (
                <SelectAntd.Option key={option.value} value={option.value}>
                  {option.label}
                </SelectAntd.Option>
              ))}
          </SelectAntd>
        </div>
      </div>
    );
  }

  renderDealDetaillossReason() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetaillossReason'
          className={this.isRequiredForLostDeal('DealDetaillossReason') ? 'required' : ''}
        >
          Motivo de pérdida
        </label>
        <Select
          id='dealDetaillossReason'
          name='lossReason'
          className={
            this.isRequiredForLostDeal('DealDetaillossReason')
              ? 'form-control lost-deal-validate validate-required to-front'
              : 'form-control to-front'
          }
          disabled={this.state.disableField.lossReason}
          value={this.state.dealDetails.lossReason ? this.state.dealDetails.lossReason : ''}
          options={getOptionsForSelectWithDeprecationSupport(
            this.state.lossReasonOptions,
            this.state.dealDetails.lossReason
          )}
          onChange={value => this.handleSelectChange('lossReason', value)}
        />
      </div>
    );
  }

  renderWhereEmployeesWillStayed() {
    return (
      <div className='form-group'>
        <label htmlFor='dealDetailwhereHaveTheyStayed'>Comentarios</label>
        <input
          type='text'
          className='form-control'
          id='dealDetailwhereHaveTheyStayed'
          name='whereHaveTheyStayed'
          placeholder='Ejemplo: ¿Dónde se han alojado?'
          value={
            this.state.dealDetails.whereHaveTheyStayed
              ? this.state.dealDetails.whereHaveTheyStayed
              : ''
          }
          onChange={this.handleChange}
        />
      </div>
    );
  }

  renderDealDetailLossReasonDescription() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailLossReasonDescription'
          className={
            this.isRequiredForLostDeal('DealDetailLossReasonDescription') ? 'required' : ''
          }
        >
          Detalle del motivo de pérdida
        </label>
        <Select
          id='dealDetailLossReasonDescription'
          name='lossReasonDetail'
          className={
            this.isRequiredForLostDeal('DealDetailLossReasonDescription') &&
            !this.checkIfSelectIsDisabled()
              ? 'form-control lost-deal-validate validate-required'
              : 'form-control'
          }
          value={
            this.state.dealDetails.lossReasonDetail ? this.state.dealDetails.lossReasonDetail : ''
          }
          disabled={this.checkIfSelectIsDisabled()}
          options={getOptionsForSelectWithDeprecationSupport(
            OPPORTUNITY_LOSS_REASONS_DETAILS_OPTIONS_ES[this.state.dealDetails.lossReason],
            this.state.dealDetails.lossReasonDetail
          )}
          onChange={value => this.handleSelectChange('lossReasonDetail', value)}
        />
      </div>
    );
  }

  renderDealDetailcontractWritingDate() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailcontractWritingDate'
          className={this.isRequired('DealDetailcontractWritingDate') ? 'required' : ''}
        >
          Contrato enviado
        </label>
        <input
          type='date'
          className={
            this.isRequired('DealDetailcontractWritingDate')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          id='dealDetailcontractWritingDate'
          name='contractWritingDate'
          placeholder='19/10/2017'
          disabled={this.state.disableField.contractWritingDate}
          value={
            this.state.dealDetails.contractWritingDate
              ? moment(this.state.dealDetails.contractWritingDate).format(PARSE_DATE_FORMAT)
              : ''
          }
          onChange={this.handleChange}
        />
      </div>
    );
  }

  renderDealDetailcontractSigningDate() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailcontractSigningDate'
          className={this.isRequired('DealDetailcontractSigningDate') ? 'required' : ''}
        >
          Contrato firmado
        </label>
        <input
          type='date'
          className={
            this.isRequired('DealDetailcontractSigningDate')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          id='dealDetailcontractSigningDate'
          name='contractSigningDate'
          placeholder='19/10/2017'
          disabled={this.state.disableField.contractSigningDate}
          value={
            this.state.dealDetails.contractSigningDate
              ? moment(this.state.dealDetails.contractSigningDate).format(PARSE_DATE_FORMAT)
              : ''
          }
          onChange={this.handleChange}
        />
      </div>
    );
  }

  renderDealDetailNumberOfPeople() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailNumberOfPeople'
          className={this.isRequired('DealDetailNumberOfPeople') ? 'required' : ''}
        >
          PAX
        </label>
        <input
          type='number'
          className={
            this.isRequired('DealDetailNumberOfPeople')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          id='dealDetailNumberOfPeople'
          name='numberOfPeople'
          placeholder='4'
          value={this.state.dealDetails.numberOfPeople ? this.state.dealDetails.numberOfPeople : ''}
          onChange={this.handleChange}
        />
      </div>
    );
  }
  renderDealDetailExternalSearch() {
    return (
      <div className='form-group'>
        <label htmlFor='dealDetailExternalSearch'>Busquedas externas</label>
        <input
          type='number'
          className='form-control'
          id='dealDetailExternalSearch'
          name='numberOfExternalSearches'
          placeholder='0'
          value={
            this.state.dealDetails.numberOfExternalSearches
              ? this.state.dealDetails.numberOfExternalSearches
              : ''
          }
          onChange={this.handleChange}
        />
      </div>
    );
  }

  renderDealDetailRentalStartDate() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailRentalStartDate'
          className={this.isRequired('DealDetailRentalStartDate') ? 'required' : ''}
        >
          Fecha de inicio de alquiler
        </label>
        <input
          type='date'
          className={
            this.isRequired('DealDetailRentalStartDate')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          id='dealDetailRentalStartDate'
          name='rentalStartDate'
          placeholder='01/10/2017'
          value={
            this.state.dealDetails.rentalStartDate
              ? moment(this.state.dealDetails.rentalStartDate).format(PARSE_DATE_FORMAT)
              : ''
          }
          onChange={this.handleChange}
          onBlur={this.handleDateChange}
        />
      </div>
    );
  }

  renderDealDetailRentalEndDate() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailRentalEndDate'
          className={this.isRequired('DealDetailRentalEndDate') ? 'required' : ''}
        >
          Fecha fin de alquiler
        </label>
        <input
          type='date'
          className={
            this.isRequired('DealDetailRentalEndDate')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          id='dealDetailRentalEndDate'
          name='rentalEndDate'
          placeholder='19/10/2017'
          value={
            this.state.dealDetails.rentalEndDate
              ? moment(this.state.dealDetails.rentalEndDate).format(PARSE_DATE_FORMAT)
              : ''
          }
          onChange={this.handleChange}
          onBlur={this.handleDateChange}
        />
      </div>
    );
  }

  renderDealDetailAddress() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailAddress'
          className={this.isRequired('DealDetailAddress') ? 'required' : ''}
        >
          Dirección exacta a la que se desplazan
        </label>
        <GoogleMapsAutocomplete
          id='dealDetailAddress'
          inputClassName={
            this.isRequired('DealDetailAddress')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          handlePlaceChanged={this.handlePlaceChange}
          disabled={this.state.disableField.location}
          initValue={this.state.dealDetails.address}
        />
      </div>
    );
  }

  renderDealDetailCustomerRate() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailCustomerRate'
          className={this.isRequired('DealDetailCustomerRate') ? 'required' : ''}
        >
          Tarifa Cliente
        </label>
        <input
          type='number'
          className={
            this.isRequired('DealDetailCustomerRate')
              ? 'form-control dealBackgroundColor colorWhite bold noBorder validate validate-required'
              : 'form-control dealBackgroundColor colorWhite bold noBorder'
          }
          id='dealDetailCustomerRate'
          name='customerRate.amount'
          placeholder=''
          disabled={this.state.disableField.customerRate}
          required={this.state.requiredField.customerRate}
          value={
            this.state.dealDetails.customerRate ? this.state.dealDetails.customerRate.amount : ''
          }
          onChange={this.handlePriceChange}
        />
      </div>
    );
  }

  renderDealDetailProviderRate() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailProviderRate'
          className={this.isRequired('DealDetailProviderRate') ? 'required' : ''}
        >
          Tarifa Proveedor (global)
        </label>
        <input
          type='number'
          className='form-control'
          id='dealDetailProviderRate'
          name='providerRate.amount'
          disabled
          placeholder=''
          readOnly
          value={
            this.state.prices.providerRate ? round(this.state.prices.providerRate.amount, 2) : 0
          }
          onChange={this.handlePriceChange}
        />
      </div>
    );
  }

  renderDealDetailturnoverGenerated() {
    return (
      <div className='form-group'>
        <Tooltip
          placement='topLeft'
          title='Negocio generado con el alquiler (importe total que ha supuesto el desplazamiento).'
        >
          <label
            htmlFor='dealDetailturnoverGenerated'
            className={this.isRequired('DealDetailturnoverGenerated') ? 'required' : ''}
          >
            Negocio generado (GMV)
          </label>{' '}
          <i className='far fa-question-circle' style={{ fontSize: `${14}px` }} />
        </Tooltip>

        <input
          type='number'
          className={
            this.isRequired('DealDetailturnoverGenerated')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          id='dealDetailturnoverGenerated'
          name='turnoverGenerated.amount'
          placeholder=''
          disabled
          value={
            this.state.prices.turnoverGenerated
              ? round(this.state.prices.turnoverGenerated.amount, 2)
              : ''
          }
        />
      </div>
    );
  }

  renderProposalsDetailShippingDate() {
    if (this.state.dealDetails.proposals) {
      return this.state.dealDetails.proposals.map(function(value, index) {
        if (
          value.proposal &&
          (value.proposal.proposalStage === 'SHIPPED' ||
            value.proposal.proposalStage === 'ACCEPTED')
        ) {
          return (
            <div className='form-group' key={`shipping-date-${index}`}>
              <label htmlFor='proposals'>Propuesta {index + 1}: Fecha de envío</label>
              <input
                type='date'
                className='form-control'
                name='shippingDate'
                readOnly
                value={
                  value.proposal.shippingDate
                    ? moment(value.proposal.shippingDate).format(PARSE_DATE_FORMAT)
                    : ''
                }
                onChange={this.handleProposalChange}
              />
            </div>
          );
        }

        return <div key={`shipping-date-${index}`} />;
      }, this);
    }
  }

  renderDealDetailHsAgent() {
    return (
      <div className='form-group'>
        <label
          htmlFor='dealDetailHsAgent'
          className={this.isRequired('DealDetailHsAgent') ? 'required' : ''}
        >
          Gestor asignado
        </label>
        <input
          type='text'
          className={
            this.isRequired('DealDetailHsAgent')
              ? 'form-control validate validate-required'
              : 'form-control'
          }
          id='dealDetailHsAgent'
          name='hsagent'
          placeholder='morty@homyspace.com'
          disabled={this.state.disableField.hsagent}
          required={this.state.requiredField.hsagent}
          value={
            this.state.dealDetails.hsagent && this.state.dealDetails.hsagent.username
              ? this.state.dealDetails.hsagent.username
              : ''
          }
          onChange={this.handleChange}
        />
      </div>
    );
  }

  componentDidUpdate() {
    if (this.state.paymentCustomerRate !== paymentCustomerRate) {
      this.setState({
        paymentCustomerRate,
      });
    }
  }

  renderTaxTypeByArea() {
    const customerRateDetail = get(this.state, 'prices.customerRateDetail', []);

    const customerRateDetailsByTaxType = reduce(
      customerRateDetail,
      (result, detail) => ({
        ...result,
        [detail.customerRateTaxType]: {
          count: get(result, `[${detail.customerRateTaxType}].count`, 0) + 1,
          amount:
            get(result, `[${detail.customerRateTaxType}].amount`, 0) +
            getAmount(detail.customerRateSlice),
          amountWithTax:
            get(result, `[${detail.customerRateTaxType}].amountWithTax`, 0) +
            getAmount(detail.customerRateSliceWithTax),
        },
      }),
      {}
    );

    if (size(customerRateDetailsByTaxType) < 2) {
      return (
        <div style={{ fontSize: '14px' }}>
          <b>Tipo Impositivo: </b>
          {get(first(customerRateDetail), 'customerRateTaxPercentage', '')}%
        </div>
      );
    }

    return (
      <div style={{ fontSize: '14px' }}>
        <b>Desglose de Tarifa Cliente</b>
        <div className='divTable'>
          <div className='divTableBody'>
            <div className='divTableRow'>
              <div className='divTableCell'>
                <b>Propuestas reservadas</b>
              </div>
              <div className='divTableCell'>
                <b>Impuesto</b>
              </div>
              <div className='divTableCell'>
                <b>Base imponible</b>
              </div>
              <div className='divTableCell'>
                <b>Total</b>
              </div>
            </div>
            {map(customerRateDetailsByTaxType, (rateDetail, taxType) => (
              <div className='divTableRow'>
                <div className='divTableCell'>{rateDetail.count}</div>
                <div className='divTableCell'>{TAX_TYPE_LABELS_ES[taxType]}</div>
                <div className='divTableCell'>{rateDetail.amount} €</div>
                <div className='divTableCell'>{rateDetail.amountWithTax} €</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderPaymentTab() {
    const PROPUESTA_TOTAL =
      'Este es el importe total correspondiente al pago del alquiler de esta Propuesta. En el están incluidos el alquiler y la Comisión al Proveedor.';
    const COMISION_AL_PROVEEDOR =
      'Corresponde a la comisión al Proveedor (imp. incluidos) que le hemos de sustraer al pago total de la Propuesta.';
    const TRANSFERENCIA_AL_PROVEEDOR =
      'Corresponde al importe que le hemos de transferir al Proveedor de la Propuesta, una vez sustraído nuestra comisión al él.';

    let proposalsAccepted;
    const proposalRender = [];
    const { proposals } = this.state.dealDetails;
    const proposalsPricing = get(this.state, 'prices.proposalPricing', []);

    if (proposals) {
      proposalsAccepted = this.getAcceptedProposals();

      if (proposalsAccepted) {
        proposalsAccepted.forEach((proposal, i) => {
          const prop = proposal.proposal ? proposal.proposal : proposal;
          const proposalPricing = filter(proposalsPricing, ['proposalId', prop.id])[0] || {};

          proposalRender.push(
            <div className='col-sm-12' style={{ display: 'inline-flex', alignItems: 'baseline' }}>
              <Tooltip placement='topLeft' title={PROPUESTA_TOTAL}>
                <i className='far fa-question-circle' />
              </Tooltip>
              <Collapse bordered={false} defaultActiveKey={['0']} style={{ width: '100%' }}>
                <Panel
                  header={`Propuesta ${prop.arrayPosition}. Total: ${amountToString(
                    proposalPricing.customerRentPayment
                  )}`}
                  key={i}
                >
                  <div style={{ fontSize: '14px' }}>
                    <span>
                      <b>Renta impuestos incluidos:</b>{' '}
                      {amountToString(
                        proposalPricing.paymentType === 'MONTHLY_PAYMENT'
                          ? proposalPricing.monthlyPriceWithTaxDossier
                          : proposalPricing.totalPriceWithTaxDossier
                      )}
                    </span>
                    <br />
                    <span>
                      <b>Fianza:</b> {getAmount(prop.deposit)}€
                    </span>
                    <br />
                    {(isEmpty(prop.cleaningChargeType) ||
                      get(prop, 'cleaningChargeType', 'FINAL') === 'FINAL') && (
                      <span>
                        <b>Gastos de Limpieza (imp. incluidos):</b>{' '}
                        {amountToString(proposalPricing.cleaningChargesWithTax)}
                      </span>
                    )}
                  </div>
                  <br />
                  <div style={{ marginLeft: '3%' }}>
                    <div style={{ fontSize: '14px' }}>
                      <h6>
                        <Tooltip placement='topLeft' title={COMISION_AL_PROVEEDOR}>
                          <i className='far fa-question-circle' />
                        </Tooltip>{' '}
                        Comisión al Proveedor. Total:{' '}
                        {getAmount(proposalPricing.providerCommissionWithTax)} €
                      </h6>
                      <hr className='hs-hr' />
                    </div>
                    <br />
                    <div style={{ fontSize: '14px' }}>
                      <h6>
                        <Tooltip placement='topLeft' title={TRANSFERENCIA_AL_PROVEEDOR}>
                          <i className='far fa-question-circle' />
                        </Tooltip>{' '}
                        Transferencia al Proveedor. Total:{' '}
                        {amountToString(proposalPricing.amountToTransferToProvider)} <br />
                        <br />
                        {this.formFields.ProviderPaymentInfo(prop)}
                      </h6>
                      <hr className='hs-hr' />
                    </div>
                    <br />
                  </div>
                </Panel>
              </Collapse>
              <br />
            </div>
          );
        });
      }

      return proposalRender;
    }
  }

  renderProviderPaymentInfo(proposal) {
    if (proposal) {
      const landlord = proposal.contactsOfProvider.find(contact =>
        contact.roles.includes('LANDLORD')
      );

      return (
        <div style={{ fontSize: '14px' }}>
          {landlord && (
            <div>
              <span>
                <b>Arrendador:</b>{' '}
                {get(landlord, 'contact.contactType') === 'PERSON'
                  ? `${landlord.contact.name} ${landlord.contact.lastOTradeName}`
                  : landlord.contact.lastOTradeName || ''}
              </span>
              <br />
              <span>
                <b>Email:</b> {landlord.contact.email}
              </span>
              <br />
              <span>
                <b>Numero de cuente (IBAN):</b> {landlord.contact.accountNumber}
              </span>
              <br />
              <span>
                <b>Beneficiario de la cuenta:</b> {landlord.contact.beneficiary}
              </span>
              <br />
              <span>
                <b>BIC / SWIFT:</b> {landlord.contact.bicOrSwift}
              </span>
            </div>
          )}
          {!landlord && <span>No se encuentra ningun contacto ARRENDADOR en esta Propuesta.</span>}
        </div>
      );
    }
  }

  onConfirmConfirmation() {
    this.setState(
      {
        submitting: true,
        showConfirmationDataDialog: false,
      },
      () => {
        this.props.actions.saveDealDetailsData(this.state.dealDetails);
      }
    );
  }

  calculateTimeToFirstProposal() {
    let seconds = Math.floor(this.state.dealDetails.daysToFirstProposal * 24 * 60 * 60);
    const days = Math.floor(seconds / (3600 * 24));

    seconds -= days * 3600 * 24;
    const hours = Math.floor(seconds / 3600);

    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);

    seconds -= minutes * 60;

    return `${days} días, ${hours} horas, ${minutes} minutos y ${seconds}, segundos`;
  }

  renderDealPreferences() {
    return reduce(
      this.state.dealPreferences,
      (text, value, key) =>
        `${text}${
          !isNil(value)
            ? `\n${get(dealPreferenceQuestionsByValue, `[${key}].label`, '')}: ${get(
                dealPreferenceOptionsByValue,
                `[${value}].label`,
                get(dealCloseOrBudgetOptionsByValue, `[${value}].label`)
              )}`
            : ''
        }`,
      ''
    );
  }

  async handleassignToSales() {
    try {
      await api.request(
        `${ENDPOINT_V2}/opportunities/${this.state.dealDetails.id}/force-auto-assign-agent-team`,
        'POST'
      );
      // browserHistory.push(`/dealDetails/${this.state.dealDetails.id}`);
      this.setState(
        {
          submitting: true,
        },
        () => {
          this.props.actions.getDealDetailsData();
          openNotificationWithIcon(
            'success',
            'Todo OK :)',
            'Los cambios se han registrado correctamente'
          );
        }
      );
    } catch (e) {
      console.error(e);
      openNotificationWithIcon(
        'error',
        'Ooops',
        'No se ha podido re-asignar la oportunidad al equipo de ventas'
      );
    }
  }

  render() {
    const { header, message, onConfirmClick } = this.state.alert;
    const { title, onConfirmPhase } = this.state.modalFields;
    const { lostDealTitle, onConfirmLost, lostDealMessage } = this.state.modalLostDeal;
    const ctx = this;

    const fields = this.state.alertFormFields.required.map(field => ctx.formFields[field]());

    const lostDealFields = this.state.alertFormFields.lostDeal.map(field =>
      ctx.formFields[field]()
    );

    if (this.state.alertFormFields.optional) {
      this.state.alertFormFields.optional.map(field => fields.push(ctx.formFields[field]()));
    }

    const customerRateDiscountPercentage = get(
      this.state.dealDetails,
      'customerRateDiscountPercentage',
      0
    );
    // Opo recurrency
    const firstRequest = get(this.state, 'dealDetails.firstRequest', false);
    const hasPreviousAcceptedProposals = get(
      this.state,
      'dealDetails.hasPreviousAcceptedProposals',
      false
    );
    let customerRecurrency = 'Primera solicitud';
    if (!firstRequest && !hasPreviousAcceptedProposals) {
      customerRecurrency = 'Segundo tiro';
    } else if (!firstRequest && hasPreviousAcceptedProposals) {
      customerRecurrency = 'Recurrente';
    }

    return (
      <div className='col-sm-12'>
        <Modal
          title={header}
          visible={this.state.alert.show}
          onOk={onConfirmClick}
          onCancel={this.onAlertCancelClick.bind(this)}
        >
          <p>{message}</p>
        </Modal>
        <Modal
          title={title}
          visible={this.state.modalFields.show}
          onOk={onConfirmPhase}
          onCancel={this.onAlertCancelClick.bind(this)}
        >
          <div>{fields}</div>
        </Modal>
        <Modal
          title={lostDealTitle}
          visible={this.state.modalLostDeal.show}
          onOk={onConfirmLost}
          onCancel={this.onAlertCancelClick.bind(this)}
        >
          <div className='form-group'>
            <label style={{ fontFamily: 'nexa-bold' }}>{lostDealMessage}</label>
          </div>
          <hr className='hs-hr' />
          <div>{lostDealFields}</div>
        </Modal>
        <Modal
          title='Envío de confirmación de datos'
          onOk={this.onConfirmConfirmation}
          visible={this.state.showConfirmationDataDialog}
          onCancel={() => this.setState({ showConfirmationDataDialog: false })}
        >
          <p>
            Al guardar la oportunidad, se efectuará el envío de confirmación de datos en nombre del
            gestor asignado ¿Estás seguro?
          </p>
        </Modal>
        <form id='formDealDetails' action='' method='post'>
          <div className='row'>
            <div className='col-sm-2'>
              <div className='row'>
                <div className='col-sm-12'>
                  <button
                    disabled={this.checkIfButtonIsDisabled('submit')}
                    type='button'
                    data-test-id='save'
                    className='btn btn-primary btn-block alignLeft'
                    onClick={this.handleSubmit}
                  >
                    Guardar
                  </button>
                  <button
                    disabled={this.checkIfButtonIsDisabled('delete')}
                    type='button'
                    data-test-id='delete'
                    className='btn btn-primary btn-block alignLeft'
                    onClick={this.handleDelete}
                  >
                    Eliminar
                  </button>
                  <hr className='hs-hr' />
                  <button
                    disabled={this.checkIfButtonIsDisabled('increasePhase')}
                    type='button'
                    data-test-id='nextStage'
                    className='btn btn-primary btn-block alignLeft'
                    onClick={this.handleNextPhase}
                  >
                    Avanzar Etapa
                  </button>
                  <button
                    disabled={this.checkIfButtonIsDisabled('decreasePhase')}
                    type='button'
                    data-test-id='previousStage'
                    className='btn btn-primary btn-block alignLeft'
                    onClick={this.handlePreviousPhase}
                  >
                    Retroceder Etapa
                  </button>
                  <Tooltip
                    placement='topLeft'
                    title='Si la oportunidad está asignada a un gestor de primera llamada, la asigna a un gestor de ventas y lanza las consultas automáticas de disponibilidad'
                  >
                    <button
                      disabled={this.checkIfButtonIsDisabled('delete')}
                      type='button'
                      data-test-id='asignToSales'
                      className='btn btn-primary btn-block alignLeft'
                      onClick={this.handleassignToSales}
                    >
                      Asignar a ventas
                    </button>
                  </Tooltip>
                  <button
                    disabled={this.checkIfButtonIsDisabled('lossDeal')}
                    type='button'
                    data-test-id='lostDeal'
                    className='btn btn-primary btn-block alignLeft'
                    onClick={this.handleLossDeal}
                  >
                    Oportunidad Perdida
                  </button>
                  <br />
                  {this.state.dealDetails.creationDate ? (
                    <p>
                      <b>Creado por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(get(this.state, 'dealDetails.createdBy'), '')}
                      <br />
                      {moment(this.state.dealDetails.creationDate).format(DATETIME_FORMAT)}
                    </p>
                  ) : (
                    ''
                  )}
                  {this.state.dealDetails.updateDate ? (
                    <p>
                      <b>Última modificación por:</b>
                      <br />
                      {prettyPrintAgentFromEmail(get(this.state, 'dealDetails.updatedBy'), '')}
                      <br />
                      {moment(this.state.dealDetails.updateDate).format(DATETIME_FORMAT)}
                    </p>
                  ) : (
                    ''
                  )}
                  {!this.isNotConfirmedYet() && (
                    <div>
                      <p>
                        Fecha de confirmación de datos: <br />
                        {moment(
                          this.state.confirmationData.rentalRequestDataConfirmationDate
                        ).format(DATETIME_FORMAT)}
                      </p>
                      <p>
                        Gestor remitente: <br /> {this.state.confirmationData.confirmationSender}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='card-container col-sm-10' data-test-id='dealDetailsForms'>
              <Tabs type='card'>
                <TabPane tab='Datos solicitud' key='1'>
                  <div className='row'>
                    <div className='col-sm-6'>{this.formFields.DealDetailRentalRequestStage()}</div>
                    <div className='col-sm-6'>{this.formFields.DealDetailHsAgent()}</div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-2'>
                      <div className='form-group marginSelectForm'>
                        <CheckboxButton
                          borderStyle='dealCheckboxActive'
                          id='dealDetailFirstRequest'
                          name='firstRequest'
                          content={customerRecurrency}
                          disabled
                          readOnly
                          value={true}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>{this.formFields.DealDetailRentalRequestType()}</div>
                    <div className='col-sm-2'>
                      <div className='form-group'>
                        <label htmlFor='dealDetailIdentificationCode'>Código</label>
                        <input
                          type='text'
                          className='form-control'
                          id='dealDetailIdentificationCode'
                          name='identificationCode'
                          placeholder='123-345 - Pruebas'
                          readOnly
                          value={
                            this.state.dealDetails.identificationCode
                              ? this.state.dealDetails.identificationCode
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <SelectCompany
                          name='company'
                          value={this.state.dealDetails.companyId}
                          handleChange={this.handleCompanyChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-6'>{this.formFields.DealDetailAddress()}</div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='dealDetailZone'>Zona</label>
                        <input
                          type='text'
                          className='form-control'
                          id='dealDetailZone'
                          name='zone'
                          placeholder='La más barata'
                          value={this.state.dealDetails.zone ? this.state.dealDetails.zone : ''}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-2'>{this.formFields.DealDetailNumberOfPeople()}</div>
                    <div className='col-sm-2'>
                      <div className='form-group'>
                        <label htmlFor='dealDetailNumberOfRooms'>Número de habitaciones</label>
                        <input
                          type='number'
                          className='form-control'
                          id='dealDetailNumberOfRooms'
                          name='numberOfRooms'
                          placeholder='4'
                          value={
                            this.state.dealDetails.numberOfRooms
                              ? this.state.dealDetails.numberOfRooms
                              : ''
                          }
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>{this.formFields.DealDetailExternalSearch()}</div>
                    <div className='col-sm-3'>{this.formFields.DealDetailRentalStartDate()}</div>
                    <div className='col-sm-3'>{this.formFields.DealDetailRentalEndDate()}</div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-3'>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailinvoiceRequired'
                            name='invoiceRequired'
                            content='Factura'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.invoiceRequired
                                ? this.state.dealDetails.invoiceRequired
                                : false
                            }
                          />
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailcleaningRequired'
                            name='cleaningRequired'
                            content='Limpieza'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.cleaningRequired
                                ? this.state.dealDetails.cleaningRequired
                                : false
                            }
                          />
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailAcRequired'
                            name='aCRequired'
                            content='A/C'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.aCRequired
                                ? this.state.dealDetails.aCRequired
                                : false
                            }
                          />
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailwifiRequired'
                            name='wifiRequired'
                            content='WIFI'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.wifiRequired
                                ? this.state.dealDetails.wifiRequired
                                : false
                            }
                          />
                        </div>
                        <div className='col-sm-6'>
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailgarageRequired'
                            name='garageRequired'
                            content='Garaje'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.garageRequired
                                ? this.state.dealDetails.garageRequired
                                : false
                            }
                          />
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailliftRequired'
                            name='liftRequired'
                            content='Ascensor'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.liftRequired
                                ? this.state.dealDetails.liftRequired
                                : false
                            }
                          />
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailBalconyTerraceRequired'
                            name='balconyTerraceRequired'
                            content='Balcón'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.balconyTerraceRequired
                                ? this.state.dealDetails.balconyTerraceRequired
                                : false
                            }
                          />
                          <CheckboxButton
                            borderStyle='dealCheckboxActive'
                            id='dealDetailpetsAllowedRequired'
                            name='petsAllowedRequired'
                            content='Mascota'
                            handleChange={this.handleChange}
                            value={
                              this.state.dealDetails.petsAllowedRequired
                                ? this.state.dealDetails.petsAllowedRequired
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='dealDetailDescription'>Observaciones</label>{' '}
                        <textarea
                          id='dealDetailDescription'
                          name='dealDescription'
                          rows='6'
                          className='form-control'
                          placeholder='Busca lo más barato en barrio de lujo en la dimensión CR117...'
                          value={
                            this.state.dealDetails.dealDescription
                              ? this.state.dealDetails.dealDescription
                              : ''
                          }
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-5'>
                      <div className='form-group'>
                        <Tooltip
                          placement='topLeft'
                          title='Datos adicionales de la solicitud que no corresponden a otros campos.'
                        >
                          <label htmlFor='dealDetailInfo'>Detalle de la solicitud</label>{' '}
                          <i className='far fa-question-circle' style={{ fontSize: `${14}px` }} />
                        </Tooltip>

                        <textarea
                          id='dealDetailInfo'
                          name='dealDetailInfo'
                          rows='6'
                          disabled
                          className='form-control'
                          value={`${
                            this.state.dealDetails.detailInfo
                              ? this.state.dealDetails.detailInfo
                              : ''
                          }${this.renderDealPreferences()}`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>&nbsp;</div>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='card'>
                        <div className='card-header'>Precios</div>
                        <div className='card-block'>
                          {customerRateDiscountPercentage > 0 && (
                            <div className='pb-2'>
                              <InformationBlock type='warning' icon={['fas', 'info-circle']}>
                                ¡OJO! Esta oportunidad tiene un cupón de descuento del{' '}
                                {customerRateDiscountPercentage}%{' '}
                                <b>que ya se está aplicando a la tarifa del cliente.</b>
                                <br />
                                <b>
                                  Si vas a modificar la tarifa del cliente, acuérdate de aplicarle
                                  el descuento
                                </b>{' '}
                                al importe que tienes pensado indicar en el campo correspondiente
                              </InformationBlock>
                            </div>
                          )}
                          <div className='row'>
                            <div className='col-sm-2'>
                              {this.formFields.DealDetailCustomerRate()}
                            </div>
                            <div className='col-sm-2'>
                              {this.formFields.DealDetailProviderRate()}
                            </div>
                            <div className='col-sm-4'>{this.formFields.DealDetailbudget()}</div>
                            <div className='col-sm-2'>
                              {this.formFields.DealDetailturnoverGenerated()}
                            </div>
                            <div className='col-sm-2'>
                              <div className='form-group'>
                                <label htmlFor='dealDetailaveragePriceInArea'>
                                  Precio medio de la zona
                                </label>
                                <input
                                  type='number'
                                  className='form-control'
                                  id='dealDetailaveragePriceInArea'
                                  name='averagePriceInArea.amount'
                                  placeholder=''
                                  value={
                                    this.state.dealDetails.averagePriceInArea
                                      ? this.state.dealDetails.averagePriceInArea.amount
                                      : ''
                                  }
                                  onChange={this.handlePriceChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='Contrato' key='2'>
                  {/*= ==================== POST-VENTA / CIERRE ==================== */}
                  {this.state.dealDetails.proposals && (
                    <div>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label htmlFor='formularioDetailUrlPublicFormulario'>
                              URL del formulario de datos del Cliente:
                            </label>
                            <input
                              type='text'
                              className='form-control noBorder'
                              disabled
                              id='formularioDetailUrlPublicFormulario'
                              name='urlPublicFormulario'
                              placeholder='https://...'
                              value={
                                this.state.dealDetails.urlPublicFormulario
                                  ? this.state.dealDetails.urlPublicFormulario
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <label htmlFor='formularioDetailEmailContractSigning'>
                              Correo para la firma de contrato:
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              id='formularioDetailEmailContractSigning'
                              name='emailContractSigning'
                              placeholder='firmante@email.com'
                              value={
                                this.state.dealDetails.emailContractSigning
                                  ? this.state.dealDetails.emailContractSigning
                                  : ''
                              }
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='row'>
                    <div className='col-sm-3'>
                      {this.formFields.DealDetailcontractWritingDate()}
                    </div>
                    <div className='col-sm-3'>
                      {this.formFields.DealDetailcontractSigningDate()}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='Pagos' key='3'>
                  {customerRateDiscountPercentage > 0 && (
                    <div className='row'>
                      <div className='col-sm-10 pb-3'>
                        <InformationBlock type='warning' icon={['fas', 'info-circle']}>
                          A esta oportunidad se le ha aplicado un{' '}
                          <b>cupón de descuento del {customerRateDiscountPercentage}% </b> a la
                          tarifa de cliente.
                        </InformationBlock>
                      </div>
                    </div>
                  )}
                  <div className='row'>
                    <div className='col-sm-10'>
                      <div style={{ fontSize: `${14}px` }}>
                        <h4>
                          Transferencia al Op. General. Total:{' '}
                          {amountToString(this.state.prices.amountToTransferToOperative)}{' '}
                          <Tooltip placement='topLeft' title={TRANSFERENCIA_AL_OP_GENERAL}>
                            <i className='far fa-question-circle' />
                          </Tooltip>
                        </h4>
                        <hr className='hs-hr' />
                        <div style={{ fontSize: '14px' }}>
                          <p>
                            <b>Pago tarifa Cliente (imp. incluidos):</b>{' '}
                            <span>{amountToString(this.state.prices.customerRateWithTax)}</span>
                            <br />
                            <b>Comisión al proveedor (imp. incluidos):</b>{' '}
                            <span>{amountToString(this.state.prices.providerRateWithTax)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className='row'>
                    <div className='col-sm-10'>
                      <h4>
                        Pago Tarifa Cliente (imp. incluidos). Total:{' '}
                        {amountToString(this.state.prices.customerRateWithTax)}{' '}
                        <Tooltip placement='topLeft' title={PAGO_TARIFA_CLIENTE}>
                          <i className='far fa-question-circle' />
                        </Tooltip>
                      </h4>
                      <hr style={{ color: 'blue' }} />
                      <div>
                        <span style={{ fontSize: '14px' }}>
                          <b>Tarifa Cliente: </b>
                          {amountToString(get(this.state, 'dealDetails.customerRate'))}
                        </span>
                        <br />
                        <span>{this.formFields.TaxTypeByArea()}</span>
                        <br />
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className='row'>
                    <div className='col-sm-10'>
                      {' '}
                      <h4>
                        Pagos alquileres. Total:{' '}
                        {amountToString(get(this.state, 'prices.rentPayments'))}{' '}
                        <Tooltip placement='topLeft' title={PAGOS_ALQUILERES}>
                          <i className='far fa-question-circle' />
                        </Tooltip>
                      </h4>
                      <hr className='hs-hr' />
                      {this.getAcceptedProposals() ? (
                        <div
                          className='col-sm-6'
                          style={{
                            marginLeft: '3%',
                            fontSize: '14px',
                            maxWidth: '100%',
                          }}
                        >
                          {this.formFields.PaymentTab()}
                        </div>
                      ) : (
                        <span>No se encuentran Propuestas Aceptadas para esta Oportunidad.</span>
                      )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab='Cierre' key='4'>
                  <div className='row' style={{ zIndex: '9999' }}>
                    <div className='col-sm-4'>{this.formFields.DealDetaillossReason()}</div>
                    <div className='col-sm-8'>
                      {this.formFields.DealDetailLossReasonDescription()}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4' style={{ paddingBottom: `${200}px` }}>
                      <div className='form-group'>
                        <label htmlFor='dealDetailDaysToFirstProposal'>
                          Tiempo hasta 1ª propuesta
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='dealDetailDaysToFirstProposal'
                          name='daysToFirstProposal'
                          placeholder='1'
                          readOnly
                          value={
                            this.state.dealDetails.daysToFirstProposal
                              ? this.calculateTimeToFirstProposal()
                              : ''
                          }
                        />
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      {this.formFields.DealDetailWhereEmployeesWillStayed()}
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      saveDealDetailsData: data => dispatch(saveDealDetailsData(data)),
      deleteDealDetailsData: data => dispatch(deleteDealDetailsData(data)),
      getDealDetailsData: () => dispatch(getDealDetailsData()),
    },
  };
}

function mapStateToProps(state) {
  return {
    dealDetailsData: state.DealDetails.data,
    dealDetailsSubmit: state.DealDetails.submit,
    optionLabels: state.Preload.optionLabels,
    parentValues: state.Preload.parentValues,
    company: state.CompanyDetails.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDealDetails);
