import {
  NEW_PROVIDER_LOCATION_ERROR,
  NEW_PROVIDER_LOCATION_SUCCESS,
  PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR,
  PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS,
  PROVIDERS_METASOURCE_ERROR,
  PROVIDERS_METASOURCE_SUCCESS,
  PROVIDERS_TABLEDATA_ERROR,
  PROVIDERS_TABLEDATA_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  metasource: {
    links: {},
    error: false,
    errorDetail: '',
  },
  providersData: {
    tableData: [],
    totalCount: 0,
    error: false,
    errorDetail: '',
  },
  providerLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
  providersDataCached: [],
  providersList: {
    list: [],
    totalCount: 0,
    error: false,
    errorDetail: '',
    filterVersion: 0,
  },
  providerMarketingSourceMediumOptions: {
    options: [],
    error: false,
    errorDetail: '',
  },
};

const Providers = (state = initialState, action) => {
  switch (action.type) {
    case PROVIDERS_METASOURCE_SUCCESS: {
      return Object.assign({}, state, {
        metasource: {
          links: action.metasourceLinks,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROVIDERS_METASOURCE_ERROR: {
      return Object.assign({}, state, {
        metasource: {
          links: {},
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROVIDERS_TABLEDATA_SUCCESS: {
      return Object.assign({}, state, {
        providersData: {
          tableData: action.tableData,
          totalCount: parseInt(action.totalCount, 10),
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROVIDERS_TABLEDATA_ERROR: {
      return Object.assign({}, state, {
        providersData: {
          tableData: [],
          totalCount: 0,
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case NEW_PROVIDER_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        providerLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_PROVIDER_LOCATION_ERROR: {
      return Object.assign({}, state, {
        providerLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_SUCCESS: {
      return Object.assign({}, state, {
        providerMarketingSourceMediumOptions: {
          options: action.options,
          error: false,
          errorDetail: '',
        },
      });
    }

    case PROVIDER_MARKETING_SOURCE_MEDIUM_OPTIONS_ERROR: {
      return Object.assign({}, state, {
        providerMarketingSourceMediumOptions: {
          options: [],
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    default:
      return state;
  }
};

export default Providers;
