import React from 'react';
import PropTypes from 'prop-types';
import { Col, InputNumber, Row } from 'antd';
import CheckboxButton from '../CheckboxButton/CheckboxButton';
import HomySelect from '../HomyComponents/HomySelect';

const NOOP = () => {};

const ProposalTaxes = ({ ...props }) => (
  <>
    <Row className='hs-row'>
      <h6>Impuestos (alquiler)</h6>
    </Row>
    <hr className='hs-hr' />
    <Row gutter={12} className='hs-row'>
      <Col span={8}>
        <CheckboxButton
          borderStyle='primaryCheckboxActive'
          id='printableDataPlusTax'
          name='plusTax'
          handleChange={props.handleChange}
          content='+ Impuesto'
          value={props.data.plusTax}
        />
      </Col>
      <Col span={8}>
        <CheckboxButton
          borderStyle='primaryCheckboxActive'
          id='printableDataTaxIncluded'
          name='taxIncluded'
          handleChange={props.handleChange}
          content='Impuesto incluido'
          value={props.data.taxIncluded}
        />
      </Col>
      <Col span={8}>
        <CheckboxButton
          borderStyle='primaryCheckboxActive'
          id='printableDataTaxExempt'
          name='taxExempt'
          handleChange={props.handleChange}
          content='Exento'
          value={props.data.taxExempt}
        />
      </Col>
    </Row>
    <Row gutter={12} className='hs-row'>
      <Col span={8}>
        <label htmlFor='printableDataTaxValue'>% Impuesto</label>
        <InputNumber
          id='printableDataTaxValue'
          name='taxValue'
          style={{ width: '100%' }}
          placeholder={props.data.taxExempt ? '' : 0}
          min={0}
          disabled={props.data.taxExempt}
          value={props.data.taxValue}
          onChange={value => {
            props.handleChange({
              target: {
                name: 'taxValue',
                value,
              },
            });
          }}
        />
      </Col>
      <Col span={8}>
        <label htmlFor='printableDataTaxType'>Impuesto</label>
        <HomySelect
          name='taxType'
          isDisabled={props.data.taxExempt}
          value={
            props.data.taxExempt
              ? props.options.taxType[2]
              : props.data.taxType || props.options.taxType[0]
            // props.handleChange({
            //   target: {
            //     name: 'taxType',
            //     value: props.options.taxType[0],
            //     type: 'select',
            //     mode: 'default',
            //   },
            // })
          }
          onChange={value => {
            props.handleChange({
              target: {
                name: 'taxType',
                value,
                type: 'select',
                mode: 'default',
              },
            });
          }}
          options={props.options.taxType}
        />
      </Col>
    </Row>
    <style jsx>
      {`
        .hs-hr {
          margin-top: 0;
        }

        .hs-row {
          margin-bottom: 15px;
        }
      `}
    </style>
  </>
);
ProposalTaxes.propTypes = {
  data: PropTypes.shape({
    plusTax: PropTypes.bool,
    taxIncluded: PropTypes.bool,
    taxExempt: PropTypes.bool,
    taxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  handleChange: PropTypes.func,
  options: PropTypes.shape({
    taxType: PropTypes.array,
  }),
};

ProposalTaxes.defaultProps = {
  data: PropTypes.shape({
    plusTax: true,
    taxIncluded: false,
    taxExempt: false,
    taxValue: '',
  }),
  handleChange: NOOP,
  options: {
    taxType: [],
  },
};

export default ProposalTaxes;
