import { Button, Checkbox, Col, Form, Icon, Input, Layout, Row, Skeleton, Switch, Tag } from 'antd';
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';
// import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import {
  getDealDetailsForPublicForm,
  saveClientInfo,
} from '../../modules/DealDetails/DealDetailsActions';

import FormAddress from '../HomyFormComponents/FormAddress';
import { DATE_FORMAT, ENDPOINT } from '../../shared/appConfig';
import DealsApi from '../../modules/Deals/DealsApi';
import DealsDetailsApi from '../../modules/DealDetails/DealDetailsApi';
import { isEmptyObject } from '../../shared/utils/appUtils';
import { extractErrorMessage } from '../../shared/utils/apiUtils';

import { preloadPublicForm } from '../../modules/Preload/PreloadActions';
import InputAntdValidator from '../HomyFormComponents/InputAntdValidator';
import ElementAntdValidator from '../HomyFormComponents/ElementAntdValidator';
import FormIBAN from '../HomyFormComponents/FormIBAN';
import FormBIC from '../HomyFormComponents/FormBIC';
import FormInput from '../HomyFormComponents/FormInput';
import FormNIF from '../HomyFormComponents/FormNIF';
import FormEmail from '../HomyFormComponents/FormEmail';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const { Header, Content, Footer } = Layout;
const FormItem = Form.Item;

class FormDealClientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealDetails: this.props.dealDetails,
      loading: true,
      companyData: {},
      contractSigning: {},
      originContractSigning: {},
      tenantCounter: [],
      submitUrl: '',
      sameContacts: false,
      isSubmitting: false,
      submitSuccess: false,
    };
    this.loaded = false;
    // ReactGA.initialize('UA-75140213-1');
    // ReactGA.pageview(window.location.pathname + window.location.search);

    this.compareToLeaseholderNIF = this.compareToLeaseholderNIF.bind(this);
    this.handleSameData = this.handleSameData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkGDPR = this.checkGDPR.bind(this);
    this.checkGeneralConditions = this.checkGeneralConditions.bind(this);
    this.getProposals = this.getProposals.bind(this);
    this.getTenants = this.getTenants.bind(this);
    this.checkRoles = this.checkRoles.bind(this);
    this.setRoles = this.setRoles.bind(this);
    this.setSameValues = this.setSameValues.bind(this);
    this.getSameValues = this.getSameValues.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentDidMount() {
    const { params } = this.props;
    const { companyData, contractSigning } = this.state;

    DealsApi._doGet(`${ENDPOINT}/deal/public/${params.id}`)
      .then(res => {
        this.setState({
          loading: false,
          dealDetails: res.data.publicDeal,
        });
      })
      .then(() => {
        if (this.state.dealDetails) {
          const { contactsOfCompany } = this.state.dealDetails;

          this.checkRoles(contactsOfCompany);
        }
      })
      .then(() => {
        if (
          !isEmptyObject(this.state.companyData) &&
          !isEmptyObject(this.state.contractSigning) &&
          companyData.id === contractSigning.id
        ) {
          this.validateIDFields();
        }
      })
      .catch(err => console.error('Error loading data', err));

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.setState({
      companyData: {},
      contractSigning: {},
      dealDetails: {},
      isSubmitting: false,
      originContractSigning: {},
      submitSuccess: false,
    });
  }

  tenantHandleChange(e, tenantIndex, index) {
    const newData = this.state.dealDetails;

    if (newData.proposals[index]) {
      const tenant = newData.proposals[index].tenantContacts[tenantIndex];

      if (tenant) {
        tenant[e.target.name] = e.target.value;
      }
    }

    this.setState({
      dealDetails: newData,
    });
  }

  checkGDPR = (rule, value, callback) => {
    if (!value) {
      callback('Por favor, acepte los términos de Política de Privacidad y Protección de Datos.');
    }

    callback();
  };

  checkGeneralConditions = (rule, value, callback) => {
    if (!value) {
      callback('Por favor, acepte los términos de Condiciones Generales.');
    }

    callback();
  };

  handleSubmit = e => {
    e.preventDefault();
    const { params, form } = this.props;

    if (this.state.sameContacts) {
      this.setSameValues();
    }

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { dealDetails, sameContacts } = this.state;
        const companyData = Object.assign({}, values.leaseholder, {
          address: {
            ...values.leaseholder.address,
            addressComplement: values.leaseholder.addressComplement,
          },
        });
        const contractSigning = sameContacts
          ? Object.assign({}, this.getSameValues(), {
              address: {
                ...values.signature.address,
                addressComplement: values.signature.addressComplement,
              },
            })
          : Object.assign({}, values.signature, {
              address: {
                ...values.signature.address,
                addressComplement: values.signature.addressComplement,
              },
            });

        const deal = Object.assign({}, dealDetails, {
          companyData,
          contractSigning,
          gdpr: values.agreement,
          acceptedGeneralConditions: values.acceptedGeneralConditions,
          acceptedPrivacyPolicy: values.agreement,
        });

        this.setState(
          {
            isSubmitting: true,
            dealDetails: deal,
          },
          () => {
            DealsDetailsApi.doPut(`${ENDPOINT}/deal/public/${params.id}/clientInfo`, deal)
              .then(() => {
                browserHistory.push('/thankYou');
              })
              .catch(error => {
                console.error(error);
                browserHistory.push({
                  pathname: '/thankYou',
                  search: `?message=${extractErrorMessage(error)}`,
                });
              });
          }
        );
      }
    });
  };

  // En ANTD si los campos no son visibles no se pueden obtener. Por lo tanto hay que hacerlo a mano
  getSameValues = () => {
    const { form } = this.props;

    return {
      ...form.getFieldValue('signature'),
      name: form.getFieldValue('leaseholder').name,
      lastOTradeName: form.getFieldValue('leaseholder').lastOTradeName,
      address: form.getFieldValue('leaseholder').address,
      nif: form.getFieldValue('leaseholder').nif,
      addressComplement: form.getFieldValue('leaseholder').addressComplement,
    };
  };

  setSameValues = () => {
    this.props.form.setFieldsValue({
      signature: this.getSameValues(),
    });
  };

  handleSameData = checked => {
    if (checked) {
      this.setSameValues();
    } else {
      this.props.form.resetFields('signature');
    }

    this.setState(
      {
        sameContacts: checked,
      },
      () => {
        if (!checked) {
          this.validateIDFields();
        }
      }
    );
  };

  addTenant(index) {
    const { dealDetails } = Object.assign({}, this.state);
    const { form } = this.props;

    const newTenant = {
      name: '',
      lastOTradeName: '',
      identificationCode: '',
    };

    if (!dealDetails.proposals[index].tenantContacts) {
      dealDetails.proposals[index].tenantContacts = [];
    }

    dealDetails.proposals[index].tenantContacts.push(newTenant);

    form.setFieldsValue({
      [`tenantsList${index}`]: dealDetails.proposals[index].tenantContacts,
    });

    this.setState({
      dealDetails,
    });
  }

  getTenants(tenants, proposalIndex) {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator(`tenantsList${proposalIndex}`, { initialValue: tenants });
    const tenantsList = getFieldValue(`tenantsList${proposalIndex}`); // TODO: Cambiar tenantList por Proposal. Ver si sirve el tenantHandleChange

    return tenantsList.map((tenant, tenantIndex) => (
      <Row key={tenantIndex} gutter={24} style={{ marginTop: '10px' }}>
        <Col span={6}>
          <InputAntdValidator
            fieldDecorator={`proposal${proposalIndex}.tenant${tenantIndex}.name`}
            form={this.props.form}
            isRequired
            initialValue={tenant.name}
          >
            <Input
              name='name'
              onChange={e => this.tenantHandleChange(e, tenantIndex, proposalIndex)}
              placeholder='Nombre'
              prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </InputAntdValidator>
        </Col>
        <Col span={6}>
          <InputAntdValidator
            fieldDecorator={`proposal${proposalIndex}.tenant${tenantIndex}.lastOTradeName`}
            form={this.props.form}
            isRequired
            initialValue={tenant.lastOTradeName}
          >
            <Input
              name='lastOTradeName'
              onChange={e => this.tenantHandleChange(e, tenantIndex, proposalIndex)}
              placeholder='Apellidos'
              prefix={<Icon type='team' style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </InputAntdValidator>
        </Col>
        <Col span={6}>
          <InputAntdValidator
            fieldDecorator={`proposal${proposalIndex}.tenant${tenantIndex}.id`}
            form={this.props.form}
            isRequired
            validator={this.checkTenantsId}
            initialValue={tenant.identificationCode}
          >
            <Input
              name='identificationCode'
              onChange={e => this.tenantHandleChange(e, tenantIndex, proposalIndex)}
              placeholder='DNI'
              prefix={<Icon type='idcard' style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </InputAntdValidator>
        </Col>
        <Col span={6}>
          <Icon
            className='dynamic-delete-button'
            style={{ fontSize: '200%', marginTop: '6px', cursor: 'pointer' }}
            type='minus-circle-o'
            onClick={() => this.deleteTenant(proposalIndex, tenantIndex, tenant)}
          />
        </Col>
      </Row>
    ));
  }

  getProposals(proposals) {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    getFieldDecorator('proposalsList', { initialValue: proposals });
    const proposalsList = getFieldValue('proposalsList');

    return proposalsList.map((proposal, index) => (
      <li key={`proposalEntry_${index}`}>
        <div
          className='card-block'
          style={{
            borderTop: '3px solid #276377',
            backgroundColor: 'whitesmoke',
          }}
        >
          <Row style={{ marginBottom: '15px' }}>
            <Col span={6}>
              <strong>
                <h4>{index + 1}ª Propuesta</h4>
              </strong>
            </Col>
            <Col span={6}>
              <i
                className='fa fa-home'
                title='Nombre de la Propuesta'
                style={{ cursor: 'pointer' }}
              />{' '}
              {proposal.title}
              <br />
              <i
                className='fa fa-map-marker'
                title='Localización'
                style={{ cursor: 'pointer' }}
              />{' '}
              {get(proposal, 'address.locality')}
              <br />
              <i
                className='fa fa-calendar'
                title='Check-In & Check-Out'
                style={{ cursor: 'pointer' }}
              />{' '}
              {proposal.dateCheckIn
                ? `Fecha de entrada: ${moment(proposal.dateCheckIn).format(DATE_FORMAT)}`
                : ''}
              <br />
              <i
                className='fa fa-calendar'
                title='Check-In & Check-Out'
                style={{ visibility: 'hidden' }}
              />{' '}
              {proposal.dateCheckOut
                ? `Fecha de salida: ${moment(proposal.dateCheckOut).format(DATE_FORMAT)}`
                : ''}
            </Col>
            <Col span={6}>
              <i
                className='fas fa-door-open'
                title='Número de habitaciones'
                style={{ cursor: 'pointer' }}
              />{' '}
              {proposal.rooms}
              <br />
              <i className='fa fa-bed' title='Número de camas' style={{ cursor: 'pointer' }} />{' '}
              {proposal.beds}
              <br />
              <i
                className='fa fa-bath'
                title='Número de baños'
                style={{ cursor: 'pointer' }}
              />{' '}
              {proposal.bathrooms + proposal.toilets}
              <br />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <button
                type='button'
                className='btn btn-primary alignLeft buttonBreakLine'
                onClick={() => this.addTenant(index)}
              >
                <strong>Añadir trabajador</strong>
              </button>
            </Col>
          </Row>
          <hr className='hs-hr' />
          <Row>
            {proposal !== null && proposal.tenantContacts && proposal.tenantContacts.length > 0 ? (
              this.getTenants(proposal.tenantContacts, index)
            ) : (
              <div style={{ textAlign: 'center' }}>
                <br />
                <h3>Agrega uno o varios trabajadores que se alojarán en el piso</h3>
              </div>
            )}
          </Row>
        </div>
        <br />
      </li>
    ));
  }

  checkRoles(contactsOfCompany) {
    if (contactsOfCompany) {
      contactsOfCompany.forEach(contact => {
        if (
          (contact.roles && contact.roles.includes('LEASEHOLDER')) ||
          contact.roles.includes('CONTRACT_SIGNING')
        ) {
          if (contact.roles.includes('LEASEHOLDER') && contact.roles.includes('CONTRACT_SIGNING')) {
            this.setState({
              sameContacts: true,
            });
          }

          contact.roles.forEach(role => this.setRoles(role, contact.contact));
        }
      });
    }
  }

  setRoles(role, contact) {
    switch (role) {
      case 'LEASEHOLDER': {
        const companyData = {
          ...contact,
          nif: contact.identificationCode,
        };

        this.setState({
          companyData,
        });
        break;
      }

      case 'CONTRACT_SIGNING': {
        const contractSigning = {
          ...contact,
          nif: contact.identificationCode,
          lastOTradeName: contact.lastOTradeName,
          email: this.state.dealDetails.emailContractSigning,
        };

        const { companyData } = this.state;

        if (companyData.id !== contractSigning.id) {
          this.setState({
            contractSigning,
            originContractSigning: contact,
          });
        } else {
          this.setState({
            contractSigning,
          });
        }

        break;
      }

      default: {
        break;
      }
    }
  }

  handleFormChange = () => {
    const { form } = this.props;
    const isSwitchMarked = this.state.sameContacts;

    if (!isSwitchMarked) {
      const leaseholderNIF = this.props.form.getFieldValue(`leaseholder.nif`);
      const signatureNIF = this.props.form.getFieldValue(`signature.nif`);

      if (leaseholderNIF && signatureNIF) {
        form.validateFields(['leaseholder.nif', 'signature.nif'], (err, values) => {
          if (!err && values.leaseholder.nif === values.signature.nif) {
            this.setSameValues();
          }
        });
      }
    }
  };

  validateIDFields = () => {
    this.props.form.validateFields(['leaseholder.nif', 'signature.nif']);
  };

  compareToLeaseholderNIF = (rule, value, callback) => {
    if (value) {
      if (
        (rule.field.indexOf('signature') > -1 &&
          value === this.props.form.getFieldValue(`leaseholder.nif`)) ||
        (rule.field.indexOf('leaseholder') > -1 &&
          value === this.props.form.getFieldValue(`signature.nif`))
      ) {
        this.setState(
          {
            sameId: true,
          },
          () => {
            this.setSameValues();
            callback();
          }
        );
      } else {
        this.setState(
          {
            sameId: false,
          },
          () => callback()
        );
      }
    }

    callback();
    return false;
  };

  checkTenantsId = (rule, value, callback) => {
    if (typeof value === 'undefined') {
      callback();

      return false;
    }

    const { form } = this.props;

    const proposal = rule.field.split('.')[0];
    const tenant = rule.field.split('.')[1];
    const tenantsOfProposal = form.getFieldValue(proposal);
    const valueRegex = value
      .replace(/[^A-Za-z0-9]/g, '')
      .toLowerCase()
      .trim();

    Object.keys(tenantsOfProposal).forEach(key => {
      if ({}.hasOwnProperty.call(tenantsOfProposal, key)) {
        const idOfTenant = tenantsOfProposal[key].id
          .replace(/[^A-Za-z0-9]/g, '')
          .toLowerCase()
          .trim();

        if (key !== tenant && idOfTenant === valueRegex) {
          callback('El DNI está duplicado. Por favor, introduzca el DNI de la persona.');

          return false;
        }
      }
    });

    callback();
  };

  deleteTenant(proposalIndex, tenantIndex, tenant) {
    const { dealDetails } = this.state;
    const { form } = this.props;

    const newDealDetails = Object.assign({}, dealDetails);
    const { tenantContacts } = dealDetails.proposals[proposalIndex];

    newDealDetails.proposals[proposalIndex].tenantContacts = tenantContacts.filter(
      (x, i) => i !== tenantIndex
    );

    const tenantsList = form.getFieldValue(`tenantsList${proposalIndex}`);

    form.setFieldsValue({
      [`tenantsList${proposalIndex}`]: tenantsList.filter(r => r !== tenant),
    });

    this.setState({
      dealDetails: newDealDetails,
    });
  }

  render() {
    const { sameContacts, dealDetails, companyData, contractSigning, loading } = this.state;

    const hasAcceptedGeneralConditions =
      isNumber(dealDetails.generalConditionsDate) && !isEmpty(dealDetails.generalConditionsOrigin);

    const hasAcceptedPrivacyPolicy =
      isNumber(dealDetails.privacyPolicyDate) && !isEmpty(dealDetails.privacyPolicyOrigin);

    const proposalsList = dealDetails.proposals ? (
      this.getProposals(dealDetails.proposals)
    ) : (
      <div />
    );
    const responsive = {
      header: {
        xs: 0,
        sm: 0,
        md: 0,
        lg: 12,
        xl: 10,
        xxl: 8,
      },
      content: {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 12,
        xl: 12,
        xxl: 12,
      },
      submit: {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 8,
        xl: 8,
        xxl: 8,
      },
    };

    return (
      <Layout className='layout' style={{ background: 'white' }}>
        <Header style={{ background: 'white' }}>
          <Row>
            <Col>
              <div
                className='logoHs'
                style={{
                  width: '550px',
                  height: '80px',
                  float: 'left',
                }}
              />
            </Col>
            <Col {...responsive.header} style={{ float: 'right' }}>
              <b>¿Necesitas asistencia?</b>&nbsp;&nbsp;
              <i className='fa fa-phone' />
              &nbsp;
              <a href='tel:96+113+44+14'>
                <b>96 113 44 14</b>
              </a>
              &nbsp;&nbsp;
              <i className='fa fa-envelope-o' />
              &nbsp;
              <a href='mailto:reservas@homyspace.com'>
                <b>reservas@homyspace.com</b>
              </a>
            </Col>
          </Row>
        </Header>

        <Content style={{ padding: '3% 15% 3% 15%', fontFamily: 'Open sans' }}>
          <div
            style={{
              background: '#fff',
              padding: 24,
              minHeight: 280,
              border: '1px solid lightgray',
            }}
            className='contactBorderTopColor'
          >
            <Row>
              <Col
                className='positionRelative'
                style={{ backgroundColor: 'white', color: 'black', paddingBottom: '1.25rem' }}
              >
                <h2 style={{ fontFamily: 'Open sans' }}>
                  <b>Reserva de inmueble</b>
                </h2>
                Completa estos datos para empezar los trámites de la reserva
              </Col>
            </Row>
            <br />
            <Row>
              <Col {...responsive.content}>
                <label htmlFor='contactIdentificationCode'>
                  <strong>Código de la reserva</strong>
                </label>
                <Input
                  className='form-control'
                  id='contactIdentificationCode'
                  placeholder='Código de la reserva'
                  readOnly
                  style={{ width: '100px' }}
                  value={
                    dealDetails.identificationCode
                      ? dealDetails.identificationCode.substr(0, 9)
                      : ''
                  }
                />
              </Col>
            </Row>
            <br />
            <br />
            <Form
              layout='vertical'
              onSubmit={this.handleSubmit}
              onChange={this.handleFormChange}
              name='ClientForm'
            >
              <Row gutter={32}>
                <div className='col-sm-12'>
                  <h4 style={{ color: 'black' }}>
                    <b>Datos del Arrendatario</b>
                  </h4>
                  <hr className='hs-hr' />
                </div>
                <Skeleton loading={loading} active>
                  <div>
                    <Col {...responsive.content}>
                      <FormInput
                        fieldDecorator='leaseholder.name'
                        form={this.props.form}
                        isRequired
                        label={(() => {
                          switch (companyData.contactType) {
                            case 'PERSON':
                              return 'Nombre';
                            case 'COMPANY':
                              return 'Nombre comercial';
                            default:
                              return 'Nombre';
                          }
                        })()}
                        name='name'
                        initialValue={companyData.name}
                      />
                      <FormAddress
                        fieldDecorator='leaseholder.address'
                        form={this.props.form}
                        id='companyDataAddress'
                        isRequired={false}
                        label='Domicilio'
                        name='address'
                        placeholder='Número de puerta, apartamento, unidad, piso, etc.'
                        initialValue={companyData.address}
                        whitespace={false}
                      />
                      <FormInput
                        fieldDecorator='leaseholder.addressComplement'
                        form={this.props.form}
                        name='addressComplement'
                        placeholder='Número de puerta, apartamento, unidad, piso, etc.'
                        initialValue={
                          companyData.address && companyData.address.addressComplement
                            ? companyData.address.addressComplement
                            : null
                        }
                      />
                    </Col>
                    <Col {...responsive.content}>
                      <FormInput
                        fieldDecorator='leaseholder.lastOTradeName'
                        form={this.props.form}
                        isRequired
                        label={(() => {
                          switch (companyData.contactType) {
                            case 'PERSON':
                              return 'Apellidos';
                            case 'COMPANY':
                              return 'Razón Social';
                            default:
                              return 'Apellidos / Razón Social';
                          }
                        })()}
                        name='lastOTradeName'
                        initialValue={companyData.lastOTradeName}
                      />
                      <FormNIF
                        fieldDecorator='leaseholder.nif'
                        form={this.props.form}
                        isRequired
                        label={(() => {
                          switch (companyData.contactType) {
                            case 'PERSON':
                              return 'DNI';
                            case 'COMPANY':
                              return 'NIF';
                            default:
                              return 'DNI o NIF';
                          }
                        })()}
                        name='nif'
                        validator={this.compareToLeaseholderNIF}
                        initialValue={companyData.nif}
                      />
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <br />
              <p style={{ color: 'black' }}>
                <b>Datos de pago (para la devolución de la fianza)</b>
              </p>
              <Row gutter={32}>
                <Skeleton loading={loading} active>
                  <div>
                    <Col {...responsive.content}>
                      <FormIBAN
                        fieldDecorator='leaseholder.accountNumber'
                        form={this.props.form}
                        isRequired
                        initialValue={companyData.accountNumber}
                      />
                      <FormInput
                        fieldDecorator='leaseholder.beneficiary'
                        form={this.props.form}
                        isRequired
                        label='Beneficiario de la cuenta'
                        name='beneficiary'
                        initialValue={companyData.beneficiary}
                      />
                    </Col>
                    <Col {...responsive.content}>
                      <FormBIC
                        fieldDecorator='leaseholder.bicOrSwift'
                        form={this.props.form}
                        isRequired
                        initialValue={companyData.bicOrSwift}
                      />
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <Row gutter={32}>
                <div className='col-sm-12'>
                  <h4 style={{ color: 'black' }}>
                    <b>Datos del firmante del contrato</b>
                  </h4>
                  <hr className='hs-hr' />
                  {dealDetails.showCheck && (
                    <div style={{ marginBottom: '10px' }}>
                      <label htmlFor='sameContactsCheck'>
                        <strong style={{ color: 'black' }}>
                          {' '}
                          ¿Es la misma persona que el Arrendatario?
                        </strong>
                      </label>{' '}
                      <Switch
                        checked={sameContacts}
                        checkedChildren='Si'
                        id='sameContactsCheck'
                        onChange={this.handleSameData}
                        unCheckedChildren='No'
                        initialValue={sameContacts}
                      />
                    </div>
                  )}
                </div>
                <Skeleton loading={loading} active>
                  <div>
                    <Col {...responsive.content}>
                      <div className={sameContacts ? 'hideElement' : ''}>
                        <FormInput
                          form={this.props.form}
                          isDisabled={this.state.sameId}
                          isRequired
                          label={(() => {
                            switch (contractSigning.contactType) {
                              case 'PERSON':
                                return 'Nombre';
                              case 'COMPANY':
                                return 'Nombre comercial';
                              default:
                                return 'Nombre';
                            }
                          })()}
                          name='name'
                          initialValue={contractSigning.name}
                          fieldDecorator='signature.name'
                        />
                      </div>
                      <FormInput
                        fieldDecorator='signature.jobTitle'
                        form={this.props.form}
                        isRequired
                        label='Cargo'
                        name='jobTitle'
                        initialValue={contractSigning.jobTitle}
                      />
                      <div className={sameContacts ? 'hideElement' : ''}>
                        <FormAddress
                          fieldDecorator='signature.address'
                          form={this.props.form}
                          id='contractSigningAddress'
                          isDisabled={this.state.sameId}
                          isRequired={false}
                          label='Domicilio'
                          name='address'
                          placeholder='Número de puerta, apartamento, unidad, piso, etc.'
                          initialValue={contractSigning.address}
                          whitespace={false}
                        />
                        <FormInput
                          fieldDecorator='signature.addressComplement'
                          form={this.props.form}
                          isDisabled={this.state.sameId}
                          name='addressComplement'
                          placeholder='Número de puerta, apartamento, unidad, piso, etc.'
                          initialValue={
                            contractSigning.address && contractSigning.address.addressComplement
                              ? contractSigning.address.addressComplement
                              : null
                          }
                        />
                      </div>
                    </Col>
                    <Col {...responsive.content}>
                      <div className={sameContacts ? 'hideElement' : ''}>
                        <FormInput
                          fieldDecorator='signature.lastOTradeName'
                          form={this.props.form}
                          isDisabled={this.state.sameId}
                          isRequired
                          label={(() => {
                            switch (contractSigning.contactType) {
                              case 'PERSON':
                                return 'Apellidos';
                              case 'COMPANY':
                                return 'Razón Social';
                              default:
                                return 'Apellidos';
                            }
                          })()}
                          name='lastOTradeName'
                          initialValue={contractSigning.lastOTradeName}
                        />
                        <FormNIF
                          fieldDecorator='signature.nif'
                          form={this.props.form}
                          help={
                            this.state.sameId
                              ? 'Atención: El NIF de la persona con Capacidad de Firma y Arrendatario son el mismo. Se utilizarán los datos del Arrendatario.'
                              : ''
                          }
                          isDisabled={this.state.sameId}
                          isRequired
                          label={(() => {
                            switch (contractSigning.contactType) {
                              case 'PERSON':
                                return 'DNI';
                              case 'COMPANY':
                                return 'NIF';
                              default:
                                return 'DNI';
                            }
                          })()}
                          name='nif'
                          validator={this.compareToLeaseholderNIF}
                          initialValue={contractSigning.nif}
                        />
                      </div>
                      <FormEmail
                        fieldDecorator='signature.email'
                        form={this.props.form}
                        isRequired
                        label='Correo electrónico'
                        name='email'
                        type='email'
                        initialValue={contractSigning.email}
                      />
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <br />
              {!isEmpty(dealDetails.proposals) && !isEmpty(proposalsList) && (
                <Skeleton loading={loading} active>
                  <div>
                    <Row>
                      <h4 style={{ color: 'black' }}>
                        <b>Datos de los trabajadores que van a ocupar el piso</b>
                      </h4>
                      <hr className='hs-hr' />
                      <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        {this.state.dealDetails && proposalsList}
                      </ul>
                    </Row>
                    <br />
                  </div>
                </Skeleton>
              )}
              <Row>
                <Skeleton loading={loading} active>
                  <div>
                    <Col>
                      <h4 style={{ color: 'black' }}>
                        <b>
                          Aceptación Política de Privacidad, Protección de Datos y Condiciones
                          generales
                        </b>
                      </h4>
                      <Row>
                        <Col>
                          <div style={{ margin: '15px 0px' }}>
                            <ElementAntdValidator
                              fieldDecorator='agreement'
                              form={this.props.form}
                              validator={this.checkGDPR}
                              isRequired
                              valuePropName='checked'
                              type='boolean'
                              value={hasAcceptedPrivacyPolicy}
                              isRequiredMessage='Por favor, acepte los términos de Política de Privacidad y Protección de Datos.'
                              style={{ display: 'inline-block', margin: 0, padding: 0 }}
                            >
                              <Checkbox disabled={hasAcceptedPrivacyPolicy}>
                                Acepto la Política de Privacidad y Protección de Datos.{' '}
                                <a
                                  href='https://www.homyspace.com/politica-privacidad-proteccion-datos/'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{ textDecoration: 'underline' }}
                                >
                                  Ver política de privacidad y protección de datos
                                </a>
                              </Checkbox>
                            </ElementAntdValidator>
                            {hasAcceptedPrivacyPolicy && (
                              <Tag>
                                <b>
                                  Aceptadas:{' '}
                                  {moment(dealDetails.privacyPolicyDate).format(DATE_FORMAT)}
                                </b>
                              </Tag>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div>
                            <ElementAntdValidator
                              fieldDecorator='acceptedGeneralConditions'
                              form={this.props.form}
                              validator={this.checkGeneralConditions}
                              isRequired
                              valuePropName='checked'
                              type='boolean'
                              value={hasAcceptedGeneralConditions}
                              isRequiredMessage='Por favor, acepte los términos de Condiciones Generales.'
                              style={{ display: 'inline-block', margin: 0, padding: 0 }}
                            >
                              <Checkbox disabled={hasAcceptedGeneralConditions}>
                                Acepto la Condiciones generales.{' '}
                                <a
                                  href='https://www.homyspace.com/condiciones-generales/'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{ textDecoration: 'underline' }}
                                >
                                  Ver condiciones generales.
                                </a>
                              </Checkbox>
                            </ElementAntdValidator>
                            {hasAcceptedGeneralConditions && (
                              <Tag>
                                <b>
                                  Aceptadas:{' '}
                                  {moment(dealDetails.generalConditionsDate).format(DATE_FORMAT)}
                                </b>
                              </Tag>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Skeleton>
              </Row>
              <br />
              <Row type='flex' justify='center'>
                <Col {...responsive.submit}>
                  <FormItem>
                    <Button
                      className='btn btn-primary btn-block'
                      disabled={this.state.isSubmitting}
                      htmlType='submit'
                      type='primary'
                    >
                      Confirmar Datos
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>

        <Footer
          style={{
            textAlign: 'center',
            background: 'white',
            borderTop: '1px solid lightgray',
            color: 'grey',
            fontSize: '12px',
            fontFamily: 'Open Sans',
          }}
        >
          <span className='copyright'>
            <b>Homyspace Business Housing S.L. @ {new Date().getFullYear()}</b>
          </span>
        </Footer>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    mainMetasource: state.Preload.mainMetasource,
    dealDetails: state.DealDetails.data.detail,
    dealData: state.DealDetails.data,
    submit: state.DealDetails.submit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      preloadPublicForm: () => dispatch(preloadPublicForm()),
      getDealDetailsForPublicForm: dealId => dispatch(getDealDetailsForPublicForm(dealId)),
      saveClientInfo: (dealDetails, url) => dispatch(saveClientInfo(dealDetails, url)),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    // eslint-disable-next-line no-unused-vars
    onFieldsChange(props, changedFields) {
      // props.onChange(changedFields);
    },
  })(FormDealClientInfo)
);
