import React, { useState, useRef } from 'react';
import GoogleMapsAutocomplete from '../../../components/GoogleMapsAutocomplete/GoogleMapsAutocomplete';
import GoogleMapsMap from '../../../components/GoogleMapsMap/GoogleMapsMap';
import { Modal, Row, Col, Input, Form, Checkbox, Select, InputNumber } from 'antd';
import { normalizeNumberToDecimals } from '../../../shared/utils/formUtils';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import {
  getOptionsForSelect,
  openNotificationWithIcon,
  mapDateOptionLabels,
} from '../../../shared/utils/appUtils';
import { isValidDate } from '../../../shared/utils/dateUtils';
import {
  dealPreferenceQuestions,
  dealPreferenceOptions,
  dealCloseOrBudgetOptions,
} from '../../../shared/utils/dealUtils';
import { formatPlaceToWorldWideAddress } from '../../../shared/utils/geoUtils';
import { hsAgents } from '../../../shared/utils/agentUtils';

const { Group: InputGroup } = Input;
const { Item: FormItem } = Form;

const NOOP = () => {};

const mapStyle = {
  width: '100%',
  height: '60vh',
};

const dealPreferenceQuestionsByValue = keyBy(dealPreferenceQuestions, 'value');

const OpportunityInfo = ({
  deal: {
    rentalStartDate: start = '',
    rentalEndDate: end = '',
    zone = '',
    entryDate: typeOfStart = '',
    rentalDuration: typeOfEnd = '',
    area = '',
    numberOfPeople: pax = '',
    numberOfRooms: rooms = '',
    budget = '',
    transport = '',
    allreadyHosted: alreadyHosted = '',
    budgetSelect: typeOfBudget = '',
    guestsNumber = '',
    dealDescription = '',
    invoiceRequired = false,
    cleaningRequired = false,
    aCRequired = false,
    wifiRequired = false,
    garageRequired = false,
    liftRequired = false,
    balconyTerraceRequired = false,
    petsAllowedRequired = false,
    hsagent: { username = '' } = {},
    address: { formattedAddress = '', geoLocation = {} } = {},
    address = {},
    roomShared,
    sofaBed,
    closeOrBudget,
  } = {},
  setDeal = NOOP,
  options = {},
  rating = 'B',
  score = 0,
}) => {
  const [showModalMap, setShowModalMap] = useState(false);
  const map = useRef(null);

  const setOppotunityInfo = (name, value) => {
    setDeal(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const showAreaMarker = () => {
    if (!isEmpty(address) && !isEmpty(geoLocation)) {
      const mapComponent = map.current;

      mapComponent.clearMarkers();
      mapComponent.setReferencePoint(geoLocation.lat, geoLocation.lng, null, true);
    }
  };

  const mapClickHandler = e => {
    const latlng = e.latLng;

    setDealAddress(latlng);
  };

  const mapDblClickHandler = e => {
    const latlng = e.latLng;

    setDealAddress(latlng, true);
  };

  const setDealAddress = (latlng, closeModal = false) => {
    const { google } = window;

    if (!google) {
      return;
    }

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        latLng: latlng,
      },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const resultPostalCodes = results.filter(
            result => result.types.indexOf('street_address') >= 0
          );

          if (!isEmpty(resultPostalCodes)) {
            const address = formatPlaceToWorldWideAddress(resultPostalCodes[0]);
            setDeal(prev => ({ ...prev, address }));

            if (closeModal) {
              setShowModalMap(false);
            }
          }
        } else {
          console.error(`Geocoder failed due to: ${status}`);
        }
      }
    );
  };

  const areaOptions = !isEmpty(options) ? getOptionsForSelect(options, 'Area') : null;
  const entryDateOptions = !isEmpty(options)
    ? mapDateOptionLabels(getOptionsForSelect(options, 'EntryDate'))
    : null;
  const rentalDurationOptions = !isEmpty(options)
    ? mapDateOptionLabels(getOptionsForSelect(options, 'RentalDuration'))
    : null;
  const alreadyHostedOptions = !isEmpty(options)
    ? getOptionsForSelect(options, 'AllreadyHosted')
    : null;
  const guestsNumberOptions = !isEmpty(options)
    ? getOptionsForSelect(options, 'GuestsNumber')
    : null;
  const budgetOptions = !isEmpty(options) ? getOptionsForSelect(options, 'Budget') : null;
  const transportOptions = !isEmpty(options) ? getOptionsForSelect(options, 'Transport') : null;

  return (
    <div className='border-component-opportunity p-2' data-testid='opportunity-form'>
      <Row>
        <Col>
          <label className='mt-1'>
            <b className='title-component'>Información de la Oportunidad</b>
          </label>
          <div style={{ marginBottom: 10 }} />
        </Col>
      </Row>

      <Row className='clasificacion-oportunidad-bg mb-2'>
        <Col>
          <div className='clasificacion-oportunidad'>
            <div className='flex-container-info pr-2'>
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>{rating}</span>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{score} puntos</span>
            </div>
          </div>
        </Col>
      </Row>

      {/* <Row gutter={16}>
        <Col span={12}>
          <div className='form-group'>
            <label className='required' htmlFor='googledealNewAddress'>
              Ubicación
            </label>
            <GoogleMapsAutocomplete
              id='dealNewAddress'
              handlePlaceChanged={place => {
                setDeal(prev => ({ ...prev, address: place }));
              }}
              initValue={!isEmpty(address) && formattedAddress ? address : null}
              inputClassName='form-control validate validate-required'
            />
          </div>
        </Col>
        <Col span={6}>
          <div className='form-group'>
            <label className='required' htmlFor='rentalStartDate'>
              Fecha de inicio de alquiler
            </label>
            <input
              type='date'
              className='form-control validate validate-required'
              id='rentalStartDate'
              name='rentalStartDate'
              value={start}
              onChange={e => setOppotunityInfo(e.target.name, e.target.value)}
              onBlur={e => {
                if (!isEmpty(e.target.value) && !isValidDate(e.target.value)) {
                  openNotificationWithIcon(
                    'error',
                    'Quo vadis?',
                    'Comprueba la fecha... ¡Algo no cuadra!'
                  );
                }
              }}
            />
          </div>
        </Col>
        <Col span={6}>
          <div className='form-group'>
            <label className='required' htmlFor='rentalEndDate'>
              Fecha fin de alquiler
            </label>
            <input
              type='date'
              className='form-control validate validate-required'
              id='rentalEndDate'
              name='rentalEndDate'
              value={end}
              onChange={e => setOppotunityInfo(e.target.name, e.target.value)}
              onBlur={e => {
                if (!isEmpty(e.target.value) && !isValidDate(e.target.value)) {
                  openNotificationWithIcon(
                    'error',
                    'Quo vadis?',
                    'Comprueba la fecha... ¡Algo no cuadra!'
                  );
                }
              }}
            />
          </div>
        </Col>
      </Row> */}

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <InputGroup compact>
            <FormItem style={{ width: '15%' }}>
              <button
                style={{
                  width: '100%',
                  height: '38px',
                  marginTop: '1.1rem',
                }}
                type='button'
                className='btn btn-primary btn-styles-container'
                onClick={() => setShowModalMap(true)}
              >
                <img src='../img/buscar_mapa.png' style={{ width: '21px' }} />
              </button>
            </FormItem>
            <Modal
              title='Vista de zona en mapa'
              width={720}
              visible={showModalMap}
              onCancel={() => setShowModalMap(false)}
              footer={null}
              destroyOnClose
            >
              <p>Haz doble click para seleccionar una ubicación del mapa</p>
              <GoogleMapsMap
                mapStyle={mapStyle}
                viewChangeCallback={() => {}}
                ref={map}
                initializeLocation
                loadCallback={showAreaMarker}
                clickCallback={mapClickHandler}
                dblclickCallback={mapDblClickHandler}
              />
            </Modal>
            <FormItem label='Dirección exacta a la que se desplazan*' style={{ width: '85%' }}>
              <GoogleMapsAutocomplete
                id='dealNewAddress'
                handlePlaceChanged={place => {
                  setDeal(prev => ({ ...prev, address: place }));
                }}
                initValue={!isEmpty(address) && formattedAddress ? address : null}
                inputClassName='validate validate-required'
                required
              />
            </FormItem>
          </InputGroup>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
          <InputGroup compact>
            <FormItem label='Tipo' style={{ width: '50%' }}>
              <Select
                name='area'
                value={area}
                allowClear
                showSearch
                style={{ width: '100%' }}
                onChange={value => {
                  setOppotunityInfo('area', value);
                }}
              >
                {!isEmpty(areaOptions) &&
                  areaOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
            <FormItem label='Zona' style={{ width: '50%' }}>
              <Input
                allowClear
                value={zone}
                name='zone'
                placeholder='Código Postal, Barrio, Puerta...'
                onChange={e => setOppotunityInfo(e.target.name, e.target.value)}
              />
            </FormItem>
          </InputGroup>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={5}>
          <InputGroup compact>
            <FormItem label='Inicio del alquiler*' style={{ width: '50%' }}>
              <Input
                type='date'
                required
                className='form-control validate validate-required'
                name='rentalStartDate'
                value={start}
                onChange={e => setOppotunityInfo(e.target.name, e.target.value)}
                onBlur={e => {
                  if (!isEmpty(e.target.value) && !isValidDate(e.target.value)) {
                    openNotificationWithIcon(
                      'error',
                      'Quo vadis?',
                      'Comprueba la fecha... ¡Algo no cuadra!'
                    );
                  }
                }}
              />
            </FormItem>
            <FormItem label='Tipo' style={{ width: '50%' }}>
              <Select
                name='entryDate'
                value={typeOfStart}
                allowClear
                showSearch
                style={{ width: '100%' }}
                onChange={value => {
                  setOppotunityInfo('entryDate', value);
                }}
              >
                {!isEmpty(entryDateOptions) &&
                  entryDateOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </InputGroup>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={5}>
          <InputGroup compact>
            <FormItem label='Final del alquiler*' style={{ width: '50%' }}>
              <Input
                type='date'
                required
                className='form-control validate validate-required'
                name='rentalEndDate'
                value={end}
                onChange={e => setOppotunityInfo(e.target.name, e.target.value)}
                onBlur={e => {
                  if (!isEmpty(e.target.value) && !isValidDate(e.target.value)) {
                    openNotificationWithIcon(
                      'error',
                      'Quo vadis?',
                      'Comprueba la fecha... ¡Algo no cuadra!'
                    );
                  }
                }}
              />
            </FormItem>
            <FormItem label='Tipo' style={{ width: '50%' }}>
              <Select
                value={typeOfEnd}
                name='rentalDuration'
                allowClear
                showSearch
                style={{ width: '100%' }}
                onChange={value => {
                  setOppotunityInfo('rentalDuration', value);
                }}
              >
                {!isEmpty(rentalDurationOptions) &&
                  rentalDurationOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            </FormItem>
          </InputGroup>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={9} xl={8} xxl={4} className='right-group'>
              <InputGroup compact>
                <FormItem label='PAX' style={{ width: '30%' }}>
                  <InputNumber
                    min={0}
                    name='numberOfPeople'
                    value={pax}
                    onChange={value => setOppotunityInfo('numberOfPeople', value)}
                  />
                </FormItem>

                <FormItem label='Tipo' style={{ width: '70%' }}>
                  <Select
                    name='guestsNumber'
                    value={guestsNumber}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    onChange={value => {
                      setOppotunityInfo('guestsNumber', value);
                    }}
                  >
                    {!isEmpty(guestsNumberOptions) &&
                      guestsNumberOptions.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>

            <Col xs={24} sm={24} md={12} lg={5} xl={8} xxl={2}>
              <InputGroup compact>
                <FormItem label='Habitaciones' style={{ width: '100%' }}>
                  <InputNumber
                    min={0}
                    name='numberOfRooms'
                    value={rooms}
                    onChange={value => setOppotunityInfo('numberOfRooms', value)}
                  />
                </FormItem>
              </InputGroup>
            </Col>

            <Col xs={24} sm={24} md={12} lg={9} xl={8} xxl={4}>
              <InputGroup compact>
                <FormItem label='Transporte para ir al trabajo' style={{ width: '100%' }}>
                  <Select
                    name='transport'
                    value={transport}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    onChange={value => {
                      setOppotunityInfo('transport', value);
                    }}
                  >
                    {!isEmpty(transportOptions) &&
                      transportOptions.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={8} xxl={4}>
              <InputGroup compact>
                <FormItem label='¿Ya están alojados?' style={{ width: '100%' }}>
                  <Select
                    name='allreadyHosted'
                    value={alreadyHosted}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    onChange={value => {
                      setOppotunityInfo('allreadyHosted', value);
                    }}
                  >
                    {!isEmpty(alreadyHostedOptions) &&
                      alreadyHostedOptions.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6} xl={8} xxl={4} className='right-group'>
              <InputGroup compact>
                <FormItem label='Presupuesto' style={{ width: '30%' }}>
                  <InputNumber
                    name='budget'
                    min={0}
                    value={
                      !isEmpty(budget) ? normalizeNumberToDecimals(budget.amount, 2) : undefined
                    }
                    onChange={value =>
                      setOppotunityInfo('budget', {
                        amount: parseFloat(value) || null,
                        currency: 'EUR',
                      })
                    }
                  />
                </FormItem>
                <FormItem label='Tipo' style={{ width: '70%' }}>
                  <Select
                    name='budgetSelect'
                    value={typeOfBudget}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    onChange={value => {
                      setOppotunityInfo('budgetSelect', value);
                    }}
                  >
                    {!isEmpty(budgetOptions) &&
                      budgetOptions.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
              <InputGroup compact>
                <FormItem label='Gestor asignado' style={{ width: '100%' }}>
                  <Select
                    name='hsagent'
                    value={username}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='miguelangel.nadal@homyspace.com'
                    onChange={value => {
                      setOppotunityInfo('hsagent', { username: value });
                    }}
                  >
                    {!isEmpty(hsAgents) &&
                      hsAgents.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <InputGroup compact>
                <FormItem
                  label={dealPreferenceQuestionsByValue['roomShared'].label}
                  style={{ width: '100%' }}
                >
                  <Select
                    name={dealPreferenceQuestionsByValue['roomShared'].value}
                    value={roomShared}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    onChange={value => {
                      setOppotunityInfo('roomShared', value);
                    }}
                  >
                    {!isEmpty(dealPreferenceOptions) &&
                      dealPreferenceOptions.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <InputGroup compact>
                <FormItem
                  label={dealPreferenceQuestionsByValue['sofaBed'].label}
                  style={{ width: '100%' }}
                >
                  <Select
                    name={dealPreferenceQuestionsByValue['sofaBed'].value}
                    value={sofaBed}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    onChange={value => {
                      setOppotunityInfo('sofaBed', value);
                    }}
                  >
                    {!isEmpty(dealPreferenceOptions) &&
                      dealPreferenceOptions.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <InputGroup compact>
                <FormItem
                  label={dealPreferenceQuestionsByValue['closeOrBudget'].label}
                  style={{ width: '100%' }}
                >
                  <Select
                    name={dealPreferenceQuestionsByValue['closeOrBudget'].value}
                    value={closeOrBudget}
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    onChange={value => {
                      setOppotunityInfo('closeOrBudget', value);
                    }}
                  >
                    {!isEmpty(dealCloseOrBudgetOptions) &&
                      dealCloseOrBudgetOptions.map(option => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                  </Select>
                </FormItem>
              </InputGroup>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label='Descripción'>
                <Input.TextArea
                  autoSize={{ minRows: 5 }}
                  placeholder='Introduce el resto de información relevante sobre el desplazamiento aquí.'
                  name='dealDescription'
                  value={dealDescription}
                  onChange={e => setOppotunityInfo(e.target.name, e.target.value)}
                />
              </FormItem>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              Extras
              <Row>
                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={invoiceRequired}
                      onChange={e => setOppotunityInfo('invoiceRequired', e.target.checked)}
                    >
                      Factura
                    </Checkbox>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={cleaningRequired}
                      onChange={e => setOppotunityInfo('cleaningRequired', e.target.checked)}
                    >
                      Limpieza
                    </Checkbox>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={aCRequired}
                      onChange={e => setOppotunityInfo('aCRequired', e.target.checked)}
                    >
                      A/C
                    </Checkbox>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={garageRequired}
                      onChange={e => setOppotunityInfo('garageRequired', e.target.checked)}
                    >
                      Garaje
                    </Checkbox>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={liftRequired}
                      onChange={e => setOppotunityInfo('liftRequired', e.target.checked)}
                    >
                      Ascensor
                    </Checkbox>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={balconyTerraceRequired}
                      onChange={e => setOppotunityInfo('balconyTerraceRequired', e.target.checked)}
                    >
                      Balcón
                    </Checkbox>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={wifiRequired}
                      onChange={e => setOppotunityInfo('wifiRequired', e.target.checked)}
                    >
                      WiFi
                    </Checkbox>
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label='' style={{ margin: 0 }}>
                    <Checkbox
                      checked={petsAllowedRequired}
                      onChange={e => setOppotunityInfo('petsAllowedRequired', e.target.checked)}
                    >
                      Mascota
                    </Checkbox>
                  </FormItem>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <style jsx>
        {`
          .clasificacion-oportunidad-bg {
            background-color: #ededed;
          }

          .clasificacion-oportunidad {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            align-items: center;
            height: 38px;
          }

          .flex-container-info {
            display: flex;
            justify-content: space-between;
            width: 50%;
            align-items: center;
          }

          .btn-styles-container {
            display: flex;
            justify-content: center;
          }

          .right-group {
            padding-right: 0 !important;
          }

          .squared-right {
            border-radius: 0.25rem 0 0 0.25rem !important;
          }

          .left-group {
            padding-left: 0 !important;
          }

          .squared-left {
            border-radius: 0 0.25rem 0.25rem 0 !important;
            border-left: 0;
          }

           {
            /* .ant-select-dropdown {
            width: 15% !important;
          } */
          }

          .ant-select-selection {
            border-color: #ced4da !important;
          }

          .ant-input-group.ant-input-group-compact .ant-input {
            float: left;
          }

          .ant-calendar-picker {
            min-width: inherit !important;
          }

          .ant-form-explain {
            padding-top: 2px !important;
            font-size: 12px !important;
          }

          .ant-input-number {
            width: 100% !important;
            line-height: inherit !important;
          }

          .invalid-input {
            border-radius: 4px !important;
          }

          .border-component-opportunity {
            min-height: 400px;
            padding-bottom: 3px;
            border: 1px solid #d1d4d7;
          }

          .ant-form-item-control {
            line-height: inherit !important;
          }
        `}
      </style>
    </div>
  );
};

export default OpportunityInfo;
