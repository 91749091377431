import {
  CREATE_NEW_BUSINESS_EVENT_ERROR,
  CREATE_NEW_BUSINESS_EVENT_SUCCESS,
  DELETE_BUSINESS_EVENT_ERROR,
  DELETE_BUSINESS_EVENT_SUCCESS,
  EVENT_COUNTS_ERROR,
  EVENT_COUNTS_SUCCESS,
  EVENTS_LIST_ERROR,
  EVENTS_LIST_SUCCESS,
  NEW_BUSINESS_EVENT_LOCATION_ERROR,
  NEW_BUSINESS_EVENT_LOCATION_SUCCESS,
} from '../../shared/actionTypes';

const initialState = {
  eventsList: {},
  eventCounts: {},
  eventsListError: false,
  eventsListErrorDetail: '',
  eventsListVersion: 1,
  eventsListNextPageUrl: '',
  reloadEventsByDelete: false,
  businessEventCreatedType: '',
  businessEventDeletedType: '',
  businessEventLocation: {
    location: '',
    error: false,
    errorDetail: '',
  },
  businessEventCreate: {
    error: false,
    errorDetail: '',
  },
  businessEventDelete: {
    error: false,
    errorDetail: '',
  },
};

const Events = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_LIST_SUCCESS: {
      return Object.assign({}, state, {
        eventsList: action.eventsList,
        eventsListError: false,
        eventsListErrorDetail: '',
        eventsListNextPageUrl: action.eventsListNextPageUrl,
        businessEventCreatedType: '',
        businessEventDeletedType: '',
      });
    }

    case EVENTS_LIST_ERROR: {
      return Object.assign({}, state, {
        eventsList: {},
        eventsListError: true,
        eventsListErrorDetail: action.errorDetail,
        eventsListNextPageUrl: '',
      });
    }

    case EVENT_COUNTS_SUCCESS: {
      return Object.assign({}, state, {
        eventCounts: action.eventCounts,
        eventError: false,
        eventErrorDetails: '',
      });
    }

    case EVENT_COUNTS_ERROR: {
      return Object.assign({}, state, {
        eventCounts: {},
        eventError: true,
        eventErrorDetails: action.errorDetail,
      });
    }

    case NEW_BUSINESS_EVENT_LOCATION_SUCCESS: {
      return Object.assign({}, state, {
        businessEventLocation: {
          location: action.location,
          error: false,
          errorDetail: '',
        },
      });
    }

    case NEW_BUSINESS_EVENT_LOCATION_ERROR: {
      return Object.assign({}, state, {
        businessEventLocation: {
          location: '',
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case CREATE_NEW_BUSINESS_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        eventsListVersion: state.eventsListVersion + 1,
        eventsListNextPageUrl: '',
        eventsList: [],
        businessEventCreatedType: action.businessEventType,
      });
    }

    case CREATE_NEW_BUSINESS_EVENT_ERROR: {
      return Object.assign({}, state, {
        businessEventCreate: {
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    case DELETE_BUSINESS_EVENT_SUCCESS: {
      return Object.assign({}, state, {
        eventsListVersion: state.eventsListVersion + 1,
        eventsListNextPageUrl: '',
        eventsList: [],
        businessEventDeletedType: action.businessEventType,
      });
    }

    case DELETE_BUSINESS_EVENT_ERROR: {
      console.error(action.errorDetail);

      return Object.assign({}, state, {
        businessEventDelete: {
          error: true,
          errorDetail: action.errorDetail,
        },
      });
    }

    default:
      return state;
  }
};

export default Events;
