import startCase from 'lodash/startCase';
import replace from 'lodash/replace';
import first from 'lodash/first';
import split from 'lodash/split';
import indexOf from 'lodash/indexOf';

export const prettyPrintAgentFromEmail = (email = '') => {
  return indexOf(email, '@') !== -1
    ? startCase(replace(first(split(email, '@')), '.', ' '))
    : email;
};

export const prettyPrintFirstNameAgentFromEmail = (email = '') => {
  return first(split(prettyPrintAgentFromEmail(email), ' '));
};

export const hsAgents = [
  {
    label: 'borja.gromaz@homyspace.com',
    value: 'borja.gromaz@homyspace.com',
  },
  {
    label: 'leo.aguado@homyspace.com',
    value: 'leo.aguado@homyspace.com',
  },
  {
    label: 'silvia.dediego@homyspace.com',
    value: 'silvia.dediego@homyspace.com',
  },
  {
    label: 'guzman.bustelo@homyspace.com',
    value: 'guzman.bustelo@homyspace.com',
  },
  {
    label: 'judit.martinez@homyspace.com',
    value: 'judit.martinez@homyspace.com',
  },
  {
    label: 'mafer.salazar@homyspace.com',
    value: 'mafer.salazar@homyspace.com',
  },
  {
    label: 'esther.boix@homyspace.com',
    value: 'esther.boix@homyspace.com',
  },

  {
    label: 'joseignacio.florido@homyspace.com',
    value: 'joseignacio.florido@homyspace.com',
  },
  {
    label: 'alina@homyspace.com',
    value: 'alina@homyspace.com',
  },
  {
    label: 'alejandro.albert@homyspace.com',
    value: 'alejandro.albert@homyspace.com',
  },
  {
    label: 'sarai.tarin@homyspace.com',
    value: 'sarai.tarin@homyspace.com',
  },
  {
    label: 'alvaro.delafuente@homyspace.com',
    value: 'alvaro.delafuente@homyspace.com',
  },
  {
    label: 'gemma.domenech@homyspace.com',
    value: 'gemma.domenech@homyspace.com',
  },
  {
    label: 'beatriz.boada@homyspace.com',
    value: 'beatriz.boada@homyspace.com',
  },
  { label: 'pruebas@homyspace.com', value: 'pruebas@homyspace.com' },
];

export const mkt2hsAgentsMap = {};

export const discardedAgentsForFilter = [];
