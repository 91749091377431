import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, Typography } from 'antd';
import TableDataRequests from '../../components/TableDataRequests';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import RequestsFilter from '../../components/Filter/RequestsFilter';
import RequestApi from '../../modules/Requests/RequestsApi';
import { ENDPOINT } from '../../shared/appConfig';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import join from 'lodash/join';

import api from '../../shared/utils/api';
import tableUtils from '../../shared/utils/tableUtils';
import {
  hsAgents as salesAgents,
  discardedAgentsForFilter,
  mkt2hsAgentsMap,
} from '../../shared/utils/agentUtils';
const { Title } = Typography;

class Requests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: { data: [] },
      filter: {
        ...props.filter,
        hsAgent: mkt2hsAgentsMap[props.currentUsername] // first check if its from marketing and has some agents assigned
          ? mkt2hsAgentsMap[props.currentUsername]
          : this.isSalesAgent(props.currentUsername) && // if its not from mkt then maybe its an agent itself
            !discardedAgentsForFilter.includes(props.currentUsername)
          ? [props.currentUsername]
          : '', // its no one
        showMoreFilters: false,
      },
      sorter: '',
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      loading: true,
    };
    this.filterData = this.filterData.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterChangeAgent = this.handleFilterChangeAgent.bind(this);
    this.loadTableData = this.loadTableData.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleFilterCollapse = this.handleFilterCollapse.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleExportation = this.handleExportation.bind(this);
    this.handleRequestDescription = this.handleRequestDescription.bind(this);
  }

  componentDidMount() {
    this.loadTableData();
  }

  handleTableChangeAgent = (value, record) => {
    const request = record.request || null;

    RequestApi._doPut(`${ENDPOINT}/request/${request.id}/assign`, {
      email: value,
    })
      .then(() => {
        openNotificationWithIcon('success', 'Todo OK :)', 'Gestor asignado correctamente');
        this.loadTableData();
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de asignar el Gestor...
${err}`
        );
      });
  };

  handleTableCallsCount = (request, value) => {
    RequestApi._doPut(`${ENDPOINT}/request/${request.id}/calls`, {
      callsCount: parseInt(value, 10),
    })
      .then(() => {
        openNotificationWithIcon('success', 'Todo OK :)', 'Confirmado el número de llamadas');
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de poner el número de llamadas...
${err}`
        );
      });
  };

  handlePaginationChange = (page, pageSize) => {
    const { pagination } = this.state;

    this.setState(
      {
        pagination: {
          ...pagination,
          pageSize,
          page,
        },
      },
      () => this.loadTableData()
    );
  };

  handleFilterChangeAgent(e) {
    const filter = Object.assign({}, this.state.filter, { hsAgent: e });

    this.setState({
      filter,
    });
  }

  handleCheckboxFilter(isChecked, name) {
    if (isChecked) {
      switch (name) {
        case 'converted2Deal': {
          this.setState({
            filter: {
              ...this.state.filter,
              converted2Deal: true,
              notConverted2Deal: false,
            },
          });
          break;
        }

        case 'notConverted2Deal': {
          this.setState({
            filter: {
              ...this.state.filter,
              converted2Deal: false,
              notConverted2Deal: true,
            },
          });
          break;
        }

        case 'firstRequest': {
          this.setState({
            filter: {
              ...this.state.filter,
              firstRequest: true,
              recurrent: false,
            },
          });
          break;
        }

        case 'recurrent': {
          this.setState({
            filter: {
              ...this.state.filter,
              firstRequest: false,
              recurrent: true,
            },
          });
          break;
        }

        default: {
          this.setState({
            filter: {
              ...this.state.filter,
              converted2Deal: false,
              notConverted2Deal: false,
              firstRequest: false,
              recurrent: false,
            },
          });
          break;
        }
      }
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [name]: false,
        },
      });
    }
  }

  handleFilterCollapse() {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        showMoreFilters: !prevState.filter.showMoreFilters,
      },
    }));
  }

  handleFilterChange(e) {
    const { filter } = this.state;

    if (e.target.type === 'checkbox') {
      this.handleCheckboxFilter(e.target.checked, e.target.name);

      return false;
    }

    filter[e.target.name] = e.target.value;
    this.setState({ filter });
  }

  loadTableData() {
    this.setState(
      {
        loading: true,
      },
      () => {
        const { pagination, sorter } = this.state;
        const filters = this.filterData();

        const params = {
          pagination,
          filters,
          sorter,
        };

        api
          .getTableData('request', params)
          .then(res => {
            this.setState({
              loading: false,
              table: {
                data: res.json.requestList,
              },
              pagination: {
                ...pagination,
                totalCount: parseInt(res.headers.get('x-total-count'), 10) || 0,
              },
            });
          })
          .catch(err => {
            this.setState({
              loading: false,
            });
            openNotificationWithIcon(
              'error',
              'Opps!! :(',
              `Ha ocurrido un error a la hora de procesar datos en las Solicitudes... ${err.message}`
            );
          });
      }
    );
  }

  sorterData = sorter => {
    const condition = tableUtils.getSortCondition(sorter, 'request');

    this.setState(
      {
        sorter: condition,
      },
      () => this.loadTableData()
    );
  };

  handleFilterSubmit = e => {
    e.preventDefault();

    const { pagination } = this.state;

    this.setState(
      {
        pagination: {
          ...pagination,
          page: 1,
        },
      },
      () => {
        this.loadTableData();
      }
    );
  };

  handleDateChange = (name, date) => {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        [name]: isNil(date) ? null : date.valueOf(),
      },
    }));
  };

  filterData() {
    const urlFilters = {};
    const { filter } = this.state;

    urlFilters.city = filter.city || undefined;
    urlFilters.phoneNumber = filter.phoneNumber || undefined;
    urlFilters.contactEmail = filter.contactEmail || undefined;
    urlFilters.hsAgent = join(filter.hsAgent, ' ');
    urlFilters.creationDateFrom = filter.creationDateFrom || undefined;
    urlFilters.creationDateTo = filter.creationDateTo || undefined;
    urlFilters.firstRequest = filter.firstRequest || false;
    urlFilters.recurrent = filter.recurrent || false;
    urlFilters.converted2Deal = filter.converted2Deal;
    urlFilters.notConverted2Deal = filter.notConverted2Deal;

    return urlFilters;
  }

  isSalesAgent(agentToFilter) {
    const emails = salesAgents.map(agent => agent.value);

    return emails.includes(agentToFilter);
  }

  handleExportation() {
    const filters = this.filterData();

    api
      .getExportToExcelData('request', filters)
      .then(res => {
        openNotificationWithIcon(
          'info',
          <span role='img' aria-label='ok'>
            Exportación realizada con éxito &#128076;
          </span>,
          res.message
        );
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          <span aria-label='error'>El servidor está ocupado</span>,
          'En este momento se está generando un informe para otro agente. Inténtalo de nuevo dentro de unos minutos.'
        );

        console.error('Error in exportation', err);
      });
  }

  handleRequestDescription = (value, record) => {
    this.setState({
      description: {
        value,
        id: get(record, 'request.id'),
        isVisible: true,
      },
    });
  };

  render() {
    return (
      <div className='animated fadeIn requests'>
        <Row aling='middle' gutter={8} className='row-header-requests'>
          <Col xxl={22} xl={20} lg={14} md={14} sm={24} xs={24}>
            <Title level={2}>
              Solicitudes
              {/* <img src='img/xmas/reindeer.png' alt='reindeer' height={50} /> */}
            </Title>
          </Col>
          <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
            <Button className='exportation' icon='download' onClick={this.handleExportation}>
              Exportar
            </Button>
          </Col>
        </Row>
        <Row className='row-table-requests'>
          <Col span={24}>
            <RequestsFilter
              filter={this.state.filter}
              salesAgents={salesAgents}
              handleChange={this.handleFilterChange}
              handleDateChange={this.handleDateChange}
              handleChangeAgent={this.handleFilterChangeAgent}
              loading={this.state.loading}
              handleSubmitFilter={this.handleFilterSubmit}
              handleFilterCollapse={this.handleFilterCollapse}
            />
          </Col>
        </Row>
        <Row className='row-table-requests'>
          <Col span={24}>
            <TableDataRequests
              data={this.state.table.data}
              loading={this.state.loading}
              salesAgents={salesAgents}
              handleChangeAgent={this.handleTableChangeAgent}
              handleCallsCount={this.handleTableCallsCount}
              pagination={this.state.pagination}
              handleSorter={this.sorterData}
              handlePaginationChange={this.handlePaginationChange}
              handlePaginationSizeChange={this.handlePaginationChange}
              handleRequestDescription={this.handleRequestDescription}
              loadTableData={this.loadTableData}
            />
          </Col>
        </Row>
        <style jsx>
          {`
            .requests {
              margin: 10px;
            }

            .row-filter-requests {
              margin-top: 10px;
            }

            .row-table-requests {
              margin-top: 10px;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.Deals.metasource,
    metasourceStrings: state.Preload.metasourceStrings,
    filter: state.Requests.filter,
    optionLabels: state.Preload.optionLabels,
    currentUsername: state.Preload.currentUserInformation.username,
    defaultRequestStageFilter: state.Preload.rentalRequestActiveStagesForFilteredCall,
  };
}

function mapDispatchToProps() {
  return {
    actions: {},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
