import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Icon, Popover, Table } from 'antd';
import { getOptionsForSelect } from '../../shared/utils/appUtils';

// PARAMETROS A CONFIGURAR
// callbackFunctionOnContactSelect -> Funcion a la que se llama cuando se selecciona un contacto en la lista
// callbackFunctionOnContactDelete -> Funcion para eliminar un contacto
// callbackFunctionOnContactUnlink -> Funcion para desvincular un contacto del cliente

class TableDataCompanyContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      roles: this.buildRolesMap(),
    };

    this.showCompanyContactDetail = this.showCompanyContactDetail.bind(this);
  }

  buildRolesMap() {
    return getOptionsForSelect(this.props.optionLabels, 'ContactRole').reduce((map, item) => {
      map[item.value] = item.label;

      return map;
    }, {});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.companyContacts) !== JSON.stringify(this.state.data)) {
      this.setState({
        data: nextProps.companyContacts,
      });
    }
  }

  showCompanyContactDetail = contact => {
    browserHistory.push(`/contactDetails/${contact.id}`);
    // this.props.callbackFunctionOnContactSelect(contact);
  };

  render() {
    const deleteRender = contactId => {
      const content = (
        <div>
          <div className='row alignCenter popup-option'>
            <div className='col-sm-12'>
              <a
                className='popup-option'
                onClick={() => this.props.callbackFunctionOnContactDelete(contactId)}
              >
                Eliminar de homybrain
              </a>
            </div>
          </div>
          <div className='row alignCenter popup-option'>
            <a
              className='popup-option'
              onClick={() => this.props.callbackFunctionOnContactUnlink(contactId)}
            >
              Desvincular del cliente
            </a>
          </div>
        </div>
      );
      return (
        <div className='row' style={{ display: '-webkit-box' }}>
          <div>
            <button
              className='btn btn-primary btn-sm'
              onClick={() => this.props.callbackEditFunctionOnContactUnlink(contactId)}
              style={{ borderColor: 'white' }}
            >
              <Icon type='edit' />
            </button>
          </div>
          <div>
            <Popover content={content} trigger='hover'>
              <button
                type='button'
                className='btn btn-primary btn-sm'
                style={{ borderColor: 'white' }}
              >
                <Icon type='delete' />
              </button>
            </Popover>
          </div>
        </div>
      );
    };

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'contact.name',
        sorter: (a, b) =>
          a.contact.name < b.contact.name ? -1 : a.contact.name > b.contact.name ? 1 : 0,
        render: text => (
          <a href=''>{text && text.length > 27 ? `${text.substring(0, 24)}...` : text}</a>
        ),
        onCellClick: (record, event) => {
          event.preventDefault();
          this.showCompanyContactDetail(record.contact);
        },
      },
      {
        title: 'Apellidos / Razón social',
        dataIndex: 'contact.lastOTradeName',
        sorter: (a, b) =>
          a.contact.lastOTradeName < b.contact.lastOTradeName
            ? -1
            : a.contact.lastOTradeName > b.contact.lastOTradeName
            ? 1
            : 0,
        render: text => (text && text.length > 27 ? `${text.substring(0, 24)}...` : text),
      },
      {
        title: 'Función',
        dataIndex: 'contact.relatedCompany.roles',
        render: roles => (roles ? roles.map(r => this.state.roles[r]).join(', ') : ''),
      },
      {
        title: 'Cargo',
        dataIndex: 'contact.jobTitle',
        sorter: (a, b) =>
          a.contact.jobTitle < b.contact.jobTitle
            ? -1
            : a.contact.jobTitle > b.contact.jobTitle
            ? 1
            : 0,
        render: text => (text && text.length > 27 ? `${text.substring(0, 24)}...` : text),
      },
      {
        title: 'Email',
        dataIndex: 'contact.email',
        sorter: (a, b) =>
          a.contact.email < b.contact.email ? -1 : a.contact.email > b.contact.email ? 1 : 0,
        render: val => (
          <a href={`mailto:pruebas@homyspace.com`}>
            {val && val.length > 27 ? `${val.substring(0, 24)}...` : val}
          </a>
        ),
      },
      {
        title: '2º Email',
        dataIndex: 'contact.email2',
        sorter: (a, b) =>
          a.contact.email2 < b.contact.email2 ? -1 : a.contact.email2 > b.contact.email2 ? 1 : 0,
        render: val => (
          <a href={`mailto:pruebas@homyspace.com`}>
            {val && val.length > 27 ? `${val.substring(0, 24)}...` : val}
          </a>
        ),
      },
      {
        title: 'Teléfono',
        dataIndex: 'contact.phoneNumber',
        sorter: (a, b) =>
          a.contact.phoneNumber < b.contact.phoneNumber
            ? -1
            : a.contact.phoneNumber > b.contact.phoneNumber
            ? 1
            : 0,
        render: val => <a href={`tel:${val}`}>{val}</a>,
      },
      {
        title: '2º Teléfono',
        dataIndex: 'contact.mobilePhoneNumber',
        sorter: (a, b) =>
          a.contact.mobilePhoneNumber < b.contact.mobilePhoneNumber
            ? -1
            : a.contact.mobilePhoneNumber > b.contact.mobilePhoneNumber
            ? 1
            : 0,
        render: val => <a href={`tel:${val}`}>{val}</a>,
      },
      {
        title: ' ',
        dataIndex: 'contact.id',
        render: deleteRender,
      },
    ];

    return (
      <div className='col-sm-12'>
        {this.state.data && (
          <Table
            size='small'
            pagination={{ pageSize: 15 }}
            dataSource={this.state.data}
            columns={columns}
            rowKey={rec => rec.contact.id}
            defaultExpandAllRows
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps() {
  return {
    actions: {},
  };
}

function mapStateToProps(state) {
  return {
    companyContacts: state.CompanyDetails.contacts.detail,
    optionLabels: state.Preload.optionLabels,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TableDataCompanyContacts);
