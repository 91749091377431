import React from 'react';
import { MAXIMUM_PROPERTIES } from '../../shared/appConfig';

const PropertiesCounter = ({ propertiesCount = 0, totalCount = 0 }) => (
  <div className='d-flex mb-2' style={{ marginTop: '-10px', minHeight: 30 }}>
    Mostrando {propertiesCount} inmueble{propertiesCount === 1 ? '' : 's'}{' '}
    {propertiesCount === MAXIMUM_PROPERTIES && propertiesCount !== 0 ? 'de ' + totalCount : ''}
    <style jsx>{``}</style>
  </div>
);

export default PropertiesCounter;
