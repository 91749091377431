import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Modal, Tabs } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import {
  eventTotalCountsSuccess,
  getEventListData,
  getNewBusinessEventLocation,
  resetEventListData,
} from '../../modules/Events/EventsActions';
import { resetProposalsEmailSentSuccessfully } from '../../modules/DealDetails/DealDetailsActions';
import TimelineItem from './TimelineItem.js';
import FormNewBusinessEvent from '../FormNewBusinessEvent';
import {
  BUSINESS_EVENT_TYPES,
  isEmptyObject,
  openNotificationWithIcon,
} from '../../shared/utils/appUtils';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy'
import isEmpty from 'lodash/isEmpty';
import api from '../../shared/utils/api';

const { TabPane } = Tabs;

/** ************************************************
 PROPS TO CONFIGURE IN PARENT
 contextType -> Element's name to mark the context of the information to show.
 metasource -> context's metasource object
 ************************************************* */

class TimelinePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metasource: props.metasource,
      newNoteModal: false,
      newCallModal: false,
      newEmailModal: false,
      newTaskModal: false,
      businessEventLocationType: '',
      eventCounts: {},
      eventCountsToManageState: {},
      eventsList: [],
      eventsListVersion: 1,
      eventsListNextPageUrl: '',
      eventsListAll: [],
      eventsListActivities: [],
      eventsListMail: [],
      eventsListPhone: [],
      eventsListNote: [],
      eventsListTask: [],
      eventsListFollowUp: [],
      eventsListAvailabilityQuery: [],
      listEventsDone: false,
      reloadEventsByDelete: false,
      tabIndex: '1',
      switchingEventType: false,
      eventType: '',
      currentPage: null,
    };

    this.toggleNewNote = this.toggleNewNote.bind(this);
    this.toggleNewCall = this.toggleNewCall.bind(this);
    this.toggleNewEmail = this.toggleNewEmail.bind(this);
    this.toggleNewTask = this.toggleNewTask.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.reloadEvents = this.reloadEvents.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.resetEventListData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ switchingEventType: false });
    if (nextProps.businessEventLocation && nextProps.businessEventLocation.location !== '') {
      switch (this.state.businessEventLocationType) {
        case 'NEW_NOTE':
          this.toggleNewNote();
          break;
        case 'NEW_CALL':
          this.toggleNewCall();
          break;
        case 'NEW_EMAIL':
          this.toggleNewEmail();
          break;
        case 'NEW_TASK':
          this.toggleNewTask();
          break;
        default:
          break;
      }
    }

    if (nextProps.eventsListVersion !== this.state.eventsListVersion) {
      if (!isEmptyObject(nextProps.eventCounts)) {
        const { eventCounts } = this.state;

        if (nextProps.businessEventCreatedType !== '') {
          if (!eventCounts[nextProps.businessEventCreatedType]) {
            eventCounts[nextProps.businessEventCreatedType] = 0;
          }

          eventCounts[nextProps.businessEventCreatedType] += 1;
        } else if (nextProps.businessEventDeletedType !== '') {
          eventCounts[nextProps.businessEventDeletedType] -= 1;
        }

        this.setState({ eventCounts });
      }

      this.setState(
        {
          newNoteModal: false,
          newCallModal: false,
          newEmailModal: false,
          newTaskModal: false,
          businessEventLocationType: '',
          eventsListVersion: nextProps.eventsListVersion,
        },
        () => this.loadPage()
      );
    } else if (
      JSON.stringify(nextProps.eventCounts) !== JSON.stringify(this.state.eventCountsToManageState)
    ) {
      let allEventsCount = 0;

      const { eventCounts } = nextProps;
      eventCounts.ALL = nextProps.eventCounts.ALL;
      this.setState({
        eventCounts,
        eventCountsToManageState: nextProps.eventCounts,
      });
    }

    if (nextProps.eventsListNextPageUrl !== this.state.eventsListNextPageUrl) {
      this.setState({
        eventsListNextPageUrl: nextProps.eventsListNextPageUrl,
      });
    }

    if (
      JSON.stringify(nextProps.eventsList) !== JSON.stringify(this.state.eventsList)
      // ||
      // nextProps.eventsList.length==0
    ) {
      if (nextProps.eventsList instanceof Array) {
        let eventsListAll = [];
        let eventsListActivities = [];
        let eventsListMail = [];
        let eventsListPhone = [];
        let eventsListNote = [];
        let eventsListTask = [];
        let eventsListFollowUp = [];
        let eventsListAvailabilityQuery = [];

        if (this.state.eventsList.length !== 0) {
          eventsListAll = this.state.eventsListAll;
          eventsListActivities = this.state.eventsListActivities;
          eventsListMail = this.state.eventsListMail;
          eventsListPhone = this.state.eventsListPhone;
          eventsListNote = this.state.eventsListNote;
          eventsListTask = this.state.eventsListTask;
          eventsListFollowUp = this.state.eventsListFollowUp;
          eventsListAvailabilityQuery = this.state.eventsListAvailabilityQuery;
        }

        const { eventCounts } = this.state;

        nextProps.eventsList.forEach(event => {
          if (eventsListAll.length !== eventCounts.ALL) {
            eventsListAll.push(event);
            eventsListAll = uniqBy(eventsListAll, 'businessEvent.id');
          }

          const { type } = event.businessEvent;

          if (
            type.includes(BUSINESS_EVENT_TYPES.EMAIL) &&
            eventsListMail.length !== eventCounts.EMAIL
          ) {
            eventsListMail.push(event);
            eventsListMail = uniqBy(eventsListMail, 'businessEvent.id');
          }

          if (
            type.includes(BUSINESS_EVENT_TYPES.CALL) &&
            eventsListPhone.length !== eventCounts.CALL
          ) {
            eventsListPhone.push(event);
            eventsListPhone = uniqBy(eventsListPhone, 'businessEvent.id');
          }

          if (
            type.includes(BUSINESS_EVENT_TYPES.NOTE) &&
            eventsListNote.length !== eventCounts.NOTE
          ) {
            eventsListNote.push(event);
            eventsListNote = uniqBy(eventsListNote, 'businessEvent.id');
          }

          if (
            type.includes(BUSINESS_EVENT_TYPES.PROCESS_EVENT) &&
            eventsListActivities.length !== eventCounts.PROCESS_EVENT
          ) {
            eventsListActivities.push(event);
            eventsListActivities = uniqBy(eventsListActivities, 'businessEvent.id');
          }

          if (
            type.includes(BUSINESS_EVENT_TYPES.TASK) &&
            eventsListTask.length !== eventCounts.TASK
          ) {
            eventsListTask.push(event);
            eventsListTask = uniqBy(eventsListTask, 'businessEvent.id');
          }

          if (
            type.includes(BUSINESS_EVENT_TYPES.FOLLOW_UP) &&
            eventsListFollowUp.length !== eventCounts.FOLLOW_UP
          ) {
            eventsListFollowUp.push(event);
            eventsListFollowUp = uniqBy(eventsListFollowUp, 'businessEvent.id');
          }

          if (
            type.includes(BUSINESS_EVENT_TYPES.AVAILABILITY_QUERY) &&
            eventsListAvailabilityQuery.length !== eventCounts.AVAILABILITY_QUERY
          ) {
            eventsListAvailabilityQuery.push(event);
            eventsListAvailabilityQuery = uniqBy(eventsListAvailabilityQuery, 'businessEvent.id');
          }
        });
        this.setState(
          {
            eventsList: nextProps.eventsList,
            eventsListAll,
            eventsListActivities,
            eventsListMail,
            eventsListPhone,
            eventsListNote,
            eventsListTask,
            eventsListFollowUp,
            eventsListAvailabilityQuery,
          },
          () => this.getTotalCounts()
        );
      }
    }

    // To open new task modal after proposals were sent by email
    if (nextProps.proposalsEmailSentSuccessfully) {
      this.handleDetailMetasource('TASK');
      this.props.actions.resetProposalsEmailSentSuccessfully();
    }
  }

  loadPage() {
    if (this.state.eventsListNextPageUrl === this.state.currentPage) {
      return;
    }
    if (!isEmpty(get(this.props, 'metasource.links'))) {
      this.props.actions.getEventListData(
        `${this.props.contextType}_DETAIL_METASOURCE_EVENT_LIST`,
        this.state.eventType,
        this.props.metasource,
        this.state.eventsListNextPageUrl
      );
      this.setState({
        currentPage: this.state.eventsListNextPageUrl,
      });
    } else {
      this.setState({
        eventsListNextPageUrl: null,
      });
    }
  }

  getTotalCounts = async () => {
    try {
      const id = get(this.props, 'params.id');
      let type = this.props.contextType === 'DEAL' ? 'OPPORTUNITY' : this.props.contextType;
      const response = await api.getDetailDataV2('businessEvents/totalCounts/'+ type, id);

      this.props.actions.eventTotalCountsSuccess(response.json);
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Error',
        `No se ha podido cargar el numero total de BusinessEvents. ${e.message}`
      );
      console.error(e);
    }
  };

  reloadEvents(TabKey) {
    let eventType = '';

    switch (TabKey) {
      case '1':
        if (
          (this.state.eventCounts &&
            this.state.eventsListAll.length === this.state.eventCounts.ALL) ||
          !this.state.eventCounts.ALL
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListAll.length === 0) {
          this.setState({ eventsListAll: [] });
        }
        break;
      case '2':
        eventType = BUSINESS_EVENT_TYPES.PROCESS_EVENT;
        if (
          (this.state.eventCounts &&
            this.state.eventsListActivities.length === this.state.eventCounts.PROCESS_EVENT) ||
          !this.state.eventCounts.PROCESS_EVENT
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListActivities.length === 0) {
          this.setState({ eventsListActivities: [] });
        }
        break;
      case '3':
        eventType = BUSINESS_EVENT_TYPES.EMAIL;
        if (
          (this.state.eventCounts &&
            this.state.eventsListMail.length === this.state.eventCounts.EMAIL) ||
          !this.state.eventCounts.EMAIL
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListMail.length === 0) {
          this.setState({ eventsListMail: [] });
        }
        break;
      case '4':
        eventType = BUSINESS_EVENT_TYPES.CALL;
        if (
          (this.state.eventCounts &&
            this.state.eventsListPhone.length === this.state.eventCounts.CALL) ||
          !this.state.eventCounts.CALL
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListPhone.length) {
          this.setState({ eventsListPhone: [] });
        }
        break;
      case '5':
        eventType = BUSINESS_EVENT_TYPES.NOTE;
        if (
          (this.state.eventCounts &&
            this.state.eventsListNote.length === this.state.eventCounts.NOTE) ||
          !this.state.eventCounts.NOTE
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListNote.length === 0) {
          this.setState({ eventsListNote: [] });
        }
        break;
      case '6':
        eventType = BUSINESS_EVENT_TYPES.TASK;
        if (
          (this.state.eventCounts &&
            this.state.eventsListTask.length === this.state.eventCounts.TASK) ||
          !this.state.eventCounts.TASK
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListTask.length === 0) {
          this.setState({ eventsListTask: [] });
        }
        break;
      case '7':
        eventType = BUSINESS_EVENT_TYPES.FOLLOW_UP;
        if (
          (this.state.eventCounts &&
            this.state.eventsListFollowUp.length === this.state.eventCounts.FOLLOW_UP) ||
          !this.state.eventCounts.FOLLOW_UP
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListFollowUp.length === 0) {
          this.setState({ eventsListTask: [] });
        }
        break;
      case '8':
        eventType = BUSINESS_EVENT_TYPES.AVAILABILITY_QUERY;
        if (
          (this.state.eventCounts &&
            this.state.eventsListAvailabilityQuery.length ===
              this.state.eventCounts.AVAILABILITY_QUERY) ||
          !this.state.eventCounts.AVAILABILITY_QUERY
        ) {
          return this.setState({ eventsListNextPageUrl: null, eventType });
        }

        if (this.state.eventsListAvailabilityQuery.length === 0) {
          this.setState({ eventsListTask: [] });
        }
        break;
      default:
        break;
    }

    this.setState({ eventType, eventsListNextPageUrl: '' });
    this.props.actions.getEventListData(
      `${this.props.contextType}_DETAIL_METASOURCE_EVENT_LIST`,
      eventType,
      this.state.metasource,
      ''
    );
  }

  toggleNewNote() {
    this.setState({
      newNoteModal: !this.state.newNoteModal,
      businessEventLocationType: '',
    });
  }

  toggleNewCall() {
    this.setState({
      newCallModal: !this.state.newCallModal,
      businessEventLocationType: '',
    });
  }

  toggleNewEmail() {
    this.setState({
      newEmailModal: !this.state.newEmailModal,
      businessEventLocationType: '',
    });
  }

  toggleNewTask() {
    this.setState({
      newTaskModal: !this.state.newTaskModal,
      businessEventLocationType: '',
    });
  }

  handleDetailMetasource(action) {
    this.props.actions.getNewBusinessEventLocation(
      `${this.props.contextType}_DETAIL_METASOURCE_NEW_${action}`,
      this.state.metasource
    );
    this.setState({
      businessEventLocationType: `NEW_${action}`,
    });
  }

  renderEvents(events = []) {
    return events.map((event, i) => (
      <TimelineItem key={event.businessEvent.id + i} eventObject={event} />
    ));
  }

  render() {
    const {
      eventCounts,
      tabIndex,
      eventsListNextPageUrl,
      switchingEventType,
      eventsListAll,
      eventsListActivities,
      eventsListMail,
      eventsListPhone,
      eventsListNote,
      eventsListTask,
      eventsListFollowUp,
      eventsListAvailabilityQuery,
    } = this.state;

    return (
      <div>
        <div className='card col-sm-12'>
          <div className='card-block col-sm-12'>
            {this.props.businessEventDelete.error && (
              <Alert
                message='Error'
                description={this.props.businessEventDelete.errorDetail}
                type='error'
                showIcon
                closable
              />
            )}
            <div className='row'>
              <div className='col-sm-2'>
                <button
                  type='button'
                  className='btn btn-primary btn-block alignLeft'
                  onClick={() => this.handleDetailMetasource('NOTE')}
                >
                  Nueva nota
                </button>
                <Modal
                  title='Nueva nota'
                  visible={this.state.newNoteModal}
                  onCancel={this.toggleNewNote}
                  footer={null}
                >
                  <FormNewBusinessEvent />
                </Modal>

                <button
                  type='button'
                  className='btn btn-primary btn-block alignLeft'
                  onClick={() => this.handleDetailMetasource('CALL')}
                >
                  Nueva llamada
                </button>
                <Modal
                  visible={this.state.newCallModal}
                  onCancel={this.toggleNewCall}
                  title='Nueva llamada'
                  footer={null}
                >
                  <FormNewBusinessEvent />
                </Modal>

                <button
                  type='button'
                  className='btn btn-primary btn-block alignLeft'
                  onClick={() => this.handleDetailMetasource('EMAIL')}
                >
                  Nuevo correo
                </button>
                <Modal
                  visible={this.state.newEmailModal}
                  onCancel={this.toggleNewEmail}
                  title='Nuevo correo'
                  footer={null}
                >
                  <FormNewBusinessEvent />
                </Modal>

                <button
                  type='button'
                  className='btn btn-primary btn-block alignLeft'
                  onClick={() => this.handleDetailMetasource('TASK')}
                >
                  Nueva tarea
                </button>
                <Modal
                  visible={this.state.newTaskModal}
                  onCancel={this.toggleNewTask}
                  title='Nueva tarea'
                  footer={null}
                >
                  <FormNewBusinessEvent
                    highInterest
                    agent={get(this.props, 'dealDetails.hsagent.username')}
                  />
                </Modal>
              </div>

              <div className='col-sm-10' style={{ height: '80vh', overflow: 'auto' }}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadPage}
                  hasMore={eventsListNextPageUrl !== null && !switchingEventType}
                  loader={
                    <div key='1' className='loader loading-background loading-background-full'>
                      &nbsp;
                    </div>
                  }
                  initialLoad
                  threshold={400}
                  useWindow={false}
                >
                  {/* {this.state.eventsReactElementsList !== undefined &&
                    !isEmptyObject(this.state.eventsReactElementsList) && ( */}
                  <div className='card-container'>
                    <Tabs
                      type='card'
                      selectedIndex={tabIndex}
                      onChange={tabIndex =>
                        this.setState({ tabIndex, switchingEventType: true }, () =>
                          this.reloadEvents(tabIndex)
                        )
                      }
                      defaultActiveKey={tabIndex}
                      animation
                    >
                      <TabPane tab={`Todo (${eventCounts.ALL || 0})`} animation key='1'>
                        {this.renderEvents(eventsListAll)}
                      </TabPane>
                      <TabPane
                        tab={`Actividad (${eventCounts.PROCESS_EVENT || 0})`}
                        animation
                        key='2'
                      >
                        {this.renderEvents(eventsListActivities)}
                      </TabPane>
                      <TabPane tab={`Correos (${eventCounts.EMAIL || 0})`} animation key='3'>
                        {this.renderEvents(eventsListMail)}
                      </TabPane>
                      <TabPane tab={`Llamadas (${eventCounts.CALL || 0})`} animation key='4'>
                        {this.renderEvents(eventsListPhone)}
                      </TabPane>
                      <TabPane tab={`Notas (${eventCounts.NOTE || 0})`} animation key='5'>
                        {this.renderEvents(eventsListNote)}
                      </TabPane>
                      <TabPane tab={`Tareas (${eventCounts.TASK || 0})`} animation key='6'>
                        {this.renderEvents(eventsListTask)}
                      </TabPane>
                      <TabPane
                        tab={`Seguimientos (${eventCounts.FOLLOW_UP || 0})`}
                        animation
                        key='7'
                      >
                        {this.renderEvents(eventsListFollowUp)}
                      </TabPane>
                      <TabPane
                        tab={`Disponibilidad (${eventCounts.AVAILABILITY_QUERY || 0})`}
                        animation
                        key='8'
                      >
                        {this.renderEvents(eventsListAvailabilityQuery)}
                      </TabPane>
                    </Tabs>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getEventListData: (contextType, eventType, metasource, page) =>
        dispatch(getEventListData(contextType, eventType, metasource, page)),
      getNewBusinessEventLocation: (contextType, metasource) =>
        dispatch(getNewBusinessEventLocation(contextType, metasource)),
      resetEventListData: () => dispatch(resetEventListData()),
      eventTotalCountsSuccess: res => dispatch(eventTotalCountsSuccess(res)),
      resetProposalsEmailSentSuccessfully: () => dispatch(resetProposalsEmailSentSuccessfully()),
    },
  };
}

function mapStateToProps(state) {
  return {
    eventsList: state.Events.eventsList,
    eventCounts: state.Events.eventCounts,
    eventsListVersion: state.Events.eventsListVersion,
    businessEventLocation: state.Events.businessEventLocation,
    businessEventDelete: state.Events.businessEventDelete,
    businessEventCreatedType: state.Events.businessEventCreatedType,
    businessEventDeletedType: state.Events.businessEventDeletedType,
    eventsListNextPageUrl: state.Events.eventsListNextPageUrl,
    reloadEventsByDelete: state.Events.reloadEventsByDelete,
    dealDetails: state.DealDetails.data.detail,
    proposalsEmailSentSuccessfully: state.DealDetails.proposalsEmailSentSuccessfully,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TimelinePanel));
