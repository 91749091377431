import React, { Component } from 'react';
import { Alert, Col, Row } from 'antd';
import { getTaxTypeFromAddress } from '../../shared/utils/appUtils';
import BondedDealInfo from '../BondedDealInfo/BondedDealInfo';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import GenerateProposalInfo from '../FormPropertyDetails/GenerateProposalInfo';

class FormPrintablePropertyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertyDetails: {
        ...props.propertyDetails,
        observations: get(props, 'propertyDetails.observations', ''),
        taxType:
          get(props, 'propertyDetails.address.taxType') ||
          getTaxTypeFromAddress(get(props, 'propertyDetails.address')),
        featuredImageIndex: get(props, 'propertyDetails.mainPicture', []),
        imagesSelected: get(props, 'propertyDetails.selectedPictures', []),
        commissionPercentage: get(props, 'propertyDetails.providerBack.commissionPercentage', 0),
        dateCheckIn: get(props, 'bondedDeal.rentalStartDate'),
        dateCheckOut: get(props, 'bondedDeal.rentalEndDate'),
        tenants: get(props, 'propertyDetails.tenants', 1),
      },
      bondedDeal: props.bondedDeal,
      periodPricesWarning: !isEmpty(get(props, 'propertyDetails.periodPrices')),
    };
  }

  render() {
    const { bondedDeal, images, options } = this.props;
    const { propertyDetails } = this.state;

    return (
      <>
        {this.state.periodPricesWarning && (
          <Row className='hs-row'>
            <Col span={24}>
              <Alert
                message='¡Este inmueble tiene establecidos precios por temporada!'
                type='warning'
              />
            </Col>
          </Row>
        )}
        <Row className='hs-row' style={{ marginBottom: 0 }}>
          <Col span={24}>
            <BondedDealInfo />
          </Col>
        </Row>
        <Row className='hs-row'>
          <Col span={24}>
            <GenerateProposalInfo
              images={images}
              // For later images validation in FormNewProposal
              initialSelectedImagesLength={get(this.state, 'propertyDetails.pictures.length', 0)}
              data={propertyDetails}
              bondedDeal={bondedDeal}
              options={options}
            />
          </Col>
        </Row>
        <style jsx>
          {`
            .hs-row {
              margin-bottom: 15px;
            }
          `}
        </style>
      </>
    );
  }
}

export default FormPrintablePropertyDetails;
