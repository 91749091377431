import { getAxiosInstance } from '../../shared/utils/apiUtils';

class ProposalDetailsApi {
  static getMetasource(url, id) {
    const axiosInstance = getAxiosInstance();

    return url.slice(-1) === '/' ? axiosInstance.get(url + id) : axiosInstance.get(url + '/' + id);
  }

  static getProposalDetailsData(url) {
    const axiosInstance = getAxiosInstance();

    return axiosInstance.get(url);
  }

  static saveProposalDetailsData(url, proposalDetails, etag) {
    const axiosInstance = getAxiosInstance();

    if (etag) {
      axiosInstance.defaults.headers.common['if-match'] = etag;
    }

    return axiosInstance.put(url, proposalDetails);
  }

  static doGet(url) {
    return getAxiosInstance().get(url);
  }

  static doPost(url) {
    return getAxiosInstance().post(url);
  }

  static doPut(url, data) {
    return getAxiosInstance().put(url, data);
  }
}

export default ProposalDetailsApi;
