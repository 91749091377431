import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Modal, Row, Typography } from 'antd';
import TableDataCompanies from '../../components/TableDataCompanies';
import CompaniesFilter from './CompaniesFilter';
import {
  filterTableDataCompanies,
  getNewCompanyLocation,
} from '../../modules/Companies/CompaniesActions';
import FormNewCompany from '../../components/FormNewCompany';
import { openNotificationWithIcon } from '../../shared/utils/appUtils';
import api from '../../shared/utils/api';
import tableUtils from '../../shared/utils/tableUtils';
import get from 'lodash/get';

const { Title } = Typography;

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        data: [],
      },
      filter: {
        ...props.filter,
        showMoreFilters: false,
      },
      sorter: '',
      pagination: {
        totalCount: 0,
        page: 1,
        pageSize: 25,
      },
      modal: false,
      loading: true,
      companyLocation: props.companyLocation,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangeOnFilterConditions = this.handleChangeOnFilterConditions.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleFilterCollapse = this.handleFilterCollapse.bind(this);
    this.handleExportation = this.handleExportation.bind(this);
  }

  componentDidMount() {
    this.handleSubmit();
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.companyLocation !== newProps.companyLocation) {
      this.setState(
        {
          companyLocation: newProps.companyLocation,
        },
        () => this.toggle()
      );
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleFilterCollapse() {
    this.setState(prevState => ({
      filter: {
        ...prevState.filter,
        showMoreFilters: !prevState.filter.showMoreFilters,
      },
    }));
  }

  handlePaginationChange = (page, pageSize) => {
    this.setState(
      prevState => ({
        loading: true,
        pagination: {
          ...prevState.pagination,
          pageSize,
          page,
        },
      }),
      () => this.handleSubmit()
    );
  };

  filterData() {
    // const urlFilters = {};
    const { filter } = this.state;

    // urlFilters.name = filter.name || undefined;
    // urlFilters.lastOTradeName = filter.lastOTradeName || undefined;
    // urlFilters.email = filter.email || undefined;
    // urlFilters.identificationCode = filter.identificationCode || undefined;
    // urlFilters.phoneNumber = filter.phoneNumber || undefined;

    return filter;
  }

  handleChangeOnFilterConditions(e) {
    const { filter } = this.state;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    filter[e.target.name] = value;
    this.setState({ filter });
  }

  handleFilter(e) {
    e.preventDefault();
    this.props.actions.filterTableDataCompanies(this.state.filter);
  }

  handleSubmit() {
    const { pagination, sorter } = this.state;
    const filters = this.filterData();

    const params = {
      pagination,
      filters,
      sorter,
    };

    api
      .getTableDataV2('companies', params)
      .then(res => {
        this.setState({
          loading: false,
          table: {
            data: get(res, 'json.searchItems', []),
          },
          pagination: {
            ...pagination,
            totalCount: get(res, 'json.totalHits', 0),
          },
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        openNotificationWithIcon(
          'error',
          'Opps!! :(',
          `Ha ocurrido un error a la hora de procesar datos en los Clientes... ${err.message}`
        );
      });
  }

  sorterData = sorter => {
    const condition = tableUtils.getSortCondition(sorter, 'providers');

    this.setState(
      {
        sorter: condition,
        loading: true,
      },
      () => this.handleSubmit()
    );
  };

  handleFilterSubmit = e => {
    e.preventDefault();

    const { pagination } = this.state;

    this.setState(
      {
        loading: true,
        pagination: {
          ...pagination,
          page: 1,
        },
      },
      () => {
        this.handleSubmit();
      }
    );
  };

  handleExportation() {
    const filters = this.filterData();

    api
      .getExportToExcelData('company', filters)
      .then(res => {
        openNotificationWithIcon(
          'info',
          <span role='img' aria-label='ok'>
            Exportación realizada con éxito &#128076;
          </span>,
          res.message
        );
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          <span aria-label='error'>El servidor está ocupado</span>,
          'En este momento se está generando un informe para otro agente. Inténtalo de nuevo dentro de unos minutos.'
        );

        console.error('Error in exportation', err);
      });
  }

  render() {
    const { metasource, actions } = this.props;
    const { modal, filter, loading, pagination, table } = this.state;

    return (
      <div className='animated fadeIn companies'>
        {metasource.error && (
          <Alert
            message='Error'
            description={metasource.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}

        <Row gutter={8} align='middle'>
          <Col xxl={20} xl={18} lg={12} md={12} sm={24} xs={24}>
            <Title level={2} className='title-companies'>
              Clientes
              {/* <img src='img/xmas/card.png' alt='xmas-star' height={50} /> */}
            </Title>
          </Col>
          <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
            <Button className='exportation' icon='download' onClick={this.handleExportation}>
              Exportar
            </Button>
          </Col>
          <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
            <Button
              style={{ width: '100%' }}
              className='btn-primary'
              onClick={() => actions.getNewCompanyLocation()}
            >
              Crear cliente
            </Button>
            <Modal title='Nuevo cliente' visible={modal} onCancel={this.toggle} footer={null}>
              <FormNewCompany />
            </Modal>
          </Col>
        </Row>
        <Row className='row-companies'>
          <Col span={24}>
            <CompaniesFilter
              filter={filter}
              modal={modal}
              toggle={this.toggle}
              handleFilter={this.handleFilterSubmit}
              handleChangeOnFilterConditions={this.handleChangeOnFilterConditions}
              handleChangeLocalityFilter={this.handleChangeLocalityFilter}
              handleFilterCollapse={this.handleFilterCollapse}
              loading={loading}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TableDataCompanies
              data={table.data}
              loading={loading}
              pagination={pagination}
              handleSorter={this.sorterData}
              handlePaginationChange={this.handlePaginationChange}
              handlePaginationSizeChange={this.handlePaginationChange}
            />
          </Col>
        </Row>
        <style jsx>
          {`
            .companies {
              margin: 10px;
            }

            .title-companies {
              margin: 0;
            }

            .row-companies {
              margin-top: 30px;
            }

            .provider-btn-font {
              color: #212529;
            }

            .antd-input-style {
              border-radius: 4px;
              height: 32px;
            }

            .slide-down-row {
              overflow-x: hidden;
              overflow-y: hidden;
            }
          `}
        </style>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    metasource: state.Companies.metasource,
    filter: state.Companies.filter,
    companyLocation: state.Companies.companyLocation.location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      filterTableDataCompanies: filterCriteria =>
        dispatch(filterTableDataCompanies(filterCriteria)),
      getNewCompanyLocation: () => dispatch(getNewCompanyLocation()),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
