import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import FormProviderContacts from '../FormProviderContacts';
import { getProviderContactsData } from '../../modules/ProviderDetails/ProviderDetailsActions';

// PARAMETROS A CONFIGURAR
// leftOffset -> columnas bootstrap a desplazer el botón
// mainView -> main view in wich this component is included (optional)

class InfoButtonProviderContacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showProviderContacts: false,
      providerContacts: {},
      providerContactsVersion: props.providerContactsVersion,
    };
    this.showProviderContacts = this.showProviderContacts.bind(this);
    this.refreshProviderDetailsData = this.refreshProviderDetailsData.bind(this);

    this.props.actions.getProviderContactsData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.providerContacts !== nextProps.providerContacts.detail) {
      this.setState({ providerContacts: nextProps.providerContacts.detail });
    }
  }

  componentDidUpdate() {
    if (document.getElementById('infoButtonProviderContacts')) {
      if (this.state.showProviderContacts) {
        document.getElementById('infoButtonProviderContacts').style.zIndex = 99;
      } else {
        document.getElementById('infoButtonProviderContacts').style.zIndex = 1;
      }
    }
  }

  showProviderContacts(event) {
    if (event.target.nodeName !== 'A') {
      const showProviderContacts = !this.state.showProviderContacts;

      this.setState({
        showProviderContacts,
      });
      if (showProviderContacts) {
        this.props.actions.getProviderContactsData();
      }
    }
  }

  refreshProviderDetailsData() {
    setTimeout(() => {
      this.setState({
        showProviderContacts: false,
      });
      this.setState({
        showProviderContacts: true,
      });
      window.scrollTo(0, 0);
    }, 500);
  }

  render() {
    let leftOffset = 0;

    if (this.props.leftOffset) {
      leftOffset = parseInt(this.props.leftOffset, 10) * -50;
    }

    const styleInfoButtonDetail = {
      marginLeft: `${leftOffset}%`,
    };

    return (
      <div id='infoButtonProviderContacts' className='col-sm-2 noPadding'>
        {this.props.providerContacts.error && (
          <Alert
            message='Error'
            description={this.props.providerContacts.errorDetail}
            type='error'
            showIcon
            closable
          />
        )}
        <div
          className='card card-inverse contactBackgroundColor bold infoButton'
          onClick={this.showProviderContacts}
        >
          <div className='card-block'>
            <div className='row positionRelative'>
              <div className='col-sm-12'>
                <h1 className='nexa-bold'>
                  {this.state.providerContacts ? this.state.providerContacts.length : 0}{' '}
                </h1>
                contactos
              </div>
            </div>
            {this.state.showProviderContacts && (
              <div className='openIcon'>
                <i className='fa fa-caret-down fa-2x' />
              </div>
            )}
          </div>
        </div>
        {this.state.showProviderContacts && (
          <div
            style={styleInfoButtonDetail}
            className='infoButtonDetail cardNoMargin noPadding animated fadeIn contactBorderTopColor'
          >
            <div className='card'>
              <div className='card-block'>
                <FormProviderContacts
                  mainView={this.props.mainView}
                  callbackFunctionOnUpdateOrSave={this.refreshProviderDetailsData}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProviderContactsData: () => dispatch(getProviderContactsData()),
    },
  };
}

function mapStateToProps(state) {
  return {
    providerContacts: state.ProviderDetails.contacts,
    providerContactsVersion: state.ProviderDetails.contactsVersion,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButtonProviderContacts);
