import React from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import SelectCompany from '../../components/SelectCompany/SelectCompany';
import HomySelect from '../../components/HomyComponents/HomySelect';
import { DATE_FORMAT } from '../../shared/appConfig';
import moment from 'moment';
import CheckboxButton from '../../components/CheckboxButton/CheckboxButton';
import SlideDown from 'react-slidedown';
import { hsAgents } from '../../shared/utils/agentUtils';
import isEmpty from 'lodash/isEmpty';

const { Item: FormItem } = Form;

const DealsFilter = ({ ...props }) => (
  <Form onSubmit={props.handleFilter} layout='vertical'>
    <Row gutter={8} type='flex' justify='start' align='bottom'>
      <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Código'>
          <Input
            id='identificationCode'
            name='identificationCode'
            placeholder='XXXX-YYYY'
            value={props.filter.identificationCode}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Cliente'>
          <SelectCompany
            name='companyId'
            id='companyId'
            style={{ width: '100%' }}
            value={props.filter.companyId || ''}
            isRequired={false}
            handleChange={props.handleSelectChangeOfSearch}
            extraClassName='select-company-filter'
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={2} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Ciudad'>
          <Input
            id='locality'
            name='locality'
            placeholder='Valencia'
            value={props.filter.locality}
            onChange={props.handleChangeOnFilterConditions}
          />
        </FormItem>
      </Col>
      <Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={24}>
        <FormItem label='Gestor asociado'>
          <Select
            name='hsagent'
            value={props.filter.hsagent}
            allowClear
            style={{ width: '100%' }}
            placeholder='miguelangel.nadal@homyspace.com'
            onChange={value => {
              props.handleChangeOnFilterConditions({
                target: {
                  name: 'hsagent',
                  value: value || '',
                },
              });
            }}
          >
            {!isEmpty(hsAgents) &&
              hsAgents.map(option => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
      </Col>
      <Col xxl={8} xl={6} lg={12} md={12} sm={24} xs={24}>
        <FormItem label='Etapas de la Oportunidad'>
          <HomySelect
            name='rentalRequestStages'
            value={props.filter.rentalRequestStages}
            placeholder='Selecciona una o varias etapas'
            options={props.options.rentalRequestStages}
            onChange={value => {
              props.handleSelectChange('rentalRequestStages', value, 'multiple');
            }}
            mode='multiple'
            showArrow
            showSearch
            allowClear
          />
        </FormItem>
      </Col>
      <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
        <FormItem>
          <Button
            className='filter-buttons more-filters'
            icon={props.filter.showMoreFilters ? 'up' : 'down'}
            onClick={props.handleFilterCollapse}
          >
            Más filtros
          </Button>
        </FormItem>
      </Col>
      <Col xxl={2} xl={3} lg={6} md={6} sm={12} xs={24}>
        <FormItem>
          <Button
            htmlType='submit'
            icon='search'
            className='btn-primary filter-buttons'
            loading={props.loading}
          >
            Filtrar
          </Button>
        </FormItem>
      </Col>
    </Row>
    <SlideDown>
      {props.filter.showMoreFilters && (
        <Row gutter={8} type='flex' justify='start' align='bottom' className='slide-down-row'>
          <Col xxl={4} xl={4} lg={4} md={12} sm={24} xs={24}>
            <FormItem label='Tipo de Oportunidad'>
              <HomySelect
                name='rentalRequestType'
                placeholder='A, B, C, D'
                value={props.filter.rentalRequestType || ''}
                options={props.options.rentalRequestType}
                onChange={value => {
                  props.handleSelectChange('rentalRequestType', value, 'default');
                }}
                showArrow
                showSearch
                allowClear
              />
            </FormItem>
          </Col>
          <Col xxl={4} xl={4} lg={10} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                className='deal-filters-checkbox'
                borderStyle='primaryCheckboxActive'
                id='filterFirstRequest'
                name='firstRequest'
                handleChange={props.handleFirstRequestChange}
                content='Primera solicitud'
                value={props.filter.firstRequest || false}
              />
            </FormItem>
          </Col>
          <Col xxl={4} xl={4} lg={10} md={6} sm={12} xs={24}>
            <FormItem>
              <CheckboxButton
                className='deal-filters-checkbox'
                borderStyle='primaryCheckboxActive'
                id='filterRecurrent'
                name='recurrentRequest'
                handleChange={props.handleFirstRequestChange}
                content='Solicitud recurrente'
                value={props.filter.recurrentRequest || false}
              />
            </FormItem>
          </Col>
          <Col xxl={4} xl={6} lg={12} md={12} sm={12} xs={24}>
            <FormItem label='Creación desde'>
              <DatePicker
                style={{ width: '100%' }}
                placeholder='dd/mm/yyyy'
                value={props.filter.creationDateFrom ? moment(props.filter.creationDateFrom) : null}
                format={DATE_FORMAT}
                onChange={(moment, dateString) =>
                  props.handleDateChangeOnFilterConditions('creationDateFrom', moment, dateString)
                }
              />
            </FormItem>
          </Col>
          <Col xxl={4} xl={6} lg={12} md={12} sm={12} xs={24}>
            <FormItem label='Creación hasta'>
              <DatePicker
                style={{ width: '100%' }}
                value={props.filter.creationDateTo ? moment(props.filter.creationDateTo) : null}
                placeholder='dd/mm/yyyy'
                format={DATE_FORMAT}
                onChange={(moment, dateString) =>
                  props.handleDateChangeOnFilterConditions('creationDateTo', moment, dateString)
                }
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </SlideDown>
    <style jsx>
      {`
         {
          /* .Select {
          height: 66px !important;
          margin-left: 6px !important;
        }

        div.Select-control {
          border: 1px solid #d9d9d9 !important;
          border-radius: 4px !important;
          -webkit-transition: all 0.3s !important;
          transition: all 0.3s !important;
        }

        div.Select-control:hover,
        div.Select-control:active,
        div.Select-control:focus {
          border-color: #f8dda6 !important;
        }

        .Select-input {
          height: 30px !important;
        } */
        }

        label[for='companyId'] {
          display: none !important;
        }

        .switch-request {
          margin-left: 10px !important;
        }

        .filter-buttons {
          width: 100% !important;
        }

        .slide-down-row {
          overflow-x: hidden;
          overflow-y: hidden;
        }

        .select-company-filter {
          margin-top: 18px;
          height: 38px;
        }
      `}
    </style>
  </Form>
);
export default DealsFilter;
