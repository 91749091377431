import { browserHistory } from 'react-router';
import {
  CALCULATE_DEAL_SCORE_ERROR,
  CALCULATE_DEAL_SCORE_SUCCESS,
  DEALS_LIST_ERROR,
  DEALS_LIST_SUCCESS,
  DEALS_METASOURCE_ERROR,
  DEALS_METASOURCE_SUCCESS,
  DEALS_RENTAL_REQUEST_STAGE_COUNT_ERROR,
  DEALS_RENTAL_REQUEST_STAGE_COUNT_SUCCESS,
  DEALS_TABLEDATA_ERROR,
  DEALS_TABLEDATA_FILTER,
  DEALS_TABLEDATA_SUCCESS,
  NEW_DEAL_FROM_REQUEST,
  NEW_DEAL_LOCATION_ERROR,
  NEW_DEAL_LOCATION_SUCCESS,
} from '../../shared/actionTypes';
import DealsApi from './DealsApi';
import { extractErrorMessage } from '../../shared/utils/apiUtils';
import { getMetasourceLink } from '../../shared/utils/appUtils';

export const metasourceSuccess = metasourceLinks => ({
  type: DEALS_METASOURCE_SUCCESS,
  metasourceLinks,
});

export const metasourceError = errorMessage => ({
  type: DEALS_METASOURCE_ERROR,
  errorDetail: errorMessage,
});

export const filterTableDataDeals = filterCriteria => ({
  type: DEALS_TABLEDATA_FILTER,
  filterCriteria,
});

export const tableDataSuccess = (tableData, totalCount) => ({
  type: DEALS_TABLEDATA_SUCCESS,
  tableData,
  totalCount,
});

export const tablaDataError = errorMessage => ({
  type: DEALS_TABLEDATA_ERROR,
  errorDetail: errorMessage,
});

export const dealsListSuccess = (dealsList, totalCount) => ({
  type: DEALS_LIST_SUCCESS,
  dealsList,
  totalCount,
});

export const dealsListError = errorDetail => ({
  type: DEALS_LIST_ERROR,
  errorDetail,
});

export const rentalRequestStageCountSuccess = rentalRequestStageCounter => ({
  type: DEALS_RENTAL_REQUEST_STAGE_COUNT_SUCCESS,
  rentalRequestStageCounter,
});

export const rentalRequestStageCountError = errorDetail => ({
  type: DEALS_RENTAL_REQUEST_STAGE_COUNT_ERROR,
  errorDetail,
});

export const newDealLocationSuccess = location => ({
  type: NEW_DEAL_LOCATION_SUCCESS,
  location,
});

export const newDealFromRequest = data => ({
  type: NEW_DEAL_FROM_REQUEST,
  data,
});

export const newDealLocationError = errorMessage => ({
  type: NEW_DEAL_LOCATION_ERROR,
  errorDetail: errorMessage,
});

export const calculateDealScoreSuccess = dealScore => ({
  type: CALCULATE_DEAL_SCORE_SUCCESS,
  dealScore,
});

export const calculateDealScoreError = errorMessage => ({
  type: CALCULATE_DEAL_SCORE_ERROR,
  calculateDealScoreError: errorMessage,
});

export const loadDealsMetasource = () =>
  function(dispatch, getState) {
    try {
      return DealsApi.getMetasource(getState().Preload.mainMetasource.deals.href)
        .then(response => {
          dispatch(metasourceSuccess(response.data._links));
        })
        .catch(error => {
          console.error(error);
          dispatch(metasourceError(extractErrorMessage(error)));
        });
    } catch (e) {
      console.error(e);
      browserHistory.push('/');
    }
  };

export const getRentalRequestStageCountByUser = username =>
  function(dispatch, getState) {
    try {
      const { metasource } = getState().Deals;
      const { metasourceStrings } = getState().Preload;
      const url = getMetasourceLink(
        metasource,
        metasourceStrings,
        'DEAL_MAIN_METASOURCE_REQUEST_STAGE_COUNT_BY_USER'
      );

      if (url) {
        return DealsApi.getRentalRequestStageCountByUser(url.href + username)
          .then(response => {
            dispatch(
              rentalRequestStageCountSuccess(response.data, response.headers['x-total-count'])
            );
          })
          .catch(error => {
            console.error(error);
            dispatch(rentalRequestStageCountError(extractErrorMessage(error)));
          });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getNewDealLocation = (data = {}) =>
  function(dispatch, getState) {
    const url = getMetasourceLink(
      getState().Deals.metasource,
      getState().Preload.metasourceStrings,
      'DEAL_MAIN_METASOURCE_NEW_DEAL'
    );

    return DealsApi.getNewDealLocation(url.href)
      .then(response => {
        const { location } = response.headers;

        dispatch(newDealLocationSuccess(location, data));
        browserHistory.push('/deals/new');
      })
      .catch(error => {
        console.error(error);
        dispatch(newDealLocationError(extractErrorMessage(error)));
      });
  };

export const createNewDealFromRequest = requestData => dispatch => {
  dispatch(newDealFromRequest(requestData));
  browserHistory.push('/deals/newfromrequest');
};

export const calculateDealScore = dealScoreParams =>
  function(dispatch, getState) {
    const { metasource } = getState().Deals;
    const { metasourceStrings } = getState().Preload;
    const url = getMetasourceLink(metasource, metasourceStrings, 'DEAL_MAIN_METASOURCE_SCORE');

    if (url) {
      return DealsApi._doPostWithData(url.href, dealScoreParams)
        .then(response => {
          dispatch(calculateDealScoreSuccess(response.data));
        })
        .catch(error => {
          console.error(error);
          dispatch(calculateDealScoreError(extractErrorMessage(error)));
        });
    }

    console.error('URL for calculateDealScore not found');
  };
