import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getProvidersList, openNotificationWithIcon } from '../../shared/utils/appUtils';
import { showOrLoadProviderDetailsView } from '../../modules/ProviderDetails/ProviderDetailsActions';
import get from 'lodash/get';
import api from '../../shared/utils/api';

/** *****************************************************
 PROPS TO CONFIGURE IN PARENT
 handleChange -> function to be called when active state change
 isRequired -> boolean to indicate if component is required
 value -> select's value
 name -> name of the component
 id -> id of the component
 ******************************************************* */

class SelectProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      providersList: null,
      options: [],
      isLoading: false,
      providerData: null,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.state.value && this.state.value !== '') {
      this.props.actions.getProviderData(this.state.value);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }

    if (JSON.stringify(nextProps.providerData) !== JSON.stringify(this.state.providerData)) {
      if (nextProps.providerData.detail && nextProps.providerData.detail.baseContact) {
        const option = {
          label: `${
            nextProps.providerData.detail.baseContact.name
              ? nextProps.providerData.detail.baseContact.name
              : ''
          } ${
            nextProps.providerData.detail.baseContact.lastOTradeName
              ? nextProps.providerData.detail.baseContact.lastOTradeName
              : ''
          } ${
            nextProps.providerData.detail.baseContact.email
              ? `(${nextProps.providerData.detail.baseContact.email})`
              : ''
          }`,
          value: nextProps.providerData.detail.id,
        };

        this.setState({
          options: [option],
          providerData: nextProps.providerData,
        });
      } else if (nextProps.providerData.error) {
        openNotificationWithIcon(
          'error',
          'Ha ocurrido un error al cargar el proveedor',
          nextProps.providerData.errorDetail
        );
      }
    }
  }

  handleSelectChange(selectedOption) {
    this.setState({
      value: selectedOption ? selectedOption.value : '',
      options: selectedOption ? [selectedOption] : [],
    });
    this.props.handleChange(this.props.name, selectedOption, this.state.providerData);
  }

  handleInputChange = async value => {
    if (value.length >= 3) {
      try {
        const params = {
          filters: { globalSearch: value },
        };

        const response = await api.getTableDataV2('providers', params);

        const providers = get(response, 'json.searchItems');
        const providersListed = getProvidersList(providers);

        this.setState({
          value: null,
          options: providersListed,
          providersList: providers,
        });
      } catch (e) {
        openNotificationWithIcon(
          'error',
          'Ha ocurrido un error al cargar la lista de Proveedores',
          e.message
        );
        console.error(e);
      }
    } else {
      if (!this.state.value) {
        this.setState({ options: [] });
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  render() {
    return (
      <div className='form-group'>
        {this.props.label && (
          <label htmlFor='propertyDetailProvider' className='required'>
            {this.props.label}
          </label>
        )}
        <Select
          data-testid={get(this.props, 'data-testid')}
          filterOptions={options => options}
          name={this.props.name}
          id={this.props.id}
          className={
            'form-control ' +
            (this.props.isRequired ? 'validate validate-required ' : ' ') +
            (this.props.extraClassName ? this.props.extraClassName : '')
          }
          value={this.state.value ? this.state.value : ''}
          options={this.state.options}
          onChange={value => {
            this.handleSelectChange(value);
          }}
          onInputChange={value => {
            this.handleInputChange(value);
          }}
          isLoading={this.state.isLoading}
          noResultsText='No hay resultados'
          placeholder='Escribe para empezar a filtrar...'
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    providerData: state.ProviderDetails.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getProviderData: id => dispatch(showOrLoadProviderDetailsView(id, false, true)),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProvider);
